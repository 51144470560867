onePulse.factory('AlertService', ['$mdDialog', '$rootScope', '$timeout', '$interval',
    function($mdDialog, $rootScope, $timeout, $interval){

    //Fade delay for notifications
    var fadeDelay = 15000;

    var processNotification = function(type, title, message) {

        var randomId = Math.random().toString(36).slice(2);

        var notification = {
            "type": type,
            "title": title,
            "body": message,
            "action": "",
            "InternalId": randomId
        };

        $rootScope.notifications.unshift(notification);

        //Note: Using interval with single count instead of timeout for Protractor testing - do not replace
        $interval(function () {

            for (var i = 0; i < $rootScope.notifications.length; i++) {

                if ($rootScope.notifications[i].InternalId == randomId) {

                    $rootScope.notifications.splice(i, 1);
                }
            }
        }, fadeDelay, 1);
    };

    var warning = function(title, message) {

        processNotification("warning", title, message)
    };

    var error = function(title, message) {

        processNotification("error", title, message)
    };

    var info = function(title, message) {

        processNotification("info", title, message)
    };

    var success = function(title, message) {

        processNotification("success", title, message)
    };

    var confirm = function(title, message, callback, okText, cancelText, cancelCallback) {

        okText = (typeof okText == "undefined" ? "Ok" : okText);
        cancelText = (typeof cancelText == "undefined" ? "Cancel" : cancelText);

        $mdDialog.show(
            $mdDialog.confirm()
                .clickOutsideToClose(false)
                .title(title)
                .textContent(message)
                .ariaLabel('Alert')
                .ok(okText)
                .cancel(cancelText)
        ).then(function() {

                if(typeof callback != "undefined") {

                    callback();
                }
        },function() {

                if(typeof cancelCallback != "undefined") {

                    cancelCallback();
                }
        });
    };

    var alert = function(title, message, callback, okText) {

        okText = (typeof okText == "undefined" ? "Ok" : okText);

        sweetAlert({
            title: title,
            text: message,
            confirmButtonText: okText
        },
            function() {

                if(typeof callback != "undefined") {

                    callback();
                }
            }
        );
    };

    return {
        error: error,
        warning: warning,
        info: info,
        success: success,
        confirm: confirm,
        alert: alert
    }
}]);