/**
 * Created by Fausto on 2018/08/17.
 *
 * Pulses Toolbar Wrappers
 */

onePulse.directive('pulsesToolbar', [
    function () {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/pulses/pulsesToolbar.html',

            link: function postLink(scope, element, iAttrs) {

            }
        };
    }]);