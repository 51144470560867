onePulse.controller('StorePulseController', ['$http', '$scope', '$rootScope', '$routeParams', '$window', '$sce', 'AblyService', '$route',
	'Config', 'DataService', 'FormService', '$mdDialog', '$timeout', 'ngDialog', '$translate', 'AlertService', '$filter', '$location',
	'CONSTANTS', '$interval', 'FeatureService', 'ExportService', '$q', 'ErrorService', 'SocialShareService', 'ValidationService',
	function ($http, $scope, $rootScope, $routeParams, $window, $sce, AblyService, $route,
	Config, DataService, FormService, $mdDialog, $timeout, ngDialog, $translate, AlertService, $filter, $location,
	CONSTANTS, $interval, FeatureService, ExportService, $q, ErrorService, SocialShareService, ValidationService) {

		$scope.showWatermark = true;
		$scope.pulseDemographicFilters = true;
		$scope.allDemographics = {selected: true};

		$scope.pulseDemographicFiltersCallback = function (event) {

			if ($scope.pulseDemographicFilters = true) {
				$scope.pulseDemographicFilters = !$scope.pulseDemographicFilters;
			}
			else {
				$scope.pulseDemographicFilters = !$scope.pulseDemographicFilters;
			}

			event.preventDefault();
		};

		//Currently logged in user
		$scope.user = DataService.grab('user');

		//use ID to retrieve the data_token
		var data_token = findTokenById(DataService.grab('cataloguePulses'), $routeParams.id);

		$scope.pulse_data_token = data_token;
		$scope.isTranslated = false;
		$scope.translateArabic = false;
		$scope.saveState = 'pulse.saved_label';
		$scope.newQuestionId = null;
		$scope.disableNewQuestionAnimation = false;
		$scope.newQuestionHeaderVisible = false;
		$scope.deletingPulse = false;

		//Name of the default group for a pulse - must be an actual group name
		$scope.defaultPulseGroupName = 'OnePulse';

		//Controls class toggle for Thank You message star rating
		$scope.starOn = false;

		//Control for repulsed pulse
		$scope.repulsed = false;

		//Control for repulsed answer
		$scope.repulsedAnswer = false;

		//Control & ID for Youtube vid
		$scope.youTubePresent = false;
		$scope.youTubeId = null;

		//the active element in the nav list
		$scope.isActive = 'pulse';
		$scope.linkType = "Type";
		$scope.questionFormVisible = false;
		$scope.addingQuestion = false;

		//Selected Branch on load
		$scope.selectedBranch = 1;

		$scope.showIntroLink = false;
		$scope.showThankYouLink = false;

		$scope.currentFilterCategory = null;
		$scope.currentFilters = [];

		$scope.pulseUniqueResponses = [];

		$scope.pulse = {};

		//Show selected regions for the pulse
		$scope.regions = {showRegions: false};

		//Show the region selection div - only visible if a client has access to 2 or more regions
		$scope.showPulseRegions = false;

		// store the interval promise for the running pulse duration
		$scope.runningPulseDurationpromise = null;

		//Control var for pulse controls toggle (default: show if on desktop)
		$scope.controls = {show: ($rootScope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP)};

		//Control var for response avatar slider
		$scope.responseControl = {show: false};

		//Has the live edit warning for pulse edits been displayed yet?
		$rootScope.displayedEditWarning = false;

		//Get device type - mobile/desktop
		$scope.deviceType = determineDeviceType(getViewportDimensions());

		//Config var for avatar carousel - number of slides to show (default: desktop = 5 / mobile = 3)
		$scope.slidesToShow = $scope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP ? 5 : 3;

		//Pulse preview display name
		$scope.preview = {displayName: ""};

		$scope.slickConfigPulse = {
			enabled: true,
			autoplay: false,
			draggable: false,
			autoplaySpeed: 3000,
			slidesToShow: $scope.slidesToShow,
			slidesToScroll: 1,
			method: {},
			event: {
				beforeChange: function (event, slick, currentSlide, nextSlide) {
				},
				afterChange: function (event, slick, currentSlide, nextSlide) {
				}
			}
		};

		var translations;

		$translate(['alert.remove_question.text_empty', 'alert.remove_question.text_present', 'alert.remove_question.title',
			'alert.remove_question.confirm_button_text',
			'alert.remove_question.confirm_button_text', 'alert.pulse_gone_live.title', 'alert.pulse_gone_live.text',
			'alert.pulse_gone_live.confirm_button_text', 'alert.pulse_completed.title', 'alert.pulse_completed.text',
			'alert.pulse_completed.confirm_button_text', 'alert.user_responded.title', 'alert.user_responded.confirm_button_text',
			'alert.remove_question_success.title', 'alert.remove_question_success.text', 'alert.pulse_title_absent.text',
			'alert.pulse_title_absent.title', 'alert.pulse_panel_absent.title',
			'alert.pulse_panel_absent.text', 'alert.pulse_close.title', 'alert.pulse_close.text',
			"question.boolean_true", "question.boolean_false", "alert.question_text_absent.text", "alert.question_text_absent.title",
			"alert.question_slider_absent.text", "alert.question_slider_absent.title", "alert.question_has_answer_duplicates.text",
			"alert.question_has_answer_duplicates.title", "alert.question_has_bad_minmax.text", "alert.question_has_bad_minmax.title",
			"alert.question_min_answers.text", "alert.question_min_answers.title",
			"alert.question_slider_min_error.title", "alert.question_slider_min_error.text",
			"alert.question_slider_max_error.title", "alert.question_slider_max_error.text",
			"alert.question_slider_step_error.title", "alert.question_slider_step_error.text",
			"alert.question_slider_negative.title", "alert.question_slider_negative.text",
			"alert.question_slider_modulus_error.title", "alert.question_slider_modulus_error.text",
			"alert.question_has_bad_link.title", "alert.question_has_bad_link.text",
			"alert.intro_has_bad_link.title", "alert.intro_has_bad_link.text",
			"alert.message_has_bad_link.title", "alert.message_has_bad_link.text", "generic.yes",
			"preview.success.title", "preview.success.text"])
			.then(function (result) {
				translations = result;
			});

		$scope.deregisterPulseUpdateHandler = $rootScope.$on('event:pulse-update', function (event, pulse) {

			if (pulse.data.status.value != 'Closed' && $scope.pulse != null) {

				$scope.formatAnimation = null;

				//We need to redefine the question formatText (local value only) when updating the pulse data
				if (typeof pulse.data != 'undefined') {

					for (var i = 0; i < pulse.data.questions.length; i++) {

						if (typeof pulse.data.questions[i] != 'undefined') {

							pulse.data.questions[i].format = findSelectedOption(pulse.data.questions[i].data.format.options).option_token;
							pulse.data.questions[i].formatText = findSelectedOption(pulse.data.questions[i].data.format.options).option.text;

							if (typeof pulse.data.questions[i].data.answers != "undefined") {

								var usedAnswers = $filter('usedAnswers')(pulse.data.questions[i].data.answers);
								pulse.data.questions[i].min_answers = usedAnswers.length > 2 ? usedAnswers.length : 2;
								pulse.data.questions[i].displayAnswers = pulse.data.questions[i].data.answers.slice(0, pulse.data.questions[i].min_answers);

								//Fix for slider answer value removal
								if (pulse.data.questions[i].data.id.value == $rootScope.questionId &&
									(pulse.data.questions[i].formatText != 'Yes/No' && pulse.data.questions[i].formatText != 'Slider') &&
									(typeof $rootScope.answerTexts != "undefined" && $rootScope.answerTexts != null && $rootScope.answerTexts.length != 0)) {

									for(var j = 0; j < pulse.data.questions[i].data.answers.length; j++) {

										pulse.data.questions[i].data.answers[j].data.text.value = $rootScope.answerTexts[j];
									}

									$rootScope.answerTexts = [];
									$rootScope.questionId = null;
								}
							}
						}
					}
				}

				$scope.pulse = pulse;

				$scope.checkQuestionLinks();

				$scope.populatePotentialParticipants();
			}
		});

		$scope.$on('event:pulse-saving', function (event, pulse) {

			$scope.saveState = 'pulse.saving_label';

			$timeout(function () {
				$scope.saveState = 'pulse.saved_label';
			}, 1500);
		});

		$scope.$on('event:question-edited', function (event) {

			$scope.updatePulseImage();
			$scope.savePulse($scope.pulse, false);
		});

		$scope.$on('$destroy', function() {

			//De-register all root scope event handlers when pulse controller scope is destroyed
			if(typeof $scope.deregisterPulseUpdateHandler != "undefined") {$scope.deregisterPulseUpdateHandler()}
			if(typeof $scope.deregisterGroupUpdateHandler != "undefined") {$scope.deregisterGroupUpdateHandler()}
			if(typeof $scope.deregisterDemographicsUpdateHandler != "undefined") {$scope.deregisterDemographicsUpdateHandler()}
			if(typeof $scope.deregisterRenderCompleteHandler != "undefined") {$scope.deregisterRenderCompleteHandler()}
			if(typeof $scope.deregisterLivePulseEditHandler != "undefined") {$scope.deregisterLivePulseEditHandler()}
		});

		//Leave pulse channel and save pulse on location change.
		$scope.$on('$locationChangeStart', function (event, next, current) {

			$scope.deletingPulse = false;
			$scope.pulse = null;
		});

		$scope.isActiveBranch = function (branch) {

			return $scope.selectedBranch == branch;
		};

		$scope.deregisterGroupUpdateHandler = $rootScope.$on('event:group-updated', function(event, group) {

			$scope.group = group;
		});

		$scope.deregisterDemographicsUpdateHandler = $rootScope.$on('event:demographics-updated', function(event, demographics) {

			$scope.demographics = demographics;
		});

		//Event handler for 417 error code (Pulse edit not enabled)
		$scope.deregisterLivePulseEditHandler = $rootScope.$on('event:417-pulse-update', function (event) {

			liveEditWarningModalController.$inject = ['$scope', '$mdDialog', 'pulse', 'DataService', 'savePulse'];

			//Call the browser warning modal
			$mdDialog.show({
				templateUrl: 'app/views/elements/includes/edit-pulse-warning.html',
				controller: liveEditWarningModalController,
				parent: angular.element(document.body),
				clickOutsideToClose: false,
				locals: {
					pulse: $scope.pulse,
					DataService: DataService,
					savePulse: $scope.savePulse
				}
			});

		});

		//Saves pulse in active/scheduled state and resets all edit control vars to false
		$scope.liveEditSave = function(notify) {

			$scope.editPulseTitle = false;
			$scope.editThankYouMessage = false;
			$scope.editIntroMessage = false;
			$scope.savePulse($scope.pulse, notify, false, true);
		};

		$scope.removeArabic = function () {

			$scope.isTranslated = false;
			$scope.translateArabic = false;
		};

		//Select default group for pulse if no group is already selected
		$scope.defaultGroupSelection = function() {

			if(typeof $scope.group == "undefined") {

				for(var i = 0; i < $scope.pulse.data.group.options.length; i++) {

					if($scope.pulse.data.group.options[i].option.text == $scope.defaultPulseGroupName) {

						$scope.pulse.data.group.options[i].option.selected = true;
						$scope.group = $scope.pulse.data.group.options[i];
					}
				}
			}
		};

		$scope.resultShareable = function () {

			$scope.pulse.data.is_result_shareable.value = $scope.isResultShareable.toString();
		};

		$scope.brandShareable = function () {

			$scope.pulse.data.is_brand_shareable.value = $scope.isBrandShareable.toString();
		};

		$scope.hasImage = function (imagePath) {

			var imagePathArray = imagePath.split("/");

			return Config.get('EMPTY_IMAGE_TOKEN') != imagePathArray[5];
		};

		$scope.showQuestion = function (question) {

			if ($scope.pulse.data.is_branched.value == '1') {

				if (question.data.branch != undefined) {
					if (question.data.branch.value == $scope.selectedBranch) {
						return true;
					} else if (question.data.sequence.value = 1) {
						return true;
					} else {
						return false;
					}
				} else {
					return true;
				}

			} else {
				return true;
			}
		};

		//Remove any erroneous sequence 1 questions. Should only ever be 1
		$scope.checkSequences = function() {

			var sequenceOne = 0;

			var _loop = function _loop(j) {

				if ($scope.pulse.data.questions[j].data.sequence.value == '1' || $scope.pulse.data.questions[j].data.sequence.value == 1) {

					sequenceOne++;
				}

				if (sequenceOne >= 2) {

					DataService.remove('pulse/question', $scope.pulse.data.questions[j].data_token).then(function () {

						$scope.removeQuestionFromPulse($scope.pulse.data.questions[j], true);
					});
				}
			};

			for (var j = 0; j < $scope.pulse.data.questions.length; j++) {
				_loop(j);
			}
		};

		//Return the current sequence for the currently selected branch
		$scope.checkCurrentBranchSequence = function () {

			var currentBranchSequence = 0;

			for (var j = 0; j < $scope.pulse.data.questions.length; j++) {

				if (typeof $scope.pulse.data.questions[j].data.branch != "undefined") {

					if ($scope.pulse.data.questions[j].data.branch.value == $scope.selectedBranch) {

						var val = parseInt($scope.pulse.data.questions[j].data.sequence.value);

						currentBranchSequence = val > currentBranchSequence ? val : currentBranchSequence;
					}
				}
			}

			return currentBranchSequence;
		};

		$scope.backCallback = function ($event) {

			$window.history.back();
			$event.preventDefault();
		};

		//Changes branch
		$scope.selectBranchCallback = function (event, branch) {

			$scope.newQuestionHeaderVisible = false;
			$scope.selectedBranch = branch;

			if ($scope.checkCurrentBranchSequence() < 3 && $scope.pulse.data.is_branched.value == "1") {

				$scope.showQuestionHeader();
			}

			event.preventDefault();
		};

		//Checks if question can be branched
		$scope.canBeBranched = function () {

			var question = $scope.pulse.data.questions[0];

			//Only allow branching from question 1, if not already branched and if question has 2 answers
			return (question.data.sequence.value == "1" &&
			$scope.pulse.data.is_branched.value != "1" &&
			(filledAnswers(question.data.answers).length == 2));
		};

		//Only display question in pulse editor on branch type and branched question
		$scope.questionBranchFilter = function (question) {

			if (typeof question.data.branch != "undefined") {

				return (question.data.branch.value == $scope.selectedBranch || question.data.branch.value == "");

			} else {

				return true;
			}
		};

		/*
		 MM: Force edit state with edit action - this is used from the dashboard

		 */
		$scope.isEdit = function () {
			if ($scope.pulse.data.status.value == 'Draft' || ($routeParams.action == 'edit' && $scope.pulse.data.status.value != 'Closed')) {
				return true;
			}
			return false;
		};

		$scope.getAnswerTypeClass = function (answerType) {
			var classVar = null;

			switch (answerType) {
				case "Exclusive":
					classVar = "mdi-format-list-checks";
					break;
				case "Multiple":
					classVar = "mdi-format-list-bulleted";
					break;
				case "Slider":
					classVar = "mdi-tune";
					break;
				case "Drag & Drop":
					classVar = "mdi-format-line-spacing";
					break;
				case "Yes/No":
					classVar = "mdi-set-left";
					break;
				case "Image":
					classVar = "mdi-image-filter-black-white";
					break;
				case "Branching":
					classVar = "mdi-arrow-decision";
					break;
				case "Free Text":
					classVar = "mdi-format-color-text";
					break;
			}

			return classVar;
		};

		$scope.getQuestionTypeClass = function (questionType) {
			var classVar = null;

			switch (questionType) {
				case "Exclusive":
					classVar = "mdi-format-list-checks";
					break;
				case "Multiple":
					classVar = "mdi-format-list-bulleted";
					break;
				case "Slider":
					classVar = "mdi-tune";
					break;
				case "Drag & Drop":
					classVar = "mdi-format-line-spacing";
					break;
				case "Yes/No":
					classVar = "mdi-set-left";
					break;
				case "Image":
					classVar = "mdi-image-filter-black-white";
					break;
				case "Branching":
					classVar = "mdi-arrow-decision";
					break;
				case "Free Text":
					classVar = "mdi-format-color-text";
					break;
			}
			return classVar;
		};

		$scope.getControlTypeTooltip = function (questionType) {
			var classVar = null;

			switch (questionType) {
				case 'Exclusive':
					classVar = 'Exclusive Answer';
					break;
				case 'Multiple':
					classVar = 'Multiple Answer';
					break;
				case 'Slider':
					classVar = 'Slider Answer';
					break;
				case 'Drag & Drop':
					classVar = 'Drag & Drop Answer';
					break;
				case 'Yes/No':
					classVar = 'Yes/No Answer';
					break;
				case 'Image':
					classVar = 'Image Answer';
					break;
				case 'Branching':
					classVar = 'Branching Answer';
					break;
				case 'Free Text':
					classVar = 'Free Text Answer';
					break;
			}

			return classVar;
		};

		$scope.isItActive = function ($id) {
			return $id == $scope.isActive;
		};

		// Setters to use from child $scopes
		$scope.setTopAnimation = function (val) {
			$timeout(function () {
				$scope.disableNewQuestionAnimation = !val;
			}, 10);
		};

		$scope.setFormatAnimation = function (value) {
			$timeout(function () {
				$scope.formatAnimation = value;
			}, 120);
		};

		$scope.purchasePulseModal = function () {

			$mdDialog.show({
				controller: 'PurchasePulseController',
				templateUrl: 'app/views/elements/includes/purchase_pulse.html',
				parent: angular.element(document.body),
				clickOutsideToClose: false,
				scope: $scope,
				preserveScope: true
			})
				.then(function (answer) {
					$scope.status = 'You said the information was "' + answer + '".';
				}, function () {
					$scope.status = 'You cancelled the dialog.';
				});
		};

		$scope.returnToPulseList = function () {

			$location.path('/pulses');
		};

		$scope.filledAnswers = filledAnswers;

		$scope.anyDemographicsSelected = function() {

			if(typeof $scope.demographics != "undefined") {

				for(var i = 0; i < $scope.demographics.length; i++) {

					if ($scope.isSelected($scope.demographics[i])) {

						return true;
					}
				}

				return false;
			}
		};


		$scope.demographicColors = function() {

			if(typeof $scope.demographics != "undefined") {

				for(var i = 0; i < $scope.demographics.length; i++) {

					if($scope.isSelected($scope.demographics[i])) {

						var colorCount = 0;

						//var shadesRequired = 4;
						var base = 0;
						//var percToAddPerIteration = (100 / shadesRequired) / 100;
						var percToAddPerIteration = 0.20;

						for(var j = 0; j < $scope.demographics[i].values.options.length; j++) {

							//if($scope.demographics[i].values.options[j].option.selected) {

								$scope.demographics[i].values.options[j].option.color = shadeColor(CONSTANTS.PULSE_CARD_COLORS[colorCount], base);

								base = base + percToAddPerIteration;

								if(base == 0.40) {

									if(CONSTANTS.PULSE_CARD_COLORS.length - 1 > colorCount) {

										colorCount++;
									} else {

										colorCount = 0;
									}

									base = 0;
								}
							//}
						}
					}
				}
			}
		};

		$scope.zeroPercent = function(option) {

			return $scope.currentFilters.indexOf(option.option.text) != -1;
		};

		//Update the user
		$scope.updateUser = function() {

			DataService.submit('user', $scope.user).then(function (result) {

				$scope.user = result.user;
			});
		};

		//Check if any demographics for a particular category are selected
		$scope.isSelected = function(demographic){

			if(typeof demographic != "undefined") {

				var selected = false;

				for(var index = 0; index < demographic.values.options.length; index++) {

					if(demographic.values.options[index].option.selected){

						selected = true;
					}
				}

				return selected;
			}
		};

		$scope.calculatePulseTimeSinceStart = function () {

			if (typeof $scope.pulse.data.published_at != "undefined" && $scope.pulse.data.published_at.value != null) {

				var currentDate = formatDate(new Date(), new Date());
				var publishedAt = ((new Date($scope.pulse.data.published_at.value) < new Date($scope.pulse.data.started_at.value) || $scope.pulse.data.published_at.value.charAt(0) == '-') ? $scope.pulse.data.started_at.value : $scope.pulse.data.published_at.value);

				if($scope.pulse.data.status.value == "Closed") {

					$scope.liveFor = dateDiff(publishedAt, $scope.pulse.data.ended_at.value);
				} else {

					$scope.liveFor = dateDiff(publishedAt, currentDate);
				}

				//Set the form of the word
				$scope.daysForm = $scope.liveFor.d == 1 ? "day" : "days";
				$scope.hoursForm = $scope.liveFor.h == 1 ? "hour" : "hours";
				$scope.minutesForm = $scope.liveFor.m == 1 ? "minute" : "minutes";
				$scope.secondsForm = $scope.liveFor.s == 1 ? "second" : "seconds";
			}
		};

		$scope.processPulseStartAndCloseDateTimes = function () {

			if (typeof $scope.pulse.data.published_at != "undefined" && $scope.pulse.data.published_at.value != null) {

				var publishedAt = new Date((new Date($scope.pulse.data.published_at.value) < new Date($scope.pulse.data.started_at.value) || $scope.pulse.data.published_at.value.charAt(0) == '-') ? $scope.pulse.data.started_at.value : $scope.pulse.data.published_at.value);

				$scope.pulseStartedDate = prettyDate(publishedAt, false, false);
				$scope.pulseStartedTime = formatTime(publishedAt, false);
				$scope.pulseStartedDateTime = simpleDate(publishedAt) + ' at ' + formatTime(publishedAt, true);
			}

			if (typeof $scope.pulse.data.ended_at != "undefined") {

				var ended_at = new Date($scope.pulse.data.ended_at.value);

				$scope.pulseClosedDate = prettyDate(ended_at, false, false);
				$scope.pulseClosedTime = formatTime(ended_at, false);
				$scope.pulseClosedDateTime = simpleDate(ended_at) + ' at ' + formatTime(ended_at, true);
			}

			if (typeof $scope.pulse.data.expired_at != "undefined") {

				var expired_at = new Date($scope.pulse.data.expired_at.value);

				$scope.pulseExpiresDate = prettyDate(expired_at, false, false);
				$scope.pulseExpiresTime = formatTime(expired_at, false);
				$scope.pulseExpiresDateTime = simpleDate(expired_at) + ' at ' + formatTime(expired_at, true);
			}
		};

		var getAllQuestionComments = function() {

			var _loop = function _loop(i) {

				DataService.getQuestionComments($scope.pulse.data.questions[i].data_token).then(function (result) {

					$scope.pulse.data.questions[i].comments = result;
					var mostPositiveSentimentScore = 0.00;
					var mostPositiveSentimentComment = null;
					var mostNegativeSentimentScore = 0.00;
					var mostNegativeSentimentComment = null;

					for(var j = 0; j < $scope.pulse.data.questions[i].comments.data.length; j++) {

						var sentimentScore = parseFloat(parseFloat($scope.pulse.data.questions[i].comments.data[j].sentiment_score.value).toFixed(2));

						if(mostPositiveSentimentComment == null) {

							mostPositiveSentimentComment = $scope.pulse.data.questions[i].comments.data[j];
							mostPositiveSentimentScore = sentimentScore;
						}

						if(mostNegativeSentimentComment == null) {

							mostNegativeSentimentComment = $scope.pulse.data.questions[i].comments.data[j];
							mostNegativeSentimentScore = sentimentScore;
						}

						//if(sentimentScore >= 0.00) {

							mostPositiveSentimentComment = sentimentScore > mostPositiveSentimentScore ? $scope.pulse.data.questions[i].comments.data[j] : mostPositiveSentimentComment;
							mostPositiveSentimentScore = sentimentScore > mostPositiveSentimentScore ? sentimentScore : mostPositiveSentimentScore;

						//} else {

							mostNegativeSentimentComment = sentimentScore < mostNegativeSentimentScore ? $scope.pulse.data.questions[i].comments.data[j] : mostNegativeSentimentComment;
							mostNegativeSentimentScore = sentimentScore < mostNegativeSentimentScore ? sentimentScore : mostNegativeSentimentScore;
						//}
					}

					$scope.pulse.data.questions[i].comments.mostPositiveSentimentScore = parseFloat(parseFloat(mostPositiveSentimentScore).toFixed(2));
					$scope.pulse.data.questions[i].comments.mostPositiveSentimentComment = mostPositiveSentimentComment;
					$scope.pulse.data.questions[i].comments.mostNegativeSentimentScore = parseFloat(parseFloat(mostNegativeSentimentScore).toFixed(2));
					$scope.pulse.data.questions[i].comments.mostNegativeSentimentComment = mostNegativeSentimentComment;
				});
			};

			for (var i = 0; i < $scope.pulse.data.questions.length; i++) {
				_loop(i);
			}
		};

		var demographicViewControls = function() {

			for(var i = 0; i < $scope.demographics.length; i++) {

				$scope.demographics[i].showCategory = ($rootScope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP);
			}
		};

		//Initialise pulse data
		$scope.init = function(pulse) {

			$scope.pulse = pulse;

			//Toggles for thank you message, intro card & link toggle (intro card)
			$scope.addThankYouMessage = $scope.pulse.data.message.value != "";
			$scope.addIntroCard = ($scope.pulse.data.intro.value != "" || $scope.pulse.data.intro_image.value != "");
			$scope.showIntroLink = $scope.pulse.data.link_url.value != "";
			$scope.showThankYouLink = $scope.pulse.data.message_url.value != "";

			$scope.questionBeingEdited = [];
			$scope.originalValues = [];

			$scope.group = findSelectedOption($scope.pulse.data.group.options);
			$scope.demographics = $scope.pulse.data.demographics;

			if($scope.pulse.data.status.value != 'Draft') {

				$scope.demographicColors();
			}

			$scope.defaultGroupSelection();

			if (typeof $scope.pulse.data.is_result_shareable != "undefined") {

				$scope.isResultShareable = parseInt($scope.pulse.data.is_result_shareable.value);
			}

			if (typeof $scope.pulse.data.is_brand_shareable != "undefined") {

				$scope.isBrandShareable = parseInt($scope.pulse.data.is_brand_shareable.value);
			}

			//check for existence of parent pulse - Only for repulsed pulses
			if (typeof $scope.pulse.data.parent_pulse != "undefined") {

				$scope.repulsed = true;
			}

			//check for existence of parent answer - Only for repulsed answers
			if (typeof $scope.pulse.data.parent_answer != "undefined") {

				$scope.repulsedAnswer = true;
			}

			demographicViewControls();

			$scope.calculatePulseTimeSinceStart();

			$scope.processPulseStartAndCloseDateTimes();

			if($scope.pulse.data.status.value != 'Draft') {

				var selectedRegionCount = 0;

				for(var i = 0; i < $scope.pulse.data.regions.options.length; i++) {

					if($scope.pulse.data.regions.options[i].option.selected) {

						selectedRegionCount++;
						$scope.pulse.data.regions.options[i].option.filterBy = true;
					} else {

						$scope.pulse.data.regions.options[i].option.filterBy = false;
					}

					$scope.showPulseRegions = (selectedRegionCount >= 2);

					$scope.regions.showRegions = $scope.showPulseRegions;
				}
			}

			getAllQuestionComments();
		};

		//Pre-initialisation - Determine if pulse has just been created or if we are pulling an existing pulse
		$scope.preInit = function() {

			if($rootScope.createdPulse != null) {

				$scope.init($rootScope.createdPulse);
				$rootScope.createdPulse = null;
			} else {

				DataService.fetchItem('catalogue/pulse', data_token).then(function (result) {

					$scope.init(result.pulse);

				}, function () {

					$scope.pulse = undefined;
				});
			}
		};

		$scope.preInit();

}]);