/**
 * Created by Barry on 10/11/17.
 */
onePulse.factory('ForexService', ['Config', '$rootScope', '$q', 'DataService', function(Config, $rootScope, $q, DataService){

    var currency = {
        "code": null,
        "symbol": null,
        "baseMultiplier": 1
    };

    var setCurrency = function(countryCode) {

        currency.code = currencyCountryMap[countryCode];
        currency.symbol = currencySymbolMap[currency.code];

        return currency;
    };

    var getLocalCurrency = function() {

        var deferred = $q.defer();

        if(typeof $rootScope.country != "undefined") {

            currency.code = currencyCountryMap[$rootScope.country];
            currency.symbol = currencySymbolMap[currency.code];

            deferred.resolve(currency);
        } else {

            $.get("https://ipinfo.io", function(response) {

                $rootScope.country = response.country;

                currency.code = currencyCountryMap[$rootScope.country];
                currency.symbol = currencySymbolMap[currency.code];

                deferred.resolve(currency);

            }, "jsonp").fail(function() {

                deferred.resolve();
            });
        }

        return deferred.promise;
    };

    var getExchangeRate = function(forexFrom, forexTo) {

        DataService.getForex(forexFrom).then(function (resp) {

            currency.baseMultiplier = resp.rates[forexTo];
        });
    };

    return{
        setCurrency: setCurrency,
        getLocalCurrency : getLocalCurrency,
        getExchangeRate: getExchangeRate
    }
}]);