/**
 * Created by Barry on 2018/05/25.
 *
 * Pulse order menu
 */

onePulse.directive('pulseSwitchMenu', [
    function () {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/pulses/pulseSwitchMenu.html',

            link: function postLink(scope, element, iAttrs) {

            }
        };
    }]);