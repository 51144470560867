/**
 * Created by Barry on 2018/10/29.
 *
 */

onePulse.directive('checkoutDash', [
    function () {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/dashboard/checkout.html',

            link: function postLink(scope, element, iAttrs) {

            }
        };
    }]);