/**
 * Created by mike on 03/02/16.
 */

onePulse.directive('questionTranslationForm', ['FormService', function(FormService) {

    var $service = FormService;

    linkFunction = function($scope,element,attributes) {

        /*
         Put the question data through the FormService and get all the rules and fields
         */
        $scope.form = FormService.parseData($scope.question.data)
    }

    return {
        link:linkFunction,
        templateUrl:'/app/views/elements/forms/question-translation.html'
    }
}]);