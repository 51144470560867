/**
 * Created by Barry on 30/03/17.
 */
onePulse.factory('TokenService', ['Config', '$window', 'jwtHelper', function(Config, $window, jwtHelper){

    var getAccessToken = function(){

        if ($window.localStorage.access_token) {

            return $window.localStorage.access_token;

        }

        return Config.get('OP_APPLICATION_TOKEN');
    };

    var setAccessToken = function(accessToken){

        $window.localStorage['access_token'] = accessToken;
    };

    var isTokenExpired = function(token) {

        return jwtHelper.isTokenExpired(token);
    };

    var getTokenExpirationDate = function(token) {

        return jwtHelper.getTokenExpirationDate(token);
    };

    var decodeToken = function(token) {

        return jwtHelper.decodeToken(token);
    };

    var resetAccessToken = function() {

        $window.localStorage['access_token'] = Config.get('OP_APPLICATION_TOKEN');
    };

    return{
        getAccessToken : getAccessToken,
        setAccessToken: setAccessToken,
        isTokenExpired: isTokenExpired,
        getTokenExpirationDate: getTokenExpirationDate,
        decodeToken: decodeToken,
        resetAccessToken: resetAccessToken
    }
}]);