/**
 * Created by Barry on 2017/03/27.
 */

onePulse.directive('ngText', [function () {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: {
            textXAxis: '=',
            textYAxis: '=',
            textFontFamily: '=',
            textFontSize: '=',
            textContent: '='
        },
        templateUrl: 'app/views/elements/svg/text.html',
        templateNamespace: 'svg'
    };
}]);
