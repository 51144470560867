/**
 * Created by Barry on 26/09/16.
 *
 * Directive for displaying an insight card
 */
onePulse.directive('insight', ['DataService', '$location', function(DataService, $location) {

    linkFunction = function($scope,element,attributes) {

        //Config options
        var totalInsights = 3; //Still to be introduced

        $scope.Math = window.Math;

        $scope.insights = {};

        if($location.url() == '/') {

            //Grab insight data from meta service
            DataService.fetchMetaData('insights').then(function(response) {

                $scope.insights = response.insights;
            });
        } else {
            DataService.fetchClientInsights().then(function(response) {

                $scope.insights = response.insights;
            })
        }
    };

    return {
        restrict: 'E',
        priority: 0,
        replace: false,
        scope: true,
        link:linkFunction,
        templateUrl:'/app/views/elements/insight.html'
    }
}]);
