onePulse.controller('StorePulsesController', ['$http', '$scope', '$location', '$rootScope', 'DataService', '$route',
	'FeatureService', 'CONSTANTS', '$window', '$timeout', '$mdDialog', 'SearchService',
	function ($http, $scope, $location, $rootScope, DataService, $route,
			  FeatureService, CONSTANTS, $window, $timeout, $mdDialog, SearchService) {

	$scope.user = DataService.grab("user");
	$scope.selected = "All";

	$scope.search = {search: "", "pulse": "", "question": "", "status": "", "type": "full_text"};

	$scope.ctrl = {
		"orderBy": "pulse.published_at",
		"orderDirection": "desc"
	};

	$scope.sort = {};
	$scope.sort.field = $scope.ctrl.orderBy;
	$scope.sort.order = $scope.ctrl.orderDirection;

	//Search status for store pulses will always only include closed pulses
	$scope.search.status = "Closed";

	$scope.noSearchResults = false;

	//Get device type - mobile/desktop
	$scope.deviceType = determineDeviceType(getViewportDimensions());

	//Control for infinite scroll - to prevent multiple calls
	$scope.loadingMore = false;

	$scope.allowType = function(showModal) {

        //Removed feature check for pulse store: http://mantis.onepulse.com/view.php?id=3198
		//return FeatureService.allowed(CONSTANTS.FEATURES.ONEPULSE_STORE, showModal);

		return true;
	};

	angular.extend($scope.search, $location.search());

	var setRegions = function(pulses) {

		for(var j = 0; j < pulses.length; j++) {

			pulses[j].data.selectedRegionsCount = 0;
			pulses[j].data.selectedRegionsString = "";
			pulses[j].data.firstSelectedRegion = null;

			for (var i = 0; i < pulses[j].data.regions.options.length; i++) {

				if (pulses[j].data.regions.options[i].option.selected == true) {

					pulses[j].data.selectedRegionsCount++;
					pulses[j].data.selectedRegionsString = pulses[j].data.selectedRegionsString + pulses[j].data.regions.options[i].option.text + ", ";

					if (pulses[j].data.firstSelectedRegion == null) {

						pulses[j].data.firstSelectedRegion = pulses[j].data.regions.options[i];
					}
				}
			}

			//Remove trailing comma and space
			pulses[j].data.selectedRegionsString = pulses[j].data.selectedRegionsString.substring(0, pulses[j].data.selectedRegionsString.length - 2);
		}

		return pulses;
	};

	var setScope = function (pulses) {

		if (pulses !== undefined) {

			$scope.pulses = setRegions(pulses);

			$scope.originalset_pulses = $scope.pulses;

			$timeout(function() {

				initPulsesCardSlideUp();
			}, 2000);
		}
	};

	var determinePulseDiscount = function() {

		$scope.storeDiscount = FeatureService.checkMeta(CONSTANTS.FEATURES.STORE_DISCOUNT);
	};

	determinePulseDiscount();

	//If enter key is pressed in search field
	$scope.enterOnSearch = function(keyEvent) {

		if($scope.search.search.length > 2) {

			$rootScope.currentSearch = $scope.search.search;
			$scope.searchPulses();
		} else if($scope.search.search.length == 0) {

			$scope.loadPulses($scope.selected);
		}
	};

	$scope.orderBy = function() {

		$scope.sort = {};
		$scope.sort.field = $scope.ctrl.orderBy;
		$scope.sort.order = $scope.ctrl.orderDirection;

		SearchService.performSearch("es", "pulses/catalogue", $scope.search, null, $scope.sort, true, true).then(function (result) {

			$scope.pulses = result.pulses;
		})
	};

	$scope.determineDiscountedPrice = function(price, discount) {

		return parseFloat(parseFloat(price) - (parseFloat(price) * (discount / 100))).toFixed(0);
	};

	$scope.setSelected = function(selected) {

		$scope.selected = selected;
	};

	$scope.getPageNumber = function(num) {

		return new Array(num);
	};

	//Handle filtering of pulses based on state
	$scope.loadPulses = function(status){

        $scope.search.search = "";
        $rootScope.currentSearch = "";

        init();
	};

	$scope.setPulseView = function(type) {

		$rootScope.pulseView = type;
		$window.localStorage['pulse_view'] = type;

	};

	//Update scope after pagination/search set change
	$scope.$on('event:pulses-page-change', function (event, result) {

		$scope.pulses = result.pulses;
		$scope.pagination = result.pagination;
	});

	//Request pulses that match search string
	$scope.searchPulses = function () {

        SearchService.performSearch("es", "pulses/catalogue", $scope.search, null, $scope.sort, true, true).then(function (result) {

			$rootScope.$broadcast('event:pulses-page-change', angular.fromJson(result));
		});
	};

	//If enter key is pressed in search field
        //Run a new search
        $scope.runSearch = function(event) {

            $rootScope.currentSearch = $scope.search.search;
            $scope.searchPulses();
        };

	//Infinite Scroll
	$scope.loadMore = function() {

		if(typeof $scope.pagination != "undefined") {

			//If haven't already pulled down the last page of pulses and not already calling, then do so
			if ($scope.pagination.current_page != $scope.pagination.last_page && !$scope.loadingMore) {

				$scope.loadingMore = true;

                SearchService.performSearch("es", "pulses/catalogue", $scope.search, ($scope.pagination.current_page + 1), $scope.sort, true, true).then(function (result) {

					var parsedResult = angular.fromJson(result);

					parsedResult.pulses = setRegions(parsedResult.pulses);

					for (var i = 0; i < parsedResult.pulses.length; i++) {

						$scope.pulses.push(parsedResult.pulses[i]);
					}

					if ($scope.selected != "All" && $scope.selected != "Archived") {

						$scope.pulses = filterPulses($scope.pulses, $scope.selected);
					}

					$scope.pagination = parsedResult.pagination;

					$scope.loadingMore = false;
				});
			}
		}
	};

	$scope.editPulse = function(pulseId) {

		$location.path('/store/pulse/' + pulseId + '/edit');
	};

	$scope.calculateRatingWidth = calculateRatingWidth;

	$scope.closeDialog = function() {

		$mdDialog.hide();
	};

	//Remove a pulse from scope by data_token
	var removePulse = function(pulses, data_token) {

		for(var i = 0; i < pulses.length; i++) {

			if(pulses[i].data_token === data_token) {

				pulses.splice(i, 1);
				$route.reload();
			}
		}

		return pulses;
	};

	var init = function() {

		var remainingPulses = [];

		$scope.search.search = "";

		SearchService.performSearch("es", "pulses/catalogue", $scope.search, null, $scope.sort, true, true).then(function (result) {

			$scope.allPulses = angular.fromJson(result).pulses;
			$scope.pagination = angular.fromJson(result).pagination;

			DataService.fetch('pulses/catalogue').then(function (result2) {

				$scope.purchasedPulses = angular.fromJson(result2).purchased_pulses;

				for(var i = 0; i < $scope.allPulses.length; i++) {

					var doesExist = false;

					for(var j = 0; j < $scope.purchasedPulses.length; j++) {

						if($scope.allPulses[i].data.id.value == $scope.purchasedPulses[j].data.id.value) {

							doesExist = true;
							break;
						}
					}

					if(!doesExist) {

						remainingPulses.push($scope.allPulses[i]);
					}
				}

				setScope(remainingPulses)
			});

		}, function () {

			$scope.pulses = undefined;
		});
	};

	init();
}]);
