onePulse.directive('questionResultsColumnchart', ['$filter', '$compile', '$sce', 'DataService', '$rootScope',
    'googleChartApiPromise', '$mdDialog', 'FeatureService', 'CONSTANTS', '$timeout', 'ExportService',
    function($filter, $compile, $sce, DataService, $rootScope,
             googleChartApiPromise, $mdDialog, FeatureService, CONSTANTS, $timeout, ExportService) {

        linkFunction = function ($scope) {

            //Determine question format
            if(typeof $scope.question.data.format.options != 'undefined') {

                for (var i = 0; i < $scope.question.data.format.options.length; i++) {

                    if ($scope.question.data.format.options[i].option.selected == true) {

                        $scope.question.formatText = $scope.question.data.format.options[i].option.text;
                    }
                }
            } else {

                $scope.question.formatText = $scope.question.data.format.value;
            }

            //Only define link function if question format is correct
            if ($scope.question.formatText == 'Slider') {

                $scope.loaded = false;

                //Mike's band
                $scope.fadedHalfHead = false;
                $scope.headCount = 0;

                $scope.validAnswerCount = 1;

                /*For the overlay directive*/
                $scope.labels = {};

                $scope.annotations = {};

                $scope.filteredAnnotations = {};

                $scope.showBarchart = false;
                $scope.showColumnchart = true;

                /*
                 Filtered 3 series: Teal filtered value,Dark Grey total, light grey shadow bar (might be a better way to create a shadow than the extra series)
                 */
                $scope.series = {
                    0: {color: $rootScope.wlClient != null && $rootScope.wlPrimaryColor != null ? $rootScope.wlPrimaryColor : '#4a4a4a'},
                    1: {color: '#50E3C2'}
                };

                $scope.filteredSeries = {
                    0: {color: '#015798'}, 1: {color: '#42A2F2'}, 2: {color: '#01695C'}, 3: {color: '#850D4F'},
                    4: {color: '#E61D63'}, 5: {color: '#FF5721'}, 6: {color: '#FFA800'}, 7: {color: '#FBD202'}
                };

                $scope.animation = {
                    duration: 800,
                    easing: 'linear',
                    startup: true
                };

                /*
                 To style the pop up data table
                 */
                $scope.tableClassNames = {
                    headerRow: "data-table-header",
                    tableRow: "data-table-row",
                    oddTableRow: "data-table-odd-row",
                    selectedTableRow: "data-table-selected-row",
                    hoverTableRow: "data-table-hover-row"
                };

                $scope.originalResults = null;

                var filename = function (ext) {
                    return $scope.result.question.text.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.' + ext;
                };

                //Fetch Question Data
                var getQuestionData = function () {

                    DataService.getQuestionData($scope.question.data_token).then(function (result) {

                        $scope.originalResults = angular.copy(result);
                        buildDemographicsPostPublish(result);
                        buildChart(result);
                    });
                };

                var buildDemographicsPostPublish = function(result) {

                    $timeout(function() {

                        if(typeof $scope.demographicsPostPublish != "undefined") {

                            if($scope.demographicsPostPublish.length == 0) {

                                for(var i = 0; i < result.filters.length; i++) {

                                    $scope.demographicsPostPublish[i] = {};
                                    $scope.demographicsPostPublish[i].text = result.filters[i].title;
                                    $scope.demographicsPostPublish[i].values = {};
                                    $scope.demographicsPostPublish[i].values.options = [];

                                    var valueArray = [];

                                    if(result.filters[i].options != null) {

                                        for (var j = 0; j < result.filters[i].options.length; j++) {

                                            if (result.filters[i].options[j].indexOf(", ") != -1 &&
                                                result.filters[i].title != 'Drinking alcohol' &&
                                                result.filters[i].title != 'Employer sector') {

                                                valueArray = result.filters[i].options[j].split(", ");
                                                valueArray = valueArray.reduce(function (a, b) {
                                                    if (a.indexOf(b) < 0) a.push(b);
                                                    return a;
                                                }, []);
                                            } else {

                                                valueArray.push(result.filters[i].options[j]);
                                            }
                                        }

                                        // var valueArrayUnique = valueArray.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);

                                        for (var k = 0; k < valueArray.length; k++) {

                                            $scope.demographicsPostPublish[i].values.options[k] = {};

                                            $scope.demographicsPostPublish[i].values.options[k].option = {};
                                            $scope.demographicsPostPublish[i].values.options[k].option.text = valueArray[k];
                                            $scope.demographicsPostPublish[i].values.options[k].option.selected = true;
                                        }
                                    }
                                }

                                $rootScope.$broadcast("event:post-dems-loaded", $scope.demographicsPostPublish);
                            }
                        }
                    });
                };

                //Build the chart
                var buildChart = function (result) {

                    $scope.filters = result.filters;
                    $scope.result = result;

                    $scope.allFilterOptions = [];

                    if(typeof $scope.filters != "undefined") {

                        for (var m = 0; m < $scope.filters.length; m++) {

                            if($scope.filters[m].options != null) {

                                for (var n = 0; n < $scope.filters[m].options.length; n++) {

                                    $scope.allFilterOptions.push($scope.filters[m].options[n])
                                }
                            }
                        }
                    }

                    $scope.min = parseInt($scope.question.data.slider_min.value);
                    $scope.max = parseInt($scope.question.data.slider_max.value);
                    $scope.step = parseInt($scope.question.data.slider_step.value);

                    //for (let i = 1; i < result.data_table.length; i++) {
                    //
                    //    result.data_table[i][8] = parseInt(result.data_table[i][8]);
                    //}

                    if($scope.result.question.total_responses > 1) {

                        $scope.showFilterControls = true;
                    }

                    //Total number of unique participants
                    $scope.uniqueIdArray = [];

                    for(var i = 1; i < $scope.result.data_table.length; i++) {

                        $scope.result.data_table[i][12] = $scope.result.data_table[i][12].toString();

                        if($scope.uniqueIdArray.indexOf($scope.result.data_table[i][0]) == -1) {

                            $scope.uniqueIdArray.push($scope.result.data_table[i][0]);
                        }
                    }

                    $scope.question.total_responses_multiple = $scope.uniqueIdArray.length;

                    googleChartApiPromise.then(function () {

                        $scope.dataTable = new google.visualization.arrayToDataTable(result.data_table);

                        //Add dataTable to scoped question for later use. Need to include raw response table too for header
                        $scope.question.dataTable = $scope.dataTable;
                        $scope.question.rawDataTable = result.data_table;

                        //Dynamically add user avatar and response data to results block
                        $scope.$on('event:user_responded', function ($event, $payload) {

                            if($scope.question.data.id.value in $payload.data) {

                                $scope.question.total_responses++;

                                if (typeof $payload.data[$scope.question.data.id.value][0] != "undefined") {

                                    if ($scope.uniqueIdArray.indexOf($payload.data[$scope.question.data.id.value][0][0] == -1)) {

                                        $scope.uniqueIdArray.push($payload.data[$scope.question.data.id.value][0][0]);
                                        $scope.question.total_responses_multiple++;
                                    }

                                    //Add comment to scope
                                    if ($payload.data[$scope.question.data.id.value][0][9] != "") {

                                        var comment = {
                                            "user": $payload.profile,
                                            "text": {
                                                "value": $payload.data[$scope.question.data.id.value][0][9]
                                            }
                                        };

                                        if (typeof $scope.question.comments != "undefined") {

                                            $scope.question.comments.data.push(comment);
                                        }
                                    }
                                }

                                $scope.showFilterControls = true;

                                if (typeof $scope.responseControl != "undefined") {

                                    $scope.responseControl.show = true;
                                }

                        
                                $scope.headCount++;

                                if (typeof $scope.slickConfigPulse != "undefined") {

                                    if ($scope.question.data.sequence.value == 1) {

                                        var firstDem = $payload.data[$scope.question.data.id.value][0][15];
                                        var secondDem = $payload.data[$scope.question.data.id.value][0][16];

                                        var avatarImage = getUserAvatar($payload.profile.avatar, firstDem, secondDem);

                                        var displayData = "<span>" + firstDem +
                                            "<br /> " + secondDem + "</span>";

                                        $scope.slickConfigPulse.method.slickAdd(
                                            "<div class='user-response-item'>" +
                                            "<div class='user-avatar'>" +
                                            "<img src='" + avatarImage + "'/>" +
                                            "</div>" +
                                            "<div class='info'>" +
                                            displayData +
                                            "</div>" +
                                            "</div>");

                                        $scope.slickConfigPulse.method.slickNext();
                                    }
                                }
                            }

                            angular.forEach($payload.data[$scope.question.data.id.value], function (row) {

                                //API can not be depended on to always return a string. Need to make sure type is properly set
                                for(var k = 0; k < row.length; k++) {

                                    if(row[k] != null) {

                                        row[k] = row[k].toString();
                                    }
                                }

                                $scope.dataTable.addRow(row);

                                //Add dataTable to scoped question for later use
                                $scope.question.dataTable = $scope.dataTable;

                                $scope.table.data = $scope.dataTable;

                                //Push ably response row into results for filtered chart and region filter
                                result.data_table.push(row);
                                $scope.originalResults.data_table.push(row);

                                //Activate new filter if necessary
                                if(typeof $scope.currentFilterCategory != "undefined" && $scope.currentFilterCategory != null) {

                                    for(var i = 0; i < $scope.currentFilterCategory.values.options.length; i++) {

                                        for(var j = 0; j < row.length; j++) {

                                            if($scope.currentFilterCategory.values.options[i].option.text == row[j]) {

                                                $scope.currentFilterCategory.values.options[i].option.showFilter = true;
                                            }
                                        }
                                    }
                                }

                            });

                            //If a filter has been set, we keep the filtered charts active
                            if(typeof $scope.currentFilterCategory != "undefined" && $scope.currentFilterCategory != null) {

                                $scope.$broadcast('event:filter-switch', $scope.currentFilterCategory);
                            } else {

                                $scope.columnChart.data = $scope.printColumnChart.data = $scope.dataView = createVisualisation($scope.dataTable);
                                $scope.showBarchart = false;
                                $scope.showColumnchart = true;

                                $scope.$apply();
                            }
                        });


                        /*
                         Do Raw Data Table
                         */
                        $scope.table = {};

                        $scope.table.type = "Table";

                        $scope.table.data = $scope.dataTable;

                        $scope.table.options = {
                            cssClassNames: $scope.tableClassNames
                        };


                        /*
                         Pops up grid of data displaying contents of $scope.table.data
                         */

                        $scope.showDataDialog = function ($event) {

                            if(FeatureService.allowed(CONSTANTS.FEATURES.RAW_DATA_EXTRACTS, true)) {

                                $scope.tempTable = angular.copy($scope.table);

                                //Remove user display name and avatar
                                $scope.tempTable.data.removeColumn(13);
                                $scope.tempTable.data.removeColumn(13);

                                var parentEl = angular.element(document.body);
                                $mdDialog.show({
                                    parent: parentEl,
                                    targetEvent: $event,
                                    templateUrl: 'app/views/elements/includes/data-table.html',
                                    locals: {
                                        table: $scope.tempTable
                                    },
                                    clickOutsideToClose: true,
                                    scope: $scope,
                                    preserveScope: true,
                                    fullscreen: true
                                });
                            }
                        };

                        $scope.barChart = {};

                        $scope.barChart.type = "BarChart";

                        $scope.barChartId = randomString(8);

                        $scope.barChart.options = {
                            fontSize: '14',
                            fontWeight: 'bold',
                            height: $scope.height,
                            chartArea: {
                                width: "100%",
                                height: "100%"
                            },
                            animation: $scope.animation,
                            legend: {
                                position: 'none'
                            },
                            tooltip: {
                                trigger: 'focus',
                                ignoreBounds: true,
                                isHtml: true
                            },
                            focusTarget: 'category',
                            bar: {
                                groupWidth: "18"
                            },
                            isStacked: false,
                            series: $scope.unfilteredSeries,
                            hAxis: {
                                gridlines: {
                                    color: 'transparent'
                                },
                                viewWindow: {
                                    min: 0,
                                    max: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                                },
                                textPosition: 'none',
                                baselineColor: 'none',
                                maxValue: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                            },
                            vAxis: {
                                gridlines: {
                                    color: 'transparent'
                                },
                                textPosition: 'none',
                                baselineColor: 'none',
                                fontSize: '0'
                            },
                            annotations: {
                                alwaysOutside: true,
                                stemColor: 'none'
                            }
                        };

                        /*
                         Do Column Chart
                         */
                        $scope.columnChart = {};

                        $scope.columnChart.type = "ColumnChart";

                        $scope.columnChartId = randomString(8);

                        $scope.columnChart.options = {
                            fontSize: '14',
                            fontWeight: 'bold',
                            chartArea: {
                                width: "95%",
                                height: "80%"
                            },
                            bar: {
                                groupWidth: "8"
                            },
                            isStacked: false,
                            animation: $scope.animation,
                            series: $scope.series,
                            legend: {
                                position: 'none'
                            },
                            focusTarget: 'category',
                            hAxis: {
                                gridlines: {
                                    color: 'transparent'
                                },
                                viewWindow: {
                                    min: $scope.min - 1,
                                    max: $scope.max + 1
                                },
                                minValue: 0,
                                textStyle : {
                                    fontSize: 8
                                }
                            },
                            vAxis: {
                                gridlines: {
                                    color: 'transparent'
                                },
                                viewWindow:{
                                    min:0,
                                    max: 2
                                }
                            }
                        };

                        /*
                         The visualisation data view is used for visible and printable chart
                         */
                        $scope.dataView = createVisualisation($scope.dataTable);

                        $scope.columnChart.data = $scope.dataView;

                        $scope.barchartDataView = createVisualisation($scope.dataTable);

                        $scope.barChart.data = $scope.barchartDataView;

                        /*
                         Set the chartArea
                         */
                        $scope.columnChartReadyHandler = function (chartWrapper) {

                            $scope.chart = chartWrapper.getChart();

                            var cli = $scope.chart.getChartLayoutInterface();

                            $scope.chartArea = cli.getChartAreaBoundingBox();

                            var el = angular.element('#' + $scope.columnChartId);

                            if (el.find('question-results-overlay').length == 0) {

                                var overlay = angular.element(document.createElement('question-results-overlay'));
                                $compile(overlay)($scope);

                                el.append(overlay);

                            }
                        };

                        /*
                         Set the chartArea
                         */
                        $scope.barChartReadyHandler = function (chartWrapper) {

                            $scope.chart = chartWrapper.getChart();

                            var cli = $scope.chart.getChartLayoutInterface();

                            $scope.chartArea = cli.getChartAreaBoundingBox();

                            var el = angular.element('#' + $scope.barChartId);

                            if (el.find('question-results-overlay').length == 0) {

                                var overlay = angular.element(document.createElement('question-results-overlay'));
                                $compile(overlay)($scope);

                                el.append(overlay);

                            }
                        };


                        /*
                         Do Printable Chart
                         */
                        $scope.printColumnChart = {};

                        $scope.printColumnChart.type = "ColumnChart";

                        $scope.printColumnChart.options = {
                            fontSize: '16',
                            width: '800',
                            chartArea: {
                                width: '80%',
                                left: '17%'
                            },
                            title: $scope.question.data.text.value,
                            legend: {
                                position: 'none'
                            },
                            tooltip: {
                                trigger: 'none'
                            },
                            focusTarget: 'category',
                            bar: {
                                groupWidth: "8"
                            },
                            isStacked: false,
                            series: {
                                0: {color: '#4a4a4a'},
                                1: {color: '#4a4a4a'},
                                2: {color: '#4a4a4a'}
                            },
                            vAxis: {
                                viewWindow: {
                                    min: 0,
                                    max: 2
                                }
                            },
                            hAxis: {
                            }
                        };

                        $scope.printColumnChart.data = $scope.dataView;

                        $scope.printColumnChartReadyHandler = function (chartWrapper) {
                            /*
                             Print a PNG
                             */
                            $scope.printPng = function ($event) {

                                if(FeatureService.allowed(CONSTANTS.FEATURES.RAW_DATA_EXTRACTS, true)) {

                                    var encodedUri = chartWrapper.getChart().getImageURI();

                                    var anchor = document.createElement('a');

                                    anchor.href = encodedUri;
                                    /*
                                     Should use the question name here..

                                     TODO: Not sure the .download has proper support
                                     */
                                    anchor.download = filename('png')
                                    anchor.target = '_blank';

                                    document.body.appendChild(anchor);

                                    anchor.click();
                                }
                            }
                        };

                        /*
                         Extract the CSV
                         */
                        $scope.extractData = function ($event) {

                            if(FeatureService.allowed(CONSTANTS.FEATURES.SPREADSHEET_DATA_EXTRACTS, true)) {

                                if(typeof analytics !== "undefined" && typeof analytics.track !== "undefined") {

                                    var user = DataService.grab('user');

                                    if(typeof user.data.brand_name != "undefined") {

                                        analytics.track('Extract Raw Data', {
                                            name: user.data.brand_name.value
                                        });
                                    }
                                }

                                var questionsArray = [];
                                questionsArray.push($scope.question);

                                var pulseHeader = ExportService.buildPulseHeader('csv', $scope.pulse);

                                var header = ExportService.buildHeader('csv', questionsArray, result.data_table[0], false, pulseHeader);

                                var dataObjArray = {};
                                dataObjArray = ExportService.addQuestion('csv', $scope.question, dataObjArray, $scope.allRegions);

                                var formattedDataTable = ExportService.buildBody('csv', header, dataObjArray, questionsArray, false);

                                ExportService.saveFile(ExportService.encodeUri('csv', formattedDataTable), $scope.result.question.text + '_raw.csv');
                            }
                        };

                        //Extract Demographic Summary CSV
                        $scope.extractDemographicSummary = function($event) {

                            if(FeatureService.allowed(CONSTANTS.FEATURES.SPREADSHEET_DATA_EXTRACTS, true)) {

                                if(typeof analytics !== "undefined" && typeof analytics.track !== "undefined") {

                                    var user = DataService.grab('user');

                                    if(typeof user.data.brand_name != "undefined") {

                                        analytics.track('Extract Summary Data', {
                                            name: user.data.brand_name.value
                                        });
                                    }
                                }

                                var questionsArray = [];
                                questionsArray.push($scope.question);

                                var pulseHeader = ExportService.buildPulseHeader('csv', $scope.pulse);

                                var header = ExportService.buildDemographicSummaryHeader('csv', questionsArray, pulseHeader);

                                var dataObjArray = {};
                                dataObjArray = ExportService.addQuestion('csv', $scope.question, dataObjArray, $scope.allRegions);

                                var formattedDataTable = ExportService.buildDemographicSummaryBody('csv', header, dataObjArray, questionsArray, $scope.pulse, $scope.demographicsPostPublish);

                                ExportService.saveFile(ExportService.encodeUri('csv', formattedDataTable), $scope.result.question.text + '_summary.csv');
                            }
                        };

                        /*
                         Dynamic Demographic Filters go here...
                         */
                        $scope.switch = {};

                        $scope.switch.all = {};

                        $scope.switch.filters = {};

                        $scope.switch.all.state = true;

                        $scope.chartFilters = [];

                        /*
                         * Filter on region
                         * */
                        $scope.$on('event:region-filter', function(event, regions) {

                            var selectedRegionIds = [];

                            for(var j = 0; j < regions.options.length; j++) {

                                if(regions.options[j].option.filterBy) {

                                    selectedRegionIds.push(parseInt(regions.options[j].option.id));
                                }
                            }

                            if($scope.originalResults.data_table.length > 1) {

                                var newDataTable = [];

                                newDataTable.push($scope.originalResults.data_table[0]);

                                for(var i = 1; i < $scope.originalResults.data_table.length; i++) {

                                    if(selectedRegionIds.indexOf(parseInt($scope.originalResults.data_table[i][12])) != -1) {

                                        newDataTable.push($scope.originalResults.data_table[i]);
                                    }
                                }

                                result.data_table = newDataTable;

                                //Total number of unique participants
                                $scope.uniqueIdArray = [];

                                for(var j = 1; j < result.data_table.length; j++) {

                                    result.data_table[j][12] = result.data_table[j][12].toString();

                                    if($scope.uniqueIdArray.indexOf(result.data_table[j][0]) == -1) {

                                        $scope.uniqueIdArray.push(result.data_table[j][0]);
                                    }
                                }

                                $scope.question.total_responses_multiple = $scope.uniqueIdArray.length;

                                $scope.dataTable = new google.visualization.arrayToDataTable(result.data_table);

                                $scope.question.dataTable = $scope.dataTable;
                                $scope.question.rawDataTable = result.data_table;

                                if(typeof $scope.currentFilterCategory != "undefined" && $scope.currentFilterCategory != null) {

                                    $scope.$broadcast('event:filter-switch', $scope.currentFilterCategory);
                                } else {

                                    $scope.columnChart.data = $scope.printColumnChart.data = $scope.dataView = createVisualisation($scope.dataTable);
                                    $scope.showBarchart = false;
                                    $scope.showColumnchart = true;
                                }
                            }
                        });

                        /*
                         To reset the filters
                         */
                        $scope.$on('event:filter-off', function(event) {

                            $scope.switch.filters = {};

                            $scope.chartFilters = [];

                            $scope.annotations = {};
                            $scope.labels = {};

                            /*
                             This resets both bar charts to the original state of the data

                             and passes in the unfiltered series colour options
                             */
                            $scope.columnChart.data = $scope.dataView;

                            $scope.printColumnChart.data = $scope.dataView;

                            $scope.columnChart.options.series = $scope.series;

                            $scope.printColumnChart.options.series = $scope.series;

                            $scope.filteredAnnotations = {};

                            $scope.showBarchart = false;
                            $scope.showColumnchart = true;
                        });

                        $scope.$on('event:filter-switch', function(event, filterCategory) {

                            //Get the colIndex by searching the header array
                            var colIndex = result.data_table[0].indexOf(filterCategory.text);

                            if (colIndex > -1) {

                                var values = [];
                                var colors = [];

                                for (var k = 0; k < filterCategory.values.options.length; k++) {

                                    if (filterCategory.values.options[k].option.showFilter && filterCategory.values.options[k].option.selected) {

                                        values.push(filterCategory.values.options[k].option.text);
                                        colors.push(filterCategory.values.options[k].option.color);
                                    }
                                }
                            }

                            //If filters have been set
                            if (values.length > 0) {

                                $scope.showColumnchart = false;

                                $scope.filteredDataView = createVisualisationFilter(result, values, colIndex);

                                var setColumns = [0];
                                var columnCount = 0;

                                for(var j = $scope.min; j <= $scope.max; j = j + $scope.step) {

                                    columnCount++;
                                }

                                for(var l = 1; l <= columnCount; l++) {

                                    setColumns.push(l);
                                }

                                $scope.filteredDataView.setColumns(setColumns);

                                //Update charts - Added timeout to smooth animation
                                $timeout(function () {

                                    $scope.barChart.data = $scope.filteredDataView;
                                    $scope.barChart.options.height = values.length * 45;
                                    $scope.barChart.options.series = $scope.filteredSeries;
                                    $scope.barChart.options.isStacked = 'percent';
                                    $scope.barChart.options.tooltip = {
                                        trigger: 'focus',
                                        ignoreBounds: true,
                                        isHtml: true
                                    };

                                    $scope.barChart.options.hAxis = {
                                        gridlines: {
                                            color: 'transparent'
                                        },
                                        textPosition: 'none',
                                        baselineColor: 'none'
                                    };

                                    $scope.$broadcast("event:demographics-on");

                                    $scope.showBarchart = true;

                                }, 800);

                                //$scope.printBarChart.data = $scope.filteredDataView;
                                //$scope.printBarChart.options.series = $scope.filteredSeries;
                                //$scope.printBarChart.options.isStacked = 'percent';
                                //$scope.printBarChart.options.tooltip = {
                                //    trigger: 'none'
                                //};
                                //
                                //$scope.barChart.options.hAxis = {
                                //    gridlines: {
                                //        color: 'transparent'
                                //    },
                                //    textPosition: 'none',
                                //    baselineColor: 'none'
                                //};

                                $scope.annotations = {};

                            } else {

                                $scope.columnChart.data = $scope.dataView;

                                $scope.printColumnChart.data = $scope.dataView;

                                $scope.columnChart.options.series = $scope.series;

                                $scope.printColumnChart.options.series = $scope.series;

                                $scope.showBarchart = false;
                                $scope.showColumnchart = true;
                            }
                        });

                        $scope.loaded = true;


                    }, undefined);
                };

                var createVisualisationFilter = function(result, filters, colIndex) {

                    var filteredDataArray = [];
                    var header = ['Answer'];

                    for(var j = $scope.min; j <= $scope.max; j = j + $scope.step) {

                        header.push(j.toString());
                    }

                    filteredDataArray.push(header);

                    for(var i = 0; i < filters.length; i++) {

                        var answerRow = [filters[i]];

                        for(var k = 1; k < header.length; k++) {

                            answerRow.push(0);
                        }

                        filteredDataArray.push(answerRow);
                    }

                    for(var l = 0; l < result.data_table.length; l++) {

                        for(var m = 1; m < filteredDataArray.length; m++) {

                            var valueArray = [];

                            if(result.data_table[l][colIndex].indexOf(", ") != -1 &&
                                $scope.allFilterOptions.indexOf(result.data_table[l][colIndex]) == -1 &&
                                result.data_table[0][colIndex] != 'Drinking alcohol' &&
                                result.data_table[0][colIndex] != 'Employer sector') {

                                valueArray = result.data_table[l][colIndex].split(", ");
                            } else {

                                valueArray.push(result.data_table[l][colIndex]);
                            }

                            if(valueArray[0].trim() == filteredDataArray[m][0]) {

                                for(var n = 1; n < filteredDataArray[0].length; n++) {

                                    if(filteredDataArray[0][n] == result.data_table[l][7]) {

                                        filteredDataArray[m][n]++;
                                    }
                                }
                            }
                        }
                    }

                    var data = google.visualization.arrayToDataTable(filteredDataArray);

                    var step1 = new google.visualization.DataView(data);

                    $scope.annotations = {};
                    $scope.labels = {};

                    for (var t = 0; t < step1.getNumberOfRows(); t++) {

                        var val = roundNumbers(step1.getValue(t, 1), 2);

                        $scope.annotations[t] = val > 0.001 ? val : 0.00;

                        $scope.labels[t] = {};

                        $scope.labels[t].label = step1.getValue(t, 0);

                        //for(var u = 0; u < $scope.question.data.answers.length; u++) {
                        //
                        //    if($scope.question.data.answers[u].data.text.value == $scope.labels[t].label){
                        //
                        //        $scope.labels[t].answerToken = $scope.question.data.answers[u].data_token;
                        //    }
                        //}
                    }

                    return new google.visualization.DataView(step1);
                };

                //Builds the visualization
                var createVisualisation = function (data) {

                    //Create new data table for visualization
                    var newDataTable = new google.visualization.DataTable();

                    //We only need 2 columns for unfiltered
                    newDataTable.addColumn('number', 'Value');
                    newDataTable.addColumn('number', 'Unfiltered');

                    console.log(data);

                    var tickArray = [$scope.min];

                    if(typeof data.Nf !== "undefined"){

                        for (var j = 0; j < data.Nf.length; j++) {

                            newDataTable.addRow([parseInt(data.Nf[j].c[7].v), 1]);
                            tickArray.push(parseInt(data.Nf[j].c[7].v));
                        }
                    } else if(typeof data.Vf !== "undefined") {

                        for (var j = 0; j < data.Vf.length; j++) {

                            newDataTable.addRow([parseInt(data.Vf[j].c[7].v), 1]);
                            tickArray.push(parseInt(data.Vf[j].c[7].v));
                        }
                    } else if(typeof data.hg !== "undefined") {

                        for (var j = 0; j < data.hg.length; j++) {

                            newDataTable.addRow([parseInt(data.hg[j].c[7].v), 1]);
                            tickArray.push(parseInt(data.hg[j].c[7].v));
                        }
                    } else if(typeof data.eg !== "undefined") {

                        for (var j = 0; j < data.eg.length; j++) {

                            newDataTable.addRow([parseInt(data.eg[j].c[7].v), 1]);
                            tickArray.push(parseInt(data.eg[j].c[7].v));
                        }
                    } else if(typeof data.fg !== "undefined") {

                        for (var j = 0; j < data.fg.length; j++) {

                            newDataTable.addRow([parseInt(data.fg[j].c[7].v), 1]);
                            tickArray.push(parseInt(data.fg[j].c[7].v));
                        }
                    }

                    //Sum the unfiltered column rows
                    var newDataTableGrouped = google.visualization.data.group(newDataTable, [{
                        column: 0,
                        type: 'number'
                    }], [{
                        column: 1,
                        label: 'Unfiltered',
                        aggregation: google.visualization.data.sum,
                        type: 'number'
                    }]);

                    //Determine highest vertical point
                    var highest = 0;

                    if(typeof data.Nf !== "undefined"){

                        for (var l = 0; l < newDataTableGrouped.Nf.length; l++) {

                            highest = highest > newDataTableGrouped.Nf[l].c[1].v ? highest : newDataTableGrouped.Nf[l].c[1].v;
                        }
                    } else if(typeof data.Vf !== "undefined") {

                        for (var l = 0; l < newDataTableGrouped.Vf.length; l++) {

                            highest = highest > newDataTableGrouped.Vf[l].c[1].v ? highest : newDataTableGrouped.Vf[l].c[1].v;
                        }
                    } else if(typeof data.hg !== "undefined") {

                        for (var l = 0; l < newDataTableGrouped.hg.length; l++) {

                            highest = highest > newDataTableGrouped.hg[l].c[1].v ? highest : newDataTableGrouped.hg[l].c[1].v;
                        }
                    } else if(typeof data.eg !== "undefined") {

                        for (var l = 0; l < newDataTableGrouped.eg.length; l++) {

                            highest = highest > newDataTableGrouped.eg[l].c[1].v ? highest : newDataTableGrouped.eg[l].c[1].v;
                        }
                    } else if(typeof data.fg !== "undefined") {

                        for (var l = 0; l < newDataTableGrouped.fg.length; l++) {

                            highest = highest > newDataTableGrouped.fg[l].c[1].v ? highest : newDataTableGrouped.fg[l].c[1].v;
                        }
                    }

                    tickArray.push($scope.max);

                    //Set the viewWindow for the vAxis to this highest value. If we don't force this, we get a lot of unnecessary resizing
                    $scope.columnChart.options.vAxis.viewWindow.max = highest;
                    $scope.columnChart.options.vAxis.viewWindow.min = 0;

                    //Remove duplicates - Can happen when results fall on min and max x-axis values
                    var uniqueTickArray = tickArray.filter(function(elem, index, self) {
                        return index == self.indexOf(elem);
                    });

                    //Set hAxisticks based on columns with value
                    $scope.columnChart.options.hAxis.ticks = uniqueTickArray;

                    if(typeof $scope.printColumnChart != 'undefined') {

                        $scope.printColumnChart.options.vAxis.viewWindow.max = highest;
                        $scope.printColumnChart.options.vAxis.viewWindow.min = 0;
                        $scope.printColumnChart.options.hAxis.ticks = uniqueTickArray;
                    }

                    //Return DataView
                    return new google.visualization.DataView(newDataTableGrouped);

                };

                //Initialize Directive
                getQuestionData();

                /*
                 Close data table pop up
                 */
                $scope.closeDialog = function ($event) {
                    $mdDialog.hide();
                }
            }
        };

        return {
            link:linkFunction,
            templateUrl:'/app/views/elements/question-results-columnchart.html'
        }
    }]);
