/**
 * Created by Barry on 05/03/18.
 *
 */
onePulse.directive('filterSlideout', ['$rootScope', '$window', 'DataService', '$timeout', '$q',
    'AlertService', 'FeatureService', 'CONSTANTS', '$translate', '$mdSidenav', '$mdDialog',
    function ($rootScope, $window, DataService, $timeout, $q,
              AlertService, FeatureService, CONSTANTS, $translate, $mdSidenav, $mdDialog) {

        linkFunction = function ($scope, element, attributes) {

            $scope.user = DataService.grab('user');

            $scope.unlocked = true;
            $scope.loaded = false;
            $scope.savedFiltersReady = false;
            $scope.categoriesReady = true;
            $scope.loadingParticipants = false;
            $scope.allowValueInteraction = false;
            $scope.allowDemInteraction = false;
            $scope.searchText = "";

            $scope.panel = null;
            $scope.demographics = null;
            $scope.regions = null;

            $scope.categories = [];
            $scope.panelSelectListItems = [];
            $scope.existingPanelNames = [];
            $scope.openArray = [];
            $scope.openCategoryArray = [];
            $scope.demRange = [];

            //Controls for region selector
            $scope.regions = {"open": false};
            $scope.showRegions = false;

            $scope.applyRegionSelection = false;

            $scope.loadingFilters = true;

            $scope.selectedRegionsCount = 0;
            $scope.selectedRegionsString = "";
            $scope.firstSelectedRegion = null;

            $scope.selectedDemsCount = 0;

            $scope.filterSearch = {"text": "", "context": "dem"};

            $scope.displayNoResults = false;

            $scope.quotasValid = true;

            $scope.canSetQuota = true;

            $scope.showLocalLanguage = false;

            //Determine if quota feature is enabled
            $scope.quotasEnabled = FeatureService.allowed(CONSTANTS.FEATURES.QUOTAS, false);

            $scope.$watch('filterSearch.text', function() {

                $scope.displayNoResults = $scope.checkNoResults();
            });

            //Toggle the demographics category checkbox to select/deselect all demographics in that category
            $scope.toggleSelectAll = function($event, demographic) {

                if($scope.allowDemInteraction) {

                    $scope.allowDemInteraction = false;

                    $event.preventDefault();
                    $event.stopPropagation();

                    $scope.selectedDemsCount = 0;

                    for(var i = 0; i < $scope.demographics.length; i++) {

                        if($scope.isSelected($scope.demographics[i])) {

                            $scope.selectedDemsCount++;
                        }
                    }

                    //If the category has a single demo selected, we deselect all else we select all
                    if($scope.isSelected(demographic)) {

                        for (var i = 0; i < demographic.values.options.length; i++) {

                            demographic.values.options[i].option.selected = false;
                        }
                    } else {

                        //Add dem category only if not exceeded max allowed dem count
                        if($scope.selectedDemsCount < CONSTANTS.MAX_ALLOWED_DEMOGRAPHICS) {

                            for (var i = 0; i < demographic.values.options.length; i++) {

                                demographic.values.options[i].option.selected = true;
                            }

                        } else {

                            var translations;
                            $translate(['alert.exceeded_max_dems.title', 'alert.exceeded_max_dems.text']).then(function (result) {
                                translations = result;

                                AlertService.warning(
                                    translations['alert.exceeded_max_dems.title'],
                                    translations['alert.exceeded_max_dems.text']
                                )
                            });
                        }
                    }

                    if(demographic.quotas_set) {

                        if($scope.isSelected(demographic)) {

                            $scope.addQuotas(demographic);
                        } else {

                            $scope.removeQuotas(demographic);
                        }
                    }

                    $scope.toggleDemographicValue(demographic, null);
                }
            };

            $scope.rangeSliderInteract = function(demographic) {

                for(var j = 0; j < demographic.values.options.length; j++) {

                    var value = parseInt(demographic.values.options[j].option.text);

                    demographic.values.options[j].option.selected =
                        value >= $scope.demRange[demographic.id].low
                        && value <= $scope.demRange[demographic.id].high;
                }
            }

            $scope.toggleOpenDemographic = function($event, demographic) {

                $event.preventDefault();

                $timeout(function() {
                    $window.dispatchEvent(new Event("resize"));
                }, 1000);

                $scope.openArray[demographic.id] = !$scope.openArray[demographic.id];

            };

            $scope.toggleOpenDemographicCategory = function($event, title) {

                $event.preventDefault();

                if(typeof title !== "undefined") {

                    $timeout(function() {
                        $window.dispatchEvent(new Event("resize"));
                    }, 1000);

                    $scope.openCategoryArray[title] = !$scope.openCategoryArray[title];
                }
            };

            //Check that at least 1 region has been selected. If true, update the pulse
            $scope.validateRegionSelection = function() {

                var atLeastOneSelected = false;

                $scope.selectedRegionsCount = 0;
                $scope.selectedRegionsString = "";
                $scope.firstSelectedRegion = null;

                for(var i = 0; i < $scope.regions.options.length; i++) {

                    if($scope.regions.options[i].option.selected == true) {

                        atLeastOneSelected = true;

                        $scope.selectedRegionsCount++;
                        $scope.selectedRegionsString = $scope.selectedRegionsString + $scope.regions.options[i].option.text + ", ";

                        if($scope.firstSelectedRegion == null) {

                            $scope.firstSelectedRegion = $scope.regions.options[i];
                        }
                    }
                }

                //Remove trailing comma and space
                $scope.selectedRegionsString = $scope.selectedRegionsString.substring(0, $scope.selectedRegionsString.length - 2);

                if($scope.originalSelectedRegionsString != $scope.selectedRegionsString) {

                    if(atLeastOneSelected) {

                        $scope.loadingParticipants = true;
                        $scope.applyRegionSelection = true;

                        $scope.originalSelectedRegionsString = angular.copy($scope.selectedRegionsString);

                        $rootScope.currentPulse.data.regions = $scope.regions;

                        for(var i = 0; i < $scope.currentPulse.data.demographics.length; i++) {

                            var keepDem = false;

                            for(var k = 0; k < $scope.currentPulse.data.demographics[i].regions.length; k++) {

                                if($scope.currentPulse.data.demographics[i].regions[k].code == CONSTANTS.INTERNATIONAL_REGION_CODE) {

                                    keepDem = true;
                                }
                            }

                            if(keepDem) {

                                continue;
                            }

                            for(var j = 0; j < $scope.currentPulse.data.demographics[i].values.options.length; j++) {

                                $scope.currentPulse.data.demographics[i].values.options[j].option.selected = false;
                            }
                        }

                        DataService.submit('pulse', $rootScope.currentPulse).then(function (result) {

                            $rootScope.currentPulse = result.pulse;

                            $scope.regions.open = false;
                            $scope.applyRegionSelection = false;

                            $scope.init();

                            $scope.populatePotentialParticipants();

                            var translations;
                            $translate(['alert.regions_changed.title', 'alert.regions_changed.text']).then(function (result) {
                                translations = result;

                                AlertService.warning(
                                    translations['alert.regions_changed.title'],
                                    translations['alert.regions_changed.text']
                                )
                            });
                        });

                    } else {

                        var translations;
                        $translate(['alert.must_select_at_least_one_region.title', 'alert.must_select_at_least_one_region.text']).then(function (result) {
                            translations = result;

                            AlertService.warning(
                                translations['alert.must_select_at_least_one_region.title'],
                                translations['alert.must_select_at_least_one_region.text']
                            )
                        });
                    }
                } else {

                    $scope.regions.open = false;
                }
            };

            //Save current region selection state when region selection control is opened
            $scope.saveCurrentRegionSelection = function() {

                $scope.currentRegionSelection = angular.copy($scope.regions);

            };

            //restore saved region selection state when region selection control is closed without saving
            $scope.restoreRegionSelection = function() {

                $scope.regions = angular.copy($scope.currentRegionSelection);
                $scope.currentRegionSelection = null;

                $scope.selectedRegionsCount = 0;
                $scope.selectedRegionsString = "";
                $scope.firstSelectedRegion = null;

                //Determine selected regions count
                for(var j = 0; j < $scope.regions.options.length; j++) {

                    if($scope.regions.options[j].option.selected == true) {

                        $scope.selectedRegionsCount++;
                        $scope.selectedRegionsString = $scope.selectedRegionsString + $scope.regions.options[j].option.text + ", ";

                        if($scope.firstSelectedRegion == null) {

                            $scope.firstSelectedRegion = $scope.regions.options[j];
                        }
                    }
                }

                //Remove trailing comma and space
                $scope.selectedRegionsString = $scope.selectedRegionsString.substring(0, $scope.selectedRegionsString.length - 2);

                $scope.regions.open = false
            };

            /*
             Set the initial scope asynchronously
             */
            $scope.init = function() {

                //Potential members - affected by changing demographic filters
                $scope.maxParticipants = $rootScope.currentPulse.maxParticipants;

                //Set demographics & regions options
                $scope.demographics = angular.copy($rootScope.currentPulse.data.demographics);
                $scope.regions = angular.copy($rootScope.currentPulse.data.regions);
                $scope.audience_type_categories = angular.copy($rootScope.currentPulse.data.audience_type_categories);
                $scope.selectedCategory = {id: angular.copy($rootScope.currentPulse.data.audience_type_category_id.value)};
                $scope.audience_type_id = angular.copy($rootScope.currentPulse.data.audience_type_id.value);

                var i = $scope.demographics.length;

                while (i--) {

                    if ($scope.demographics[i].is_hidden === "1") {

                        $scope.demographics.splice(i, 1);
                        continue;
                    }

                    if($scope.demographics[i].format_id == 8) {

                        $scope.demRange[$scope.demographics[i].id] = {
                            low: parseInt($scope.demographics[i].values.options[0].option.text),
                            high: parseInt($scope.demographics[i].values.options[$scope.demographics[i].values.options.length - 1].option.text)
                        };

                        for (var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                            if($scope.demographics[i].values.options[j].option.selected == true) {

                                $scope.demRange[$scope.demographics[i].id].low = $scope.demographics[i].values.options[j].option.text;
                                break;
                            }

                        }

                        for (var j = $scope.demographics[i].values.options.length - 1; j >= 0; j--) {

                            if($scope.demographics[i].values.options[j].option.selected == true) {

                                $scope.demRange[$scope.demographics[i].id].high = $scope.demographics[i].values.options[j].option.text;
                                break;
                            }
                        }
                    }

                    $scope.openArray[$scope.demographics[i].id] = $scope.isSelected($scope.demographics[i]);

                    $scope.demographics[i].quotas_set = $scope.isQuotaSet($scope.demographics[i]);
                    $scope.demographics[i].quotas_allowed = $scope.isQuotaDefault($scope.demographics[i]);

                    if (typeof $scope.openCategoryArray[$scope.demographics[i].category.title] === "undefined" ||
                        !$scope.openCategoryArray[$scope.demographics[i].category.title]) {

                        $scope.openCategoryArray[$scope.demographics[i].category.title] = $scope.isSelected($scope.demographics[i]);
                    }

                    if ($scope.isSelected($scope.demographics[i])) {

                        $scope.selectedDemsCount++;
                    }
                }

                //Grab a copy of the original set - to restore on a close (not saving)
                $scope.originalDemographics = angular.copy($scope.demographics);

                //Determine whether or not to show region selection control - only show if client has access to 2 or more regions
                $scope.showRegions = ($rootScope.currentPulse.data.regions.options.length >= 2 && (CONSTANTS.DISPLAY_FILTER_REGION_SELECTION || $scope.user.data.is_internal_account.value == "1"));

                $scope.selectedRegionsCount = 0;
                $scope.selectedRegionsString = "";
                $scope.firstSelectedRegion = null;

                //Determine selected regions count
                for(var j = 0; j < $scope.regions.options.length; j++) {

                    if($scope.regions.options[j].option.selected) {

                        $scope.selectedRegionsCount++;
                        $scope.selectedRegionsString = $scope.selectedRegionsString + $scope.regions.options[j].option.text + ", ";

                        if($scope.firstSelectedRegion == null) {

                            $scope.firstSelectedRegion = $scope.regions.options[j];
                        }
                    }
                }

                //Remove trailing comma and space
                $scope.selectedRegionsString = $scope.selectedRegionsString.substring(0, $scope.selectedRegionsString.length - 2);
                $scope.originalSelectedRegionsString = angular.copy($scope.selectedRegionsString);

                $scope.allowValueInteraction = true;
                $scope.allowDemInteraction = true;
                $scope.loadingFilters = false;
            };

            $scope.$on('LastRepeater', function(){

                $scope.loadingFilters = false;
            });

            $scope.deregisterFiltersOpenHandler = $rootScope.$on("event:filters-open", function(event) {

                //Call initialisation
                $scope.init();
            });

            $scope.$on("$destroy", function() {

                //De-register all scope event handlers
                if (typeof $scope.deregisterFiltersOpenHandler !== "undefined") {

                    $scope.deregisterFiltersOpenHandler()
                }
            });

            $scope.setRegion = function(region) {

                for(var j = 0; j < $scope.regions.options.length; j++) {

                    if($scope.regions.options[j].option.id == region.option.id) {

                        $scope.regions.options[j].option.selected = true;
                        $scope.selectedRegionsCount = 1;
                        $scope.selectedRegionsString = $scope.regions.options[j].option.text;
                        $scope.firstSelectedRegion = $scope.regions.options[j];
                    } else {

                        $scope.regions.options[j].option.selected = false;
                    }
                }
            }

            //Update the pulse with the demographic selections
            $scope.submitCallback = function($event) {

                $scope.validateQuotas();

                if($scope.quotasValid) {

                    //Update panel
                    if($scope.searchText !== "" && $scope.searchText != null) {

                        $scope.updatePanel();
                    }

                    $mdSidenav('right-filter').toggle();

                    $rootScope.filterOpen = false;

                    $scope.$broadcast('event:pulse-saving');

                    $rootScope.fadedOverlay = true;
                    $rootScope.loadingStateText = "Saving Pulse";

                    //Save range demographics
                    for(var i = 0; i < $scope.demographics.length; i++) {

                        if($scope.demographics[i].format_id == 8 && $scope.isSelected($scope.demographics[i])) {

                            for(var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                                var value = parseInt($scope.demographics[i].values.options[j].option.text);

                                $scope.demographics[i].values.options[j].option.selected =
                                    value >= $scope.demRange[$scope.demographics[i].id].low
                                    && value <= $scope.demRange[$scope.demographics[i].id].high;
                            }
                        }
                    }

                    for(var i = 0; i < $scope.audience_type_categories.options.length; i++) {

                        $scope.audience_type_categories.options[i].option.selected =
                            $scope.audience_type_categories.options[i].option.id == $scope.selectedCategory.id;
                    }

                    $rootScope.currentPulse.data.demographics = $scope.demographics;
                    $rootScope.currentPulse.data.regions = $scope.regions;
                    $rootScope.currentPulse.data.audience_type_category_id.value = $scope.selectedCategory.id;
                    $rootScope.currentPulse.data.audience_type_categories = $scope.audience_type_categories;

                    DataService.submit('pulse', $rootScope.currentPulse).then(function (result) {

                        $rootScope.fadedOverlay = false;
                        $rootScope.loadingStateText = "";

                        var translations;
                        $translate(['alert.pulse_saved.title', 'alert.pulse_saved.text']).then(function(result) {
                            translations = result;

                            AlertService.success(
                                translations['alert.pulse_saved.title'],
                                translations['alert.pulse_saved.text']
                            );
                        });

                        $rootScope.$broadcast('event:demographics-updated', $scope.demographics);
                        $rootScope.$broadcast('event:pulse-update', result.pulse);
                        $scope.$broadcast('event:pulse-saved');
                    });
                }
            };

            $scope.backCallback = function ($event) {

                $window.history.back();
                $event.preventDefault();
            };

            $scope.removeDemographic = function(id) {

                for(var i = 0; i < $scope.demographics.length; i++) {

                    for(var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                        if($scope.demographics[i].values.options[j].option.id == id) {

                            $scope.demographics[i].values.options[j].option.selected = false;
                        }
                    }
                }

                $scope.populatePotentialParticipants();
            };

            //Deselect all dems
            $scope.removeAllDemographics = function() {

                for(var i = 0; i < $scope.demographics.length; i++) {

                    for(var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                        $scope.demographics[i].values.options[j].option.selected = false;
                    }
                }

                $scope.populatePotentialParticipants();
            };

            //Show/lock a demographic category based on user feature rights
            $scope.showDemographic = function(selectedOptionKey) {

                var basicDemographic = false;
                var showDemographic = false;

                //Check if the selected demographic is basic
                for (var i = 0; i < $scope.demographics.length; i++) {

                    if ($scope.demographics[i].text == selectedOptionKey) {

                        basicDemographic = $scope.demographics[i].is_basic;
                    }
                }

                var showBasic = FeatureService.allowed(CONSTANTS.FEATURES.BASIC_DEMOGRAPHICS, false);
                var showPro = FeatureService.allowed(CONSTANTS.FEATURES.FULL_DEMOGRAPHICS, false);

                //Check for feature permission - This return from the API should be a boolean rather than a string
                if (basicDemographic == "1") {

                    //If the demographic is basic, we show if the user has basic OR full demo rights
                    showDemographic = (showBasic || showPro);
                } else {

                    //else we show only if the user has full demo rights
                    showDemographic = showPro;
                }

                return showDemographic
            };

            //Toggle the demographic selector
            $scope.sliderShowNHide = function(selectedOptionKey, selectedOptions){

                $scope.showUpgrade = false;

                var showDemographic = $scope.showDemographic(selectedOptionKey);

                //If allowed, show the demographic
                if(showDemographic) {

                    if ($scope.demographicsState == 'closed') {
                        $scope.demographicsState = 'open';
                    }

                    $scope.selectedOptionKey = selectedOptionKey;
                    $scope.selectedOptions = selectedOptions;

                    $scope.$emit('content.changed');
                } else {

                    $scope.showUpgrade = true;
                }
            };

            $scope.showUpgradeModal = function() {

                return FeatureService.allowed(CONSTANTS.FEATURES.FULL_DEMOGRAPHICS, true);
            };

            //Close the Panel Editor Modal
            $scope.closeFilterEditor = function() {

                $mdSidenav('right-filter').toggle();

                $rootScope.filterOpen = false;

                $scope.$broadcast('event:pulse-saving');

                $rootScope.fadedOverlay = true;
                $rootScope.loadingStateText = "";

                $rootScope.currentPulse.data.demographics = copyDemographics($scope.demographics, $scope.originalDemographics);

                DataService.submit('pulse', $rootScope.currentPulse).then(function (result) {

                    $rootScope.fadedOverlay = false;
                    $rootScope.loadingStateText = "";

                    $rootScope.$broadcast('event:demographics-updated', $scope.originalDemographics);
                    $rootScope.$broadcast('event:pulse-update', result.pulse);
                    $scope.$broadcast('event:pulse-saved');
                })
            };

            //Check if any demographics for a particular category are selected
            $scope.isSelected = function(demographic){

                if (typeof demographic !== "undefined") {

                    var selected = false;

                    for (var i = 0; i < demographic.values.options.length; i++) {

                        if (demographic.values.options[i].option.selected) {

                            selected = true;
                        }
                    }

                    return selected;
                }
            };

            $scope.isQuotaSet = function(demographic) {

                if (typeof demographic !== "undefined") {

                    var set = false;

                    for (var i = 0; i < demographic.values.options.length; i++) {

                        if (demographic.values.options[i].option.quota !== null) {

                            if (demographic.values.options[i].option.quota !== 0) {

                                set = true;
                            }
                        }
                    }

                    return set;
                }
            };

            $scope.isQuotaDefault = function(demographic) {

                if (typeof demographic !== "undefined") {

                    var set = false;

                    for (var i = 0; i < demographic.values.options.length; i++) {

                        if (demographic.values.options[i].option.quota_default !== null) {

                            set = true;
                        }
                    }

                    if(demographic.external_id !== null) {

                        set = true;
                    }

                    return set;
                }
            };

            $scope.isSelectedCategory = function(title) {

                var selected = false;

                for (var i = 0; i < $scope.demographics.length; i++) {

                    if($scope.demographics[i].category.title === title) {

                        for (var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                            if ($scope.demographics[i].values.options[j].option.selected) {

                                selected = true;
                            }
                        }
                    }
                }

                return selected;
            };

            //Is dem displayed
            $scope.isOpen = function(id) {

                return $scope.openArray[id];
            };

            //Is dem category displayed
            $scope.isCategoryOpen = function(title) {

                return $scope.openCategoryArray[title];
            };

            //Toggle demographic value selected state
            $scope.toggleDemographicValue = function(demographic, option) {

                if($scope.allowValueInteraction) {

                    $scope.selectedDemsCount = 0;
                    $scope.allowValueInteraction = false;

                    for(var i = 0; i < $scope.demographics.length; i++) {

                        if($scope.isSelected($scope.demographics[i])) {

                            $scope.selectedDemsCount++;
                        }
                    }

                    if(!$scope.isSelected(demographic) && $scope.selectedDemsCount >= CONSTANTS.MAX_ALLOWED_DEMOGRAPHICS) {

                        var translations;
                        $translate(['alert.exceeded_max_dems.title', 'alert.exceeded_max_dems.text']).then(function (result) {
                            translations = result;

                            AlertService.warning(
                                translations['alert.exceeded_max_dems.title'],
                                translations['alert.exceeded_max_dems.text']
                            )
                        });

                        $scope.allowValueInteraction = true;
                        $scope.allowDemInteraction = true;
                    } else {

                        if(option != null) {

                            option.option.selected = !option.option.selected;
                        }

                        var data = {
                            "data": demographic,
                            "data_token": $rootScope.currentPulse.data_token
                        };

                        $scope.loadingParticipants = true;

                        DataService.submit('pulse/demographics', data).then(function (result) {

                            $rootScope.currentPulse.data_token = result.pulse.data_token;

                            var k = result.pulse.data.demographics.length;

                            while (k--) {

                                if (result.pulse.data.demographics[k].is_hidden === "1") {

                                    result.pulse.data.demographics.splice(k, 1);
                                }
                            }

                            for (var i = 0; i < result.pulse.data.demographics.length; i++) {

                                for (var j = 0; j < result.pulse.data.demographics[i].values.options.length; j++) {

                                    $scope.demographics[i].values.options[j].option_token = result.pulse.data.demographics[i].values.options[j].option_token;
                                }
                            }

                            $scope.populatePotentialParticipants();

                            $scope.allowValueInteraction = true;
                            $scope.allowDemInteraction = true;

                            $scope.adjustQuotas(demographic, option);
                        });
                    }
                }
                $scope.$broadcast('content.reload');

            };


            //Get potential participants - every demographic change
            $scope.populatePotentialParticipants = function() {

                //TEMP: Removed as part of business request
                // DataService.fetchPotentialParticipants($rootScope.currentPulse.data_token).then(function(result) {
                //
                //     $scope.maxParticipants = result.count;
                //
                //     $scope.loadingParticipants = false;
                // });
            };

            $scope.filterSearchFn = function(type) {

                return function(obj) {

                    switch(type) {

                        case "dem":

                            return ($scope.filterSearch.context !== type || obj.text.toLowerCase().indexOf($scope.filterSearch.text.toLowerCase()) !== -1);

                        case "value":

                            return ($scope.filterSearch.context !== type || obj.option.text.toLowerCase().indexOf($scope.filterSearch.text.toLowerCase()) !== -1);
                    }

                    return true;
                };
            };

            $scope.checkNoResults = function() {

                var displayNoResults = false;

                if($scope.filterSearch.text != "") {

                    displayNoResults = true;

                    for(var i = 0; i < $scope.demographics.length; i++) {

                        $scope.openCategoryArray[$scope.demographics[i].category.title] = false;
                    }

                    switch($scope.filterSearch.context) {

                        case "dem":

                            for(var i = 0; i < $scope.demographics.length; i++) {

                                if($scope.demographics[i].text.toLowerCase().indexOf($scope.filterSearch.text.toLowerCase()) !== -1) {

                                    $scope.openCategoryArray[$scope.demographics[i].category.title] = true;
                                    displayNoResults =  false;
                                }
                            }

                            break;

                        case "value":

                            for(var i = 0; i < $scope.demographics.length; i++) {

                                if(typeof $scope.demographics[i].values.options !== "undefined") {

                                    for(var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                                        if ($scope.demographics[i].values.options[j].option.text.toLowerCase().indexOf($scope.filterSearch.text.toLowerCase()) !== -1) {

                                            $scope.openCategoryArray[$scope.demographics[i].category.title] = true;
                                            $scope.openArray[$scope.demographics[i].id] = true;
                                            displayNoResults = false;
                                        }
                                    }
                                }
                            }

                            break;
                    }
                }

                return displayNoResults;
            };

            $scope.quotaInfoModal = function($event) {

                $event.stopPropagation();

                var quotasInfoModalController = function($scope, $mdDialog) {

                    $scope.closeDialog = function () {

                        $mdDialog.hide();
                    }
                };

                quotasInfoModalController.$inject = ["$scope", "$mdDialog"];

                $mdDialog.show({
                    controller: quotasInfoModalController,
                    templateUrl: "app/views/elements/includes/quotas-info.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    targetEvent: $event
                });
            };

            $scope.addQuotas = function(demographic) {

                var optionCount = 0;
                var selectedOptionKeys = [];

                demographic.quotas_set = true;

                for (var k = 0; k < demographic.values.options.length; k++) {

                    if(demographic.values.options[k].option.selected) {

                        optionCount++;
                        selectedOptionKeys.push(k);
                    }
                }

                var optionInitialQuotaValues = divideEvenly(100, optionCount);

                for(var i = 0; i < optionCount; i++) {

                    if(demographic.values.options[selectedOptionKeys[i]].option.quota_default != null) {

                        demographic.values.options[selectedOptionKeys[i]].option.quota = demographic.values.options[selectedOptionKeys[i]].option.quota_default;
                    } else {

                        demographic.values.options[selectedOptionKeys[i]].option.quota = optionInitialQuotaValues[i];
                    }

                }

                for (var m = 0; m < $scope.demographics.length; m++) {

                    var testDemographic = $scope.demographics[m];

                    if (testDemographic.quotas_set && testDemographic.id !== demographic.id) {

                        var data = {
                            "data": testDemographic,
                            "data_token": $rootScope.currentPulse.data_token
                        };

                        DataService.submit('pulse/demographics', data).then(function (result) {

                            $rootScope.currentPulse = result.pulse;

                            var data = {
                                "data": demographic,
                                "data_token": $rootScope.currentPulse.data_token
                            };

                            DataService.submit('pulse/demographics', data).then(function (result) {

                                $rootScope.currentPulse = result.pulse;

                                $scope.init();

                                $scope.populatePotentialParticipants();
                            });
                        });
                    }
                }
            };

            $scope.adjustQuotas = function(demographic, option) {

                if(demographic.quotas_set) {

                    if(!option.option.selected) {

                        var totalToRemove = 0;
                        var totalOtherOptions = 0;
                        var optionCount = 0;
                        var selectedOptionKeys = [];

                        if(option.option.quota !== null) {

                            totalToRemove = option.option.quota;
                        }

                        for (var k = 0; k < demographic.values.options.length; k++) {

                            if(demographic.values.options[k].option.selected) {

                                optionCount++;
                                totalOtherOptions += demographic.values.options[k].option.quota;
                                selectedOptionKeys.push(k);
                            }
                        }

                        if(totalToRemove > 0 && totalOtherOptions < 100) {

                            var optionInitialQuotaValues = divideEvenly(totalToRemove, optionCount);

                            for(var i = 0; i < optionCount; i++) {

                                demographic.values.options[selectedOptionKeys[i]].option.quota += optionInitialQuotaValues[i];
                            }
                        }

                        for (var j = 0; j < demographic.values.options.length; j++) {

                            if (!demographic.values.options[j].option.selected) {

                                demographic.values.options[j].option.quota = 0;
                            }
                        }
                    } else {

                        for (var l = 0; l < demographic.values.options.length; l++) {

                            if (!demographic.values.options[l].option.id === option.id) {

                                demographic.values.options[l].option.quota = 0;
                            }
                        }
                    }

                    var data = {
                        "data": demographic,
                        "data_token": $rootScope.currentPulse.data_token
                    };

                    DataService.submit('pulse/demographics', data).then(function (result) {

                        $rootScope.currentPulse = result.pulse;

                        $scope.init();

                        $scope.populatePotentialParticipants();
                    });
                }
            };

            $scope.removeQuotas = function(demographic) {

                demographic.quotas_set = false;

                for (var i = 0; i < demographic.values.options.length; i++) {

                    demographic.values.options[i].option.quota = null;
                }

                var data = {
                    "data": demographic,
                    "data_token": $rootScope.currentPulse.data_token
                };

                DataService.submit('pulse/demographics', data).then(function (result) {

                    $rootScope.currentPulse = result.pulse;

                    $scope.init();

                    $scope.populatePotentialParticipants();

                    if(result.pulse.data.is_valid_quota !== true && result.pulse.data.is_valid_quota !== false) {

                        var translations;
                        $translate(['alert.quota_error.title', 'alert.quota_error.text']).then(function (result) {
                            translations = result;

                            AlertService.warning(
                                translations['alert.quota_error.title'],
                                translations['alert.quota_error.text']
                            )
                        });
                    }
                });
            };

            $scope.preventDefault = function($event) {

                $event.stopPropagation();
            };

            $scope.validateQuotas = function() {

                $scope.quotasValid = true;

                for (var m = 0; m < $scope.demographics.length; m++) {

                    var demographic = $scope.demographics[m];

                    if(demographic.quotas_set) {

                        for (var n = 0; n < demographic.values.options.length; n++) {

                            var option = $scope.demographics[m].values.options[n];

                            if (option.option.selected && option.option.quota !== '' && parseInt(option.option.quota) > 0) {

                                var quotaTotal = 0;

                                for (var i = 0; i < demographic.values.options.length; i++) {

                                    if (demographic.values.options[i].option.selected) {

                                        quotaTotal += parseInt(demographic.values.options[i].option.quota);
                                    }
                                }

                                if (quotaTotal > 100 || quotaTotal < 100) {

                                    $scope.quotasValid = false;
                                }
                            }
                        }

                        if($scope.quotasValid) {

                            var data = {
                                "data": demographic,
                                "data_token": $rootScope.currentPulse.data_token
                            };

                            DataService.submit('pulse/demographics', data).then(function (result) {

                            });
                        }
                    }
                }

                if(!$scope.quotasValid) {

                    var translations;
                    $translate(['alert.general_100_quota.title', 'alert.general_100_quota.text']).then(function (result) {
                        translations = result;

                        AlertService.warning(
                            translations['alert.general_100_quota.title'],
                            translations['alert.general_100_quota.text']
                        )
                    });
                }
            };
        };

        return {
            restrict: 'E',
            priority: 0,
            replace: false,
            scope: true,
            link: linkFunction,
            templateUrl: '/app/views/elements/pulse/filterSlideout.html'
        }
    }]);
