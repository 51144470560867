/**
 * Created by Barry on 2018/05/25.
 *
 * Activity stats
 */

onePulse.directive('activityStats', [
    function () {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/dashboard/activityStats.html',

            link: function postLink(scope, element, iAttrs) {

            }
        };
    }]);