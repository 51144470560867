onePulse.directive('clickOutside', ['$document', "$parse", function ($document, $parse) {
    return {
        restrict: 'A',
        link:  function (scope, element, attr, controller) {

            var clickOutsideFunction = $parse(attr['clickOutside']);

            var documentClickHandler = function (event) {

                var eventOutsideTarget = (element[0] !== event.target) && (0 === element.find(event.target).length);

                if (eventOutsideTarget) {

                    scope.$apply(function () {

                        clickOutsideFunction(scope, {});
                    });
                }
            };

            $document.on("click", documentClickHandler);

            scope.$on("$destroy", function () {

                $document.off("click", documentClickHandler);
            });
        }
    };
}]);