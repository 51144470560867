onePulse.controller('PanelsController', ['$http', '$scope', '$filter', '$rootScope', 'DataService',
	'$location', '$routeParams', '$mdDialog', '$translate', 'AlertService', '$timeout',
	function($http, $scope, $filter, $rootScope, DataService,
			 $location, $routeParams, $mdDialog, $translate, AlertService, $timeout) {

	$scope.showPanelId = null;
	$rootScope.showPanelId = null;
	$rootScope.showPanel = null;

	$scope.setPanelId = function(panel, type) {

		$rootScope.showPanelId = panel.data.id.value;
		$rootScope.showPanel = panel;

		$mdDialog.show({
			templateUrl: 'app/views/elements/includes/' + type + '.html',
			clickOutsideToClose: false
		});
	};

	var panels;

		$scope.colors = ['#673AB7', '#FF4F48', '#067AB9', '#839BB4'];

	DataService.grabOrFetch('meta/colors').then(
			function(result) {
				result = JSON.parse(result);
				if (result.colors.length > 0) {
					$scope.colors = result.colors;
				}
			},

			function() {}
	);

	$scope.$on('event:panel-update',function(event, panel){

		//$scope.panels = DataService.grab('panels');
        //
		//for(var index in $scope.panels) {
        //
		//	prepareListViewData(index)
		//}
	});

	$scope.isItActive = function($id) {
		return $id == $routeParams.id;
	};

	$scope.getGroupImage=function($groups){

		var group = findSelectedOption($groups);

		if(group !==undefined) {

			if (group.option.image.length > 0) {

				return group.option.image
			}
		}

		return 'https://placehold.it/325x325';
	};

	$scope.getGroupName=function($groups){

		var group = findSelectedOption($groups);

		if(group !==undefined) {

			if (group.option.text > 0) {

				return group.option.text
			}
		}

		return '';
	};

	$scope.closeCallback = function ($event, panel) {

		DataService.close('panel', panel.data_token).then(function (result) {

			$rootScope.$broadcast('event:panel-update',result.panel);

		});

		$event.preventDefault()

	};

	//Soft delete Panel
	$scope.deleteCallback = function($event, panel) {

		var translations;
		$translate(['alert.delete_panel_confirm.title', 'alert.delete_panel_confirm.text']).then(function(result) {
			translations = result;

			AlertService.confirm(
				translations['alert.delete_panel_confirm.title'],
				translations['alert.delete_panel_confirm.text'],
				function() {

					DataService.delete('panel', panel.data_token).then(function() {

						$scope.panels = removePanel($scope.panels, panel.data_token);

						var translations;
						$translate(['alert.delete_panel_complete.title', 'alert.delete_panel_complete.text']).then(function(result) {
							translations = result;

							AlertService.success(
								translations['alert.delete_panel_complete.title'],
								translations['alert.delete_panel_complete.text']
							);

							$scope.initPanels(true);
						});
					});
				}
			);
		});

		$event.preventDefault();
	};

	$scope.createPulseFromPanel = function($event, panel){

		if(!panel.pulseCreationStarted) {

			panel.pulseCreationStarted = true;

			//Prevent click propagation to underlying panel edit click handler
			$event.stopPropagation();
			$event.preventDefault();

			if(panel.data.is_active.value != '1'){

				DataService.publish('panel', panel.data_token).then(function (result) {

					$rootScope.$broadcast('event:panel-update',result.panel);

					panel.pulseCreationStarted = false;
					$scope.createPulseFromPanel(result.panel);
				});

			}else {

				DataService.create('pulse').then(function (result) {

					var pulse = result.pulse;

					DataService.createQuestion(pulse.data_token).then(function(){

						//Add panel id to root scope so it can be available in the pulse controller (not loaded yet)
						$rootScope.starterPanel = panel.data.id.value;

						panel.pulseCreationStarted = false;

						$location.path('/pulse/' + pulse.data.id.value + '/edit');

					});
				});
			}
		}
	};

	var selectedDemographicOptions = function(options){

		var optionNames = [];

		for(var index = 0; index < options.length; index++) {

			if(options[index].option.selected){

				optionNames.push(options[index].option.text);
			}
		}

		return (optionNames.length > 0 ? optionNames.join(', ') : false);
	};

	$scope.initPanels = function() {

		var processPanel = function() {

			//ng-cloak is too slow - we have to manually show the div when panels are ready
			$("#filter-modal-control-container").show();
		};

		DataService.fetch('panels').then(function(result) {

			$scope.panels = angular.fromJson(result).panels;

			processPanel();

		}, function() {

			$scope.panels = undefined;
		});
	};

	$scope.initPanels();

	$scope.panelsFilter = function (panel) {

		if(typeof panel.selectedGroup != "undefined") {

			return panel.data.name.value !== panel.selectedGroup.option.text;
		} else {

			return false;
		}
	};

	$scope.isActive = function(){
		//console.log('is active')
	};

	//Duplicate a panel
	$scope.duplicateCallback = function($event, panel) {

		DataService.create('panel').then(function(result) {

			var duplicatedPanel = angular.fromJson(result).panel;

			panel.data.is_edited.value = "1";

			var duplicatedId = duplicatedPanel.data.id.value;

			duplicatedPanel = angular.merge({}, panel);
			duplicatedPanel.data.is_edited.value = '1';
			duplicatedPanel.data.id.value = duplicatedId;

			DataService.submit('panel', duplicatedPanel).then(function (result) {
				
				$rootScope.$broadcast('event:panel-update', result.panel);

				$scope.setPanelId(result.panel.data.id.value, 'panel');
			});
		});

		$event.preventDefault()
	};

	//Remove a panel from scope by data_token
	var removePanel = function(panels, data_token) {

		for(var i = 0; i < panels.length; i++) {

			if(panels[i].data_token === data_token) {

				panels.splice(i, 1);
			}
		}

		return panels;
	}
}]);
