/**
 * Created by Barry on 2017/11/16
 *
 * Service handling building of charts
 */
onePulse.factory('ChartService', [function() {

        return {

            buildDonutChart: function(data, sliceTitle, sliceType) {

                var chartObj = {};
                var chartData = [];
                var chartColors = [];

                chartObj.type = "PieChart";

                if(typeof data != "undefined") {

                    for (var i = 0; i < data.length; i++) {

                        chartData.push({c: [{v: data[i].title}, {v: data[i].percent}]});
                        chartColors.push(data[i].color);
                    }

                    chartObj.data = {"cols": [
                        {id: "t", label: sliceTitle, type: "string"},
                        {id: "s", label: sliceType, type: "number"}
                    ], "rows": chartData
                    };

                    chartObj.options = {
                        legend: 'none',
                        pieSliceText: 'none',
                        pieHole: 0.8,
                        colors: chartColors,
                         width: '100%',
                        height: 200
                    };
                }

                return chartObj;
                
            }
        }
    }]);