onePulse.controller('ReferralsController', ['$scope', '$rootScope', 'DataService', '$translate', 'AlertService',
    'SocialShareService', '$timeout', '$mdDialog', 'CONSTANTS', '$route',
    function ($scope, $rootScope, DataService, $translate, AlertService,
              SocialShareService, $timeout, $mdDialog, CONSTANTS, $route) {

        $scope.totalAmountEarned = 0.00;
        $scope.totalRewardBalance = 0.00;
        $scope.created_at_dates = [];

        $scope.notifyClipboard = function() {

            var translations;
            $translate(['alert.invitation_link_copied.title', 'alert.invitation_link_copied.text']).then(function(result) {
                translations = result;

                AlertService.success(
                    translations['alert.invitation_link_copied.title'],
                    translations['alert.invitation_link_copied.text']
                )
            });
        };

        $scope.share = function($event, provider) {

            $event.stopPropagation();

            SocialShareService.share("link", "https://www.onepulse.com/share/invitation/" + $scope.user.data.referral_code.value, provider);
        };

        //Controller for Paypal payout modal
        function payPalPayoutModalController($scope, $rootScope, $mdDialog, $translate, AlertService, DataService, user, totalRewardBalance) {

            $scope.submitSpinner = false;

            $scope.user = user;
            $scope.amountToPayout = parseFloat((totalRewardBalance > CONSTANTS.MAX_DAILY_REFERRAL_PAYOUT ? CONSTANTS.MAX_DAILY_REFERRAL_PAYOUT : totalRewardBalance));

            $scope.payOut = function() {

                if($scope.amountToPayout <= 0) {

                    var translations;
                    $translate(['alert.referral_payout_zero.title', 'alert.referral_payout_zero.text']).then(function(result) {
                        translations = result;

                        AlertService.warning(
                            translations['alert.referral_payout_zero.title'],
                            translations['alert.referral_payout_zero.text']
                        )
                    });
                } else {

                    $scope.submitSpinner = true;

                    DataService.submit('user', $scope.user).then(function (result) {

                        $scope.user = result.user;

                        var data = {
                            "amount": $scope.amountToPayout
                        };

                        DataService.withdrawPayPal(data, function() {

                            $scope.submitSpinner = false;
                        }).then(function(result2) {

                            DataService.fetchItem('user').then(function(result3) {

                                $scope.user = result3.user;

                                $scope.submitSpinner = false;

                                var translations;
                                $translate(["alert.payout_successful.title", "alert.payout_successful.text"]).then(function (result) {
                                    translations = result;

                                    AlertService.success(
                                        translations["alert.payout_successful.title"],
                                        translations["alert.payout_successful.text"]
                                    );
                                });

                                init();

                                $mdDialog.hide();

                                $route.reload();
                            });
                        });
                    });
                }
            };

            $scope.closeDialog = function () {

                $mdDialog.hide();
            }
        }

        //Paypal payout
        $scope.payPalPayoutModal = function ($event) {

            payPalPayoutModalController.$inject = ["$scope", "$rootScope", "$mdDialog", "$translate", "AlertService", "DataService", "user", "totalRewardBalance"];

            $mdDialog.show({
                templateUrl: "app/views/elements/includes/paypal-payout.html",
                controller: payPalPayoutModalController,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                targetEvent: $event,
                locals: {
                    AlertService: AlertService,
                    DataService: DataService,
                    user: $scope.user,
                    totalRewardBalance: $scope.totalRewardBalance
                }
            });
        };

        var init = function() {

            $timeout(function() {

                $scope.totalRewardBalance = parseFloat($scope.user.data.reward_balance.value).toFixed(2);

                for(var i = 0; i < $scope.user.data.referrals.length; i++) {

                    $scope.created_at_dates[i] = {};
                    $scope.created_at_dates[i].created_at = new Date($scope.user.data.referrals[i].client.data.created_at.value);
                    $scope.totalAmountEarned += parseFloat($scope.user.data.referrals[i].reward_amount.toFixed(2));
                }
            })
        };

        init();
}]);
