/**
 * Created by mike on 27/01/16.
 */
onePulse.directive('answerForm', ['$compile', 'FormService', function($compile, FormService) {

    var $service = FormService;

    linkFunction = function($scope, element, attributes){

        /*
         Put the question data through the FormService and get all the rules and fields
         */
        $scope.form = $service.parseData($scope.answer.data);


        $scope.$on('event:question-translated',function(){

            $scope.answer.translation = findOptionById($scope.question.translation.data.answers,$scope.answer.data.id.value);

        })

    }

    return {
        link:linkFunction,
        templateUrl:'app/views/elements/forms/answer.html'
    }
}]);
