/**
 * Created by Barry on 2018/05/25.
 *
 * Pulse grid view
 */

onePulse.directive('pulseGrid', ['PulseService',
    function (PulseService) {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/pulses/pulseGrid.html',

            link: function postLink(scope, element, iAttrs,$rootScope) {

                scope.duplicatePulse = function($event, pulse) {

                    PulseService.duplicatePulse($event, pulse, true);
                };

                scope.rePulse = function($event, pulse) {

                    PulseService.rePulse($event, pulse);
                };

                scope.deletePulse = function ($event, pulse) {

                    PulseService.deletePulse($event, pulse, null, scope.removePulse);
                };

                scope.editPulse = function(pulseId) {

                    PulseService.editPulse(pulseId);
                };

                scope.createPulse = function ($event) {

                    PulseService.createPulse($event);
                    /*
                    @Barry I moved this here to be able to use the PulseService.createPulse
                    in this route callback app/config/routes.js:218
                     */
                    scope.pulses.push($rootScope.createdPulse);
                };
            }
        };
    }]);