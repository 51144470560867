/**
 * Created by mike on 16/12/15.
 */
onePulse.controller('GroupsController', ['$http', '$scope', '$rootScope', 'DataService', '$location', '$routeParams', 'RoleService',
	'$filter', '$mdDialog', '$timeout', 'FeatureService', 'CONSTANTS', '$translate', 'AlertService', '$window',
	function ($http, $scope, $rootScope, DataService, $location, $routeParams, RoleService,
			  $filter, $mdDialog, $timeout, FeatureService, CONSTANTS, $translate, AlertService, $window) {

	$scope.user = DataService.grab('user');
	var groups;

	$scope.isLoading = true;
	$scope.allowedToEdit = false;

	$scope.showGroupId = null;
	$rootScope.showGroupToken = null;
	$rootScope.creatingGroup = false;

	$scope.isResellerClient = false;

	$scope.channelSearch = {"text": ""};

	//Display/hide web training overlay
	if(CONSTANTS.DISPLAY_TRAINING_OVERLAYS) {

		if (typeof $window.localStorage['showPanelsTrainingOverlay'] == "undefined") {

			$rootScope.showPanelsTrainingOverlay = (typeof $scope.user.data != "undefined" ? ($location.absUrl().search('/channels') != -1 && $scope.user.data.is_web_training_displayed.value) : false);
			$window.localStorage['showPanelsTrainingOverlay'] = (typeof $scope.user.data != "undefined" ? ($location.absUrl().search('/channels') != -1 && $scope.user.data.is_web_training_displayed.value) : false);
		} else {

			$rootScope.showPanelsTrainingOverlay = ($window.localStorage['showPanelsTrainingOverlay'] === "true") && ($location.absUrl().search('/channels') != -1 && $scope.user.data.is_web_training_displayed.value);
		}
	}

	if($scope.user.data.master_reseller_id.value !== null && $scope.user.data.master_reseller_id.value !== "") {

		$scope.isResellerClient = true;
	}

    $scope.channelSearchFn = function() {

        return function(obj) {

            return obj.data.name.value.toLowerCase().indexOf($scope.channelSearch.text.toLowerCase()) !== -1;
        };
    };

	$scope.isItActive = function ($id) {
		return $id == $routeParams.id;
	};

	$scope.setGroupId = function(group) {

		//is editable
		if(group.data.is_editable.value == "1") {

			$rootScope.showGroupToken = group.data_token;

			$mdDialog.show({
				templateUrl: 'app/views/elements/includes/group.html',
				clickOutsideToClose: false
			});
		}
	};

	$scope.getGroups = function() {

		$scope.groups = [];
		$scope.publicGroups = [];
		$scope.privateGroups = [];

		var page = 1;

		var getPage = function() {

			DataService.fetch('groups', page).then(function(result) {

				Array.prototype.push.apply($scope.groups, angular.fromJson(result).all);
				Array.prototype.push.apply($scope.privateGroups, angular.fromJson(result).groups);
				$scope.publicGroups = unShiftArrayByProperty(sortByProperty(angular.fromJson(result).public, 'name', 'desc'), 'name', 'OnePulse');

				if(angular.fromJson(result).groups.length == 15) {

					page++;

					getPage();
				}
			});
		};

		getPage();
	};

	$scope.getGroups();

	$scope.$on('event:modal-closed',function(event){

		$timeout(function() {

			$scope.getGroups();
		}, 1000);
	});

	$scope.allowType = function(showModal) {

		if($scope.panelShow == 'private') {

			return FeatureService.allowed(CONSTANTS.FEATURES.PRIVATE_COMMUNITIES, showModal);
		}

		return true;
	};

	$scope.createGroupCallback = function ($event) {

		DataService.create('group').then(function(result) {

			var group = angular.fromJson(result).group;

			$rootScope.creatingGroup = true;

			$scope.setGroupId(group);
		});
	};

	$scope.duplicateCallback = function($event, group) {

		DataService.create('group').then(function(result) {

			var newGroup = angular.fromJson(result).group;

			newGroup.data.block_leaving = angular.copy(group.data.block_leaving);
			newGroup.data.color = angular.copy(group.data.color);
			newGroup.data.description = angular.copy(group.data.description);
			newGroup.data.image = angular.copy(group.data.image);
			newGroup.data.is_active = angular.copy(group.data.is_active);
			newGroup.data.is_editable = angular.copy(group.data.is_editable);
			newGroup.data.is_edited = angular.copy(group.data.is_edited);
			newGroup.data.is_public = angular.copy(group.data.is_public);
			newGroup.data.is_visible = angular.copy(group.data.is_visible);
			newGroup.data.name = angular.copy(group.data.name);

			DataService.submit('group', newGroup).then(function (result) {

				$scope.setGroupId(result.group);
			});
		});

		$event.preventDefault()
	};

	$scope.closeCallback = function ($event, group) {

		DataService.close('group', group.data_token).then(function(result) {

			$timeout(function() {

				for(var i = 0; i < $scope.groups.length; i++) {

					if($scope.groups[i].data.id.value === result.group.data.id.value) {

						$scope.groups[i] = result.group;
					}
				}
			});
		});

		$event.preventDefault();
	};

	//Soft delete Group
	$scope.deleteCallback = function($event, group) {

		var translations;
		$translate(['alert.delete_panel_confirm.title', 'alert.delete_panel_confirm.text']).then(function(result) {
			translations = result;

			AlertService.confirm(
				translations['alert.delete_panel_confirm.title'],
				translations['alert.delete_panel_confirm.text'],
				function() {

					DataService.delete('group', group.data_token).then(function() {

						$scope.groups = removeGroup($scope.groups, group.data_token);
						$scope.publicGroups = removeGroup($scope.publicGroups, group.data_token);
                        $scope.privateGroups = removeGroup($scope.privateGroups, group.data_token);

						var translations;
						$translate(['alert.delete_panel_complete.title', 'alert.delete_panel_complete.text']).then(function(result) {
							translations = result;

							AlertService.success(
								translations['alert.delete_panel_complete.title'],
								translations['alert.delete_panel_complete.text']
							);
						});
					});
				}
			);
		});

		$event.preventDefault();
	};

	$scope.createPulseFromGroup = function($event, group){

        if(RoleService.allowed(CONSTANTS.RIGHTS.CREATE_EDIT_PULSE, true)) {

            if (!group.pulseCreationStarted) {

                $rootScope.renderComplete = false;
                $rootScope.loadingStateText = "Creating Pulse";

                group.pulseCreationStarted = true;

                $event.stopPropagation();
                $event.preventDefault();

                //Publish group if not already active and resubmit
                if (typeof group.data.is_active != "undefined" && group.data.is_active.value != '1') {

                    DataService.publish('group', group.data_token).then(function (result) {

                        group.pulseCreationStarted = false;
                        $scope.createPulseFromGroup(result.group);
                    });

                } else {

                    //Create pulse
                    DataService.create('pulse').then(function (result) {

                        var pulse = result.pulse;

                        //Set the selected group
                        for (var i = 0; i < pulse.data.group.options.length; i++) {

                            pulse.data.group.options[i].option.selected = (pulse.data.group.options[i].option.id == group.data.id.value)
                        }

                        //update pulse and redirect to pulse editor
                        DataService.submit('pulse', pulse).then(function () {

                            $rootScope.renderComplete = true;
                            $rootScope.loadingStateText = "";
                            group.pulseCreationStarted = false;
                            $location.path('/pulse/' + pulse.data.id.value + '/edit');
                        });
                    });
                }
            }
        }
	};

	$scope.isActive = function(){
		//console.log('is active')
	};

	//Remove a group scope by data_token
	var removeGroup = function(groups, data_token) {

		for(var i = 0; i < groups.length; i++) {

			if(groups[i].data_token === data_token) {

				groups.splice(i, 1);
			}
		}

		return groups;
	};

}]);