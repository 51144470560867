/**
 * Created by Barry on 2016/11/30.
 */

onePulse.controller('BlogController', ['$scope', 'ContentService', '$window', '$routeParams', '$sce',
    function ($scope, ContentService, $window, $routeParams, $sce) {

        $scope.blogId = $routeParams.blogId;
        $scope.blogSlug = $routeParams.blogSlug;
        $scope.categoryId = $routeParams.categoryId;
        $scope.categorySlug = $routeParams.categorySlug;

        $scope.blogs = [];

        //Initiate scope with blog and category arrays
        $scope.init = function() {

            ContentService.fetchContent('blog').then(function(result) {

                $scope.blogs = result.content;

                for(var i = 0; i < $scope.blogs.entries.length; i++) {

                    var dPub = $scope.blogs.entries[i].date_published.date.split('.');

                    $scope.blogs.entries[i].date_published.date = dPub[0];

                    if ($scope.categoryId != null) {

                        $scope.blogs.entries[i].show = false;

                        for (var j = 0; j < $scope.blogs.entries[i].categories.length; j++) {

                            if ($scope.blogs.entries[i].categories[j].id == $scope.categoryId) {

                                $scope.blogs.entries[i].show = true;
                            }
                        }
                    } else {

                        $scope.blogs.entries[i].show = true;
                    }
                }
            });
        };

        ContentService.fetchContent('blog', 'categories').then(function(result) {

            $scope.categories = result.content;

            if ($scope.categoryId != null) {

                for(var i = 0; i < $scope.categories.entries.length; i++) {

                    if($scope.categories.entries[i].id == $scope.categoryId) {

                        $scope.categoryText = $scope.categories.entries[i].title
                    }
                }
            }
        });

        //Redirect to specific blog entry
        $scope.viewBlog = function(blog) {

            $window.location = '/blog-post/' + blog.id + '/' + blog.slug;
        };

        //View blog entries from specific category
        $scope.viewCategory = function(category) {

            $window.location = '/blog-category/' + category.id + '/' + category.slug;
        };

        //If the blogId routeParam has been set, fetch the relevant blog entry
        if(typeof $scope.blogSlug != "undefined" && $scope.blogSlug != null) {

            ContentService.fetchContent('blog', "", $scope.blogId).then(function(result) {

                $scope.blog = result.content.entries[0];

                var dPub = $scope.blog.date_published.date.split('.');

                $scope.blog.date_published.date = dPub[0];

                $scope.blog.text = $sce.trustAsHtml($scope.blog.text);
            });
        }

        //Only initiate if we are not viewing a specific blog entry
        if ($scope.blogSlug == null) {

            $scope.init();
        }
    }]);

