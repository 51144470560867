/**
 * Created by mike on 11/12/15.
 */
onePulse.factory('FormService', ['$window', 'DataService', function ($window, DataService) {

    var $rules = [];
    var $images = [];
    var $attributes = [];
    var $disabled = [];
    var $selects = [];
    var $selecteds = {};
    var $order = [];
    var $checkboxs=[];
    var $dates=[];
    var $item;
    var $data;
    var $translatable=[];

    parseSelect = function($value,$key){
        //add the option
        var $options = [];

        //push in the select rules
        $rules.push(newFormObject($key, parseSelectRules($value.rules)));

        angular.forEach($value.options, function ($v, $k) {

            //add the item as an option for this select
            $options.push(newFormObject($v.option.text, $v.option.selected));

            //if the option is already selected
            if ($v.option.selected == true) {

                //create base object
                if(!$selecteds[$item]){
                    $selecteds[$item]={};
                }
                //instantiate for the field name ($key)
                if(!$selecteds[$item][$key]){

                    //create new base single select structure
                    $selecteds[$item][$key]=newObject('selected',$v.option_token);

                }else{

                    //its multi select replace with an object structure
                    if(!angular.isObject($selecteds[$item][$key]['selected'])) {

                        //instantiate the object with the existing value and the new value
                        $selecteds[$item][$key]['selected'] = [$selecteds[$item][$key]['selected'],$v.option_token];

                    }else{

                        //or if object already then push in a new value
                        $selecteds[$item][$key]['selected'].push($v.option_token);

                    }
                }

            }

        });

        //add the options to the selects keyed by field name
        $selects.push(newFormObject($key, $options));
    }

    parseMultiSelect = function($value,$key){

        //add the option
        var $options = [];

        //push in the select rules
        $rules.push(newFormObject($key, parseSelectRules($value.rules)));

        angular.forEach($value.options, function ($v, $k) {

            //add the item as an option for this select
            $options.push(newFormObject($v.option.text, $v.option.selected));

            //if the option is already selected
            if ($v.option.selected == true) {

                //create base object
                if(!$selecteds[$item]){
                    $selecteds[$item]={};
                }

                if(!$selecteds[$item][$key]){
                    $selecteds[$item][$key]=['selected'];
                }

                //its multi select replace with an object structure
                if(!angular.isObject($selecteds[$item][$key]['selected'])) {
                    //instantiate the object with the existing value and the new value
                    $selecteds[$item][$key]['selected'] = [$v.option_token];

                }else{
                    //or if object already then push in a new value
                    $selecteds[$item][$key]['selected'].push($v.option_token);

                }

            }

        });

        //add the options to the selects keyed by field name
        $selects.push(newFormObject($key, $options));
    }

    parse = function($data){

        $rules = [];
        $images = [];
        $attributes = [];
        $disabled = [];
        $selects = [];
        $selecteds = {};
        $order = [];
        $checkboxs=[];
        $dates=[];
        $translatable=[];
        $item;
        $data;

        for(var $key in $data) {

            //to maintain some order
            $order.push($key);

            if($key == 'demographics'){ //special case of nested multiple selects

                for(var $k in $data[$key]){
                        parseMultiSelect($data[$key][$k].values,$data[$key][$k].text)
                }

            }else if ($data[$key] != null && angular.isDefined($data[$key].value) && typeof $data[$key].rules != 'undefined') {

                //console.log($key)

                if ($data[$key].rules == 'locked') {
                    //put in the disabled list
                    $disabled.push(newFormObject($key, $data[$key].value));

                } else if ($data[$key].rules.search('image_url') > -1) {

                    $images.push(newFormObject($key, $data[$key].value));

                    $rules.push(newFormObject($key, parseTextRules($data[$key].rules)));
                } else if ($data[$key].rules.search('boolean') > -1) {

                    $rules.push(newFormObject($key, parseTextRules($data[$key].rules)));
                    $checkboxs.push(newFormObject($key, $data[$key].value));

                } else if ($data[$key].rules.search('date') > -1) {

                    $rules.push(newFormObject($key, parseTextRules($data[$key].rules)));
                    $dates.push(newFormObject($key, $data[$key].value));

                }else {
                    //save the rules list
                    $rules.push(newFormObject($key, parseTextRules($data[$key].rules)));
                    $attributes.push(newFormObject($key, $data[$key].value));
                }

                if($data[$key].rules.search('translate') > -1){
                    $translatable.push(newFormObject($key, $data[$key].value));

                }
            } else if ( $data[$key] != null && angular.isDefined($data[$key].options)) {
                parseSelect($data[$key],$key)
            }

        }
    };

    return {
        parse: function ($it) {

            $item = $it;
            $data = DataService.grab($item).data;

            parse($data);

            return {
                rules: $rules,
                images: $images,
                attributes: $attributes,
                disabled: $disabled,
                selects: $selects,
                selecteds: $selecteds,
                checkboxs:$checkboxs,
                order: $order,
                dates:$dates,
                translatable:$translatable
            };
        },
        field: function($name){

        },
        parseItem:function($it,$key){

            $item = $it.replace(/s$/, '');

            var $grabbedItem = DataService.grabItem($it,$key);

            parse($grabbedItem.data);

            return {
                rules: $rules,
                images: $images,
                attributes: $attributes,
                disabled: $disabled,
                selects: $selects,
                selecteds: $selecteds,
                checkboxs:$checkboxs,
                order: $order,
                dates:$dates,
                translatable:$translatable
            };
        },
        parseData:function($data,$it){

            $item = $it;

            parse($data);

            return {
                rules: $rules,
                images: $images,
                attributes: $attributes,
                disabled: $disabled,
                selects: $selects,
                selecteds: $selecteds,
                checkboxs:$checkboxs,
                order: $order,
                dates:$dates,
                translatable:$translatable
            };

        }
    }
}]);
