/**
 * Created by barry on 02/05/2018.
 *
 */
onePulse.controller('WorkspaceSelectController', ['$scope', '$rootScope', 'DataService', 'TokenService', 'LoginService',
    'AuthService', '$location', '$routeParams', '$window',
	function ($scope, $rootScope, DataService, TokenService, LoginService,
              AuthService, $location, $routeParams, $window) {

        $scope.type = $routeParams.type;
        $scope.code = $routeParams.code;

        $rootScope.displayWorkspaceSelectOverlay = {"open": true};
        $rootScope.submitSpinner = false;

        $scope.selectedWorkspace = {};

        $scope.selectWorkspace = function() {

            var data = {
                "code": $scope.selectedWorkspace.code.value
            };

            $rootScope.submitSpinner = true;
            $rootScope.renderComplete = false;
            $rootScope.loadingStateText = "Switching Workspace";

            DataService.workspace("select", data).then(function(response) {

                TokenService.setAccessToken = response.access_token;

                LoginService.processLogin(response);

                $scope.isLoggedIn = true;

                $rootScope.renderComplete = true;
                $rootScope.loadingStateText = "";

                $window.location = '/dashboard';
            })
        };

        $scope.continueCurrentWorkspace = function() {

            $rootScope.displayWorkspaceSelectOverlay.open = false;
            $location.path('/dashboard');
        };

        $scope.displaySelection = function() {

            return AuthService.isLoggedIn();
        };
	
		//Initialise
		var init = function() {

		    switch($scope.type) {

                case 'invite-accepted':

                    if (!AuthService.isLoggedIn()) {

                        $location.path('/login/' + $scope.code);
                    } else {

                        DataService.fetchItem('user').then(function(result) {

                            $scope.user = result.user;
                        });
                    }

                    break;
            }
		};

		init();
}]);
