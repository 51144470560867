onePulse.controller('RegisterController', ['$scope', '$window', '$location', 'LoginService', 'AuthService', '$routeParams',
    'DataService', '$translate', 'AlertService', 'CONSTANTS', 'vcRecaptchaService', 'Config', '$rootScope',
    function ($scope, $window, $location, LoginService, AuthService, $routeParams,
              DataService, $translate, AlertService, CONSTANTS, vcRecaptchaService, Config, $rootScope) {

    $scope.user = DataService.grab('user');

    $scope.newUser = {
        type: "client",
        industry: false,
        agreedTC: false,
        agreedPrivacy: false,
        display_name: "",
        email: "",
        password: "",
        confirm_password: "",
        company: ""
    };
    $scope.submitSpinner = false;

    $scope.password = {strength: ""};

    $scope.GOOGLE_RECAPTCHA_SITE_KEY = CONSTANTS.GOOGLE_RECAPTCHA_SITE_KEY;

    $scope.referral = $routeParams.referral;

    $scope.firstStep = false;

    $scope.showReferralMessage = (typeof $scope.referral !== "undefined");

    var translations;

    $translate(['alert.register_wait.title', 'alert.register_wait.text', 'alert.register.title',
        'alert.register.text', 'alert.not_agreed_tc.title', 'alert.not_agreed_tc.text',
        'alert.password_match_error.title', 'alert.password_match_error.text',
        'alert.password_weak_error.title', 'alert.password_weak_error.text',
        'alert.required_field_missing.title', 'alert.required_field_missing.text',
        'alert.captcha_error.title', 'alert.captcha_error.text',
        'alert.industry_error.title', 'alert.industry_error.text']).then(function(result){
        translations = result;
    });

    $scope.showReferralCodeInput = function() {

    };

    $scope.cancel = function() {

        $window.location = '/';
    };

    $scope.setResponse = function (response) {

        $scope.rcResponse = response;
        $scope.doRegistration();
    };

    $scope.setWidgetId = function (widgetId) {
        $scope.widgetId = widgetId;
    };

    $scope.cbExpiration = function() {
        vcRecaptchaService.reload($scope.widgetId);
        $scope.rcResponse = null;
    };

    $scope.firstStepValidation = function() {

        if(scorePassword($scope.newUser.password) < 50) {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.password_weak_error.title'],
                translations['alert.password_weak_error.text']
            );
        } else if($scope.newUser.password == "" || $scope.newUser.confirm_password == "" ||
            $scope.newUser.email == "" || $scope.newUser.display_name == "") {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.required_field_missing.title'],
                translations['alert.required_field_missing.text']
            );
        } else if($scope.newUser.confirm_password != $scope.newUser.password) {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.password_match_error.title'],
                translations['alert.password_match_error.text']
            );
        } else {

            $scope.firstStep = true;
        }
    };

    $scope.doRegistration = function () {

        $scope.submitSpinner = true;

        if(!$scope.newUser.agreedTC) {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.not_agreed_tc.title'],
                translations['alert.not_agreed_tc.text']
            );
        } else if(scorePassword($scope.newUser.password) < 50) {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.password_weak_error.title'],
                translations['alert.password_weak_error.text']
            );
        } else if($scope.newUser.password == "" || $scope.newUser.confirm_password == "" ||
            $scope.newUser.email == "" || $scope.newUser.company == "") {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.required_field_missing.title'],
                translations['alert.required_field_missing.text']
            );
        } else if($scope.newUser.confirm_password != $scope.newUser.password) {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.password_match_error.title'],
                translations['alert.password_match_error.text']
            );
        } else if(vcRecaptchaService.getResponse($scope.recaptchaId) === "") {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.captcha_error.title'],
                translations['alert.captcha_error.text']
            );
        } else {

            DataService.validateClient({
                "email": $scope.newUser.email,
                "brand_name": $scope.newUser.company,
                'g-recaptcha-response':vcRecaptchaService.getResponse($scope.recaptchaId)
            }, function() {

                $scope.submitSpinner = false;
                $scope.cbExpiration();
            }).then(function () {

                Config.countryCheck(true, false).then(function(response) {

                    var countryCode = typeof response !== "undefined" ? (typeof response.country !== "undefined" ? response.country : null) : null;

                    //Pass in referral code and country code
                    var data = {
                        "referral_code": (typeof $scope.referral !== "undefined" ? $scope.referral : null),
                        "country_code": countryCode
                    };

                    //Call the enrol API endpoint for an access token with client publishing permissions
                    DataService.enrol(data).then(function (response) {

                        response.user.data.email.value = $scope.newUser.email;
                        response.user.data.brand_name.value = $scope.newUser.company;
                        response.user.data.display_name.value = $scope.newUser.display_name;
                        response.user.data.contact_name.value = $scope.newUser.display_name;

                        //Update the selected industry
                        if ($scope.newUser.industry != null) {

                            for (var i = 0; i < response.user.data.industry.options.length; i++) {

                                response.user.data.industry.options[i].option.selected = (response.user.data.industry.options[i].option.text == $scope.newUser.industry);
                            }
                        }

                        DataService.submit('user', response.user, function () {

                            $scope.submitSpinner = false;
                            $scope.cbExpiration();
                        }).then(function () {

                            //Update Client User password
                            DataService.updatePassword($scope.newUser.password, $scope.newUser.confirm_password).then(function () {

                                //Publish Client User
                                DataService.publishClient().then(function () {

                                    $scope.submitSpinner = false;

                                    //Set state to logged in and redirect to the dashboard
                                    $scope.isLoggedIn = true;

                                    AlertService.success(
                                        translations['alert.register.title'],
                                        translations['alert.register.text']
                                    );

                                    $window.location = '/dashboard';
                                });
                            })
                        })
                    });
                });
            });
        }
    };

    $scope.doCompleteRegistration = function() {

        $scope.submitSpinner = true;

        if(!$scope.newUser.agreedTC) {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.not_agreed_tc.title'],
                translations['alert.not_agreed_tc.text']
            );
        } else if(scorePassword($scope.newUser.password) < 50) {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.password_weak_error.title'],
                translations['alert.password_weak_error.text']
            );
        } else if($scope.newUser.password == "" || $scope.newUser.confirm_password == "" ||
            $scope.newUser.company == "") {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.required_field_missing.title'],
                translations['alert.required_field_missing.text']
            );
        } else if($scope.newUser.confirm_password != $scope.newUser.password) {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.password_match_error.title'],
                translations['alert.password_match_error.text']
            );
        } else if(vcRecaptchaService.getResponse($scope.recaptchaId) === "") {

            $scope.submitSpinner = false;

            AlertService.warning(
                translations['alert.captcha_error.title'],
                translations['alert.captcha_error.text']
            );
        } else {

            $scope.user.data.brand_name.value = $scope.newUser.company;

            DataService.validateClient({
                "brand_name": $scope.user.data.brand_name.value,
                'g-recaptcha-response':vcRecaptchaService.getResponse($scope.recaptchaId)
            }, function() {

                $scope.submitSpinner = false;
            }).then(function () {

                DataService.submit('user', $scope.user, function() {
                    $scope.signupNowButtonClicked = false;
                }).then(function (response) {

                    $scope.user = response.user;

                    //Update Client User password
                    DataService.updatePassword($scope.newUser.password, $scope.newUser.password_confirmation).then(function () {

                        //Publish Client User
                        DataService.publishClient().then(function () {

                            //Reset state for final Sign Up Now button
                            $scope.signupNowButtonClicked = false;

                            //Set state to logged in and redirect to the dashboard
                            $scope.isLoggedIn = true;
                            $window.location = '/dashboard';
                        });
                    });
                });
            });
        }
    };

    $scope.analyzePassword = function() {

        $scope.password.strength = checkPassStrength($scope.newUser.password);
    };

    $scope.$watch(function () {
        return AuthService.isLoggedIn()
    }, function (newVal, oldVal) {

        if (oldVal !== newVal) {
            $scope.isLoggedIn = newVal;
        }
    });

    var init = function() {

        // //Grab regions list
        // DataService.fetchMetaData('regions').then(function (response) {
        //
        //     var country = 'UK';
        //
        //     $scope.regions = [];
        //
        //     for(var i = 0;  i < response.regions.length; i++) {
        //
        //         if(response.regions[i].code !== 'INT' && response.regions[i].code !== 'FR' && response.regions[i].code !== 'DZ') {
        //
        //             $scope.regions.push(response.regions[i]);
        //         }
        //     }
        //
        //     $scope.newUser.region = country;
        //
        //     $.get("https://ipinfo.io", function(response) {
        //
        //         country = response.country.toUpperCase();
        //         country = country === 'GB' ? 'UK' : country;
        //
        //         switch(country) {
        //
        //             case 'US':
        //
        //                 $scope.newUser.region = country;
        //                 break;
        //
        //             case 'KE':
        //
        //                 $scope.newUser.region = country;
        //                 break;
        //
        //             case 'UK':
        //
        //                 $scope.newUser.region = country;
        //                 break;
        //             default:
        //
        //                 $scope.newUser.region = 'UK';
        //                 break;
        //         }
        //
        //     }, "jsonp").fail(function() {});
        // });

        //Grab industries list
        DataService.fetchMetaData('industries').then(function (response) {

            $scope.industries = response.industries;
        });

    };

    init();

}]);
