/**
 * Created by Barry on 2016/11/17.
 *
 * Directive to handle behaviour inbox dropdown in header
 */

onePulse.directive('inboxDropdown', ['DataService', 'AlertService', 'CONSTANTS', '$translate', '$location', '$rootScope',
    function (DataService, AlertService, CONSTANTS, $translate, $location, $rootScope) {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: false,
        templateUrl:'/app/views/elements/inboxDropdown.html',

        link: function postLink(scope, element, iAttrs) {

            $rootScope.hasUnreadMessages = false;

            var init = function() {

                //Fetch all inbox items
                DataService.fetchItem('user/inbox').then(function(result) {

                    scope.messages = [];

                    var allMessageCounter = 0;
                    var unreadMessageCounter = 0;

                    //Sort by created_at
                    result.inboxes = sortByProperty(result.inboxes, 'created_at', 'desc');

                    //Limit inbox items to NUMBER_OF_MESSAGES_INBOX_HEADER
                    for(var i = 0; i < result.inboxes.length; i++) {

                        if(allMessageCounter < CONSTANTS.NUMBER_OF_MESSAGES_INBOX_HEADER) {

                            allMessageCounter++;
                            scope.messages.push(result.inboxes[i])
                        }

                        if(!result.inboxes[i].data.is_read.value) {

                            unreadMessageCounter++;
                        }
                    }

                    //If unread messages exist in the inbox, display notification and red dot indicator
                    if(unreadMessageCounter > 0) {

                        $rootScope.hasUnreadMessages = true;

                        var translations;
                        $translate(['alert.unread_messages.title', 'alert.unread_messages.text']).then(function(result) {
                            translations = result;

                            AlertService.info(
                                translations['alert.unread_messages.title'],
                                translations['alert.unread_messages.text']
                            );
                        });
                    }

                    scope.originalMessages = angular.copy(scope.messages);
                })
            };

            init();

            scope.openMessage = function(message) {

                $location.path('/inbox/' + message.data.id.value);
            };

            scope.deregisterInboxUpdateHandler = $rootScope.$on('event:inbox-update', function() {

                init();
            });

            scope.$on('$destroy', function() {

                //De-register root scope event handler when scope is destroyed
                if (typeof scope.deregisterPulseUpdateForPreviewHandler != "undefined") {scope.deregisterPulseUpdateForPreviewHandler()}
            });
        }
    };
}]);