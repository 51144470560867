/**
 * Created by Barry on 15/03/17.
 *
 */
onePulse.controller('SharePulseController', ['$scope', '$routeParams', '$window', '$timeout', 'DataService', 'CONSTANTS', 'SocialShareService', 'AblyService',
    '$translate', 'AlertService', '$interval', 'SearchService', 'Config',
    function ($scope, $routeParams, $window, $timeout, DataService, CONSTANTS, SocialShareService, AblyService,
              $translate, AlertService, $interval, SearchService, Config) {

        $scope.webResponseURI = Config.get('WEB_RESPONSE_URI');
        $scope.clientWebURI = Config.get('OP_CLIENT_URI');

        $scope.pulsesLoaded = false;
        $scope.deeplinkOverlay = {"open": false};
        $scope.deeplinkDesktopOverlay = {"open": false};
        $scope.showScheduledToActiveTransitionSpinner = false;

        $scope.selectedBranch = 1;

        //Get device type - mobile/desktop
        $scope.deviceType = determineDeviceType(getViewportDimensions());

        //Get Mobile OS. 'unknown' for desktop
        $scope.mobileOS = getMobileOperatingSystem();

        //Config var for carousel - number of slides to show (default: desktop = 4 / mobile = 3)
        $scope.slidesToShow = $scope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP ? 3 : 2;

        $scope.pulses = null;
        $scope.pagination = null;

        $scope.search = {search: "", pulse: "", question: "", status: ""};

        $scope.ctrl = {
            "orderBy": "pulse.created_at",
            "orderDirection": "desc"
        };

        $scope.sort = {};
        $scope.sort.field = $scope.ctrl.orderBy;
        $scope.sort.order = $scope.ctrl.orderDirection;

        //Configure carousel for other pulses (enabled is set to true only after API call for pulses is complete)
        $scope.slickConfigPulses = {
            enabled: false,
            autoplay: false,
            draggable: true,
            autoplaySpeed: 3000,
            slidesToShow: $scope.slidesToShow,
            slidesToScroll: $scope.slidesToShow,
            method: {},
            event: {
                beforeChange: function (event, slick, currentSlide, nextSlide) {
                },
                afterChange: function (event, slick, currentSlide, nextSlide) {
                }
            }
        };

        //Get route params
        $scope.slug = $routeParams.slug;

        var deepLink = (typeof $routeParams.deeplink != "undefined");

        //Hide everything but charts if for Unbranded
        $scope.unbranded = (typeof $routeParams.unbranded != "undefined" ? $routeParams.unbranded : false);

        //Client results view
        $scope.clientResults = (typeof $routeParams.client != "undefined" ? $routeParams.client : false);

        //Hide/display header
        $scope.displayHeader = (typeof $routeParams.header != "undefined" ? $routeParams.header : true);

        //Hide/display answer on web button
        $scope.blockWeb = (typeof $routeParams.block_web != "undefined" ? $routeParams.block_web : false);

        //Handle app:scheme redirect
        var handleAppRedirect = function() {

            //1. if user has app installed, then they would be redirected to open the app to specified screen
            //2. if user doesn't have app installed, then their browser wouldn't recognize the URL scheme
            //and app wouldn't open since it's not installed. In 1 second (1000 milliseconds) user is redirected
            //to download app from app store.
            switch($scope.mobileOS) {

                case 'Android': //Start Android app and join pulse

                    //Deep link URL for existing users with app already installed on their device -->
                    $window.location = CONSTANTS.MOBILE_APP_SCHEME + "p/" + $scope.slug;

                    <!-- Download URL for new users to download the app -->
                    $timeout("window.location = '" + CONSTANTS.ANDROID_APP + "';", 1000);

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                case 'iOS': //Start iOS app and join pulse

                    //Deep link URL for existing users with app already installed on their device -->
                    $window.location = CONSTANTS.MOBILE_APP_SCHEME + "p/" + $scope.slug;

                    <!-- Download URL for new users to download the app -->
                    $timeout("window.location = '" + CONSTANTS.IOS_APP + "';", 1000);

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                default: //Not mobile, show app info block

                    $scope.deeplinkDesktopOverlay.open = !$scope.deeplinkDesktopOverlay.open;
                    break;
            }
        };

        //View Pulse - Real-time results
        var viewPulse = function() {

            //If Pulse Lite is not in a closed state
            if ($scope.pulse.status.value !== 'Closed') {

                var translations;
                $translate(['alert.pulse_completed.title', 'alert.pulse_completed.text',
                    'alert.pulse_completed.confirm_button_text']).then(function(result) {
                    translations = result;
                });

                $scope.ablyChannel = AblyService.client().channels.get('pulse:data:' + $scope.pulse.id.value);

                $scope.ablyChannel.presence.enter();

                //Subscribe to the pulse_completed channel
                $scope.ablyChannel.subscribe('pulse_completed', function($ablyMessage) {

                    AlertService.info(
                        translations['alert.pulse_completed.title'],
                        translations['alert.pulse_completed.text']
                    );

                    $scope.pulse.status.value = 'Closed';
                });

                $scope.ablyChannel.subscribe("pulse_go_live", function ($ablyMessage) {

                    //Check that pulse is not already active
                    if($scope.pulse.status.value !== "Active") {

                        $scope.showScheduledToActiveTransitionSpinner = true;

                        $scope.init();
                    }
                });

                $scope.lastAblyMessage = undefined;

                $scope.previousAblyMessage = undefined;

                //Subscribe to the user_responded channel
                $scope.ablyChannel.subscribe('user_responded',function($ablyMessage){

                    $scope.previousAblyMessage = $scope.lastAblyMessage;

                    $scope.lastAblyMessage = $ablyMessage;

                    $scope.$broadcast('event:user_responded',angular.fromJson($ablyMessage.data));
                });

            }
        };

        var initFaceBookSDK = function() {

            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "//connect.facebook.com/en_US/messenger.Extensions.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'Messenger'));
        };

        //Enroll in pulse
        $scope.enroll = function($event, pulse) {

            handleAppRedirect();
        };

        $scope.notifyClipboard = function() {

            var translations;
            $translate(['alert.pulse_id_copied.title', 'alert.pulse_id_copied.text']).then(function(result) {
                translations = result;

                AlertService.success(
                    translations['alert.pulse_id_copied.title'],
                    translations['alert.pulse_id_copied.text']
                )
            });
        };

        //Link to iOS/Android store
        $scope.storeLink = function() {

            switch($scope.mobileOS) {

                case 'Android':
                    $window.open(CONSTANTS.ANDROID_APP, '_blank');
                    break;
                case 'iOS':
                    $window.open(CONSTANTS.IOS_APP, '_blank');
                    break;
                default:
                    break;
            }
        };

        $scope.openOP = function() {

            switch($scope.mobileOS) {

                case 'Android':

                    $window.location = CONSTANTS.MOBILE_APP_SCHEME;

                    $timeout("window.location = '" + CONSTANTS.ANDROID_APP + "';", 1000);

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                case 'iOS':

                    $window.location = CONSTANTS.MOBILE_APP_SCHEME;

                    $timeout("window.location = '" + CONSTANTS.IOS_APP + "';", 1000);

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                default:

                    $scope.deeplinkDesktopOverlay.open = !$scope.deeplinkDesktopOverlay.open;
                    break;
            }
        };

        $scope.firstCheck = function($event) {

            switch($scope.mobileOS) {

                case 'Android': //Start Android app and join pulse

                    if($scope.pulse.status.value !== 'Closed') {

                        //Deep link URL for existing users with app already installed on their device -->
                        $window.location = CONSTANTS.MOBILE_APP_SCHEME + "p/" + $scope.slug;
                    } else {

                        $window.location = CONSTANTS.MOBILE_APP_SCHEME
                    }

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                case 'iOS':

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                default: //Not mobile, show app info block

                    $scope.deeplinkDesktopOverlay.open = !$scope.deeplinkDesktopOverlay.open;
                    break;
            }
        };

        //Share pulse
        $scope.share = function($event, pulse, provider) {

            SocialShareService.share("pulse", pulse, provider);
        };

        //Changes branch
        $scope.selectBranchCallback = function (event, branch) {

            $scope.selectedBranch = branch;

            event.preventDefault();
        };

        $scope.isActiveBranch = function (branch) {

            return $scope.selectedBranch == branch;
        };

        //Only display question in pulse editor on branch type and branched question
        $scope.questionBranchFilter = function (question) {

            if (typeof question.data.branch != "undefined") {

                return (question.data.branch.value == $scope.selectedBranch || question.data.branch.value == "");

            } else {

                return true;
            }
        };

        //Calculate the time until the pulse starts
        $scope.calculatePulseTimeUntilStart = function () {

            if ($scope.pulse.started_at != "undefined") {

                var currentDate = formatDate(new Date(), new Date());

                $scope.untilLive = dateDiff(currentDate, $scope.pulse.started_at.value);

                //Set the form of the word
                $scope.daysForm = $scope.untilLive.d == 1 ? "day" : "days";
                $scope.hoursForm = $scope.untilLive.h == 1 ? "hour" : "hours";
                $scope.minutesForm = $scope.untilLive.m == 1 ? "minute" : "minutes";
                $scope.secondsForm = $scope.untilLive.s == 1 ? "second" : "seconds";

                if($scope.untilLive.d === 0 && $scope.untilLive.h === 0 && $scope.untilLive.m === 0 && $scope.untilLive.s === 0) {

                    showScheduledToActiveTransitionSpinner = true;
                }
            }
        };

        $scope.calculatePulseTimeSinceStart = function () {

            if (typeof $scope.pulse.published_at != "undefined" && $scope.pulse.published_at.value != null) {

                var currentDate = formatDate(new Date(), new Date());
                var publishedAt = ((new Date($scope.pulse.published_at.value) < new Date($scope.pulse.started_at.value) || $scope.pulse.published_at.value.charAt(0) == "-") ? $scope.pulse.started_at.value : $scope.pulse.published_at.value);

                if($scope.pulse.status.value == "Closed") {

                    $scope.liveFor = dateDiff(publishedAt, $scope.pulse.ended_at.value);
                } else {

                    $scope.liveFor = dateDiff(publishedAt, currentDate);
                }

                //Set the form of the word
                $scope.daysForm = $scope.liveFor.d == 1 ? "day" : "days";
                $scope.hoursForm = $scope.liveFor.h == 1 ? "hour" : "hours";
                $scope.minutesForm = $scope.liveFor.m == 1 ? "minute" : "minutes";
                $scope.secondsForm = $scope.liveFor.s == 1 ? "second" : "seconds";
            }
        };

        $scope.processPulseStartAndCloseDateTimes = function () {

            if (typeof $scope.pulse.published_at != "undefined" && $scope.pulse.published_at.value != null) {

                var publishedAt = new Date((new Date($scope.pulse.published_at.value) < new Date($scope.pulse.started_at.value) || $scope.pulse.published_at.value.charAt(0) == "-") ? $scope.pulse.started_at.value : $scope.pulse.published_at.value);

                $scope.pulseStartedDate = prettyDate(publishedAt, false, false);
                $scope.pulseStartedTime = formatTime(publishedAt, false);
                $scope.pulseStartedDateTime = simpleDate(publishedAt) + " at " + formatTime(publishedAt, true);
            }

            if (typeof $scope.pulse.ended_at != "undefined") {

                var ended_at = new Date($scope.pulse.ended_at.value);

                $scope.pulseClosedDate = prettyDate(ended_at, false);
                $scope.pulseClosedTime = formatTime(ended_at, false);
                $scope.pulseClosedDateTime = simpleDate(ended_at) + " at " + formatTime(ended_at, true);
            }

            if (typeof $scope.pulse.expired_at != "undefined") {

                var expired_at = new Date($scope.pulse.expired_at.value);

                $scope.pulseExpiresDate = prettyDate(expired_at, false);
                $scope.pulseExpiresTime = formatTime(expired_at, false);
                $scope.pulseExpiresDateTime = simpleDate(expired_at) + " at " + formatTime(expired_at, true);
            }
        };

        $scope.showQuestion = function (question) {

            if ($scope.pulse.is_branched.value == '1') {

                if (question.data.branch != undefined) {
                    if (question.data.branch.value == $scope.selectedBranch) {
                        return true;
                    } else if (question.data.sequence.value = 1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }

            } else {
                return true;
            }
        };

        //Start the interval for the countdown duration
        $scope.startCountDownDuration = function() {

            // stops any running interval to avoid two intervals running at the same time
            $scope.stopCountDownDuration();

            //Interval for the running pulse duration clock - updates every second
            $scope.countDownDurationpromise = $interval(function() {

                $scope.calculatePulseTimeUntilStart();
            }, 1000);
        };

        //Stop the interval for the countdown duration
        $scope.stopCountDownDuration = function() {

            $interval.cancel($scope.countDownDurationpromise);
        };

        //Redirect to pulse share
        $scope.redirectToPulseShare = function(url) {

            $window.open(url, '_blank');
        };

        //Initialise Pulse share
        $scope.init = function () {

            initFaceBookSDK();

            //Pull pulse data with $scope.slug and present to user
            DataService.fetchShareData('pulse', $scope.slug, null).then(function (result) {

                $scope.pulse = result.pulse;

                $scope.calculatePulseTimeSinceStart();

                $scope.processPulseStartAndCloseDateTimes();

                //Initialise pulse countdown for scheduled pulses
                if($scope.pulse.status.value == 'Scheduled') {

                    $scope.startCountDownDuration();
                }

                $scope.showScheduledToActiveTransitionSpinner = false;

                //Determine question format
                for(var j = 0; j < $scope.pulse.questions.length; j++) {

                    if(typeof $scope.pulse.questions[j].data.format.options != 'undefined') {

                        for (var i = 0; i < $scope.pulse.questions[j].data.format.options.length; i++) {

                            if ($scope.pulse.questions[j].data.format.options[i].option.selected == true) {

                                $scope.pulse.questions[j].formatText = $scope.pulse.questions[j].data.format.options[i].option.text;
                            }
                        }
                    } else {

                        $scope.pulse.questions[j].formatText = $scope.pulse.questions[j].data.format.value;
                    }

                    if($scope.pulse.questions[j].formatText == 'Free Text') {

                        //Set initial display type for free text question: results|sentiment|wordCloud
                        $scope.pulse.questions[j].displayType = CONSTANTS.QUESTION_FREE_TEXT_INITIAL_DISPLAY_TYPE;
                    }
                }

                //If results are shareable, grab the data
                if($scope.pulse.is_result_shareable.value == "1") {

                    viewPulse();
                }

                if($scope.pulse.status.value == "Active") {

                    //If the referring url is a deep link and the share is being viewed on a mobile device, we process accordingly
                    if (deepLink && ($scope.mobileOS == "Android" || $scope.mobileOS == "iOS")) {

                        handleAppRedirect();
                    }
                }
            });

            //Pull all other active pulses
            $scope.search.search = "";
            $scope.search.status = "Active";

            SearchService.performSearch("es", "pulses", $scope.search, null, $scope.sort, false, false).then(function (result) {

                $scope.pulses = [];

                for(var i = 0; i < result.pulses.length; i++) {

                    if(result.pulses[i].data.share_links.length != 0) {

                        $scope.pulses.push(result.pulses[i]);
                    }
                }

                $scope.pagination = result.pagination;

                //Enable carousel
                $scope.pulsesLoaded = true;
                $scope.slickConfigPulses.enabled = true;
            });
        };

        $scope.init();

        //Leave pulse channel on location change.
        $scope.$on('$locationChangeStart', function (event, next, current) {

            if (typeof $scope.ablyChannel != "undefined" && $scope.ablyChannel != null) {

                $scope.ablyChannel.presence.leave();
                $scope.ablyChannel.detach();
                AblyService.disconnect();
            }
        });

        //Leave pulse channel on scope destroy.
        $scope.$on('$destroy', function() {

            if (typeof $scope.ablyChannel != "undefined" && $scope.ablyChannel != null) {

                $scope.ablyChannel.presence.leave();
                $scope.ablyChannel.detach();
                AblyService.disconnect();
            }

            if(!$scope.isLoggedIn) {

                $window.localStorage.clear();
            }
        });

    }]);
