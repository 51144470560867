/**
 * Created by Barry on 2018/05/25.
 *
 * Pulse question card
 */

onePulse.directive('questionCard', [
    function () {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: false,
        templateUrl:'/app/views/elements/pulse/questionCard.html',

        link: function postLink(scope, element, iAttrs) {

        }
    };
}]);