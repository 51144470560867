/**
 * Created by mike on 08/12/15.
 */
onePulse.controller("BodyController", [
  "$scope",
  "$rootScope",
  "AuthService",
  "DataService",
  "$mdDialog",
  "TokenService",
  "RoleService",
  "PulseService",
  "$location",
  "$filter",
  "Config",
  "AlertService",
  "$window",
  "$translate",
  "$sce",
  "$timeout",
  "ContentService",
  "CONSTANTS",
  "ValidationService",
  function (
    $scope,
    $rootScope,
    AuthService,
    DataService,
    $mdDialog,
    TokenService,
    RoleService,
    PulseService,
    $location,
    $filter,
    Config,
    AlertService,
    $window,
    $translate,
    $sce,
    $timeout,
    ContentService,
    CONSTANTS,
    ValidationService
  ) {
    $scope.isLoggedIn = AuthService.isLoggedIn();

    //Swap below when ready to add complete reg check for client:new
    $scope.showHeader =
      AuthService.isLoggedIn() && $location.absUrl().search("/share") == -1;
    //$scope.showHeader = ($scope.isLoggedIn && $location.absUrl().search('/share') == -1 && AuthService.role() != CONSTANTS.ROLES.CLIENT_NEW);

    $scope.calendly = { link: CONSTANTS.CALENDLY_LINK };

    $scope.user = DataService.grab("user");
    $scope.transitionClass = "hello_body";

    $rootScope.showPhone = true;
    $rootScope.switchAccount = false;

    //Env specific control for displaying pricing page/pricing modals
    $rootScope.showPricing = Config.get("SHOW_PRICING");

    //Default active tab in Panels View
    $rootScope.panelShow = "public";

    //Default view type for Panels and Pulses Lists (grid/list)
    $rootScope.panelView =
      typeof $window.localStorage["panel_view"] == "undefined"
        ? "grid"
        : $window.localStorage["panel_view"];
    $rootScope.pulseView =
      typeof $window.localStorage["pulse_view"] == "undefined"
        ? "grid"
        : $window.localStorage["pulse_view"];

    $rootScope.panels = [];

    //Toggle control for common toolbar search
    $rootScope.toolbarSearchToggle = false;

    //Toggle control for help panels
    $rootScope.pulsesHelpShow = true;
    $rootScope.panelsHelpShow = true;
    $rootScope.pulseHelpShow = true;
    $rootScope.accountHelpShow = true;

    $rootScope.displayWorkspaceLoginOverlay = { open: false };

    //Control var for loaders
    $rootScope.filtersLoaded = true;

    //Default search value for pulses list
    $rootScope.currentSearch = "";

    //Watch variable for modal - UserController needs for circliful - modals share controller
    $rootScope.modalOpen = false;

    //The feature currently being displayed as locked
    $rootScope.currentLockedFeature = null;

    //Control for topup popup triggered from footer
    $rootScope.topupComingFromFooter = false;

    $rootScope.loadingStateText = "";

    //Holds pulse created from pulse list - for use in pulse controller - to avoid unnecessary pulse call
    $rootScope.createdPulse = null;

    //Holds start and end date for pulse scheduler - need these to limit timepicker
    $rootScope.pulseDateEnd = null;
    $rootScope.pulseDateStart = null;

    $rootScope.pickerType = null;

    $rootScope.errorOnBranchChange = false;
    $rootScope.answerTexts = [];

    //Control variable for payment method
    $rootScope.isDefault = true;

    //Dropdown Toggle
    $scope.toggleDropdown = toggleDropdown;

    // console.log($rootScope.renderComplete);

    // if ($rootScope.renderComplete) {
    //   console.log($rootScope.renderComplete);

    //   var elements = angular.element(
    //     document.querySelector(".circles-container .circle")
    //   );
    //   console.log(elements);
    //     elements.addClass("in");
    // }

    $scope.slickConfigHomeBrands = {
      enabled: true,
      autoplay: true,
      draggable: true,
      infinite: true,
      arrows: false,
      autoplaySpeed: 3000,
      centerPadding: 0,
      slidesToShow: 8,
      slidesToScroll: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      method: {},
      event: {
        beforeChange: function (event, slick, currentSlide, nextSlide) {},
        afterChange: function (event, slick, currentSlide, nextSlide) {},
      },
    };

    $scope.slickConfigProductPage = {
      enabled: true,
      autoplay: true,
      draggable: true,
      infinite: true,
      arrows: false,
      autoplaySpeed: 3000,
      centerMode: true,
      centerPadding: 0,
      slidesToShow: 3,
      pauseOnFocus: false,
      pauseOnHover: false,
      method: {},
      event: {
        beforeChange: function (event, slick, currentSlide, nextSlide) {},
        afterChange: function (event, slick, currentSlide, nextSlide) {},
      },
    };

    $scope.slickConfigProductPageAudience = {
      enabled: true,
      autoplay: true,
      draggable: true,
      infinite: true,
      arrows: false,
      adaptiveHeight: true,
      autoplaySpeed: 3000,
      vertical: true,
      centerMode: true,
      centerPadding: 0,
      slidesToShow: 3,
      pauseOnFocus: false,
      pauseOnHover: false,
      method: {},
      event: {
        beforeChange: function (event, slick, currentSlide, nextSlide) {},
        afterChange: function (event, slick, currentSlide, nextSlide) {},
      },
    };

    //Config var for carousel - number of slides to show (default: desktop = 4 / mobile = 3)
    $scope.slides = $scope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP ? 4 : 3;

    $scope.slickConfigLaunchUsa = {
      enabled: true,
      autoplay: true,
      draggable: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      method: {},
      event: {
        beforeChange: function (event, slick, currentSlide, nextSlide) {},
        afterChange: function (event, slick, currentSlide, nextSlide) {},
      },
    };


    $scope.displayFooter =
      $location.absUrl().search("/login") == -1 &&
      $location.absUrl().search("/chart") == -1 &&
      $location.absUrl().search("/launch") == -1 &&
      $location.absUrl().search("/share") == -1 &&
      $location.absUrl().search("/try-me") == -1 &&
      $rootScope.renderComplete == true;

    //Only pull content for CMS supported pages
    if (["/terms", "/privacy"].indexOf($location.path()) != -1) {
      var contentType = $location.path().split("/")[1];

      $scope.content = ContentService.fetchContent(contentType);
    }

    $scope.isActive = function (viewLocation) {
      var pathArray = $location.path().split("/");

      return viewLocation == "/" + pathArray[1];
    };

    $scope.createPulse = function ($event) {

      PulseService.createPulse($event)
    };

    /*
     MM:

     We should just call save on any change - this is the easies thing as it will also update all the JSON so you dont need to make your own prototypes

     So when panel changed, when image upload compelte, when any question format change is made, each of this should just change the json and trigger save
     */
    $scope.savePulse = function (pulse, notify, loader, faded) {
      pulse = ValidationService.duplicateAnswerCorrection(pulse);

      pulse = ValidationService.answerSequenceCorrection(pulse);

      pulse = $scope.checkLinks(pulse);

      $scope.$broadcast("event:pulse-saving");

      loader = typeof loader == "undefined" ? true : loader;
      faded = typeof faded == "undefined" ? false : faded;

      if (loader) {
        $rootScope.renderComplete = false;
        $rootScope.loadingStateText = "Saving Pulse";
      }

      if (faded) {
        $rootScope.fadedOverlay = true;
        $rootScope.loadingStateText = "";
      }

      //Set correct order of D&D question answers if it is necessary
      // for (var i = 0; i < pulse.data.questions.length; i++) {
      //   if (typeof pulse.data.questions[i].answerTexts != "undefined") {
      //     for (
      //       var j = 0;
      //       j < pulse.data.questions[i].data.answers.length;
      //       j++
      //     ) {
      //       pulse.data.questions[i].data.answers[j].data.text.value =
      //         pulse.data.questions[i].answerTexts[j];
      //       pulse.data.questions[i].data.answers[j].data.sequence.value = (
      //         j + 1
      //       ).toString();
      //     }
      //   }
      // }

      DataService.submit("pulse", pulse).then(function (result) {
        if ($rootScope.routeChanged) {
          $rootScope.routeChanged = false;

          $window.location = $rootScope.routeChangedPath;
        } else {
          $rootScope.fadedOverlay = false;
          $rootScope.renderComplete = true;
          $rootScope.loadingStateText = "";
        }

        if (notify) {
          var translations;
          $translate([
            "alert.pulse_saved.title",
            "alert.pulse_saved.text",
          ]).then(function (result) {
            translations = result;

            AlertService.success(
              translations["alert.pulse_saved.title"],
              translations["alert.pulse_saved.text"]
            );
          });
        }

        $rootScope.filtersLoaded = true;

        $rootScope.$broadcast("event:pulse-update", result.pulse);

        if ($scope.isTranslated) {
          DataService.submit("pulse/translation", $scope.translation).then(
            function (result) {
              $scope.translation = angular.fromJson(result.translation);
              $scope.$broadcast("event:pulse-translated");
            }
          );
        }

        $scope.$broadcast("event:pulse-saved");
      });
    };

    //Set which panel tab is currently visible
    $scope.setPanelShow = function (type) {
      $rootScope.panelShow = type;
    };

    $scope.setPanelView = function (type) {
      $rootScope.panelView = type;
      $window.localStorage["panel_view"] = type;
    };

    /*
    MM: Stop return
     */

    $scope.hijackReturn = function ($event) {
      if ($event.which === 13) {
        $event.preventDefault();
      }
    };

    $scope.getRegionLanguage = function(externalCode) {
        var langCode;
        if (externalCode !== '' && externalCode !== undefined && externalCode !== null) {
          langCode = externalCode.substring(0,3);
        } else {
          langCode = "ENG"
        }
        var language = CONSTANTS.LANGUAGES[langCode]
        return language
    };

    $scope.topupFromFooter = function () {
      if ($location.path() == "/user/plans") {
        $rootScope.modalOpen = true;

        $mdDialog.show({
          controller: "BillingController",
          templateUrl: "app/views/elements/includes/billing-options.html",
          clickOutsideToClose: true,
        });
      } else {
        $rootScope.topupComingFromFooter = true;
        $location.path("/user/plans");
      }
    };

    //Check pulse links - If they are not empty and do not contain either http:// or https://, then prepend http://
    //Currently not being used - Will consider its use at a later stage
    $scope.checkLinks = function (pulse) {
      if (pulse.data.link_url.value != "") {
        if (
          pulse.data.link_url.value.indexOf("http://") == -1 &&
          pulse.data.link_url.value.indexOf("https://") == -1
        ) {
          pulse.data.link_url.value = "http://" + pulse.data.link_url.value;
        }
      } else {
        //If the link does not exist, make sure all options are set to false
        for (var k = 0; k < pulse.data.link_type.options.length; k++) {
          pulse.data.link_type.options[k].option.selected = false;
        }
      }

      //Question links
      for (var i = 0; i < pulse.data.questions.length; i++) {
        if (
          typeof pulse.data.questions[i].data.link_url.value != "undefined" &&
          pulse.data.questions[i].data.link_url.value != ""
        ) {
          if (
            pulse.data.questions[i].data.link_url.value.indexOf("http://") ==
              -1 &&
            pulse.data.questions[i].data.link_url.value.indexOf("https://") ==
              -1
          ) {
            pulse.data.questions[i].data.link_url.value =
              "http://" + pulse.data.questions[i].data.link_url.value;
          }
        } else {
          //If the link does not exist, make sure all options are set to false
          for (
            var k = 0;
            k < pulse.data.questions[i].data.link_type.options.length;
            k++
          ) {
            pulse.data.questions[i].data.link_type.options[
              k
            ].option.selected = false;
          }
        }
      }

      return pulse;
    };

    //Modal for Calendly Iframe
    $scope.calendlyDialog = function (ev) {
      calendlyDialogController.$inject = ["$scope", "$mdDialog"];
      $mdDialog.show({
        controller: calendlyDialogController,
        templateUrl: "app/views/elements/includes/calendly.html",
        clickOutsideToClose: false,
      });
    };

    //Controller for Modal Calendly Iframe
    function calendlyDialogController($scope, $mdDialog) {
      $scope.closeDialog = function () {
        $mdDialog.hide();
      };
    }

    //Modal for subscription type selection
    $scope.popupChangePlanSelectionDialog = function () {
      $rootScope.modalOpen = true;

      $mdDialog.show({
        controller: "UserController",
        templateUrl: "app/views/elements/includes/plan-subscription.html",
        // fullscreen: true
      });
    };

    //Testimonial HTML for carousel
    $scope.testimonials = [
      //$sce.trustAsHtml("<div class='testimonial'><div class='tesimonial-logo'><img src='/assets/img/testimonials/bbh.png' alt='' /></div><div class='testimonial-content'><p>OnePulse is a revelation. Never has testing a hypotheses with real consumers in real time been so easy, cost-effective, insightful, fast and fun. I think every planner should use this as part of their research toolbox.</p></div> <div class='testimonial-name'><span>Achim Schuarte, Strategy Director, BBH London</span></div></div>"),
      $sce.trustAsHtml(
        "<div class='testimonial'><div class='tesimonial-logo'><img src='/assets/img/testimonials/zenith.png' alt='' /></div><div class='testimonial-content'><p>OnePulse is one of the fast and frugal tools that we use to make sure all our recommendations are evidence based. It allows us to survey a wide range of tightly defined audiences and get the results back quicker than we could from anywhere else.</p></div> <div class='testimonial-name'><span>Head of Research, Zenith Optimedia</span></div></div>"
      ),
      $sce.trustAsHtml(
        "<div class='testimonial'><div class='tesimonial-logo'><img src='/assets/img/testimonials/kitcatt.png' alt='' /></div><div class='testimonial-content'><p>OnePulse has become an invaluable way for us to quickly and reliably get genuinely insightful information from consumers.  It’s fast becoming an indispensable tool in the way we do business.</p></div> <div class='testimonial-name'><span>Head of Creative Strategy, Kitcatt Nohr</span></div></div>"
      ),
      $sce.trustAsHtml(
        "<div class='testimonial'><div class='tesimonial-logo'><img src='/assets/img/testimonials/marks&spencer.png' alt='' /></div><div class='testimonial-content'><p>OnePulse has allowed my team to make decisions and validate assumptions at a speed we never thought possible. It’s changed the way we do business.</p></div> <div class='testimonial-name'><span>Head of Shopper Insights, Marks & Spencer</span></div></div>"
      ),
      $sce.trustAsHtml(
        "<div class='testimonial'><div class='tesimonial-logo'><img src='/assets/img/testimonials/monzo.png' alt='' /></div><div class='testimonial-content'><p>It's important to us at Monzo to always gather a variety of opinions before we make an important decision. We use OnePulse to get feedback from a wide range of people within hours. It takes just a few minutes to set up a question on their site, decide what kind of people we wanted to ask, and not long after we had clear results.</p></div> <div class='testimonial-name'><span>Head of Marketing, Monzo</span></div></div>"
      ),
    ];

    //Organizations HTML for carousel
    $scope.organizations = [
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/cocacola.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/barclays.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/google.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/tesco.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/virginMedia.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/kfc.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/iris.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/eventbrite.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/aviva.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/grey.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/monzo.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/marksSpencer.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/zenithOptimedia.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/starcom.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/lion.png" alt="" /></div>'
      ),
      $sce.trustAsHtml(
        '<div class="org_brand"><img src="/assets/img/organisations/red.png" alt="" /></div>'
      ),
    ];
  },
]);
