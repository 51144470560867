/**
 * Created by Barry on 13/06/2017.
 *
 * Directive for displaying a question sentiment overall view card
 */
onePulse.directive('questionSentimentOverall', ['DataService', 'googleChartApiPromise', '$mdDialog', 'FeatureService', '$filter', '$compile', '$sce',
    'CONSTANTS', '$timeout',
    function(DataService, googleChartApiPromise, $mdDialog, FeatureService, $filter, $compile, $sce,
             CONSTANTS, $timeout) {

        linkFunction = function($scope,element,attributes) {

            //Determine question format
            if (typeof $scope.question.data.format.options != 'undefined') {

                for (var i = 0; i < $scope.question.data.format.options.length; i++) {

                    if ($scope.question.data.format.options[i].option.selected == true) {

                        $scope.question.formatText = $scope.question.data.format.options[i].option.text;
                    }
                }
            } else {

                $scope.question.formatText = $scope.question.data.format.value;
            }

            //Only define link function if question format is correct
            if ($scope.question.formatText == 'Free Text') {


                $scope.sentimentDataLoaded = false;
                $scope.wordCloudDataLoaded = false;
                $scope.resultsDataLoaded = false;

                $scope.showAllComments = false;

                $scope.loaded = false;

                $scope.validAnswerCount = 1;

                /*For the overlay directive*/
                $scope.labels = {};

                $scope.annotations = {};

                $scope.filteredAnnotations = {};

                $scope.showBarchart = false;
                $scope.showColumnchart = true;

                $scope.series = {
                    0: {color: '#D3D3D3'},
                    1: {color: '#50E3C2'}
                };

                $scope.filteredSeries = {
                    0: {color: '#015798'}, 1: {color: '#42A2F2'}, 2: {color: '#01695C'}, 3: {color: '#850D4F'},
                    4: {color: '#E61D63'}, 5: {color: '#FF5721'}, 6: {color: '#FFA800'}, 7: {color: '#FBD202'}
                };

                $scope.animation = {
                    duration: 800,
                    easing: 'linear',
                    startup: true
                };

                /*
                 To style the pop up data table
                 */
                $scope.tableClassNames = {
                    headerRow: "data-table-header",
                    tableRow: "data-table-row",
                    oddTableRow: "data-table-odd-row",
                    selectedTableRow: "data-table-selected-row",
                    hoverTableRow: "data-table-hover-row"
                };

                $scope.originalResults = null;

                $scope.averageSentiment = {
                    score: 0.00
                };

                $scope.mostPositiveSentiment = {
                    userDisplayName: "",
                    text: "",
                    score: 0.00
                };

                $scope.mostNegativeSentiment = {
                    userDisplayName: "",
                    text: "",
                    score: 0.00
                };

                $scope.popOver = {content: ""};

                $scope.determineSentimentColour = function (score) {

                    var colourRGB = null;

                    if (parseFloat(score) > -1.00 && parseFloat(score) < -0.85) {

                        colourRGB = "rgb(255,29,50)";
                    } else if (parseFloat(score) >= -0.85 && parseFloat(score) < -0.70) {

                        colourRGB = "rgb(255,69,50)";
                    } else if (parseFloat(score) >= -0.70 && parseFloat(score) < -0.55) {

                        colourRGB = "rgb(255,108,51)";
                    } else if (parseFloat(score) >= -0.55 && parseFloat(score) < -0.30) {

                        colourRGB = "rgb(255,147,51)";
                    } else if (parseFloat(score) >= -0.30 && parseFloat(score) < 0.15) {

                        colourRGB = "rgb(255,187,51)";
                    } else if (parseFloat(score) >= 0.15 && parseFloat(score) < 0.30) {

                        colourRGB = "rgb(255,228,77)";
                    } else if (parseFloat(score) >= 0.30 && parseFloat(score) < 0.55) {

                        colourRGB = "rgb(179,210,72)";
                    } else if (parseFloat(score) >= 0.55 && parseFloat(score) < 0.70) {

                        colourRGB = "rgb(140,201,70)";
                    } else {

                        colourRGB = "rgb(51,201,72)";
                    }

                    return colourRGB;
                };

                $scope.determineSalientScore = function (score) {

                    var salientScore = null;

                    if (parseFloat(score) > -1.00 && parseFloat(score) < -0.60) {

                        salientScore = "Very Negative";
                    } else if (parseFloat(score) >= -0.60 && parseFloat(score) < -0.20) {

                        salientScore = "Negative";
                    } else if (parseFloat(score) >= -0.20 && parseFloat(score) < 0.20) {

                        salientScore = "Neutral";
                    } else if (parseFloat(score) >= 0.20 && parseFloat(score) < 0.60) {

                        salientScore = "Positive";
                    } else {

                        salientScore = "Very Positive";
                    }

                    return salientScore;
                };

                var filename = function (ext) {
                    return $scope.result.question.text.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.' + ext;
                };

                //Calculate absolute positions for most negative, most positive and average score pins
                var calculateOverlayPositions = function () {

                    //Calculate positions and display overlay elements
                    var midPoint = ($scope.chartArea.width / 2);

                    //Most negative point pin
                    var negativePointSign = Math.sign($scope.mostNegativeSentiment.score);
                    var negativeElem = angular.element('#most-negative-sentiment-' + $scope.question.data.id.value);

                    var negativePointOffset = (midPoint / 100) * ($scope.mostNegativeSentiment.score * 100);
                    negativePointOffset = (negativePointSign == 1 ? negativePointOffset : -Math.abs(negativePointOffset));

                    negativeElem.css({
                        position: 'absolute',
                        left: ($scope.chartArea.left - 9) + midPoint + negativePointOffset + 'px',
                        top: ($scope.chartArea.top + ($scope.chartArea.height - 11)) + 'px'
                    });

                    //Most positive point pin
                    var positivePointSign = Math.sign($scope.mostPositiveSentiment.score);
                    var positiveElem = angular.element('#most-positive-sentiment-' + $scope.question.data.id.value);

                    var positivePointOffset = (midPoint / 100) * ($scope.mostPositiveSentiment.score * 100);
                    positivePointOffset = (positivePointSign == 1 ? positivePointOffset : -Math.abs(positivePointOffset));

                    positiveElem.css({
                        position: 'absolute',
                        left: ($scope.chartArea.left - 9) + midPoint + positivePointOffset + 'px',
                        top: ($scope.chartArea.top + ($scope.chartArea.height - 11)) + 'px'
                    });

                    //Average point pin
                    var averageSign = Math.sign($scope.averageSentiment.score);
                    var averageElem = angular.element('#average-sentiment-container-' + $scope.question.data.id.value);

                    var averageOffset = (midPoint / 100) * ($scope.averageSentiment.score * 100);
                    averageOffset = (averageSign == 1 ? averageOffset : -Math.abs(averageOffset));

                    averageElem.css({
                        position: 'absolute',
                        left: ($scope.chartArea.left - 26) + midPoint + averageOffset + 'px',
                        top: ($scope.chartArea.top + ($scope.chartArea.height - 22)) + 'px'
                    });
                };

                //Calculate most negative, most positive and average sentiment scores
                var calculateSentiments = function (data_table) {

                    var sentimentCount = data_table.length - 1;
                    var sentimentTotal = 0.00;

                    for (var i = 1; i < data_table.length; i++) {

                        //Set the baseline
                        if (i == 1) {

                            $scope.mostPositiveSentiment.userDisplayName = data_table[i][13];
                            $scope.mostPositiveSentiment.text = data_table[i][7];
                            $scope.mostPositiveSentiment.score = parseFloat(data_table[i][11]).toFixed(2);

                            $scope.mostNegativeSentiment.userDisplayName = data_table[i][13];
                            $scope.mostNegativeSentiment.text = data_table[i][7];
                            $scope.mostNegativeSentiment.score = parseFloat(data_table[i][11]).toFixed(2);
                        }

                        //Set the most positive sentiment
                        if (parseFloat(data_table[i][11]) >= parseFloat($scope.mostPositiveSentiment.score)) {

                            $scope.mostPositiveSentiment.userDisplayName = data_table[i][13];
                            $scope.mostPositiveSentiment.text = data_table[i][7];
                            $scope.mostPositiveSentiment.score = parseFloat(data_table[i][11]).toFixed(2);
                        }

                        //Set the most negative sentiment
                        if (parseFloat(data_table[i][11]) <= parseFloat($scope.mostNegativeSentiment.score)) {

                            $scope.mostNegativeSentiment.userDisplayName = data_table[i][13];
                            $scope.mostNegativeSentiment.text = data_table[i][7];
                            $scope.mostNegativeSentiment.score = parseFloat(data_table[i][11]).toFixed(2);
                        }

                        //Add to the total
                        sentimentTotal += parseFloat(data_table[i][11]);
                    }

                    //Calculate average
                    $scope.averageSentiment.score = parseFloat(parseFloat(sentimentTotal) / sentimentCount).toFixed(2);
                };

                //Assign sentiments to buckets (currently 0.10)
                var assignSentimentsToBuckets = function (dataTable) {

                    for (var i = 1; i < dataTable.length; i++) {

                        dataTable[i][11] = (Math.round(dataTable[i][11] * 10) / 10).toFixed(2);
                    }

                    return dataTable;
                };

                var getQuestionData = function () {

                    $scope.sentimentDataLoaded = false;
                    $scope.wordCloudDataLoaded = false;
                    $scope.resultsDataLoaded = false;

                    $scope.$parent.showOTChartSpinner = true;

                    DataService.getQuestionData($scope.question.data_token).then(function (result) {

                        $scope.originalResults = angular.copy(result);

                        result.data_table = assignSentimentsToBuckets(result.data_table);

                        calculateSentiments(result.data_table);

                        //populateKeyPopup();

                        buildChart(result);
                    });
                };

                //Build the chart
                var buildChart = function (result) {

                    $scope.filters = result.filters;
                    $scope.result = result;

                    if ($scope.result.question.total_responses > 1) {

                        $scope.showFilterControls = true;
                    }

                    //Total number of unique participants
                    $scope.uniqueIdArray = [];

                    for (var i = 1; i < $scope.result.data_table.length; i++) {

                        $scope.result.data_table[i][12] = $scope.result.data_table[i][12].toString();

                        if ($scope.uniqueIdArray.indexOf($scope.result.data_table[i][0]) == -1) {

                            $scope.uniqueIdArray.push($scope.result.data_table[i][0]);
                        }
                    }

                    $scope.question.total_responses_multiple = $scope.uniqueIdArray.length;

                    googleChartApiPromise.then(function () {

                        $scope.dataTable = new google.visualization.arrayToDataTable(result.data_table);

                        //Add dataTable to scoped question for later use. Need to include raw response table too for header
                        $scope.question.dataTable = $scope.dataTable;
                        $scope.question.rawDataTable = result.data_table;

                        //Dynamically add user avatar and response data to results block
                        $scope.$on('event:user_responded', function ($event, $payload) {

                            $scope.sentimentDataLoaded = true;
                            $scope.$parent.showOTChartSpinner = false;

                            angular.forEach($payload.data[$scope.question.data.id.value], function (row) {

                                //API can not be depended on to always return a string. Need to make sure type is properly set
                                for (var k = 0; k < row.length; k++) {

                                    if (row[k] != null) {

                                        row[k] = row[k].toString();
                                    }
                                }

                                $scope.dataTable.addRow(row);

                                //Push ably response row into results for filtered chart and region filter
                                result.data_table.push(row);
                                $scope.originalResults.data_table.push(row);

                                //Activate new filter if necessary
                                if (typeof $scope.currentFilterCategory != "undefined" && $scope.currentFilterCategory != null) {

                                    for (var i = 0; i < $scope.currentFilterCategory.values.options.length; i++) {

                                        for (var j = 0; j < row.length; j++) {

                                            if ($scope.currentFilterCategory.values.options[i].option.text == row[j]) {

                                                $scope.currentFilterCategory.values.options[i].option.showFilter = true;
                                            }
                                        }
                                    }
                                }

                            });

                            //Add dataTable to scoped question for later use
                            $scope.question.dataTable = $scope.dataTable;
                            $scope.question.rawDataTable = result.data_table;

                            $scope.table.data = $scope.dataTable;

                            result.data_table = assignSentimentsToBuckets(result.data_table);

                            calculateSentiments(result.data_table);

                            calculateOverlayPositions();

                            //If a filter has been set, we keep the filtered charts active
                            if (typeof $scope.currentFilterCategory != "undefined" && $scope.currentFilterCategory != null) {

                                $scope.$broadcast('event:filter-switch', $scope.currentFilterCategory);
                            } else {

                                $scope.sentimentChart.data = $scope.printColumnChart.data = $scope.dataView = createVisualisation($scope.dataTable);
                                $scope.showBarchart = false;
                                $scope.showColumnchart = true;

                                $scope.$apply();
                            }
                        });


                        /*
                         Do Raw Data Table
                         */
                        $scope.table = {};

                        $scope.table.type = "Table";

                        $scope.table.data = $scope.dataTable;

                        $scope.table.options = {
                            cssClassNames: $scope.tableClassNames
                        };

                        $scope.barChart = {};

                        $scope.barChart.type = "BarChart";

                        $scope.barChartId = randomString(8);

                        $scope.barChart.options = {
                            fontSize: '14',
                            fontWeight: 'bold',
                            height: $scope.height,
                            chartArea: {
                                width: "100%",
                                height: "100%"
                            },
                            animation: $scope.animation,
                            legend: {
                                position: 'none'
                            },
                            tooltip: {
                                trigger: 'focus',
                                ignoreBounds: true,
                                isHtml: true
                            },
                            focusTarget: 'category',
                            bar: {
                                groupWidth: "18"
                            },
                            isStacked: false,
                            series: $scope.unfilteredSeries,
                            hAxis: {
                                gridlines: {
                                    color: 'transparent'
                                },
                                viewWindow: {
                                    min: 0,
                                    max: ($scope.question.total_responses_multiple == 0 ? 1 : $scope.question.total_responses_multiple)
                                },
                                textPosition: 'none',
                                baselineColor: 'none',
                                maxValue: ($scope.question.total_responses_multiple == 0 ? 1 : $scope.question.total_responses_multiple)
                            },
                            vAxis: {
                                gridlines: {
                                    color: 'transparent'
                                },
                                textPosition: 'none',
                                baselineColor: 'none',
                                fontSize: '0'
                            },
                            annotations: {
                                alwaysOutside: true,
                                stemColor: 'none'
                            }
                        };

                        /*
                         Do Column Chart
                         */
                        $scope.sentimentChart = {};

                        $scope.sentimentChart.type = "ColumnChart";

                        $scope.sentimentChartId = randomString(8);

                        $scope.sentimentChart.options = {
                            fontSize: '14',
                            fontWeight: 'bold',
                            chartArea: {
                                width: "95%",
                                height: "80%"
                            },
                            bar: {
                                groupWidth: "8"
                            },
                            isStacked: false,
                            animation: $scope.animation,
                            series: $scope.series,
                            //legend: {
                            //    position: 'none'
                            //},
                            focusTarget: 'category',
                            hAxis: {
                                baselineColor: 'transparent',
                                gridlines: {
                                    color: 'transparent'
                                },
                                viewWindow: {
                                    min: -1.00,
                                    max: 1.00
                                },
                                ticks: [-1.00, 0, 1.00],
                                min: -1.00,
                                max: 1.00,
                                textStyle: {
                                    fontSize: 8
                                }
                            },
                            vAxis: {
                                textPosition: 'none',
                                baselineColor: '#D3D3D3',
                                gridlines: {
                                    color: 'transparent'
                                },
                                viewWindow: {
                                    min: 0,
                                    max: 2
                                },
                                min: 0
                            }
                        };

                        /*
                         The visualisation data view is used for visible and printable chart
                         */
                        $scope.dataView = createVisualisation($scope.dataTable);

                        $scope.sentimentChart.data = $scope.dataView;

                        $scope.barchartDataView = createVisualisation($scope.dataTable);

                        $scope.barChart.data = $scope.barchartDataView;

                        /*
                         Set the chartArea
                         */
                        $scope.sentimentChartReadyHandler = function (chartWrapper) {

                            $scope.chart = chartWrapper.getChart();

                            var cli = $scope.chart.getChartLayoutInterface();

                            $scope.chartArea = cli.getChartAreaBoundingBox();

                            calculateOverlayPositions();
                        };

                        /*
                         Set the chartArea
                         */
                        $scope.barChartReadyHandler = function (chartWrapper) {

                            $scope.chart = chartWrapper.getChart();

                            var cli = $scope.chart.getChartLayoutInterface();

                            $scope.chartArea = cli.getChartAreaBoundingBox();

                            var el = angular.element('#' + $scope.barChartId);

                            if (el.find('question-results-overlay').length == 0) {

                                var overlay = angular.element(document.createElement('question-results-overlay'));
                                $compile(overlay)($scope);

                                el.append(overlay);

                            }
                        };


                        /*
                         Do Printable Chart
                         */
                        $scope.printColumnChart = {};

                        $scope.printColumnChart.type = "ColumnChart";

                        $scope.printColumnChart.options = {
                            fontSize: '16',
                            width: '800',
                            chartArea: {
                                width: '80%',
                                left: '17%'
                            },
                            title: $scope.question.data.text.value,
                            legend: {
                                position: 'none'
                            },
                            tooltip: {
                                trigger: 'none'
                            },
                            focusTarget: 'category',
                            bar: {
                                groupWidth: "8"
                            },
                            isStacked: false,
                            series: {
                                0: {color: '#4a4a4a'},
                                1: {color: '#4a4a4a'},
                                2: {color: '#4a4a4a'}
                            },
                            vAxis: {
                                viewWindow: {
                                    min: 0,
                                    max: 2
                                }
                            },
                            hAxis: {}
                        };

                        $scope.printColumnChart.data = $scope.dataView;

                        $scope.printColumnChartReadyHandler = function (chartWrapper) {
                            /*
                             Print a PNG
                             */
                            $scope.printPng = function ($event) {

                                if (FeatureService.allowed(CONSTANTS.FEATURES.RAW_DATA_EXTRACTS, true)) {

                                    var encodedUri = chartWrapper.getChart().getImageURI();

                                    var anchor = document.createElement('a');

                                    anchor.href = encodedUri;
                                    /*
                                     Should use the question name here..

                                     TODO: Not sure the .download has proper support
                                     */
                                    anchor.download = filename('png');
                                    anchor.target = '_blank';

                                    document.body.appendChild(anchor);

                                    anchor.click();
                                }
                            }
                        };

                        /*
                         Dynamic Demographic Filters go here...
                         */
                        $scope.switch = {};

                        $scope.switch.all = {};

                        $scope.switch.filters = {};

                        $scope.switch.all.state = true;

                        $scope.chartFilters = [];

                        /*
                         * Filter on region
                         * */
                        $scope.$on('event:region-filter', function (event, regions) {

                            var selectedRegionIds = [];

                            for (var j = 0; j < regions.options.length; j++) {

                                if (regions.options[j].option.filterBy) {

                                    selectedRegionIds.push(parseInt(regions.options[j].option.id));
                                }
                            }

                            if ($scope.originalResults.data_table.length > 1) {

                                var newDataTable = [];

                                newDataTable.push($scope.originalResults.data_table[0]);

                                for (var i = 1; i < $scope.originalResults.data_table.length; i++) {

                                    if (selectedRegionIds.indexOf(parseInt($scope.originalResults.data_table[i][12])) != -1) {

                                        newDataTable.push($scope.originalResults.data_table[i]);
                                    }
                                }

                                result.data_table = newDataTable;

                                $scope.dataTable = new google.visualization.arrayToDataTable(result.data_table);

                                $scope.question.dataTable = $scope.dataTable;
                                $scope.question.rawDataTable = result.data_table;

                                if (typeof $scope.currentFilterCategory != "undefined" && $scope.currentFilterCategory != null) {

                                    $scope.$broadcast('event:filter-switch', $scope.currentFilterCategory);
                                } else {

                                    $scope.sentimentChart.data = $scope.printColumnChart.data = $scope.dataView = createVisualisation($scope.dataTable);
                                    $scope.showBarchart = false;
                                    $scope.showColumnchart = true;
                                }
                            }
                        });

                        /*
                         To reset the filters
                         */
                        $scope.$on('event:filter-off', function (event) {

                            $scope.switch.filters = {};

                            $scope.chartFilters = [];

                            $scope.annotations = {};
                            $scope.labels = {};

                            /*
                             This resets both bar charts to the original state of the data

                             and passes in the unfiltered series colour options
                             */
                            $scope.sentimentChart.data = $scope.dataView;

                            $scope.printColumnChart.data = $scope.dataView;

                            $scope.sentimentChart.options.series = $scope.series;

                            $scope.printColumnChart.options.series = $scope.series;

                            $scope.filteredAnnotations = {};

                            $scope.showBarchart = false;
                            $scope.showColumnchart = true;
                        });

                        $scope.$on('event:filter-switch', function (event, filterCategory) {

                            //Get the colIndex by searching the header array
                            var colIndex = result.data_table[0].indexOf(filterCategory.text);

                            if (colIndex > -1) {

                                var values = [];
                                var colors = [];

                                for (var k = 0; k < filterCategory.values.options.length; k++) {

                                    if (filterCategory.values.options[k].option.showFilter && filterCategory.values.options[k].option.selected) {

                                        values.push(filterCategory.values.options[k].option.text);
                                        colors.push(filterCategory.values.options[k].option.color);
                                    }
                                }
                            }

                            //If filters have been set
                            if (values.length > 0) {

                                $scope.showColumnchart = false;

                                $scope.filteredData = createVisualisationFilter(result, values, colIndex);

                                $scope.filteredData = assignSentimentsToBuckets($scope.filteredData);

                                calculateSentiments($scope.filteredData);

                                calculateOverlayPositions();

                                var dataTable = google.visualization.arrayToDataTable($scope.filteredData);

                                $scope.question.dataTable = dataTable;

                                $scope.sentimentChart.data = $scope.printColumnChart.data = createVisualisation(dataTable);

                                $scope.annotations = {};

                            } else {

                                $scope.sentimentChart.data = $scope.dataView;

                                $scope.printColumnChart.data = $scope.dataView;

                                $scope.sentimentChart.options.series = $scope.series;

                                $scope.printColumnChart.options.series = $scope.series;
                            }

                            $scope.showBarchart = false;
                            $scope.showColumnchart = true;
                        });

                        $scope.loaded = true;

                    }, undefined);

                    $scope.sentimentDataLoaded = true;
                    $scope.$parent.showOTChartSpinner = false;
                };

                var createVisualisationFilter = function (result, filters, colIndex) {

                    var filteredDataArray = [];

                    filteredDataArray.push(result.data_table[0]);

                    for (var l = 1; l < result.data_table.length; l++) {

                        for (var m = 0; m < filters.length; m++) {

                            var valueArray = [];

                            if (result.data_table[l][colIndex].indexOf(", ") != -1) {

                                valueArray = result.data_table[l][colIndex].split(", ");
                            } else {

                                valueArray.push(result.data_table[l][colIndex]);
                            }

                            for (var n = 0; n < valueArray.length; n++) {

                                if (valueArray[n].trim() == filters[m]) {

                                    filteredDataArray.push(result.data_table[l]);
                                }
                            }
                        }
                    }

                    return filteredDataArray;
                };

                //Builds the visualization
                var createVisualisation = function (data) {

                    //Create new data table for visualization
                    var newDataTable = new google.visualization.DataTable();

                    //We only need 2 columns for unfiltered
                    newDataTable.addColumn('number', 'Value');
                    newDataTable.addColumn('number', 'Total Responses');

                    if (typeof data.Nf !== "undefined"){

                        for (var j = 0; j < data.Nf.length; j++) {

                            newDataTable.addRow([parseFloat(data.Nf[j].c[11].v), 1]);
                        }
                    } else if(typeof data.hg !== "undefined") {

                        for (var j = 0; j < data.hg.length; j++) {

                            newDataTable.addRow([parseFloat(data.hg[j].c[11].v), 1]);
                        }
                    } else if(typeof data.eg !== "undefined") {

                        for (var j = 0; j < data.eg.length; j++) {

                            newDataTable.addRow([parseFloat(data.eg[j].c[11].v), 1]);
                        }
                    } else if(typeof data.fg !== "undefined") {

                        for (var j = 0; j < data.fg.length; j++) {

                            newDataTable.addRow([parseFloat(data.fg[j].c[11].v), 1]);
                        }
                    }


                    //Sum the unfiltered column rows
                    var newDataTableGrouped = google.visualization.data.group(newDataTable, [{
                        column: 0,
                        type: 'number'
                    }], [{
                        column: 1,
                        label: 'Total Responses',
                        aggregation: google.visualization.data.sum,
                        type: 'number'
                    }]);

                    //Determine highest vertical point
                    var highest = 0;

                    if (typeof newDataTableGrouped.Nf !== "undefined") {

                        for (var l = 0; l < newDataTableGrouped.Nf.length; l++) {

                            highest = highest > newDataTableGrouped.Nf[l].c[1].v ? highest : newDataTableGrouped.Nf[l].c[1].v;
                        }
                    } else if(typeof newDataTableGrouped.hg !== "undefined") {

                        for (var l = 0; l < newDataTableGrouped.hg.length; l++) {

                            highest = highest > newDataTableGrouped.hg[l].c[1].v ? highest : newDataTableGrouped.hg[l].c[1].v;
                        }
                    } else if(typeof newDataTableGrouped.eg !== "undefined") {

                        for (var l = 0; l < newDataTableGrouped.eg.length; l++) {

                            highest = highest > newDataTableGrouped.eg[l].c[1].v ? highest : newDataTableGrouped.eg[l].c[1].v;
                        }
                    } else if(typeof newDataTableGrouped.fg !== "undefined") {

                        for (var l = 0; l < newDataTableGrouped.fg.length; l++) {

                            highest = highest > newDataTableGrouped.fg[l].c[1].v ? highest : newDataTableGrouped.fg[l].c[1].v;
                        }
                    }

                    //Set the viewWindow for the vAxis to this highest value. If we don't force this, we get a lot of unnecessary resizing
                    $scope.sentimentChart.options.vAxis.viewWindow.max = highest;
                    $scope.sentimentChart.options.vAxis.viewWindow.min = 0;

                    //if(typeof $scope.printColumnChart != 'undefined') {
                    //
                    //    $scope.printColumnChart.options.vAxis.viewWindow.max = highest;
                    //    $scope.printColumnChart.options.vAxis.viewWindow.min = 0;
                    //    $scope.printColumnChart.options.hAxis.ticks = uniqueTickArray;
                    //}

                    //Return DataView
                    return new google.visualization.DataView(newDataTableGrouped);

                };

                //Initialize Directive
                getQuestionData();
            }
        };

        return {
            restrict: 'E',
            priority: 0,
            replace: false,
            scope: false,
            link:linkFunction,
            templateUrl:'/app/views/elements/questionSentimentOverall.html'
        }
    }]);

