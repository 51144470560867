/**
 * Created by Barry on 13/09/2017.
 *
 * Directive for displaying the dashboard training overlay
 */
onePulse.directive('dashboardTrainingOverlay', ['$rootScope', 'DataService', '$timeout', '$interval', '$window', function ($rootScope, DataService, $timeout, $interval, $window) {

    linkFunction = function ($scope, element, attributes) {

        //Current card count
        $scope.card = 1;

        // Small Animation on Audience Slide Training Overlaty Dashboard
        
            $timeout(function () {
                $scope.training_audience = 1;
                $interval(function () {
                    $scope.training_audience++;

                    if ($scope.training_audience === 4) {
                        $scope.training_audience = 1;
                    }
                }, 3000);
            }, 3000);
        

        $scope.turnOffTraining = false;

        $scope.complete = function () {

            $rootScope.showDashboardTrainingOverlay = false;
            $window.localStorage['showDashboardTrainingOverlay'] = false;

            var showPanelOverlay = (typeof $window.localStorage['showPanelsTrainingOverlay'] !== "undefined" ? JSON.parse($window.localStorage['showPanelsTrainingOverlay']) : true);

            if ($scope.turnOffTraining || !showPanelOverlay) {

                //Update user is_web_training_displayed property
                $scope.user = DataService.grab('user');

                $scope.user.data.is_web_training_displayed.value = '0';

                DataService.submit('user', $scope.user).then(function (result) {

                    $scope.user = result.user;
                });
            }
        };

        $scope.close = function () {

            $rootScope.showDashboardTrainingOverlay = false;
            $window.localStorage['showDashboardTrainingOverlay'] = false;
        }

    };

    return {
        restrict: 'E',
        priority: 0,
        replace: false,
        scope: true,
        link: linkFunction,
        templateUrl: '/app/views/elements/overlays/dashboardTrainingOverlay.html'
    }
}]);