/**
 * Created by mike on 02/02/16.
 */
onePulse.controller('TranslateController', ['$scope', '$sce', '$routeParams', '$rootScope', 'DataService', 'FormService',
    function($scope, $sce, $routeParams, $rootScope, DataService, FormService) {

        DataService.fetchItem('pulse/translation',$scope.pulse.data_token).then(function(result){
            //$scope.pulse.data_token = result.pulse.data_token;
            $scope.translation = angular.fromJson(result.pulse);
            $scope.form = FormService.parseData($scope.translation.data);

            $rootScope.$broadcast('event:pulse-translation',result.pulse.data_token);

        });

        $scope.$on('$destroy', function() {

            //De-register all root scope event handlers when pulse controller scope is destroyed
            if(typeof $scope.deregisterPulseUpdateTranslatorHandler != "undefined") {$scope.deregisterPulseUpdateTranslatorHandler()};
        });

        $scope.deregisterPulseUpdateTranslatorHandler = $rootScope.$on('event:pulse-update',function(data_token){

        if($scope.isTranslated==true){

            DataService.submit('pulse/translate',$scope.translation).then(function(result){

                $scope.pulse.data_token = result.pulse.data_token

            });
        }
    });
}]);
