/**
 * Created by barry on 15/09/2017.
 *
 */
onePulse.controller('UserManagementController', ['$scope', '$rootScope', 'DataService', 'FeatureService', 'CONSTANTS', '$mdDialog', 'AlertService', '$translate',
	function ($scope, $rootScope, DataService, FeatureService, CONSTANTS, $mdDialog, AlertService, $translate) {

		//Grab current user and features features
		$scope.features = FeatureService.getFeatures();
		$scope.user = DataService.grab('user');

		$scope.clientUsers = null;

		$scope.roles = [];

		var translations;

		$translate(['alert.required_field_missing.title', 'alert.required_field_missing.text',
			'alert.user_created.title', 'alert.user_created.text',
			'alert.user_edited.title', 'alert.user_edited.text',
			'alert.delete_user_complete.title', 'alert.delete_user_complete.text',
			'alert.delete_user_confirm.title', 'alert.delete_user_confirm.text']).then(function(result){
			translations = result;
		});

		function AddUserController($scope, $mdDialog, DataService, init, roles, addUser) {

			$scope.submitSpinner = false;

            $scope.select = {"userRole": roles[0].role.value};
            $scope.user = {"data": null};

            $scope.roles = roles;

			$scope.data = {
				"display_name": "",
				"email": ""
			};

            $scope.sortSequences = function(role) {

                return parseInt(role.sequence.value);
            };

			$scope.modalAddUser = function() {

				$scope.submitSpinner = true;

				if($scope.data.email === "") {

					$scope.submitSpinner = false;

					AlertService.warning(
						translations['alert.required_field_missing.title'],
						translations['alert.required_field_missing.text']
					);
				} else {

					DataService.client('user', 'create', $scope.data, null, function() {

                        $scope.submitSpinner = false;
					}).then(function (result) {

					    $scope.user = result.user;

                        for(var i = 0; i < $scope.user.data.role.length; i++) {

                            $scope.user.data.role[i].option.selected = ($scope.user.data.role[i].option.text == $scope.select.userRole);
                        }

                        DataService.client('user', 'update', $scope.user.data, $scope.user.data_token).then(function (result) {

                            $scope.submitSpinner = false;

                            $mdDialog.hide();

                            AlertService.success(
                                translations['alert.user_created.title'],
                                translations['alert.user_created.text']
                            );

                            init();
                        });
					});
				}
			};

			$scope.closeDialog = function () {

				$mdDialog.hide();
			};

            //Controller for user role matrix modal
            function userRoleMatrixController($scope, $mdDialog, addUser) {

                //When modal is closed, re-open the edit user modal
                $scope.closeDialog = function () {

                    $mdDialog.hide();

                    addUser(null);
                };
            }

            //Call user role matrix modal
            $scope.showUserRoleMatrixModal = function($event) {

                userRoleMatrixController.$inject = ['$scope', '$mdDialog', 'addUser'];

                $mdDialog.show({
                    controller: userRoleMatrixController,
                    templateUrl: 'app/views/elements/includes/user-management-roles.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    targetEvent: $event,
                    locals: {
                        addUser: addUser
                    }
                });
            };
		}

		//Add new user
		$scope.addUser = function($event) {

			AddUserController.$inject = ['$scope', '$mdDialog', 'DataService', 'init', 'roles', 'addUser'];

			$mdDialog.show({
				controller: AddUserController,
				templateUrl: 'app/views/elements/includes/user-management-add-user.html',
				parent: angular.element(document.body),
				clickOutsideToClose: true,
				targetEvent: $event,
				locals: {
				    DataService: DataService,
                    init: init,
                    roles: $scope.roles,
                    addUser: $scope.addUser
				}
			});
		};

		function EditUserController($scope, $mdDialog, DataService, init, user, roles, editUser) {

			$scope.submitSpinner = false;

			//Original user - for comparison
			$scope.originalUser = angular.copy(user);
			$scope.user = user;
            $scope.roles = roles;

			var userRole = findSelectedOption($scope.user.data.role);

			$scope.select = {"userRole": userRole.option.text};

            $scope.sortSequences = function(role) {

                return parseInt(role.sequence.value);
            };

			//Update the user
			$scope.modalEditUser = function() {

				$scope.submitSpinner = true;

                //Remove email from request data payload if not changed
                if(typeof $scope.user.data.email !== "undefined") {

                    if($scope.user.data.email.value == $scope.originalUser.data.email.value) {

                        delete $scope.user.data.email;
                    }
                }

                $scope.setUserRole();

                DataService.client('user', 'update', $scope.user.data, $scope.user.data_token).then(function (result) {

                    $scope.submitSpinner = false;

                    $mdDialog.hide();

                    AlertService.success(
                        translations['alert.user_edited.title'],
                        translations['alert.user_edited.text']
                    );

                    init();
                });
			};

			//Set the user role
            $scope.setUserRole = function() {

                for(var i = 0; i < $scope.user.data.role.length; i++) {

                    $scope.user.data.role[i].option.selected = ($scope.user.data.role[i].option.text == $scope.select.userRole);
                }
            };

            //Close edit user modal
			$scope.closeDialog = function () {

				$mdDialog.hide();
			};

			//Controller for user role matrix modal
            function userRoleMatrixController($scope, $mdDialog, user, editUser) {

                //When modal is closed, re-open the edit user modal
                $scope.closeDialog = function () {

                    $mdDialog.hide();

                    editUser(null, user);
                };
            }

            //Call user role matrix modal
            $scope.showUserRoleMatrixModal = function($event) {

                userRoleMatrixController.$inject = ['$scope', '$mdDialog', 'user', 'editUser'];

                $mdDialog.show({
                    controller: userRoleMatrixController,
                    templateUrl: 'app/views/elements/includes/user-management-roles.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    targetEvent: $event,
                    locals: {
                        user: user,
                        editUser: editUser
                    }
                });
            };
		}

		//Edit user
		$scope.editUser = function($event, user) {

			EditUserController.$inject = ['$scope', '$mdDialog', 'DataService', 'init', 'user', 'roles', 'editUser'];

			$mdDialog.show({
				controller: EditUserController,
				templateUrl: 'app/views/elements/includes/user-management-edit-user.html',
				parent: angular.element(document.body),
				clickOutsideToClose: true,
				targetEvent: $event,
				locals: {
				    DataService: DataService,
                    init: init,
                    user: user,
                    roles: $scope.roles,
                    editUser: $scope.editUser
				}
			});
		};

		//Re-init user
		$scope.reInitUser = function() {


		};

		$scope.showLoginUpgrade = function() {

			return FeatureService.showUpgradeModal(CONSTANTS.FEATURES.ACCOUNT_LOGINS);
		};

		//Disable user (soft delete)
		$scope.disableUser = function(user) {

			AlertService.confirm(
				translations['alert.delete_user_confirm.title'],
				translations['alert.delete_user_confirm.text'],
				function() {

					$rootScope.renderComplete = false;
					$rootScope.loadingStateText = "Deleting User";
					$scope.deletingPulse = true;

					DataService.client('user', 'delete', null, user.data_token).then(function() {

						AlertService.success(
							translations['alert.delete_user_complete.title'],
							translations['alert.delete_user_complete.text']
						);

						$rootScope.renderComplete = true;
						$rootScope.loadingStateText = "";

						$scope.clientUsers = unsetElementByDataToken($scope.clientUsers, user.data_token);
					});
				}
			);
		};

		//Check to see if a client may add a user
		$scope.mayAddUser = function() {

			if($scope.clientUsers != null) {

				return ($scope.clientUsers.length < $scope.allowLoginsCount);
			}
		};

		var formatAccessedAt = function() {

			if($scope.clientUsers != null) {

				for(var i = 0; i < $scope.clientUsers.length; i++) {

					if($scope.clientUsers[i].data.accessed_at.value !== "") {

                        $scope.clientUsers[i].data.accessed_at_formatted = prettyDate(new Date($scope.clientUsers[i].data.accessed_at.value.replace(/\s/, 'T')), false, true);
					} else {

                        $scope.clientUsers[i].data.accessed_at_formatted = "";
					}
				}
			}
		};

		//Populate client user's list of manageable users
		var populateUserList = function() {

			$scope.clientUsers = [];
			$scope.otherUsers = [];

			DataService.client('user', 'fetch').then(function(result) {

			    for(var i = 0; i < result.users.length; i++) {

			        //Push logged in user to array first
			        if(result.users[i].data.email.value == $scope.user.data.email.value) {

                        $scope.clientUsers.push(result.users[i]);
                    } else {

                        $scope.otherUsers.push(result.users[i]);
                    }
                }

                $scope.clientUsers = $scope.clientUsers.concat($scope.otherUsers);

				formatAccessedAt();
			});
		};

		var populateRolesList = function() {

		    DataService.fetchMetaData("roles").then(function(result) {

		        $scope.roles = result.roles;
            })
        };

		//Initialise user management
		var init = function() {

			populateUserList();

            populateRolesList();

			$scope.allowLoginsCount = FeatureService.checkMeta(CONSTANTS.FEATURES.ACCOUNT_LOGINS);
		};

		init();

}]);
