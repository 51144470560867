onePulse.controller('PanelController', ['$scope', '$rootScope', '$window', 'DataService',
	'$mdDialog', 'AlertService', 'FeatureService', 'CONSTANTS', '$translate',
	function ($scope, $rootScope, $window, DataService,
			  $mdDialog, AlertService, FeatureService, CONSTANTS, $translate) {

	$scope.user = DataService.grab('user');

	$scope.submitSpinner = false;
	$scope.currentCategory = null;

	$scope.panelShow = 'public';
	$scope.unlocked = true;
	$scope.loaded = false;

	/*
	Set the initial scope asynchronously
	*/
	$scope.init = function() {

        //Set initially selected group id
        $scope.selectedGroup = findSelectedOption($rootScope.currentPulse.data.group.options);

        //Set group options
        $scope.groups = $rootScope.currentPulse.data.group.options;

	};

	//Call initialisation
	$scope.init();

	//Select active group for this pulse and deactivate old group
	$scope.selectGroup = function(group) {

		if(typeof $scope.selectedGroup != "undefined") {

			var oldGroupIndex = findOptionKeyById($rootScope.currentPulse.data.group.options, $scope.selectedGroup.option.id);

			//Deselect currently selected group
			$rootScope.currentPulse.data.group.options[oldGroupIndex].option.selected = false;
		}

		var newGroupIndex = findOptionKeyById($rootScope.currentPulse.data.group.options, group.option.id);

		$rootScope.currentPulse.data.group.options[newGroupIndex].option.selected = true;

		$scope.selectedGroup = findSelectedOption($rootScope.currentPulse.data.group.options);
	};

	//Update the pulse with the group selection
    $scope.submitCallback = function($event) {

		$mdDialog.hide();
		$scope.$broadcast('event:pulse-saving');

		$rootScope.fadedOverlay = true;
		$rootScope.loadingStateText = "Saving Pulse";

		DataService.submit('pulse', $rootScope.currentPulse).then(function (result) {

			$rootScope.fadedOverlay = false;
			$rootScope.loadingStateText = "";

			var translations;
			$translate(['alert.pulse_saved.title', 'alert.pulse_saved.text']).then(function(result) {
				translations = result;

				AlertService.success(
					translations['alert.pulse_saved.title'],
					translations['alert.pulse_saved.text']
				);
			});

			$rootScope.$broadcast('event:group-updated', $scope.selectedGroup);
			$rootScope.$broadcast('event:pulse-update', result.pulse);
			$scope.$broadcast('event:pulse-saved');
		});
    };

	$scope.backCallback = function ($event) {

		$window.history.back();
		$event.preventDefault();
	};

	//Close the Panel Editor Modal
	$scope.closePanelEditor = function() {

		$mdDialog.hide();
	};

	//Toggle between public and private groups
	$scope.setPanelTab = function(type) {

		$scope.panelShow = type;
		$scope.unlocked = $scope.allowType(false);
	};

	//Check if user feature rights allow access to private groups
	$scope.allowType = function(showModal) {

		if($scope.panelShow == 'private') {

			return FeatureService.allowed(CONSTANTS.FEATURES.PRIVATE_COMMUNITIES, showModal);
		}

		return true;
	};

}]);
