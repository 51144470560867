onePulse.factory('DataService', ['$window', '$http', '$q', '$rootScope', 'Config', 'TokenService', '$location', 'LZString', 'CONSTANTS',
    function ($window, $http, $q, $rootScope, Config, TokenService, $location, LZString, CONSTANTS) {

        var SERVICE_URL = Config.get("OP_API");
        var CONTENT_URL = Config.get("OP_CONTENT_API");
        var pulsesInProgress = [];

        var refreshURLS = function() {

            SERVICE_URL = Config.get("OP_API");
            CONTENT_URL = Config.get("OP_CONTENT_API");
        };

        var getAccessToken = function () {

            return TokenService.getAccessToken();
        };

        var fetchData = function ($item,$page) {

            var deferred = $q.defer();
            var url = SERVICE_URL + $item;

            $http({

                method: "POST",
                url: url,
                data:{"page":$page},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                // console.log("succeess response"+JSON.stringify(response.data));
                deferred.resolve(JSON.stringify(response.data));

                // update angular's scopes
                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;

        };

        var fetchItemData = function ($item, $data_token) {

            var deferred = $q.defer();
            var url = SERVICE_URL + $item;

            $http({
                method: "POST",
                url: url,
                data: {"data_token": $data_token},
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                //console.log("succeess response"+JSON.stringify(response.data));
                //console.log(response.data);
                deferred.resolve(response.data);

                // update angular's scopes
                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;

        };

        var fetchShareData = function (type, slug, page) {

            var deferred = $q.defer();
            var payload = (slug != null ? {"data": slug} : (page != null ? {"page": page} : {}));
            var list = (slug == null ? "list/" : "");
            var url = SERVICE_URL + "meta/share/" + list + type;

            $http({
                method: "POST",
                url: url,
                data: payload,
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var grabData = function ($item) {

            if ($window.localStorage[$item]) {

                return angular.fromJson(LZString.decompressFromUTF16($window.localStorage[$item]));

            }

            return false;
        };

        var grabItemData = function ($item,$id) {

            if ($window.localStorage[$item]) {

                return findItemById(angular.fromJson(LZString.decompressFromUTF16($window.localStorage[$item])),$id);

            }

            return false;
        };

        var setItemData = function ($item, $id, newValue) {

            if ($window.localStorage[$item]) {

                var list = angular.fromJson(LZString.decompressFromUTF16($window.localStorage[$item]));
                var itemKey = findKeyById(angular.fromJson(LZString.decompressFromUTF16($window.localStorage[$item])),$id);
                list[itemKey] = newValue;

                $window.localStorage[$item] = LZString.compressToUTF16(JSON.stringify(list))
            }

            return false;
        };

        var checkAccessToken = function(){

            var deferred = $q.defer();
            var url = SERVICE_URL + "user/check";
            $http({

                method: "POST",
                url: url,
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);

            });

            return deferred.promise;
        };

        var submitItem = function ($item, $data, errorCallback) {

            var deferred = $q.defer();
            var url = SERVICE_URL + $item + "/update";

            $http({

                method: "POST",
                url: url,
                data: {"data": JSON.stringify($data.data), "data_token": $data.data_token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {

                // this callback will be called asynchronously
                // when the response is available
                // console.log("succeess response"+JSON.stringify(response.data));
                deferred.resolve(response.data);

            }, function errCallback() {

                if(typeof errorCallback != "undefined") {

                    errorCallback();
                }
            });

            return deferred.promise;
        };

        var submitTags = function (item, data, dataToken, errorCallback) {

            var deferred = $q.defer();
            var url = SERVICE_URL + item + "/tag";

            $http({

                method: "POST",
                url: url,
                data: {"data": JSON.stringify(data), "data_token": dataToken},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {

                deferred.resolve(response.data);

            }, function errCallback() {

                if(typeof errorCallback != "undefined") {

                    errorCallback();
                }
            });

            return deferred.promise;
        };

        var closeItem = function($item,$token){

            var deferred = $q.defer();
            var url = SERVICE_URL + $item + "/close";
            $http({

                method: "POST",
                url: url,
                data: {"data_token": $token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                //console.log("succeess response"+JSON.stringify(response.data));
                deferred.resolve(response.data);

                // update angular's scopes
                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;

        };

        var deleteItem = function($item, $token){

            var deferred = $q.defer();
            var url = SERVICE_URL + $item + "/delete";
            $http({

                method: "POST",
                url: url,
                data: {"data_token": $token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;

        };

        var publishItem = function($item,$token){

            var deferred = $q.defer();
            var url = SERVICE_URL + $item + "/publish";
            $http({

                method: "POST",
                url: url,
                data: {"data_token": $token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                //console.log("succeess response"+JSON.stringify(response.data));
                deferred.resolve(response.data);

                // update angular's scopes
                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;

        };

        var livePublishItem = function(item, data, dataToken){

            var deferred = $q.defer();
            var url = SERVICE_URL + item + "/livepublish";
            $http({
                method: "POST",
                url: url,
                data: {"data": JSON.stringify(data), "data_token": dataToken},
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();
            });

            return deferred.promise;
        };

        var publishClient = function(){

            var deferred = $q.defer();
            var url = SERVICE_URL + "client/publish";

            $http({

                method: "POST",
                url: url,
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;

        };

        //Validate Client with either email or brand_name
        var validateClient = function(data, errCb){

            var deferred = $q.defer();
            var url = SERVICE_URL + "meta/validate/client";

            $http({

                method: "POST",
                url: url,
                data: JSON.stringify(data),
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            }, function errorCallback() {

                if(typeof errCb != "undefined") {

                    errCb();
                }
            });

            return deferred.promise;

        };

        //Get/set client subscription/topup/transactions
        var profileClient = function(type, data, purchase, errorCallback){

            var deferred = $q.defer();
            var url = SERVICE_URL + "client/"+ type + purchase;

            $http({

                method: "POST",
                url: url,
                data: JSON.stringify(data),
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            }, function errCallback() {

                if(typeof errorCallback != "undefined") {

                    errorCallback();
                }
            });

            return deferred.promise;

        };

        var fetchMetaData = function(type, data) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "meta/" + type;

            $http({

                method: "POST",
                url: url,
                data: JSON.stringify(data),
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var createItem = function($item, data, errorCallback){

            var deferred = $q.defer();
            var url = SERVICE_URL + $item + "/create";

            $http({
                method: "POST",
                url: url,
                data: {"data": JSON.stringify(data)},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);

            }, function errCallback() {

                if(typeof errorCallback !== "undefined") {

                    errorCallback();
                }
            });

            return deferred.promise;
        };

        var duplicateItem = function($token, $item){

            var deferred = $q.defer();
            var url = SERVICE_URL + $item + "/duplicate";
            $http({

                method: "POST",
                url: url,
                data: {"data_token": $token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);

            });

            return deferred.promise;
        };

        var repulse = function($token, type) {
            var deferred = $q.defer();

            switch(type) {

                case "pulse":
                    var url = SERVICE_URL + "pulse/repulse";
                    break;
                case "answer":
                    var url = SERVICE_URL + "pulse/question/answer/repulse";
                    break;
            }

            $http({
                method: "POST",
                url: url,
                data: {"data_token": $token},
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {
                deferred.resolve(response.data);
            });

            return deferred.promise;
        };

        var removeItem = function($item, $token){

            var deferred = $q.defer();
            var url = SERVICE_URL + $item + "/remove";
            $http({

                method: "POST",
                url: url,
                data: {"data_token": $token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                //console.log("succeess response"+JSON.stringify(response.data));
                deferred.resolve(response.data);

                // update angular's scopes
                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };


        var uploadItem = function($data){
            var deferred = $q.defer();
            var url = SERVICE_URL + "upload";
            $http({

                method: "POST",
                url: url,
                data:{"file":$data},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);

            });

            return deferred.promise;
        };

        var searcher = function($item,$data){
            var deferred = $q.defer();
            var url = SERVICE_URL + $item;
            $http({

                method: "POST",
                url: url,
                data:JSON.stringify($data),
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);

            });

            return deferred.promise;
        };

        var createQuestion = function($token){

            var deferred = $q.defer();
            var url = SERVICE_URL + "pulse/question/create";

            if(pulsesInProgress.indexOf($token) == -1) {
                pulsesInProgress.push($token);

                $http({
                    method: "POST",
                    url: url,
                    data: {"data_token":$token},
                    headers: {"Authorization": "Bearer " + getAccessToken()}

                }).then(function(response) {

                    // this callback will be called asynchronously
                    // when the response is available
                    deferred.resolve(response.data);
                    pulsesInProgress.splice(pulsesInProgress.indexOf($token), 1);
                }, function(reject) {
                    pulsesInProgress.splice(pulsesInProgress.indexOf($token), 1);
                });

            } else {
                deferred.reject("Already in progress");
            }

            return deferred.promise;
        };

        var getGroupPanels = function($token) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "group/panels";

            $http({
                method: "POST",
                url: url,
                data: {"data_token":$token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function(response) {

                deferred.resolve(response.data);
            });

            return deferred.promise
        };

        var getPulseChart = function($token){
            var deferred = $q.defer();
            var url = SERVICE_URL + "pulse/chart";
            $http({

                method: "POST",
                url: url,
                data:{"data_token":$token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data.chart_url);
            });

            return deferred.promise;
        };

        var getArchivedPulses = function(){

            var deferred = $q.defer();
            var url = SERVICE_URL + "pulse/archives";
            $http({

                method: "POST",
                url: url,
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function(response) {

                deferred.resolve(response.data);
            });

            return deferred.promise;
        };

        var getArchivedPulse = function(format, data_token){

            var deferred = $q.defer();
            var url = SERVICE_URL + "pulse/archives/" + format;
            $http({

                method: "POST",
                url: url,
                data:{"data_token":data_token},
                responseType: "arraybuffer",
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function(response) {

                deferred.resolve(response.data);
            });

            return deferred.promise;
        };

        var getQuestionChart = function($token){
            var deferred = $q.defer();
            var url = SERVICE_URL + "pulse/question/chart";
            $http({

                method: "POST",
                url: url,
                data:{"data_token":$token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data.chart_url);
            });

            return deferred.promise;
        };

        var getQuestionData = function($token){

            var deferred = $q.defer();
            var endpoint = "pulse/question/data";

            if($location.path().split("/")[1] == "share") {

                endpoint = "meta/share/question/data";
            }

            var url = SERVICE_URL + endpoint;

            $http({

                method: "POST",
                url: url,
                data:{"data_token":$token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);
            });

            return deferred.promise;
        };

        var getQuestionComments = function($token){

            var deferred = $q.defer();
            var url = SERVICE_URL + "pulse/question/comments";
            $http({

                method: "POST",
                url: url,
                data:{"data_token":$token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);
            });

            return deferred.promise;
        };

        var getQuestionEntities = function($token){

            var deferred = $q.defer();

            var endpoint = "pulse/question/entities";

            if($location.path().split("/")[1] == "share") {

                endpoint = "meta/share/question/entities";
            }

            var url = SERVICE_URL + endpoint;

            $http({

                method: "POST",
                url: url,
                data:{"data_token":$token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);
            });

            return deferred.promise;
        };

        var getClientInvoice = function($token){

            var deferred = $q.defer();
            var url = SERVICE_URL + "client/invoice/download";
            $http({

                method: "POST",
                url: url,
                data:{"data_token":$token},
                dataType : "binary",
                processData : false,
                responseType : "arraybuffer",
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);
            });

            return deferred.promise;
        };

        var signUp = function() {
            var deferred = $q.defer();
            var url = SERVICE_URL + "create/client";
            $http({
                method: "POST",
                url: url,
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                // this callback will be called asynchronously
                // when the response is available
                deferred.resolve(response.data);

            });

            return deferred.promise;
        };

        var updatePassword = function(password, password_confirmation) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "user/update/password";

            $http({
                method: "POST",
                url: url,
                data: {"password": password, "password_confirmation": password_confirmation},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {

                deferred.resolve(response.data);

            });

            return deferred.promise;
        };

        var enrol = function(data) {

            var url = SERVICE_URL + "enrol/client";
            var deferred = $q.defer();

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data),
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$broadcast("event:auth-enrol");

            });

            return deferred.promise;
        };

        var fetchClient = function() {

            var deferred = $q.defer();
            var url = SERVICE_URL + "client";

            $http({

                method: "POST",
                url: url,
                data: "",
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var fetchClientInsights = function() {

            var deferred = $q.defer();
            var url = SERVICE_URL + "client/insights";

            $http({

                method: "POST",
                url: url,
                data: "",
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {
                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var fetchPotentialParticipants = function($token) {

            var deferred = $q.defer();
            //var url = SERVICE_URL + "pulse/participants/count";
            var url = SERVICE_URL + "meta/demographic/participants";

            $http({

                method: "POST",
                url: url,
                //data: {data: JSON.stringify(data.data)},
                data:{"data_token":$token},
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var fetchBrainTreeClientToken = function() {

            var deferred = $q.defer();
            var url = SERVICE_URL + "client/generate/token";

            $http({
                method: "POST",
                url: url,
                data: "",
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {

                deferred.resolve(response.data);

            });

            return deferred.promise;
        };

        var fetchContent = function(type, subType, itemIdentifier) {

            subType = (typeof subType != "undefined" && subType != "" ? "/" + subType : "");
            itemIdentifier = (typeof itemIdentifier != "undefined" && itemIdentifier != "" ? "/" + itemIdentifier : "");

            var deferred = $q.defer();
            var url = CONTENT_URL + type + subType + itemIdentifier + ".json";

            $http({
                method: "GET",
                url: url,
                headers: {
                    "Pragma": undefined,
                    "Cache-Control": undefined,
                    "X-Requested-With": undefined,
                    "If-Modified-Since": undefined
                }
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

            });

            return deferred.promise;
        };

        var paymentMethod = function(action, data) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "client/payment_method/" + action;

            $http({
                method: "POST",
                url: url,
                data: data,
                headers: {"Authorization": "Bearer " + getAccessToken()}

            }).then(function successCallback(response) {

                deferred.resolve(response.data);

            });

            return deferred.promise;
        };

        var client = function(type, action, data, data_token, errorCallback) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "client/" + type;
            var appendUrl = "";
            var postData = {};

            if(action != null) {

                switch(action) {

                    case "fetch":
                        appendUrl = "s";
                        postData = {};
                        break;

                    case "create":
                        appendUrl = "/" + action;
                        postData = {"data": JSON.stringify(data)}
                        break;

                    case "delete":
                        appendUrl = "/" + action;
                        postData = {data_token: data_token};
                        break;

                    case "update":
                        appendUrl = "/" + action;
                        postData = {"data": JSON.stringify(data), "data_token": data_token};
                        break;
                }
            }

            url = url + appendUrl;

            $http({
                method: "POST",
                url: url,
                data: postData,
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            }, function errCallback() {

                if(typeof errorCallback !== "undefined") {

                    errorCallback();
                }
            });

            return deferred.promise;
        };

        var pulsePreview = function(data, data_token) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "pulse/preview";

            $http({
                method: "POST",
                url: url,
                data: {"data": data, "data_token": data_token},
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var retarget = function(data, data_token) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "pulse/retarget";

            $http({
                method: "POST",
                url: url,
                data: {"data": data, "data_token": data_token},
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var withdrawPayPal = function(data, errorCallback) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "user/withdraw/paypal";

            $http({
                method: "POST",
                url: url,
                data: data,
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            }, function errCallback() {

                if(typeof errorCallback != "undefined") {

                    errorCallback();
                }
            });

            return deferred.promise;
        };

        var purchasePulse = function(data_token) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "catalogue/purchase";

            $http({
                method: "POST",
                url: url,
                data: {"data_token": data_token},
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var enablePulseEdit = function(data_token) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "pulse/update/set";

            $http({
                method: "POST",
                url: url,
                data: {"data_token": data_token},
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var getForex = function(baseCurrency) {

            var deferred = $q.defer();
            var url = 'https://data.fixer.io/api/latest?access_key=' + CONSTANTS.FIXER_API_KEY + '&base=' + baseCurrency;

            $http({
                method: "GET",
                url: url,
                headers: {
                    "Pragma": undefined,
                    "Cache-Control": undefined,
                    "X-Requested-With": undefined,
                    "If-Modified-Since": undefined
                }
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();
            });

            return deferred.promise;
        };

        var submitESQuery = function(type, query, page, sort, isStore, isAuth, isSimplified) {

            var deferred = $q.defer();
            var url = SERVICE_URL + (!isAuth ? "meta/" : "") + type + "/query";

            var data = {
                "query": query,
                "sort": JSON.stringify(sort),
                "is_store": isStore,
                "simplified": isSimplified
            };

            if(page != null) {

                data.page = page;
            }

            $http({
                method: "POST",
                url: url,
                data: data,
                headers: {
                    "Authorization": "Bearer " + getAccessToken(),
                    "Content-Type": "application/json"
                }
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var workspace = function(action, data) {

            var deferred = $q.defer();
            var url = SERVICE_URL + "workspace/" + action;

            $http({
                method: "POST",
                url: url,
                data: data,
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        var invite = function (code) {
            var deferred = $q.defer();
            var url = SERVICE_URL + "meta/invite/code";

            $http({
                method: "POST",
                url: url,
                data: {invite_code:code},
                headers: {"Authorization": "Bearer " + getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();

            });

            return deferred.promise;
        };

        return {
            grab: grabData,
            grabOrFetch: function ($item) {

                if ($data = grabData($item)) {
                    return $data;
                }

                return fetchData($item)
            },
            grabItem:grabItemData,
            setItem: setItemData,
            fetch: fetchData,
            fetchItem: fetchItemData,
            fetchShareData: fetchShareData,
            getAccessToken: getAccessToken,
            submit: submitItem,
            submitTags: submitTags,
            remove: removeItem,
            repulse: repulse,
            close:closeItem,
            delete: deleteItem,
            publish: publishItem,
            livePublish: livePublishItem,
            publishClient: publishClient,
            validateClient: validateClient,
            profileClient: profileClient,
            create:createItem,
            duplicate:duplicateItem,
            upload:uploadItem,
            searcher:searcher,
            getPulseChart:getPulseChart,
            getArchivedPulses: getArchivedPulses,
            getArchivedPulse: getArchivedPulse,
            getQuestionChart:getQuestionChart,
            getQuestionData:getQuestionData,
            getQuestionComments: getQuestionComments,
            getQuestionEntities: getQuestionEntities,
            getClientInvoice: getClientInvoice,
            createQuestion:createQuestion,
            getGroupPanels: getGroupPanels,
            checkAccessToken:checkAccessToken,
            signUp: signUp,
            enrol: enrol,
            updatePassword: updatePassword,
            fetchClient: fetchClient,
            fetchMetaData: fetchMetaData,
            fetchClientInsights: fetchClientInsights,
            fetchBrainTreeClientToken: fetchBrainTreeClientToken,
            fetchContent: fetchContent,
            fetchPotentialParticipants: fetchPotentialParticipants,
            paymentMethod: paymentMethod,
            pulsePreview: pulsePreview,
            enablePulseEdit: enablePulseEdit,
            client: client,
            purchasePulse: purchasePulse,
            refreshURLS: refreshURLS,
            getForex: getForex,
            submitESQuery: submitESQuery,
            withdrawPayPal: withdrawPayPal,
            workspace: workspace,
            invite:invite,
            retarget: retarget
        }
    }]);
