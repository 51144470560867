onePulse.animation('.animated_top_editor', [function() {
    return {
        // make note that other events (like addClass/removeClass)
        // have different function input parameters
        enter: function(element, doneFn) {
            $(element).hide();
            $(element).slideDown(300, doneFn);

            // remember to call doneFn so that angular
            // knows that the animation has concluded
        },

        move: function(element, doneFn) {
            $(element).slideDown(300, doneFn);
        },

        leave: function(element, doneFn) {
            $(element).slideUp(300, doneFn);
        },

        addClass: function(element, classAdded, doneFn) {
            $(element).slideUp(300, doneFn);
        },

        removeClass: function(element, classAdded, doneFn) {
            $(element).hide();
            $(element).slideDown(300, doneFn);
        }
    }
}])

.animation('.dashboard_transition', [function() {
    return {
        // make note that other events (like addClass/removeClass)
        // have different function input parameters
        enter: function(element, doneFn) {
            $(element).hide();
            $(element).fadeIn(500, doneFn);

             //remember to call doneFn so that angular
             //knows that the animation has concluded
        },

        leave: function(element, doneFn) {
            $(element).find('.your-question').animate({height: 400}, 700);
            $(element).find('.recent-activity').fadeOut(700, doneFn);
//            $(element).find('.insights').fadeOut(700, doneFn);
        }
    }
}])