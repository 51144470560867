
onePulse.directive('workspace.purchases', [function() {

    linkFunction = function($scope, element, attributes){

    };

    return {
        templateUrl: '/app/views/elements/forms/workspace/purchases.html',
        link:linkFunction
    }
}]);
