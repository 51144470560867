/**
 * Created by Barry on 2018/04/20.
 *
 * Directive to handle behaviour of profile menu
 */

onePulse.directive('profileMenu', [
    function () {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: false,
        templateUrl:'/app/views/elements/navigation/profileMenu.html',

        link: function postLink(scope, element, iAttrs) {

            var init = function() {

            };

            init();
        }
    };
}]);