onePulse.controller('StoreController', ['$scope', 'DataService', 'FeatureService', 'CONSTANTS', '$location', 'SearchService', 'AlertService', '$translate',
    function ($scope, DataService, FeatureService, CONSTANTS, $location, SearchService, AlertService, $translate) {

    $scope.search = {search: "", pulse: "", question: "", status: ""};

    $scope.ctrl = {
        "orderBy": "pulse.published_at",
        "orderDirection": "desc"
    };

    $scope.sort = {};
    $scope.sort.field = $scope.ctrl.orderBy;
    $scope.sort.order = $scope.ctrl.orderDirection;

    //Search status for store pulses will always only include closed pulses
    $scope.search.status = "Closed";

    $scope.isResellerClient = false;

    var setRegions = function(pulses) {

        for(var j = 0; j < pulses.length; j++) {

            pulses[j].data.selectedRegionsCount = 0;
            pulses[j].data.selectedRegionsString = "";
            pulses[j].data.firstSelectedRegion = null;

            for (var i = 0; i < pulses[j].data.regions.options.length; i++) {

                if (pulses[j].data.regions.options[i].option.selected == true) {

                    pulses[j].data.selectedRegionsCount++;
                    pulses[j].data.selectedRegionsString = pulses[j].data.selectedRegionsString + pulses[j].data.regions.options[i].option.text + ", ";

                    if (pulses[j].data.firstSelectedRegion == null) {

                        pulses[j].data.firstSelectedRegion = pulses[j].data.regions.options[i];
                    }
                }
            }

            //Remove trailing comma and space
            pulses[j].data.selectedRegionsString = pulses[j].data.selectedRegionsString.substring(0, pulses[j].data.selectedRegionsString.length - 2);
        }

        return pulses;
    };

    var initPulses = function() {

        var remainingPulses = [];
        var pulsesCount = 0;

        $scope.search.search = "";

        SearchService.performSearch("es", "pulses/catalogue", $scope.search, null, $scope.sort, true, true).then(function (result) {

            $scope.allPulses = angular.fromJson(result).pulses;
            $scope.pagination = angular.fromJson(result).pagination;

            DataService.fetch('pulses/catalogue').then(function (result2) {

                $scope.purchasedPulses = angular.fromJson(result2).purchased_pulses;

                for(var i = 0; i < $scope.allPulses.length; i++) {

                    var doesExist = false;

                    for(var j = 0; j < $scope.purchasedPulses.length; j++) {

                        if($scope.allPulses[i].data.id.value == $scope.purchasedPulses[j].data.id.value) {

                            doesExist = true;
                            break;
                        }
                    }

                    if(!doesExist && pulsesCount < CONSTANTS.PULSES_DISPLAY_COUNT_STORE_MAIN) {

                        remainingPulses.push($scope.allPulses[i]);
                        pulsesCount++;
                    }
                }

                $scope.pulses = setRegions(remainingPulses);
            });

        }, function () {

            $scope.pulses = undefined;
        });
    };

    var initTopups = function() {

        DataService.fetchMetaData("topups").then(function(result) {

            var tempTopups = result.topups;
            var cleanedTopups = [];

            for (var i = 0; i < tempTopups.length; i++) {

                if (typeof tempTopups[i].data.is_active.value != "undefined") {

                    if (tempTopups[i].data.is_active.value == '1' && tempTopups[i].data.display_order.value != '') {

                        cleanedTopups.push(tempTopups[i])
                    }
                }
            }

            cleanedTopups.sort(function (a, b) {

                return parseInt(a.data.display_order.value) - parseInt(b.data.display_order.value);
            });

            $scope.topups = cleanedTopups;
        });
    };

    //Initialise subscription features
    var initSubscriptionFeatures = function() {

        DataService.fetchMetaData("subscriptions").then(function(result) {

            //Grab subs, topups & features
            $scope.subscriptions = result.subscriptions;

            DataService.fetchMetaData("products/features").then(function (result) {

                var featuresTemp = [];
                var featuresKeys = [];

                for (var key in result) {

                    if (typeof result[key].is_active != "undefined") {

                        result[key].key.value = removeCharsAfterChar(':', result[key].key.value);

                        if (result[key].is_active.value == '1' && featuresKeys.indexOf(result[key].key.value) == -1) {

                            result[key].display_order.value = parseInt(result[key].display_order.value);
                            result[key].description.value = removeCharsAfterChar(':', result[key].description.value);

                            featuresKeys.push(result[key].key.value);
                            featuresTemp.push(result[key])
                        }
                    }
                }

                var featuresTempOrdered = featuresTemp.slice(0);

                featuresTempOrdered.sort(function (a, b) {

                    return a.display_order.value - b.display_order.value;
                });

                $scope.features = featuresTempOrdered;

                var tempSubscriptions = angular.copy($scope.subscriptions);

                for (var i = 0; i < tempSubscriptions.length; i++) {

                    if (tempSubscriptions[i].data.cost.value != "") {

                        tempSubscriptions[i].data.cost.value = thousandSeparator([tempSubscriptions[i].data.cost.value], true);
                    }

                    featuresTemp = [];
                    featuresKeys = [];

                    for (var key in tempSubscriptions[i].data.features) {

                        if (typeof tempSubscriptions[i].data.features[key].is_active != "undefined") {

                            if (tempSubscriptions[i].data.features[key].is_active.value == '1' && featuresKeys.indexOf(tempSubscriptions[i].data.features[key].key.value) == -1) {

                                tempSubscriptions[i].data.features[key].featureDetail = {"value": null};

                                //Check description for meta data
                                if (tempSubscriptions[i].data.features[key].description.value.indexOf(':') != -1) {

                                    tempSubscriptions[i].data.features[key].featureDetail.value = tempSubscriptions[i].data.features[key].description.value.split(':')[1].trim();
                                    tempSubscriptions[i].data.features[key].description.value = tempSubscriptions[i].data.features[key].featureDetail.value + ' ' + tempSubscriptions[i].data.features[key].description.value;
                                }

                                //Check key for meta data - key takes precedence over description
                                if (tempSubscriptions[i].data.features[key].key.value.indexOf(':') != -1) {

                                    tempSubscriptions[i].data.features[key].featureDetail.value = tempSubscriptions[i].data.features[key].key.value.split(':')[1];
                                }

                                tempSubscriptions[i].data.features[key].blank = {"value": false};

                                tempSubscriptions[i].data.features[key].key.value = removeCharsAfterChar(':', tempSubscriptions[i].data.features[key].key.value);
                                tempSubscriptions[i].data.features[key].display_order.value = parseInt(tempSubscriptions[i].data.features[key].display_order.value);
                                tempSubscriptions[i].data.features[key].description.value = removeCharsAfterChar(':', tempSubscriptions[i].data.features[key].description.value);

                                featuresKeys.push(tempSubscriptions[i].data.features[key].key.value);
                                featuresTemp.push(tempSubscriptions[i].data.features[key])
                            }
                        }
                    }

                    featuresTempOrdered = featuresTemp.slice(0);

                    featuresTempOrdered.sort(function (a, b) {

                        return a.display_order.value - b.display_order.value;
                    });

                    var count = featuresTempOrdered.length;

                    for (var k = 0; k < ($scope.features.length - count); k++) {

                        var item = {"blank": {"value": true}, "description": {"value": "Blank"}};

                        featuresTempOrdered.push(item);
                    }

                    tempSubscriptions[i].data.features = featuresTempOrdered
                }

                $scope.subscriptions = tempSubscriptions;
            });
        });
    };

    var determinePulseDiscount = function() {

        $scope.storeDiscount = FeatureService.checkMeta(CONSTANTS.FEATURES.STORE_DISCOUNT);
    };

    var determineMasterReseller = function() {

        DataService.fetchItem('user').then(function(result) {

            $scope.user = result.user;

            if ($scope.user.data.master_reseller_id.value !== null && $scope.user.data.master_reseller_id.value !== "" && $scope.user.data.master_reseller_id.value != '0') {

                $scope.isResellerClient = true;
            }

            $scope.purchaseManager = null;

            for (var i = 0; i <= $scope.user.data.client_users.length; i++) {

                if (typeof $scope.user.data.client_users[i] !== 'undefined') {

                    for (var j = 0; j <= $scope.user.data.client_users[i].workspaces.length; j++) {

                        if (typeof $scope.user.data.client_users[i].workspaces[j] !== 'undefined') {

                            if ($scope.user.data.client_users[i].workspaces[j].code.value === $scope.user.data.client_code.value
                                && $scope.user.data.client_users[i].workspaces[j].role.value === CONSTANTS.ROLES.CLIENT_PURCHASE_MANAGER.label) {

                                $scope.purchaseManager = $scope.user.data.client_users[i].email.value;
                            }
                        }
                    }
                }
            }
        });
    };

    initPulses();
    initTopups();
    initSubscriptionFeatures();

    determinePulseDiscount();
    determineMasterReseller();

    $scope.purchaseManagerPopup = function() {

        $translate(['alert.request_credits.title', 'alert.request_credits.text']).then(function(result) {

            var translations = result;

            AlertService.alert(
                translations['alert.request_credits.title'],
                translations['alert.request_credits.text']
            )
        });
    };

    $scope.determineDiscountedPrice = function(price, discount) {

        return parseFloat(parseFloat(price) - (parseFloat(price) * (discount / 100))).toFixed(0);
    };

    $scope.editPulse = function(pulseId) {

        //Removed feature check for pulse store: http://mantis.onepulse.com/view.php?id=3198

        // //Check that feature is allowed
        // if(FeatureService.allowed(CONSTANTS.FEATURES.ONEPULSE_STORE, true)) {

            $location.path('/store/pulse/' + pulseId + '/edit');
        // }
    };

    $scope.twoDecPlacesNoRounding = function(num) {

        return twoDecPlacesNoRounding(num);
    };

}]);
