/**
 * Created by mike on 17/12/15.
 */
onePulse.factory('LogoutService', ['$window', '$http', 'TokenService', 'Config', '$rootScope', 'CONSTANTS',
    function($window, $http, TokenService, Config, $rootScope, CONSTANTS) {

    var SERVICE_URL = Config.get('OP_API');

    return {
        logout:function(){

            var url = SERVICE_URL + 'user/logout';

            $rootScope.renderComplete = false;
            $rootScope.loadingStateText = "Logging out";

            if(TokenService.getAccessToken() == CONSTANTS.APP_TOKEN) {

                $window.localStorage.clear();
                $window.location = '/';
            } else {

                $http({
                    method: 'POST',
                    url:url ,
                    headers: {'Authorization': 'Bearer ' + TokenService.getAccessToken()}
                }).then(function successCallback(response) {

                    TokenService.setAccessToken(CONSTANTS.APP_TOKEN);

                    $window.localStorage.clear();
                    $window.location = '/';

                }, function errorCallback () {

                    TokenService.setAccessToken(CONSTANTS.APP_TOKEN);

                    $window.localStorage.clear();
                    $window.location = '/';
                });
            }
        }
    }
}]);
