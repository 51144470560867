/**
 * Created by Barry on 2016/09/13.
 *
 * Directive to parse rule-set in data object and apply attributes to respective DOM element
 */

onePulse.directive('genericParser', ['$timeout', 'FeatureService', function ($timeout, FeatureService) {

    return {
        restrict: 'A',
        priority: 0,
        replace: false,
        scope: {
            limit: '@'
        },

        link: function postLink(scope, element, iAttrs) {

            $timeout(function() {

                //Parse rules
                var rules = iAttrs.data.split('|');

                for (var i = 0; i < rules.length; i++) {

                    //Readonly
                    if (rules[i] == 'locked') {
                        element.attr('readonly', 'readonly');
                    }

                    //Required
                    if (rules[i] == 'mandatory') {
                        element.attr('required', 'required');
                    }

                    //URL
                    if (rules[i] == 'url') {
                        element.attr('type', 'url');
                    }

                    //Min & Max Length
                    if (rules[i].indexOf('min') !== -1 ||
                        rules[i].indexOf('max') !== -1) {

                        //split attr
                        var attr = rules[i].split(':');
                        element.attr(attr[0] + 'Length', attr[1]);
                    }
                }
            }, 5000); //Delay is needed as some elements get their value from an API call
        }
    };
}]);
