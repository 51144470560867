/**
 * Created by Barry on 23/09/16.
 *
 * Directive for displaying a gallery of profile avatars
 */
onePulse.directive('profileGallery', ['DataService', '$interval', '$timeout', function(DataService, $interval, $timeout) {

    linkFunction = function($scope, element, attributes){

        //Avatar swap delays
        var topGalleryAvatarSwapDelay = 2000;
        var bottomGalleryAvatarSwapDelay = 2500;

        //Window width
        $scope.windowWidth = window.innerWidth;

        $scope.showTopGallery = false;
        $scope.showBottomGallery = false;

        $scope.profiles = [];

        $scope.topGallery = [];
        $scope.bottomGallery = [];

        //Grab sprite data from data service
        DataService.fetchMetaData('avatars').then(function(response) {

            $scope.sprite_src = response.avatars.url;

            var i = 0;

            for(key in response.avatars.annotations){

                var offsetArray = key.split(':');

                var profile = response.avatars.annotations[key];

                $scope.profiles[i] = {
                    "offset": "-" + offsetArray[0] + "px -" + offsetArray[1] + "px",
                    "tooltip": profile.display_name
                };

                i++;
            }

            var range = getRangeFromWidth();

            //Insert a set of data into each gallery array
            $scope.topGallery = $scope.profiles.slice(range.topStart, range.topEnd);
            $scope.bottomGallery = $scope.profiles.slice(range.bottomStart, range.bottomEnd);

            var selectorTopGallery = angular.element('.profiles-top-row');
            var selectorBottomGallery = angular.element('.profiles-bottom-row');

            //Fade in bottom gallery
            $timeout(function() {

                selectorBottomGallery.addClass('fade-outleft-inright');

                $scope.showBottomGallery = true;
            }, 5000);

            //Fade in top gallery
            $timeout(function() {

                selectorTopGallery.addClass('fade-outright-inleft');

                $scope.showTopGallery = true;
            }, 6000);
        });

        //Add/remove elements out of the top gallery array
        $interval(function() {

            var randomIndex = Math.floor(Math.random() * 14) + 1;
            var randomIndex2 = Math.floor(Math.random() * 99) + 1;

            if($scope.topGallery.indexOf($scope.profiles[randomIndex2]) == -1) {

                //$scope.topGallery.pop();
                //$scope.topGallery.unshift( $scope.profiles[randomIndex2] );

                $scope.topGallery.splice(randomIndex, 1, $scope.profiles[randomIndex2]);
                $scope.topGallery = $scope.topGallery.filter(function () {
                    return true;
                });
            }

        }, topGalleryAvatarSwapDelay);

        //Add/remove elements out of the bottom gallery array
        $interval(function() {

            var randomIndex = Math.floor(Math.random() * 14) + 1;
            var randomIndex2 = Math.floor(Math.random() * 99) + 1;

            if($scope.bottomGallery.indexOf($scope.profiles[randomIndex2]) == -1) {

                //$scope.bottomGallery.shift();
                //$scope.bottomGallery.push( $scope.profiles[randomIndex2] );

                $scope.bottomGallery.splice(randomIndex, 1, $scope.profiles[randomIndex2]);
                $scope.bottomGallery = $scope.bottomGallery.filter(function () {
                    return true;
                });
            }
        }, bottomGalleryAvatarSwapDelay);

        //Set number of avatars per galley based on window width
        var getRangeFromWidth = function() {

            var total = $scope.windowWidth > 1280 ? $scope.windowWidth / 40 : $scope.windowWidth / 22;

            return range = {
                "topStart": 0,
                "topEnd": (total / 2),
                "bottomStart": (total / 2),
                "bottomEnd": total
            };
        };
    };

    return {
        restrict: 'E',
        priority: 0,
        replace: false,
        scope: true,
        link:linkFunction,
        templateUrl:'/app/views/elements/profileGallery.html'
    }
}]);
