onePulse.controller('AssumeController', ['$scope', '$rootScope', '$window', 'DataService',
    'AuthService', '$translate', '$routeParams', '$http', 'Config', 'TokenService', 'CONSTANTS',
	function ($scope, $rootScope, $window, DataService,
              AuthService, $translate, $routeParams, $http, Config, TokenService, CONSTANTS) {

        var SERVICE_URL = Config.get('OP_API');

        $scope.displayFooter = false;

        //Login to client account with an access token
        $scope.doAssumedLogin = function () {

            $rootScope.renderComplete = false;
            $rootScope.loadingStateText = "Logging in to client account";

            $scope.token = $routeParams.token;

            //Wipe current local storage
            $window.localStorage.clear();

            //Store token in local storage
            TokenService.setAccessToken($scope.token);

            DataService.checkAccessToken().then(function () {

                DataService.fetchItem('user').then(function(result) {

                    $scope.user = result.user;

                    $scope.isLoggedIn = true;
                    $rootScope.renderComplete = true;
                    $rootScope.loadingStateText = "";

                    $rootScope.$broadcast('event:auth-login');

                    $window.location = '/dashboard';
                })
            });
        };

        var init = function() {

            if($routeParams.token == null || $routeParams.token == "" || typeof $routeParams.token == "undefined") {

                $window.location = '/';
            } else {

                if(AuthService.isLoggedIn()) {

                    var url = SERVICE_URL + 'user/logout';

                    $rootScope.renderComplete = false;
                    $rootScope.loadingStateText = "Logging out";

                    $http({
                        method: 'POST',
                        url:url ,
                        headers: {'Authorization': 'Bearer ' + TokenService.getAccessToken()}
                    }).then(function successCallback(response) {

                        TokenService.setAccessToken(CONSTANTS.APP_TOKEN);

                        $scope.doAssumedLogin();

                    }, function errorCallback () {

                        TokenService.setAccessToken(CONSTANTS.APP_TOKEN);

                        $scope.doAssumedLogin();
                    });
                } else {

                    $scope.doAssumedLogin();
                }
            }
        };

        init();

        $scope.$watch(function () {
            return AuthService.isLoggedIn()
        }, function (newVal, oldVal) {

            if (oldVal !== newVal) {
                $scope.isLoggedIn = newVal;
            }
        })

}]);