/**
 * Created by mike on 6/25/16.
 */

onePulse.config([
  "$routeProvider",
  "$locationProvider",
  "ngMetaProvider",
  function ($routeProvider, $locationProvider, ngMetaProvider) {
    // use the HTML5 History API
    $locationProvider.html5Mode(true);

    //Set meta tags
    ngMetaProvider.setDefaultTag("og_title", "OnePulse");
    ngMetaProvider.setDefaultTag(
      "og_description",
      "OnePulse gets you answers from people in minutes, so you can make decisions with confidence."
    );
    ngMetaProvider.setDefaultTag("og_url", "https://www.onepulse.com");

    ngMetaProvider.setDefaultTag("twitter_site", "OnePulse");
    ngMetaProvider.setDefaultTag("twitter_title", "OnePulse");
    ngMetaProvider.setDefaultTag("twitter_url", "https://www.onepulse.com");
    ngMetaProvider.setDefaultTag(
      "twitter_description",
      "OnePulse gets you answers from people in minutes, so you can make decisions with confidence."
    );
    ngMetaProvider.setDefaultTag("twitter_label1", "Check out our blog");
    ngMetaProvider.setDefaultTag(
      "twitter_data1",
      "At https://insights.onepulse.com/"
    );
    ngMetaProvider.setDefaultTag("twitter_label2", "Book a demo");
    ngMetaProvider.setDefaultTag(
      "twitter_data2",
      "At https://www.onepulse.com/demo"
    );

    $routeProvider
      .when("/question/:id", {
        templateUrl: "app/views/elements/question-results.html",
      })
      .when("/", {
        templateUrl: "app/views/pages.html",
      })
      .when("/pricing", {
        templateUrl: "app/views/pages/pricing.html",
      })
      .when("/blog", {
        templateUrl: "app/views/pages/blog.html",
      })
      .when("/blog-category/:categoryId/:categorySlug", {
        templateUrl: "app/views/pages/blog.html",
      })
      .when("/blog-post/:blogId/:blogSlug", {
        templateUrl: "app/views/pages/blog-post.html",
      })
      .when("/terms", {
        templateUrl: "app/views/pages/terms.html",
      })
      .when("/terms-mobile", {
        templateUrl: "app/views/pages/termsMobile.html",
      })
      .when("/privacy", {
        templateUrl: "app/views/pages/privacy.html",
      })
      .when("/cookies", {
        templateUrl: "app/views/pages/cookieDeclaration.html",
      })
      .when("/about", {
        templateUrl: "app/views/pages/about.html",
      })
      .when("/solutions", {
        templateUrl: "app/views/pages/solutions.html",
      })
      .when("/community", {
        templateUrl: "app/views/pages/community.html",
      })
      .when("/product", {
        templateUrl: "app/views/pages/product.html",
      })
      // .when('/features', {
      //     templateUrl: 'app/views/pages/features.html'
      // })
      .when("/launch", {
        templateUrl: "app/views/pages/launch.html",
      })
      .when("/faq", {
        templateUrl: "app/views/pages/faq.html",
      })
      .when("/clientOnBoarding", {
        templateUrl: "app/views/pages/clientOnBoarding.html",
      })
      .when("/contact", {
        templateUrl: "app/views/pages/contact.html",
      })
      .when("/forgot/client", {
        templateUrl: "app/views/pages/forgotPasswordClient.html",
      })
      .when("/forgot/mobile", {
        templateUrl: "app/views/pages/forgotPasswordMobile.html",
      })
      .when("/reset/:token", {
        templateUrl: "app/views/pages/resetPassword.html",
      })
      .when("/create/:token", {
        templateUrl: "app/views/pages/createPassword.html",
      })
      .when("/accept/:token", {
        templateUrl: "app/views/pages/acceptInvitation.html",
      })
      .when("/assume/:token", {
        templateUrl: "app/views/pages/assume.html",
      })
      .when("/login", {
        templateUrl: "app/views/login.html",
      })
      .when("/login", {
        templateUrl: "app/views/login.html",
      })
      .when("/sso", {
        templateUrl: "app/views/sso.html",
      })
      .when("/register", {
        templateUrl: "app/views/register.html",
      })
      .when("/register/:referral", {
        templateUrl: "app/views/register.html",
      })
      .when("/register-complete", {
        templateUrl: "app/views/registerComplete.html",
      })
      .when("/page/:pagename", {
        templateUrl: "app/views/pages.html",
      })
      .when("/user/:settings", {
        templateUrl: "app/views/accountProfile.html",
      })
      .when("/workspace/:settings", {
        templateUrl: "app/views/workspaceProfile.html",
      })
      .when("/workspaces/select/:type", {
        templateUrl: "app/views/workspaceSelect.html",
      })
      .when("/workspaces/select/:type/:code", {
        templateUrl: "app/views/workspaceSelect.html",
      })
      .when("/pulses", {
        templateUrl: "app/views/pulses.html",
        loginRequired: true,
      })
      .when("/direct/:action/:type/:target/:id", {
        templateUrl: "app/views/direct.html",
        reloadOnSearch: false,
      })
      .when("/pulse/:id", {
        templateUrl: "app/views/pulse.html",
        reloadOnSearch: false,
      })
      .when("/pulse/:id/:action", {
        templateUrl: "app/views/pulse.html",
        reloadOnSearch: false,
      })
      .when("/store", {
        templateUrl: "app/views/store/store.html",
        loginRequired: true,
      })
      .when("/store/topups", {
        templateUrl: "app/views/store/storeTopups.html",
        loginRequired: true,
      })
      //            .when('/store/subscriptions', {
      //                templateUrl: 'app/views/store/storeSubscriptions.html'
      //            })
      .when("/store/panels", {
        templateUrl: "app/views/store/storePanels.html",
        loginRequired: true,
      })
      .when("/store/pulses", {
        templateUrl: "app/views/store/storePulses.html",
        loginRequired: true,
      })
      .when("/store/pulse/:id", {
        templateUrl: "app/views/store/storePulse.html",
        reloadOnSearch: false,
      })
      .when("/store/pulse/:id/:action", {
        templateUrl: "app/views/store/storePulse.html",
        reloadOnSearch: false,
      })
      .when("/channels", {
        templateUrl: "app/views/panels.html",
        loginRequired: true,
      })
      .when("/catalogueStore", {
        templateUrl: "app/views/catalogueStore.html",
      })
      .when("/cataloguePulses", {
        templateUrl: "app/views/cataloguePulses.html",
      })
      .when("/cataloguePulse", {
        templateUrl: "app/views/cataloguePulse.html",
      })
      .when("/dashboard", {
        templateUrl: "app/views/dashboard.html",
      })
      .when("/dashboard/:id", {
        templateUrl: "app/views/dashboard.html",
      })
      .when("/inbox", {
        templateUrl: "app/views/inbox.html",
        loginRequired: true,
      })
      .when("/inbox/:id/", {
        templateUrl: "app/views/inbox.html",
      })
      .when("/chart", {
        templateUrl: "app/views/chart.html",
      })
      .when("/try-me", {
        templateUrl: "app/views/pages/clientOnBoardingNoModal.html",
      })
      .when("/logout", {
        resolve: {
          logout: [
            "LogoutService",
            function (LogoutService) {
              LogoutService.logout();
            },
          ],
        },
      })
      .when("/err/:type", {
        templateUrl: "app/views/error.html",
      })
      .when("/share/pulse/:slug", {
        templateUrl: "app/views/shares/pulse.html", //Public share route: pulse deep links
      })
      .when("/share/pulses", {
        templateUrl: "app/views/shares/pulses.html", //Public share route: pulses list
      })
      .when("/share/invitation/:referral", {
        templateUrl: "app/views/shares/invitation.html", //Public share route: invitation
      })
      .when("/share/community/:slug", {
        templateUrl: "app/views/shares/panel.html", //Public share route: panel join links
      })
      .when("/share/results/:slug", {
        templateUrl: "app/views/shares/results.html", //Public share route: pulse results links
      })
      .when("/invite/:code", {
        templateUrl: "app/views/shares/friend.html", //Public share route: invite a friend links
      })
      .when("/new/pulse", {
        resolve: {
          new_pulse: [
            "PulseService",
            function (PulseService) {
              var document = new Document();
              PulseService.createPulse(document.createEvent("Event"), true);
            },
          ],
        },
        loginRequired: true,
      })
      .otherwise({
        redirectTo: "/",
      });

    //check browser support
    if (window.history && window.history.pushState) {
      //$locationProvider.html5Mode(true); will cause an error $location in HTML5 mode requires a  tag to be present! Unless you set baseUrl tag after head tag like so: <head> <base href="/">
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: false,
      });
    }
  },
]);
