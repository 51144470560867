/**
 * Created by Barry on 26/04/2018
 *
 * Service handling user roles and permissions
 */
onePulse.factory('RoleService', ['DataService', 'TokenService', '$mdDialog', '$rootScope', 'ngDialog', '$window', 'CONSTANTS', 'AlertService','AuthService',
    function(DataService, TokenService, $mdDialog, $rootScope, ngDialog, $window, CONSTANTS, AlertService, AuthService) {

    return {

        initRole: function() {

            var access_token;
            var payload;

            var accessToken = function(){

                access_token = TokenService.getAccessToken();
                return access_token;
            };

            var getPayload = function(){

                return angular.fromJson(decodeBase64(accessToken().split('.')[1]));
            };

            payload = getPayload();

            $window.localStorage['role'] = payload.aud.role_id;
        },

        getRole: function() {

            if(!$window.localStorage['role'] || $window.localStorage['role'] === "undefined") {

                this.initRole();
            }

            return $window.localStorage['role'];
        },

        allowed: function(right, showModal) {

            var role = this.getRole();

            var rights = [];

            for(var key in CONSTANTS.ROLES) {

                if(CONSTANTS.ROLES[key].label == role) {

                    rights = CONSTANTS.ROLES[key].rights;
                }
            }

            //Check if user holds right
            for(var i = 0; i < rights.length; i++) {

                if(right == rights[i]) {

                        return true;
                }
            }

            /*
            If not logged in then never show the modal
             */
            if(!AuthService.isLoggedIn()){
                showModal = false;
            }

            if(showModal) {
                AlertService.alert(
                    "Insufficient role permissions",
                    "You don't have permission to perform this action. Please contact your account administrator."
                );
            } else {

                return false;
            }
        }
    }
}]);