onePulse.controller('StoreTopupsController', ['$scope', '$rootScope', '$mdDialog', '$translate', 'DataService', 'AlertService',
    function ($scope, $rootScope, $mdDialog, $translate, DataService, AlertService) {

    var initTopups = function() {

        DataService.fetchMetaData("topups").then(function(result) {

            var tempTopups = result.topups;
            var cleanedTopups = [];

            for (var i = 0; i < tempTopups.length; i++) {

                if (typeof tempTopups[i].data.is_active.value != "undefined") {

                    if (tempTopups[i].data.is_active.value == '1' && tempTopups[i].data.display_order.value != '') {

                        tempTopups[i].data.display_price.value = parseFloat(tempTopups[i].data.display_price.value).toFixed(0);
                        tempTopups[i].data.cost.value = parseFloat(tempTopups[i].data.cost.value).toFixed(0);

                        cleanedTopups.push(tempTopups[i])
                    }
                }
            }

            cleanedTopups.sort(function (a, b) {

                return parseInt(a.data.display_order.value) - parseInt(b.data.display_order.value);
            });

            $scope.topups = cleanedTopups;
        });
    };

    initTopups();

    $scope.buyTopup = function(topup) {

        $rootScope.modalOpen = true;

        $rootScope.descriptionTopup = topup.data.description.value;

        $mdDialog.show({
            controller: 'UserController',
            templateUrl: 'app/views/elements/includes/plan-topup.html',
            clickOutsideToClose: false
        });

        DataService.fetchBrainTreeClientToken().then(function(result) {

            var brainTreeClientToken = result.token;

            initiateBrainTree(brainTreeClientToken, '.topup-form', $scope.errorCallback, $scope.successCallbackTopup);
        });
    };

    //Error callback for Braintree
    $scope.errorCallback = function() {

        var translations;
        $translate(['alert.card_details_error.title', 'alert.card_details_error.text']).then(function(result) {
            translations = result;

            AlertService.error(
                translations['alert.card_details_error.title'],
                translations['alert.card_details_error.text']
            );
        });
    };

    //Success callback for Braintree - Topup payment
    $scope.successCallbackTopup = function(payload, deviceData) {

        $scope.submitSpinner = true;

        var data_token = $('#selectedDataToken').val();
        var description = $('#selectedDescription').val();

        // Submit payload.nonce and other data
        var data = {
            "nonce": payload.nonce,
            "device_data": deviceData,
            "data_token": data_token
        };

        DataService.profileClient("topups", data, "/purchase", function() {

            $scope.submitSpinner = false;

            //Hide loading spinner and show confirm button text
            $('.button-text').css('display', 'block');
            $('.button-spinner').css('display', 'none');

            //Enable submit button
            $('#submit').prop("disabled",false);
        }).then(function(result) {

            DataService.fetchItem('user').then(function (result) {

                $scope.submitSpinner = false;
                $scope.user = result.user;

                //Store topup object for use in next modal
                $rootScope.descriptionTopup = description;

                $rootScope.modalOpen = true;

                $mdDialog.hide();

                $mdDialog.show({
                    controller: 'UserController',
                    templateUrl: 'app/views/elements/includes/plan-topup-complete.html',
                    clickOutsideToClose: false
                });
            });
        });
    };
}]);
