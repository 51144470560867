/**
 * Created by mike on 23/01/16.
 */

onePulse.factory('TemplateService', ['$http', '$templateCache', '$q',
    function($http, $templateCache, $q) {

    var fetch = function(templateUrl){

        var deferred = $q.defer();

        $http.get(templateUrl).success(function (t) {
            $templateCache.put(templateUrl, t);
            deferred.resolve(t);
        });

        return deferred.promise
    }

    return {
        fetch:fetch
    }
}]);
