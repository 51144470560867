/**
 * Created by Barry on 23/04/2018.
 *
 * Directive for displaying the workspace select overlay
 */
onePulse.directive('workspaceSelectOverlay', ['$rootScope', 'DataService', '$window', 'TokenService', 'LoginService',
    function($rootScope, DataService, $window, TokenService, LoginService) {

        linkFunction = function($scope, element, attributes) {

            $scope.selectedWorkspace = {};

            $rootScope.submitSpinner = false;

            // var init = function() {
            //
            //     if($scope.reducedUser.data.workspaces.length > 0) {
            //
            //         $scope.selectedWorkspace = $scope.reducedUser.data.workspaces[0];
            //     }
            // };
            //
            // init();

            $scope.selectWorkspace = function() {

                var data = {
                    "code": $scope.selectedWorkspace.code.value
                };

                $rootScope.submitSpinner = true;

                DataService.workspace("select", data).then(function(response) {

                    $rootScope.submitSpinner = false;

                    TokenService.setAccessToken = response.access_token;

                    LoginService.processLogin(response);

                    $scope.isLoggedIn = true;

                    $window.location = typeof $rootScope.redirect !== "undefined"? $rootScope.redirect : '/dashboard';
                })
            };

            $scope.close = function() {

                TokenService.resetAccessToken();
            }
        };

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            link:linkFunction,
            templateUrl:'/app/views/elements/overlays/workspaceSelectOverlay.html'
        }
    }]);