/**
 * Created by Barry on 04/08/17.
 */
onePulse.factory('ValidationService', [function(){

    var duplicateAnswerCorrection = function(pulse){

        for(var i = 0; i < pulse.data.questions.length; i++) {

            if (typeof pulse.data.questions[i].data.answers !== "undefined") {

                var existingAnswerTextArray = [];

                for (var l = 0; l < pulse.data.questions[i].data.answers.length; l++) {

                    if (typeof pulse.data.questions[i].data.answers[l] !== "undefined") {

                        if(existingAnswerTextArray.indexOf(pulse.data.questions[i].data.answers[l].data.text.value) != -1 &&
                            pulse.data.questions[i].data.answers[l].data.text.value != "") {

                            pulse.data.questions[i].data.answers[l].data.image = "";
                            pulse.data.questions[i].data.answers[l].data.sequence = "";
                            pulse.data.questions[i].data.answers[l].data.text = "";
                        } else {

                            existingAnswerTextArray.push(pulse.data.questions[i].data.answers[l].data.text.value);
                        }
                    }
                }
            }
        }

        return pulse;
    };

    var answerSequenceCorrection = function(pulse){

        for(var i = 0; i < pulse.data.questions.length; i++) {

            var tempAnswerArray = [];

            if (typeof pulse.data.questions[i].data.answers !== "undefined") {

                for (var l = 0; l < pulse.data.questions[i].data.answers.length; l++) {

                    if (typeof pulse.data.questions[i].data.answers[l] !== "undefined") {

                        if (pulse.data.questions[i].data.answers[l].data.text.value !== "") {

                            tempAnswerArray.push(pulse.data.questions[i].data.answers[l])
                        }
                    }
                }

                for (var j = 0; j < tempAnswerArray.length; j++) {

                    pulse.data.questions[i].data.answers[j].data.text.value = tempAnswerArray[j].data.text.value;
                    pulse.data.questions[i].data.answers[j].data.anchor.value = tempAnswerArray[j].data.anchor.value;
                    pulse.data.questions[i].data.answers[j].data.sequence.value = j + 1;

                    if (pulse.data.questions[i].data.answers[j].data.image != null) {

                        pulse.data.questions[i].data.answers[j].data.image.value = tempAnswerArray[j].data.image.value;
                    }
                }

                for (var m = tempAnswerArray.length; m < pulse.data.questions[i].data.answers.length; m++) {

                    pulse.data.questions[i].data.answers[m].data.text.value = "";
                    pulse.data.questions[i].data.answers[m].data.anchor.value = "";
                    pulse.data.questions[i].data.answers[m].data.sequence.value = "";

                    if (pulse.data.questions[i].data.answers[j].data.image != null) {

                        pulse.data.questions[i].data.answers[j].data.image.value = "";
                    }
                }
            }
        }

        return pulse;
    };

    return{
        duplicateAnswerCorrection : duplicateAnswerCorrection,
        answerSequenceCorrection: answerSequenceCorrection
    }
}]);