/**
 * Created by Barry on 2016/11/17.
 *
 * Directive to handle behaviour of notifications list
 */

onePulse.directive('notifications', ['$rootScope', '$window', 'Config', 'AblyService', 'DataService', '$timeout', 'AuthService', 'ngAudio', 'SearchService',
    function ($rootScope, $window, Config, AblyService, DataService, $timeout, AuthService, ngAudio, SearchService) {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: true,
        templateUrl:'/app/views/elements/general/notifications.html',

        link: function postLink(scope, element, iAttrs) {

            $rootScope.notifications = [];

            $rootScope.completedNotifications = [];
            $rootScope.liveNotifications = [];

            scope.initNotifications = function() {

                //Delay time before notification fades
                scope.fadeDelay = 15000;

                scope.user = DataService.grab('user');

                if($window.localStorage.access_token && $window.localStorage['access_token'] !== Config.get('OP_APPLICATION_TOKEN')) {

                    scope.ablyUserChannel = AblyService.client().channels.get(scope.user.data.presence_channel.value);
                    scope.ablyUserChannel.presence.enter();

                    scope.ablyClientChannel = AblyService.client().channels.get(scope.user.data.notification_channel.value);
                    scope.ablyClientChannel.presence.enter();

                    //Subscribe to pulse_go_live channel event
                    scope.ablyClientChannel.subscribe('pulse_go_live', function ($ablyMessage) {

                        scope.processPulseNotification($ablyMessage, 'pulse_go_live');
                    });

                    //Subscribe to pulse_completed channel event
                    scope.ablyClientChannel.subscribe('pulse_completed', function ($ablyMessage) {

                        scope.processPulseNotification($ablyMessage, 'pulse_completed');
                    });

                    //Subscribe to system_notification channel event
                    scope.ablyClientChannel.subscribe('system_notification', function ($ablyMessage) {

                        $timeout(function () {

                            var randomId = Math.random().toString(36).slice(2);

                            $ablyMessage.data.InternalId = randomId;

                            //Add to notifications
                            $rootScope.notifications.unshift($ablyMessage.data);

                            //Fade notification after delay
                            scope.fadeNotification($ablyMessage, randomId);
                        });
                    });
                }

                //Process pulse type of notification
                scope.processPulseNotification = function($ablyMessage, type) {

                    var search = {
                        "search": "",
                        "pulse": "",
                        "question": "",
                        "status": "Draft Active Scheduled Closed",
                        "type": "full_text"
                    };

                    var ctrl = {
                        "orderBy": "pulse.started_at",
                        "orderDirection": "desc"
                    };

                    var sort = {};

                    sort.field = ctrl.orderBy;
                    sort.order = ctrl.orderDirection;

                    SearchService.performSearch("es", "pulses", search, null, sort, false, true).then(function (result) {

                        $rootScope.$broadcast('event:pulse_status_change', result);

                        var randomId = Math.random().toString(36).slice(2);

                        scope.actionArray = $ablyMessage.data.action.split('/');

                        var pulse = DataService.grabItem('pulses', scope.actionArray[1]);

                        $ablyMessage.data.title = $ablyMessage.data.title.replace('Your Pulse', "Your Pulse '" + pulse.data.title.value + "'");
                        $ablyMessage.data.InternalId = randomId;

                        var displayNotif = function() {

                            $timeout(function() {

                                //Play notification audio
                                ngAudio.play('assets/audio/notif_ping.mp3');

                                //Add to notifications
                                $rootScope.notifications.unshift($ablyMessage.data);

                                //Fade notification after delay
                                scope.fadeNotification($ablyMessage, randomId);

                                switch(type) {

                                    case 'pulse_go_live':

                                        $rootScope.liveNotifications.push(pulse.data.id.value);
                                        break;

                                    case 'pulse_completed':

                                        $rootScope.completedNotifications.push(pulse.data.id.value);
                                        break;
                                }
                            });
                        };

                        if((type === 'pulse_go_live' && !$rootScope.liveNotifications.includes(pulse.data.id.value)) ||
                            (type === 'pulse_completed' && !$rootScope.completedNotifications.includes(pulse.data.id.value))) {

                            displayNotif();
                        }
                    });
                };

                //Fade notification after delay
                scope.fadeNotification = function($ablyMessage, randomId) {

                    $timeout(function() {

                        for(var i = 0; i < $rootScope.notifications.length; i++) {

                            if($rootScope.notifications[i].InternalId == randomId) {

                                $rootScope.notifications.splice(i, 1);
                            }
                        }
                    }, scope.fadeDelay);
                }
            };

            //Register Ably listeners on successful login
            scope.deregisterAuthLoginHandler = $rootScope.$on('event:auth-login', function() {

                scope.initNotifications();
            });

            scope.$on('$destroy', function() {

                //De-register all root scope event handlers when pulse controller scope is destroyed
                if(typeof scope.deregisterAuthLoginHandler != "undefined") {scope.deregisterAuthLoginHandler()};
            });

            //Or if page is refreshed and user is logged in
            if(AuthService.isLoggedIn()) {

                scope.initNotifications();
            }
        }
    };
}]);
