onePulse.controller('PulseController', ['$http', '$scope', '$rootScope', '$routeParams', '$window', '$sce', 'AblyService', '$route', 'RoleService',
    'Config', 'DataService', 'FormService', '$mdDialog', '$timeout', 'ngDialog', '$translate', 'AlertService', '$filter', '$location', 'PulseService',
    'CONSTANTS', '$interval', 'FeatureService', 'ExportService', '$q', 'ErrorService', 'SocialShareService', 'ValidationService', '$mdSidenav', '$mdBottomSheet',
    'dragulaService',
    function ($http, $scope, $rootScope, $routeParams, $window, $sce, AblyService, $route, RoleService,
              Config, DataService, FormService, $mdDialog, $timeout, ngDialog, $translate, AlertService, $filter, $location, PulseService,
              CONSTANTS, $interval, FeatureService, ExportService, $q, ErrorService, SocialShareService, ValidationService, $mdSidenav, $mdBottomSheet, dragulaService) {

        //use ID to retrieve the data_token
        var data_token = findTokenById(DataService.grab("pulses"), $routeParams.id);
        var endpoint = "pulse";

        if(typeof data_token == "undefined") {

            data_token = findTokenById(DataService.grab("purchasedPulses"), $routeParams.id);
            endpoint = "pulses/catalogue/pulse";
        }

        $scope.pulseDemographicFilters = true;
        $scope.allDemographics = {selected: true};

        $scope.shareLinkHelpShow = false;
        $scope.inviteLinkHelpShow = false;
        $scope.openRegions = false;
        $rootScope.audienceSlideUp = false;
        $rootScope.pulseEditorHelpShow = true;
        $rootScope.pulseEditorRetarget = false;

        // $scope.showRegions = false;

        $rootScope.filterOpen = false;
        $rootScope.customBackDrop = false;
        $rootScope.channelOpen = false;

        $scope.originalParticipantCount = 0;

        //Currently logged in user
        $scope.user = DataService.grab("user");

        $scope.pulse_data_token = data_token;
        $scope.isTranslated = false;
        $scope.translateArabic = false;
        $scope.saveState = "pulse.saved_label";
        $scope.newQuestionId = null;
        $scope.disableNewQuestionAnimation = false;
        $scope.newQuestionHeaderVisible = false;
        $rootScope.deletingPulse = false;

        //Name of the default group for a pulse - must be an actual group name
        $scope.defaultPulseGroupName = "OnePulse";

        //Controls class toggle for Thank You message star rating
        $scope.starOn = false;

        //Control for repulsed pulse
        $scope.repulsed = false;

        //Control for repulsed answer
        $scope.repulsedAnswer = false;

        //Control & ID for Youtube vid
        $scope.youTubePresent = false;
        $scope.youTubeId = null;

        //the active element in the nav list
        $scope.isActive = "pulse";
        $scope.linkType = "Type";
        $scope.questionFormVisible = false;
        $scope.addingQuestion = false;

        //Selected Branch on load
        $scope.selectedBranch = 1;

        $scope.showIntroLink = false;
        $scope.showThankYouLink = false;

        $scope.currentFilterCategory = null;
        $scope.currentFilters = [];

        $scope.pulseUniqueResponses = [];

        $scope.tags = [];
        $scope.clientTags = [];

        $scope.pulse = {};

        //Control for processing live udpate after confirmation
        $rootScope.liveUpdate = false;

        //Control for processing route change after save
        $rootScope.routeChanged = false;
        $rootScope.routeChangedPath = "";

        //Show selected regions for the pulse
        $scope.regions = {showRegions: false};

        $scope.selectedRegion = null;

        //Show the region selection div - only visible if a client has access to 2 or more regions
        $scope.showPulseRegions = false;

        // store the interval promise for the running pulse duration
        $scope.runningPulseDurationpromise = null;

        //Control var for pulse controls toggle (default: show if on desktop)
        $scope.controls = {show: ($rootScope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP)};

        //Control var for response avatar slider
        $scope.responseControl = {show: false};

        //Has the live edit warning for pulse edits been displayed yet?
        $rootScope.displayedEditWarning = false;

        //Get device type - mobile/desktop
        $scope.deviceType = determineDeviceType(getViewportDimensions());

        //Config var for avatar carousel - number of slides to show (default: desktop = 5 / mobile = 3)
        $scope.slidesToShow = $scope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP ? 5 : 3;

        //Pulse preview display name
        $scope.preview = {displayName: ""};

        //All Regions
        $scope.allRegions = [];

        $rootScope.demographicsPostPublish = [];

        $scope.originalPulse = null;

        $scope.slickConfigPulse = {
            enabled: true,
            autoplay: false,
            draggable: false,
            autoplaySpeed: 3000,
            slidesToShow: $scope.slidesToShow,
            slidesToScroll: 1,
            method: {},
            event: {
                beforeChange: function (event, slick, currentSlide, nextSlide) {
                },
                afterChange: function (event, slick, currentSlide, nextSlide) {
                }
            }
        };

        var translations;

        $translate(["alert.remove_question.text_empty", "alert.remove_question.text_present", "alert.remove_question.title",
            "alert.remove_question.confirm_button_text",
            "alert.remove_question.confirm_button_text", "alert.pulse_gone_live.title", "alert.pulse_gone_live.text",
            "alert.pulse_gone_live.confirm_button_text", "alert.pulse_completed.title", "alert.pulse_completed.text",
            "alert.pulse_completed.confirm_button_text", "alert.user_responded.title", "alert.user_responded.confirm_button_text",
            "alert.remove_question_success.title", "alert.remove_question_success.text", "alert.pulse_title_absent.text",
            "alert.pulse_title_absent.title", "alert.pulse_panel_absent.title",
            "alert.pulse_panel_absent.text", "alert.pulse_close.title", "alert.pulse_close.text",
            "question.boolean_true", "question.boolean_false", "alert.question_text_absent.text", "alert.question_text_absent.title",
            "alert.question_slider_absent.text", "alert.question_slider_absent.title", "alert.question_has_answer_duplicates.text",
            "alert.question_has_answer_duplicates.title", "alert.question_has_bad_minmax.text", "alert.question_has_bad_minmax.title",
            "alert.question_min_answers.text", "alert.question_min_answers.title",
            "alert.question_slider_min_error.title", "alert.question_slider_min_error.text",
            "alert.question_slider_max_error.title", "alert.question_slider_max_error.text",
            "alert.question_slider_step_error.title", "alert.question_slider_step_error.text",
            "alert.question_slider_negative.title", "alert.question_slider_negative.text",
            "alert.question_slider_modulus_error.title", "alert.question_slider_modulus_error.text",
            "alert.question_has_bad_link.title", "alert.question_has_bad_link.text",
            "alert.intro_has_bad_link.title", "alert.intro_has_bad_link.text",
            "alert.message_has_bad_link.title", "alert.message_has_bad_link.text", "generic.yes"])
            .then(function (result) {
                translations = result;
            });

        //Handle the nested Dragula containers
        dragulaService.options($scope, 'bag-two', {
            moves: function (el, container, handle) {

                return handle.className === 'mdi mdi-cursor-move handle-bag-two';
            }
        });


        //Modal for QrCode Scan
        $scope.qrcodeDialog = function (ev) {
            qrcodeDialogController.$inject = ["$scope", "$mdDialog", "pulse"];
            $mdDialog.show({
            controller: qrcodeDialogController,
            templateUrl: "app/views/elements/includes/pulse-qrcode.html",
            clickOutsideToClose: true,
            locals: {
                pulse: $scope.pulse
            }
            });
        };

    function qrcodeDialogController($scope, $mdDialog, pulse) {
        $scope.pulse = pulse;
        $scope.closeDialog = function () {
          $mdDialog.hide();
        };
      }

            //Modal for Invite Link Scan
            $scope.inviteLinkDialog = function (ev) {
                inviteLinkDialogController.$inject = ["$scope", "$mdDialog", "pulse"];
                $mdDialog.show({
                controller: inviteLinkDialogController,
                templateUrl: "app/views/elements/includes/pulse-invite-link.html",
                clickOutsideToClose: true,
                locals: {
                    pulse: $scope.pulse
                }
                });
            };
    
        function inviteLinkDialogController($scope, $mdDialog, pulse) {
            $scope.notifyClipboard = function() {

                var translations;
                $translate(['alert.invitation_code_copied.title', 'alert.invitation_code_copied.text']).then(function(result) {
                    translations = result;
        
                    AlertService.success(
                        translations['alert.invitation_code_copied.title'],
                        translations['alert.invitation_code_copied.text']
                    )
                });
            };
            $scope.pulse = pulse;
            $scope.closeDialog = function () {
              $mdDialog.hide();
            };
          }
    
        $scope.showPulseLiteModal = function() {

            $scope.pulseLiteClosedModal();
        }

        $scope.notifyClipboard = function() {

            var translations;
            $translate(['alert.share_link_copied.title', 'alert.share_link_copied.text']).then(function(result) {
                translations = result;

                AlertService.success(
                    translations['alert.share_link_copied.title'],
                    translations['alert.share_link_copied.text']
                )
            });
        };

        $scope.pulseDemographicFiltersCallback = function (event) {

            if ($scope.pulseDemographicFilters = true) {
                $scope.pulseDemographicFilters = !$scope.pulseDemographicFilters;
            }
            else {
                $scope.pulseDemographicFilters = !$scope.pulseDemographicFilters;
            }

            event.preventDefault();
        };

        $scope.deregisterPulseUpdateHandler = $rootScope.$on("event:pulse-update", function (event, pulse) {

            if (pulse.data.status.value != "Closed" && $scope.pulse != null) {

                $scope.formatAnimation = null;

                //We need to redefine the question formatText (local value only) when updating the pulse data
                if (typeof pulse.data != "undefined") {

                    for (var i = 0; i < pulse.data.questions.length; i++) {

                        if (typeof pulse.data.questions[i] != "undefined") {

                            pulse.data.questions[i].format = findSelectedOption(pulse.data.questions[i].data.format.options).option_token;
                            pulse.data.questions[i].formatText = findSelectedOption(pulse.data.questions[i].data.format.options).option.text;

                            if (typeof pulse.data.questions[i].data.answers != "undefined") {

                                var usedAnswers = $filter("usedAnswers")(pulse.data.questions[i].data.answers);
                                pulse.data.questions[i].min_answers = usedAnswers.length > 2 ? usedAnswers.length : 2;
                                pulse.data.questions[i].displayAnswers = pulse.data.questions[i].data.answers.slice(0, pulse.data.questions[i].min_answers);

                                //Fix for slider answer value removal
                                if (pulse.data.questions[i].data.id.value == $rootScope.questionId &&
                                    (pulse.data.questions[i].formatText != "Yes/No" && pulse.data.questions[i].formatText != 'Branching' && pulse.data.questions[i].formatText != "Slider") &&
                                    (typeof $rootScope.answerTexts != "undefined" && $rootScope.answerTexts != null && $rootScope.answerTexts.length != 0)) {

                                    for(var j = 0; j < pulse.data.questions[i].data.answers.length; j++) {

                                        pulse.data.questions[i].data.answers[j].data.text.value = $rootScope.answerTexts[j];
                                    }

                                    $rootScope.answerTexts = [];
                                    $rootScope.questionId = null;
                                }
                            }
                        }
                    }
                }

                $scope.pulse = pulse;

                //Update localstorage with updated pulse data
                DataService.setItem('pulses', $scope.pulse.data.id.value, $scope.pulse);

                $scope.checkQuestionLinks();

                $scope.populatePotentialParticipants();

                $route.reload();
            }
        });

        $scope.$on("event:pulse-saving", function (event, pulse) {

            $scope.saveState = "pulse.saving_label";

            $timeout(function () {
                $scope.saveState = "pulse.saved_label";
            }, 1500);
        });

        $scope.$on("event:question-edited", function (event) {

            $scope.updatePulseImage();
            $scope.savePulse($scope.pulse, false);
        });

        $scope.$on("$destroy", function() {

            //De-register all scope event handlers when pulse controller scope is destroyed
            if(typeof $scope.deregisterPulseUpdateHandler != "undefined") {$scope.deregisterPulseUpdateHandler()}
            if(typeof $scope.deregisterGroupUpdateHandler != "undefined") {$scope.deregisterGroupUpdateHandler()}
            if(typeof $scope.deregisterDemographicsUpdateHandler != "undefined") {$scope.deregisterDemographicsUpdateHandler()}
            if(typeof $scope.deregisterRenderCompleteHandler != "undefined") {$scope.deregisterRenderCompleteHandler()}
            if(typeof $scope.deregisterLivePulseEditHandler != "undefined") {$scope.deregisterLivePulseEditHandler()}
            if(typeof $scope.deregisterLocationChangeStartHandler != "undefined") {$scope.deregisterLocationChangeStartHandler()}
            if(typeof $scope.deregisterPostDemsLoadedHandler != "undefined") {$scope.deregisterPostDemsLoadedHandler()}
        });

        function SaveController($scope, $mdDialog, validate, completeClose) {

            $scope.save = function() {

                validate(true, false);
                $mdDialog.hide();
            };

            $scope.noSave = function () {

                completeClose();
                $mdDialog.hide();
            };

            $scope.cancel = function () {

                $rootScope.routeChangedPath = $rootScope.routeCurrentPath;
                $rootScope.routeChanged = false;
                $mdDialog.hide();
            };
        }

        $scope.completeClose = function() {

            $rootScope.renderComplete = false;

            $scope.deregisterLocationChangeStartHandler();

            $scope.stopRunningPulseDuration();

            $rootScope.deletingPulse = false;
            $scope.pulse = null;

            if (typeof $scope.ablyChannel != "undefined") {

                $scope.ablyChannel.presence.leave();
                $scope.ablyChannel.detach();
            }

            $window.location = $rootScope.routeChangedPath;
        };

        //Leave pulse channel and save pulse on location change.
        $scope.deregisterLocationChangeStartHandler = $scope.$on("$locationChangeStart", function(event, next, current) {

            $rootScope.renderComplete = true;
            $rootScope.routeChanged = true;
            $rootScope.routeChangedPath = $location.path();
            $rootScope.routeCurrentPath = current;
            event.preventDefault();

            if(typeof $scope.pulse.data !== "undefined" && $scope.originalPulse !== null) {

                if($scope.pulse.data.status.value === "Draft" && !$rootScope.deletingPulse && !angular.equals($scope.pulse.data, $scope.originalPulse.data)) {

                    SaveController.$inject = ["$scope", "$mdDialog", "validate", "completeClose"];

                    $mdDialog.show({
                        controller: SaveController,
                        templateUrl: "app/views/elements/includes/save-pulse.html",
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        locals: {validate: $scope.validate, completeClose: $scope.completeClose}
                    });
                } else {

                    $scope.completeClose();
                }
            } else {

                $scope.completeClose();
            }
        });

        $scope.isActiveBranch = function (branch) {

            return $scope.selectedBranch == branch;
        };

        //Toggle rating button in Thank You message editor
        $scope.toggleRating = function () {

            $scope.starOn = !$scope.starOn;
        };

        $scope.selectQuestionCallback = function (question) {

            $scope.newQuestionHeaderVisible = false;
            $scope.questionBeingEdited.push(question.data.id.value);
        };

        $scope.share = function($event, pulse, provider) {

            SocialShareService.share("pulse", pulse.data, provider);
        };

        $scope.invite = function($event, pulse, provider) {

            SocialShareService.invite("pulse", pulse.data, provider);
        };

        $scope.setPanelSize = function ($event) {

        };

        $scope.deregisterGroupUpdateHandler = $rootScope.$on("event:group-updated", function(event, group) {

            $scope.group = group;
        });

        $scope.deregisterDemographicsUpdateHandler = $rootScope.$on("event:demographics-updated", function(event, demographics) {

            $scope.demographics = demographics;
        });

        $scope.addArabic = function () {

            DataService.fetchItem("pulse/translate/arq", $scope.pulse.data_token).then(function (result) {

                $scope.translation = angular.fromJson(result.translation);

                $scope.isTranslated = true;
                $scope.translateArabic = true;

                $scope.$broadcast("event:pulse-translated");

            });
        };

        //Check if related link is a video or inline type
        $scope.linkTypeCheck = function(type) {

            var deferred = $q.defer();

            if(typeof type.data != "undefined") {

                if(typeof type.data.link_url.value != "undefined") {

                    if(type.data.link_url.value != "") {

                        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                        var matches = type.data.link_url.value.match(regExp);

                        //Reset all options
                        for (var i = 0; i < type.data.link_type.options.length; i++) {

                            type.data.link_type.options[i].option.selected = false;
                        }

                        //Set option
                        for (var j = 0; j < type.data.link_type.options.length; j++) {

                            if (matches && type.data.link_type.options[j].option.text == "video") {

                                type.data.link_type.options[j].option.selected = true;
                                type.youTubePresent = true;
                                type.youTubeId = $sce.trustAsResourceUrl("https://www.youtube.com/embed/" + matches[2]);
                                deferred.resolve();

                            } else if (matches == null && type.data.link_type.options[j].option.text == "page") {

                                type.data.link_type.options[j].option.selected = true;
                                type.youTubePresent = false;
                                type.youTubeId = null;
                                deferred.resolve();
                            }
                        }
                    } else {

                        for (var k = 0; k < type.data.link_type.options.length; k++) {

                            type.data.link_type.options[k].option.selected = false;
                        }

                        type.youTubePresent = false;
                        type.youTubeId = null;
                        deferred.resolve();
                    }
                }
            }

            return deferred.promise;
        };

        $scope.checkQuestionLinks = function() {

            for(var i = 0; i < $scope.pulse.data.questions.length; i++) {

                $scope.linkTypeCheck($scope.pulse.data.questions[i]);
            }
        };

        $scope.checkPulseLinks = function() {

            $scope.linkTypeCheck($scope.pulse);
        };

        $scope.hasAnswerDuplicates = function(question) {

            if(typeof question.data != "undefined") {

                var answerTexts = question.data.answers.map(function (el) {
                    return el.data.text.value
                });
                answerTexts = answerTexts.filter(String); // Get rid of empty strings

                return hasDuplicates(answerTexts)
            }
        };

        //Make sure multiple min and max values are within bounds (1 - 12)
        $scope.checkMultipleMinMax = function(question) {

            if(typeof question.data != "undefined") {

                if (typeof question.data.max_answers != "undefined" && typeof question.data.min_answers != "undefined") {

                    var validAnswers = $filter("validAnswer")(question.data.answers);

                    //Set max to the total number of valid answers if it is greater (This can occur if answers are added and then removed)
                    if(parseInt(question.data.max_answers.value) > validAnswers.length) {

                        question.data.max_answers.value = validAnswers.length;
                    }

                    if(parseInt(question.data.min_answers.value) > validAnswers.length) {

                        question.data.min_answers.value = validAnswers.length;
                    }

                    return (parseInt(question.data.max_answers.value) > 12 || parseInt(question.data.max_answers.value) < 1 ||
                        parseInt(question.data.min_answers.value) > 12 || parseInt(question.data.min_answers.value) < 1 ||
                        typeof question.data.max_answers.value === "undefined" || typeof question.data.min_answers.value === "undefined")
                } else {

                    return false;
                }
            }
        };

        //Moved all the question validation logic out of directive into controller as it now occurs only on pulse save/publish
        //Note: This was required in order to keep all questions of a draft pulse in an edit state
        $scope.validate = function(notify, publish) {

            //I am automatically correcting answer dupes, both text and sequence here before final validation
            $scope.pulse = ValidationService.duplicateAnswerCorrection($scope.pulse);

            var pulseValid = true;

            for(var i = 0; i < $scope.pulse.data.questions.length; i++) {

                var question = $scope.pulse.data.questions[i];
                var validAnswers = $filter("validAnswer")(question.data.answers);
                var errorText = "";

                //If a question has a branch set, we need to display it in the error text
                if (typeof question.data.branch !== "undefined") {

                    errorText = "There is an issue with question " + parseInt(question.data.sequence.value) + " on the " +
                        integerToRanking(parseInt(question.data.branch.value)) + " branch: ";
                } else {

                    errorText = "There is an issue with question " + (i + 1) + ": ";
                }

                $scope.validAnswerCount = validAnswers.length;

                if(question.data.text.value == "") {

                    AlertService.warning(
                        translations["alert.question_text_absent.title"],
                        errorText +
                        translations["alert.question_text_absent.text"]
                    );

                    pulseValid = false

                } else if(question.formatText != "Slider" && question.formatText != "Free Text" && $scope.validAnswerCount < 2){

                    AlertService.warning(
                        translations["alert.question_min_answers.title"],
                        errorText +
                        translations["alert.question_min_answers.text"]
                    );

                    pulseValid = false

                } else if(question.formatText == "Slider" &&
                    ((question.data.slider_min.value == "" || typeof question.data.slider_min.value == "undefined") ||
                        (question.data.slider_max.value == "" || typeof question.data.slider_max.value == "undefined") ||
                        (question.data.slider_step.value == "" || typeof question.data.slider_step.value == "undefined"))) {

                    AlertService.warning(
                        translations["alert.question_slider_absent.title"],
                        errorText +
                        translations["alert.question_slider_absent.text"]
                    );

                    pulseValid = false

                } else if(question.formatText == "Slider" &&
                    (question.data.slider_min.value.toString().indexOf("-") != -1 ||
                        question.data.slider_max.value.toString().indexOf("-") != -1 ||
                        question.data.slider_step.value.toString().indexOf("-") != -1 )) {

                    AlertService.warning(
                        translations["alert.question_slider_negative.title"],
                        errorText +
                        translations["alert.question_slider_negative.text"]
                    );

                    pulseValid = false

                } else if(question.formatText == "Slider" &&
                    (parseInt(question.data.slider_min.value) >= parseInt(question.data.slider_max.value))){

                    AlertService.warning(
                        translations["alert.question_slider_min_error.title"],
                        errorText +
                        translations["alert.question_slider_min_error.text"]
                    );

                    pulseValid = false

                } else if(question.formatText == "Slider" &&
                    (parseInt(question.data.slider_max.value) <= parseInt(question.data.slider_min.value))){

                    AlertService.warning(
                        translations["alert.question_slider_max_error.title"],
                        errorText +
                        translations["alert.question_slider_max_error.text"]
                    );

                    pulseValid = false

                } else if(question.formatText == "Slider" && (parseInt(question.data.slider_step.value) < 1 ||
                    (parseInt(question.data.slider_step.value) > parseInt(question.data.slider_max.value)))){

                    AlertService.warning(
                        translations["alert.question_slider_step_error.title"],
                        errorText +
                        translations["alert.question_slider_step_error.text"]
                    );

                    pulseValid = false

                } else if(question.formatText == "Slider" &&
                    (parseInt(question.data.slider_max.value) - parseInt(question.data.slider_min.value)) % parseInt(question.data.slider_step.value) != 0){

                    AlertService.warning(
                        translations["alert.question_slider_modulus_error.title"],
                        errorText +
                        translations["alert.question_slider_modulus_error.text"]
                    );

                    pulseValid = false

                } else if(question.formatText != "Slider" && question.formatText != "Free Text" && $scope.hasAnswerDuplicates(question)) {

                    AlertService.warning(
                        translations["alert.question_has_answer_duplicates.title"],
                        errorText +
                        translations["alert.question_has_answer_duplicates.text"]
                    );

                    pulseValid = false

                } else if (question.formatText == "Multiple" && $scope.checkMultipleMinMax(question)) {

                    AlertService.warning(
                        translations["alert.question_has_bad_minmax.title"],
                        errorText +
                        translations["alert.question_has_bad_minmax.text"]
                    );

                    pulseValid = false
                } else if (question.data.link_url.value != "" && question.data.link_url.value.indexOf("http://") == -1 && question.data.link_url.value.indexOf("https://") == -1) {

                    AlertService.warning(
                        translations["alert.question_has_bad_link.title"],
                        errorText +
                        translations["alert.question_has_bad_link.text"]
                    );

                    pulseValid = false

                } else if ($scope.pulse.data.link_url.value != "" && $scope.pulse.data.link_url.value.indexOf("http://") == -1 && $scope.pulse.data.link_url.value.indexOf("https://") == -1) {

                    AlertService.warning(
                        translations["alert.intro_has_bad_link.title"],
                        translations["alert.intro_has_bad_link.text"]
                    );

                    pulseValid = false

                } else if ($scope.pulse.data.message_url.value != "" && $scope.pulse.data.message_url.value.indexOf("http://") == -1 && $scope.pulse.data.message_url.value.indexOf("https://") == -1) {

                    AlertService.warning(
                        translations["alert.message_has_bad_link.title"],
                        translations["alert.message_has_bad_link.text"]
                    );

                    pulseValid = false

                } else {}
            }

            //Check link for type and save pulse/show publish modal on completion
            var _loop = function _loop(j) {

                $scope.linkTypeCheck($scope.pulse.data.questions[j]).then(function () {

                    if (j == $scope.pulse.data.questions.length - 1) {

                        if (pulseValid) {

                            $scope.pulse = ValidationService.answerSequenceCorrection($scope.pulse);

                            if (publish && $scope.performValidation() &&
                                (RoleService.allowed(CONSTANTS.RIGHTS.PUBLISH_PULSE_ANON, true) ||
                                    RoleService.allowed(CONSTANTS.RIGHTS.PUBLISH_PULSE_COMPANY, true))) {

                                $scope.popupPanelSizeDialog(false);
                            } else if (!publish) {

                                $scope.updatePulseImage();

                                $scope.savePulse($scope.pulse, notify);
                            }
                        }
                    }
                });
            };

            for (var j = 0; j < $scope.pulse.data.questions.length; j++) {
                _loop(j);
            }

            return pulseValid;
        };

        //Controller for browser warning modal
        function liveEditWarningModalController($scope, $mdDialog, pulse, DataService, savePulse) {

            $scope.closeDialog = function () {

                $mdDialog.hide();
            };

            $scope.proceedDialog = function () {

                DataService.enablePulseEdit(pulse.data_token).then(function() {

                    $mdDialog.hide();

                    if($rootScope.liveUpdate) {

                        DataService.submit('pulse', pulse).then(function (result) {

                            $rootScope.$broadcast('event:pulse-update', result.pulse);

                            angular.element('.got-error').removeClass('got-error');
                            angular.element('.is-error').remove();

                            DataService.publish('pulse', result.pulse.data_token).then(function (result2) {

                                DataService.fetchItem('user').then(function (result3) {

                                    $scope.user = result3.user;

                                    $rootScope.renderComplete = true;
                                    $rootScope.loadingStateText = "";

                                    $window.location = '/pulse/' + result.pulse.data.id.value;
                                });
                            });
                        });
                    } else {

                        savePulse(pulse, true);
                    }
                });
            };
        }

        //Display warning if editing question while pulse is active
        //$scope.liveEditWarning = function(question) {
        //
        //	if($scope.pulse.data.status.value == "Active" && !$rootScope.displayedEditWarning) {
        //
        //		liveEditWarningModalController.$inject = ["$scope", "$mdDialog", "selectQuestion", "question", "DataService"];
        //
        //		//Call the browser warning modal
        //		$mdDialog.show({
        //			templateUrl: "app/views/elements/includes/edit-pulse-warning.html",
        //			controller: liveEditWarningModalController,
        //			parent: angular.element(document.body),
        //			clickOutsideToClose: false,
        //			locals: {
        //				selectQuestion: $scope.selectQuestionCallback,
        //				question: question,
        //				DataService: DataService
        //			}
        //		});
        //	} else {
        //
        //		$scope.selectQuestionCallback(question);
        //	}
        //};

        //Event handler for 417 error code (Pulse edit not enabled)
        $scope.deregisterLivePulseEditHandler = $rootScope.$on("event:417-pulse-update", function (event) {

            liveEditWarningModalController.$inject = ["$scope", "$mdDialog", "pulse", "DataService", "savePulse"];

            //Call the browser warning modal
            $mdDialog.show({
                templateUrl: "app/views/elements/includes/edit-pulse-warning.html",
                controller: liveEditWarningModalController,
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                locals: {
                    pulse: $scope.pulse,
                    DataService: DataService,
                    savePulse: $scope.savePulse
                }
            });

        });

        //Saves pulse in active/scheduled state and resets all edit control vars to false
        $scope.liveEditSave = function(notify) {

            $scope.editPulseTitle = false;
            $scope.editThankYouMessage = false;
            $scope.editIntroMessage = false;
            $scope.savePulse($scope.pulse, notify, false, true);
        };

        $scope.removeArabic = function () {

            $scope.isTranslated = false;
            $scope.translateArabic = false;
        };

        //Select default group for pulse if no group is already selected
        $scope.defaultGroupSelection = function() {

            if(typeof $scope.group == "undefined") {

                for(var i = 0; i < $scope.pulse.data.group.options.length; i++) {

                    if($scope.pulse.data.group.options[i].option.text == $scope.defaultPulseGroupName) {

                        $scope.pulse.data.group.options[i].option.selected = true;
                        $scope.group = $scope.pulse.data.group.options[i];
                        break;
                    }
                }
            }
        };

        $scope.resultShareable = function () {

            $scope.pulse.data.is_result_shareable.value = $scope.isResultShareable.value.toString();

            if($scope.pulse.data.status.value == 'Active') {

                $scope.savePulse($scope.pulse, false, false, true);
            }
        };

        $scope.brandShareable = function () {

            $scope.pulse.data.is_brand_shareable.value = $scope.isBrandShareable.value.toString();

            if($scope.pulse.data.status.value == 'Active') {

                $scope.savePulse($scope.pulse, false, false, true);
            }
        };

        $scope.hasImage = function (imagePath) {

            var imagePathArray = imagePath.split("/");

            return Config.get("EMPTY_IMAGE_TOKEN") != imagePathArray[5];
        };

        $scope.showQuestion = function (question) {

            if ($scope.pulse.data.is_branched.value == "1") {

                if (typeof question.data.branch !== "undefined") {

                    return ((question.data.branch.value == $scope.selectedBranch) || (parseInt(question.data.sequence.value) == 1))
                } else {

                    return true;
                }
            } else {

                return true;
            }
        };


        //re-sequence questions if pulse is branched
        $scope.reSequenceQuestions = function (question) {

            //Sequence of removed question
            var sequence = question.data.sequence.value;

            switch (sequence) {

                //Handle question in sequence 3 if sequence 2 is removed
                case "2":

                    //Branch of removed question
                    var branch = question.data.branch.value;

                    for (var j = 0; j < $scope.pulse.data.questions.length; j++) {

                        if (typeof $scope.pulse.data.questions[j].data.branch != "undefined") {

                            if ($scope.pulse.data.questions[j].data.branch.value == branch &&
                                $scope.pulse.data.questions[j].data.sequence.value == "3") {

                                $scope.pulse.data.questions[j].data.sequence.value = "2";
                                $scope.savePulse($scope.pulse, false);
                            }
                        }
                    }
                    break;
            }
        };

        //If branched question's format is changed to something else, remove questions from all other branches except 1
        $scope.branchingDisabledCleanup = function () {

            var _loop = function _loop(j) {

                if (typeof $scope.pulse.data.questions[j].data.branch != "undefined") {

                    if ($scope.pulse.data.questions[j].data.branch.value > 1) {

                        DataService.remove("pulse/question", $scope.pulse.data.questions[j].data_token).then(function () {
                            console.log('QUESTION IN LOOP IS: ', $scope.pulse.data.questions[j])
                            $scope.removeQuestionFromPulse($scope.pulse.data.questions[j], true);
                        });
                    }
                }
            };

            for (var j = 0; j < $scope.pulse.data.questions.length; j++) {
                _loop(j);
            }

            $scope.newQuestionHeaderVisible = $scope.pulse.data.questions.length < 3;
        };

        $scope.branchingEnabledPrep = function() {

            var _loop = function _loop(j) {

                $scope.pulse.data.questions[j].data.branch = {value: 1};
            };

            for (var j = 1; j < $scope.pulse.data.questions.length; j++) {
                _loop(j);
            }
        };

        /*
         MM:

         Massively simplified this. All you need to do is call the new question json from the API, merge to the pulse and pass the scope over to the question form via broadcast
         */

        $scope.addQuestionCallback = function () {

            if(!$scope.addingQuestion) {

                $scope.newQuestionHeaderVisible = false;

                DataService.createQuestion($scope.pulse.data_token).then(function (result) {

                    $scope.newQuestionFormVisible = true;
                    $scope.addingQuestion = true;
                    $scope.newQuestionId = result.question.data.id.value;

                    //Determine question format
                    if (typeof result.question.data.format.options != "undefined") {

                        for (var i = 0; i < result.question.data.format.options.length; i++) {

                            if (result.question.data.format.options[i].option.selected == true) {

                                result.question.formatText = result.question.data.format.options[i].option.text;
                            }
                        }
                    } else {

                        result.question.formatText = result.question.data.format.value;
                    }

                    var processQuestion = function() {

                        $scope.addingQuestion = false;

                        if ($scope.pulse.data.questions.length < 3) {

                            $scope.showQuestionHeader();

                        } else if ($scope.checkCurrentBranchSequence() < 3 && $scope.pulse.data.is_branched.value == "1") {

                            $scope.showQuestionHeader();
                        } else {

                            $timeout(function () {

                                angular.element(".focus_question_form_title").focus();
                            });
                        }
                    };

                    //If pulse is branched, set the branch and sequence values for the question and update it
                    if ($scope.pulse.data.is_branched.value == "1") {

                        //Set sequence and branch for new question
                        var branchMatch = false;

                        for (var j = 0; j < $scope.pulse.data.questions.length; j++) {

                            //Only include branched questions
                            if (typeof $scope.pulse.data.questions[j].data.branch != "undefined") {

                                //Only include questions on the current branch
                                if ($scope.pulse.data.questions[j].data.branch.value == $scope.selectedBranch) {


                                    //Question/s exist on the current branch already
                                    branchMatch = true;

                                    //We get the highest sequence integer of all questions on this branch
                                    $scope.currentQuestionSequence = $scope.pulse.data.questions[j].data.sequence.value;
                                }
                            }
                        }

                        //Set the new question's sequence to 1 + the current highest sequence integer for this branch
                        $scope.currentQuestionSequence = (parseInt($scope.currentQuestionSequence) + 1).toString();

                        //If there is no branch match, we are adding a question to a branch with no questions so its sequence will be 2 and we overwrite previous value
                        if (!branchMatch) {

                            $scope.currentQuestionSequence = "2";
                        }

                        result.question.data.sequence.value = $scope.currentQuestionSequence;
                        result.question.data.branch.value = $scope.selectedBranch.toString();

                        //Sequence 1 safety check - Cannot ever manually add a question in sequence 1
                        if(result.question.data.sequence.value != "1" && result.question.data.sequence.value != 1) {

                            DataService.submit("pulse/question", result.question).then(function(updatedResult) {

                                //Push new question object into questions array and show new question header if applicable
                                $scope.pulse.data.questions.push(updatedResult.question);

                                processQuestion();
                            });
                        } else {

                            DataService.remove("pulse/question", result.question.data_token).then(function () {

                                $scope.removeQuestionFromPulse(result.question, true);
                            });
                        }
                    } else {

                        //Sequence 1 safety check - Cannot ever manually add a question in sequence 1
                        if(result.question.data.sequence.value != "1" && result.question.data.sequence.value != 1) {

                            $scope.pulse.data.questions.push(result.question);

                            processQuestion();
                        } else {

                            DataService.remove("pulse/question", result.question.data_token).then(function () {

                                $scope.removeQuestionFromPulse(result.question, true);
                            });
                        }
                    }
                });
            }
        };

        //Remove any erroneous sequence 1 questions. Should only ever be 1
        $scope.checkSequences = function() {

            var sequenceOne = 0;

            var _loop = function _loop(j) {

                if ($scope.pulse.data.questions[j].data.sequence.value == "1" || $scope.pulse.data.questions[j].data.sequence.value == 1) {

                    sequenceOne++;
                }

                if (sequenceOne >= 2) {

                    DataService.remove("pulse/question", $scope.pulse.data.questions[j].data_token).then(function () {

                        $scope.removeQuestionFromPulse($scope.pulse.data.questions[j], true);
                    });
                }
            };

            for (var j = 0; j < $scope.pulse.data.questions.length; j++) {
                _loop(j);
            }
        };

        //Autocomplete for Tags input
        $scope.getMatches = function(searchText) {

            var matches = [];

            for(var i = 0; i < $scope.clientTags.length; i++) {

                if($scope.clientTags[i].indexOf(searchText) != -1) {

                    matches.push($scope.clientTags[i]);
                }
            }

            return matches;
        };

        $scope.addTag = function(tag) {

            //If tag text is set and does not already exist on the pulse, we set it
            if(typeof tag != "undefined" && tag != "" && $scope.tags.indexOf(tag) == -1) {

                $scope.tags.push(tag);
                $scope.updateTags();
                $scope.searchText = "";
            }
        };

        $scope.removeTag = function(tag) {

            //If tag text is set and does not already exist on the pulse, we remove it
            if(typeof tag != "undefined" && tag != "" && $scope.tags.indexOf(tag) != -1) {

                $scope.tags.splice($scope.tags.indexOf(tag), 1);
                $scope.updateTags();
            }
        };

        //Update tags
        $scope.updateTags = function() {

            DataService.submitTags("pulse", {"tags": $scope.tags}, $scope.pulse.data_token).then(function(result) {

                $scope.tags = [];

                for(var i = 0; i < result.pulse.data.tags.length; i++) {

                    $scope.tags.push(result.pulse.data.tags[i].text.value);
                }
            });
        };

        //Initialise pulse and client tags
        $scope.initTags = function() {

            $scope.tags = [];
            $scope.clientTags = [];

            //Fetch client tags
            DataService.profileClient("tags", {}, "").then(function(result) {

                //Populate clientTags
                for(var i = 0; i < result.tags.length; i++) {

                    $scope.clientTags.push(result.tags[i].text.value);
                }

                //Populate pulse tags
                for(var i = 0; i < $scope.pulse.data.tags.length; i++) {

                    $scope.tags.push($scope.pulse.data.tags[i].text.value);
                }
            });
        };

        //Return the current sequence for the currently selected branch
        $scope.checkCurrentBranchSequence = function () {

            var currentBranchSequence = 0;

            for (var j = 0; j < $scope.pulse.data.questions.length; j++) {

                if (typeof $scope.pulse.data.questions[j].data.branch != "undefined") {

                    if ($scope.pulse.data.questions[j].data.branch.value == $scope.selectedBranch) {

                        var val = parseInt($scope.pulse.data.questions[j].data.sequence.value);

                        currentBranchSequence = val > currentBranchSequence ? val : currentBranchSequence;
                    }
                }
            }

            return currentBranchSequence;
        };

        $scope.showQuestionHeader = function () {

            //Apply focus to new question input and show new question header
            $timeout(function () {

                angular.element(".focus_question_form_title").focus();
                $scope.newQuestionHeaderVisible = true;
            });
        };


        $scope.backCallback = function ($event) {

            $window.history.back();
            $event.preventDefault();
        };

        //Controller for Close Pulse modal
        function closePulseModalController($scope, $rootScope, $mdDialog, pulse) {

            $scope.pulse = pulse;
            $scope.submitSpinner = false;

            //Send Pulse Preview
            $scope.closePulse = function() {

                $mdDialog.hide();
                $rootScope.renderComplete = false;
                $rootScope.loadingStateText = "Closing Pulse";

                DataService.close("pulse", $scope.pulse.data_token).then(function (result) {

                    $rootScope.renderComplete = true;
                    $rootScope.loadingStateText = "";

                    $rootScope.$broadcast("event:pulse-update", result.pulse);

                    $route.reload();
                });
            };

            $scope.closeDialog = function () {

                $mdDialog.hide();
            }
        }

        /*
         Callback function for the close button
         */
        $scope.closeCallback = function ($event) {

            closePulseModalController.$inject = ["$scope", "$rootScope", "$mdDialog", "pulse"];

            $mdDialog.show({
                templateUrl: "app/views/elements/includes/close-pulse.html",
                controller: closePulseModalController,
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                targetEvent: $event,
                locals: {
                    pulse: $scope.pulse
                }
            });
        };

        /*
         Callback function for the form submit
         */
        $scope.submitCallback = function ($event, question) {
            $scope.newQuestionFormVisible = false;
            $scope.newQuestionHeaderVisible = false;
            $scope.newQuestionId = null;
            $scope.disableNewQuestionAnimation = false;

            var index = $scope.questionBeingEdited.indexOf(question.data.id.value);

            if (index != -1) {
                $scope.questionBeingEdited.splice(index, 1);
            }

            $scope.updatePulseImage();

            if($scope.pulse.data.status.value != "Draft") {

                $scope.savePulse($scope.pulse, true, false, true);
            }

            if ($scope.pulse.data.questions.length < 3) {

                $scope.showQuestionHeader();

            } else if ($scope.checkCurrentBranchSequence() < 3 && $scope.pulse.data.is_branched.value == "1") {

                $scope.showQuestionHeader();
            }
        };

        //Update the pulse image
        $scope.updatePulseImage = function () {

            if(typeof $scope.pulse.data != "undefined") {

                if ($scope.pulse.data.intro_image.value != "") {

                    $scope.pulse.data.image.value = $scope.pulse.data.intro_image.value;
                } else if (typeof $scope.pulse.data.questions[0] != "undefined" && $scope.pulse.data.questions[0].data.image.value != "") {

                    $scope.pulse.data.image.value = $scope.pulse.data.questions[0].data.image.value;
                } else if (typeof $scope.pulse.data.questions[1] != "undefined" && $scope.pulse.data.questions[1].data.image.value != "") {

                    $scope.pulse.data.image.value = $scope.pulse.data.questions[1].data.image.value;
                } else if (typeof $scope.pulse.data.questions[2] != "undefined" && $scope.pulse.data.questions[2].data.image.value != "") {

                    $scope.pulse.data.image.value = $scope.pulse.data.questions[2].data.image.value;
                }
            }
        };

        //Changes branch
        $scope.selectBranchCallback = function (event, branch) {

            $scope.newQuestionHeaderVisible = false;
            $scope.selectedBranch = branch;

            if ($scope.checkCurrentBranchSequence() < 3 && $scope.pulse.data.is_branched.value == "1") {

                $scope.showQuestionHeader();
            }

            event.preventDefault();
        };

        //If questions already exist in sequences greater than 1 when branching occurs, these are moved to branch 1
        $scope.branchQuestionToggle = function () {

            var question = $scope.pulse.data.questions[0];

            if ($scope.pulse.data.is_branched.value == "0") {

                if ($scope.canBeBranched()) {

                    var branchedSequence = parseInt(question.data.sequence.value);

                    for (var j = 0; j < $scope.pulse.data.questions.length; j++) {

                        if (parseInt($scope.pulse.data.questions[j].data.sequence.value) > branchedSequence) {

                            $scope.pulse.data.questions[j].data.branch = {value: 1};
                        }
                    }

                    //Set this question to type 'Branching' - We do it this way because the button is not bound to the model
                    for (var i = 0; i < question.data.format.options.length; i++) {

                        if (question.data.format.options[i].option.text == "Branching") {

                            question.data.format.options[i].option.selected = true;
                            question.formatText = question.data.format.options[i].option.text;
                        } else {
                            question.data.format.options[i].option.selected = false;
                        }
                    }

                    $scope.savePulse($scope.pulse, false);
                } else {

                    var translations;
                    $translate(["alert.cannot_branch_pulse.title", "alert.cannot_branch_pulse.text"]).then(function (result) {
                        translations = result;

                        AlertService.warning(
                            translations["alert.cannot_branch_pulse.title"],
                            translations["alert.cannot_branch_pulse.text"]
                        )
                    });
                }
            } else {

                var translations;
                $translate(["alert.remove_branch_pulse.title", "alert.remove_branch_pulse.text"]).then(function (result) {
                    translations = result;

                    AlertService.confirm(
                        translations["alert.remove_branch_pulse.title"],
                        translations["alert.remove_branch_pulse.text"],
                        function () {

                            for (var i = 0; i < question.data.format.options.length; i++) {

                                if (question.data.format.options[i].option.text == "Exclusive") {

                                    question.data.format.options[i].option.selected = true;
                                    question.formatText = question.data.format.options[i].option.text;
                                } else {
                                    question.data.format.options[i].option.selected = false;
                                }
                            }

                            $scope.branchingDisabledCleanup();

                            $scope.savePulse($scope.pulse, false);
                        }
                    )
                });
            }
        };

        //Checks if question can be branched
        $scope.canBeBranched = function () {

            var question = $scope.pulse.data.questions[0];

            //Only allow branching from question 1, if not already branched and if question has 2 answers
            return (question.data.sequence.value == "1" &&
                $scope.pulse.data.is_branched.value != "1" &&
                (filledAnswers(question.data.answers).length == 2));
        };

        //Only display question in pulse editor on branch type and branched question
        $scope.questionBranchFilter = function (question) {

            if (typeof question.data.branch != "undefined") {

                return (question.data.branch.value == $scope.selectedBranch || question.data.branch.value == "");

            } else {

                return true;
            }
        };

        /*
         Callback function for the Question Delete button
         */
        $scope.removeQuestion = function (question) {

            var deletetxt = translations["alert.remove_question.text_empty"];

            if (question.data.text.value != "") {
                $translate("alert.remove_question.text_present", {text: question.data.text.value}).then(function (result) {
                    deletetxt = result;
                });
            }

            AlertService.confirm(
                translations["alert.remove_question.title"],
                deletetxt,
                function () {

                    DataService.remove("pulse/question", question.data_token).then(function (result) {

                        AlertService.success(
                            translations["alert.remove_question_success.title"],
                            translations["alert.remove_question_success.text"]
                        );

                    }).then(function () {

                            $scope.removeQuestionFromPulse(question, true);
                        }
                    )
                },
                translations["alert.remove_question.confirm_button_text"]
            );
        };

        /*
         MM: Force edit state with edit action - this is used from the dashboard

         */
        $scope.isEdit = function () {
            if ($scope.pulse.data.status.value == "Draft" || ($routeParams.action == "edit" && $scope.pulse.data.status.value != "Closed")) {
                return true;
            }
            return false;
        };

        $scope.getAnswerTypeClass = function (answerType) {
            var classVar = null;

            switch (answerType) {
                case "Exclusive":
                    classVar = "mdi-format-list-checks";
                    break;
                case "Multiple":
                    classVar = "mdi-format-list-bulleted";
                    break;
                case "Slider":
                    classVar = "mdi-tune";
                    break;
                case "Drag & Drop":
                    classVar = "mdi-format-line-spacing";
                    break;
                case "Yes/No":
                    classVar = "mdi-set-left";
                    break;
                case "Image":
                    classVar = "mdi-image-filter-black-white";
                    break;
                case "Branching":
                    classVar = "mdi-arrow-decision";
                    break;
                case "Free Text":
                    classVar = "mdi-format-color-text";
                    break;
            }

            return classVar;
        };

        $scope.getQuestionTypeClass = function (questionType) {
            var classVar = null;

            switch (questionType) {
                case "Exclusive":
                    classVar = "mdi-format-list-checks";
                    break;
                case "Multiple":
                    classVar = "mdi-format-list-bulleted";
                    break;
                case "Slider":
                    classVar = "mdi-tune";
                    break;
                case "Drag & Drop":
                    classVar = "mdi-format-line-spacing";
                    break;
                case "Yes/No":
                    classVar = "mdi-set-left";
                    break;
                case "Image":
                    classVar = "mdi-image-filter-black-white";
                    break;
                case "Branching":
                    classVar = "mdi-arrow-decision";
                    break;
                case "Free Text":
                    classVar = "mdi-format-color-text";
                    break;
            }

            return classVar;
        };

        $scope.getControlTypeTooltip = function (questionType) {
            var classVar = null;

            switch (questionType) {
                case "Exclusive":
                    classVar = "Exclusive Answer";
                    break;
                case "Multiple":
                    classVar = "Multiple Answer";
                    break;
                case "Slider":
                    classVar = "Slider Answer";
                    break;
                case "Drag & Drop":
                    classVar = "Drag & Drop Answer";
                    break;
                case "Yes/No":
                    classVar = "Yes/No Answer";
                    break;
                case "Image":
                    classVar = "Image Answer";
                    break;
                case "Branching":
                    classVar = "Branching Answer";
                    break;
                case "Free Text":
                    classVar = "Free Text Answer";
                    break;
            }

            return classVar;
        };

        $scope.isItActive = function ($id) {
            return $id == $scope.isActive;
        }

        $scope.onStartedSet = function (newDate, oldDate) {
            if (moment(newDate) < moment()) {
                $mdDialog.alert({
                    title: "Error!",
                    textContent: "Start date must be in the future.",
                    ok: "OK"
                });

                $scope.pulse.data.started_at.value = oldDate;
                $scope.started_at = moment(oldDate);

            } else if (moment(newDate) > $scope.expired_at) {
                $mdDialog.alert({
                    title: "Error!",
                    textContent: "Start date must be before the end date.",
                    ok: "OK"
                });

                $scope.pulse.data.started_at.value = oldDate;
                $scope.started_at = moment(oldDate);

            } else {
                $scope.pulse.data.started_at.value = newDate;
                $scope.started_at = moment(newDate);
            }
        };

        $scope.onExpiredSet = function (newDate, oldDate) {
            if (moment(newDate) < $scope.started_at.add(1, "hours")) {
                $mdDialog.alert({
                    title: "Error!",
                    textContent: "End date must be at least 1 hour ahead of start date.",
                    ok: "OK"
                });

                $scope.pulse.data.expired_at.value = oldDate;
                $scope.expired_at = moment(oldDate);
            } else {
                $scope.pulse.data.expired_at.value = newDate;
                $scope.expired_at = moment(newDate);
            }
        };

        $scope.isQuestionVisible = function (question) {
            return (question.data.id.value == $scope.newQuestionId) && $scope.newQuestionFormVisible;
        };

        //Resequence questions if a question is removed (Only really applies if Q2 is removed when Q3 already exists)
        $scope.correctQuestionSequence = function(removedQuestion) {

            var removedSequence = removedQuestion.data.sequence.value;

            //Safety check - Never update a question"s sequence to 1
            if(removedSequence != "1" && removedSequence != 1) {

                if(typeof removedQuestion.data.branch != "undefined") {

                    var removedBranch = removedQuestion.data.branch.value;
                }

                for(var i = 0; i < $scope.pulse.data.questions.length; i++) {

                    if(parseInt($scope.pulse.data.questions[i].data.sequence.value) > parseInt(removedSequence)) {

                        if(typeof removedBranch != "undefined" && typeof $scope.pulse.data.questions[i].data.branch != "undefined") {

                            if($scope.pulse.data.questions[i].data.branch.value == removedBranch) {

                                $scope.pulse.data.questions[i].data.sequence.value = removedSequence;
                            }
                        } else {

                            $scope.pulse.data.questions[i].data.sequence.value = removedSequence;
                        }
                    }
                }
            }
        };

        // Method to use in child controllers
        $scope.removeQuestionFromPulse = function (removedQuestion, reload) {

            // Remove form question from local storage
            $scope.pulse.data.questions.forEach(function (question, index) {
                if (question.data.id.value == removedQuestion.data.id.value) {
                    $scope.pulse.data.questions.splice(index, 1);
                }
            });

            $scope.correctQuestionSequence(removedQuestion);

            var process = function () {

                $scope.newQuestionFormVisible = false;
                $scope.newQuestionId = null;

                if ($scope.pulse.data.questions.length == 0) {

                    $scope.addQuestionCallback();
                } else if ($scope.pulse.data.questions.length < 3) {

                    $scope.showQuestionHeader();

                    if (reload) {

                        $route.reload();
                    }
                } else if ($scope.checkCurrentBranchSequence() < 3 && $scope.pulse.data.is_branched.value == "1") {

                    $scope.showQuestionHeader();

                    if (reload) {

                        $route.reload();
                    }
                }
            };

            //If pulse is branched we need to re-sequence the questions
            if ($scope.pulse.data.is_branched.value == "1") {

                $scope.reSequenceQuestions(removedQuestion);

                //This timeout is temporary - it suffices for now
                $timeout(function () {
                    process()
                }, 1000)
            } else {

                process();
            }
        };

        // Setters to use from child $scopes
        $scope.setTopAnimation = function (val) {
            $timeout(function () {
                $scope.disableNewQuestionAnimation = !val;
            }, 10);
        };

        $scope.setFormatAnimation = function (value) {
            $timeout(function () {
                $scope.formatAnimation = value;
            }, 120);
        };

        $scope.performValidation = function () {

            if ($scope.pulse.data.title.value == "") {

                AlertService.warning(
                    translations["alert.pulse_title_absent.title"],
                    translations["alert.pulse_title_absent.text"]
                );
            } else if(typeof findSelectedOption($scope.pulse.data.group.options) == "undefined") {

                AlertService.warning(
                    translations["alert.pulse_panel_absent.title"],
                    translations["alert.pulse_panel_absent.text"]
                );
            } else {
                return true
            }

            return false;
        };

        $scope.pulseLiteClosedModal = function($event) {

            PulseService.pulseLiteClosedModal($event);
        };


        $scope.popupPanelSizeDialog = function (extend) {

            $scope.updatePulseImage();

            //var template = (extend ? 'extend-panel-size' : 'panel-size');
            var template = (extend ? 'extend-panel-size' : 'pulse-checkout');

            $mdDialog.show({
                controller: "PanelSizeDialogCtrl",
                templateUrl: "app/views/elements/includes/" + template + ".html",
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                scope: $scope,
                preserveScope: true
            })
                .then(function (answer) {
                    $scope.status = "You said the information was '" + answer + "'.";
                }, function () {
                    $scope.status = "You cancelled the dialog.";
                });
        };

        $scope.addThankYouMessageListener = function ($event) {

            $scope.addThankYouMessage = true;
            scrollTo("new_pulse_draft", 80);
        };

        $scope.addIntroCardListener = function ($event) {
            $scope.addIntroCard = true;
        };

        $scope.removeIntro = function () {

            $scope.addIntroCard = false;
            $scope.pulse.data.intro.value = "";
            $scope.pulse.data.link_url.value = "";
            $scope.pulse.data.intro_image.value = "";

            $scope.editIntroMessage = true;

            $scope.updatePulseImage();

            //$scope.savePulse($scope.pulse, false);
        };

        $scope.removeThankYou = function () {

            $scope.addThankYouMessage = false;
            $scope.pulse.data.message.value = "";
            $scope.pulse.data.message_url.value = "";

            $scope.editThankYouMessage = true;

            $scope.updatePulseImage();

            //$scope.savePulse($scope.pulse, false);
        };

        $scope.populatePotentialParticipants = function() {

            if($scope.pulse.data.status.value == "Draft" && !$scope.repulsed && !$scope.repulsedAnswer) {

                DataService.fetchPotentialParticipants($scope.pulse.data_token).then(function(result) {

                    if($scope.pulse !== null) {

                        var defaultPulseParticipantCount = ($scope.user.data.current_purchased_subscription.data.default_pulse_participant_count.value != null) ? parseInt($scope.user.data.current_purchased_subscription.data.default_pulse_participant_count.value) : CONSTANTS.MIN_PULSE_PARTICIPANTS;

                        $scope.pulse.maxParticipants = result.count;
                        $scope.pulse.data.participants.value = (result.count < defaultPulseParticipantCount ? result.count : defaultPulseParticipantCount);

                        $scope.originalPulse = angular.copy($scope.pulse);
                    }
                });
            } else if($scope.pulse.data.status.value == "Draft" && ($scope.repulsed || $scope.repulsedAnswer)) {

                $scope.pulse.maxParticipants = $scope.pulse.data.participants.value;

                $scope.originalPulse = angular.copy($scope.pulse);
            }
        };

        $scope.returnToPulseList = function () {

            $location.path("/pulses");
        };

        $scope.editParentPulse = function () {

            $window.open("/pulse/" + $scope.pulse.data.parent_pulse.data.id.value + "/edit", "_blank");
        };


        $scope.showCommentsModal = function(ev, question) {

            if(typeof analytics !== "undefined" && typeof analytics.track !== "undefined") {

                var user = DataService.grab("user");

                if(typeof user.data.brand_name != "undefined") {

                    analytics.track("Comments viewed", {
                        name: user.data.brand_name.value
                    });
                }
            }

            CommentsController.$inject = ["$scope", "$mdDialog", "question"];

            $mdDialog.show({
                controller: CommentsController,
                templateUrl: "app/views/elements/includes/comments.html",
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                targetEvent: ev,
                locals: {question: question}
            });
        };

        //Handle edit state check for title/intro/thanks text inputs
        $scope.checkEditState = function (event, type) {

            if($scope.pulse.data.status.value != "Active" && $scope.pulse.data.status.value != "Scheduled") {

                // Use timeout to delay examination of activeElement until after blur/focus
                // events have been processed.
                setTimeout(function () {
                    var target = document.activeElement;
                    var targetClass = target ? target.className : "";

                    switch (type) {

                        case "title":

                            $scope.editPulseTitle = $scope.pulse.data.title.value == "";
                            break;
                        case "intro":

                            //Only check edit state if we are clicking outside of the relevant control
                            if (targetClass.indexOf("intro-link-button") == -1 &&
                                targetClass.indexOf("intro-image-span") == -1 &&
                                targetClass.indexOf("add-link") == -1) {

                                // $scope.editIntroMessage = $scope.pulse.data.intro.value == "";
                            }
                            break;
                        case "thanks":

                            //Only check edit state if we are clicking outside of the relevant control
                            if (targetClass.indexOf("thanks-link-button") == -1 &&
                                targetClass.indexOf("add-link") == -1) {

                                // $scope.editThankYouMessage = $scope.pulse.data.message.value == "";
                            }
                            break;
                    }
                }, 1);
            }
        };

        //Initial edit states for title/intro/thanks text inputs
        $scope.initEditState = function () {

            $scope.editPulseTitle = $scope.pulse.data.title.value == "";
            // $scope.editIntroMessage = $scope.pulse.data.intro.value == "";
            // $scope.editThankYouMessage = $scope.pulse.data.message.value == "";
            $scope.editIntroMessage = true;
            $scope.editThankYouMessage = true;
            
        };

        $scope.filledAnswers = filledAnswers;

        $scope.$watch("editPulseTitle", function (newVal) {

            if (newVal) {

                $timeout(function () {

                    angular.element(".focus_pulse_title").focus();
                })
            }
        });

        $scope.$watch("editIntroMessage", function (newVal) {

            if (newVal) {

                $timeout(function () {

                    angular.element(".focus_pulse_intro").focus();
                })
            }
        });

        $scope.$watch("editThankYouMessage", function (newVal) {

            if (newVal) {
                $timeout(function () {

                    angular.element(".focus_pulse_thanks").focus();
                })
            }
        });

        $scope.toggleRegionsSelected = function() {

            $scope.$broadcast("event:region-filter", $scope.pulse.data.regions);
        };

        $scope.deregisterPostDemsLoadedHandler = $rootScope.$on("event:post-dems-loaded", function(event, demographicsPostPublish) {

            $scope.demographicsPostPublish = [];

            if (typeof $scope.pulse.data.published_at != "undefined" && $scope.pulse.data.published_at.value != null) {

                //If pulse was published post env merge, we need to add pulse dems not included yet in responses
                var mergeDate = new Date("2018-01-21T00:00:00Z");
                var pulseDate = new Date($scope.pulse.data.published_at.value);

                if (pulseDate > mergeDate) {

                    for (var i = 0; i < $scope.demographics.length; i++) {

                        if ($scope.isSelected($scope.demographics[i])) {

                            $scope.demographicsPostPublish.push($scope.demographics[i]);
                        }
                    }
                } else {

                    $scope.demographicsPostPublish = demographicsPostPublish;
                }
            } else {

                $scope.demographicsPostPublish = demographicsPostPublish;
            }
        });

        $scope.toggleAllDemographicsSelected = function() {

            if(typeof $scope.demographicsPostPublish != "undefined") {

                for(var i = 0; i < $scope.demographicsPostPublish.length; i++) {

                    $scope.demographicsPostPublish[i].showFilter = false;
                }

                $scope.$broadcast("event:filter-off");
            }

            $scope.currentFilterCategory = null;
        };

        $scope.toggleSubDemographicsSelected = function(demographic) {

            for (var j = 0; j < demographic.values.options.length; j++) {

                if(demographic.values.options[j].option.showFilter) {

                    $scope.$broadcast("event:filter-switch", demographic);
                    return;
                }
            }

            demographic.showFilter = false;
            $scope.allDemographics.selected = true;
            $scope.currentFilterCategory = null;
            $scope.$broadcast("event:filter-off", demographic);
        };

        $scope.toggleDemographicsSelected = function(demographic) {

            $scope.currentFilters = [];

            if(typeof $scope.demographicsPostPublish != "undefined") {

                for(var i = 0; i < $scope.demographicsPostPublish.length; i++) {

                    if($scope.demographicsPostPublish[i].text != demographic.text) {

                        $scope.demographicsPostPublish[i].showFilter = false;
                    }
                }
            }

            if(demographic.showFilter) {

                for(var j = 0; j < demographic.values.options.length; j++) {

                    demographic.values.options[j].option.showFilter = true;
                }

                $scope.allDemographics.selected = false;
                $scope.currentFilterCategory = demographic;
                $scope.$broadcast("event:filter-switch", demographic);
            } else {

                $scope.allDemographics.selected = true;
                $scope.currentFilterCategory = null;
                $scope.$broadcast("event:filter-off");
            }
        };

        $scope.anyDemographicsSelected = function() {

            if(typeof $scope.demographics != "undefined") {

                for(var i = 0; i < $scope.demographics.length; i++) {

                    if ($scope.isSelected($scope.demographics[i])) {

                        return true;
                    }
                }

                return false;
            }
        };
        


        $scope.demographicColors = function() {

            if(typeof $scope.demographics != "undefined") {

                for(var i = 0; i < $scope.demographics.length; i++) {

                    if($scope.isSelected($scope.demographics[i])) {

                        var colorCount = 0;

                        //var shadesRequired = 4;
                        var base = 0;
                        //var percToAddPerIteration = (100 / shadesRequired) / 100;
                        var percToAddPerIteration = 0.20;

                        for(var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                            //if($scope.demographics[i].values.options[j].option.selected) {

                            $scope.demographics[i].values.options[j].option.color = shadeColor(CONSTANTS.PULSE_CARD_COLORS[colorCount], base);

                            base = base + percToAddPerIteration;

                            if(base == 0.40) {

                                if(CONSTANTS.PULSE_CARD_COLORS.length - 1 > colorCount) {

                                    colorCount++;
                                } else {

                                    colorCount = 0;
                                }

                                base = 0;
                            }
                            //}
                        }
                    }
                }
            }
        };

        $scope.zeroPercent = function(option) {

            return $scope.currentFilters.indexOf(option.option.text) != -1;
        };

        // $scope.sideNavState = false;
        //Open Panel/Filters config modal
        $scope.openConfigModal = function($event, type) {

            //Only allow config of panel and filters if the pulse is in Draft state
            if($scope.pulse.data.status.value == "Draft" && !$scope.repulsed) {

                $rootScope.currentPulse = $scope.pulse;

                switch(type) {

                    case 'filter':

                        $rootScope.customBackDrop = true;
                        $mdSidenav('right-filter').onClose(function () {

                            $rootScope.filterOpen = false;
                            $rootScope.customBackDrop = false;
                        });

                        $mdSidenav('right-filter').toggle().then(function(){

                            $rootScope.filterOpen = $mdSidenav('right-filter').isOpen();

                            if($rootScope.filterOpen) {

                                $rootScope.$broadcast("event:filters-open");
                            }
                        });
                        break;

                    case 'panel':
                        // console.log('CHANNEL')
                        $rootScope.customBackDrop = true;
                        $mdSidenav('right-channel').onClose(function () {

                            $rootScope.channelOpen = false;
                            $rootScope.customBackDrop = false;
                        });

                        $mdSidenav('right-channel').toggle().then(function(){

                            $rootScope.channelOpen = $mdSidenav('right-channel').isOpen();

                            if($rootScope.channelOpen) {

                                $rootScope.$broadcast("event:channels-open");
                            }
                            $timeout(function() {
                                $window.dispatchEvent(new Event("resize"));
                            }, 500);
                        });

                        // $mdDialog.show({
                        //     templateUrl: "app/views/elements/includes/" + type + ".html",
                        //     targetEvent: $event,
                        //     parent: angular.element(document.body),
                        //     clickOutsideToClose: false,
                        //     scope: $scope,
                        //     preserveScope: true
                        // });
                        break;
                }
                $timeout(function() {
                    $window.dispatchEvent(new Event("resize"));
                }, 2000);

                $event.preventDefault();
            }
        };

        //Update the user
        $scope.updateUser = function() {

            DataService.submit("user", $scope.user).then(function (result) {

                $scope.user = result.user;
            });
        };

        $scope.determineInitProgressPercentage = function() {

            if(typeof $scope.group !== 'undefined') {

                return parseInt($scope.group.option.init_progress_percentage) > 100 ? 100 : $scope.group.option.init_progress_percentage;
            } else {

                return 0;
            }
        };

        //Check if any demographics for a particular category are selected
        $scope.isSelected = function(demographic){

            if(typeof demographic != "undefined") {

                var selected = false;

                for(var index = 0; index < demographic.values.options.length; index++) {

                    if(demographic.values.options[index].option.selected){

                        selected = true;
                    }
                }

                return selected;
            }
        };

        //Check anon/brand publishing rights
        $scope.publishFromCompany = function() {

            return RoleService.allowed(CONSTANTS.RIGHTS.PUBLISH_PULSE_COMPANY, false);
        };

        // starts the interval for the running pulse duration
        $scope.startRunningPulseDuration = function() {

            // stops any running interval to avoid two intervals running at the same time
            $scope.stopRunningPulseDuration();

            //Interval for the running pulse duration clock - updates every second
            $scope.runningPulseDurationpromise = $interval(function() {

                $scope.calculatePulseTimeSinceStart();
            }, 1000);
        };

        // stops the interval for the running pulse duration
        $scope.stopRunningPulseDuration = function() {

            $interval.cancel($scope.runningPulseDurationpromise);
        };

        function CommentsController($scope, $mdDialog, question) {

            $scope.question = question;

            $scope.ctrl = {
                "searchTerm": ""
            };

            $scope.validateSearch = function(commentText) {

                return (commentText.indexOf($scope.ctrl.searchTerm) !== -1);
            };

            $scope.getQuestionComments = function() {

                DataService.getQuestionComments(question.data_token).then(function (result) {

                    $scope.comments = result;
                });
            };

            //Initially populate comments
            $scope.getQuestionComments();

            $scope.closeDialog = function () {

                $mdDialog.hide();
            }
        }

        $scope.calculatePulseTimeSinceStart = function () {

            if (typeof $scope.pulse.data.published_at != "undefined" && $scope.pulse.data.published_at.value != null) {

                var currentDate = formatDate(new Date(), new Date());
                var publishedAt = ((new Date($scope.pulse.data.published_at.value) < new Date($scope.pulse.data.started_at.value) || $scope.pulse.data.published_at.value.charAt(0) == "-") ? $scope.pulse.data.started_at.value : $scope.pulse.data.published_at.value);

                if($scope.pulse.data.status.value == "Closed") {

                    $scope.liveFor = dateDiff(publishedAt, $scope.pulse.data.ended_at.value);
                } else {

                    $scope.liveFor = dateDiff(publishedAt, currentDate);
                }

                //Set the form of the word
                $scope.daysForm = $scope.liveFor.d == 1 ? "day" : "days";
                $scope.hoursForm = $scope.liveFor.h == 1 ? "hour" : "hours";
                $scope.minutesForm = $scope.liveFor.m == 1 ? "min" : "mins";
                $scope.secondsForm = $scope.liveFor.s == 1 ? "sec" : "secs";
            }
        };

        $scope.processPulseStartAndCloseDateTimes = function () {

            if (typeof $scope.pulse.data.published_at != "undefined" && $scope.pulse.data.published_at.value != null) {

                var publishedAt = new Date((new Date($scope.pulse.data.published_at.value) < new Date($scope.pulse.data.started_at.value)
                    || $scope.pulse.data.published_at.value.charAt(0) == "-"
                    || $scope.pulse.data.published_at.value == "") ? $scope.pulse.data.started_at.value : $scope.pulse.data.published_at.value);

                $scope.pulseStartedDate = prettyDate(publishedAt, false, false);
                $scope.pulseStartedTime = formatTime(publishedAt, false);
                $scope.pulseStartedDateTime = simpleDate(publishedAt) + " at " + formatTime(publishedAt, true);
            }

            if (typeof $scope.pulse.data.ended_at != "undefined") {

                var ended_at = new Date($scope.pulse.data.ended_at.value);

                $scope.pulseClosedDate = prettyDate(ended_at, false);
                $scope.pulseClosedTime = formatTime(ended_at, false);
                $scope.pulseClosedDateTime = simpleDate(ended_at) + " at " + formatTime(ended_at, true);
            }

            if (typeof $scope.pulse.data.expired_at != "undefined") {

                var expired_at = new Date($scope.pulse.data.expired_at.value);

                $scope.pulseExpiresDate = prettyDate(expired_at, false);
                $scope.pulseExpiresTime = formatTime(expired_at, false);
                $scope.pulseExpiresDateTime = simpleDate(expired_at) + " at " + formatTime(expired_at, true);
            }
        };

        function pulseSheetMenu($scope, $mdBottomSheet, PulseService, pulse, returnToPulseList, preview, validate, allRegions, demographicsPostPublish) {


            function pulseSheetShareMenu($scope, $mdBottomSheet, SocialShareService, pulse) {

                $scope.pulse = pulse;

                $scope.share = function($event, pulse, provider) {

                    SocialShareService.share("pulse", pulse.data, provider);
                    $mdBottomSheet.hide();
                };
            }

            pulseSheetShareMenu.$inject = ["$scope", "$mdBottomSheet", "SocialShareService", "pulse"];

            $scope.showSharePulseSheet = function() {

                $mdBottomSheet.show({
                    templateUrl: "app/views/elements/pulse/pulseSheetShareMenu.html",
                    controller: pulseSheetShareMenu,
                    locals: {
                        SocialShareService: SocialShareService,
                        pulse: pulse
                    }
                }).catch(function(error) {
                    // User clicked outside or hit escape
                });
            };

            $scope.validate = function(notify, publish) {

                validate(notify, publish);
                $mdBottomSheet.hide();
            };

            $scope.rePulse = function($event) {

                PulseService.rePulse($event, pulse);
                $mdBottomSheet.hide();
            };

            $scope.duplicatePulse = function($event) {

                PulseService.duplicatePulse($event, pulse, false);
                $mdBottomSheet.hide();
            };

            $scope.deletePulse = function ($event) {

                PulseService.deletePulse($event, pulse, returnToPulseList, null);
                $mdBottomSheet.hide();
            };

            $scope.previewPulseModal = function($event) {

                PulseService.previewPulseModal($event, pulse, preview.displayName, validate);
                $mdBottomSheet.hide();
            };

            $scope.extractPulseData = function($event) {

                PulseService.extractPulseData($event, pulse, allRegions);
                $mdBottomSheet.hide();
            };

            $scope.extractPulseDemographicSummary = function($event) {

                PulseService.extractPulseDemographicSummary($event, pulse, allRegions, demographicsPostPublish);
                $mdBottomSheet.hide();
            };
        }

        pulseSheetMenu.$inject = ["$scope", "$mdBottomSheet", "PulseService", "pulse", "returnToPulseList", "preview", "validate", "allRegions", "demographicsPostPublish"];

        $scope.showPulseSheet = function() {

            $mdBottomSheet.show({
                templateUrl: "app/views/elements/pulse/pulseSheetMenu.html",
                controller: pulseSheetMenu,
                locals: {
                    pulse: $scope.pulse,
                    returnToPulseList: $scope.returnToPulseList,
                    displayName: $scope.displayName,
                    preview: $scope.preview,
                    validate: $scope.validate,
                    allRegions: $scope.allRegions,
                    demographicsPostPublish: $scope.demographicsPostPublish
                }
            }).catch(function(error) {
                // User clicked outside or hit escape
            });
        };

        $scope.isUndefined = function (val) {

            return (typeof val === "undefined");
        };

        var getAllQuestionComments = function() {

            var _loop = function _loop(i) {

                DataService.getQuestionComments($scope.pulse.data.questions[i].data_token).then(function (result) {

                    $scope.pulse.data.questions[i].comments = result;
                    var mostPositiveSentimentScore = 0.00;
                    var mostPositiveSentimentComment = null;
                    var mostNegativeSentimentScore = 0.00;
                    var mostNegativeSentimentComment = null;

                    for(var j = 0; j < $scope.pulse.data.questions[i].comments.data.length; j++) {

                        var sentimentScore = parseFloat(parseFloat($scope.pulse.data.questions[i].comments.data[j].sentiment_score.value).toFixed(2));

                        if(mostPositiveSentimentComment == null) {

                            mostPositiveSentimentComment = $scope.pulse.data.questions[i].comments.data[j];
                            mostPositiveSentimentScore = sentimentScore;
                        }

                        if(mostNegativeSentimentComment == null) {

                            mostNegativeSentimentComment = $scope.pulse.data.questions[i].comments.data[j];
                            mostNegativeSentimentScore = sentimentScore;
                        }

                        //if(sentimentScore >= 0.00) {

                        mostPositiveSentimentComment = sentimentScore > mostPositiveSentimentScore ? $scope.pulse.data.questions[i].comments.data[j] : mostPositiveSentimentComment;
                        mostPositiveSentimentScore = sentimentScore > mostPositiveSentimentScore ? sentimentScore : mostPositiveSentimentScore;

                        //} else {

                        mostNegativeSentimentComment = sentimentScore < mostNegativeSentimentScore ? $scope.pulse.data.questions[i].comments.data[j] : mostNegativeSentimentComment;
                        mostNegativeSentimentScore = sentimentScore < mostNegativeSentimentScore ? sentimentScore : mostNegativeSentimentScore;
                        //}
                    }

                    $scope.pulse.data.questions[i].comments.mostPositiveSentimentScore = parseFloat(parseFloat(mostPositiveSentimentScore).toFixed(2));
                    $scope.pulse.data.questions[i].comments.mostPositiveSentimentComment = mostPositiveSentimentComment;
                    $scope.pulse.data.questions[i].comments.mostNegativeSentimentScore = parseFloat(parseFloat(mostNegativeSentimentScore).toFixed(2));
                    $scope.pulse.data.questions[i].comments.mostNegativeSentimentComment = mostNegativeSentimentComment;
                });
            };

            for (var i = 0; i < $scope.pulse.data.questions.length; i++) {
                _loop(i);
            }
        };

        var setAllQuestionFormats = function() {

            for(var i = 0; i < $scope.pulse.data.questions.length; i++) {

                $scope.pulse.data.questions[i].format = findSelectedOption($scope.pulse.data.questions[i].data.format.options).option_token;
                $scope.pulse.data.questions[i].formatText = findSelectedOption($scope.pulse.data.questions[i].data.format.options).option.text;
            }
        };

        var demographicViewControls = function() {

            for(var i = 0; i < $scope.demographics.length; i++) {

                $scope.demographics[i].showCategory = ($rootScope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP);

                if($scope.demographics[i].format_id === 8) {

                    var selectedDetected = false;

                    for(var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                        if(!selectedDetected && $scope.demographics[i].values.options[j].option.selected) {

                            $scope.demographics[i].lowValue = $scope.demographics[i].values.options[j].option.text;
                            selectedDetected = true;
                        }

                        if(selectedDetected && $scope.demographics[i].values.options[j].option.selected) {

                            $scope.demographics[i].highValue = $scope.demographics[i].values.options[j].option.text;
                        }
                    }
                }
            }
        };

        var getAllRegions = function() {

            DataService.fetchMetaData('regions').then(function(response) {

                $scope.allRegions = response.regions;
            });
        };

        var setDefaultRegion = function(pulse) {

            if(typeof $scope.user.data.client_home_region != "undefined") {

                if($scope.user.data.client_home_region != null) {

                    for(var i = 0; i < pulse.data.regions.options.length; i++) {

                        pulse.data.regions.options[i].option.selected = ($scope.user.data.client_home_region.id == pulse.data.regions.options[i].option.id);
                    }
                }
            }

            return pulse;
        };

        var setOriginalParticipantCount = function() {

            $scope.originalParticipantCount = $scope.pulse.data.participants.value;
        };

        //Initialise pulse data
        $scope.init = function(pulse) {

            $scope.pulse = pulse;

            $scope.audienceSwitch = ($scope.pulse.data.is_no_push == 1);

            if($routeParams.action == "duplicate") {

                $scope.duplicatePulse();
            }

            if($scope.pulse.data.status.value == "Scheduled") {

                $scope.waitingResponses = 3;
            }

            if($scope.pulse.data.status.value == "Active" && $scope.pulse.data.responses.value == 0) {

                $scope.waitingResponses = 0;
                $scope.showProgress = true;
                $scope.waitingResponsesAnimation = function() {
                    var animationProgress = angular.element( document.querySelector( '.ils-progress') );

                    animationProgress.addClass('open');

                    var stepInterval = $interval(function () {

                        if($scope.waitingResponses < 2) {
                            animationProgress.removeClass('open');

                            $scope.waitingResponses++;


                            $timeout(function(){
                                animationProgress.addClass('open');
                            },100)

                        } else {
                            clearInterval(stepInterval);
                        }

                        $rootScope.renderComplete = true;
                        $rootScope.loadingStateText = "";

                    }, 10000);

                };

                $timeout(function () {
                    $scope.waitingResponsesAnimation();
                }, 1000);
            }

            //Toggles for thank you message, intro card & link toggle (intro card)
            $scope.addThankYouMessage = $scope.pulse.data.message.value != "";
            $scope.addIntroCard = ($scope.pulse.data.intro.value != "" || $scope.pulse.data.intro_image.value != "");
            $scope.showIntroLink = $scope.pulse.data.link_url.value != "";
            $scope.showThankYouLink = $scope.pulse.data.message_url.value != "";

            //Purchased pulse
            $scope.isPurchased = (typeof $scope.pulse.data.extract_price !== "undefined");

            $scope.questionBeingEdited = [];
            $scope.originalValues = [];

            $scope.group = findSelectedOption($scope.pulse.data.group.options);
            $scope.queryObject = parseQueryObject($scope.group);
            $scope.demographics = $scope.pulse.data.demographics;

            if($scope.pulse.data.status.value != "Draft") {

                $scope.demographicColors();
            }

            $scope.defaultGroupSelection();

            //If pulse is in draft state, add question or question header accordingly
            if($scope.pulse.data.status.value == "Draft") {

                if(RoleService.allowed(CONSTANTS.RIGHTS.CREATE_EDIT_PULSE, true)) {

                    if ($scope.pulse.data.title.value == "") {

                        //Once DOM is rendered, focus on title input if it is empty
                        $scope.deregisterRenderCompleteHandler = $rootScope.$on("event:render_complete", function (event) {

                            $timeout(function () {
                                angular.element(".focus_pulse_title").focus();
                            })
                        });
                    }

                    if ($scope.pulse.data.questions.length == 0) {

                        $scope.addQuestionCallback();
                    } else if ($scope.pulse.data.questions.length < 3) {

                        $scope.showQuestionHeader();
                    } else if ($scope.checkCurrentBranchSequence() < 3 && $scope.pulse.data.is_branched.value == "1") {

                        $scope.showQuestionHeader();
                    }
                }
            }

            //Detach from Ably channel if already attached
            if (typeof $scope.ablyChannel != "undefined") {

                $scope.ablyChannel.presence.leave();
                $scope.ablyChannel.detach();
            }

            if ($scope.pulse.data.status.value !== "Closed") {

                $scope.ablyChannel = AblyService.client().channels.get("pulse:data:" + $routeParams.id);

                $scope.ablyChannel.presence.enter();

                $scope.ablyChannel.subscribe("pulse_go_live", function ($ablyMessage) {

                    //Check that pulse is not already active
                    if($scope.pulse.data.status.value != "Active") {

                        $scope.preInit();
                    }
                });

                if ($scope.pulse.data.status.value != "Draft") {

                    $scope.ablyChannel.subscribe("pulse_completed", function ($ablyMessage) {

                        //Check that pulse is active
                        if($scope.pulse.data.status.value == "Active") {

                            $scope.pulse.data.status.value = "Closed";

                            //Set end date to current date
                            $scope.pulse.data.ended_at.value = formatDate(new Date(), new Date());

                            $scope.stopRunningPulseDuration();

                            $scope.processPulseStartAndCloseDateTimes();

                            $rootScope.$broadcast("event:pulse-update", $scope.pulse);

                            if($scope.pulse.data.pulse_type_id.value == CONSTANTS.PULSE_LITE) {

                                $timeout(function() {

                                    $scope.pulseLiteClosedModal();
                                }, 3000)
                            } else {

                                $route.reload();
                            }
                        }
                    });

                    $scope.lastAblyMessage = undefined;
                    $scope.previousAblyMessage = undefined;

                    $scope.ablyChannel.subscribe("user_responded", function ($ablyMessage) {

                        var payload = angular.fromJson($ablyMessage.data);

                        if(typeof payload.data != "undefined") {

                            for(var key in payload.data) {

                                if(typeof payload.data[key][0] != "undefined") {

                                    if(typeof payload.data[key][0][0] != "undefined") {

                                        var uniqueResponseId = payload.data[key][0][0];
                                    }
                                }
                            }

                            if(typeof uniqueResponseId != "undefined") {

                                if ($scope.pulseUniqueResponses.indexOf(uniqueResponseId) == -1) {

                                    $scope.previousAblyMessage = $scope.lastAblyMessage;

                                    $scope.lastAblyMessage = $ablyMessage;

                                    //Increment pulse responses
                                    $scope.pulse.data.responses.value++;

                                    $scope.pulseUniqueResponses.push(uniqueResponseId);

                                    $scope.$broadcast("event:user_responded", payload);
                                }
                            }
                        }
                    });
                }
            }

            if ($scope.isEdit()) {
                //for the start and end date editing
                //$scope.minimum_started_at_date = new Date();
                //$scope.minimum_expired_at_date = new Date();
                var started_at = moment($scope.pulse.data.started_at.value);
                $scope.started_at = (started_at.format() == "Invalid date") ? moment() : started_at;
                $scope.pulse.data.started_at.value = $scope.started_at;
                var expired_at = moment($scope.pulse.data.expired_at.value);

                if ($scope.pulse.data.status.value == "Draft" && expired_at < moment()
                    .add(1, "hours")) {
                    expired_at = moment()
                        .add(1, "hours");
                }

                $scope.expired_at = (expired_at == "Invalid Date") ? moment()
                    .add(1, "hours") : expired_at;

                $scope.pulse.data.expired_at.value = $scope.expired_at;
            }

            if (typeof $scope.pulse.data.is_result_shareable != "undefined") {

                $scope.isResultShareable = {"value": parseInt($scope.pulse.data.is_result_shareable.value)};
            }

            if (typeof $scope.pulse.data.is_brand_shareable != "undefined") {

                $scope.isBrandShareable = {"value": parseInt($scope.pulse.data.is_brand_shareable.value)};
            }

            //check for existence of parent pulse - Only for repulsed pulses
            if (typeof $scope.pulse.data.parent_pulse != "undefined") {

                $scope.repulsed = true;
            }

            //check for existence of parent answer - Only for repulsed answers
            if (typeof $scope.pulse.data.parent_answer != "undefined") {

                $scope.repulsedAnswer = true;
            }

            demographicViewControls();

            $scope.calculatePulseTimeSinceStart();

            $scope.processPulseStartAndCloseDateTimes();

            $scope.initTags();

            if($scope.pulse.data.status.value == "Draft") {

                $scope.initEditState();

                $scope.checkPulseLinks();

                $scope.checkSequences();

                //If pulse is in draft state we show regions if there are more than 2
                $scope.showPulseRegions = $scope.pulse.data.regions.options.length >= 2;

                $scope.regions.showRegions = $scope.showPulseRegions;

                for(var i = 0; i < $scope.pulse.data.regions.options.length; i++) {

                    if($scope.pulse.data.regions.options[i].option.selected) {

                        $scope.selectedRegion = $scope.pulse.data.regions.options[i].option;
                    }
                }

                //Always disable brand sharing if user does not have the publish from company right (toggle is also hidden)
                $scope.pulse.data.is_brand_shareable.value = ($scope.publishFromCompany() ? $scope.pulse.data.is_brand_shareable.value : "0");
            }

            if($scope.pulse.data.status.value != "Draft") {

                var selectedRegionCount = 0;

                for(var i = 0; i < $scope.pulse.data.regions.options.length; i++) {

                    if($scope.pulse.data.regions.options[i].option.selected) {

                        selectedRegionCount++;
                        $scope.selectedRegion = $scope.pulse.data.regions.options[i].option;
                        $scope.pulse.data.regions.options[i].option.filterBy = true;
                    } else {

                        $scope.pulse.data.regions.options[i].option.filterBy = false;
                    }

                    $scope.showPulseRegions = (selectedRegionCount >= 2);

                    $scope.regions.showRegions = $scope.showPulseRegions;
                }
            }

            $scope.checkQuestionLinks();

            $scope.populatePotentialParticipants();

            getAllQuestionComments();

            setAllQuestionFormats();

            getAllRegions();

            //Start the pulse duration clock if it"s in an active state
            if($scope.pulse.data.status.value == "Active") {

                $scope.startRunningPulseDuration();
            }

            setOriginalParticipantCount();
        };

        //Pre-initialisation - Determine if pulse has just been created or if we are pulling an existing pulse
        $scope.preInit = function() {

            $rootScope.renderComplete = false;
            $rootScope.loadingStateText = "";

            if ($rootScope.createdPulse != null) {

                if (typeof $scope.user.data.client_home_region != "undefined") {

                    if ($scope.user.data.client_home_region != null) {

                        for (var i = 0; i < $rootScope.createdPulse.data.regions.options.length; i++) {

                            $rootScope.createdPulse.data.regions.options[i].option.selected = ($scope.user.data.client_home_region.id == $rootScope.createdPulse.data.regions.options[i].option.id);
                        }
                    }
                }

                DataService.submit("pulse", $rootScope.createdPulse).then(function (result) {

                    $scope.init(result.pulse);
                    $rootScope.createdPulse = null;
                });
            } else {

                DataService.fetchItem(endpoint, data_token).then(function (result) {

                    var obj = "";

                    switch (endpoint) {

                        case "pulses/catalogue/pulse":

                            obj = "purchased_pulse";
                            break;
                        case "pulse":
                        default:

                            obj = "pulse";
                            break;
                    }

                    $scope.init(result[obj]);

                }, function () {

                    $scope.pulse = undefined;
                });
            }
        };

        $scope.preInit();
    }]);
