
onePulse.config(['$compileProvider', function ($compileProvider) {

	$compileProvider.debugInfoEnabled(false);
}]);

onePulse.config(['$mdThemingProvider', '$translateProvider', '$provide',
    function($mdThemingProvider, $translateProvider, $provide) {

        var host = window.location.hostname;
        var	wlClient = null;
        var theme = mdTheme;

        if (host.indexOf('.') >= 0) {

            wlClient = host.split('.')[0];
        }

        try {

            theme = (wlClient !== null ? eval(wlClient + "Theme") : mdTheme);
        } catch (e) {}

        var customPrimary = theme.customPrimary;
        $mdThemingProvider.definePalette('customPrimary', customPrimary);

        var customAccent = theme.customAccent;
        $mdThemingProvider.definePalette('customAccent', customAccent);

        var customWarn = theme.customWarn;
        $mdThemingProvider.definePalette('customWarn', customWarn);

        $mdThemingProvider.theme('default').primaryPalette('customPrimary', {

                // 'default': '500',
            }).accentPalette('customAccent', {

                // 'default': '200',
                // 'hue-1': '800',
                // 'hue-2': '600',
                // 'hue-3': '500'
            }).warnPalette('customWarn',{

                'hue-3': 'A700'
            });

        // Watch for theme updates to dynamically change the theme.
        $mdThemingProvider.generateThemesOnDemand(true);

        // Save the theming provider for later use
        $provide.value('mdThemingProvider', $mdThemingProvider);

        $translateProvider.useLoader('TranslationService');
        $translateProvider.preferredLanguage('en_GB');
        $translateProvider.useSanitizeValueStrategy('escapeParameters');

    }]);

//Disable Aria label warnings in console
onePulse.config(['$mdAriaProvider', function ($mdAriaProvider) {
	$mdAriaProvider.disableWarnings();
}]);

/*
 * Configuration Service
 */
onePulse.service('Config', ['$window', 'CONSTANTS', '$q', '$rootScope', '$injector',
	function Config($window, CONSTANTS, $q, $rootScope, $injector) {

	var _environments = environments(CONSTANTS);
	var	_environment;

	return {
		getEnvironment: function () {
			var host = $window.location.host;

			if (_environment) {
				return _environment;
			}

			for (var environment in _environments) {
				if (typeof _environments[environment].host && _environments[environment].host == host) {
					_environment = environment;

					return _environment;
				}
			}

			return 'whitelabel';

		},
		get: function (property) {

			if (typeof $rootScope.country != "undefined") {

				switch($rootScope.country) {

					case 'GB':
						return _environments['uk'].config[property];
						break;
					default:
						return _environments['us'].config[property];
						break;
				}
			} else {

				return _environments[this.getEnvironment()].config[property];
			}
		},

		countryCheck: function(force, initial) {

			var deferred = $q.defer();
			var host = $window.location.host;

			if(host == 'www.onepulse.com' || host == 'client-v2.onepulse.com' || force) {

				var response = {
					country: 'GB'
				}

				$rootScope.country = response.country;
				$injector.get('DataService').refreshURLS();

				deferred.resolve(response);
			} else {

				deferred.resolve();
			}

			return deferred.promise;
		}
	}
}]);

onePulse.config(["$httpProvider", "$animateProvider", function ($httpProvider, $animateProvider) {

	$httpProvider.useApplyAsync(true);

	//initialize get if not there
	if (!$httpProvider.defaults.headers.get) {
		$httpProvider.defaults.headers.get = {};
	}

	$animateProvider.classNameFilter(/^(?:(?!not_animate).)*$/);

	//disable IE ajax request caching
	$httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
	// extra
	$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
	$httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

	//Prevent pre-flight requests
	$httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';

	$httpProvider.interceptors.push('ApiInterceptor');

}]);

onePulse.factory('focus', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
	return function(name) {
		$timeout(function (){
			$rootScope.$broadcast('focusOn', name);
		});
	}
}]);
