/**
 * Created by mike on 13/12/15.
 */

onePulse.directive('user.referrals', [function() {

    linkFunction = function($scope, element, attributes){

    };

    return {
        templateUrl: '/app/views/elements/forms/user/referrals.html',
        link:linkFunction
    }
}]);
