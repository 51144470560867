/**
 * Created by barry on 13/02/2018.
 *
 */
onePulse.controller('UserPaymentsController', ['$scope',
	function ($scope) {

		//Initialise user payment methods
		var init = function() {

		};

		init();
}]);
