/**
 * Created by Fausto (from Barry PanelController) on 23/03/18.
 *
 */

onePulse.directive('channelSlideout', ['$rootScope', '$window', 'DataService', 'SearchService', '$timeout',
    'AlertService', 'FeatureService', 'CONSTANTS', '$translate', '$mdSidenav',
    function ($rootScope, $window, DataService, SearchService, $timeout,
              AlertService, FeatureService, CONSTANTS, $translate, $mdSidenav) {

        linkFunction = function ($scope, element, attributes) {

            $scope.user = DataService.grab('user');

            $scope.submitSpinner = false;
            $scope.currentCategory = null;

            $scope.loadingCustomData = true;

            $scope.allPulses = {};

            $scope.panelShow = 'public';
            $scope.unlocked = true;
            $scope.loaded = false;

            $scope.customChannelSet = [{
                "pulse"     : "",
                "question"  : "",
                "answer"    : [],
                "inc_exc"   : "",  /* has|has_not */
            }];

            $scope.search = {"search": "", "pulse": "", "question": "", "status": "", "type": "full_text"};

            $scope.ctrl = {
                "orderBy": "pulse.started_at",
                "orderDirection": "desc"
            };

            $scope.sort = {};
            $scope.sort.field = $scope.ctrl.orderBy;
            $scope.sort.order = $scope.ctrl.orderDirection;

            $scope.search.status = "Closed";

            //Determine if custom targeting feature is enabled
            $scope.targetingEnabled = FeatureService.allowed(CONSTANTS.FEATURES.CUSTOM_AUDIENCE, false);

            var filterGroups = function() {

                //If user is a master reseller or reseller client exclude all public groups except for the OP group
                if(($scope.user.data.master_reseller_id.value !== null && $scope.user.data.master_reseller_id.value !== '')
                    || $scope.user.data.is_master_reseller.value == '1') {

                    for (var i = $scope.groups.length - 1; i >= 0; i--) {

                        if ($scope.groups[i].option.is_public == '1' && $scope.groups[i].option.text !== 'OnePulse') {
                            $scope.groups.splice(i, 1);
                        }
                    }
                }
            };

            /*
            Set the initial scope asynchronously
            */
            $scope.init = function() {

                //Set initially selected group id
                $scope.selectedGroup = findSelectedOption($rootScope.currentPulse.data.group.options);

                //Set group options
                $scope.groups = $rootScope.currentPulse.data.group.options;

                filterGroups();

                SearchService.performSearch("es", "pulses", $scope.search, null, $scope.sort, false, true, true).then(function (result) {

                    $scope.tempAllPulses = angular.fromJson(result).pulses;

                    for(var i = 0; i < $scope.tempAllPulses.length; i++) {

                        $scope.allPulses[$scope.tempAllPulses[i].id.value] = {
                            "id"        : $scope.tempAllPulses[i].id.value,
                            "title"     : $scope.tempAllPulses[i].title.value,
                            "questions" : {}
                        };

                        for(var j = 0; j < $scope.tempAllPulses[i].questions.length; j++) {

                            $scope.allPulses[$scope.tempAllPulses[i].id.value].questions[$scope.tempAllPulses[i].questions[j].data.id.value] = {
                                "id"        : $scope.tempAllPulses[i].questions[j].data.id.value,
                                "text"      : $scope.tempAllPulses[i].questions[j].data.text.value,
                                "answers"   : {}
                            };

                            if(typeof $scope.tempAllPulses[i].questions[j].data.answers !== "undefined") {

                                for(var k = 0; k < $scope.tempAllPulses[i].questions[j].data.answers.length; k++) {

                                    if($scope.tempAllPulses[i].questions[j].data.answers[k].data.id.value !== "") {

                                        $scope.allPulses[$scope.tempAllPulses[i].id.value]
                                            .questions[$scope.tempAllPulses[i].questions[j].data.id.value]
                                            .answers[$scope.tempAllPulses[i].questions[j].data.answers[k].data.id.value] = {
                                            "id"  : $scope.tempAllPulses[i].questions[j].data.answers[k].data.id.value,
                                            "text": $scope.tempAllPulses[i].questions[j].data.answers[k].data.text.value
                                        }
                                    }
                                }
                            }
                        }
                    }

                    $scope.loadingCustomData = false;
                });
            };

            $scope.deregisterChannelsOpenHandler = $rootScope.$on("event:channels-open", function(event) {

                //Call initialisation
                $scope.init();
            });

            $scope.$on("$destroy", function() {

                //De-register all scope event handlers
                if (typeof $scope.deregisterChannelsOpenHandler !== "undefined") {

                    $scope.deregisterChannelsOpenHandler()
                }
            });

            //Select active group for this pulse and deactivate old group
            $scope.selectGroup = function(group) {

                if(typeof $scope.selectedGroup != "undefined") {

                    var oldGroupIndex = findOptionKeyById($rootScope.currentPulse.data.group.options, $scope.selectedGroup.option.id);

                    //Deselect currently selected group
                    $rootScope.currentPulse.data.group.options[oldGroupIndex].option.selected = false;
                }

                var newGroupIndex = findOptionKeyById($rootScope.currentPulse.data.group.options, group.option.id);

                $rootScope.currentPulse.data.group.options[newGroupIndex].option.selected = true;

                $scope.selectedGroup = findSelectedOption($rootScope.currentPulse.data.group.options);
            };

            var processRetargetData = function() {

                var retargetData = [];

                for(var i = 0; i < $scope.customChannelSet.length; i++) {

                    if($scope.customChannelSet[i].pulse !== "") {

                        retargetData.push({
                            "field": "pulses",
                            "inc_exc": $scope.customChannelSet[i].inc_exc,
                            "values": [$scope.customChannelSet[i].pulse]
                        })
                    }

                    if($scope.customChannelSet[i].question !== "") {

                        retargetData.push({
                            "field": "questions",
                            "inc_exc": $scope.customChannelSet[i].inc_exc,
                            "values": [$scope.customChannelSet[i].question]
                        })
                    }

                    if($scope.customChannelSet[i].answer.length > 0 && $scope.customChannelSet[i].answer[0] !== "") {

                        retargetData.push({
                            "field": "answers",
                            "inc_exc": $scope.customChannelSet[i].inc_exc,
                            "values": $scope.customChannelSet[i].answer
                        })
                    }
                }

                return retargetData;
            };

            //Update the pulse with the group selection
            $scope.submitCallback = function($event) {

                var submit = function() {

                    $mdSidenav('right-channel').toggle();

                    $scope.$broadcast('event:pulse-saving');

                    $rootScope.fadedOverlay = true;
                    $rootScope.loadingStateText = "Saving Pulse";

                    DataService.submit('pulse', $rootScope.currentPulse).then(function (result) {

                        $rootScope.fadedOverlay = false;
                        $rootScope.loadingStateText = "";

                        var translations;
                        $translate(['alert.pulse_saved.title', 'alert.pulse_saved.text']).then(function(result) {
                            translations = result;

                            AlertService.success(
                                translations['alert.pulse_saved.title'],
                                translations['alert.pulse_saved.text']
                            );
                        });

                        $rootScope.$broadcast('event:group-updated', $scope.selectedGroup);
                        $rootScope.$broadcast('event:pulse-update', result.pulse);
                        $scope.$broadcast('event:pulse-saved');
                    });
                };

                if($scope.panelShow === 'custom') {

                    var retargetData = processRetargetData();

                    DataService.retarget(retargetData, $rootScope.currentPulse.data_token).then(function (result) {

                        $timeout(function() {

                            $window.location.reload();
                        })
                    });
                } else {

                    submit();
                }
            };

            $scope.addCustomGroup = function() {

                $scope.customChannelSet.push({
                    "pulse"     : "",
                    "question"  : "",
                    "answer"    : [],
                    "inc_exc"   : "",  /* has|has_not */
                })
            };

            $scope.removeCustomGroup = function(index) {

                $scope.customChannelSet.splice(index, 1);
            };

            $scope.backCallback = function ($event) {

                $window.history.back();
                $event.preventDefault();
            };

            //Close the Panel Editor Modal
            $scope.closePanelEditor = function() {

                $mdSidenav('right-channel').toggle();
            };

            //Toggle between public and private groups
            $scope.setPanelTab = function(type) {

                $scope.panelShow = type;
                $scope.unlocked = $scope.allowType(false);
            };

            //Check if user feature rights allow access to private groups
            $scope.allowType = function(showModal) {

                if($scope.panelShow == 'private') {

                    return FeatureService.allowed(CONSTANTS.FEATURES.PRIVATE_COMMUNITIES, showModal);
                }

                return true;
            };
        };

        return {
            restrict: 'E',
            priority: 0,
            replace: false,
            scope: true,
            link: linkFunction,
            templateUrl: '/app/views/elements/pulse/channelSlideout.html'
        }

    }]);
