/**
 * Created by mike on 10/12/15.
 */
onePulse.controller('HeaderController', ['$scope', '$rootScope', 'DataService', 'AuthService', 'Config', '$location',
    function ($scope, $rootScope, DataService, AuthService, Config, $location)
{
    $scope.user = DataService.grab('user');

    $scope.isLoggedIn = AuthService.isLoggedIn();

    $scope.$on('event:auth-login', function() {
        $scope.user = DataService.grab('user');
        $scope.isLoggedIn=true;
    });

    $scope.$on('event:auth-logout', function() {
        $scope.isLoggedIn=false;
    });

    $scope.isActive = function (viewLocation) {
  		var active = (viewLocation === $location.path());
  		return active;
  	};
}]);
