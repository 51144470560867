/**
 * Created by barry on 09/10/2017.
 *
 */
onePulse.controller('UserPurchasesController', ['$scope', 'DataService', 'FileSaver', '$location',
	function ($scope, DataService, FileSaver, $location) {

		//Grab current user
		$scope.user = DataService.grab('user');

		//Client user transaction history, max viewable and view more toggle control
		$scope.transactions = [];
		$scope.maxTransactionsViewable = 3;
		$scope.viewAllTransactions = false;

		$scope.showTransactions = function(type) {

			switch(type) {

				case 'show':
					$scope.maxTransactionsViewable = $scope.transactions.length;
					$scope.viewAllTransactions = true;
					break;
				case 'hide':
					$scope.maxTransactionsViewable = 3;
					$scope.viewAllTransactions = false;
					break;
			}
		};

		$scope.downloadReceipt = function(transaction) {

			DataService.getClientInvoice(transaction.data_token).then(function(result) {

				var blob = new Blob([result], { type: 'application/octet-stream' });
				var filename = $scope.user.data.brand_name.value + '.' + formatDateNoTimeZone(new Date(), new Date());

				FileSaver.saveAs(blob, filename + '.pdf');
			});
		};

		$scope.formatDate = function(dateString) {

			var dateObj = new Date(dateString);

			return prettyDate(dateObj, false, true);
		};

		$scope.openPulse = function(pulseId) {

			$location.path('/pulse/' + pulseId + '/edit');
		};

		//Initialise user management
		var init = function() {

			DataService.profileClient('transactions', null, '').then(function(result) {

				//Need to grab purchased pulses so they are available in local storage in order to open from the transaction list
				DataService.fetch('pulses/catalogue').then(function () {

					$scope.transactions = result.transactions;

					for(var i = 0; i < $scope.transactions.length; i++) {

						if(typeof $scope.transactions[i].data.payment_instrument_type != "undefined") {

							if($scope.transactions[i].data.payment_instrument_type.value == 'credit_card') {

								$scope.transactions[i].data.payment_instrument_type.value = 'bank_card';
							}
						} else {

                            $scope.transactions[i].data.payment_instrument_type = {"value": "invoiced"}
						}
					}
				});
			})
		};

		init();
}]);
