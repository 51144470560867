onePulse.controller('StoreSubscriptionsController', ['$scope', '$rootScope', 'DataService', '$mdDialog', '$translate', 'AlertService',
    'TokenService', 'FeatureService',
    function ($scope, $rootScope, DataService, $mdDialog, $translate, AlertService,
    TokenService, FeatureService) {

    $scope.user = DataService.grab("user");

    $scope.canUpgrade = function(subscription) {

        if(typeof subscription != "undefined") {

            return parseInt($scope.user.data.current_subscription.data.display_price.value) < parseInt(subscription.data.display_price.value);
        }
    };

    $scope.upgrade = function(subscription) {

        $rootScope.descriptionSubscription = subscription.data.description.value;

        $scope.popupChangePlanPaymentDialog();
    };

    //Modal for subscription payment
    $scope.popupChangePlanPaymentDialog = function() {

        $rootScope.modalOpen = true;

        $mdDialog.show({
            controller: 'UserController',
            templateUrl: 'app/views/elements/includes/plan-subscription-payment.html',
            clickOutsideToClose: false
        });

        DataService.fetchBrainTreeClientToken().then(function(result) {

            var brainTreeClientToken = result.token;

            initiateBrainTree(brainTreeClientToken, '.subs-form', $scope.errorCallback, $scope.successCallbackSubs);
        });
    };

    //Error callback for Braintree
    $scope.errorCallback = function() {

        var translations;
        $translate(['alert.card_details_error.title', 'alert.card_details_error.text']).then(function(result) {
            translations = result;

            AlertService.error(
                translations['alert.card_details_error.title'],
                translations['alert.card_details_error.text']
            );
        });
    };

    //Success callback for Braintree - Subscription payment
    $scope.successCallbackSubs = function(payload, deviceData) {

        $scope.submitSpinner = true;

        var data_token = $('#selectedDataToken').val();
        var description = $('#selectedDescription').val();

        // Submit payload.nonce and other data
        var data = {
            "nonce": payload.nonce,
            "device_data": deviceData,
            "data_token": data_token
        };

        DataService.profileClient("subscription", data, "/purchase", function() {

            $scope.submitSpinner = false;

            //Hide loading spinner and show confirm button text
            $('.button-text').css('display', 'block');
            $('.button-spinner').css('display', 'none');

            //Enable submit button
            $('#submit').prop("disabled",false);
        }).then(function(result) {

            //Set the new access token and reinitialize the feature set
            TokenService.setAccessToken(result.access_token);

            DataService.fetchMetaData("products/features").then(function(result) {

                FeatureService.initFeatures(result);

                DataService.fetchItem('user').then(function (result) {

                    $scope.submitSpinner = false;
                    $scope.user = result.user;

                    //Store subscription object for use in next modal
                    $rootScope.descriptionSubscription = description;

                    $rootScope.modalOpen = true;

                    $mdDialog.show({
                        controller: 'UserController',
                        templateUrl: 'app/views/elements/includes/plan-subscription-complete.html',
                        clickOutsideToClose: false
                    });
                });
            });
        });
    };

    var init = function() {

        DataService.fetchMetaData("products/features").then(function(result) {

            var featuresTemp = [];
            var featuresKeys = [];

            for(var key in result) {

                if(typeof result[key].is_active != "undefined") {

                    result[key].key.value = removeCharsAfterChar(':', result[key].key.value);

                    if(result[key].is_active.value == '1' && featuresKeys.indexOf(result[key].key.value) == -1) {

                        result[key].display_order.value = parseInt(result[key].display_order.value);
                        result[key].description.value = removeCharsAfterChar(':', result[key].description.value);

                        featuresKeys.push(result[key].key.value);
                        featuresTemp.push(result[key])
                    }
                }
            }

            var featuresTempOrdered = featuresTemp.slice(0);

            featuresTempOrdered.sort(function(a,b) {

                return a.display_order.value - b.display_order.value;
            });

            $scope.features = featuresTempOrdered;

            DataService.fetchMetaData("subscriptions").then(function(result) {

                for(var i = 0; i < result.subscriptions.length; i++) {

                    if(result.subscriptions[i].data.cost.value != "") {

                        result.subscriptions[i].data.cost.value = thousandSeparator([result.subscriptions[i].data.cost.value], true);
                    }

                    var featuresTemp = [];
                    var featuresKeys = [];

                    for(var key in result.subscriptions[i].data.features) {

                        if(typeof result.subscriptions[i].data.features[key].is_active != "undefined") {

                            if(result.subscriptions[i].data.features[key].is_active.value == '1' && featuresKeys.indexOf(result.subscriptions[i].data.features[key].key.value) == -1) {

                                //Check description for meta data
                                if(result.subscriptions[i].data.features[key].description.value.indexOf(':') != -1) {

                                    result.subscriptions[i].data.features[key].featureDetail = {"value": result.subscriptions[i].data.features[key].description.value.split(':')[1].trim()};
                                }

                                //Check key for meta data - key takes precedence over description
                                if(result.subscriptions[i].data.features[key].key.value.indexOf(':') != -1) {

                                    result.subscriptions[i].data.features[key].featureDetail = {"value": result.subscriptions[i].data.features[key].key.value.split(':')[1]};
                                }

                                result.subscriptions[i].data.features[key].blank = {"value": false};

                                result.subscriptions[i].data.features[key].key.value = removeCharsAfterChar(':', result.subscriptions[i].data.features[key].key.value);
                                result.subscriptions[i].data.features[key].display_order.value = parseInt(result.subscriptions[i].data.features[key].display_order.value);
                                result.subscriptions[i].data.features[key].description.value = removeCharsAfterChar(':', result.subscriptions[i].data.features[key].description.value);

                                featuresKeys.push(result.subscriptions[i].data.features[key].key.value);
                                featuresTemp.push(result.subscriptions[i].data.features[key])
                            }
                        }
                    }

                    var featuresTempOrdered = featuresTemp.slice(0);

                    featuresTempOrdered.sort(function(a,b) {

                        return a.display_order.value - b.display_order.value;
                    });

                    $scope.tempFeaturesSub = featuresTempOrdered;

                    for(var k = 0; k < $scope.features.length; k++) {

                        var existsInSubFeatures = false;
                        var item = {"blank": {"value": true}};

                        for(var l = 0; l < $scope.tempFeaturesSub.length; l++) {

                            if(typeof $scope.tempFeaturesSub[l].key != "undefined" && typeof $scope.features[k].key != "undefined") {

                                if($scope.tempFeaturesSub[l].key.value == $scope.features[k].key.value) {

                                    existsInSubFeatures = true;
                                }
                            }
                        }

                        if(!existsInSubFeatures) {

                            $scope.tempFeaturesSub.splice(k, 0, item);
                        }
                    }

                    result.subscriptions[i].data.features = $scope.tempFeaturesSub
                }

                $scope.subscriptions = result.subscriptions;
            });
        });
    };

    init();
}]);
