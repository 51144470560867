/**
 * Created by Barry on 23/08/2018.
 *
 * Directive for displaying the White Label client Register form
 */
onePulse.directive('whitelabelRegister', ['$rootScope', function($rootScope) {

        linkFunction = function($scope, element, attributes) {

        };

        return {
            restrict: 'E',
            priority: 0,
            replace: false,
            scope: false,
            link:linkFunction,
            templateUrl:'/app/views/elements/registers/whitelabelRegister.html'
        }
    }]);
