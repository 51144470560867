/**
 * Created by Barry on 2018/05/25.
 *
 * Pulse editor
 */

onePulse.directive('pulseEditor', ['$timeout',
    function ($timeout) {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: false,
        templateUrl:'/app/views/elements/pulse/pulseEditor.html',

        link: function postLink(scope, element, iAttrs) {

            //If a dragged element is dropped we need to re-sequence the questions
            scope.$on('bag-two.drop-model', function (e, el, target, source) {

                $timeout(function() {

                    for (var i = 0; i < scope.pulse.data.questions.length; i++) {

                        scope.pulse.data.questions[i].data.sequence.value = i + 1;
                    }
                });
            });
        }
    };
}]);
