var vigaTheme = {
    customPrimary: {
        '50': '#fef0c0',
        '100': '#feeaa7',
        '200': '#fee58e',
        '300': '#fedf74',
        '400': '#fdd95b',
        '500': '#fdd342',
        '600': '#fdcd29',
        '700': '#fcc70f',
        '800': '#f0bb03',
        '900': '#d7a702',
        'A100': '#fff6d9',
        'A200': '#fffcf3',
        'A400': '#ffffff',
        'A700': '#bd9302',
        'contrastDefaultColor': 'light',
        'contrastStrongLightColors': ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700']
    },
    customAccent: {
        '50': '#0241a4',
        '100': '#024bbd',
        '200': '#0255d7',
        '300': '#035ff0',
        '400': '#0f6cfc',
        '500': '#297bfd',
        '600': '#5b9afd',
        '700': '#74aafe',
        '800': '#8eb9fe',
        '900': '#a7c9fe',
        'A100': '#5b9afd',
        'A200': '#428bfd',
        'A400': '#297bfd',
        // chnaged in order to get the right hover color
        // 'A700': '#c0d8fe', 
        'A700': '#0f6cfc',
        'contrastDefaultColor': 'light',
        'contrastStrongLightColors': ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700']
    },
    customWarn: {
        '50': '#ffb280',
        '100': '#ffa266',
        '200': '#ff934d',
        '300': '#ff8333',
        '400': '#ff741a',
        '500': '#ff6400',
        '600': '#e65a00',
        '700': '#cc5000',
        '800': '#b34600',
        '900': '#993c00',
        'A100': '#ffc199',
        'A200': '#ffd1b3',
        'A400': '#ffe0cc',
        'A700': '#803200',
        'contrastDefaultColor': 'light',
        'contrastStrongLightColors': ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700']
    }
};