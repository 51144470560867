/**
 * Created by Barry on 2018/10/29.
 *
 */

onePulse.directive('upsellSubsDash', ['DataService', '$window', '$rootScope',
    function (DataService, $window, $rootScope) {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/dashboard/upsellSubs.html',

            link: function postLink(scope, element, iAttrs) {

                DataService.fetchMetaData("subscriptions").then(function(result) {

                    result.subscriptions.sort(function(a,b) {

                        return a.data.display_order.value - b.data.display_order.value;
                    });

                    scope.subscriptions = result.subscriptions;

                    $rootScope.showStepLoadingSpinner = false;
                });

                scope.canUpgrade = function(subscription) {

                    if(typeof subscription !== "undefined") {

                        return (scope.user.data.current_subscription.data.display_price.value != "" ? (parseFloat(scope.user.data.current_subscription.data.display_price.value) < parseFloat(subscription.data.display_price.value) && parseFloat(subscription.data.display_price.value) > 0.00) : true);
                    }
                };

                scope.sendSubContactMail = function() {

                    $window.open("mailto:info@onepulse.com","_self");
                };
            }
        };
    }]);