var mdTheme = {
    customPrimary: {
        '50': '#b199dd',
        '100': '#a386d6',
        '200': '#9473d0',
        '300': '#8660c9',
        '400': '#774dc3',
        '500': '#6A3EB8',
        '600': '#5f38a5',
        '700': '#543192',
        '800': '#492b7f',
        '900': '#3e246c',
        'A100': '#c0ace3',
        'A200': '#cebfe9',
        'A400': '#ddd2f0',
        'A700': '#331e59',
        'contrastDefaultColor': 'light',
        'contrastStrongLightColors': ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700']
    },
    customAccent: {
        '50': '#146158',
        '100': '#18776b',
        '200': '#1c8c7e',
        '300': '#20a191',
        '400': '#25b6a4',
        '500': '#29ccb7',
        '600': '#4cdbc9',
        '700': '#62dfd0',
        '800': '#77e4d6',
        '900': '#8ce8dc',
        'A100': '#4cdbc9',
        'A200': '#37D7C3',
        'A400': '#29ccb7',
        // chnaged in order to get the right hover color
        // 'A700': '#a1ece3', 
        'A700': '#25b6a4',
        'contrastDefaultColor': 'light',
        'contrastStrongLightColors': ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700']
    },
    customWarn: {
        '50': '#925b00',
        '100': '#ab6a00',
        '200': '#c57a00',
        '300': '#de8a00',
        '400': '#f89a00',
        '500': '#ffa512',
        '600': '#ffb945',
        '700': '#ffc25f',
        '800': '#ffcc78',
        '900': '#ffd692',
        'A100': '#ffb945',
        'A200': '#FFAF2C',
        'A400': '#ffa512',
        'A700': '#ffdfab',
        'contrastDefaultColor': 'light',
        'contrastStrongLightColors': ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700']
    }
};