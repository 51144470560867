onePulse.controller('BillingController', ['$scope', '$mdDialog', 'DataService', '$rootScope', '$window', '$translate', 'AlertService', '$location', '$timeout',
    function($scope, $mdDialog, DataService, $rootScope, $window, $translate, AlertService, $location, $timeout) {

    $scope.user = DataService.grab('user');
    $scope.topups = DataService.grab('topups');

    //Default top-up
    $scope.selectedTopup = 'autoBill';
    $rootScope.descriptionTopup = $scope.selectedTopup;

    $scope.processCost = function(obj) {

        for(var i = 0; i < obj.length; i++) {

            if(obj[i].data.cost.value != "") {

                obj[i].data.cost.value = thousandSeparator([obj[i].data.cost.value], true);
            }
        }
    };

    if(!$scope.topups) {

        DataService.fetchMetaData("topups").then(function(result) {

            $scope.topups = result.topups;
            $scope.processCost($scope.topups);
        });
    } else {

        $scope.processCost($scope.topups);
    }

    $scope.setActive = function(value) {

        $scope.selectedTopup = value;
        $rootScope.descriptionTopup = $scope.selectedTopup;
    };

    //Modal for Topup payment
    $scope.popupTopupDialog = function(ev) {

        $mdDialog.hide();

        $rootScope.modalOpen = true;

        $mdDialog.show({
            controller: 'UserController',
            templateUrl: 'app/views/elements/includes/plan-topup.html',
            clickOutsideToClose: false
        });

        DataService.fetchBrainTreeClientToken().then(function(result) {

            var brainTreeClientToken = result.token;

            initiateBrainTree(brainTreeClientToken, '.topup-form', $scope.errorCallback, $scope.successCallbackTopup);
        });
    };

    //Error callback for Braintree
    $scope.errorCallback = function() {

        var translations;
        $translate(['alert.card_details_error.title', 'alert.card_details_error.text']).then(function(result) {
            translations = result;

            AlertService.error(
                translations['alert.card_details_error.title'],
                translations['alert.card_details_error.text']
            );
        });
    };

    //Success callback for Braintree - Topup payment
    $scope.successCallbackTopup = function(payload, deviceData) {

        if($scope.selectedTopup == 'autoBill') {

            //Register client's banking details - set as preferred if selected
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "is_default": true
            };

            DataService.paymentMethod('create', data).then(function(result) {

                $rootScope.isDefault = true;

                //Set user is_autobilled to true and update user
                $scope.user.data.is_autobilled.value = "1";

                DataService.submit('user', $scope.user).then(function (result) {

                    $scope.user = result.user;

                    //Only pre-publish if we are not topping up from user/plans
                    if($location.path() != '/user/plans') {

                        $rootScope.modalOpen = false;
                        $mdDialog.hide();
                        $timeout(function() {
                            angular.element('#schedulePulse').triggerHandler('click');
                        }, 0)
                    } else {

                        $rootScope.currentLockedFeature = null;
                        $rootScope.modalOpen = false;
                        $mdDialog.hide();
                        $window.location = 'user/plans';
                    }
                });
            });

        } else {

            var data_token = $('#selectedDataToken').val();

            // Submit payload.nonce and other data
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "data_token": data_token,
                "is_default": true
            };

            DataService.profileClient("topups", data, "/purchase", function() {

                $scope.submitSpinner = false;
            }).then(function (result) {

                DataService.fetchItem('user').then(function (result) {

                    $scope.submitSpinner = false;
                    $scope.user = result.user;

                    //Only pre-publish if we are not topping up from user/plans
                    if ($location.path() != '/user/plans') {

                        $rootScope.modalOpen = false;
                        $mdDialog.hide();
                        $timeout(function () {
                            angular.element('#schedulePulse').triggerHandler('click');
                        }, 0);
                    } else {

                        $rootScope.currentLockedFeature = null;
                        $rootScope.modalOpen = false;
                        $mdDialog.hide();
                        $window.location = 'user/plans';
                    }
                });
            });
        }
    };

    $scope.closeDialog = function(){

        $mdDialog.hide();
    };

}]);
