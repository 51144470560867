
onePulse.directive('imageonload', ['$rootScope', '$window', '$timeout', function($rootScope, $window, $timeout) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {

            element.bind('load', function() {

                //call the function that was passed
                $rootScope.imageSpinner = false;

                scope.$apply();

                $timeout(function() {

                    $window.dispatchEvent(new Event("resize"));
                }, 500);
            });
        }
    };
}]);
