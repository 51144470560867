/**
 * Created by mike on 08/12/15.
 */
// register the interceptor as a service
onePulse.factory('ApiInterceptor', ['$q', '$window', '$location', '$rootScope', '$timeout', '$injector', 'TokenService',
	'AuthService', 'CONSTANTS', 'Config', '$translate', 'LZString',
	function ($q, $window, $location, $rootScope, $timeout, $injector, TokenService,
			  AuthService, CONSTANTS, Config, $translate, LZString) {

	return {
		'request': function (config) {

			//only check calls to the api
			if (config.url.search('/api/') > -1 && Config.get('OP_VERSION') !== 'test') {

				//retrieve the target endpoint
				var endpoint = parseEndPoint(config.url);

				var permissions = $injector.get('AuthService').permissions();
				var allowed = false;

				//Check that the requested endpoint is allowed for this user
				for (var i = 0; i < permissions.role.length; i++) {

					if (endpoint.indexOf(permissions.role[i].replace('*', '')) != -1) {

						allowed = true;
					}
				}

				//var role = $injector.get('AuthService').role();
                //
				////Check if role is client:new (Client has not completed registration)
				//if(role == CONSTANTS.ROLES.CLIENT_NEW && AuthService.isLoggedIn() && !allowed) {
                //
				//	//Redirect to 2nd phase registration
				//	$window.location = "/register-complete"
                //
				//}

				$rootScope.$broadcast('event:api-request');
				$rootScope.$broadcast('event:api-request-' + endpoint);

				//now we need the token in the request..
				//grab auth header, get rid of Bearer from the string, split on "." and get the part at index:1, decode it and parse it into an object using fromJson
				if(typeof config.headers.Authorization !== "undefined") {

                    var token = config.headers.Authorization.replace("Bearer ", "");
                    var token_array = token.split('.');
                    var token_payload = angular.fromJson(decodeBase64(token_array[1]));

                    if($injector.get('AuthService').isLoggedIn() && config.url.search('/logout') === -1) {

                        //We check the auth token in the request for expiration state
                        if (TokenService.isTokenExpired(token)) {

                            $injector.get('LogoutService').logout();

                            var translations;
                            $translate(['alert.session_expired.title', 'alert.session_expired.text']).then(function(result) {
                                translations = result;

                                $injector.get('AlertService').info(
                                    translations['alert.session_expired.title'],
                                    translations['alert.session_expired.text']
                                );
                            });

                            return false;
                        }

                        //If the request payload includes a data token, we check it for expiration state
                        if(typeof config.data !== "undefined") {

                            if(config.data !== null) {

                                if (typeof config.data.data_token !== "undefined") {

                                    if (TokenService.isTokenExpired(config.data.data_token)) {

                                        $injector.get('LogoutService').logout();

                                        var translations;
                                        $translate(['alert.session_expired.title', 'alert.session_expired.text']).then(function(result) {
                                            translations = result;

                                            $injector.get('AlertService').info(
                                                translations['alert.session_expired.title'],
                                                translations['alert.session_expired.text']
                                            );
                                        });

                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }

				if (allowed || $location.path() == "/logout" || endpoint.indexOf('https://data.fixer.io/api')) {

					return config;
				} else {

					//$injector.get('LogoutService').logout();
                    //
					//var translations;
					//$translate(['alert.incorrect_role_permissions.title', 'alert.incorrect_role_permissions.text']).then(function(result) {
					//	translations = result;
                    //
					//	$injector.get('AlertService').info(
					//		translations['alert.incorrect_role_permissions.title'],
					//		translations['alert.incorrect_role_permissions.text']
					//	);
					//});

					return false;
				}
			}

			return config;
		},
		// optional method
		'requestError': function (rejection) {

			// console.log(rejection);
			//
			// if (rejection.status === 0) {
			// 	return $timeout(function() {
			// 		var $http = $injector.get('$http');
			// 		return $http(rejection.config);
			// 	}, 15000);
			// }

			return $q.reject(rejection);
		},
		'response': function (response, $scope) {

			var endpoint = '';

			//only check calls to the api
			if (response.config.url.search('/api/') != -1 && Config.get('OP_VERSION') !== 'test') {

				var pulsePagination = 1;
				var endpoint = parseEndPoint(response.config.url);
				var endpointArray = response.config.url.split('/');

				//Need pagination first - cannot include pulses
				if (response.config.url.search('/pulses') == -1 &&
					response.config.url.search('/catalogue') == -1 &&
                    response.config.url.search('/white_label') == -1) {

					//loop through the response data.
					angular.forEach(response.data, function (value, key) {

						//if already is an item here
						if (key == 'access_token') {

							$window.localStorage['access_token'] = value;
						} else if (key != 'status_code' && key != 'embed_url') {

							if(key == 'translation'
								|| (endpoint == 'meta' && key == 'subscriptions')
								|| (endpoint == 'meta' && key == 'topups')
								|| (endpoint == 'meta' && key == 'products')
                                || (endpoint == 'meta' && endpointArray[5] == 'white_label')){

								$window.localStorage[key] = LZString.compressToUTF16(JSON.stringify(value));
							}
							else if ( !endsWith(endpoint, 's') ) {

								if (response.config.url.search('/question/') == -1 &&
									response.config.url.search('/answer/') == -1 &&
									response.config.url.search('/ably/') == -1 &&
									response.config.url.search('/lite/') == -1 &&
									response.config.url.search('/sharing/') == -1 &&
									response.config.url.search('/inbox') == -1
								) {

									//requires an id to perform the merge
									if (typeof value.data !== "undefined") {
										if (typeof value.data.id !== "undefined") {

											if(endpoint != 'panel') {

												//then it is a singluar item and needs to be add/replaced under the list
												var id = value.data.id.value;

												//search the existing list for this id
												var newKey = endpoint + 's';
												if($window.localStorage[newKey]===undefined){
                                                    var list = [];
												}else {
                                                    var list = angular.fromJson(LZString.decompressFromUTF16($window.localStorage[newKey]));
                                                }
												var index = findKeyById(list, id);

												//replace old with the new
												if (index != undefined) {
													list[index] = value;
												} else {
													list.push(value)
												}
												//replace the list in local storage with updated version
												$window.localStorage[newKey] = LZString.compressToUTF16(JSON.stringify(list))
											}
										} else {
											//cant merge so just store it
											$window.localStorage[key] = LZString.compressToUTF16(JSON.stringify(value))
										}
									}
								} else {

									//If repulsing - add result to pulses list
									if((response.config.url.search('/repulse') != -1)) {

										var list = angular.fromJson(LZString.decompressFromUTF16($window.localStorage['pulses']));

										list.push(value);

										$window.localStorage['pulses'] = LZString.compressToUTF16(JSON.stringify(list))
									}

									//TODO; pick up questions and answers and ignore them/ push them into the right pulse record..

									//special case to handle questions and answer records
								}
							} else {

								if(key != 'debug') {

									//its a list just store it
									$window.localStorage[key] = LZString.compressToUTF16(JSON.stringify(value))
								}
							}
						}

					});

					if (response.config.url.search('/close') != -1) {

						//We are referring to Groups as Panels in the UI
						if(endpoint == 'group') {
							endpoint = 'panel';
						}

						$injector.get('AlertService').success(
							"Close Successful",
							uppercaseFirst(endpoint) + ' Closed Successfully'
						);
					}

					//Do not display 'Publish Successful' message for clients, groups or pulse lites
					if (response.config.url.search('/publish') != -1 &&
						response.config.url.search('/client') == -1 &&
						response.config.url.search('/group') == -1 &&
						response.config.url.search('/lite') == -1) {

						$injector.get('AlertService').success(
							"Schedule Successful",
							uppercaseFirst(endpoint) + ' Scheduled Successfully'
						);
					}

				} else {

					//Handle local storage inserts for pulse types
					if(endpointArray.indexOf('pulse') == -1 &&
                        endpointArray.indexOf('purchase') == -1 &&
                        endpointArray.indexOf('white_label') == -1) {

						var arrayKey = '';
						var property = '';

						//Pulse list and search
                        if($location.path() == "/pulses" || $location.path().split('/')[1] == 'dashboard') {

                            if(endpointArray[4] == 'pulses' && endpointArray[5] == "catalogue" && endpointArray[6] != "query") {

                                arrayKey = 'purchasedPulses';
                                property = 'purchased_pulses';
                            } else {

                                arrayKey = 'pulses';
                                property = 'pulses';
                            }
                        } else if(($location.path() == "/store/pulses" || $location.path() == "/store")){

                            if(endpointArray[4] == 'pulses' && endpointArray[5] == "catalogue" && endpointArray[6] != "query") {

                                arrayKey = 'purchasedPulses';
                                property = 'purchased_pulses';
                            } else {

                                arrayKey = 'cataloguePulses';
                                property = 'pulses';
                            }
						}

						//Grab current page of pulse list
						pulsePagination = response.data.pagination.current_page;

                        if(arrayKey != '' && property != '') {

                            //If there is more than 1 page, we need to append the list
                            if(pulsePagination > 1) {

                                var list = angular.fromJson(LZString.decompressFromUTF16($window.localStorage[arrayKey]));

                                list = list.concat(response.data[property]);

                                $window.localStorage[arrayKey] = LZString.compressToUTF16(JSON.stringify(list));

								$rootScope.$broadcast('event:pulses-localstorage-update');

                            } else {

                                $window.localStorage[arrayKey] = LZString.compressToUTF16(JSON.stringify(response.data[property]));

								$rootScope.$broadcast('event:pulses-localstorage-update');
                            }
                        }
					}
				}
			}

			return response;
		},
		'responseError': function (rejection) {

			var translations;
			var $mdDialog = $injector.get('$mdDialog');

			//Reset render state and text
			$rootScope.renderComplete = true;
			$rootScope.fadedOverlay = false;
			$rootScope.loadingStateText = "";

			switch(rejection.status) {

				case 401: //401: Expired token
					break;
				case 403: //403: Logout

					$injector.get('LogoutService').logout();

					//Prevent notif overload
					if($rootScope.notifications.length === 0) {

						$translate(['alert.session_expired.title', 'alert.session_expired.text']).then(function(result) {
							translations = result;

							$injector.get('AlertService').info(
								translations['alert.session_expired.title'],
								translations['alert.session_expired.text']
							);
						});
					}

					break;
				case 406: //406: User does not exist (Preview Pulse)

					if (typeof rejection.config !== "undefined" && typeof rejection.data !== "undefined") {

						$injector.get('ErrorService').removeAll(
							rejection.config.url
						);
					}

					if(typeof rejection.data.message !== "undefined") {

                        $translate(['alert.generic_error.title', 'alert.generic_error.text']).then(function(result) {

                            translations = result;
                            $injector.get('AlertService').error(
                                translations['alert.generic_error.title'],
                                rejection.data.message
                            );
                        });
                    }

					break;
				case 409: //409: User already logged in

					if(!$injector.get('AuthService').isLoggedIn()) {

                        //Controller for multiple login warning modal
                        function multipleLoginWarningModalController($scope, $rootScope, $mdDialog, $injector) {

                            $scope.forceLogin = function() {

                                $mdDialog.hide();
                                $rootScope.fadedOverlay = true;
                                $rootScope.loadingStateText = "Forcing Login";

                                var $data = {
                                    "username": $rootScope.login.username,
                                    "password": $rootScope.login.password,
                                    "is_forced": true
                                };

                                $injector.get('LoginService').login($data, false, function() {

                                    $scope.submitSpinner = false;
                                    $rootScope.fadedOverlay = false;
                                    $rootScope.loadingStateText = "";
                                }).then(function () {

                                    $scope.isLoggedIn = true;

                                    $window.location = '/dashboard';
                                });
                            };

                            $scope.closeDialog = function () {

                                $mdDialog.hide();
                            };
                        }

                        multipleLoginWarningModalController.$inject = ['$scope', '$rootScope', '$mdDialog', '$injector'];

                        $mdDialog.show({
                            templateUrl: 'app/views/elements/includes/multiple-login-alert.html',
                            controller: multipleLoginWarningModalController,
                            parent: angular.element(document.body),
                            clickOutsideToClose: true,
                            ariaLabel: 'Alert',
                            fullscreen: false
                        });
                    } else {

                        $injector.get('LogoutService').logout();
                    }

					break;
				case 417: //417: Live edit Pulse status error

					$rootScope.$broadcast('event:417-pulse-update');
					break;
				case 422: //422: Unprocessable Entity

					$timeout(function () {

                        if(typeof rejection.data.errors !== "undefined") {

                            angular.forEach(rejection.data.errors, function(value, key){

                                $injector.get('AlertService').error('Whoops!', value[0])

                            });
                        } else if(typeof rejection.data.message !== "undefined") {

                            $injector.get('AlertService').error('Whoops!', rejection.data.message)
                        } else {

                            $translate(['alert.generic_error.title', 'alert.generic_error.text']).then(function(result) {
                                translations = result;

                                $injector.get('AlertService').error(
                                    translations['alert.generic_error.title'],
                                    translations['alert.generic_error.text']
                                );
                            });
                        }
					}, 20);

					if ($location.path() != "/" && $location.path() != "/channels") {

						$mdDialog.hide();
					}
					break;
				case 503: //503: Maintenance

					$mdDialog.show({
						templateUrl: 'app/views/elements/includes/maintenance.html',
						clickOutsideToClose: false,
						ariaLabel: 'Alert',
						fullscreen: true
					});
					break;
				case 591: //591: Braintree Payment error

					$translate(['alert.payment_error.title', 'alert.payment_error.text']).then(function(result) {
						translations = result;

						$injector.get('AlertService').info(
							translations['alert.payment_error.title'],
							translations['alert.payment_error.text'] + ": " + rejection.data.message
						);
					});
					break;
				default:

					if (typeof rejection.data != 'undefined') {

						//if the data token is not found check the access token probably been logged out..
						if (rejection.data.message == 'Data Token Not Found: user:user:data') {
							//Fix for https://onepulse.atlassian.net/browse/OPW20-91 goes here
						}

						$translate(['alert.generic_error.title']).then(function(result) {
							translations = result;

							var errorMessage = translations['alert.generic_error.text'];

							//Set error message depending on env
							switch($rootScope.env) {
								case "DEV":
								case "BETA":
								case "FEATURE":
								case "RELEASE":

									errorMessage = rejection.data.message;
									break;
								case "DZ":
								case "UK":
								case "US":
								default:
									errorMessage = rejection.data.message;
									//errorMessage = translations['alert.generic_error.text'];
									break
							}

							if(errorMessage == 'Incorrect role permissions.' && AuthService.isLoggedIn()) {

								$injector.get('LogoutService').logout();

								$injector.get('AlertService').error(
									translations['alert.generic_error.title'],
									errorMessage
								);
							}
						});
					} else {

						$translate(['alert.generic_error.title', 'alert.generic_error.text']).then(function(result) {
							translations = result;

							$injector.get('AlertService').error(
								translations['alert.generic_error.title'],
								translations['alert.generic_error.text']
							);
						});

						$mdDialog.hide();
					}
					break;
			}

			return $q.reject(rejection);
		}
	};
}]);
