/**
 * Created by Barry on 2018/05/25.
 *
 * Pulse status select
 */

onePulse.directive('pulseStatusSelect', [
    function () {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/pulses/pulseStatusSelect.html',

            link: function postLink(scope, element, iAttrs) {

            }
        };
    }]);