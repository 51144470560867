/**
 * Created by Barry on 2016/11/30.
 *
 * Service handling Content requests and parsing
 */
onePulse.factory('ContentService', ['DataService', '$q', 'Config',
    function(DataService, $q, Config) {

        return {

            fetchContent: function(type, subType, itemIdentifier) {

                //Append env specific content id to type if applicable
                type = type + (Config.get('I18N_CONTENT') != "" ? '_' + Config.get('I18N_CONTENT') : "");

                var deferred = $q.defer();

                DataService.fetchContent(type, subType, itemIdentifier).then(function(response) {

                    deferred.resolve(response.data);
                });

                return deferred.promise;
            }
        }
    }]);