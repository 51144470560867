/**
 * Created by mike on 23/01/16.
 *
 * For each display of the results we need to build 3 google chart objects
 *
 * 1st is the one we show on screen - $scope.barChart.data - is populated using $scope.dataView or $scope.filteredDataView
 *
 * 2nd is hidden and used for printing. It has different styles and uses the native labels etc - $scope.printBarChart.data - is populated using $scope.dataView or $scope.filteredDataView
 *
 * 3rd is the underlying data table to be shown in a pop up and transformed into CSV for download - $scope.table.data is populated using $scope.dataTable or $scope.filteredDataTable
 *
 * Note: How can we handle large volumes of data? We dont want to be loading a lot of rows onto the page (what is a sensible max)
 *
 * This is going to cause issues with the filters and using a single flat grid..
 *
 */
onePulse.directive('questionResultsBarchart', ['$filter', '$compile', '$sce', 'DataService', 'googleChartApiPromise', '$rootScope',
    '$mdDialog', '$location', '$timeout', 'FeatureService', 'CONSTANTS', '$interval', 'ExportService',
    function($filter, $compile, $sce, DataService, googleChartApiPromise, $rootScope,
             $mdDialog, $location, $timeout, FeatureService, CONSTANTS, $interval, ExportService) {

        linkFunction = function($scope,element,attributes) {

            //Determine question format
            if(typeof $scope.question.data.format.options != 'undefined') {

                for (var i = 0; i < $scope.question.data.format.options.length; i++) {

                    if ($scope.question.data.format.options[i].option.selected == true) {

                        $scope.question.formatText = $scope.question.data.format.options[i].option.text;
                    }
                }
            } else {

                $scope.question.formatText = $scope.question.data.format.value;
            }

            //Only define link function if question format is correct
            if ($scope.question.formatText  == 'Exclusive' ||
                $scope.question.formatText == 'Multiple' ||
                $scope.question.formatText == 'Yes/No' ||
                $scope.question.formatText == 'Drag & Drop' ||
                $scope.question.formatText == 'Image' ||
                $scope.question.formatText == 'Branching') {

                $scope.loaded = false;
                $scope.showBarchart = true;
                $scope.showColumnchart = false;
                $scope.showDragDrop = false;

                //Mike's band
                $scope.fadedHalfHead = false;
                $scope.headCount = 0;

                //Control for Drag & Drop HAxis maximum value
                $scope.maxHAxis = 0;

                $scope.validAnswers = $filter('validAnswer')($scope.question.data.answers);

                $scope.validAnswerCount = $scope.validAnswers.length;

                /*For the overlay directive*/
                $scope.labels = {};

                $scope.annotations = {};

                $scope.filteredAnnotations = {};

                $scope.filteredSeries = {
                    0: {color: '#015798'}, 1: {color: '#42A2F2'}, 2: {color: '#01695C'}, 3: {color: '#850D4F'},
                    4: {color: '#E61D63'}, 5: {color: '#FF5721'}, 6: {color: '#FFA800'}, 7: {color: '#FBD202'}
                };

                /*
                 Unfiltered 2 series: Dark Grey total, light grey shadow bar
                 */
                $scope.unfilteredSeries = {
                    0: {color: $rootScope.wlClient != null && $rootScope.wlPrimaryColor != null ? $rootScope.wlPrimaryColor : '#4a4a4a'},
                    1: {color: '#f7f7f7'}
                };

                $scope.animation = {
                    duration: 800,
                    easing: 'linear',
                    startup: true
                };

                /*
                 To style the pop up data table
                 */
                $scope.tableClassNames = {
                    headerRow: "data-table-header",
                    tableRow: "data-table-row",
                    oddTableRow: "data-table-odd-row",
                    selectedTableRow: "data-table-selected-row",
                    hoverTableRow: "data-table-hover-row"
                };

                $scope.originalResults = null;


                var filename = function (ext) {
                    return $scope.result.question.text.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.' + ext;
                };

                //Pulse/Pulse Lite pull question data differently
                var getQuestionData = function (type) {

                    switch (type) {

                        case 'LITE':
                            var result = {
                                'data_table': [CONSTANTS.PULSE_DATA_TABLE_HEADER],
                                'question': {
                                    'total_responses': 1,
                                    'text': $scope.question.data.text.value
                                }
                            };

                            $scope.originalResults = angular.copy(result);

                            buildChart(result);

                            break;
                        case 'PULSE':

                            /*
                             / We need a timeout on this call for question data because in the case of a save and send,
                             / the server has not yet been updated before this request is sent
                             */
                            $timeout(function() {

                                DataService.getQuestionData($scope.question.data_token).then(function (result) {

                                    $scope.originalResults = angular.copy(result);
                                    buildDemographicsPostPublish(result);
                                    buildChart(result);
                                });
                            }, 2000);

                            break;
                    }
                };

                //Temporary function to correct Android ranking question numbering (0-7). Sets to same as iOS (1-8)
                //This will be corrected on the Mobile app side but needs to be here for historical data
                var cleanAndroidRankingNumbering = function(dataTable) {

                    var valueArray = [];

                    for(var i = 1; i < dataTable.length; i++) {

                        if($scope.uniqueIdArray.indexOf(dataTable[i][0]) != -1) {

                            if(typeof valueArray[dataTable[i][0]] == "undefined") {

                                valueArray[dataTable[i][0]] = [];
                            }

                            valueArray[dataTable[i][0]].push({
                                "answerId": dataTable[i][6],
                                "value": dataTable[i][7]
                            });
                        }
                    }

                    for(var j in valueArray) {

                        for(var k in valueArray[j]) {

                            if(valueArray[j][k].value == "0") {

                                for (var o in valueArray[j]) {

                                    valueArray[j][o].value = (parseInt(valueArray[j][o].value) + 1).toString();

                                }
                                break;
                            }
                        }
                    }

                    for(var l = 1; l < dataTable.length; l++) {

                        for(var m in valueArray) {

                            if (dataTable[l][0] == m) {

                                for(var n in valueArray[m]) {

                                    if(dataTable[l][6] == valueArray[m][n].answerId) {

                                        dataTable[l][7] = valueArray[m][n].value;
                                    }
                                }
                            }
                        }
                    }

                    return dataTable;
                };

                var buildDemographicsPostPublish = function(result) {

                    $timeout(function() {

                        if(typeof $scope.demographicsPostPublish != "undefined") {

                            if($scope.demographicsPostPublish.length == 0) {

                                if(result.filters != null) {

                                    for(var i = 0; i < result.filters.length; i++) {

                                        $scope.demographicsPostPublish[i] = {};
                                        $scope.demographicsPostPublish[i].text = result.filters[i].title;
                                        $scope.demographicsPostPublish[i].values = {};
                                        $scope.demographicsPostPublish[i].values.options = [];

                                        var valueArray = [];

                                        if(result.filters[i].options != null) {

                                            for (var j = 0; j < result.filters[i].options.length; j++) {

                                                if (result.filters[i].options[j].indexOf(", ") != -1 &&
                                                    result.filters[i].title != 'Drinking alcohol' &&
                                                    result.filters[i].title != 'Employer sector') {

                                                    valueArray = result.filters[i].options[j].split(", ");
                                                    valueArray = valueArray.reduce(function (a, b) {
                                                        if (a.indexOf(b) < 0) a.push(b);
                                                        return a;
                                                    }, []);
                                                } else {

                                                    valueArray.push(result.filters[i].options[j]);
                                                }
                                            }

                                            // var valueArrayUnique = valueArray.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);

                                            for (var k = 0; k < valueArray.length; k++) {

                                                $scope.demographicsPostPublish[i].values.options[k] = {};

                                                $scope.demographicsPostPublish[i].values.options[k].option = {};
                                                $scope.demographicsPostPublish[i].values.options[k].option.text = valueArray[k];
                                                $scope.demographicsPostPublish[i].values.options[k].option.selected = true;
                                            }
                                        }
                                    }
                                }

                                $rootScope.$broadcast("event:post-dems-loaded", $scope.demographicsPostPublish);
                            }
                        }
                    });
                };

                var buildChart = function (result) {

                    $scope.height = $scope.validAnswerCount * 45;//must be equal to the line height set on .overlay

                    $scope.filters = result.filters;
                    $scope.result = result;

                    $scope.allFilterOptions = [];

                    if(typeof $scope.filters != "undefined") {

                        for(var m = 0; m < $scope.filters.length; m++) {

                            if($scope.filters[m].options != null) {

                                for (var n = 0; n < $scope.filters[m].options.length; n++) {

                                    $scope.allFilterOptions.push($scope.filters[m].options[n])
                                }
                            }
                        }
                    }

                    if($scope.result.question.total_responses > 1) {

                        $scope.showFilterControls = true;
                    }

                    //Total number of unique participants
                    $scope.uniqueIdArray = [];

                    for(var i = 1; i < $scope.result.data_table.length; i++) {

                        $scope.result.data_table[i][12] = $scope.result.data_table[i][12].toString();

                        if($scope.uniqueIdArray.indexOf($scope.result.data_table[i][0]) == -1) {

                            $scope.uniqueIdArray.push($scope.result.data_table[i][0]);
                        }
                    }

                    $scope.question.total_responses_multiple = $scope.uniqueIdArray.length;

                    $scope.result.data_table = cleanAndroidRankingNumbering($scope.result.data_table);

                    googleChartApiPromise.then(function () {

                        $scope.sequence = sequenceAnswers($scope.question.data);

                        $scope.dataTable = new google.visualization.arrayToDataTable(result.data_table);

                        //Add dataTable to scoped question for later use. Need to include raw response table too for header
                        $scope.question.dataTable = $scope.dataTable;
                        $scope.question.rawDataTable = result.data_table;

                        //Below Interval is for TESTING response heads****leave commented!***

                        //var avatarImage = 'assets/img/user-default.png';
                        //
                        //$interval(function() {
                        //
                        //    $scope.headCount++;
                        //    $scope.pulse.data.responses.value++;
                        //    $scope.responseControl.show = true;
                        //
                        //    if($scope.question.data.sequence.value == 1) {
                        //
                        //        $scope.slickConfigPulse.method.slickAdd(
                        //        "<div class='user-response-item'>" +
                        //        "<div class='user-avatar'>" +
                        //        "<img src='" + avatarImage + "/50x50'/>" +
                        //        "</div>" +
                        //        "<div class='info'>" +
                        //        "<span>Jamie " + $scope.headCount++ + ",<br /> 22-24, West Midlands</span>" +
                        //        "</div>" +
                        //        "</div>");
                        //
                        //        $scope.slickConfigPulse.method.slickNext();
                        //    }
                        //},5000);

                        //Dynamically add user avatar and response data to results block
                        $scope.$on('event:user_responded', function ($event, $payload) {

                            if($scope.question.data.id.value in $payload.data) {

                                if (typeof $payload.data[$scope.question.data.id.value][0] != "undefined") {

                                    if ($scope.uniqueIdArray.indexOf($payload.data[$scope.question.data.id.value][0][0] == -1)) {

                                        $scope.uniqueIdArray.push($payload.data[$scope.question.data.id.value][0][0]);
                                        $scope.question.total_responses_multiple++;
                                    }

                                    //Add comment to scope
                                    if ($payload.data[$scope.question.data.id.value][0][9] != "") {

                                        var comment = {
                                            "user": $payload.profile,
                                            "text": {
                                                "value": $payload.data[$scope.question.data.id.value][0][9]
                                            }
                                        };

                                        if (typeof $scope.question.comments != "undefined") {

                                            $scope.question.comments.data.push(comment);
                                        }
                                    }
                                }

                                $scope.showFilterControls = true;

                                if (typeof $scope.responseControl != "undefined") {

                                    $scope.responseControl.show = true;
                                }

                                $scope.headCount++;

                                if (typeof $scope.slickConfigPulse != "undefined") {

                                    if ($scope.question.data.sequence.value == 1) {

                                        var firstDem = $payload.data[$scope.question.data.id.value][0][15];
                                        var secondDem = $payload.data[$scope.question.data.id.value][0][16];

                                        var avatarImage = getUserAvatar($payload.profile.avatar, firstDem, secondDem);

                                        var displayData = "<span>" + firstDem +
                                            "<br /> " + secondDem + "</span>";

                                        $scope.slickConfigPulse.method.slickAdd(
                                            "<div class='user-response-item'>" +
                                            "<div class='user-avatar'>" +
                                            "<img src='" + avatarImage + "'/>" +
                                            "</div>" +
                                            "<div class='info'>" +
                                            displayData +
                                            "</div>" +
                                            "</div>");

                                        $scope.slickConfigPulse.method.slickNext();
                                    }
                                }
                            }

                            angular.forEach($payload.data[$scope.question.data.id.value], function (row) {

                                //API can not be depended on to always return a string. Need to make sure type is properly set
                                for(var k = 0; k < row.length; k++) {

                                    if(row[k] != null) {

                                        row[k] = row[k].toString();
                                    }
                                }

                                $scope.dataTable.addRow(row);

                                //Add dataTable to scoped question for later use
                                $scope.question.dataTable = $scope.dataTable;

                                $scope.table.data = $scope.dataTable;

                                //Push ably response row into results for filtered chart and region filter
                                result.data_table.push(row);
                                $scope.originalResults.data_table.push(row);

                                //Activate new filter if necessary
                                if(typeof $scope.currentFilterCategory !== "undefined" && $scope.currentFilterCategory != null) {

                                    for(var i = 0; i < $scope.currentFilterCategory.values.options.length; i++) {

                                        for(var j = 0; j < row.length; j++) {

                                            if($scope.currentFilterCategory.values.options[i].option.text == row[j]) {

                                                $scope.currentFilterCategory.values.options[i].option.showFilter = true;
                                            }
                                        }
                                    }
                                }
                            });

                            //If a filter has been set, we keep the filtered charts active
                            if(typeof $scope.currentFilterCategory !== "undefined" && $scope.currentFilterCategory != null) {

                                $scope.$broadcast('event:filter-switch', $scope.currentFilterCategory);
                            } else {

                                $scope.barChart.options.hAxis = {
                                    gridlines: {
                                        color: 'transparent'
                                    },
                                    viewWindow: {
                                        min: 0,
                                        max: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                                    },
                                    textPosition: 'none',
                                    baselineColor: 'none',
                                    maxValue: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                                };

                                if($scope.question.formatText == 'Drag & Drop') {

                                    $scope.barChart.options.hAxis.viewWindow.max = $scope.maxHAxis;
                                    $scope.barChart.options.hAxis.maxValue = $scope.maxHAxis;
                                }

                                $scope.dataView = createVisualisation($scope.dataTable);

                                $scope.printBarChart.data = $scope.dataView;

                                $scope.barChart.data = $scope.dataView;

                            }

                            //Do not scope.$apply if this is a Pulse Lite
                            if ($location.url() != '/') {

                                $scope.$apply();
                            }
                        });

                        /*
                         Do Raw Data Table
                         */
                        $scope.table = {};

                        $scope.table.type = "Table";

                        $scope.table.data = $scope.dataTable;

                        $scope.table.options = {
                            cssClassNames: $scope.tableClassNames
                        };


                        /*
                         Pops up grid of data displaying contents of $scope.table.data
                         */

                        $scope.showDataDialog = function ($event) {

                            if(FeatureService.allowed(CONSTANTS.FEATURES.RAW_DATA_EXTRACTS, true)) {

                                $scope.tempTable = angular.copy($scope.table);

                                //Remove user display name and avatar
                                $scope.tempTable.data.removeColumn(13);
                                $scope.tempTable.data.removeColumn(13);

                                var parentEl = angular.element(document.body);
                                $mdDialog.show({
                                    parent: parentEl,
                                    targetEvent: $event,
                                    templateUrl: 'app/views/elements/includes/data-table.html',
                                    locals: {
                                        table: $scope.tempTable
                                    },
                                    clickOutsideToClose: true,
                                    scope: $scope,
                                    preserveScope: true,
                                    fullscreen: true
                                });
                            }
                        };

                        /*
                         Do Bar Chart
                         */
                        $scope.barChart = {};

                        $scope.barChart.type = "BarChart";

                        $scope.chartId = randomString(8);

                        $scope.barChart.options = {
                            fontSize: '14',
                            fontWeight: 'bold',
                            height: $scope.height,
                            chartArea: {
                                width: "100%",
                                height: "100%"
                            },
                            animation: $scope.animation,
                            legend: {
                                position: 'none'
                            },
                            tooltip: {
                                trigger: 'focus',
                                ignoreBounds: true,
                                isHtml: true
                            },
                            focusTarget: 'category',
                            bar: {
                                groupWidth: "18"
                            },
                            isStacked: false,
                            series: $scope.unfilteredSeries,
                            hAxis: {
                                gridlines: {
                                    color: 'transparent'
                                },
                                viewWindow: {
                                    min: 0,
                                    max: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                                },
                                textPosition: 'none',
                                baselineColor: 'none',
                                maxValue: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                            },
                            vAxis: {
                                gridlines: {
                                    color: 'transparent'
                                },
                                textPosition: 'none',
                                baselineColor: 'none',
                                fontSize: '0'
                            },
                            annotations: {
                                alwaysOutside: true,
                                stemColor: 'none'
                            }
                        };

                        /*
                         The visualisation data view is used for visible and printable chart
                         */
                        $scope.dataView = createVisualisation($scope.dataTable);

                        $scope.barChart.data = $scope.dataView;

                        /*
                         Set the chartArea
                         */
                        $scope.barChartReadyHandler = function (chartWrapper) {

                            $scope.chart = chartWrapper.getChart();

                            var cli = $scope.chart.getChartLayoutInterface();

                            $scope.chartArea = cli.getChartAreaBoundingBox();

                            var el = angular.element('#' + $scope.chartId);

                            if (el.find('question-results-overlay').length == 0) {

                                var overlay = angular.element(document.createElement('question-results-overlay'));
                                $compile(overlay)($scope);

                                el.append(overlay);

                            }
                        };

                        /*
                         Do Printable Bar Chart
                         */
                        $scope.printBarChart = {};

                        $scope.printBarChart.type = "BarChart";

                        $scope.printBarChart.options = {
                            fontSize: '16',
                            width: '1600',
                            chartArea: {
                                width: '60%',
                                left: '30%'
                            },
                            title: $scope.question.data.text.value,
                            titleTextStyle: {
                                color: '#000',
                                fontSize: 12,
                                bold: false,
                                italic: false
                            },
                            legend: {
                                position: 'bottom',
                                alignment: 'center'
                            },
                            tooltip: {
                                trigger: 'none'
                            },
                            focusTarget: 'category',
                            bar: {
                                groupWidth: "18"
                            },
                            isStacked: false,
                            series: {
                                0: {color: '#4a4a4a'},
                                1: {color: '#f7f7f7'}
                            }
                        };

                        $scope.printBarChart.data = $scope.dataView;

                        $scope.printBarChartReadyHandler = function (chartWrapper) {
                            /*
                             Print a PNG
                             */
                            $scope.printPng = function ($event) {

                                if(FeatureService.allowed(CONSTANTS.FEATURES.RAW_DATA_EXTRACTS, true)) {

                                    var encodedUri = chartWrapper.getChart().getImageURI();

                                    var anchor = document.createElement('a');

                                    anchor.href = encodedUri;
                                    /*
                                     Should use the question name here..

                                     TODO: Not sure the .download has proper support
                                     */
                                    anchor.download = filename('png');
                                    anchor.target = '_blank';

                                    document.body.appendChild(anchor);

                                    anchor.click();
                                }
                            }
                        };

                        //Extract Raw Data CSV
                        $scope.extractData = function ($event) {

                            if(FeatureService.allowed(CONSTANTS.FEATURES.SPREADSHEET_DATA_EXTRACTS, true)) {

                                if(typeof analytics !== "undefined" && typeof analytics.track !== "undefined") {

                                    var user = DataService.grab('user');

                                    if(typeof user.data.brand_name != "undefined") {

                                        analytics.track('Extract Raw Data', {
                                            name: user.data.brand_name.value
                                        });
                                    }
                                }

                                var questionsArray = [];
                                questionsArray.push($scope.question);

                                var pulseHeader = ExportService.buildPulseHeader('csv', $scope.pulse);

                                var header = ExportService.buildHeader('csv', questionsArray, result.data_table[0], false, pulseHeader);

                                var dataObjArray = {};
                                dataObjArray = ExportService.addQuestion('csv', $scope.question, dataObjArray, $scope.allRegions);

                                var formattedDataTable = ExportService.buildBody('csv', header, dataObjArray, questionsArray, false);

                                ExportService.saveFile(ExportService.encodeUri('csv', formattedDataTable), $scope.result.question.text + '_raw.csv');
                            }
                        };

                        //Extract Demographic Summary CSV
                        $scope.extractDemographicSummary = function($event) {

                            if(FeatureService.allowed(CONSTANTS.FEATURES.SPREADSHEET_DATA_EXTRACTS, true)) {

                                if(typeof analytics !== "undefined" && typeof analytics.track !== "undefined") {

                                    var user = DataService.grab('user');

                                    if(typeof user.data.brand_name != "undefined") {

                                        analytics.track('Extract Summary Data', {
                                            name: user.data.brand_name.value
                                        });
                                    }
                                }

                                var questionsArray = [];
                                questionsArray.push($scope.question);

                                var pulseHeader = ExportService.buildPulseHeader('csv', $scope.pulse);

                                var header = ExportService.buildDemographicSummaryHeader('csv', questionsArray, pulseHeader);

                                var dataObjArray = {};
                                dataObjArray = ExportService.addQuestion('csv', $scope.question, dataObjArray, $scope.allRegions);

                                var formattedDataTable = ExportService.buildDemographicSummaryBody('csv', header, dataObjArray, questionsArray, $scope.pulse, $scope.demographicsPostPublish);

                                ExportService.saveFile(ExportService.encodeUri('csv', formattedDataTable), $scope.result.question.text + '_summary.csv');
                            }
                        };

                        /*
                         Dynamic Demographic Filters go here...
                         */
                        $scope.switch = {};

                        $scope.switch.all = {};

                        $scope.switch.filters = {};

                        $scope.switch.all.state = true;

                        $scope.chartFilters = [];

                        /*
                         * Filter on region
                         * */
                        $scope.$on('event:region-filter', function(event, regions) {

                            var selectedRegionIds = [];

                            for(var j = 0; j < regions.options.length; j++) {

                                if(regions.options[j].option.filterBy) {

                                    selectedRegionIds.push(parseInt(regions.options[j].option.id));
                                }
                            }

                            if($scope.originalResults.data_table.length > 1) {

                                var newDataTable = [];

                                newDataTable.push($scope.originalResults.data_table[0]);

                                for(var i = 1; i < $scope.originalResults.data_table.length; i++) {

                                    if(selectedRegionIds.indexOf(parseInt($scope.originalResults.data_table[i][12])) != -1) {

                                        newDataTable.push($scope.originalResults.data_table[i]);
                                    }
                                }

                                result.data_table = newDataTable;

                                //Total number of unique participants
                                $scope.uniqueIdArray = [];

                                for(var j = 1; j < result.data_table.length; j++) {

                                    result.data_table[j][12] = result.data_table[j][12].toString();

                                    if($scope.uniqueIdArray.indexOf(result.data_table[j][0]) == -1) {

                                        $scope.uniqueIdArray.push(result.data_table[j][0]);
                                    }
                                }

                                $scope.question.total_responses_multiple = $scope.uniqueIdArray.length;

                                $scope.dataTable = new google.visualization.arrayToDataTable(result.data_table);

                                $scope.question.dataTable = $scope.dataTable;
                                $scope.question.rawDataTable = result.data_table;

                                //If a filter has been set, we keep the filtered charts active
                                if(typeof $scope.currentFilterCategory != "undefined" && $scope.currentFilterCategory != null) {

                                    $scope.$broadcast('event:filter-switch', $scope.currentFilterCategory);
                                } else {

                                    $scope.barChart.options.hAxis = {
                                        gridlines: {
                                            color: 'transparent'
                                        },
                                        viewWindow: {
                                            min: 0,
                                            max: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                                        },
                                        textPosition: 'none',
                                        baselineColor: 'none',
                                        maxValue: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                                    };

                                    if($scope.question.formatText == 'Drag & Drop') {

                                        $scope.barChart.options.hAxis.viewWindow.max = $scope.maxHAxis;
                                        $scope.barChart.options.hAxis.maxValue = $scope.maxHAxis;
                                    }

                                    $scope.dataView = createVisualisation($scope.dataTable);

                                    $scope.printBarChart.data = $scope.dataView;

                                    $scope.barChart.data = $scope.dataView;

                                }
                            }
                        });

                        /*
                         To reset the filters
                         */
                        $scope.$on('event:filter-off', function(event) {

                            $scope.switch.filters = {};

                            $scope.chartFilters = [];

                            $scope.annotations = {};
                            $scope.labels = {};

                            $scope.showDragDrop = false;

                            var step1 = $scope.dataView;

                            for (var t = 0; t < step1.getNumberOfRows(); t++) {

                                var val = roundNumbers(step1.getValue(t, 1), 2);

                                $scope.annotations[t] = val > 0 ? roundNumbers((val / $scope.question.total_responses_multiple) * 100, 2) : 0;

                                $scope.labels[t] = {};

                                $scope.labels[t].label = step1.getValue(t, 0);

                                for(var u = 0; u < $scope.question.data.answers.length; u++) {

                                    if($scope.question.data.answers[u].data.text.value == $scope.labels[t].label){

                                        $scope.labels[t].answerToken = $scope.question.data.answers[u].data_token;
                                    }
                                }
                            }

                            /*
                             This resets both bar charts to the original state of the data

                             and passes in the unfiltered series colour options
                             */
                            $scope.barChart.data = step1;

                            $scope.printBarChart.data = step1;

                            $scope.barChart.options.series = $scope.unfilteredSeries;
                            $scope.barChart.options.height = $scope.height;
                            $scope.barChart.options.isStacked = false;

                            $scope.barChart.options.hAxis = {
                                gridlines: {
                                    color: 'transparent'
                                },
                                viewWindow: {
                                    min: 0,
                                    max: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                                },
                                textPosition: 'none',
                                baselineColor: 'none',
                                maxValue: ($scope.question.total_responses_multiple == 0?1:$scope.question.total_responses_multiple)
                            };

                            if($scope.question.formatText == 'Drag & Drop') {

                                $scope.barChart.options.hAxis.viewWindow.max = $scope.maxHAxis;
                                $scope.barChart.options.hAxis.maxValue = $scope.maxHAxis;
                            }


                            $scope.printBarChart.options.series = $scope.unfilteredSeries;

                            $scope.filteredAnnotations = {};
                        });

                        $scope.$on('event:filter-switch', function(event, filterCategory) {

                            //Get the colIndex by searching the header array
                            var colIndex = result.data_table[0].indexOf(filterCategory.text);

                            if (colIndex > -1) {

                                var values = [];
                                var colors = [];

                                for (var k = 0; k < filterCategory.values.options.length; k++) {

                                    if (filterCategory.values.options[k].option.showFilter) {

                                        values.push(filterCategory.values.options[k].option.text);
                                        colors.push(filterCategory.values.options[k].option.color);
                                    }
                                }
                            }

                            //If filters have been set
                            if (values.length > 0) {

                                if($scope.question.formatText != 'Drag & Drop') {

                                    $scope.filteredDataView = createVisualisationFilter(result, values, colIndex);

                                    var setColumns = [0];

                                    for (var j = 1; j <= $scope.validAnswers.length; j++) {

                                        setColumns.push(j);
                                    }

                                    //We need to set double the number of columns for multiple due to the custom tooltips
                                    if ($scope.question.formatText == 'Multiple') {

                                        for (var j = 1; j <= $scope.validAnswers.length; j++) {

                                            setColumns.push($scope.validAnswers.length + j);
                                        }
                                    }

                                    $scope.filteredDataView.setColumns(setColumns);

                                    //Update charts - Added timeout to smooth animation
                                    $timeout(function () {

                                        $scope.barChart.data = $scope.filteredDataView;
                                        $scope.barChart.options.height = values.length * 45;
                                        $scope.barChart.options.series = $scope.filteredSeries;

                                        if ($scope.question.formatText == 'Multiple') {

                                            $scope.barChart.options.isStacked = true;

                                            $scope.barChart.options.hAxis = {
                                                gridlines: {
                                                    color: 'transparent'
                                                },
                                                viewWindow: {
                                                    min: 0,
                                                    max: ($scope.question.total_responses_multiple == 0 ? 1 : $scope.question.total_responses)
                                                },
                                                textPosition: 'none',
                                                baselineColor: 'none',
                                                maxValue: ($scope.question.total_responses_multiple == 0 ? 1 : $scope.question.total_responses)
                                            };
                                        } else {

                                            $scope.barChart.options.isStacked = 'percent';

                                            $scope.barChart.options.hAxis = {
                                                gridlines: {
                                                    color: 'transparent'
                                                },
                                                textPosition: 'none',
                                                baselineColor: 'none'
                                            };
                                        }

                                    }, 800);

                                    $scope.printBarChart.data = $scope.filteredDataView;
                                    $scope.printBarChart.options.series = $scope.filteredSeries;

                                    if ($scope.question.formatText == 'Multiple') {

                                        $scope.printBarChart.options.isStacked = true;

                                        $scope.barChart.options.hAxis = {
                                            gridlines: {
                                                color: 'transparent'
                                            },
                                            viewWindow: {
                                                min: 0,
                                                max: ($scope.question.total_responses_multiple == 0 ? 1 : $scope.question.total_responses)
                                            },
                                            textPosition: 'none',
                                            baselineColor: 'none',
                                            maxValue: ($scope.question.total_responses_multiple == 0 ? 1 : $scope.question.total_responses)
                                        };

                                    } else {

                                        $scope.printBarChart.options.isStacked = 'percent';

                                        $scope.barChart.options.hAxis = {
                                            gridlines: {
                                                color: 'transparent'
                                            },
                                            textPosition: 'none',
                                            baselineColor: 'none'
                                        };
                                    }

                                    $scope.printBarChart.options.tooltip = {
                                        trigger: 'none'
                                    };

                                    $scope.annotations = {};

                                } else {

                                    //TODO: Build SVG for D&D
                                    $scope.initSvgWrapper(result, values, colIndex, $scope.question);

                                    $scope.showDragDrop = true;
                                }

                            } else {

                                /*
                                 Why isnt any set? Who knows, just make sure the original data gets shown
                                 */
                                $scope.barChart.data = $scope.dataView;

                                $scope.printBarChart.data = $scope.dataView;

                            }
                        });

                        $scope.loaded = true;


                    }, undefined);
                };

                var createVisualisationFilter = function(result, filters, colIndex) {

                    var filterTotals = [];
                    var filteredDataArray = [];
                    var uniqueUserIds = [];
                    var header = ['Answer'];

                    for (var j = 0; j < $scope.validAnswers.length; j++) {

                        header.push($scope.validAnswers[j].data.text.value);
                    }

                    filteredDataArray.push(header);

                    for(var i = 0; i < filters.length; i++) {

                        var answerRow = [filters[i]];
                        filterTotals[filters[i]] = 0;

                        for(var k = 1; k < header.length; k++) {

                            answerRow.push(0);
                        }

                        filteredDataArray.push(answerRow);
                    }

                    for(var l = 1; l < result.data_table.length; l++) {

                        if(uniqueUserIds.indexOf(result.data_table[l][0]) == -1) {

                            uniqueUserIds.push(result.data_table[l][0]);

                            if(result.data_table[l][colIndex].indexOf(", ") != -1 &&
                                $scope.allFilterOptions.indexOf(result.data_table[l][colIndex]) == -1 &&
                                result.data_table[0][colIndex] != 'Drinking alcohol' &&
                                result.data_table[0][colIndex] != 'Employer sector') {

                                var colIndexArray = result.data_table[l][colIndex].split(", ");

                                for (var p = 0; p < colIndexArray.length; p++) {

                                    var element = colIndexArray[p].trim();
                                    filterTotals[element]++;
                                }
                            } else {

                                filterTotals[result.data_table[l][colIndex].trim()]++;
                            }
                        }

                        for(var m = 1; m < filteredDataArray.length; m++) {

                            var valueArray = [];

                            if(result.data_table[l][colIndex].indexOf(", ") != -1 &&
                                $scope.allFilterOptions.indexOf(result.data_table[l][colIndex]) == -1 &&
                                result.data_table[0][colIndex] != 'Drinking alcohol' &&
                                result.data_table[0][colIndex] != 'Employer sector') {

                                var colIndexArray2 = result.data_table[l][colIndex].split(", ");

                                for (var q = 0; q < colIndexArray.length; q++) {

                                    if(typeof colIndexArray2[q] != "undefined") {

                                        var element2 = colIndexArray2[q].trim();
                                        valueArray.push(element2);
                                    }
                                }
                            } else {

                                valueArray.push(result.data_table[l][colIndex].trim());
                            }

                            if(valueArray.indexOf(filteredDataArray[m][0]) !== -1) {

                                for(var n = 1; n < filteredDataArray[0].length; n++) {

                                    if(filteredDataArray[0][n] == result.data_table[l][5]) {

                                        filteredDataArray[m][n]++;
                                    }
                                }
                            }
                        }
                    }

                    var data = google.visualization.arrayToDataTable(filteredDataArray);

                    if($scope.question.formatText == 'Multiple') {

                        for (var x = 2; x <= data.getNumberOfColumns(); x = x + 2) {

                            data.insertColumn(x, {'type': 'string', 'role': 'tooltip', 'p': {'html': true}});
                        }

                        function createCustomHTMLContent(value, total, percentage) {
                            return $sce.trustAsHtml(' ' + value + '/' + total + ' : <strong>' + percentage + '%</strong>');
                        }

                        for (var t = 0; t < data.getNumberOfRows(); t++) {

                            for (var x = 2; x <= data.getNumberOfColumns(); x = x + 2) {

                                var newValue =  $sce.getTrustedHtml(createCustomHTMLContent(
                                        data.getValue(t, x - 1),
                                        filterTotals[data.getValue(t, 0)],
                                        ((data.getValue(t, x - 1) / filterTotals[data.getValue(t, 0)]) * 100).toFixed(2))
                                );
                                data.setCell(t, x, newValue);
                            }
                        }
                    }

                    var step1 = new google.visualization.DataView(data);

                    $scope.annotations = {};
                    $scope.labels = {};

                    for (var t = 0; t < step1.getNumberOfRows(); t++) {

                        $scope.labels[t] = {};

                        $scope.labels[t].label = step1.getValue(t, 0);

                        for(var u = 0; u < $scope.question.data.answers.length; u++) {

                            if($scope.question.data.answers[u].data.text.value == $scope.labels[t].label){

                                $scope.labels[t].answerToken = $scope.question.data.answers[u].data_token;
                            }
                        }
                    }

                    return new google.visualization.DataView(step1);
                };


                /*
                 This takes the flat grid of data and turns it into the dataview with the right number of series to

                 display the chart.

                 It adds a shadow series on all charts and if filters are being used the first filtered series

                 */
                var createVisualisation = function (data, unfilteredTable) {

                    /*
                     Step 1 is to limit the columns and group responses as a count
                     */
                    if($scope.question.formatText != 'Drag & Drop') {

                        var step2 = google.visualization.data.group(data,
                            [
                                {
                                    column: 'Answer',
                                    label: 'Answer',
                                    type: 'string'
                                }
                            ],
                            [
                                {
                                    column: 'Answer ID',
                                    label: 'Total Responses',
                                    aggregation: google.visualization.data.count,
                                    type: 'number'
                                }
                            ]
                        );

                        /*
                         Compare the contents of the step2 table with the number of expected answers

                         If there is fewer rows than answers then some answers are 0% and should be added to the table at the correct index.

                         This should keep the correct number of bars on the chart in a consistent order
                         */
                        if (step2.getNumberOfRows() < $scope.validAnswerCount) {

                            angular.forEach($scope.validAnswers, function (a, i) {

                                if (i <= step2.getNumberOfRows()) {

                                    if (!searchData(step2, a.data.text.value)) {

                                        step2.insertRows(i, [[a.data.text.value, 0]]);
                                    }

                                } else {
                                    /*No rows just adding everything*/
                                    step2.insertRows(i, [[a.data.text.value, 0]]);
                                }
                            })
                        }

                        //This section re-sequences the answers in the data table - There is no other way to regain their initial order after grouping
                        if(typeof step2.cache !== "undefined" && typeof step2.eg !== "undefined") {

                            var newCache = [];
                            var newEg = [];

                            var cacheLength = step2.cache.length;
                            var egLength = step2.eg.length;

                            for (var j = 0; j < cacheLength; j++) {

                                if (typeof step2.cache[j] != "undefined") {

                                    if (typeof $scope.sequence[step2.cache[j][0].Te] != "undefined") {

                                        newCache[parseInt($scope.sequence[step2.cache[j][0].Te])] = step2.cache[j];
                                    }
                                }
                            }

                            for (var i = 0; i < egLength; i++) {

                                if (typeof step2.eg[i] != "undefined") {

                                    if (typeof $scope.sequence[step2.eg[i].c[0].v] != "undefined") {

                                        newEg[parseInt($scope.sequence[step2.eg[i].c[0].v])] = step2.eg[i];
                                    }
                                }
                            }

                            step2.cache = newCache;
                            step2.eg = newEg;
                        } else if (typeof step2.cache !== "undefined" && typeof step2.hg !== "undefined") {

                            var newCache = [];
                            var newHg = [];

                            var cacheLength = step2.cache.length;
                            var hgLength = step2.hg.length;

                            for (var j = 0; j < cacheLength; j++) {

                                if (typeof step2.cache[j] != "undefined") {

                                    if (typeof $scope.sequence[step2.cache[j][0].We] != "undefined") {

                                        newCache[parseInt($scope.sequence[step2.cache[j][0].We])] = step2.cache[j];
                                    }
                                }
                            }

                            for (var i = 0; i < hgLength; i++) {

                                if (typeof step2.hg[i] != "undefined") {

                                    if (typeof $scope.sequence[step2.hg[i].c[0].v] != "undefined") {

                                        newHg[parseInt($scope.sequence[step2.hg[i].c[0].v])] = step2.hg[i];
                                    }
                                }
                            }

                            step2.cache = newCache;
                            step2.hg = newHg;
                        } else if (typeof step2.cache !== "undefined" && typeof step2.Vf !== "undefined") {

                            var newCache = [];
                            var newVf = [];

                            var cacheLength = step2.cache.length;
                            var vfLength = step2.Vf.length;

                            for (var j = 0; j < cacheLength; j++) {

                                if (typeof step2.cache[j] != "undefined") {

                                    if (typeof $scope.sequence[step2.cache[j][0].Me] != "undefined") {

                                        newCache[parseInt($scope.sequence[step2.cache[j][0].Me])] = step2.cache[j];
                                    }
                                }
                            }

                            for (var i = 0; i < vfLength; i++) {

                                if (typeof step2.Vf[i] != "undefined") {

                                    if (typeof $scope.sequence[step2.Vf[i].c[0].v] != "undefined") {

                                        newVf[parseInt($scope.sequence[step2.Vf[i].c[0].v])] = step2.Vf[i];
                                    }
                                }
                            }

                            step2.cache = newCache;
                            step2.Vf = newVf;
                        } else if (typeof step2.cache !== "undefined" && typeof step2.fg !== "undefined") {

                            var newCache = [];
                            var newFg = [];

                            var cacheLength = step2.cache.length;
                            var fgLength = step2.fg.length;

                            for (var j = 0; j < cacheLength; j++) {

                                if(typeof step2.cache[j] != "undefined") {

                                    if (typeof $scope.sequence[step2.cache[j][0].We] != "undefined") {

                                        newCache[parseInt($scope.sequence[step2.cache[j][0].We])] = step2.cache[j];
                                    }
                                }
                            }

                            for (var i = 0; i < fgLength; i++) {

                                if(typeof step2.fg[i] != "undefined") {

                                    if(typeof $scope.sequence[step2.fg[i].c[0].v] != "undefined") {

                                        newFg[parseInt($scope.sequence[step2.fg[i].c[0].v])] = step2.fg[i];
                                    }
                                }
                            }

                            step2.cache = newCache;
                            step2.fg = newFg;
                        } else if (typeof step2.Nf !== "undefined" && typeof step2.hc !== "undefined") {

                            var newNf = [];
                            var newhc = [];
                            var nfLength = step2.Nf.length;
                            var hcLength = step2.hc.length;

                            for (var i = 0; i < nfLength; i++) {

                                if(typeof step2.Nf[i] != "undefined") {

                                    if(typeof $scope.sequence[step2.Nf[i].c[0].v] != "undefined") {

                                        newNf[parseInt($scope.sequence[step2.Nf[i].c[0].v])] = step2.Nf[i];
                                    }
                                }
                            }

                            for (var j = 0; j < hcLength; j++) {

                                if(typeof step2.hc[j] != "undefined") {

                                    if (typeof $scope.sequence[step2.hc[j][0].wf] != "undefined") {

                                        newhc[parseInt($scope.sequence[step2.hc[j][0].wf])] = step2.hc[j];
                                    }
                                }
                            }

                            step2.Nf = newNf;
                            step2.hc = newhc;
                        }

                        /*
                         Step 4 simply converts the DataTable to a DataView. This is required only if the table has filters turned on.

                         If filters are turned on we need to add another series to show the filtered value on top of the unfilterd value and the shadow

                         Series order is filtered value, unfiltered value, shadow. This requires the columns to be re-ordered.

                         Unfiltered = original value - filtered value. Shadow = max - unfiltered value
                         */
                        var step4 = new google.visualization.DataView(step2);

                        for (var t = 0; t < step4.getNumberOfRows(); t++) {

                            var val = roundNumbers(step4.getValue(t, 1), 2);

                            $scope.annotations[t] = val > 0 ? roundNumbers((val / $scope.question.total_responses_multiple) * 100, 2) : 0;

                            $scope.labels[t] = {};

                            $scope.labels[t].label = step4.getValue(t, 0);

                            for(var u = 0; u < $scope.question.data.answers.length; u++) {

                                if($scope.question.data.answers[u].data.text.value == $scope.labels[t].label){

                                    $scope.labels[t].answerToken = $scope.question.data.answers[u].data_token;
                                }
                            }
                        }

                        return step4;

                    } else {

                        var step1 = google.visualization.data.group(data,
                            [
                                {
                                    column: 'Answer',
                                    label: 'Answer',
                                    type: 'string'
                                }
                            ],
                            [
                                {
                                    column: 'Value',
                                    label: 'Weighted Average Rank',
                                    aggregation: function (value) {

                                        var rankingAverage = 0;
                                        var totalsArray = [];

                                        for (var j = 1; j <= $scope.validAnswerCount; j++) {

                                            totalsArray[j] = 0;
                                        }

                                        for (var i = 0; i < value.length; i++) {

                                            totalsArray[value[i]]++;
                                        }

                                        //Inverse weighting. i.e. Answer in 1st position gets max answer array length as multiplier
                                        for (var k = 1; k < totalsArray.length; k++) {

                                            rankingAverage += totalsArray[k] * ((totalsArray.length - 1) - (k - 1));
                                        }

                                        rankingAverage = rankingAverage / ($scope.question.total_responses_multiple);

                                        //Round to 2 decimal places
                                        rankingAverage = Math.round(rankingAverage * 100) / 100;

                                        $scope.maxHAxis = (rankingAverage > $scope.maxHAxis ? rankingAverage : $scope.maxHAxis);

                                        //Round to 2 decimal places
                                        return rankingAverage;
                                    },
                                    type: 'number'
                                }
                            ]
                        );

                        $scope.barChart.options.hAxis.viewWindow.max = $scope.maxHAxis;
                        $scope.barChart.options.hAxis.maxValue = $scope.maxHAxis;

                        if (step1.getNumberOfRows() < $scope.validAnswerCount) {

                            angular.forEach($scope.validAnswers, function (a, i) {

                                if (i <= step1.getNumberOfRows()) {

                                    if (!searchData(step1, a.data.text.value)) {

                                        step1.insertRows(i, [[a.data.text.value, 0]]);
                                    }

                                } else {
                                    /*No rows just adding everything*/
                                    step1.insertRows(i, [[a.data.text.value, 0]]);
                                }
                            })
                        }

                        step1.sort([{column: 1, desc: true}]);

                        for (var t = 0; t < step1.getNumberOfRows(); t++) {

                            var val = roundNumbers(step1.getValue(t, 1), 2);
                            $scope.annotations[t] = val > 0 ? roundNumbers((val / $scope.question.total_responses_multiple) * 100, 2) : 0;

                            $scope.labels[t] = {};

                            $scope.labels[t].label = step1.getValue(t, 0);

                            for(var u = 0; u < $scope.question.data.answers.length; u++) {

                                if($scope.question.data.answers[u].data.text.value == $scope.labels[t].label){

                                    $scope.labels[t].answerToken = $scope.question.data.answers[u].data_token;
                                }
                            }
                        }

                        return new google.visualization.DataView(step1);
                    }


                };

                if ($scope.question.formatText != 'Slider') {

                    //If we are at root we are viewing a Pulse Lite - data is not pulled from the API
                    if ($location.url() == '/') {
                        getQuestionData('LITE');
                    } else {
                        getQuestionData('PULSE');
                    }
                }

                /*
                 Close data table pop up
                 */
                $scope.closeDialog = function ($event) {
                    $mdDialog.hide();
                }
            }
        };

        //Create sequence array with answer text values as keys - Needed to re-sequence answers correctly
        var sequenceAnswers = function(data) {

            var sequence = [];

            //Decrement handles gaps in the answer sequence
            var decrement = 0;

            if(typeof data.answers != "undefined") {

                for(var i = 0; i < data.answers.length; i++){

                    if(data.answers[i].data.text.value != "") {

                        sequence[data.answers[i].data.text.value] = i - decrement;
                    } else {

                        decrement++;
                    }
                }
            }

            return sequence;
        };

        return {
            link:linkFunction,
            templateUrl:'/app/views/elements/question-results-barchart.html'
        }
    }]);
