onePulse.factory('AblyService', ['TokenService', '$http', 'Config', '$location',
    function(TokenService, $http, Config, $location){

        var authCallback = function(tokenParams, callback) {

        var url = null;

        //If we are at root or a share view we user the Pulse Lite Ably endpoint
        if($location.url() == '/') {

            url = Config.get('ABLY_AUTH_ENDPOINT_LITE');
        } else if($location.absUrl().search('/share') != -1) {

            url = Config.get('ABLY_AUTH_ENDPOINT_SHARE');
        } else {

            url = Config.get('ABLY_AUTH_ENDPOINT');
        }

        $http({
            method: 'GET',
            url: url,
            data: {},
            headers: {'Authorization': 'Bearer ' + TokenService.getAccessToken()}
        })
            .then(function successCallback(response) {

                callback(null, response.data);

            }, function errCallback(response) {

                callback(response.data, null);
            });
        };

        var ablyClient = null;

        var client = function() {

            if(ablyClient == null) {

                ablyClient = new Ably.Realtime({authCallback: authCallback});
            }

            return ablyClient;
        };

        var disconnect = function() {

            if(ablyClient != null) {

                ablyClient.close();
                ablyClient = null;
            }
        };

        return {
            client: client,
            disconnect: disconnect
        }
    }]);