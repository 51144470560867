/**
 * Created by Barry on 2017/03/27.
 */

onePulse.directive('svgDragDropResultsWrapper', ['$timeout', function ($timeout) {

    return {
        restrict: 'E',
        templateUrl: 'app/views/elements/svg/svgDragDropResultsWrapper.html',

        link: function postLink(scope, element, iAttrs) {

            scope.svg = {height: '100%'};

            var textFontFamily = "Open Sans";
            var textFontSizeDemographic = 14;
            var textFontSizeRankingValue = 10;

            scope.initSvgWrapper = function(result, values, colIndex, question) {

                scope.svgGroups = [];
                scope.svgAnswers = [];
                scope.popOver = [];

                $timeout(function() {

                    var elementRect = element[0].children[0].children[0].children[0].getBoundingClientRect();
                    var width = elementRect.width;

                    //Loop over dem values
                    for(var i = 0; i < values.length; i++) {

                        //If there are no answers for this dem value, skip iteration
                        var noAnswersForDemValue = true;

                        for(var k = 1; k < result.data_table.length; k++) {

                            if(result.data_table[k][colIndex] == values[i]) {

                                noAnswersForDemValue = false;
                            }
                        }

                        if(noAnswersForDemValue) {

                            continue;
                        }

                        var text = {
                            textXAxis: 0,
                            textYAxis: (50 * i) + 20,
                            textFontFamily: textFontFamily,
                            textFontSize: textFontSizeDemographic,
                            textContent: values[i]
                        };

                        scope.svgGroups[i] = {
                            texts: [],
                            rects: []
                        };

                        scope.svgGroups[i].texts.push(text);

                        for(var j = 0; j < scope.validAnswers.length; j++) {

                            scope.svgAnswers[j] = {};
                            scope.svgAnswers[j].id = scope.validAnswers[j].data.id.value;
                            scope.svgAnswers[j].text = scope.validAnswers[j].data.text.value;
                            scope.svgAnswers[j].answerValues = [];
                            scope.svgAnswers[j].color = scope.filteredSeries[j].color;

                            for(var k = 1; k < result.data_table.length; k++) {

                                if(result.data_table[k][colIndex] == values[i] && result.data_table[k][6] == scope.validAnswers[j].data.id.value) {

                                    scope.svgAnswers[j].answerValues.push(result.data_table[k][7]);
                                }
                            }

                            var rankingAverage = 0;
                            var totalsArray = [];

                            for (var l = 1; l <= scope.validAnswerCount; l++) {

                                totalsArray[l] = 0;
                            }

                            for (var m = 0; m < scope.svgAnswers[j].answerValues.length; m++) {

                                totalsArray[scope.svgAnswers[j].answerValues[m]]++;
                            }

                            //Inverse weighting. i.e. Answer in 1st position gets max answer array length as multiplier
                            for (var n = 1; n < totalsArray.length; n++) {

                                rankingAverage += totalsArray[n] * ((totalsArray.length - 1) - (n - 1));
                            }

                            rankingAverage = rankingAverage / (question.total_responses_multiple);

                            //Round to 2 decimal places
                            rankingAverage = Math.round(rankingAverage * 100) / 100;

                            scope.svgAnswers[j].rankingAverage = rankingAverage;
                        }

                        scope.svgAnswers.sort(function(a, b) {

                            return b.rankingAverage - a.rankingAverage;
                        });

                        for(var o = 0; o < scope.svgAnswers.length; o++) {

                            scope.svgGroups[i].texts.push({
                                textXAxis: ((width / scope.validAnswerCount) * o) + 5,
                                textYAxis: (50 * i) + 40,
                                textFontFamily: textFontFamily,
                                textFontSize: textFontSizeRankingValue,
                                textContent: ""
                                }
                            );

                            scope.svgGroups[i].rects.push({
                                rectXAxis: ((width / scope.validAnswerCount) * o),
                                rectYAxis: (50 * i) + 25,
                                rectHeight: 25,
                                rectWidth: (width / scope.validAnswerCount) - 2,
                                rectFill: scope.svgAnswers[o].color
                                }
                            );

                            if(typeof scope.popOver[i] == "undefined") {

                                scope.popOver[i] = {content: ""};
                            }

                            scope.popOver[i].content = scope.popOver[i].content +
                            "<div class='color-box' style='background-color: " + scope.svgAnswers[o].color + ";'></div>" +
                            " " + appendNumberPositionText((o + 1)) +
                            ": " + scope.svgAnswers[o].text + "</span>" +
                            " (WR: " + scope.svgAnswers[o].rankingAverage + ")" +
                            "<br />"
                        }
                    }

                    scope.svg.height = values.length * 50;
                })
            }
        }
    };
}]);
