/**
 * Created by mike on 13/12/15.
 */

onePulse.directive('pulse-default', ['FormService', 'DataService', function(FormService, DataService) {

    var $form = FormService.parse('user')

    linkFunction = function($scope, element, attributes){
        $scope.form = $form
        $scope.submit = DataService.submit;
    }

    return {
        templateUrl: '/app/views/elements/pulse-default.html',
        link:linkFunction
    }
}]);
