onePulse.run(['$rootScope', '$location', '$window', 'DataService', 'AuthService', '$timeout', '$http', '$mdTheming', 'CONSTANTS', '$injector',
    'LogoutService', 'TokenService', 'AlertService', 'Config', 'ngMeta', '$translate', 'mdThemingProvider',
    function ($rootScope, $location, $window, DataService, AuthService, $timeout, $http, $mdTheming, CONSTANTS, $injector,
              LogoutService, TokenService, AlertService, Config, ngMeta, $translate, mdThemingProvider) {

        var init = function() {

            var dataLayer = $window.dataLayer = $window.dataLayer || [];

            $rootScope.$on('$routeChangeSuccess', function() {

                dataLayer.push({
                    event: 'ngRouteChange',
                    attributes: {
                        route: $location.path()
                    }
                });
            });

            //Online/offline listeners
            $rootScope.online = navigator.onLine;

            window.addEventListener("offline", function() {

                $rootScope.$apply(function() {

                    $rootScope.online = false;
                });
            }, false);

            window.addEventListener("online", function() {

                $rootScope.$apply(function() {

                    $rootScope.online = true;
                });
            }, false);

            Config.countryCheck(false, true).then(function() {

                //Set runtime language
                $translate.use(Config.get('I18N_LANGUAGE'));

                //Set runtime vars
                $rootScope.currency = Config.get('I18N_CURRENCY');
                $rootScope.showVat = Config.get('SHOW_VAT');
                $rootScope.chargeVat = Config.get('CHARGE_VAT');
                $rootScope.env = Config.get('ENV');
                $rootScope.showSubContent = CONSTANTS.SHOW_SUB_CONTENT;

                ngMeta.init();

                if(!analytics.invoked) {

                    enableSegment();
                }

                if(typeof analytics.load !== "undefined") {

                    analytics.load(Config.get('SEGMENT_KEY'));
                }

                //Get view-port dimensions
                var dimensions = getViewportDimensions();

                //Prep errors
                $rootScope.errors = [];

                //Determine device type and assign to rootScope
                $rootScope.deviceType = determineDeviceType(dimensions);

                $rootScope.$on('$routeChangeStart', function (event, next, current) {

                    var path = window.localStorage['path'];
                    envRedirect();

                    $rootScope.renderComplete = false;
                    $rootScope.fadedOverlay = false;
                    $rootScope.previousState = current;

                    //If the user is logged in and the auth token is not expired we check for token validity. We logout if token is expired
                    if (AuthService.isLoggedIn()) {

                        if (!TokenService.isTokenExpired(TokenService.getAccessToken())) {

                            DataService.checkAccessToken();
                        } else {

                            LogoutService.logout();

                            var translations;
                            $translate(['alert.session_expired.title', 'alert.session_expired.text']).then(function (result) {
                                translations = result;

                                AlertService.info(
                                    translations['alert.session_expired.title'],
                                    translations['alert.session_expired.text']
                                );
                            });
                        }
                    }

                    if ((['/', '/workspaces', '/register', '/register-complete', '/terms',
                            '/terms-mobile', '/privacy', '/product', '/solutions',
                            '/community', '/product', '/about', '/features',
                            '/faq', '/contact', '/launch', '/cookies',
                            '/pricing', '/blog', '/clientOnBoarding', '/chart', '/sso'].indexOf($location.path()) != -1) ||
                        $location.path().split('/')[1] == 'workspaces' ||
                        $location.path().split('/')[1] == 'err' ||
                        $location.path().split('/')[1] == 'register' ||
                        $location.path().split('/')[1] == 'try-me' ||
                        $location.path().split('/')[1] == 'forgot' ||
                        $location.path().split('/')[1] == 'reset' ||
                        $location.path().split('/')[1] == 'create' ||
                        $location.path().split('/')[1] == 'assume' ||
                        $location.path().split('/')[1] == 'share' ||
                        $location.path().split('/')[1] == 'invite' ||
                        $location.path().split('/')[1] == 'blog-post' ||
                        $location.path().split('/')[1] == 'blog-category' ||
                        $location.path().split('/')[1] == 'page') {

                        $timeout(checkDigest);

                        if (AuthService.isLoggedIn() &&
                            $location.path().split('/')[1] != 'err' &&
                            $location.path().split('/')[1] != 'workspaces' &&
                            $location.path().split('/')[1] != 'register' &&
                            $location.path().split('/')[1] != 'try-me' &&
                            $location.path().split('/')[1] != 'sso' &&
                            $location.path().split('/')[1] != 'register-complete' &&
                            $location.path().split('/')[1] != 'terms' &&
                            $location.path().split('/')[1] != 'terms-mobile' &&
                            $location.path().split('/')[1] != 'privacy' &&
                            $location.path().split('/')[1] != 'cookies' &&
                            $location.path().split('/')[1] != 'pricing' &&
                            $location.path().split('/')[1] != 'assume' &&
                            $location.path().split('/')[1] != 'forgot' &&
                            $location.path().split('/')[1] != 'reset' &&
                            $location.path().split('/')[1] != 'create' &&
                            $location.path().split('/')[1] != 'share' &&
                            $location.path().split('/')[1] != 'invite' &&
                            $location.path().split('/')[1] != 'blog' &&
                            $location.path().split('/')[1] != 'blog-post' &&
                            $location.path().split('/')[1] != 'blog-category') {


                            $location.path('/dashboard');
                        }

                    } else if ($location.path().split('/')[1] == 'login') {

                        $rootScope.renderComplete = true;

                        if (AuthService.isLoggedIn()) {
                            $location.path('/dashboard');
                        }

                    } else if (!AuthService.isLoggedIn()) {

                        window.localStorage.clear();

                        if(next.loginRequired !== undefined && next.loginRequired){
                            window.localStorage['path']=$location.path();
                            window.location = '/login';
                        }else{
                            window.location = '/';
                        }

                    } else if (path!==undefined) {
                        $location.path(path);
                        window.localStorage.removeItem('path');
                    }else{
                        $timeout(checkDigest);
                    }
                });

                $rootScope.$on('$routeChangeSuccess', function (event, next, current) {

                    var path = $location.path();
                    var queryString = '';
                    var referrer = '';

                    //Check if there is a query string
                    if(path.indexOf('?') !== -1) {

                        queryString = path.substring(path.indexOf('?'), path.length);
                    }

                    //Check if there is a referrer
                    if(typeof current != "undefined") {

                        referrer = $location.protocol() + '://' + $location.host() + '/#' + current.originalPath;
                    }

                    //If Segment has not been init'd yet
                    if (!analytics.invoked) {

                        enableSegment();

                        //Load Segment
                        if(typeof analytics.load !== "undefined") {

                            analytics.load(Config.get('SEGMENT_KEY'));
                        }
                    }

                    if (typeof analytics.page !== "undefined") {

                        //Track the page event
                        analytics.page({
                            path: path,
                            referrer: referrer,
                            search: queryString,
                            url: $location.absUrl()
                        });
                    }
                });

                //Remove loading overlay when digest is complete
                var checkDigest = function() {

                    var internalApiCalls = 0;

                    for(var i = 0; i < $http.pendingRequests.length; i++) {

                        if($http.pendingRequests[i].url.indexOf("onepulse") !== -1) {

                            internalApiCalls++;
                        }
                    }

                    if(internalApiCalls > 0) {
                        $timeout(checkDigest);
                    } else {

                        $rootScope.$broadcast('event:render_complete');
                        $rootScope.renderComplete = true;
                        $rootScope.$apply();
                    }
                    $('.main-wrapper').addClass('ag_ready');
                };

                $timeout(checkDigest);
            });
        };

        $rootScope.renderComplete = true;

        var host = $location.host();

        if (host.indexOf('.') >= 0) {

            $rootScope.subDomain = host.split('.')[0];
        }

        $rootScope.envWhiteLabel = Config.get('WHITE_LABEL');

        if($rootScope.subDomain !== 'www' &&
            $rootScope.subDomain !== 'uk' &&
            $rootScope.subDomain !== 'us' &&
            $rootScope.envWhiteLabel === null) {

            var data = {
                "sub_domain": $rootScope.subDomain
            };

            DataService.fetchMetaData('white_label', data).then(function(result) {

                if(typeof result.sub_domain !== "undefined") {

                    $rootScope.wlClient = result.sub_domain;
                    $rootScope.wlLogo = result.logo_image;
                    $rootScope.wlBar = result.bar_image;
                    $rootScope.wlHero = result.background_image;
                    $rootScope.wlBackgroundColor = result.background_color;
                    $rootScope.wlPrimaryColor = result.primary_theme_color;
                    $rootScope.wlAccentColor = result.accent_theme_color;
                    $rootScope.wlLogoDims = result.logo_size_px;
                    $rootScope.wlBarDims = result.bar_size_px;

                    //Update the theme if custom
                    var primaryThemeName = result.primary_theme_color !== null ? $rootScope.wlClient + 'Primary' : 'customPrimary';
                    var accentThemeName = result.accent_theme_color !== null ? $rootScope.wlClient + 'Accent' : 'customAccent';
                    var warnThemeName = result.warning_theme_color !== null ? $rootScope.wlClient + 'Warn' : 'customWarn';

                    if(result.primary_theme_color !== null) {

                        var primaryColors = computeColors(result.primary_theme_color);
                        var primaryPalette = createAjsPaletteForUse(primaryColors);
                        mdThemingProvider.definePalette(primaryThemeName, primaryPalette);

                        var newPrimary = primary.replace(/\$primary/g, result.primary_theme_color);

                        var stylePrimary = document.createElement('style');
                        stylePrimary.type = 'text/css';
                        stylePrimary.innerHTML = newPrimary;

                        document.getElementsByTagName('head')[0].appendChild(stylePrimary);
                    }

                    if(result.accent_theme_color !== null) {

                        var accentColors = computeColors(result.accent_theme_color);
                        var accentPalette = createAjsPaletteForUse(accentColors);
                        mdThemingProvider.definePalette(accentThemeName, accentPalette);

                        var newSecondary = secondary.replace(/\$secondary/g, result.accent_theme_color);

                        var styleSecondary = document.createElement('style');
                        styleSecondary.type = 'text/css';
                        styleSecondary.innerHTML = newSecondary;

                        document.getElementsByTagName('head')[0].appendChild(styleSecondary);
                    }

                    if(result.warning_theme_color !== null) {

                        var warnColors = computeColors(result.warning_theme_color);
                        var warnPalette = createAjsPaletteForUse(warnColors);
                        mdThemingProvider.definePalette(warnThemeName, warnPalette);

                        var newWarning = warning.replace(/\$warning/g, result.warning_theme_color);

                        var styleWarning = document.createElement('style');
                        styleWarning.type = 'text/css';
                        styleWarning.innerHTML = newWarning;

                        document.getElementsByTagName('head')[0].appendChild(styleWarning);
                    }

                    //update default theme
                    mdThemingProvider.theme('default')
                        .primaryPalette(primaryThemeName)
                        .accentPalette(accentThemeName)
                        .warnPalette(warnThemeName);

                } else {

                    $rootScope.wlClient = null;
                    $rootScope.wlBackgroundColor = "";
                    $rootScope.wlPrimaryColor = "";
                    $rootScope.wlLogo = "";
                    $rootScope.wlBar = "";
                    $rootScope.wlHero = "";
                }

                $mdTheming.generateTheme('default');

                init();
            });
        } else {

            if($rootScope.envWhiteLabel !== null) {

                $rootScope.wlClient = Config.get('WHITE_LABEL');
                $rootScope.wlHero = '/assets/img/clients/' + $rootScope.wlClient + '/hero.png';
                $rootScope.wlLogo = '/assets/img/clients/' + $rootScope.wlClient + '/logo.png';
                $rootScope.wlBar = '/assets/img/clients/' + $rootScope.wlClient + '/nav-logo.png';
                $rootScope.wlLogoDims = 300;
                $rootScope.wlBarDims = 100;
            } else {

                $rootScope.wlClient = null;
                $rootScope.wlLogo = "";
                $rootScope.wlBar = "";
                $rootScope.wlHero = "";
            }

            $rootScope.wlBackgroundColor = "";
            $rootScope.wlPrimaryColor = "";

            $mdTheming.generateTheme('default');

            init();
        }
    }]);
