onePulse.controller('SSOController', ['$scope', '$window', '$location', 'LoginService', 'AuthService', '$translate', 'TokenService',
    '$routeParams', 'AlertService', 'CONSTANTS', '$mdDialog', 'Config', '$rootScope', 'DataService',
    function ($scope, $window, $location, LoginService, AuthService, $translate, TokenService,
              $routeParams, AlertService, CONSTANTS, $mdDialog, Config, $rootScope, DataService) {

        $scope.displayFooter = false;
        $rootScope.submitSpinner = false;

        $scope.selectedWorkspace = null;
        $scope.logStraightIn = false;

        var urlParams = $location.search();

        $scope.redirect = urlParams.redirect;
        $scope.username = urlParams.username;
        $scope.hash = urlParams.hash;
        $scope.client = urlParams.client;
        $scope.ssoClientCode = urlParams.sso_client_code;

        //Determine name and version of user's browser
        $scope.browser = determineBrowser();

        TokenService.resetAccessToken();

        var translations;

        $translate(['alert.enter_username.title', 'alert.enter_username.text',
            'alert.enter_password.title', 'alert.enter_password.text']).then(function(result){
            translations = result;
        });

        //Set env toggle to current env
        $scope.dataRadio = {
            domain : Config.get('ENV')
        };

        $scope.$on("$locationChangeStart", function(event, next, current) {

            $rootScope.displayWorkspaceLoginOverlay.open = false;
        });

        $scope.$watch(function () {
            return AuthService.isLoggedIn()
        }, function (newVal, oldVal) {

            if (oldVal !== newVal) {
                $scope.isLoggedIn = newVal;
            }
        });

        var doLogin = function (direct) {

            $rootScope.submitSpinner = true;
            $rootScope.redirect = $scope.redirect;

            var completeLogin = function() {

                var data = {
                    "username": $scope.username,
                    "hash": $scope.hash,
                    "client": $scope.client,
                    "sso_client_code": $scope.ssoClientCode
                };

                LoginService.loginSSO(data, direct, function() {

                    $rootScope.submitSpinner = false;

                }).then(function () {

                    console.log('SSO CONTROLLER');
                    $scope.isLoggedIn = true;

                    if(typeof $scope.redirect !== "undefined") {

                        switch($scope.redirect) {

                            case 'pulse':
                                $window.location = '/new/pulse';
                                break;

                            case 'dashboard':
                            default:
                                $window.location = '/dashboard';
                        }
                    }
                });
            };

            //If the browser is compatible we login else we display warning confirmation modal before login
            if(LoginService.browserCompatibility($scope.browser)) {

                completeLogin();
            } else {

                //Controller for browser warning modal
                function browserWarningModalController($scope, $mdDialog) {

                    $scope.acceptDialog = function () {

                        $mdDialog.hide();
                        completeLogin();
                    };
                }

                browserWarningModalController.$inject = ['$scope', '$mdDialog'];

                //Call the browser warning modal
                $mdDialog.show({
                    templateUrl: 'app/views/elements/includes/browser-detection.html',
                    controller: browserWarningModalController,
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            }
        };

        var init = function() {

            //TODO: check url params exist
            doLogin(false);
        };

        init();

    }]);
