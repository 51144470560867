onePulse.controller('ShareInvitationController', ['$scope', '$location', 'DataService', '$window', '$routeParams',
    function ($scope, $location, DataService, $window, $routeParams) {

        $scope.referral = $routeParams.referral;

        $scope.registerReferral = function() {

            $location.path("/register/" + $scope.referral);
        };

        var init = function() {

            //Pass in referral code
            var data = {
                "referral_code": (typeof $scope.referral != "undefined" ? $scope.referral : null)
            };

            DataService.fetchMetaData('referral/code', data).then(function(result) {

                $scope.client = result.client;
            });
        };

        init();
}]);
