onePulse.factory('LoginService', ['$resource', '$http', '$q', '$rootScope', 'TokenService', 'CONSTANTS', 'Config',
    function($resource, $http, $q, $rootScope, TokenService, CONSTANTS, Config) {

    var SERVICE_URL = Config.get('OP_API');

    return {

        processLogin: function(response) {

            var decodedToken = TokenService.decodeToken(TokenService.getAccessToken());
            var obfsUserId = decodedToken.sub.split(':')[1];

            if(Config.get('ENV') !== 'BETA' && typeof analytics !== "undefined" && typeof analytics.identify !== "undefined") {

                analytics.identify(obfsUserId, {

                    name: response.user.data.brand_name.value
                }, function() {

                    $rootScope.$broadcast('event:auth-login');

                    $rootScope.$$phase || $rootScope.$apply();
                });
            } else {

                $rootScope.$broadcast('event:auth-login');

                $rootScope.$$phase || $rootScope.$apply();
            }
        },

        login:function($data, direct, errCb){

            var url = SERVICE_URL + 'login/client';

            var deferred = $q.defer();

            $http({
                method: 'POST',
                url:url ,
                data: $data,
                headers: {'Authorization': 'Bearer ' + TokenService.getAccessToken()}
            }).then(function successCallback(response) {

                TokenService.setAccessToken = response.data.access_token;

                if(response.data.user.data.workspaces.length > 1 && !direct) {

                    $rootScope.submitSpinner = false;
                    $rootScope.reducedUser = response.data.user;
                    $rootScope.displayWorkspaceLoginOverlay.open = true;
                } else {

                    var decodedToken = TokenService.decodeToken(TokenService.getAccessToken());
                    var obfsUserId = decodedToken.sub.split(':')[1];

                    if(typeof analytics !== "undefined" && typeof analytics.identify !== "undefined") {

                        analytics.identify(obfsUserId, {

                            name: response.data.user.data.brand_name.value
                        }, function() {

                            deferred.resolve(response.data);

                            $rootScope.$broadcast('event:auth-login');

                            $rootScope.$$phase || $rootScope.$apply();
                        });
                    } else {

                        deferred.resolve(response.data);

                        $rootScope.$broadcast('event:auth-login');

                        $rootScope.$$phase || $rootScope.$apply();
                    }
                }

            }, function errorCallback () {

                errCb();
            });

            return deferred.promise;
        },

        loginSSO:function(data, direct, errCb){

            var url = SERVICE_URL + 'login/sso';

            var deferred = $q.defer();

            $http({
                method: 'POST',
                url:url ,
                data: data,
                headers: {'Authorization': 'Bearer ' + TokenService.getAccessToken()}
            }).then(function successCallback(response) {

                console.log('LOGIN SERVICE');
                TokenService.setAccessToken = response.data.access_token;

                if(response.data.user.data.workspaces.length > 1 && !direct) {

                    $rootScope.submitSpinner = false;
                    $rootScope.reducedUser = response.data.user;
                    $rootScope.displayWorkspaceLoginOverlay.open = true;
                } else {

                    var decodedToken = TokenService.decodeToken(TokenService.getAccessToken());
                    var obfsUserId = decodedToken.sub.split(':')[1];

                    if(typeof analytics !== "undefined" && typeof analytics.identify !== "undefined") {

                        analytics.identify(obfsUserId, {

                            name: response.data.user.data.brand_name.value
                        }, function() {

                            deferred.resolve(response.data);

                            $rootScope.$broadcast('event:auth-login');

                            $rootScope.$$phase || $rootScope.$apply();
                        });
                    } else {

                        deferred.resolve(response.data);

                        $rootScope.$broadcast('event:auth-login');

                        $rootScope.$$phase || $rootScope.$apply();
                    }
                }

            }, function errorCallback () {

                errCb();
            });

            return deferred.promise;
        },

        assumeLogin:function(data, errCb){

            var url = SERVICE_URL + 'login/assume';

            var deferred = $q.defer();

            $http({
                method: 'POST',
                url:url ,
                data: data,
                headers: {'Authorization': 'Bearer ' + TokenService.getAccessToken()}
            }).then(function successCallback(response) {

                TokenService.setAccessToken = response.data.access_token;

                var decodedToken = TokenService.decodeToken(TokenService.getAccessToken());
                var obfsUserId = decodedToken.sub.split(':')[1];

                if(typeof analytics !== "undefined" && typeof analytics.identify !== "undefined") {

                    analytics.identify(obfsUserId, {

                        name: response.data.user.data.brand_name.value
                    }, function() {

                        deferred.resolve(response.data);

                        $rootScope.$broadcast('event:auth-login');

                        $rootScope.$$phase || $rootScope.$apply();
                    });
                } else {

                    deferred.resolve(response.data);

                    $rootScope.$broadcast('event:auth-login');

                    $rootScope.$$phase || $rootScope.$apply();
                }

            }, function errorCallback () {

                errCb();
            });

            return deferred.promise;
        },

        resetPasswordRequest: function($data, type) {

            var url = SERVICE_URL + 'meta/forgot/' + type;

            var deferred = $q.defer();

            $http({
                method: 'POST',
                url:url ,
                data: $data,
                headers: {'Authorization': 'Bearer ' + TokenService.getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

            });

            return deferred.promise;
        },

        resetPassword: function($data, token) {

            var url = SERVICE_URL + 'meta/forgot';

            var deferred = $q.defer();

            $http({
                method: 'POST',
                url:url ,
                data: $data,
                headers: {'Authorization': 'Bearer ' + token}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

            });

            return deferred.promise;
        },

        createPassword: function(data, token) {

            var url = SERVICE_URL + 'meta/create';

            var deferred = $q.defer();

            data.data_token = token;

            $http({
                method: 'POST',
                url:url ,
                data: data,
                headers: {"Authorization": "Bearer " + TokenService.getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

            });

            return deferred.promise;

        },

        acceptInvitation: function() {

            var url = SERVICE_URL + 'meta/accept';

            var deferred = $q.defer();

            $http({
                method: 'POST',
                url:url ,
                data: "",
                headers: {"Authorization": "Bearer " + TokenService.getAccessToken()}
            }).then(function successCallback(response) {

                deferred.resolve(response.data);

            });

            return deferred.promise;

        },

        browserCompatibility: function(browser) {

            for(var i = 0; i < CONSTANTS.SUPPORTED_BROWSERS.length; i++) {

                if(browser.name == CONSTANTS.SUPPORTED_BROWSERS[i].name) {

                    if(browser.version == CONSTANTS.SUPPORTED_BROWSERS[i].version ||
                       parseInt(browser.version) >= parseInt(CONSTANTS.SUPPORTED_BROWSERS[i].version) ||
                       CONSTANTS.SUPPORTED_BROWSERS[i].version == '*') {

                        return true;
                    }
                }
            }

            return false;
        }
    }
}]);
