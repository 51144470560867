onePulse.controller("PageController", [
  "$scope",
  "DataService",
  "AuthService",
  "$routeParams",
  "$mdDialog",
  "ForexService",
  "$interval",
  "ngDialog",
  "$window",
  "$templateCache",
  "$templateRequest",
  "$location",
  "ContentService",
  "$rootScope",
  "Config",
  "$timeout",
  function(
    $scope,
    DataService,
    AuthService,
    $routeParams,
    $mdDialog,
    ForexService,
    $interval,
    ngDialog,
    $window,
    $templateCache,
    $templateRequest,
    $location,
    ContentService,
    $rootScope,
    Config,
    $timeout
  ) {
    $scope.action = $routeParams.pagename;

    $scope.currency = null;
    $scope.toggleState = "USD";

    //    $scope.subscriptionOn = true;
    //    $scope.paygOn = false;

    //    $scope.subscriptionCallback = function(event) {
    //
    //        $scope.subscriptionOn = true;
    //        $scope.paygOn = !$scope.paygOn;
    //        event.preventDefault();
    //
    //    };

    //    $scope.paygCallback = function(event) {
    //
    //        $scope.paygOn = true;
    //        $scope.subscriptionOn = !$scope.subscriptionOn;
    //        event.preventDefault();
    //
    //    };

    $scope.productPage = {
      questionText: "",
      answerText1: "",
      answerText2: ""
    };

    $templateRequest("app/views/pages/clientOnBoarding.html").then(function(
      response
    ) {
      $templateCache.put("clientOnBoarding.html", response);
    });

    $scope.clientOnBoardingModal = function(ev) {
      $mdDialog.show({
        templateUrl: "clientOnBoarding.html",
        clickOutsideToClose: true,
        fullscreen: true,
        controller: "ClientOnBoardingController"
      });
    };

    $scope.fetchContent = function() {
      var contentType = $location.path().split("/")[1];

      //Exceptions to the cms naming convention
      contentType = contentType == "support" ? "faq" : contentType;
      contentType = contentType == "terms-mobile" ? "service" : contentType;

      ContentService.fetchContent(contentType).then(function(result) {
        $scope.content = result.content;
      });
    };

    //Only pull content for CMS supported pages
    if (
      [
        "/faq",
        "/demo",
        "/launch",
        "/terms",
        "/terms-mobile",
        "/privacy"
      ].indexOf($location.path()) != -1
    ) {
      $scope.fetchContent();
    }

    //Only pull subs, topups & features if on pricing page
    if (["/pricing"].indexOf($location.path()) != -1) {
      DataService.fetchMetaData("products/features").then(function(result) {
        var featuresTemp = [];
        var featuresKeys = [];

        for (var key in result) {
          if (typeof result[key].is_active != "undefined") {
            result[key].key.value = removeCharsAfterChar(
              ":",
              result[key].key.value
            );

            if (
              result[key].is_active.value == "1" &&
              featuresKeys.indexOf(result[key].key.value) == -1
            ) {
              result[key].display_order.value = parseInt(
                result[key].display_order.value
              );
              result[key].description.value = removeCharsAfterChar(
                ":",
                result[key].description.value
              );

              featuresKeys.push(result[key].key.value);
              featuresTemp.push(result[key]);
            }
          }
        }

        var featuresTempOrdered = featuresTemp.slice(0);

        featuresTempOrdered.sort(function(a, b) {
          return a.display_order.value - b.display_order.value;
        });

        $scope.features = featuresTempOrdered;

        DataService.fetchMetaData("subscriptions").then(function(result) {
          $scope.original = [];

          result.subscriptions.sort(function(a, b) {
            return a.data.display_order.value - b.data.display_order.value;
          });

          for (var i = 0; i < result.subscriptions.length; i++) {
            if (result.subscriptions[i].data.cost.value != "") {
              result.subscriptions[i].data.cost.value = thousandSeparator(
                [result.subscriptions[i].data.cost.value],
                true
              );
            }

            $scope.original[i] = {};

            $scope.original[i].display_price = angular.copy(
              result.subscriptions[i].data.display_price.value
            );
            $scope.original[i].equivalent_value = angular.copy(
              result.subscriptions[i].data.equivalent_value.value
            );
            $scope.original[i].response_value = angular.copy(
              result.subscriptions[i].data.response_value.value
            );
            $scope.original[i].cost = angular.copy(
              result.subscriptions[i].data.cost.value
            );

            var featuresTemp = [];
            var featuresKeys = [];

            for (var key in result.subscriptions[i].data.features) {
              if (
                typeof result.subscriptions[i].data.features[key].is_active !=
                "undefined"
              ) {
                if (
                  result.subscriptions[i].data.features[key].is_active.value ==
                    "1" &&
                  featuresKeys.indexOf(
                    result.subscriptions[i].data.features[key].key.value
                  ) == -1
                ) {
                  //Check description for meta data
                  if (
                    result.subscriptions[i].data.features[
                      key
                    ].description.value.indexOf(":") != -1
                  ) {
                    result.subscriptions[i].data.features[key].featureDetail = {
                      value: result.subscriptions[i].data.features[
                        key
                      ].description.value
                        .split(":")[1]
                        .trim()
                    };
                  }

                  //Check key for meta data - key takes precedence over description
                  if (
                    result.subscriptions[i].data.features[
                      key
                    ].key.value.indexOf(":") != -1
                  ) {
                    result.subscriptions[i].data.features[key].featureDetail = {
                      value: result.subscriptions[i].data.features[
                        key
                      ].key.value.split(":")[1]
                    };
                  }

                  result.subscriptions[i].data.features[key].blank = {
                    value: false
                  };

                  result.subscriptions[i].data.features[
                    key
                  ].key.value = removeCharsAfterChar(
                    ":",
                    result.subscriptions[i].data.features[key].key.value
                  );
                  result.subscriptions[i].data.features[
                    key
                  ].display_order.value = parseInt(
                    result.subscriptions[i].data.features[key].display_order
                      .value
                  );
                  result.subscriptions[i].data.features[
                    key
                  ].description.value = removeCharsAfterChar(
                    ":",
                    result.subscriptions[i].data.features[key].description.value
                  );

                  featuresKeys.push(
                    result.subscriptions[i].data.features[key].key.value
                  );
                  featuresTemp.push(result.subscriptions[i].data.features[key]);
                }
              }
            }

            var featuresTempOrdered = featuresTemp.slice(0);

            featuresTempOrdered.sort(function(a, b) {
              return a.display_order.value - b.display_order.value;
            });

            $scope.tempFeaturesSub = featuresTempOrdered;

            for (var k = 0; k < $scope.features.length; k++) {
              var existsInSubFeatures = false;
              var item = { blank: { value: true } };

              for (var l = 0; l < $scope.tempFeaturesSub.length; l++) {
                if (
                  typeof $scope.tempFeaturesSub[l].key != "undefined" &&
                  typeof $scope.features[k].key != "undefined"
                ) {
                  if (
                    $scope.tempFeaturesSub[l].key.value ==
                    $scope.features[k].key.value
                  ) {
                    existsInSubFeatures = true;
                  }
                }
              }

              if (!existsInSubFeatures) {
                $scope.tempFeaturesSub.splice(k, 0, item);
              }
            }

            result.subscriptions[i].data.features = $scope.tempFeaturesSub;
          }

          $scope.subscriptions = result.subscriptions;

          // ForexService.getLocalCurrency().then(function(result) {
          //
          //     $scope.currency = result;
          //
          //     ForexService.getExchangeRate("USD", result.code);
          // });

          $scope.currency = ForexService.setCurrency("GB");

          ForexService.getExchangeRate("USD", $scope.currency.code);
        });
      });

      DataService.fetchMetaData("topups").then(function(result) {
        for (var i = 0; i < result.topups.length; i++) {
          if (result.topups[i].data.cost.value != "") {
            result.topups[i].data.cost.value = thousandSeparator(
              [result.topups[i].data.cost.value],
              true
            );
          }
        }

        $scope.topups = result.topups;
      });
    }

    $scope.toggleCurrency = function(currencyToCode) {
      if (currencyToCode != $scope.toggleState) {
        $rootScope.currency =
          currencyToCode == "USD" ? "$" : $scope.currency.symbol;

        $scope.toggleState = currencyToCode;

        for (var i = 0; i < $scope.subscriptions.length; i++) {
          $scope.subscriptions[i].data.display_price.value =
            currencyToCode == "USD"
              ? parseFloat($scope.original[i].display_price)
              : (
                  parseFloat($scope.subscriptions[i].data.display_price.value) *
                  $scope.currency.baseMultiplier
                ).toFixed(0);
          $scope.subscriptions[i].data.equivalent_value.value =
            currencyToCode == "USD"
              ? parseFloat($scope.original[i].equivalent_value)
              : (
                  parseFloat(
                    $scope.subscriptions[i].data.equivalent_value.value
                  ) * $scope.currency.baseMultiplier
                ).toFixed(2);
          $scope.subscriptions[i].data.response_value.value =
            currencyToCode == "USD"
              ? parseFloat($scope.original[i].response_value)
              : (
                  parseFloat(
                    $scope.subscriptions[i].data.response_value.value
                  ) * $scope.currency.baseMultiplier
                ).toFixed(2);
          $scope.subscriptions[i].data.cost.value =
            currencyToCode == "USD"
              ? parseFloat($scope.original[i].cost)
              : (
                  parseFloat($scope.subscriptions[i].data.cost.value) *
                  $scope.currency.baseMultiplier
                ).toFixed(0);
        }
      }
    };

    $scope.$on("$locationChangeStart", function(event, next, current) {
      $rootScope.currency = Config.get("I18N_CURRENCY");
    });

    //Clear local storage when ClientOnBoarding modal is closed
    $scope.$on("ngDialog.closed", function() {
      $window.localStorage.clear();
    });

    //Metrics odometers
    //waypoint.destroy()

    switch($location.path()) {

      case '/':

        $timeout(function() {

          var metrics_el = document.getElementById("metrics-waypoint");

          if (metrics_el !== null) {

            var el1 = document.querySelector("#home-metrics-time-odometer");
            var el2 = document.querySelector("#home-metrics-scale-odometer");
            var el3 = document.querySelector("#home-metrics-speed-panels-odometer");
            var el4 = document.querySelector("#home-metrics-speed-data-odometer");

            if(el1 !== null && el2 !== null && el3 !== null && el4 !== null) {

              var waypoint1 = new Waypoint({
                element: metrics_el,
                handler: function(direction) {

                  //average time for 500 responses
                  od1 = new Odometer({
                    el: el1,
                    value: 0,
                    format: "(,ddd)"
                  });

                  od1.update(10);

                  //Questions answered by community per month
                  od2 = new Odometer({
                    el: el2,
                    value: 0,
                    format: "(,ddd)"
                  });

                  od2.update(2500000);

                  //consumer panels
                  od3 = new Odometer({
                    el: el3,
                    value: 0,
                    format: "(,ddd)"
                  });

                  od3.update(20);

                  //data points
                  od4 = new Odometer({
                    el: el4,
                    value: 0,
                    format: "(,ddd)"
                  });

                  od4.update(60);
                },
                offset: "bottom-in-view"
              });
            }
          }
        }, 1000);

        break;

      case '/community':

        $timeout(function() {

          var community_metrics_el = document.getElementById("community-result-waypoint");

          if (community_metrics_el !== null) {

            var el6 = document.querySelector("#community-metrics-data");

            if(el6 !== null) {

              var waypoint2 = new Waypoint({
                element: community_metrics_el,
                handler: function(direction) {

                  //community page metrics
                  od6 = new Odometer({
                    el: el6,
                    value: 0,
                    format: "(,ddd)"
                  });

                  od6.update(2500000);
                },
                offset: "bottom-in-view"
              });
            }
          }
        }, 1000);

        break;

      case '/product':

        $timeout(function() {

          var product_el = document.getElementById("product-question-waypoint");
          var product_result_el = document.getElementById("product-result-waypoint");

          if (product_el !== null) {
            //Question/answer texts - product page
            var questionText =
                "What is the answer to life, the universe and everything?";
            var answerText1 = "Life is like a box of chocolates...";
            var answerText2 = "42";

            var waypoint3 = new Waypoint({
              element: product_el,
              handler: function(direction) {
                $interval(function() {
                  if ($scope.productPage.questionText !== questionText) {
                    $scope.productPage.questionText +=
                        questionText[$scope.productPage.questionText.length];
                  } else if ($scope.productPage.answerText1 !== answerText1) {
                    $scope.productPage.answerText1 +=
                        answerText1[$scope.productPage.answerText1.length];
                  } else if ($scope.productPage.answerText2 !== answerText2) {
                    $scope.productPage.answerText2 +=
                        answerText2[$scope.productPage.answerText2.length];
                  }
                }, 150); //Delay between characters printing onscreen
              },
              offset: "bottom-in-view"
            });
          }

          if (product_result_el !== null) {

            var el5 = document.querySelector("#product-metrics-results");

            if(el5 !== null) {

              var waypoint4 = new Waypoint({
                element: product_result_el,
                handler: function(direction) {
                  //product page results
                  od5 = new Odometer({
                    el: el5,
                    value: 0,
                    format: "(,ddd)"
                  });

                  od5.update(500);
                },
                offset: "bottom-in-view"
              });
            }
          }
        }, 1000);

        break;
    }
  }
]);
