/**
 * Created by Barry on 15/03/17.
 *
 * Important to note: Panel in this context refers to the following models in other contexts:
 *  DB: ClientGroup / API: ClientGroup (community for share API calls, group for other API calls) / Web(UI): Panel
 */
onePulse.controller('SharePanelController', ['$scope', '$routeParams', '$window', '$timeout', 'DataService', 'CONSTANTS', '$translate', 'AlertService', 'SocialShareService',
    function ($scope, $routeParams, $window, $timeout, DataService, CONSTANTS, $translate, AlertService, SocialShareService) {

        $scope.groupsLoaded = false;
        $scope.deeplinkOverlay = {"open": false};
        $scope.deeplinkDesktopOverlay = {"open": false};

        //Get device type - mobile/desktop
        $scope.deviceType = determineDeviceType(getViewportDimensions());

        //Get Mobile OS. 'unknown' for desktop
        $scope.mobileOS = getMobileOperatingSystem();

        //Config var for carousel - number of slides to show (default: desktop = 4 / mobile = 3)
        $scope.slidesToShow = $scope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP ? 4 : 3;

        //Configure carousel for other groups (enabled is set to true only after API call for groups is complete)
        $scope.slickConfigGroups = {
            enabled: false,
            autoplay: false,
            draggable: true,
            autoplaySpeed: 3000,
            slidesToShow: $scope.slidesToShow,
            slidesToScroll: $scope.slidesToShow,
            method: {},
            event: {
                beforeChange: function (event, slick, currentSlide, nextSlide) {
                },
                afterChange: function (event, slick, currentSlide, nextSlide) {
                }
            }
        };

        //Get route params
        var slug = $routeParams.slug;
        var deepLink = (typeof $routeParams.deeplink != "undefined");

        var handleAppRedirect = function(code) {

            var mobileOS = getMobileOperatingSystem();

            //1. if user has app installed, then they would be redirected to open the app to specified screen
            //2. if user doesn't have app installed, then their browser wouldn't recognize the URL scheme
            //and app wouldn't open since it's not installed. In 1 second (1000 milliseconds) user is redirected
            //to download app from app store.
            switch(mobileOS) {

                case 'Android': //Start Android app and join panel

                    //Deep link URL for existing users with app already installed on their device -->
                    $window.location = CONSTANTS.MOBILE_APP_SCHEME + "c/" + code;

                    // <!-- Download URL for new users to download the app -->
                    $timeout("window.location = '" + CONSTANTS.ANDROID_APP + "';", 1000);

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;

                    break;
                case 'iOS': //Start iOS app and join panel

                    //Deep link URL for existing users with app already installed on their device -->
                    $window.location = CONSTANTS.MOBILE_APP_SCHEME + "c/" + code;

                    // <!-- Download URL for new users to download the app -->
                    $timeout("window.location = '" + CONSTANTS.IOS_APP + "';", 1000);

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;

                    break;
                    default: //Not mobile, show app info block

                    $scope.deeplinkDesktopOverlay.open = !$scope.deeplinkDesktopOverlay.open;
                    break;
            }
        };

        $scope.openOP = function() {

            switch($scope.mobileOS) {

                case 'Android':

                    $window.location = CONSTANTS.MOBILE_APP_SCHEME;

                    $timeout("window.location = '" + CONSTANTS.ANDROID_APP + "';", 1000);

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                case 'iOS':

                    $window.location = CONSTANTS.MOBILE_APP_SCHEME;

                    $timeout("window.location = '" + CONSTANTS.IOS_APP + "';", 1000);

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                default:

                    $scope.deeplinkDesktopOverlay.open = !$scope.deeplinkDesktopOverlay.open;
                    break;
            }
        };

        //Link to iOS/Android store
        $scope.storeLink = function() {

            switch($scope.mobileOS) {

                case 'Android':
                    $window.open(CONSTANTS.ANDROID_APP, '_blank');
                    break;
                case 'iOS':
                    $window.open(CONSTANTS.IOS_APP, '_blank');
                    break;
                default:
                    break;
            }
        };

        $scope.firstCheck = function($event) {

            switch($scope.mobileOS) {

                case 'Android': //Start Android app and join pulse

                    $window.location = CONSTANTS.MOBILE_APP_SCHEME + "c/" + $scope.group.code.value;

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                case 'iOS':

                    $scope.deeplinkOverlay.open = !$scope.deeplinkOverlay.open;
                    break;
                default: //Not mobile, show app info block

                    $scope.deeplinkDesktopOverlay.open = !$scope.deeplinkDesktopOverlay.open;
                    break;
            }
        };
        
        $scope.notifyClipboard = function() {

            var translations;
            $translate(['alert.invitation_code_copied.title', 'alert.invitation_code_copied.text']).then(function(result) {
                translations = result;
    
                AlertService.success(
                    translations['alert.invitation_code_copied.title'],
                    translations['alert.invitation_code_copied.text']
                )
            });
        };

        $scope.init = function () {

            //Pull panel data with slug and present to user
            DataService.fetchShareData('community', slug, null).then(function(result) {

                $scope.group = result.community;

                var mobileOS = getMobileOperatingSystem();

                //If the referring url is a deep link and the share is being viewed on a mobile device, we process accordingly
                if(deepLink && (mobileOS == "Android" || mobileOS == "iOS")) {

                    handleAppRedirect($scope.group.code.value);
                }
            });

            //Pull all other public groups
            DataService.fetchShareData('communities', null, null).then(function(result) {

                $scope.groups = result.communities;

                //Enable carousel
                $scope.groupsLoaded = true;
                $scope.slickConfigGroups.enabled = true;
            });
        };

        $scope.init();

        $scope.join = function($event, group) {

            handleAppRedirect(group.code.value);
        };

        $scope.share = function($event, group, provider) {

            SocialShareService.share("community", group, provider);
        }
    }]);