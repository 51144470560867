/**
 * Created by mike on 26/01/16.
 */
onePulse.directive('pulseForm', ['FormService', function(FormService) {

    var $service = FormService;

    linkFunction = function($scope, element, attributes){

        /*
         Put the question data through the FormService and get all the rules and fields
         */
        $scope.form = $service.parseData($scope.pulse.data);

        //the selected option
        //formatOption = findSelectedOption($scope.question.data.format.options)

        //slugify the text so it is a suitable file name
        //questionFormat = slug(formatOption.option.text);

        //use the original human text for the view
        //$scope.format = formatOption.option.text;

        //use slugified questionForma to get teh template file for this question type
        /*TemplateService.fetch('/app/views/elements/forms/questions/'+questionFormat+'.html').then(function(t){

            //when template retrieved add it to the dom
            element.html(t).show();

            //recompile the dom
            $compile(element.contents())($scope);
        });*/

        // for algeria default is fr

        // $scope.promptLanguage = function (language) {
        //     console.log("Setting Language!" + language);
        //     swal({
        //         title: "Please select the language",
        //         text: " <a class='btn btn-danger' ng-click=\"setLanguage()\"> Arabic </a>",
        //         html: true,
        //         showConfirmButton: true,
        //         confirmButtonText: "Dismiss",
        //         allowEscapeKey: true,
        //     });
        // }

        // $scope.$watch('arabicLanguage', function() {

        // });

    }

    return {
        link:linkFunction,
        templateUrl:'/app/views/elements/forms/pulse.html',

    }
}]);
