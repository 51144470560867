
onePulse.directive('chartImage', ['$filter', '$compile', '$sce', 'DataService', 'googleChartApiPromise', '$mdDialog', '$location', '$routeParams', '$http',
    function($filter, $compile, $sce, DataService, googleChartApiPromise, $mdDialog, $location, $routeParams, $http) {

        linkFunction = function($scope) {

            var jsonUrl = $routeParams.url;

            //Test URL
            //var jsonUrl = "https://s3.amazonaws.com/op.uploads/data/qvVm597pmQjy.json";

            //Pull question data from url
            $http({
                method: 'GET',
                url: jsonUrl

            }).then(function(response) {

                var answers = [];

                for(var k in response.data.id_map) {

                    answers.push(k);
                }

                $scope.validAnswers = answers;

                $scope.validAnswerCount = answers.length;

                /*For the overlay directive*/
                $scope.labels = {};

                $scope.annotations = {};

                $scope.filteredAnnotations = {};

                /*
                 Filtered 3 series: Teal filtered value,Dark Grey total, light grey shadow bar (might be a better way to create a shadow than the extra series)
                 */
                $scope.filteredSeries = {
                    0: {color: '#50E3C2'},
                    1: {color: '#4a4a4a'},
                    2: {color: '#f7f7f7'}
                };

                /*
                 Unfiltered 2 series: Dark Grey total, light grey shadow bar
                 */
                $scope.unfilteredSeries = {
                    0: {color: '#4a4a4a'},
                    1: {color: '#f7f7f7'}
                };

                $scope.animation = {
                    duration: 800,
                    easing: 'in'
                };

                /*
                 To style the pop up data table
                 */
                $scope.tableClassNames = {
                    headerRow: "data-table-header",
                    tableRow: "data-table-row",
                    oddTableRow: "data-table-odd-row",
                    selectedTableRow: "data-table-selected-row",
                    hoverTableRow: "data-table-hover-row"
                };

                buildChart(response);
            });

            var buildChart = function (result) {

                $scope.filters = result.data.filters;
                $scope.result = result.data;

                //API is incorrectly returning 1 extra response - we need to subtract by 1 to correct this
                $scope.result.question.total_responses--;

                //If Drag and drop, we need to divide total responses by valid questions
                if($scope.result.question.format == 'Drag & Drop') {

                    $scope.result.question.total_responses = $scope.result.question.total_responses / $scope.validAnswerCount;
                }

                googleChartApiPromise.then(function () {

                    $scope.dataTable = new google.visualization.arrayToDataTable(result.data.data_table);

                    /*
                     Do Raw Data Table
                     */
                    $scope.table = {};

                    $scope.table.type = "Table";

                    $scope.table.data = $scope.dataTable;

                    $scope.table.options = {
                        cssClassNames: $scope.tableClassNames
                    };


                    /*
                     Pops up grid of data displaying contents of $scope.table.data
                     */

                    $scope.showDataDialog = function ($event) {

                        var parentEl = angular.element(document.body);
                        $mdDialog.show({
                            parent: parentEl,
                            targetEvent: $event,
                            templateUrl: 'app/views/elements/includes/data-table.html',
                            locals: {
                                table: $scope.table
                            },
                            clickOutsideToClose: true,
                            scope: $scope,
                            preserveScope: true,
                            fullscreen: true
                        });

                    };

                    $scope.chartId = randomString(8);

                    /*
                     The visualisation data view is used for visible and printable chart
                     */
                    $scope.dataView = createVisualisation($scope.dataTable);

                    /*
                     Do Printable Bar Chart
                     */
                    $scope.printBarChart = {};

                    $scope.printBarChart.type = "BarChart";

                    $scope.printBarChart.options = {
                        fontSize: '16',
                        width: '800',
                        chartArea: {
                            width: '80%',
                            left: '17%'
                        },
                        title: $scope.result.question.text,
                        legend: {
                            position: 'none'
                        },
                        tooltip: {
                            trigger: 'none'
                        },
                        focusTarget: 'category',
                        bar: {
                            groupWidth: "8"
                        },
                        isStacked: true,
                        series: {
                            0: {color: '#4a4a4a'},
                            1: {color: '#f7f7f7'}
                        }
                    };

                    $scope.printBarChart.data = $scope.dataView;

                    $scope.printBarChartReadyHandler = function (chartWrapper) {

                        var my_div = document.getElementById('chart_image_div');
                        var my_chart = chartWrapper.getChart().getImageURI();

                        my_div.innerHTML = '<img id="chart_image" src="' + my_chart + '">';

                        if (typeof window.callPhantom === 'function') {

                            window.callPhantom({
                                image: my_chart
                            });
                        }
                    }

                }, undefined);
            };

            var createVisualisation = function (data, unfilteredTable) {

                /*
                 Step 1 is to limit the columns and group responses as a count
                 */
                if($scope.result.question.format != 'Drag & Drop') {

                    var step1 = google.visualization.data.group(data,
                        [
                            {
                                column: 'Answer',
                                label: 'Answer',
                                type: 'string'
                            }
                        ],
                        [
                            {
                                column: 'Answer ID',
                                label: 'Total Responses',
                                aggregation: google.visualization.data.count,
                                type: 'number'
                            }
                        ]
                    );

                    /*
                     Step 2 is to convert the total responses into a percentage
                     */
                    var step2 = google.visualization.data.group(step1,
                        [
                            {
                                column: 'Answer',
                                label: 'Answer',
                                type: 'string'
                            }
                        ],
                        [
                            {
                                column: 'Total Responses',
                                label: 'Total Responses',
                                aggregation: function (value) {
                                    return value[0] > 0 ? parseFloat(((value[0] / $scope.result.question.total_responses) * 100).toFixed(2)) : 0.00
                                },
                                type: 'number'
                            }
                        ]
                    );

                    $scope.test = {};

                    $scope.test.type = 'Table';

                    $scope.test.data = step2;

                    /*
                     Compare the contents of the step2 table with the number of expected answers

                     If there is fewer rows than answers then some answers are 0% and should be added to the table at the correct index.

                     This should keep the correct number of bars on the chart in a consistent order
                     */
                    if (step2.getNumberOfRows() < $scope.validAnswerCount) {

                        angular.forEach($scope.validAnswers, function (a, i) {

                            if (i <= step2.getNumberOfRows()) {

                                if (!searchData(step2, a.data.text.value)) {

                                    step2.insertRows(i, [[a.data.text.value, 0.001]]);
                                }

                            } else {
                                /*No rows just adding everything*/
                                step2.insertRows(i, [[a.data.text.value, 0.001]]);
                            }
                        })
                    }

                    /*
                     Find the maximum value rounded to 100 (this will alwayss be 100 if we are dealing with %)

                     This has been kept incase we also want an option to display results as a count
                     */
                    var max = Math.ceil(step2.getColumnRange(1).max / 100) * 100;

                    /*
                     Step 3 adds the shadow series. This should be max - value
                     */
                    var step3 = google.visualization.data.group(step2,
                        [
                            {
                                column: 'Answer',
                                label: 'Answer',
                                type: 'string'
                            },
                            {
                                column: 'Total Responses',
                                label: 'Total Responses',
                                type: 'number'
                            }
                        ],
                        [
                            {
                                column: 'Total Responses',
                                label: 'Shadow',
                                aggregation: function (value) {
                                    return value[0] ? max - value[0] : max;
                                },
                                type: 'number'
                            }
                        ]
                    );

                    var step4 = new google.visualization.DataView(step3);

                    for (var t = 0; t < step4.getNumberOfRows(); t++) {

                        var val = roundNumbers(step4.getValue(t, 1), 2);
                        $scope.annotations[t] = val > 0.001 ? val : 0.00;

                        $scope.labels[t] = step4.getValue(t, 0);
                    }

                    if (unfilteredTable) {

                        step4.setColumns([0, 1,
                            {
                                calc: function (table, row) {
                                    var n = unfilteredTable.getValue(row, 1) - table.getValue(row, 1);
                                    return n > 0 ? roundNumbers(n, 2) : 0.001;
                                },
                                type: 'number'
                            },
                            {
                                calc: function (table, row) {
                                    return unfilteredTable.getValue(row, 2);
                                },
                                type: 'number'
                            }
                        ]);

                        for (var t = 0; t < step4.getNumberOfRows(); t++) {

                            var val = roundNumbers(step4.getValue(t, 2) + step4.getValue(t, 1), 2);
                            $scope.annotations[t] = val > 0.001 ? val : 0.00;

                            var fval = step4.getValue(t, 1);
                            $scope.filteredAnnotations[t] = fval > 0.001 ? fval : 0.00;
                        }
                    }

                    return step4;

                } else {

                    var step1 = google.visualization.data.group(data,
                        [
                            {
                                column: 'Answer',
                                label: 'Answer',
                                type: 'string'
                            }
                        ],
                        [
                            {
                                column: 'Value',
                                label: 'Total Responses',
                                aggregation: function (value) {

                                    var rankingAverage = 0;

                                    for (var i = 0; i < value.length; i++) {

                                        for (var j = 0; j < $scope.validAnswerCount; j++) {

                                            if (value[i] == j) {

                                                rankingAverage += (j + 1);
                                            }
                                        }
                                    }

                                    rankingAverage = rankingAverage / ($scope.result.question.total_responses);

                                    //Round to 2 decimal places
                                    return Math.round(rankingAverage * 100) / 100;
                                },
                                type: 'number'
                            }
                        ]
                    );

                    if (step1.getNumberOfRows() < $scope.validAnswerCount) {

                        angular.forEach($scope.validAnswers, function (a, i) {

                            if (i <= step1.getNumberOfRows()) {

                                if (!searchData(step1, a.data.text.value)) {

                                    step1.insertRows(i, [[a.data.text.value, 0.001]]);
                                }

                            } else {
                                /*No rows just adding everything*/
                                step1.insertRows(i, [[a.data.text.value, 0.001]]);
                            }
                        })
                    }

                    step1.sort([{column: 1, desc: false}]);

                    for (var t = 0; t < step1.getNumberOfRows(); t++) {

                        var val = roundNumbers(step1.getValue(t, 1), 2);
                        $scope.annotations[t] = val > 0.001 ? val : 0.00;

                        $scope.labels[t] = step1.getValue(t, 0);
                    }

                    return new google.visualization.DataView(step1);
                }


            };

            /*
             Close data table pop up
             */
            $scope.closeDialog = function ($event) {
                $mdDialog.hide();
            }
        };

        /*
         Functions
         */
        var searchData = function(data,value){

            for(var e=0; e< data.getNumberOfRows(); e++){
                if(data.getValue(e,0) == value){
                    return true;
                }
            }

            return false;
        };

        var roundNumbers = function(number,decimals){
            return parseFloat((number).toFixed(decimals))
        };

        var randomString = function(length) {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for(var i = 0; i < length; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        };

        return {
            link:linkFunction,
            restrict: 'C'
        }
    }]);
