/**
 * Created by Barry on 23/08/2018.
 *
 * Directive for displaying the standard OnePulse Register form
 */
onePulse.directive('onepulseRegister', [function() {

        linkFunction = function($scope, element, attributes) {

        };

        return {
            restrict: 'E',
            priority: 0,
            replace: false,
            scope: false,
            link:linkFunction,
            templateUrl:'/app/views/elements/registers/onepulseRegister.html'
        }
    }]);
