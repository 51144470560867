/**
 * Created by Barry on 2016/10/26.
 *
 * Service handling feature locking based on user rights
 */
onePulse.factory('FeatureService', ['DataService', 'TokenService', '$mdDialog', '$rootScope', 'ngDialog', '$window',
    function(DataService, TokenService, $mdDialog, $rootScope, ngDialog, $window) {

    return {

        initFeatures: function(features) {

            var access_token;
            var payload;

            var accessToken = function(){

                access_token = TokenService.getAccessToken();
                return access_token;
            };

            var getPayload = function(){

                return angular.fromJson(decodeBase64(accessToken().split('.')[1]));
            };

            if(payload = getPayload()){

                var payloadFeatures =  payload.aud.features
            }

            for (var index in features) {

                if(typeof payloadFeatures != "undefined") {

                    for (var j = 0; j < payloadFeatures.length; j++) {

                        if (typeof features[index].id != "undefined") {

                            if (payloadFeatures[j].id == features[index].id.value) {

                                payloadFeatures[j].description = features[index].description.value;
                                payloadFeatures[j].key = features[index].key.value;
                            }
                        }
                    }
                }
            }

            $window.localStorage['features'] = JSON.stringify(payloadFeatures);
        },

        getFeatures: function() {

            return angular.fromJson($window.localStorage['features']);
        },

        //Returns the meta data(int) after the : in an active feature's key
        checkMeta: function(feature) {

            var features = angular.fromJson($window.localStorage['features']);
            var keyArray = null;

            var metaCount = 0;

            if(typeof features != "undefined") {

                for(var i = 0; i < features.length; i++) {

                    if(features[i].on) {

                        if(typeof features[i].key != "undefined") {

                            keyArray = features[i].key.split(':');

                            if(keyArray[0] == feature) {

                                metaCount = keyArray[1];
                            }
                        }
                    }
                }
            }

            return metaCount;
        },

        allowed: function(feature, showModal) {

            var features = angular.fromJson($window.localStorage['features']);
            var keyArray = null;

            //Check if the feature is on for this user
            for(var i = 0; i < features.length; i++) {

                keyArray = features[i].key.split(':');

                if(keyArray[0] == feature) {

                    if(features[i].on) {

                        $rootScope.currentLockedFeature = null;
                        return true;
                    }
                }
            }

            $rootScope.currentLockedFeature = feature;

            if(showModal) {

                ngDialog.open({
                    template: 'app/views/elements/includes/plan-subscription-upgrade.html',
                    className: 'ngdialog-theme-default feature-locking-modal md-dialog-fullscreen subs-dialog',
                    showClose: false,
                    controller: 'UserController'
                });
            } else {

                return false;
            }
        },

        showUpgradeModal: function(feature) {

            $rootScope.currentLockedFeature = (typeof feature !== "undefined" ? feature : false);

            ngDialog.open({
                template: 'app/views/elements/includes/plan-subscription-upgrade.html',
                className: 'ngdialog-theme-default feature-locking-modal md-dialog-fullscreen subs-dialog',
                showClose: false,
                controller: 'UserController'
            });
        }
    }
}]);