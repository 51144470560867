/**
 * Created by Barry on 2016/12/02.
 */
onePulse.controller('FilterController', ['$scope', '$rootScope', '$window', 'DataService', '$timeout', '$q',
    '$mdDialog', 'AlertService', 'FeatureService', 'CONSTANTS', '$translate',
    function ($scope, $rootScope, $window, DataService, $timeout, $q,
              $mdDialog, AlertService, FeatureService, CONSTANTS, $translate) {

        $scope.user = DataService.grab('user');

        $scope.unlocked = true;
        $scope.loaded = false;
        $scope.savedFiltersReady = false;
        $scope.categoriesReady = true;
        $scope.loadingParticipants = false;
        $scope.searchText = "";

        $scope.panel = null;
        $scope.demographics = null;
        $scope.regions = null;

        $scope.panelSelectListItems = [];
        $scope.existingPanelNames = [];
        $scope.openArray = [];

        //Controls for region selector
        $scope.openRegions = false;
        $scope.showRegions = false;

        $scope.applyRegionSelection = false;

        $scope.selectedRegionsCount = 0;
        $scope.selectedRegionsString = "";
        $scope.firstSelectedRegion = null;

        $scope.selectedDemsCount = 0;

        //Potential members - affected by changing demographic filters
        $scope.maxParticipants = $rootScope.currentPulse.maxParticipants;

        //Toggle the demographics category checkbox to select/deselect all demographics in that category
        $scope.toggleSelectAll = function($event, demographic) {

            $event.preventDefault();
            $event.stopPropagation();

            $scope.selectedDemsCount = 0;

            for(var i = 0; i < $scope.demographics.length; i++) {

                if($scope.isSelected($scope.demographics[i])) {

                    $scope.selectedDemsCount++;
                }
            }

            //If the category has a single demo selected, we deselect all else we select all
            if($scope.isSelected(demographic)) {

                for (var i = 0; i < demographic.values.options.length; i++) {

                    demographic.values.options[i].option.selected = false;
                }
            } else {

                //Add dem category only if not exceeded max allowed dem count
                if($scope.selectedDemsCount < CONSTANTS.MAX_ALLOWED_DEMOGRAPHICS) {

                    for (var i = 0; i < demographic.values.options.length; i++) {

                        demographic.values.options[i].option.selected = true;
                    }

                } else {

                    var translations;
                    $translate(['alert.exceeded_max_dems.title', 'alert.exceeded_max_dems.text']).then(function (result) {
                        translations = result;

                        AlertService.warning(
                            translations['alert.exceeded_max_dems.title'],
                            translations['alert.exceeded_max_dems.text']
                        )
                    });
                }
            }

            $scope.toggleDemographicValue(demographic, null);

        };

        $scope.toggleOpenDemographic = function($event, demographic) {

            $event.preventDefault();

            $scope.openArray[demographic.id] = !$scope.openArray[demographic.id];
            $timeout(function() {
                    $window.dispatchEvent(new Event("resize"));
                }, 500);
        };

        //Check that at least 1 region has been selected. If true, update the pulse
        $scope.validateRegionSelection = function() {

            var atLeastOneSelected = false;

            $scope.selectedRegionsCount = 0;
            $scope.selectedRegionsString = "";
            $scope.firstSelectedRegion = null;

            for(var i = 0; i < $scope.regions.options.length; i++) {

                if($scope.regions.options[i].option.selected == true) {

                    atLeastOneSelected = true;

                    $scope.selectedRegionsCount++;
                    $scope.selectedRegionsString = $scope.selectedRegionsString + $scope.regions.options[i].option.text + ", ";

                    if($scope.firstSelectedRegion == null) {

                        $scope.firstSelectedRegion = $scope.regions.options[i];
                    }
                }
            }

            //Remove trailing comma and space
            $scope.selectedRegionsString = $scope.selectedRegionsString.substring(0, $scope.selectedRegionsString.length - 2);

            if($scope.originalSelectedRegionsString != $scope.selectedRegionsString) {

                if(atLeastOneSelected) {

                    $scope.loadingParticipants = true;
                    $scope.applyRegionSelection = true;

                    $scope.originalSelectedRegionsString = angular.copy($scope.selectedRegionsString);

                    $rootScope.currentPulse.data.regions = $scope.regions;

                    for(var i = 0; i < $scope.currentPulse.data.demographics.length; i++) {

                        var keepDem = false;

                        for(var k = 0; k < $scope.currentPulse.data.demographics[i].regions.length; k++) {

                            if($scope.currentPulse.data.demographics[i].regions[k].code == CONSTANTS.INTERNATIONAL_REGION_CODE) {

                                keepDem = true;
                            }
                        }

                        if(keepDem) {

                            continue;
                        }

                        for(var j = 0; j < $scope.currentPulse.data.demographics[i].values.options.length; j++) {

                            $scope.currentPulse.data.demographics[i].values.options[j].option.selected = false;
                        }
                    }

                    DataService.submit('pulse', $rootScope.currentPulse).then(function (result) {

                        $rootScope.currentPulse = result.pulse;

                        $scope.openRegions = false;
                        $scope.applyRegionSelection = false;

                        $scope.init();

                        $scope.populatePotentialParticipants();
                    });

                } else {

                    var translations;
                    $translate(['alert.must_select_at_least_one_region.title', 'alert.must_select_at_least_one_region.text']).then(function (result) {
                        translations = result;

                        AlertService.warning(
                            translations['alert.must_select_at_least_one_region.title'],
                            translations['alert.must_select_at_least_one_region.text']
                        )
                    });
                }
            } else {

                $scope.openRegions = false;
            }
        };

        //Save current region selection state when region selection control is opened
        $scope.saveCurrentRegionSelection = function() {

            $scope.currentRegionSelection = angular.copy($scope.regions);
    
        };

        //restore saved region selection state when region selection control is closed without saving
        $scope.restoreRegionSelection = function() {

            $scope.regions = angular.copy($scope.currentRegionSelection);
            $scope.currentRegionSelection = null;

            $scope.selectedRegionsCount = 0;
            $scope.selectedRegionsString = "";
            $scope.firstSelectedRegion = null;

            //Determine selected regions count
            for(var j = 0; j < $scope.regions.options.length; j++) {

                if($scope.regions.options[j].option.selected == true) {

                    $scope.selectedRegionsCount++;
                    $scope.selectedRegionsString = $scope.selectedRegionsString + $scope.regions.options[j].option.text + ", ";

                    if($scope.firstSelectedRegion == null) {

                        $scope.firstSelectedRegion = $scope.regions.options[j];
                    }
                }
            }

            //Remove trailing comma and space
            $scope.selectedRegionsString = $scope.selectedRegionsString.substring(0, $scope.selectedRegionsString.length - 2);
        };

        /*
         Set the initial scope asynchronously
         */
        $scope.init = function() {

            //Set demographics & regions options
            $scope.demographics = angular.copy($rootScope.currentPulse.data.demographics);
            $scope.regions = angular.copy($rootScope.currentPulse.data.regions);

            //Grab a copy of the original set - to restore on a close (not saving)
            $scope.originalDemographics = angular.copy($scope.demographics);

            for(var i = 0; i < $scope.demographics.length; i++) {

                $scope.openArray[$scope.demographics[i].id] = $scope.isSelected($scope.demographics[i]);

                if($scope.isSelected($scope.demographics[i])) {

                    $scope.selectedDemsCount++;
                }
            }

            //Determine whether or not to show region selection control - only show if client has access to 2 or more regions
            $scope.showRegions = ($rootScope.currentPulse.data.regions.options.length >= 2 && (CONSTANTS.DISPLAY_FILTER_REGION_SELECTION || $scope.user.data.is_internal_account.value == "1"));

            $scope.selectedRegionsCount = 0;
            $scope.selectedRegionsString = "";
            $scope.firstSelectedRegion = null;

            //Determine selected regions count
            for(var j = 0; j < $scope.regions.options.length; j++) {

                if($scope.regions.options[j].option.selected == true) {

                    $scope.selectedRegionsCount++;
                    $scope.selectedRegionsString = $scope.selectedRegionsString + $scope.regions.options[j].option.text + ", ";

                    if($scope.firstSelectedRegion == null) {

                        $scope.firstSelectedRegion = $scope.regions.options[j];
                    }
                }
            }

            //Remove trailing comma and space
            $scope.selectedRegionsString = $scope.selectedRegionsString.substring(0, $scope.selectedRegionsString.length - 2);
            $scope.originalSelectedRegionsString = angular.copy($scope.selectedRegionsString);

            //DataService.fetch('panels').then(function(result) {
            //
            //    $scope.panelSelectListItems = angular.fromJson(result).panels;
            //    $scope.savedFiltersReady = true;
            //})
        };

        //Call initialisation
        $scope.init();

        //Update or create a panel based on auto-complete list actions
        $scope.updatePanel = function() {

            var updateDemographics = function(panel) {

                for(var i = 0; i < panel.data.demographics.length; i++) {

                    for (var j = 0; j < panel.data.demographics[i].values.options.length; j++) {

                        panel.data.demographics[i].values.options[j].option.selected = ($scope.demographics[i].values.options[j].option.selected);
                    }
                }

                return panel
            };

            if($scope.existingPanelNames.indexOf($scope.searchText) == -1) {

                DataService.create('panel').then(function (result) {

                    var panel = angular.fromJson(result).panel;

                    panel.data.name.value = $scope.searchText;

                    panel = updateDemographics(panel);

                    DataService.submit('panel', panel);
                });
            } else {

                var temp = updateDemographics(angular.copy($scope.panel));

                if(!angular.equals(temp, $scope.panel)) {

                    $scope.panel = updateDemographics($scope.panel);
                    DataService.submit('panel', $scope.panel);
                }
            }
        };

        //Update the pulse with the demographic selections
        $scope.submitCallback = function($event) {

            //Update panel
            if($scope.searchText != "" && $scope.searchText != null) {

                $scope.updatePanel();
            }

            $mdDialog.hide();
            $scope.$broadcast('event:pulse-saving');

            $rootScope.fadedOverlay = true;
            $rootScope.loadingStateText = "Saving Pulse";

            $rootScope.currentPulse.data.demographics = $scope.demographics;
            $rootScope.currentPulse.data.regions = $scope.regions;

            DataService.submit('pulse', $rootScope.currentPulse).then(function (result) {

                $rootScope.fadedOverlay = false;
                $rootScope.loadingStateText = "";

                var translations;
                $translate(['alert.pulse_saved.title', 'alert.pulse_saved.text']).then(function(result) {
                    translations = result;

                    AlertService.success(
                        translations['alert.pulse_saved.title'],
                        translations['alert.pulse_saved.text']
                    );
                });

                $rootScope.$broadcast('event:demographics-updated', $scope.demographics);
                $rootScope.$broadcast('event:pulse-update', result.pulse);
                $scope.$broadcast('event:pulse-saved');
            });
        };

        $scope.backCallback = function ($event) {

            $window.history.back();
            $event.preventDefault();
        };

        $scope.removeDemographic = function(id) {

            for(var i = 0; i < $scope.demographics.length; i++) {

                for(var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                    if($scope.demographics[i].values.options[j].option.id == id) {

                        $scope.demographics[i].values.options[j].option.selected = false;
                    }
                }
            }

            $scope.populatePotentialParticipants();
        };

        $scope.removeAllDemographics = function() {

            for(var i = 0; i < $scope.demographics.length; i++) {

                for(var j = 0; j < $scope.demographics[i].values.options.length; j++) {

                    $scope.demographics[i].values.options[j].option.selected = false;
                }
            }

            $scope.populatePotentialParticipants();
        };

        //Show/lock a demographic category based on user feature rights
        $scope.showDemographic = function(selectedOptionKey) {

            var basicDemographic = false;
            var showDemographic = false;

            //Check if the selected demographic is basic
            for (var i = 0; i < $scope.demographics.length; i++) {

                if ($scope.demographics[i].text == selectedOptionKey) {

                    basicDemographic = $scope.demographics[i].is_basic;
                }
            }

            var showBasic = FeatureService.allowed(CONSTANTS.FEATURES.BASIC_DEMOGRAPHICS, false);
            var showPro = FeatureService.allowed(CONSTANTS.FEATURES.FULL_DEMOGRAPHICS, false);

            //Check for feature permission - This return from the API should be a boolean rather than a string
            if (basicDemographic == "1") {

                //If the demographic is basic, we show if the user has basic OR full demo rights
                showDemographic = (showBasic || showPro);
            } else {

                //else we show only if the user has full demo rights
                showDemographic = showPro;
            }

            return showDemographic
        };

        //Toggle the demographic selector
        $scope.sliderShowNHide = function(selectedOptionKey, selectedOptions){

            $scope.showUpgrade = false;

            var showDemographic = $scope.showDemographic(selectedOptionKey);

            //If allowed, show the demographic
            if(showDemographic) {

                if ($scope.demographicsState == 'closed') {
                    $scope.demographicsState = 'open';
                }

                $scope.selectedOptionKey = selectedOptionKey;
                $scope.selectedOptions = selectedOptions;

                $scope.$emit('content.changed');
            } else {

                $scope.showUpgrade = true;
            }
        };

        $scope.showUpgradeModal = function() {

            return FeatureService.allowed(CONSTANTS.FEATURES.FULL_DEMOGRAPHICS, true);
        };

        //Close the Panel Editor Modal
        $scope.closeFilterEditor = function() {

            $mdDialog.hide();
            $scope.$broadcast('event:pulse-saving');

            $rootScope.fadedOverlay = true;
            $rootScope.loadingStateText = "";

            $rootScope.currentPulse.data.demographics = copyDemographics($scope.demographics, $scope.originalDemographics);

            DataService.submit('pulse', $rootScope.currentPulse).then(function (result) {

                $rootScope.fadedOverlay = false;
                $rootScope.loadingStateText = "";

                $rootScope.$broadcast('event:demographics-updated', $scope.originalDemographics);
                $rootScope.$broadcast('event:pulse-update', result.pulse);
                $scope.$broadcast('event:pulse-saved');
            })
        };

        //Check if any demographics for a particular category are selected
        $scope.isSelected = function(demographic){

            if (typeof demographic != "undefined") {

                var selected = false;

                for (var index = 0; index < demographic.values.options.length; index++) {

                    if (demographic.values.options[index].option.selected) {

                        selected = true;
                    }
                }

                return selected;
            }
        };

        $scope.isOpen = function(demographic) {

            if(typeof demographic != "undefined") {

                return $scope.openArray[demographic.id];
            }
        };

        //Toggle demographic value selected state
        $scope.toggleDemographicValue = function(demographic, option) {

            $scope.selectedDemsCount = 0;

            for(var i = 0; i < $scope.demographics.length; i++) {

                if($scope.isSelected($scope.demographics[i])) {

                    $scope.selectedDemsCount++;
                }
            }

            if(!$scope.isSelected(demographic) && $scope.selectedDemsCount >= CONSTANTS.MAX_ALLOWED_DEMOGRAPHICS) {

                var translations;
                $translate(['alert.exceeded_max_dems.title', 'alert.exceeded_max_dems.text']).then(function (result) {
                    translations = result;

                    AlertService.warning(
                        translations['alert.exceeded_max_dems.title'],
                        translations['alert.exceeded_max_dems.text']
                    )
                });
            } else {

                if(option != null) {

                    option.option.selected = !option.option.selected;
                }

                var data = {
                    "data": demographic,
                    "data_token": $rootScope.currentPulse.data_token
                };

                $scope.loadingParticipants = true;

                DataService.submit('pulse/demographics', data).then(function (result) {

                    $rootScope.currentPulse.data_token = result.pulse.data_token;

                    for (var i = 0; i < result.pulse.data.demographics.length; i++) {

                        for (var j = 0; j < result.pulse.data.demographics[i].values.options.length; j++) {

                            $scope.demographics[i].values.options[j].option_token = result.pulse.data.demographics[i].values.options[j].option_token;
                        }
                    }

                    $scope.populatePotentialParticipants();
                });
            }

            $scope.$broadcast('content.reload');
        };

        //Get potential participants - every demographic change
        $scope.populatePotentialParticipants = function() {

            //TEMP: Removed as part of business request
            DataService.fetchPotentialParticipants($rootScope.currentPulse.data_token).then(function(result) {

                $scope.maxParticipants = result.count;

                $scope.loadingParticipants = false;
            });
        };

        //Select a saved filter from auto-complete list
        $scope.setFilters = function(panel) {

            if(panel != null) {

                $scope.categoriesReady = false;

                DataService.fetchItem('panel', panel.data_token).then(function(result) {

                    $scope.panel = result.panel;
                    $scope.categoriesReady = true;

                    for(var i = 0; i < result.panel.data.demographics.length; i++) {

                        for (var j = 0; j < result.panel.data.demographics[i].values.options.length; j++) {

                            $scope.demographics[i].values.options[j].option.selected = (result.panel.data.demographics[i].values.options[j].option.selected);
                        }
                    }

                    $scope.populatePotentialParticipants();
                });
            }
        };

        //Populate auto-complete list of saved filters
        $scope.getSavedFilters = function(searchText) {

            $scope.searchText = searchText;
            $scope.existingPanelNames = [];
            var suggestions = [];
            var deferred = $q.defer();

            $timeout(function() {

                for(var i = 0; i < $scope.panelSelectListItems.length; i++) {

                    var item = $scope.panelSelectListItems[i].data.name.value;

                    if(item.indexOf(searchText) !== -1 && item != 'single_use' && item != '') {

                        suggestions.push($scope.panelSelectListItems[i]);
                        $scope.existingPanelNames.push(item);
                    }
                }

                deferred.resolve(suggestions);
            });

            return deferred.promise;
        };

    }]);

