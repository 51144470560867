/**
 * Created by mike on 13/12/15.
 */
onePulse.filter('convertKey', function() {
    return function (key) {
        return ucWords(key.replace(new RegExp('_', 'g'),' '));
    }
});

onePulse.filter('displayCredit', ['$sce', 'Config', function($sce, Config) {
    return function (decimal) {

        if(decimal != undefined) {

            if(decimal.toString().indexOf('.') != -1) {

                var parts = decimal.toString().split('.');

                return $sce.trustAsHtml(Config.get('I18N_CURRENCY') + parts[0] + '<small>.' + parts[1] + '</small>');
            } else {
                return $sce.trustAsHtml(Config.get('I18N_CURRENCY') + decimal);
            }
        }
    }
}]);

onePulse.filter('codeLink', ['Config', function(Config){
    INVITE_URL = Config.get('OP_INVITE_LINK');
    return function (code){
        return INVITE_URL+code;
    }
}]);

/*
MM:

Better to use a filter to do the ordering

 */
onePulse.filter('sequenceQuestions',function(){

    return function(questions){

        if(questions !==undefined) {
            return questions.sort(function (a, b) {
                // By default, questions are sorted from latest to newest.
                return a.data.sequence.value > b.data.sequence.value ? 1 : -1;
            });
        }
    }
});

/*
MM: I have added a flag to hide 'draft' panels/groups and only show which have been edited more than once

*/
onePulse.filter('display',function(){

    return function(array){

        if(array !== undefined) {
            return array.filter(function (a) {
                // return panel or group which have been edited
                return a.data.is_edited.value == '1';
            });
        }
    }
});

onePulse.filter('usedAnswers',function(){
    return function(answers){
        if(typeof answers != "undefined"){
            return answers.filter(function(answer){
                if(typeof answer.data.text.value != "undefined") {
                    return (answer.data.text.value.length > 0 /*|| answer.data.image.value.length>0*/); //add this in with API change to remove default pulse image
                }
            })
        }
    }
});

onePulse.filter('unsafe', ['$sce', function($sce) { return $sce.trustAsHtml; }]);

onePulse.filter('rules', function() {

    return function($rules,$name){
        angular.forEach($rules,function(v){

            if(v.name==$name){
                return JSON.stringify(v.value);
            }
        })
    }

});

onePulse.filter('imageString',function(){
    return function($image) {
        if ($image) {
            return imageString($image)
        }
    }
});


onePulse.filter('validAnswer',function(){
    return function($answers) {
        var $response=[];
        angular.forEach($answers,function($answer){

            if ($answer.data.text.value != null && $answer.data.text.value.length > 0) {
                $response.push($answer);
            }
        });

        return $response;
    }
});


onePulse.filter('ucFirst',function(){
    return function($text) {
        return uppercaseFirst($text);
    }
});

onePulse.filter('removeAfterDecimalIfWholeNumber',function(){
    return function($float) {
        return ($float % 1 != 0 ? parseFloat($float.toFixed(0)) : $float);
    }
});

onePulse.filter('stripUnderscoresAndUCFirst',function(){

    return function($text) {

        if(typeof $text != "undefined") {

            return uppercaseFirst($text.replace("_", " "));
        }

        return $text;
    }
});


onePulse.filter('ucCamel',function(){
    return function($text) {
        return camelLettersUppercase($text); //Just wrap helper function into filter
    }
});

onePulse.filter('unique', function () {
        return function (items, filterOn) {
            if (filterOn === false) {
                return items;
            }

            if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
                var hashCheck = {}, newItems = [];
                var extractValueToCompare = function (item) {
                    if (angular.isObject(item) && angular.isString(filterOn)) {
                        return item[filterOn];
                    } else {
                        return item;
                    }
                };

                angular.forEach(items, function (item) {
                    var valueToCheck, isDuplicate = false;

                    for (var i = 0; i < newItems.length; i++) {
                        if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
                            isDuplicate = true;
                            break;
                        }
                    }
                    if (!isDuplicate) {
                        newItems.push(item);
                    }

                });
                items = newItems;
            }
            return items;
        };
    });

//Ordinal number suffix filter
onePulse.filter('numberSuffix', function() {

    var suffixes = ["st", "nd", "rd", "th"];

    return function(input) {

        var suffix = input > 3 ? suffixes[3] : suffixes[input - 1];

        return input + suffix;
    };
});

onePulse.filter('nospace', function () {
    return function (value) {
        return (!value) ? '' : value.replace(/ /g, '');
    };
});

onePulse.filter('parsePaymentString', function() {
    return function(value) {

        if(typeof value != "undefined") {

            return value.replace("_", " ").capitalize();
        }
    };
});

onePulse.filter('questionFormatOrderBy', function() {

    return function(input,sortBy) {

        var ordered = [];

        for (var key in sortBy) {

            for(var i = 0; i < input.length; i++) {

                if(sortBy[key] == input[i].option.text) {

                    ordered.push(input[i]);
                }
            }
        }

        return ordered;
    };
});

onePulse.filter('removeNumbersBeforeDecimal', function() {
    return function(value) {

        if(typeof value !== "undefined") {

            return value.replace("0.", "");
        }
    };
});

onePulse.filter('removeNumbersAfterDecimal', function() {
    return function(value) {

        if(typeof value !== "undefined") {

            return parseFloat(value).toFixed(0);
        }
    };
});

onePulse.filter('parseRoleString', function() {
    return function(value) {

        if(typeof value !== "undefined") {

            return value.replace("client:", "").replace("_", " ").capitalize();
        }
    };
});

onePulse.filter('orderByParseFloat', function() {

    return function(input, direction) {

        if(typeOf(input) === 'array') {

            var ordered = [];

            var newInput = input.slice();

            if(typeof newInput !== "undefined") {

                ordered = newInput.sort(function(a,b) {

                    if(typeof direction !== "undefined" && direction === 'asc') {

                        return a[11] - b[11];
                    }

                    return b[11] - a[11];

                });
            }

            return ordered;
        }

        return input;

    };
});

onePulse.filter('formatDateTime', function() {

    return function(value) {

        if(typeof value !== "undefined") {

            var date = new Date(value);

            tzo = -date.getTimezoneOffset(),
                dif = tzo >= 0 ? '+' : '-',
                pad = function(num) {
                    var norm = Math.abs(Math.floor(num));
                    return (norm < 10 ? '0' : '') + norm;
                };

            return date.getFullYear()
                + '-' + pad(date.getMonth()+1)
                + '-' + pad(date.getDate())
                // + ' ' + pad(date.getHours())
                // + ':' + pad(date.getMinutes())
                // + ':' + pad(date.getSeconds())
                // + dif + pad(tzo / 60)
                // + '' + pad(tzo % 60);
        }
    }
});

onePulse.filter('hexToRgb', function() {

    return function(hex, opacity) {

        var c;

        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
        }
        throw new Error('Bad Hex');
    }
});
