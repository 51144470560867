/**
 * Created by Barry on 28/09/2017.
 *
 * Directive for displaying the panels training overlay
 */
onePulse.directive('panelsTrainingOverlay', ['$rootScope', 'DataService', '$window', function($rootScope, DataService, $window) {

        linkFunction = function($scope, element, attributes) {

            //Current card count
            $scope.card = 1;

            $scope.turnOffTraining = false;

            $scope.complete = function() {

                $rootScope.showPanelsTrainingOverlay = false;
                $window.localStorage['showPanelsTrainingOverlay'] = false;

                var showDashOverlay = (typeof $window.localStorage['showDashboardTrainingOverlay'] !== "undefined" ? JSON.parse($window.localStorage['showDashboardTrainingOverlay']) : true);

                if($scope.turnOffTraining || !showDashOverlay) {

                    //Update user is_web_training_displayed property
                    $scope.user = DataService.grab('user');

                    $scope.user.data.is_web_training_displayed.value = '0';

                    DataService.submit('user', $scope.user).then(function (result) {

                        $scope.user = result.user;
                    });
                }
            };

            $scope.close = function() {

                $rootScope.showPanelsTrainingOverlay = false;
                $window.localStorage['showPanelsTrainingOverlay'] = false;
            }
        };

        return {
            restrict: 'E',
            priority: 0,
            replace: false,
            scope: true,
            link:linkFunction,
            templateUrl:'/app/views/elements/overlays/panelsTrainingOverlay.html'
        }
    }]);