/**
 * Created by mike on 13/12/15.
 */

onePulse.directive('focusOn', ['$timeout', function($timeout) {

			return function(scope, elem, attr) {

				/*
				MM Focus the new element once
				 */
				$timeout(function (){
					angular.element('#'+attr.focusOn).focus();
				},10);
			};

		}]).directive('textInput', ['$compile', function ($compile) {

		var linkFunction = function ($scope, element, attributes) {

			//pass params into scope for the template
			$scope.name = attributes.name;
			$scope.rfModel = $scope[attributes.scopeRf];

			//go through the rules and add them as attributes
			angular.forEach(angular.fromJson(findByName($scope.form.rules, attributes.name)), function (v) {
				//element.attr()
				var bits = v.split(':')
				element.find('input')
					.attr(bits[0], bits[1])
			})

			//recompile the scope
			$compile(element.contents())($scope);

		}

		return {
			templateUrl: 'app/views/elements/form/text.html',
			link: linkFunction,

		};
	}])
	.directive('textAreaInput', ['$compile', function ($compile) {

		var linkFunction = function ($scope, element, attributes) {

			//pass params into scope for the template
			$scope.name = attributes.name;
			$scope.rfModel = $scope[attributes.scopeRf];

			//go through the rules and add them as attributes
			angular.forEach(angular.fromJson(findByName($scope.form.rules, attributes.name)), function (v) {
				//element.attr()
				var bits = v.split(':')
				element.find('textarea')
					.attr(bits[0], bits[1])
			})

			//recompile the scope
			$compile(element.contents())($scope);

		}

		return {
			templateUrl: 'app/views/elements/form/textarea.html',
			link: linkFunction
		};
	}])
	.directive('selectDemographicInput', function () {

		var linkFunction = function ($scope, element, attributes) {

			$scope.name = attributes.name;
			$scope.options = angular.fromJson(attributes.options);
			$scope.rfModel = $scope[attributes.scopeRf];
			$scope.rfIndex = findIndexByDemographicText($scope[attributes.scopeRf].data.demographics, attributes.name)
			$scope.modelName = attributes.scopeRf;
			$scope.multi = false;


			//go through the rules and add them as attributes
			angular.forEach(angular.fromJson(findByName($scope.form.rules, attributes.name)), function (v) {
				var bits = v.split(':')
				element.find('select')
					.attr(bits[0], bits[1])
				if (bits[0] == 'multiple') {
					$scope.multi = true;
				}
			})
		}

		return {
			templateUrl: 'app/views/elements/form/demographics-select.html',
			link: linkFunction
		};

	})
	.directive('selectInput', function () {

		var linkFunction = function ($scope, element, attributes) {

			$scope.name = attributes.name;
			$scope.options = angular.fromJson(attributes.options);
			$scope.rfModel = $scope[attributes.scopeRf];
			$scope.modelName = attributes.scopeRf;

			//go through the rules and add them as attributes
			angular.forEach(angular.fromJson(findByName($scope.form.rules, attributes.name)), function (v) {
				var bits = v.split(':')
				element.find('select')
					.attr(bits[0], bits[1])
			})
		}

		return {
			templateUrl: 'app/views/elements/form/select.html',
			link: linkFunction

		};

	})
	.directive('checkboxInput', ['$compile', function ($compile) {

		var linkFunction = function ($scope, element, attributes) {

			//pass params into scope for the template
			$scope.name = attributes.name;

			//go through the rules and add them as attributes
			angular.forEach(angular.fromJson(findByName($scope.form.rules, attributes.name)), function (v) {
				//element.attr()
				var bits = v.split(':')
				element.find('input')
					.attr(bits[0], bits[1])
			})

			//recompile the scope
			$compile(element.contents())($scope);
		}

		return {
			templateUrl: 'app/views/elements/form/checkbox.html',
			link: linkFunction

		};
	}])

	.directive('imageInput', ['$compile', 'DataService', 'ngDialog', '$timeout', '$rootScope', '$location',
		function ($compile, DataService, ngDialog, $timeout, $rootScope, $location) {

		var linkFunction = function ($scope, element, attributes) {

			//pass params into scope for the template
			$scope.modelName = attributes.scopeRf;
			$scope.name = attributes.name;

			$scope.id = randomString(8);
			$scope.edited = false;
			$scope.imageSrc = '';

            $scope.$watch('rfModel', function () {

                if(typeof $scope.rfModel !== "undefined") {

                    if($scope.name === 'background_image' || $scope.name === 'logo_image' || $scope.name === 'bar_image') {

                        $scope.imageSrc = $scope.rfModel[$scope.name];
                        $scope.width = 1152; //1024
                        $scope.height = 723; //768
                        $scope.ratio = 1152/723;
                    } else if ($scope.rfModel !== null && typeof $scope.rfModel.data !== "undefined") {

                        if(typeof $scope.rfModel.data[$scope.name] !== "undefined") {

                            $scope.imageSrc = $scope.rfModel.data[$scope.name].value;

                            var $rule = findImageRuleByName($scope.rfModel, $scope.name);

                            if(typeof $rule !== 'undefined') {

								if(typeof $rule.split(':')[1] !== 'undefined') {

									var $rules = $rule.split(':')[1].split(','); // image_path:8,555,366

									$scope.width = $rules[1]; //1024
									$scope.height = $rules[2]; //768
									//$scope.width = 1152; //1024
									//$scope.height = 768; //768
									$scope.ratio = $rules[1] / $rules[2];
								}
							}
                        }
                    }
				}
            });

			$scope.getImageContainerDims = function() {

				if($scope.name == 'avatar' || $scope.name == 'brand_logo') {

					return {
						'min-width': '450px',
						'min-height': '450px'
					}
				}
			};

			$scope.resizeModal = function() {

				$('.modal-dialog').css({ width: $scope.width, height: $scope.height});

				$scope.cropperModal = ngDialog.open({
					className: 'ngdialog-theme-default image-cropper-modal',
					template: 'app/views/elements/templates/image-cropper-modal.html',
					scope: $scope,
					showClose: false
				})
			};

			$scope.closeModal = function() {
				$scope.cropperModal.close();
			};

			$scope.resetCallback = function ($e) {
				angular.element("file").val(null);
				$scope.imageSrc = '';

                if($scope.name === 'logo_image' || $scope.name === 'bar_image' || $scope.name === 'background_image') {

                    $scope.rfModel[$scope.name] = "";
                } else if (typeof $scope.rfModel.data[$scope.name] !== "undefined") {

                    $scope.rfModel.data[$scope.name].value = "";
                }

				$scope.cropperModal.close();
				$e.preventDefault();
			};

			$scope.selectFileCallback = function($e) {
				element.find('input:file').click();
			};

			$scope.saveCallback = function (base64, close) {

				//Only attempt upload if we have image data
				if(base64 != "data:,") {

					DataService.upload(base64)
						.then(function ($result) {
							//reset edited
							$scope.edited = false;

							//update cropper preview with new image
							$scope.imageSrc = $result.image_path;

                            if($scope.name === 'logo_image' || $scope.name === 'bar_image' || $scope.name === 'background_image') {

                                $scope.rfModel[$scope.name] = $result.image_path;
                            } else if (typeof $scope.rfModel.data[$scope.name] !== "undefined") {

                                $scope.rfModel.data[$scope.name].value = $result.image_path;
                            }

							if (close) {
								$scope.cropperModal.close();
							}

							if(($location.path().split('/')[1] === 'user' || $location.path().split('/')[1] === 'workspace')
								&& $location.path().split('/')[2] !== 'customization') {

                                $rootScope.$broadcast('event:image-saved');
                            }

                            if($location.path().split('/')[2] === 'customization') {

                                $rootScope.$broadcast('event:whitelabel-image-saved');
                            }
						})
				} else {

					$scope.cropperModal.close();
				}
			};

			$scope.cropperApi = function(api) {

				$scope.submitCallback = function(event) {

					base64 = api.crop();
					$scope.saveCallback(base64, true);
				};

				$scope.scrollCallback = function($event, $delta, $deltaX, $deltaY) {
					if ($deltaY > 0) {
						api.zoomIn(0.2);
					} else {
						api.zoomOut(0.2);
					}

					$event.preventDefault();
				};

				$rootScope.$$phase || $rootScope.$apply();
			};


			$scope.$watch('file', function (newVal, oldVal) {

				if (newVal != oldVal) {
					$timeout(function() {
						$scope.imageSrc = ''; // Trigger redraw of image-cropper directive
					}, 10);

					$timeout(function() {
						$scope.edited = true;
						$scope.imageSrc = imageString(newVal);
						//$scope.rfModel.data[$scope.name].value = imageString(newVal);
					}, 10);
				}
			});

			// Prevent infinite bubbling of click event
			element.find('input:file').on('click', function($e){
				$e.stopPropagation();
			});

			element.on('click', function($e) {

				if($scope.imageSrc.length > 0) {
					$scope.resizeModal();
				} else {
					element.find('input:file').click();
					$scope.resizeModal();
				}

				$rootScope.$$phase || $rootScope.$apply();
			})
	};

	return {
		scope: {
			rfModel: '=scopeRf'
		},
		restrict: 'AE',
		transclude: true,
		templateUrl: 'app/views/elements/form/image.html',
		link: linkFunction
	};
}]);
