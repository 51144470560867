onePulse.factory('ErrorService', ['$rootScope', '$timeout',
    function($rootScope, $timeout){

    var parseURL = function(url) {

        var urlArray = url.split('/');

        return urlArray[urlArray.length - 1];
    };

    var prep = function(context) {

        $rootScope.errors[context] = "";
    };

    var add = function(url, error) {

        var context = parseURL(url);

        $timeout(function() {

            $rootScope.errors[context] = error;
        });
    };

    var remove = function(context, error) {

    };

    var removeAll = function(url) {

        var context = parseURL(url);

        $rootScope.errors[context] = "";
    };

    return {
        prep: prep,
        add: add,
        remove: remove,
        removeAll: removeAll
    }
}]);