/**
 * Created by Barry on 2018/05/25.
 *
 * Pulse top menu
 */

onePulse.directive('pulseTopMenu', ['PulseService',
    function (PulseService) {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: false,
        templateUrl:'/app/views/elements/pulse/pulseTopMenu.html',

        link: function postLink(scope, element, iAttrs) {

            scope.duplicatePulse = function($event) {

                PulseService.duplicatePulse($event, scope.pulse, false);
            };

            scope.deletePulse = function ($event) {

                PulseService.deletePulse($event, scope.pulse, scope.returnToPulseList, null);
            };

            scope.previewPulseModal = function($event) {

                PulseService.previewPulseModal($event, scope.pulse, scope.preview.displayName, scope.validate);
            };

            scope.extractPulseData = function($event) {

                PulseService.extractPulseData($event, scope.pulse, scope.allRegions);
            };

            scope.extractPulseDemographicSummary = function($event) {

                PulseService.extractPulseDemographicSummary($event, scope.pulse, scope.allRegions, scope.demographicsPostPublish);
            };
        }
    };
}]);