/**
 * Created by mike on 13/12/15.
 */

onePulse.directive('workspace.payments', ['DataService', function(DataService) {

    linkFunction = function($scope, element, attributes){

        DataService.profileClient("subscription", null, "").then(function(result) {

            $scope.subscription = result.subscriptions;
        });

        $scope.responses = 500;

    };

    return {
        templateUrl: '/app/views/elements/forms/workspace/payments.html',
        link:linkFunction
    }
}]);
