onePulse.factory('SearchService', ['ESQueryService', 'DataService', '$q',
    function(ESQueryService, DataService, $q){

        /*
        ** Perform a search
        * searchType: db | es
         */
        var performSearch = function(searchType, targetType, searchObj, page, sort, isStore, isAuth, isSimplified) {

            var deferred = $q.defer();

            switch(searchType) {

                case 'db':

                    DataService.searcher(targetType, searchObj).then(function (result) {

                        deferred.resolve(result);
                    });

                    break;

                case 'es':

                    var searchQuery = ESQueryService.buildQuery(searchObj);

                    ESQueryService.submitQuery(targetType, searchQuery, page, sort, isStore, isAuth, isSimplified).then(function(result) {

                        deferred.resolve(result);
                    });

                    break;
            }

            return deferred.promise;
        };

        return {
            performSearch: performSearch
        }
    }]);