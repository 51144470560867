/**
 * Created by mike on 15/12/15.
 */
onePulse.factory('AuthService', ['TokenService', '$injector', 'CONSTANTS', function(TokenService, $injector, CONSTANTS){

    var payload;

    var accessToken = function(){

        return TokenService.getAccessToken();
    };

    var isLoggedIn = function(){

        var user = $injector.get('DataService').grab('user');

        //only valid for client type users, if user is logged into a workspace and if user is active
        if(user && typeof user.data.status_id !== "undefined") {

            return(userType() == 'client' && workspace() && user.data.status_id.value == CONSTANTS.STATUS_ACTIVE);
        } else {

            return(userType() == 'client' && workspace());
        }

    };

    var userType = function(){

        if(payload = getPayload()){

            //the type contains user/token type :client, mobile or application (backward compat. requires check for sub)
            return (typeof payload.typ !== "undefined" ? payload.typ : payload.sub);
        }

        return false;
    };

    var workspace = function(){

        if(payload = getPayload()){

            return (typeof payload.wsp !== "undefined" ? payload.wsp : false);
        }

        return false;
    };

    var permissions = function(){

        if(payload = getPayload()){

            //the audience claim contains the permissions
            return payload.aud
        }

        return false;
    };

    var role = function(){

        if(payload = getPayload()){

            return payload.aud.role_id
        }

        return false;
    };

    var getPayload = function(){

        return angular.fromJson(decodeBase64(accessToken().split('.')[1]));
    };

    return{
        accessToken : accessToken,
        isLoggedIn : isLoggedIn,
        getPayload: getPayload,
        userType: userType,
        permissions: permissions,
        role: role
    }
}]);