/**
 * Created by mike on 12/01/16.
 */

onePulse.directive('showtab',function () {
    return {
        link: function (scope, element, attrs) {
            element.click(function(e) {
                e.preventDefault();
                $(element).tab('show').addClass('active');
            });
        }
    };
}).directive('pulsePanel',function(){

    return {
        templateUrl: 'app/views/elements/pulse-default.html'
    };

}).directive('background',function(){
    return {
        link: function(scope, element, attrs){
                element.css({
                    'background-image': 'url("' +  attrs.background +'")',
                    'background-size' : 'cover',
                    'background-position':'center',
                    'display':'block',
                    'width':'100%',
                });
    }}
});