/**
 * Created by Barry on 24/05/17.
 *
 * Important to note: For type 'pulse' the urls are in key:share_links, while for type 'community' they are in key: share_urls
 * The ternary below deals with this but ideally the keys should be uniform. This will require an API change and needs to be requested
 */
onePulse.factory('SocialShareService', ['Socialshare', function(Socialshare){

    var invite = function(type, object, provider){

        var url = "";

        switch(type) {

            case 'pulse':
            case 'community':

                url = (typeof object.share_urls != "undefined" ? object.share_urls.short_share_url : object.share_links.short_share_url);
                break;

            case 'link':

                url = object;
                break;
        }

        switch(provider) {

            case 'facebook':
            case 'twitter':
            case 'google':
            case 'linkedin':
            case 'reddit':

                Socialshare.share({
                    'provider': provider,
                    'attrs': {
                        'socialshareUrl': url
                    }
                });

                break;

            case 'pinterest':
                Socialshare.share({
                    'provider': provider,
                    'attrs': {
                        'socialshareUrl': url,
                        'socialshareMedia': 'https://s3.amazonaws.com/op.uploads/client-default-300.png',
                        'socialshareText': url
                    }
                });
                break;

            case 'email':

                Socialshare.share({
                    'provider': 'email',
                    'attrs': {
                        'socialshareSubject': 'OnePulse!',
                        'socialshareBody': url
                    }
                });

                break;
        }
    };

    var share = function(type, object, provider){

        var url = "";

        switch(type) {

            case 'pulse':
            case 'community':

                url = (typeof object.share_urls != "undefined" ? object.share_urls.share_url : object.share_links.share_url) + '?client=true';
                break;

            case 'link':

                url = object;
                break;
        }

        switch(provider) {

            case 'facebook':
            case 'twitter':
            case 'google':
            case 'linkedin':
            case 'reddit':

                Socialshare.share({
                    'provider': provider,
                    'attrs': {
                        'socialshareUrl': url
                    }
                });

                break;

            case 'pinterest':
                Socialshare.share({
                    'provider': provider,
                    'attrs': {
                        'socialshareUrl': url,
                        'socialshareMedia': 'https://s3.amazonaws.com/op.uploads/client-default-300.png',
                        'socialshareText': url
                    }
                });
                break;

            case 'email':

                Socialshare.share({
                    'provider': 'email',
                    'attrs': {
                        'socialshareSubject': 'OnePulse!',
                        'socialshareBody': url
                    }
                });

                break;
        }
    };

    return{
        share : share,
        invite: invite
    }
}]);
