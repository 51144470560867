onePulse.factory('DocumentService', [
    function(){

        var buildPdf = function(domElementId, fileName) {

            var element = document.getElementById(domElementId);

            html2canvas(element, {

                onclone: function(document){

                    var hiddenDiv = document.getElementById(domElementId);
                    hiddenDiv.style.display = 'block';
                },

                onrendered: function (canvas) {

                    var data = canvas.toDataURL();
                    var docDefinition = {
                        content: [{
                            image: data,
                            width: 500,
                            bold: true
                        }]
                    };
                    pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
                }
            });
        };

        return {
            buildPdf: buildPdf
        }
    }]);