/**
 * Created by mike on 23/01/16.
 */
onePulse.directive('questionResultsOverlay', ['$timeout', '$location', 'CONSTANTS', function($timeout, $location, CONSTANTS) {

    var linkFunction = function($scope){

        var isPreviewPulse = function() {

            return ($location.path().split('/')[1] == 'store');
        };

        var highestAnnotation = 0.00;
        var highestAnnotationIndex = 0;
        var secondHighestAnnotation = 0.00;
        var secondHighestAnnotationIndex = 0;

        $scope.audience_type_cint = CONSTANTS.AUDIENCE_TYPE_CINT;
        $scope.audience_type_lucid = CONSTANTS.AUDIENCE_TYPE_LUCID;

        var drawOverlay = function() {

            if($scope.showBarchart) {

                $timeout(function() {

                    for (var t = 0; t < $scope.barChart.data.getNumberOfRows(); t++) {

                        var val = $scope.barChart.data.getValue(t, 1);

                        $scope.labels[t].label = $scope.barChart.data.getValue(t, 0);

                        if($scope.question.formatText != 'Slider') {

                            $scope.annotations[t] = val > 0.001 ? roundNumbers((val / $scope.question.total_responses_multiple) * 100, 2).toFixed(2) : '0.00';

                            if(parseFloat($scope.annotations[t]) > highestAnnotation) {

                                secondHighestAnnotation = highestAnnotation;
                                secondHighestAnnotationIndex = highestAnnotationIndex;
                                highestAnnotation = $scope.annotations[t];
                                highestAnnotationIndex = t;
                            }

                            for (var u = 0; u < $scope.question.data.answers.length; u++) {

                                if ($scope.question.data.answers[u].data.text.value == $scope.labels[t].label) {

                                    $scope.labels[t].answerToken = $scope.question.data.answers[u].data_token;
                                }
                            }
                        }
                    }
                });
            }
        };

        $scope.$on("event:demographics-on", function(event) {

            drawOverlay();
        });

        drawOverlay();

        $scope.hideBar = function($index) {

            return (isPreviewPulse() && $index != highestAnnotationIndex && $index != secondHighestAnnotationIndex)
        };

        $scope.showAnnotation = function($index) {

            return (isPreviewPulse() ? $index == highestAnnotationIndex || $index == secondHighestAnnotationIndex : true);
        };
    };

    return {
        link:linkFunction,
        templateUrl:'/app/views/elements/question-results-overlay.html'
    }
}]);
