onePulse.factory('TranslationService', ['$http', '$q', 'TokenService', 'Config', '$rootScope',
    function ($http, $q, TokenService, Config, $rootScope) {

    var SERVICE_URL = Config.get('OP_API');
    // angular-translate doesn't support markup, that's why we have to split formatted text into parts by tags

    var translationStorage = {
        en_GB: en_GB,
        en_US: en_US,
        fr_DZ: fr_DZ
    };

    // return loaderFn
    return function (options) {
        var deferred = $q.defer();

        // Check first if language data was loaded
        if(translationStorage[options.key] == undefined) {
            $http({
                method: 'POST',
                url: SERVICE_URL + '/translations/' + options.key,
                headers: {'Authorization': 'Bearer ' + TokenService.getAccessToken()}
            }).then(function successCallback(response) {
                translationStorage[options.key] = response.data;

                deferred.resolve(response.data);

                $rootScope.$$phase || $rootScope.$apply();
            }, function errorCallback(response) {
                return deferred.reject(options.key);
            });
        } else {

            deferred.resolve(translationStorage[options.key]);
        }

        return deferred.promise;
    };
}]);