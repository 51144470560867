
onePulse.directive('user.accessibility', [function() {

    linkFunction = function($scope, element, attributes){

    };

    return {
        templateUrl: '/app/views/elements/forms/user/accessibility.html',
        link:linkFunction
    }
}]);
