onePulse.controller('PurchasePulseController', ['$scope', '$rootScope', '$mdDialog', 'DataService', '$window', '$translate',
    'AlertService', '$location', 'FeatureService', 'CONSTANTS', 'RoleService',
    function($scope, $rootScope, $mdDialog, DataService, $window, $translate,
             AlertService, $location,FeatureService, CONSTANTS, RoleService) {

        $scope.submitSpinner = false;

        //Default setting for publish button (2 states: 'Purchase'/'Topup')
        $scope.submitButtonState = 'None';

        //Default setting for warning message (3 states: 'None'/'InsufficientCredit'/'NoCredit')
        $scope.warningState = 'None';

        //Default setting for publish type (3 states: 'None'/'AutoBill'/'Mixed')
        $scope.publishState = 'None';

        $scope.purchasePulse = function() {

            $mdDialog.hide();

            if(RoleService.allowed(CONSTANTS.RIGHTS.PURCHASE_PULSE_EDIT_SUB_PAYMENT, true)) {

                $rootScope.renderComplete = false;
                $rootScope.loadingStateText = "Purchasing Pulse";

                switch ($scope.publishState) {

                    //Publish entirely on credit: Simply publish the pulse and debit the responses
                    case 'Credit':

                        DataService.purchasePulse($scope.pulse.data_token).then(function (result) {

                            $window.localStorage['pulses_active_tab'] = 'Purchased';

                            $window.location = '/pulses';
                        });
                        break;

                    //Publish with a mix of credit and auto-bill: Bill for the difference between the user's credit and the required participants
                    case 'Mixed':

                        var data = {
                            "responses": parseInt(parseInt($scope.determineDiscountedPrice(($scope.pulse.data.responses.value * $scope.pulse.data.extract_price.value), $scope.storeDiscount)) - parseInt($scope.user.data.balance.value))
                        };

                        DataService.profileClient("topups", data, "/purchase/onthego").then(function (result) {

                            DataService.purchasePulse($scope.pulse.data_token).then(function (result) {

                                $window.localStorage['pulses_active_tab'] = 'Purchased';

                                $window.location = '/pulses';
                            });
                        });
                        break;

                    //Publish entirely on auto-bill: Bill for the entire number of required participants
                    case 'AutoBill':

                        var data = {
                            "responses": parseInt($scope.determineDiscountedPrice(($scope.pulse.data.responses.value * $scope.pulse.data.extract_price.value), $scope.storeDiscount))
                        };

                        DataService.profileClient("topups", data, "/purchase/onthego").then(function (result) {

                            DataService.purchasePulse($scope.pulse.data_token).then(function (result) {

                                $window.localStorage['pulses_active_tab'] = 'Purchased';

                                $window.location = '/pulses';
                            });
                        });
                        break;
                }
            }
        };

        //Check that user has enough credit to publish to the selected number of participants
        $scope.checkCredit = function() {

            //Grab user again
            DataService.fetchItem("user").then(function(result) {

                $scope.user = result.user;

                if(parseInt($scope.user.data.balance.value) > 0) {

                    //If the user does not have enough credit we need to check auto-bill status
                    if (parseInt($scope.user.data.balance.value) < parseInt($scope.determineDiscountedPrice(($scope.pulse.data.responses.value * $scope.pulse.data.extract_price.value), $scope.storeDiscount))) {

                        //If the user is not auto-billed we need to present the top-up button and insufficient credit warning
                        if ($scope.user.data.is_autobilled.value == "0") {

                            $scope.publishState = 'None';
                            $scope.submitButtonState = 'Topup';
                            $scope.warningState = 'InsufficientCredit';
                            //Else if the user is auto-billed but they do have some credit, we can process a mixed form of publish payment
                            //Else if the user is auto-billed but they do have some credit, we can process a mixed form of publish payment
                        } else {

                            $scope.publishState = 'Mixed';
                            $scope.submitButtonState = 'Purchase';
                            $scope.warningState = 'None';
                        }
                    } else {

                        $scope.publishState = 'Credit';
                        $scope.submitButtonState = 'Purchase';
                        $scope.warningState = 'None';
                    }
                    //Else if the user is auto-billed we can also proceed with the publish process
                } else if($scope.user.data.is_autobilled.value == "1") {

                    $scope.publishState = 'AutoBill';
                    $scope.submitButtonState = 'Purchase';
                    $scope.warningState = 'None';
                } else {

                    $scope.publishState = 'None';
                    $scope.submitButtonState = 'Topup';
                    $scope.warningState = 'NoCredit';
                }
            });
        };

        $scope.closeDialog = function(){

            $mdDialog.hide();
        };

        //Default top-up
        $scope.selectedTopup = 'autoBill';
        $rootScope.descriptionTopup = $scope.selectedTopup;

        //Modal for Topup payment
        $scope.popupTopupDialog = function(ev) {

            $mdDialog.hide();

            $rootScope.modalOpen = true;

            $mdDialog.show({
                controller: 'UserController',
                templateUrl: 'app/views/elements/includes/plan-topup.html',
                clickOutsideToClose: false
            });

            DataService.fetchBrainTreeClientToken().then(function(result) {

                var brainTreeClientToken = result.token;

                initiateBrainTree(brainTreeClientToken, '.topup-form', $scope.errorCallback, $scope.successCallbackTopup);
            });
        };

        //Error callback for Braintree
        $scope.errorCallback = function() {

            var translations;
            $translate(['alert.card_details_error.title', 'alert.card_details_error.text']).then(function(result) {
                translations = result;

                AlertService.error(
                    translations['alert.card_details_error.title'],
                    translations['alert.card_details_error.text']
                );
            });
        };

        //Success callback for Braintree - Topup payment
        $scope.successCallbackTopup = function(payload, deviceData) {


            //Register client's banking details - set as preferred if selected
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "is_default": true
            };

            DataService.paymentMethod('create', data).then(function (result) {

                $rootScope.isDefault = true;

                //Set user is_autobilled to true and update user
                $scope.user.data.is_autobilled.value = "1";

                DataService.submit('user', $scope.user).then(function (result) {

                    $scope.user = result.user;

                    //Only pre-publish if we are not topping up from user/plans
                    if ($location.path() != '/user/plans') {

                        $rootScope.modalOpen = false;
                        $mdDialog.hide();
                        $timeout(function () {
                            angular.element('#schedulePulse').triggerHandler('click');
                        }, 0)
                    } else {

                        $rootScope.currentLockedFeature = null;
                        $rootScope.modalOpen = false;
                        $mdDialog.hide();
                        $window.location = 'user/plans';
                    }
                });
            });
        };

        $scope.checkCredit();

        var determinePulseDiscount = function() {

            $scope.storeDiscount = FeatureService.checkMeta(CONSTANTS.FEATURES.STORE_DISCOUNT);
        };

        determinePulseDiscount();

        $scope.determineDiscountedPrice = function(price, discount) {

            return parseFloat(parseFloat(price) - (parseFloat(price) * (discount / 100))).toFixed(0);
        };
    }]);
