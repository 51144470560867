/**
 * Created by Barry on 2018/10/29.
 *
 */

onePulse.directive('upsellSubs', ['DataService', '$window', '$rootScope',
    function (DataService, $window, $rootScope) {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/checkout/upsellSubs.html',

            link: function postLink(scope, element, iAttrs) {

                $rootScope.showStepLoadingSpinner = true;
                scope.showStep = false;

                DataService.fetchMetaData("subscriptions").then(function(result) {

                    result.subscriptions.sort(function(a,b) {

                        return a.data.display_order.value - b.data.display_order.value;
                    });

                    for (var i = 0; i < result.subscriptions.length; i++) {

                        scope.showStep = (parseFloat(result.subscriptions[i].data.response_value.value) < scope.user.data.current_subscription.data.response_value.value
                            && parseFloat(result.subscriptions[i].data.response_value.value) > 0
                            && result.subscriptions[i].data.description.value != 'Enterprise'
                            && ((scope.user.data.current_subscription.data.response_value.value * scope.pulse.data.participants.value)
                                + parseFloat(scope.user.data.current_subscription.data.display_price.value))
                            - ((result.subscriptions[i].data.response_value.value * scope.pulse.data.participants.value)
                                + parseFloat(result.subscriptions[i].data.display_price.value)) > 0);
                    }

                    scope.subscriptions = result.subscriptions;

                    if(!scope.showStep || scope.user.data.current_subscription.data.is_custom.value == "1") {

                        scope.step(false);
                    }

                    $rootScope.showStepLoadingSpinner = false;
                });

                scope.canUpgrade = function(subscription) {

                    if(typeof subscription !== "undefined") {

                        return (scope.user.data.current_subscription.data.display_price.value != "" ? (parseFloat(scope.user.data.current_subscription.data.display_price.value) < parseFloat(subscription.data.display_price.value) && parseFloat(subscription.data.display_price.value) > 0.00) : true);
                    }
                };

                scope.sendSubContactMail = function() {

                    $window.open("mailto:info@onepulse.com","_self");
                };
            }
        };
    }]);