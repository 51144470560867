/**
 * Service handling creation of data exports. Currently only supports CSV format
 * Author: B. Whyte
 * Date: 22/02/2017
 */
onePulse.factory('ExportService', ['$sce', '$filter', 'FileSaver',
    function($sce, $filter, FileSaver){

        var commonHeader = "User ID, Created";

        var buildFileName = function (filename, ext, type) {

            var fullFilename = "";

            switch(type) {

                case 'summary':
                    fullFilename = filename.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '_summary' + '.' + ext;

                    break;
                case 'raw':

                    fullFilename = filename.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.' + ext;
                    break;
            }

            return fullFilename
        };

        var buildPulseHeader = function(type, pulse) {

            var pulseHeader = pulse.data.title.value.replace(/(\r?\n|\r\n?)/g, "").replace(/,/g , ";");
            pulseHeader = pulseHeader + " (Pulse started: " + pulse.data.started_at.value + "";
            pulseHeader = pulseHeader + " Unique respondents: " + pulse.data.responses.value + ")";
            pulseHeader = newLine(pulseHeader);
            pulseHeader = pulseHeader + "A small percentage of respondents may be indicated as being in the 'INT' region - this signifies users that are based in the region(s) you selected but are currently travelling.";
            pulseHeader = newLine(pulseHeader);

            return newLine(pulseHeader);
        };

        var buildHeader = function(type, questions, dataHeaders, full, pulseHeader) {

            var header = (pulseHeader ? pulseHeader + commonHeader : commonHeader);

            for(var l in dataHeaders) {

                if(l > 14) {

                    header = header + "," + dataHeaders[l];
                }
            }

            header = header + ", Device type";

            header = header + ", Region";

            for(var i = 1; i <= questions.length; i++) {

                var questionText = questions[i - 1].data.text.value.replace(/(\r?\n|\r\n?)/g, "").replace(/,/g , ";");

                switch(questions[i - 1].formatText) {

                    case 'Exclusive':
                    case 'Yes/No':
                    case 'Image':
                    case 'Slider':
                    case 'Branching':
                        header = header + "," + (full?"Q(" + i + ") ":"Q: ") + questionText;
                        header = header + "," + (full?"Q(" + i + ") ":"") + "Comments [Question: " + questionText + "]";
                        break;
                    case 'Multiple':
                    case 'Drag & Drop':

                        for(var j = 0; j < questions[i - 1].data.answers.length; j++) {

                            var answerText = questions[i - 1].data.answers[j].data.text.value.replace(/(\r?\n|\r\n?)/g, "").replace(/,/g , ";");
                            var answerSequence = questions[i - 1].data.answers[j].data.sequence.value;

                            if(answerText != "") {

                                header = header + "," + (full?"Q(" + i + "_" + answerSequence + ") ":"Q(" + answerSequence + ") ") + answerText + "[Question: " + questionText + "]";
                            }
                        }
                        header = header + "," + (full?"Q(" + i + ") ":"") + "Comments [Question: " + questionText + "]";
                        break;
                    case 'Free Text':
                        header = header + "," + (full?"Q(" + i + ") ":"Q: ") + questionText;
                        header = header + "," + (full?"Q(" + i + ") ":"") + "Sentiment [Question: " + questionText + "]";
                        break;
                }
            }

            return header;
        };

        var buildRow = function(userId, rowData, questions, branched) {

            if(branched == "1") {

                var allQuestions = [];

                for(var i = 0; i < questions.length; i++) {

                    allQuestions[questions[i].data.id.value] = ",,";
                }
            }

            var row = userId + "," + rowData['Created'];

            for(var k in rowData['Demographics']) {

                var demValue = rowData['Demographics'][k].replace(/,/g , ";");

                row = row + "," + demValue;
            }

            row = row + "," + rowData['Device'];

            row = row + "," + rowData['Region'];

            for (var j = 0; j < questions.length; j++) {

                k = questions[j].data.id.value;

                switch(questions[j].formatText) {

                    case 'Exclusive':
                    case 'Yes/No':
                    case 'Image':
                    case 'Branching':

                        if(branched == "1") {

                            for (l in allQuestions) {

                                if (l == k) {

                                    if(typeof rowData['Question'][k] != "undefined") {

                                        for (var n in rowData['Question'][k]['Answer']) {

                                            allQuestions[l] = "," + rowData['Question'][k]['Answer'][n]['Text'] + "," + rowData['Question'][k]['Comment'];
                                            break;
                                        }
                                    } else {

                                        allQuestions[l] = ",,";
                                    }
                                }
                            }
                        } else {

                            if(typeof rowData['Question'][k] != "undefined") {

                                for (var l in rowData['Question'][k]['Answer']) {

                                    row = row + "," + rowData['Question'][k]['Answer'][l]['Text'];
                                    break;
                                }

                                row = row + "," + rowData['Question'][k]['Comment'];
                            } else {

                                row = row + ",,"
                            }
                        }

                        break;
                    case 'Slider':

                        if(branched == "1") {

                            for (l in allQuestions) {

                                if (l == k) {

                                    if(typeof rowData['Question'][k] != "undefined") {

                                        for (var n in rowData['Question'][k]['Answer']) {

                                            allQuestions[l] = "," + rowData['Question'][k]['Answer'][n]['Value'] + "," + rowData['Question'][k]['Comment'];
                                            break;
                                        }
                                    }else {

                                        allQuestions[l] = ",,";
                                    }
                                }
                            }
                        } else {

                            if(typeof rowData['Question'][k] != "undefined") {

                                for (var l in rowData['Question'][k]['Answer']) {

                                    row = row + "," + rowData['Question'][k]['Answer'][l]['Value'];
                                    break;
                                }

                                row = row + "," + rowData['Question'][k]['Comment'];
                            } else {

                                row = row + ",,"
                            }
                        }

                        break;
                    case 'Multiple':

                        if(branched == "1") {

                            for (l in allQuestions) {

                                if (l == k) {

                                    var answers = [];

                                    if(typeof rowData['Question'][k] != "undefined") {

                                        for (var m = 1; m <= rowData['Question'][k]['AnswerCount']; m++) {

                                            answers[m] = "";

                                            for (var n in rowData['Question'][k]['Answer']) {

                                                if (rowData['Question'][k]['Answer'][n]['Sequence'] == m) {

                                                    answers[m] = rowData['Question'][k]['Answer'][n]['Text'];
                                                }
                                            }
                                        }

                                        allQuestions[l] = "";

                                        for (var o in answers) {

                                            allQuestions[l] = allQuestions[l] + "," + answers[o];
                                        }

                                        allQuestions[l] = allQuestions[l] + "," + rowData['Question'][k]['Comment']
                                    } else {

                                        var validAnswers = $filter('validAnswer')(questions[j].data.answers);

                                        for (var m = 1; m <= validAnswers.length; m++) {

                                            answers[m] = "";
                                        }

                                        allQuestions[l] = "";

                                        for (var o in answers) {

                                            allQuestions[l] = allQuestions[l] + ",";
                                        }

                                        allQuestions[l] = allQuestions[l] + ",";
                                    }
                                }
                            }
                        } else {

                            var answers = [];

                            if(typeof rowData['Question'][k] != "undefined") {

                                for (var m = 1; m <= rowData['Question'][k]['AnswerCount']; m++) {

                                    answers[m] = "";

                                    for (var n in rowData['Question'][k]['Answer']) {

                                        if(userId == 'vMJkGLNPnbxQ') {
                                        }

                                        if (rowData['Question'][k]['Answer'][n]['Sequence'] == m) {

                                            answers[m] = rowData['Question'][k]['Answer'][n]['Text'];
                                        }
                                    }
                                }

                                for (var o in answers) {

                                    row = row + "," + answers[o];
                                }

                                row = row + "," + rowData['Question'][k]['Comment'];
                            } else {

                                var validAnswers = $filter('validAnswer')(questions[j].data.answers);

                                for (var m = 1; m <= validAnswers.length; m++) {

                                    answers[m] = "";
                                }

                                for (var o in answers) {

                                    row = row + ",";
                                }

                                row = row + ",";
                            }
                        }

                        break;
                    case 'Drag & Drop':

                        if(branched == "1") {

                            for (l in allQuestions) {

                                if (l == k) {

                                    var sortable = [];

                                    if(typeof rowData['Question'][k] != "undefined") {

                                        for (var m in rowData['Question'][k]['Answer']) {

                                            sortable.push(rowData['Question'][k]['Answer'][m])
                                        }

                                        sortable.sort(function (a, b) {

                                            return parseInt(a['Sequence']) - parseInt(b['Sequence'])
                                        });

                                        allQuestions[l] = "";

                                        for (var n in sortable) {

                                            allQuestions[l] = allQuestions[l] + "," + sortable[n]['Value'];
                                        }

                                        allQuestions[l] = allQuestions[l] + "," + rowData['Question'][k]['Comment'];
                                    } else {

                                        var validAnswers = $filter('validAnswer')(questions[j].data.answers);

                                        allQuestions[l] = "";

                                        for (var m = 1; m <= validAnswers.length; m++) {

                                            allQuestions[l] = allQuestions[l] + ",";
                                        }

                                        allQuestions[l] = allQuestions[l] + ",";
                                    }
                                }
                            }
                        } else {

                            var sortable = [];

                            if(typeof rowData['Question'][k] != "undefined") {

                                for (var l in rowData['Question'][k]['Answer']) {

                                    sortable.push(rowData['Question'][k]['Answer'][l])
                                }

                                sortable.sort(function (a, b) {

                                    return parseInt(a['Sequence']) - parseInt(b['Sequence'])
                                });

                                for (var m in sortable) {

                                    row = row + "," + sortable[m]['Value'];
                                }

                                row = row + "," + rowData['Question'][k]['Comment'];
                            } else {

                                var validAnswers = $filter('validAnswer')(questions[j].data.answers);

                                for (var m = 1; m <= validAnswers.length; m++) {

                                    row = row + ",";
                                }

                                row = row + ",";
                            }
                        }

                        break;

                    case 'Free Text':

                        if(branched == "1") {

                            for (l in allQuestions) {

                                if (l == k) {

                                    if(typeof rowData['Question'][k] != "undefined") {

                                        for (var n in rowData['Question'][k]['Answer']) {

                                            allQuestions[l] = "," + rowData['Question'][k]['Answer'][n]['Value'] + "," + rowData['Question'][k]['Sentiment'];
                                            break;
                                        }
                                    } else {

                                        allQuestions[l] = ",,";
                                    }
                                }
                            }
                        } else {

                            if(typeof rowData['Question'][k] != "undefined") {

                                for (var l in rowData['Question'][k]['Answer']) {

                                    row = row + "," + rowData['Question'][k]['Answer'][l]['Value'];
                                    break;
                                }

                                row = row + "," + rowData['Question'][k]['Sentiment'];
                            } else {

                                row = row + ",,"
                            }
                        }

                        break;
                }
            }

            if(branched == "1") {

                for (m in allQuestions) {

                    row = row + allQuestions[m];
                }
            }

            return row;
        };

        var buildBody = function(type, header, dataObjArray, questions, branched) {

            var body = header;

            body = newLine(body);

            for (var k in dataObjArray) {

                if (dataObjArray.hasOwnProperty(k)) {

                    body = body + buildRow(k, dataObjArray[k], questions, branched);
                    body = newLine(body);
                }
            }

            return body;
        };

        var buildDemographicSummaryTitle = function(type, question, pulseHeader) {

            var title = (pulseHeader ? pulseHeader + "" : "");

            title = title + question.data.text.value.replace(/(\r?\n|\r\n?)/g, "").replace(/,/g , ";");
            title = title + " (" + question.formatText + ")";

            return newLine(title);
        };

        var buildDemographicSummaryHeader = function(type, questions, title) {

            var header = (title ? title + "" : "");

            for(var i = 1; i <= questions.length; i++) {

                switch(questions[i - 1].formatText) {

                    case 'Drag & Drop':

                        header = header + (questions[i - 1].formatText == "Drag & Drop"?"":",Total");

                        for(var j = 0; j < questions[i - 1].data.answers.length; j++) {

                            var answerText = questions[i - 1].data.answers[j].data.text.value.replace(/(\r?\n|\r\n?)/g, "").replace(/,/g , ";");

                            if(answerText != "") {

                                header = header + "," + answerText;
                            }
                        }

                        break;
                    case 'Exclusive':
                    case 'Yes/No':
                    case 'Image':
                    case 'Multiple':
                    case 'Branching':

                        header = header + (questions[i - 1].formatText == "Drag & Drop"?"":",Total");

                        for(var j = 0; j < questions[i - 1].data.answers.length; j++) {

                            var answerText = questions[i - 1].data.answers[j].data.text.value.replace(/(\r?\n|\r\n?)/g, "").replace(/,/g , ";");

                            if(answerText != "") {

                                header = header + "," + answerText;
                            }
                        }

                        header = header + ",,,";

                        header = header + (questions[i - 1].formatText == "Drag & Drop"?"":",Total");

                        for(var j = 0; j < questions[i - 1].data.answers.length; j++) {

                            var answerText = questions[i - 1].data.answers[j].data.text.value.replace(/(\r?\n|\r\n?)/g, "").replace(/,/g , ";");

                            if(answerText != "") {

                                header = header + "," + answerText;
                            }
                        }

                        break;
                    case 'Slider':

                        header = header + ",Total";

                        var min = parseInt(questions[i - 1].data.slider_min.value);
                        var max = parseInt(questions[i - 1].data.slider_max.value);
                        var step = parseInt(questions[i - 1].data.slider_step.value);

                        for (var j = min; j <= max; j += step) {

                            header = header + "," + j;
                        }

                        header = header + ",,,";

                        header = header + ",Total";

                        var min = parseInt(questions[i - 1].data.slider_min.value);
                        var max = parseInt(questions[i - 1].data.slider_max.value);
                        var step = parseInt(questions[i - 1].data.slider_step.value);

                        for (var j = min; j <= max; j += step) {

                            header = header + "," + j;
                        }

                        break;
                }
            }

            return header;
        };

        var buildDemographicSummaryRow = function(dataObjArray, questions, demographicText, demographicValue, pulse) {

            var row = demographicValue.text.replace(/,/g , ";");

            for (var i = 0; i < questions.length; i++) {

                var rowTallyArray = [];

                switch(questions[i].formatText) {

                    case 'Multiple':

                        rowTallyArray[0] = 0;
                        var uniqueIdArray = [];

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var answerTally = 0;

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            if(dataObjArray[k]['Demographics'][demographicText].indexOf('; ') != -1 &&
                                                demographicValue.text.replace(/,/g , ";").trim() !== dataObjArray[k]['Demographics'][demographicText].trim()) {

                                                var tempDem = dataObjArray[k]['Demographics'][demographicText].split('; ');

                                                for(var p = 0; p < tempDem.length; p++) {

                                                    if (tempDem[p].trim() == demographicValue.text.replace(/,/g , ";").trim() &&
                                                        (questions[i].data.answers[j].data.id.value)) {

                                                        answerTally++;

                                                        if (uniqueIdArray.indexOf(k) == -1) {

                                                            uniqueIdArray.push(k);
                                                        }
                                                    }
                                                }
                                            } else {

                                                if (dataObjArray[k]['Demographics'][demographicText] == demographicValue.text.replace(/,/g , ";") &&
                                                    (questions[i].data.answers[j].data.id.value)) {

                                                    answerTally++;

                                                    if (uniqueIdArray.indexOf(k) == -1) {

                                                        uniqueIdArray.push(k);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                rowTallyArray[j + 1] = answerTally;
                            }
                        }

                        rowTallyArray[0] = rowTallyArray[0] + uniqueIdArray.length;

                        for (var m = 0; m < rowTallyArray.length; m++) {

                            row = row + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Exclusive':
                    case 'Yes/No':
                    case 'Image':
                    case 'Branching':

                        rowTallyArray[0] = 0;

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var answerTally = 0;

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            if(dataObjArray[k]['Demographics'][demographicText].indexOf('; ') != -1 &&
                                                demographicValue.text.replace(/,/g , ";").trim() !== dataObjArray[k]['Demographics'][demographicText].trim()) {

                                                var tempDem = dataObjArray[k]['Demographics'][demographicText].split('; ');

                                                for(var p = 0; p < tempDem.length; p++) {

                                                    if (tempDem[p].trim() == demographicValue.text.replace(/,/g , ";").trim() &&
                                                        (questions[i].data.answers[j].data.id.value)) {

                                                        answerTally++;
                                                    }
                                                }
                                            } else {

                                                if (dataObjArray[k]['Demographics'][demographicText].trim() == demographicValue.text.replace(/,/g , ";").trim() &&
                                                    (questions[i].data.answers[j].data.id.value)) {

                                                    answerTally++;
                                                }
                                            }
                                        }
                                    }
                                }

                                rowTallyArray[0] = rowTallyArray[0] + answerTally;
                                rowTallyArray[j + 1] = answerTally;
                            }
                        }

                        for (var m = 0; m < rowTallyArray.length; m++) {

                            row = row + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Slider':

                        rowTallyArray[0] = 0;

                        var min = parseInt(questions[i].data.slider_min.value);
                        var max = parseInt(questions[i].data.slider_max.value);
                        var step = parseInt(questions[i].data.slider_step.value);

                        for (var j = min; j <= max; j += step) {

                            var answerTally = 0;

                            for (var k in dataObjArray) {

                                for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                    if (dataObjArray[k]['Question'][questions[i].data.id.value]['Answer'][o]['Value'] == j) {

                                        if(dataObjArray[k]['Demographics'][demographicText].indexOf('; ') != -1 &&
                                            demographicValue.text.replace(/,/g , ";").trim() !== dataObjArray[k]['Demographics'][demographicText].trim()) {

                                            var tempDem = dataObjArray[k]['Demographics'][demographicText].split('; ');

                                            for(var p = 0; p < tempDem.length; p++) {

                                                if (tempDem[p].trim() == demographicValue.text.replace(/,/g , ";").trim()) {

                                                    answerTally++;
                                                }
                                            }
                                        } else {

                                            if (dataObjArray[k]['Demographics'][demographicText] == demographicValue.text.replace(/,/g , ";")) {

                                                answerTally++;
                                            }
                                        }
                                    }
                                }
                            }

                            rowTallyArray[0] = rowTallyArray[0] + answerTally;
                            rowTallyArray[j + 1] = answerTally;
                        }

                        for (var m in rowTallyArray) {

                            row = row + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Drag & Drop':

                        var validAnswers = $filter('validAnswer')(questions[i].data.answers);
                        var validAnswerCount = validAnswers.length;

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var rankingAverage = 0;
                                var rowTotal = 0;
                                var totalsArray = [];
                                var value = [];

                                for (var h = 1; h <= validAnswerCount; h++) {

                                    totalsArray[h] = 0;
                                }

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            if(dataObjArray[k]['Demographics'][demographicText].indexOf('; ') != -1 &&
                                                demographicValue.text.replace(/,/g , ";").trim() !== dataObjArray[k]['Demographics'][demographicText].trim()) {

                                                var tempDem = dataObjArray[k]['Demographics'][demographicText].split('; ');

                                                for(var p = 0; p < tempDem.length; p++) {

                                                    if (tempDem[p].trim() == demographicValue.text.replace(/,/g , ";").trim() &&
                                                        (questions[i].data.answers[j].data.id.value)) {

                                                        value.push(dataObjArray[k]['Question'][questions[i].data.id.value]['Answer'][o]['Value']);
                                                        break;
                                                    }
                                                }
                                            } else {

                                                if (dataObjArray[k]['Demographics'][demographicText] == demographicValue.text.replace(/,/g , ";") &&
                                                    (questions[i].data.answers[j].data.id.value)) {

                                                    value.push(dataObjArray[k]['Question'][questions[i].data.id.value]['Answer'][o]['Value']);
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }

                                for (var m = 0; m < value.length; m++) {

                                    totalsArray[value[m]]++;
                                }

                                //Inverse weighting. i.e. Answer in 1st position gets max answer array length as multiplier
                                for (var n = 1; n < totalsArray.length; n++) {

                                    rankingAverage += totalsArray[n] * ((totalsArray.length - 1) - (n - 1));
                                    rowTotal += totalsArray[n];
                                }

                                rankingAverage = (rowTotal > 0 ? rankingAverage / rowTotal : rowTotal);

                                //Round to 2 decimal places
                                rowTallyArray[j + 1] = Math.round(rankingAverage * 100) / 100;
                            }
                        }

                        for (var m = 1; m < rowTallyArray.length; m++) {

                            row = row + "," + rowTallyArray[m];
                        }

                        break;
                }
            }

            row = row + ",,,";

            for (var i = 0; i < questions.length; i++) {

                var rowTallyArray = [];

                switch(questions[i].formatText) {

                    case 'Multiple':

                        rowTallyArray[0] = 0;
                        var uniqueIdArray = [];

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var answerTally = 0;

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            if(dataObjArray[k]['Demographics'][demographicText].indexOf('; ') != -1 &&
                                                demographicValue.text.replace(/,/g , ";").trim() !== dataObjArray[k]['Demographics'][demographicText].trim()) {

                                                var tempDem = dataObjArray[k]['Demographics'][demographicText].split('; ');

                                                for(var p = 0; p < tempDem.length; p++) {

                                                    if (tempDem[p].trim() == demographicValue.text.replace(/,/g , ";").trim() &&
                                                        (questions[i].data.answers[j].data.id.value)) {

                                                        answerTally++;

                                                        if (uniqueIdArray.indexOf(k) == -1) {

                                                            uniqueIdArray.push(k);
                                                        }
                                                    }
                                                }
                                            } else {

                                                if (dataObjArray[k]['Demographics'][demographicText] == demographicValue.text.replace(/,/g , ";") &&
                                                    (questions[i].data.answers[j].data.id.value)) {

                                                    answerTally++;

                                                    if (uniqueIdArray.indexOf(k) == -1) {

                                                        uniqueIdArray.push(k);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                rowTallyArray[j + 1] = answerTally;
                            }
                        }

                        rowTallyArray[0] = rowTallyArray[0] + uniqueIdArray.length;

                        for (var m = 0; m < rowTallyArray.length; m++) {

                            rowTallyArray[m] = (m != 0 ? (rowTallyArray[0] != 0 ? (((100 / rowTallyArray[0]) * rowTallyArray[m]).toFixed(2).toString() + "%") : "0%") : rowTallyArray[m]);
                            row = row + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Exclusive':
                    case 'Yes/No':
                    case 'Image':
                    case 'Branching':

                        rowTallyArray[0] = 0;

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var answerTally = 0;

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            if(dataObjArray[k]['Demographics'][demographicText].indexOf('; ') != -1 &&
                                                demographicValue.text.replace(/,/g , ";").trim() !== dataObjArray[k]['Demographics'][demographicText].trim()) {

                                                var tempDem = dataObjArray[k]['Demographics'][demographicText].split('; ');

                                                for(var p = 0; p < tempDem.length; p++) {

                                                    if (tempDem[p].trim() == demographicValue.text.replace(/,/g , ";").trim() &&
                                                        (questions[i].data.answers[j].data.id.value)) {

                                                        answerTally++;
                                                    }
                                                }
                                            } else {

                                                if (dataObjArray[k]['Demographics'][demographicText] == demographicValue.text.replace(/,/g , ";") &&
                                                    (questions[i].data.answers[j].data.id.value)) {

                                                    answerTally++;
                                                }
                                            }
                                        }
                                    }
                                }

                                rowTallyArray[0] = rowTallyArray[0] + answerTally;
                                rowTallyArray[j + 1] = answerTally;
                            }
                        }

                        for (var m = 0; m < rowTallyArray.length; m++) {

                            rowTallyArray[m] = (m != 0 ? (rowTallyArray[0] != 0 ? (((100 / rowTallyArray[0]) * rowTallyArray[m]).toFixed(2).toString() + "%") : "0%") : rowTallyArray[m]);
                            row = row + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Slider':

                        rowTallyArray[0] = 0;

                        var min = parseInt(questions[i].data.slider_min.value);
                        var max = parseInt(questions[i].data.slider_max.value);
                        var step = parseInt(questions[i].data.slider_step.value);

                        for (var j = min; j <= max; j += step) {

                            var answerTally = 0;

                            for (var k in dataObjArray) {

                                for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                    if (dataObjArray[k]['Question'][questions[i].data.id.value]['Answer'][o]['Value'] == j) {

                                        if(dataObjArray[k]['Demographics'][demographicText].indexOf('; ') != -1 &&
                                            demographicValue.text.replace(/,/g , ";").trim() !== dataObjArray[k]['Demographics'][demographicText].trim()) {

                                            var tempDem = dataObjArray[k]['Demographics'][demographicText].split('; ');

                                            for(var p = 0; p < tempDem.length; p++) {

                                                if (tempDem[p].trim() == demographicValue.text.replace(/,/g , ";").trim()) {

                                                    answerTally++;
                                                }
                                            }
                                        } else {

                                            if (dataObjArray[k]['Demographics'][demographicText] == demographicValue.text.replace(/,/g , ";")) {

                                                answerTally++;
                                            }
                                        }
                                    }
                                }
                            }

                            rowTallyArray[0] = rowTallyArray[0] + answerTally;
                            rowTallyArray[j + 1] = answerTally;
                        }

                        for (var m in rowTallyArray) {

                            rowTallyArray[m] = (m != 0 ? (rowTallyArray[0] != 0 ? (((100 / rowTallyArray[0]) * rowTallyArray[m]).toFixed(2).toString() + "%") : "0%") : rowTallyArray[m]);
                            row = row + "," + rowTallyArray[m];
                        }

                        break;
                }
            }

            return row;
        };

        var buildDemographicSummaryTotalRow = function(dataObjArray, questions, pulse) {

            var totalRow = "Total";

            for (var i = 0; i < questions.length; i++) {

                var rowTallyArray = [];

                switch(questions[i].formatText) {

                    case 'Multiple':

                        rowTallyArray[0] = 0;
                        var uniqueIdArray = [];

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var answerTally = 0;

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            answerTally++;

                                            if (uniqueIdArray.indexOf(k) == -1) {

                                                uniqueIdArray.push(k);
                                            }
                                        }
                                    }
                                }

                                rowTallyArray[j + 1] = answerTally;
                            }
                        }

                        rowTallyArray[0] = rowTallyArray[0] + uniqueIdArray.length;

                        for (var m = 0; m < rowTallyArray.length; m++) {

                            totalRow = totalRow + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Exclusive':
                    case 'Yes/No':
                    case 'Image':
                    case 'Branching':

                        rowTallyArray[0] = 0;

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var answerTally = 0;

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            answerTally++;
                                        }
                                    }
                                }

                                rowTallyArray[0] = rowTallyArray[0] + answerTally;
                                rowTallyArray[j + 1] = answerTally;
                            }
                        }

                        for (var m = 0; m < rowTallyArray.length; m++) {

                            totalRow = totalRow + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Slider':

                        rowTallyArray[0] = 0;

                        var min = parseInt(questions[i].data.slider_min.value);
                        var max = parseInt(questions[i].data.slider_max.value);
                        var step = parseInt(questions[i].data.slider_step.value);

                        for (var j = min; j <= max; j += step) {

                                var answerTally = 0;

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (dataObjArray[k]['Question'][questions[i].data.id.value]['Answer'][o]['Value'] == j) {

                                            answerTally++;
                                        }
                                    }
                                }

                                rowTallyArray[0] = rowTallyArray[0] + answerTally;
                                rowTallyArray[j + 1] = answerTally;
                        }

                        for (var m in rowTallyArray) {

                            totalRow = totalRow + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Drag & Drop':

                        var validAnswers = $filter('validAnswer')(questions[i].data.answers);
                        var validAnswerCount = validAnswers.length;

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var rankingAverage = 0;
                                var totalsArray = [];
                                var value = [];

                                for (var l = 1; l <= validAnswerCount; l++) {

                                    totalsArray[l] = 0;
                                }

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            value.push(dataObjArray[k]['Question'][questions[i].data.id.value]['Answer'][o]['Value']);
                                        }
                                    }
                                }

                                for (var m = 0; m < value.length; m++) {

                                    totalsArray[value[m]]++;
                                }

                                //Inverse weighting. i.e. Answer in 1st position gets max answer array length as multiplier
                                for (var n = 1; n < totalsArray.length; n++) {

                                    rankingAverage += totalsArray[n] * ((totalsArray.length - 1) - (n - 1));
                                }

                                rankingAverage = rankingAverage / (pulse.data.responses.value);

                                //Round to 2 decimal places
                                totalRow = totalRow + "," + Math.round(rankingAverage * 100) / 100;
                            }
                        }

                        break;

                    case 'Free Text':

                        totalRow = "Summary data isn't currently available for open text questions; but we're working on it! For now you can download the Raw Data export to view all OT responses and their sentiment score.";
                        break;
                }
            }

            totalRow = totalRow + ",,,";

            for (var i = 0; i < questions.length; i++) {

                var rowTallyArray = [];

                switch(questions[i].formatText) {

                    case 'Multiple':

                        rowTallyArray[0] = 0;
                        var uniqueIdArray = [];

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var answerTally = 0;

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            answerTally++;

                                            if (uniqueIdArray.indexOf(k) == -1) {

                                                uniqueIdArray.push(k);
                                            }
                                        }
                                    }
                                }

                                rowTallyArray[j + 1] = answerTally;
                            }
                        }

                        rowTallyArray[0] = rowTallyArray[0] + uniqueIdArray.length;

                        for (var m = 0; m < rowTallyArray.length; m++) {

                            rowTallyArray[m] = (m != 0 ? (rowTallyArray[0] != 0 ? (((100 / rowTallyArray[0]) * rowTallyArray[m]).toFixed(2).toString() + "%") : "0%") : rowTallyArray[m]);
                            totalRow = totalRow + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Exclusive':
                    case 'Yes/No':
                    case 'Image':
                    case 'Branching':

                        rowTallyArray[0] = 0;

                        for (var j = 0; j < questions[i].data.answers.length; j++) {

                            if (questions[i].data.answers[j].data.text.value != "") {

                                var answerTally = 0;

                                for (var k in dataObjArray) {

                                    for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                        if (questions[i].data.answers[j].data.id.value == o) {

                                            answerTally++;
                                        }
                                    }
                                }

                                rowTallyArray[0] = rowTallyArray[0] + answerTally;
                                rowTallyArray[j + 1] = answerTally;
                            }
                        }

                        for (var m = 0; m < rowTallyArray.length; m++) {

                            rowTallyArray[m] = (m != 0 ? (rowTallyArray[0] != 0 ? (((100 / rowTallyArray[0]) * rowTallyArray[m]).toFixed(2).toString() + "%") : "0%") : rowTallyArray[m]);
                            totalRow = totalRow + "," + rowTallyArray[m];
                        }

                        break;
                    case 'Slider':

                        rowTallyArray[0] = 0;

                        var min = parseInt(questions[i].data.slider_min.value);
                        var max = parseInt(questions[i].data.slider_max.value);
                        var step = parseInt(questions[i].data.slider_step.value);

                        for (var j = min; j <= max; j += step) {

                            var answerTally = 0;

                            for (var k in dataObjArray) {

                                for (var o in dataObjArray[k]['Question'][questions[i].data.id.value]['Answer']) {

                                    if (dataObjArray[k]['Question'][questions[i].data.id.value]['Answer'][o]['Value'] == j) {

                                        answerTally++;
                                    }
                                }
                            }

                            rowTallyArray[0] = rowTallyArray[0] + answerTally;
                            rowTallyArray[j + 1] = answerTally;
                        }

                        for (var m in rowTallyArray) {

                            rowTallyArray[m] = (m != 0 ? (rowTallyArray[0] != 0 ? (((100 / rowTallyArray[0]) * rowTallyArray[m]).toFixed(2).toString() + "%") : "0%") : rowTallyArray[m]);
                            totalRow = totalRow + "," + rowTallyArray[m];
                        }

                        break;
                }
            }

            return totalRow;
        };

        var buildDemographicSummaryBody = function(type, header, dataObjArray, questions, pulse, demographics) {

            var body = header;

            body = newLine(body);

            body = body + buildDemographicSummaryTotalRow(dataObjArray, questions, pulse);

            body = newLine(body);

            for(var i = 0; i < demographics.length; i++) {

                if(isSelected(demographics[i])) {

                    body = body + demographics[i].text;
                    body = newLine(body);

                    for (var j = 0; j < demographics[i].values.options.length; j++) {

                        if (demographics[i].values.options[j].option.selected) {

                            body = body + buildDemographicSummaryRow(dataObjArray, questions, demographics[i].text, demographics[i].values.options[j].option, pulse);
                            body = newLine(body);
                        }
                    }
                }
            }

            return body;
        };

        var buildObjArray = function(question, dataObjArray, allRegions) {

            for(var i = 1; i < question.rawDataTable.length; i++) {

                var secondaryDataArray = question.rawDataTable[i];

                if(typeof dataObjArray[secondaryDataArray[0]] == "undefined") {

                    dataObjArray[secondaryDataArray[0]] = {};
                }

                dataObjArray[secondaryDataArray[0]]['Created'] = secondaryDataArray[8];

                dataObjArray[secondaryDataArray[0]]['Region'] = "";

                for(var g in allRegions) {

                    if(parseInt(allRegions[g].id) == parseInt(secondaryDataArray[12])) {

                        dataObjArray[secondaryDataArray[0]]['Region'] = allRegions[g].code;
                    }
                }

                dataObjArray[secondaryDataArray[0]]['Device'] = (secondaryDataArray[1] != null?secondaryDataArray[1].replace(/,/g , ";"):"");
                dataObjArray[secondaryDataArray[0]]['Demographics'] = {};

                for(var k in secondaryDataArray) {

                    if(k > 14) {

                        dataObjArray[secondaryDataArray[0]]['Demographics'][question.rawDataTable[0][k]] = secondaryDataArray[k].replace(/,/g , ";").trim();
                    }
                }

                if(typeof dataObjArray[secondaryDataArray[0]]['Question'] == "undefined") {

                    dataObjArray[secondaryDataArray[0]]['Question'] = {};
                }

                if(typeof dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]] == "undefined") {

                    dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]] =  {}
                }

                dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Type'] = question.formatText;

                var validAnswers = $filter('validAnswer')(question.data.answers);
                dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['AnswerCount'] = validAnswers.length;

                if(typeof dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Answer'] == "undefined") {

                    dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Answer'] = {};
                }

                if(secondaryDataArray[9] != null) {

                    dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Comment'] = secondaryDataArray[9].replace(/,/g , ";").replace(/(\r\n|\n|\r)/gm," ");
                } else {

                    dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Comment'] = "";
                }

                if(typeof dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Answer'][secondaryDataArray[6]] == "undefined") {

                    dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Answer'][secondaryDataArray[6]] =  {}
                }

                if(secondaryDataArray[5] != null) {

                    dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Answer'][secondaryDataArray[6]]['Text'] = secondaryDataArray[5].replace(/,/g , ";").replace(/(\r\n|\n|\r)/gm," ");
                }

                if(secondaryDataArray[7] != null) {

                    dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Answer'][secondaryDataArray[6]]['Value'] = secondaryDataArray[7].replace(/,/g , ";").replace(/(\r\n|\n|\r)/gm," ");
                }

                if(secondaryDataArray[11] != null) {

                    dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Sentiment'] = secondaryDataArray[11];
                }

                if(typeof question.data.answers != "undefined") {

                    for (var j = 0; j < question.data.answers.length; j++) {

                        if (question.data.answers[j].data.id.value == secondaryDataArray[6]) {

                            dataObjArray[secondaryDataArray[0]]['Question'][secondaryDataArray[4]]['Answer'][secondaryDataArray[6]]['Sequence'] = question.data.answers[j].data.sequence.value;
                        }
                    }
                }
            }

            return dataObjArray;
        };

        var addQuestion = function(type, question, dataObjArray, allRegions) {

            return buildObjArray(question, dataObjArray, allRegions);
        };

        var newLine = function(dataTable) {

            return dataTable + "\r\n";
        };

        var encodeUri = function(type, formattedDataTable) {

            var BOM = "\uFEFF";
            formattedDataTable = BOM + formattedDataTable;

            return new Blob([formattedDataTable],{type : 'text/csv'});
        };

        var prepareDownloadLink = function(ext, encodedUri, filename, type) {

            var link = document.createElement("a");

            link.setAttribute("href", window.URL.createObjectURL(encodedUri));

            link.setAttribute("download", buildFileName(filename, ext, type));

            return link;
        };

        var saveFile = function(blob, filename) {

            FileSaver.saveAs(blob, filename);
        };

        var isSelected = function(demographic){

            if(typeof demographic != "undefined") {

                var selected = false;

                for(var index = 0; index < demographic.values.options.length; index++) {

                    if(demographic.values.options[index].option.selected){

                        selected = true;
                    }
                }

                return selected;
            }
        };

        return {
            buildPulseHeader: buildPulseHeader,
            buildHeader: buildHeader,
            buildBody: buildBody,
            addQuestion: addQuestion,
            newLine: newLine,
            buildDemographicSummaryTitle: buildDemographicSummaryTitle,
            buildDemographicSummaryHeader: buildDemographicSummaryHeader,
            buildDemographicSummaryBody: buildDemographicSummaryBody,
            encodeUri: encodeUri,
            saveFile: saveFile,
            prepareDownloadLink: prepareDownloadLink
        }
    }]);
