/**
 * Created by mike on 06/01/16.
 */
onePulse.directive('panel', function() {


    return {
        templateUrl: '/app/views/elements/forms/panel.html',
    }
});
