/**
 * Created by barry on 06/10/2017.
 *
 * Handles all direct url actions where an ID may be provided. Avoids need for panels/pulses arrays to pre-exist in local storage
 *
 */
onePulse.controller('DirectController', ['$scope', '$rootScope', 'DataService', '$routeParams', '$location',
    function ($scope, $rootScope, DataService, $routeParams, $location) {

    var action = typeof $routeParams.action != "undefined" ? $routeParams.action : null ;
    var type = typeof $routeParams.type != "undefined" ? $routeParams.type : null ;
    var target = typeof $routeParams.target != "undefined" ? $routeParams.target : null ;
    var id = typeof $routeParams.id != "undefined" ? $routeParams.id : null ;

    //Create new pulse and set Panel (Group)
    if(action == 'create' && type == 'pulse' && target == 'panel') {

        if(id != null) {

            //Create pulse
            DataService.create('pulse').then(function (result) {

                var pulse = result.pulse;

                //Set the selected group
                for (var i = 0; i < pulse.data.group.options.length; i++) {

                    pulse.data.group.options[i].option.selected = (pulse.data.group.options[i].option.id == id)
                }

                //update pulse and redirect to pulse editor
                DataService.submit('pulse', pulse).then(function () {

                    $rootScope.renderComplete = true;
                    $rootScope.loadingStateText = "";
                    $location.path('/pulse/' + pulse.data.id.value + '/edit');
                });
            });
        }
    }

    if(action == 'view' && type == 'pulse' && target == 'store') {

        if(id != null) {

            data_token = findTokenById(DataService.grab('purchasedPulses'), $routeParams.id);
        }
    }

}]);