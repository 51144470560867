/**
 * Created by Barry on 30/11/16.
 *
 * Directive for displaying a dynamic help panel
 */
onePulse.directive('helpPanel', ['ContentService', '$location', function(ContentService, $location) {

    linkFunction = function($scope, element, attributes) {

        var helpRequestRoute = $location.path().split('/')[1];
        var helpRequestElement = attributes.data;

        $scope.fetchContent = function() {

            ContentService.fetchContent('help', helpRequestRoute, helpRequestElement).then(function(result) {

                $scope.content = result.content;
            });
        };
    };

    return {
        restrict: 'A',
        priority: 0,
        replace: false,
        scope: true,
        link:linkFunction
    }
}]);
