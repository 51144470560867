/**
 * Created by Fausto on 2018/07/13.
 *
 * Pulse mobile controls
 */

onePulse.directive('mobilePulseControls', [
    function () {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: false,
        templateUrl:'/app/views/elements/pulse/mobilePulseControls.html',

        link: function postLink(scope, element, iAttrs) {

        }
    };
}]);