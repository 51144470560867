/**
 * Created by Barry on 13/06/2017.
 *
 * Directive for displaying a question sentiment word cloud card
 */
onePulse.directive('questionSentimentWordCloud', ['$window', 'DataService', 'FeatureService', 'ExportService', 'CONSTANTS', '$timeout',
    function($window, DataService, FeatureService, ExportService, CONSTANTS, $timeout) {

        linkFunction = function($scope, element, attributes) {

            //Determine question format
            if (typeof $scope.question.data.format.options != 'undefined') {

                for (var i = 0; i < $scope.question.data.format.options.length; i++) {

                    if ($scope.question.data.format.options[i].option.selected == true) {

                        $scope.question.formatText = $scope.question.data.format.options[i].option.text;
                    }
                }
            } else {

                $scope.question.formatText = $scope.question.data.format.value;
            }

            //Only define link function if question format is correct
            if ($scope.question.formatText == 'Free Text') {

                $scope.wordCloudDataLoaded = false;
                $scope.resultsDataLoaded = false;
                $scope.sentimentDataLoaded = false;

                $scope.wc = {};

                //$scope.wc.height = $window.innerHeight * 0.5;
                //$scope.wc.width = element[0].offsetWidth;

                $scope.wc.height = 300;
                $scope.wc.width = 560;

                $scope.wc.padding = 8;

                $scope.wc.words = [];

                $scope.originalResults = null;

                $scope.wc.text = function(d) {
                    return d.word;
                };

                $scope.wc.rotate = function () {
                    return ~~(Math.random() * 2) * 90;
                };

                $scope.wc.wordClicked = function (word) {
                    //alert('text: ' + word.text + ',size: ' + word.size);
                };

                var resizeWordCloud = function() {
                    $timeout(function() {

                        var element = $('#wordsCloud');
                        var height = $window.innerHeight * 0.25;
                        element.height(height);

                        if(typeof element[0] != "undefined") {

                            var width = element[0].offsetWidth;
                            $scope.wc.width = width;
                            $scope.wc.height = height;
                        }
                    })
                };

                var buildWordCloudCount = function (dataTable) {

                    var sentimentWords = [];

                    for (var i = 1; i < dataTable.length; i++) {

                        var splitSentiment = dataTable[i][7].split(" ");

                        var regex = "/[.,\/#!$%\^&\*;:{}=\-_`~()]/g";

                        for (var j = 0; j < splitSentiment.length; j++) {

                            if (CONSTANTS.EXCLUDED_SENTIMENT_WC_WORDS.indexOf(splitSentiment[j].replace(regex, "").trim().toLowerCase()) == -1) {

                                if (typeof sentimentWords[splitSentiment[j].replace(regex, "").trim()] != "undefined") {

                                    sentimentWords[splitSentiment[j].replace(regex, "").trim()]++;
                                } else {

                                    sentimentWords[splitSentiment[j].replace(regex, "").trim()] = 1;
                                }
                            }
                        }
                    }

                    sentimentWords.sort(sortNumber);

                    //TODO: Splice array to limit words. WC may blow up - need to monitor. Maybe 30 words total

                    var wordCount = sentimentWords.length;

                    for (var word in sentimentWords) {

                        $scope.wc.words.push({
                            text: word,
                            size: sentimentWords[word] * 20,
                            color: '#000000' //#473fa3
                        });
                    }
                };

                var buildWordCloudEntities = function (entities) {

                    var sentimentWords = [];
                    var highestSalience = 0.00;
                    var multiplier = 1;
                    var maxEntities = 30;

                    entities.sort(function(a,b) { return b['salience'] - a['salience'];});

                    for (var i = 0; i < maxEntities; i++) {

                        if(typeof entities[i] != "undefined") {

                            sentimentWords.push(entities[i])
                        }
                    }

                    $scope.wc.words = [];

                    highestSalience = parseFloat(sentimentWords[0].salience);

                    multiplier = 60 / highestSalience;

                    for (var k = 0; k < sentimentWords.length; k++) {

                        if(sentimentWords[k].salience < 60.00 && multiplier > 1) {

                            sentimentWords[k].salience = sentimentWords[k].salience * multiplier;
                        }

                        if(sentimentWords[k].salience < 12.00) {

                            sentimentWords[k].salience = 12.00
                        }

                        if(sentimentWords[k].salience > 60.00) {

                            sentimentWords[k].salience = 60.00
                        }
                    }

                    for (var j = 0; j < sentimentWords.length; j++) {

                        if(sentimentWords[j].salience) {

                            $scope.wc.words.push({
                                text: sentimentWords[j].name,
                                size: sentimentWords[j].salience,
                                color: '#000000' //#473fa3
                            });
                        }
                    }
                };

                var getQuestionData = function () {

                    $scope.wordCloudDataLoaded = false;
                    $scope.resultsDataLoaded = false;
                    $scope.sentimentDataLoaded = false;

                    $scope.$parent.showOTChartSpinner = true;

                    DataService.getQuestionData($scope.question.data_token).then(function (result) {

                        DataService.getQuestionEntities($scope.question.data_token).then(function (result2) {

                            if(result2.entities.length > 0) {

                                buildWordCloudEntities(result2.entities);

                                resizeWordCloud();
                            }

                            //buildWordCloudCount(result.data_table);

                            $scope.originalResults = angular.copy(result);

                            $scope.dataTable = angular.copy(result.data_table);

                            $scope.question.dataTable = $scope.dataTable;
                            $scope.question.rawDataTable = result.data_table;

                            $scope.result = result;

                            if ($scope.result.question.total_responses > 1) {

                                $scope.showFilterControls = true;
                            }

                            //Total number of unique participants
                            $scope.uniqueIdArray = [];

                            for (var i = 1; i < $scope.result.data_table.length; i++) {

                                $scope.result.data_table[i][12] = $scope.result.data_table[i][12].toString();

                                if ($scope.uniqueIdArray.indexOf($scope.result.data_table[i][0]) == -1) {

                                    $scope.uniqueIdArray.push($scope.result.data_table[i][0]);
                                }
                            }

                            $scope.question.total_responses_multiple = $scope.uniqueIdArray.length;

                            $scope.wordCloudDataLoaded = true;
                            $scope.$parent.showOTChartSpinner = false;

                            $scope.initResponseHandler(result);
                        });
                    });
                };

                angular.element($window).bind('resize', resizeWordCloud);
                getQuestionData();

                $scope.initResponseHandler = function (result) {

                    $scope.$on('event:user_responded', function ($event, $payload) {

                        $scope.wordCloudDataLoaded = true;

                        angular.forEach($payload.data[$scope.question.data.id.value], function (row) {

                            //API can not be depended on to always return a string. Need to make sure type is properly set
                            for (var k = 0; k < row.length; k++) {

                                if (row[k] != null) {

                                    row[k] = row[k].toString();
                                }
                            }

                            //Push ably response row into results for filtered chart and region filter
                            result.data_table.push(row);
                            $scope.originalResults.data_table.push(row);

                            //buildWordCloudCount(result.data_table);

                            DataService.getQuestionEntities($scope.question.data_token).then(function (result2) {

                                if (result2.entities.length > 0) {

                                    buildWordCloudEntities(result2.entities);
                                }
                            });
                        });

                        $scope.question.dataTable = $scope.dataTable;
                        $scope.question.rawDataTable = result.data_table;
                    });
                };
            }
        };

        return {
            restrict: 'E',
            priority: 0,
            replace: false,
            scope: false,
            link:linkFunction,
            templateUrl:'/app/views/elements/questionSentimentWordCloud.html'
        }
    }]);
