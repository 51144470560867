/**
 * Created by barry on 27/10/2016.
 *
 * Application Constants
 */
onePulse.constant("CONSTANTS", {

    "APP_VERSION": "2.44.0",

    "FEATURES": {
        "PRIVATE_COMMUNITIES"       : "communities_private",
        "CHAINED_PULSING"           : "chained_pulsing",
        "RAW_DATA_EXTRACTS"         : "data_extracts",
        "SPREADSHEET_DATA_EXTRACTS" : "data_extracts",
        "SUMMARY_DATA_EXTRACTS"     : "summary_data_extracts",
        "BASIC_DEMOGRAPHICS"        : "demographics_basic",
        "FULL_DEMOGRAPHICS"         : "demographics_full",
        "WEB_SUPPORT"               : "support_web",
        "PHONE_SUPPORT"             : "support_phone",
        "PARTICIPANTS"              : "participants",
        "USER_MANAGEMENT"           : "",
        "UNLIMITED_USERS"           : "",
        "ACCOUNT_LOGINS"            : "account_logins",
        "OPEN_TEXT"                 : "open_text",
        "ONEPULSE_STORE"            : "catalogue_access",
        "FREE_PULSE"                : "free_pulse",
        "STORE_DISCOUNT"            : "store_discount",
        "ANSWER_TYPES"              : "answer_types",
        "QUOTAS"                    : "quotas",
        "EXTENDED_ANSWERS"          : "extended_answers",
        "WHITE_LABEL"               : "white_label",
        "CUSTOM_AUDIENCE"           : "custom_audience",
        "EXTENDED_CHAR_LIMIT"       : "extended_char_limit"
    },

    "PULSE_LITE": "2",
    "PULSE_STANDARD": "5",

    "FEATURES_DISPLAY_ORDER": ['demographics_full', 'participants', 'account_logins', 'data_extracts', 'open_text',
        'chained_pulsing', 'communities_private', 'free_pulse', 'onepulse_store', 'store_discount', 'support_phone'],

    "PULSE_CARD_COLORS": ['#01579b', '#42a5f5' , '#80d8ff', '#01695c', '#338e23', '#4caf50', '#fed502', '#ffab00', '#ff5721', '#880d4f', '#e91d63', '#ff4081'],
    "GOOGLE_RECAPTCHA_SITE_KEY": "6LeUxuQZAAAAAOh4L54EXGuc6dVyimONdKjgC1I7",

    "DEVICE_TYPE_DESKTOP": "desktop",
    "DEVICE_TYPE_MOBILE" : "mobile",

    //Roles and rights
    "ROLES": {
        "CLIENT_ADMIN"              : {"label": "client:admin", "rights": [
                "close_account",
                "purchase_pulse_edit_sub_payment",
                "edit_user_workspace",
                "publish_pulse_company",
                "publish_pulse_anon",
                "create_edit_pulse",
                "view_pulse_results",
                "customization"
            ]},
        "CLIENT_PURCHASE_MANAGER"   : {"label": "client:purchase_manager", "rights": [
                "purchase_pulse_edit_sub_payment",
                "edit_user_workspace",
                "publish_pulse_company",
                "publish_pulse_anon",
                "create_edit_pulse",
                "view_pulse_results"
            ]},
        "CLIENT_ACCOUNT_MANAGER"    : {"label": "client:account_manager", "rights": [
                "edit_user_workspace",
                "publish_pulse_company",
                "publish_pulse_anon",
                "create_edit_pulse",
                "view_pulse_results"
            ]},
        "CLIENT_BRAND_PUBLISHER"    : {"label": "client:brand_publisher", "rights": [
                "publish_pulse_company",
                "publish_pulse_anon",
                "create_edit_pulse",
                "view_pulse_results"
            ]},
        "CLIENT_PUBLISHER"          : {"label": "client:publisher", "rights": [
                "publish_pulse_anon",
                "create_edit_pulse",
                "view_pulse_results"
            ]},
        "CLIENT_WRITER"             : {"label": "client:writer", "rights": [
                "create_edit_pulse",
                "view_pulse_results"
            ]},
        "CLIENT_ANALYST"            : {"label": "client:analyst", "rights": [
                "view_pulse_results"
            ]},
        "CLIENT_NEW"                : {"label": "client:new", "rights": [

            ]}
    },

    "RIGHTS": {
        "CLOSE_ACCOUNT"                     : "close_account",
        "PURCHASE_PULSE_EDIT_SUB_PAYMENT"   : "purchase_pulse_edit_sub_payment",
        "EDIT_USER_WORKSPACE"               : "edit_user_workspace",
        "PUBLISH_PULSE_COMPANY"             : "publish_pulse_company",
        "PUBLISH_PULSE_ANON"                : "publish_pulse_anon",
        "CREATE_EDIT_PULSE"                 : "create_edit_pulse",
        "VIEW_PULSE_RESULTS"                : "view_pulse_results",
        "CUSTOMIZATION"                     : "customization"
    },

    "VAT": 20,
    "MIN_PULSE_PARTICIPANTS": 500,

    "ANDROID_APP"   : "https://play.google.com/store/apps/details?id=com.rawduck.onepulse",
    "IOS_APP"       : "https://itunes.apple.com/gb/app/onepulse/id705905081",

    "DZ_SEGMENT_KEY"        : "cdEdfymwTsh5o1aUfZhl53vyMv8Apj4Q",
    "UK_SEGMENT_KEY"        : "iuy7kPR53A1dbzbkGaoEgeuwBqZcm38U",
    "US_SEGMENT_KEY"        : "kC5XtINpCWnECM3z83dTNOnTdh1RuQBo", //"MNIB3tbkgZUR8egw5j9b4RCONdRF1php",
    "BETA_SEGMENT_KEY"      : "HCAoLkmhwdK7nyEVJZm8b9p8VNyZ1BMY",
    "DEV_SEGMENT_KEY"       : "GfgTMPstruo8M3ExfBGcVkydAgUKHGf2",
    "FEATURE_SEGMENT_KEY"   : "HCAoLkmhwdK7nyEVJZm8b9p8VNyZ1BMY",
    "RELEASE_SEGMENT_KEY"   : "kC5XtINpCWnECM3z83dTNOnTdh1RuQBo", //Same as US (Pre-prod)

    "DZ_API"        : "https://dz-web.onepulse.com/api/",
    "UK_API"        : "https://uk-web.onepulse.com/api/",
    "US_API"        : "https://us-web.onepulse.com/api/",
    "BETA_API"      : "https://beta-api.onepulse.com/api/",
    "DEV_API"       : "https://dev-api.onepulse.com/api/",
    "FEATURE_API"   : "https://feature-api.onepulse.com/api/",
    "RELEASE_API"   : "https://release-api.onepulse.com/api/",

    "CMS_API": "https://cms.onepulse.com/",

    "APP_TOKEN": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOnsicm9sZSI6WyJsb2dpbioiLCJlbnJvbCoiLCJ2ZXJpZnkqIiwibWV0YSoiLCJ0cmFuc2xhdGUqIl19LCJzdWIiOiJhcHBsaWNhdGlvbiIsImlzcyI6Imh0dHA6XC9cL29uZXB1bHNlLnYyLmFwaSIsImlhdCI6IjE0NDk4MjM3MjUiLCJleHAiOiIxNjA3NTAzNzI1IiwibmJmIjoiMTQ0OTgyMzcyNSIsImp0aSI6ImViYzI4NTgxYWNmZDVkZjQ0ZmUwMGY4NGQzMzA1NDQzIn0.b-kSgigtlWhtZPPQAY6z4vD5eH16pv7eCXnIkSQ9jzc",

    "DZ_ABLY_AUTH_ENDPOINT"             : "https://dz-api.onepulse.com/api/client/ably/auth",
    "DZ_ABLY_AUTH_ENDPOINT_LITE"        : "https://dz-api.onepulse.com/api/lite/ably/auth",
    "DZ_ABLY_AUTH_ENDPOINT_SHARE"       : "https://dz-api.onepulse.com/api/meta/sharing/auth",
    "UK_ABLY_AUTH_ENDPOINT"             : "https://uk-web.onepulse.com/api/client/ably/auth",
    "UK_ABLY_AUTH_ENDPOINT_LITE"        : "https://uk-web.onepulse.com/api/lite/ably/auth",
    "UK_ABLY_AUTH_ENDPOINT_SHARE"       : "https://uk-api.onepulse.com/api/meta/sharing/auth",
    "US_ABLY_AUTH_ENDPOINT"             : "https://us-web.onepulse.com/api/client/ably/auth",
    "US_ABLY_AUTH_ENDPOINT_LITE"        : "https://us-web.onepulse.com/api/lite/ably/auth",
    "US_ABLY_AUTH_ENDPOINT_SHARE"       : "https://us-api.onepulse.com/api/meta/sharing/auth",
    "BETA_ABLY_AUTH_ENDPOINT"           : "https://beta-api.onepulse.com/api/client/ably/auth",
    "BETA_ABLY_AUTH_ENDPOINT_LITE"      : "https://beta-api.onepulse.com/api/lite/ably/auth",
    "BETA_ABLY_AUTH_ENDPOINT_SHARE"     : "https://beta-api.onepulse.com/api/meta/sharing/auth",
    "DEV_ABLY_AUTH_ENDPOINT"            : "https://dev-api.onepulse.com/api/client/ably/auth",
    "DEV_ABLY_AUTH_ENDPOINT_LITE"       : "https://dev-api.onepulse.com/api/lite/ably/auth",
    "DEV_ABLY_AUTH_ENDPOINT_SHARE"      : "https://dev-api.onepulse.com/api/meta/sharing/auth",
    "FEATURE_ABLY_AUTH_ENDPOINT"        : "https://feature-api.onepulse.com/api/client/ably/auth",
    "FEATURE_ABLY_AUTH_ENDPOINT_LITE"   : "https://feature-api.onepulse.com/api/lite/ably/auth",
    "FEATURE_ABLY_AUTH_ENDPOINT_SHARE"  : "https://feature-api.onepulse.com/api/meta/sharing/auth",
    "RELEASE_ABLY_AUTH_ENDPOINT"        : "https://release-api.onepulse.com/api/client/ably/auth",
    "RELEASE_ABLY_AUTH_ENDPOINT_LITE"   : "https://release-api.onepulse.com/api/lite/ably/auth",
    "RELEASE_ABLY_AUTH_ENDPOINT_SHARE"  : "https://release-api.onepulse.com/api/meta/sharing/auth",

    "DEV_ENV"       : "DEV",
    "BETA_ENV"      : "BETA",
    "DZ_ENV"        : "DZ",
    "UK_ENV"        : "UK",
    "US_ENV"        : "US",
    "FEATURE_ENV"   : "FEATURE",
    "RELEASE_ENV"   : "RELEASE",

    "DEV_URI"       : "https://dev.onepulse.com",
    "BETA_URI"      : "https://beta.onepulse.com",
    "DZ_URI"        : "https://dz.onepulse.com",
    "UK_URI"        : "https://onepulse.com",
    "US_URI"        : "https://onepulse.com",
    "FEATURE_URI"   : "https://feature.onepulse.com",
    "RELEASE_URI"   : "https://release.onepulse.com",

    "BETA_WEB_RESPONSE_URI" : "https://dev-web.onepulse.com",
    "WEB_RESPONSE_URI"      : "https://web.onepulse.com",

    //White Label clients
    "WL_ODD"        : "odd",
    "WL_TRIPTK"     : "triptk",
    "WL_DELOITTE"   : "deloitte",
    "WL_VIGA"       : "viga",

    "MOBILE_APP_SCHEME": "onepulse://onepul.se/",

    //Browser name and minimum supported version (* is wildcard = all versions)
    "SUPPORTED_BROWSERS": [
        {"name": "Chrome"   , "version": "*"},
        {"name": "Firefox"  , "version": "*"},
        {"name": "Safari"   , "version": "*"},
        {"name": "IE"       , "version": "9"}
    ],

    "DATE_PICKER_START" : "date_picker_start",
    "DATE_PICKER_END"   : "date_picker_end",

    "I18N_US_LANGUAGE"      : "en_US",
    "I18N_US_DATE_FORMAT"   : "yyyy-mm-dd",
    "I18N_US_CURRENCY"      : "$",
    "I18N_US_CONTENT"       : "",
    "I18N_UK_LANGUAGE"      : "en_GB",
    "I18N_UK_DATE_FORMAT"   : "dd/mm/yyyy",
    "I18N_UK_CURRENCY"      : "£",
    "I18N_UK_CONTENT"       : "",
    "I18N_DZ_LANGUAGE"      : "fr_DZ",
    "I18N_DZ_DATE_FORMAT"   : "dd/mm/yyyy",
    "I18N_DZ_CURRENCY"      : "€",
    "I18N_DZ_CONTENT"       : "",

    //results|sentiment|wordCloud
    "QUESTION_FREE_TEXT_INITIAL_DISPLAY_TYPE": "results",

    "PULSE_DATA_TABLE_HEADER": [
        "User ID",
        "Device",
        "Model",
        "Question",
        "Question ID",
        "Answer",
        "Answer ID",
        "Value",
        "Created",
        "Question Comments",
        "Comment Sentiment",
        "Answer Sentiment",
        "Region ID",
        "User Display Name",
        "User Avatar",
        "Age",
        "Gender"
    ],

    //Region specific start and end hours during which we display a publish time warning to advise user against publishing
    "PULSE_QUIET_TIMES" : {
        "UK": {
            "START": 23,
            "END": 6,
            "UTC_OFFSETS": ["0"]
        },
        "US": {
            "START": 23,
            "END": 6,
            "UTC_OFFSETS": ["-8", "-7", "-6", "-5"]
        },
        "DZ": {
            "START": 23,
            "END": 6,
            "UTC_OFFSETS": ["+1"]
        }
    },

    // "CALENDLY_LINK": 'https://calendly.com/dave-sweeney',
    "CALENDLY_LINK": 'https://meetings.hubspot.com/onepulse/onepulse-demo',
    
    //Words to be excluded from the sentiment word cloud - Need to be lower case for comparison - May want to add pronouns and prepositions
    "EXCLUDED_SENTIMENT_WC_WORDS": [
        "i", "we", "they", "them", "this", "that", "was", "as",
        "of", "in", "to", "for", "the", "a", "is", "am", "be"
    ],

    //Access to the open text feature: none|all|internal
    "OPEN_TEXT_ACCESS": "all",

    "MAX_ALLOWED_DEMOGRAPHICS": 6,

    //Number of pulses to display on the main store page
    "PULSES_DISPLAY_COUNT_STORE_MAIN": 3,

    //Display training overlays (Dependent on user flag)
    "DISPLAY_TRAINING_OVERLAYS": true,

    //Display region selector in filter modal
    "DISPLAY_FILTER_REGION_SELECTION": true,

    //Max number of unread messages to display in inbox drop-down in header
    "NUMBER_OF_MESSAGES_INBOX_HEADER": 10,

    "INTERNATIONAL_REGION_CODE": "INT",

    "MAX_DAILY_REFERRAL_PAYOUT": 5.00,

    "REGION_ORDER": ["UK", "US", "INT"],

    "FIXER_API_KEY": "af3dfdc3de4d57f0515ebcd2a83970bc",

    "STATUS_ACTIVE": 3,

    //If a pulse is published on PAYG to this number of participants or more, a sub upsell modal is displayed
    "PAYG_UPSELL_PARTICIPANT_COUNT": 10000000, //Set to 10000000 to disable as per business request: 25/10/18

    "MAX_WIDTH_WL_LOGIN_LOGO_PX": 500,
    "MAX_WIDTH_WL_BAR_LOGO_PX": 200,

    //Show/hide all subscription content
    "SHOW_SUB_CONTENT": false,

    //The initialisation percentage a channel needs have reached in order to publish
    "CHANNEL_INIT_PUBLISH_PERCENTAGE": 90,

    "AUDIENCE_TYPE_CINT": 2,
    "AUDIENCE_TYPE_LUCID": 3,
    "LANGUAGES": {
        "FRE": "French",
        "ENG": "English",
        "CHI": "Chinese",
        "GER": "German",
        "ITA": "Italian",
        "JPN": "Japanese",
        "RUS": "Russian",
        "SPA": "Spanish",
        "POR": "Portuguese",
        "DUT": "Dutch",
        "BUL": "Bulgarian",
        "CZE": "Czech",
        "HUN": "Hungarian",
        "POL": "Polish",
        "SLK": "Slovak",
        "UKR": "Ukrainian",
        "RUM": "Romanian",
        "TUR": "Turkish",
        "ARA": "Arabic",
        "SWE": "Swedish",
        "DAN": "Danish",
        "NOR": "Norwegian",
        "FIN": "Finnish"
    }
});
