/**
 * Created by mike on 13/12/15.
 */

onePulse.directive('workspace.credits', ['DataService', 'RoleService', function(DataService, RoleService) {

    linkFunction = function($scope, element, attributes){

        DataService.profileClient("subscription", null, "").then(function(result) {

            $scope.subscription = result.subscriptions;
        });

        $scope.responses = 500;

        $scope.role = RoleService.getRole();
    };

    return {
        templateUrl: '/app/views/elements/forms/workspace/credits.html',
        link:linkFunction
    }
}]);
