onePulse.controller('SharePulsesController', ['$scope', '$window', 'SearchService', function ($scope, $window, SearchService) {

    //Infinite scroll control var
    $scope.loadingMore = false;

    $scope.pulses = null;
    $scope.pagination = null;

    $scope.search = {search: "", pulse: "", question: "", status: ""};

    $scope.ctrl = {
        "orderBy": "pulse.created_at",
        "orderDirection": "desc"
    };

    $scope.sort = {};
    $scope.sort.field = $scope.ctrl.orderBy;
    $scope.sort.order = $scope.ctrl.orderDirection;

    //Infinite Scroll
    $scope.loadMore = function() {

        if(typeof $scope.pagination != "undefined" && $scope.pagination != null) {

            //If haven't already pulled down the last page of pulses and not already calling, then do so
            if ($scope.pagination.current_page != $scope.pagination.last_page && !$scope.loadingMore) {

                $scope.loadingMore = true;

                SearchService.performSearch("es", "pulses", $scope.search, ($scope.pagination.current_page + 1), $scope.sort, false, false).then(function (result) {

                    var parsedResult = angular.fromJson(result);

                    for (var i = 0; i < parsedResult.pulses.length; i++) {

                        $scope.pulses.push(parsedResult.pulses[i]);
                    }

                    $scope.pagination = parsedResult.pagination;

                    $scope.loadingMore = false;
                });
            }
        }
    };

    //If enter key is pressed in search field
    $scope.enterOnSearch = function(keyEvent) {

        if($scope.search.search.length > 2) {

            $scope.searchPulses();
        } else if($scope.search.search.length == 0) {

            $scope.loadPulses($scope.selected);
        }
    };

    $scope.orderBy = function() {

        $scope.sort = {};
        $scope.sort.field = $scope.ctrl.orderBy;
        $scope.sort.order = $scope.ctrl.orderDirection;

        SearchService.performSearch("es", "pulses", $scope.search, null, $scope.sort, false, false).then(function (result) {

            $scope.pulses = result.pulses;
            $scope.pagination = result.pagination;
        })
    };

    $scope.searchPulses = function () {

        SearchService.performSearch("es", "pulses", $scope.search, null, $scope.sort, false, false).then(function (result) {

            $scope.pulses = result.pulses;
            $scope.pagination = result.pagination;
        });
    };

    //Redirect to pulse share
    $scope.redirectToPulseShare = function(url) {

        $window.open(url, '_blank');
    };

    $scope.loadPulses = function(status){

        $scope.search.search = "";

        init();
    };

    var init = function() {

        $scope.search.search = "";
        $scope.search.status = "Draft Active Scheduled Closed";

        SearchService.performSearch("es", "pulses", $scope.search, null, $scope.sort, false, false).then(function (result) {

            $scope.pulses = result.pulses;
            $scope.pagination = result.pagination;
        });
    };

    init();
}]);