/**
 * Created by Barry on 2018/05/25.
 *
 * Pulse demographic controls
 */

onePulse.directive('pulseDemographicControls', ['DataService',
    function (DataService) {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/pulse/pulseDemographicControls.html',

            link: function postLink(scope, element, iAttrs) {

                scope.hideDetails = false;

                scope.togglePublishShare = function() {

                    DataService.fetchItem("pulse/no_push", scope.pulse.data_token).then(function(result) {

                        scope.pulse.data.is_no_push = result.pulse.data.is_no_push;
                        scope.pulse.data.pulse_type_id = result.pulse.data.pulse_type_id;
                    });
                };
            }
        };
    }]);
