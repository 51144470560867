/**
 * Created by mike on 13/12/15.
 */

onePulse.directive('workspace.management', [function() {

    linkFunction = function($scope, element, attributes){

    };

    return {
        templateUrl: '/app/views/elements/forms/workspace/management.html',
        link:linkFunction
    }
}]);
