/**
 * Created by Barry on 15/03/17.
 *
 */
onePulse.controller('ShareResultsController', ['$scope', '$routeParams', '$window', '$timeout', 'DataService', 'CONSTANTS', 'SocialShareService',
    function ($scope, $routeParams, $window, $timeout, DataService, CONSTANTS, SocialShareService) {

        $scope.pulsesLoaded = false;

        //Get device type - mobile/desktop
        $scope.deviceType = determineDeviceType(getViewportDimensions());

        //Config var for carousel - number of slides to show (default: desktop = 4 / mobile = 3)
        $scope.slidesToShow = $scope.deviceType == CONSTANTS.DEVICE_TYPE_DESKTOP ? 4 : 3;

        //Configure carousel for other pulses (enabled is set to true only after API call for pulses is complete)
        $scope.slickConfigPulses = {
            enabled: false,
            autoplay: false,
            draggable: true,
            autoplaySpeed: 3000,
            slidesToShow: $scope.slidesToShow,
            slidesToScroll: $scope.slidesToShow,
            method: {},
            event: {
                beforeChange: function (event, slick, currentSlide, nextSlide) {
                },
                afterChange: function (event, slick, currentSlide, nextSlide) {
                }
            }
        };

        //Get route params
        var slug = $routeParams.slug;
        var deepLink = (typeof $routeParams.deeplink != "undefined");

        var handleAppRedirect = function(code) {

            var mobileOS = getMobileOperatingSystem();

            //1. if user has app installed, then they would be redirected to open the app to specified screen
            //2. if user doesn't have app installed, then their browser wouldn't recognize the URL scheme
            //and app wouldn't open since it's not installed. In 1 second (1000 milliseconds) user is redirected
            //to download app from app store.
            switch(mobileOS) {

                case 'Android': //Start Android app and join pulse

                    //Deep link URL for existing users with app already installed on their device -->
                    $window.location = CONSTANTS.MOBILE_APP_SCHEME + code;
                    <!-- Download URL for new users to download the app -->
                    $timeout("window.location = '" + CONSTANTS.ANDROID_APP + "';", 1000);

                    break;
                case 'iOS': //Start iOS app and join pulse

                    //Deep link URL for existing users with app already installed on their device -->
                    $window.location = CONSTANTS.MOBILE_APP_SCHEME + code;
                    <!-- Download URL for new users to download the app -->
                    $timeout("window.location = '" + CONSTANTS.IOS_APP + "';", 1000);

                    break;
                default: //Not mobile... do nothing
                    break;
            }
        };

        $scope.init = function () {

            //Pull pulse data with slug and present to user
            DataService.fetchShareData('pulse', slug, null).then(function (result) {

                $scope.pulse = {"data": result.pulse};

                for(var j = 0; j < $scope.pulse.data.questions.length; j++) {

                    $scope.pulse.data.questions[j] = {"data": $scope.pulse.data.questions[j]};

                    //Determine question format
                    if(typeof $scope.pulse.data.questions[j].data.format.options != 'undefined') {

                        for (var i = 0; i < $scope.pulse.data.questions[j].data.format.options.length; i++) {

                            if ($scope.pulse.data.questions[j].data.format.options[i].option.selected == true) {

                                $scope.pulse.data.questions[j].formatText = $scope.pulse.data.questions[j].data.format.options[i].option.text;
                            }
                        }
                    } else {

                        $scope.pulse.data.questions[j].formatText = $scope.pulse.data.questions[j].data.format.value;
                    }
                }


                //If the referring url is a deep link, we process accordingly
                if (deepLink) {

                    handleAppRedirect(result.pulse.code.value);
                }
            });

            //Pull all other active pulses
            DataService.fetchShareData('pulses', null, null).then(function (result) {

                $scope.pulses = result.pulses;

                //Enable carousel
                $scope.pulsesLoaded = true;
                $scope.slickConfigPulses.enabled = true;
            });
        };

        $scope.init();

        $scope.share = function($event, pulse, provider) {

            SocialShareService.share("pulse", pulse, provider);
        }

    }]);