/**
 * Created by Barry on 2016/11/17.
 *
 * Directive to handle behaviour of each notification in list
 */

onePulse.directive('notification', ['$rootScope', function ($rootScope) {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: false,
        templateUrl:'/app/views/elements/general/notification.html',

        link: function postLink(scope, element, iAttrs) {

            scope.closeNotification = function(index) {

                $rootScope.notifications.splice(index, 1);
            }
        }
    };
}]);