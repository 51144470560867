/**
 * Created by barry
 */

onePulse.directive('workspace.reseller', [function() {

    linkFunction = function($scope, element, attributes){

    };

    return {
        templateUrl: '/app/views/elements/forms/workspace/reseller.html',
        link:linkFunction
    }
}]);
