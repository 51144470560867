/**
* Created by mike on 23/01/16.
*/
onePulse.directive('questionForm', ['FormService', 'DataService', '$window', '$rootScope', '$filter', '$translate', '$mdDialog',
	'AlertService', '$timeout', 'CONSTANTS', 'ValidationService',
	function (FormService, DataService, $window, $rootScope, $filter, $translate, $mdDialog,
			  AlertService, $timeout, CONSTANTS, ValidationService) {

	var $service = FormService;
	var translations;
	$translate(["question.boolean_true", "question.boolean_false", "alert.question_text_absent.text", "alert.question_text_absent.title",
		"alert.question_slider_absent.text", "alert.question_slider_absent.title", "alert.question_has_answer_duplicates.text",
		"alert.question_has_answer_duplicates.title", "alert.question_has_bad_minmax.text", "alert.question_has_bad_minmax.title",
		"alert.question_min_answers.text", "alert.question_min_answers.title",
		"alert.question_slider_min_error.title", "alert.question_slider_min_error.text",
		"alert.question_slider_max_error.title", "alert.question_slider_max_error.text",
		"alert.question_slider_step_error.title", "alert.question_slider_step_error.text",
		"alert.cannot_live_edit.title", "alert.cannot_live_edit.text",
		"alert.question_slider_negative.title", "alert.question_slider_negative.text"]).then(function(result) {
				translations = result;
			});

	linkFunction = function ($scope, element, attributes) {

		$scope.link = "";
		$scope.answer1 = null;
		$scope.answer8 = null;

        $rootScope.answerTexts = [];
        $rootScope.questionId = null;

		$scope.showOTChartSpinner = true;

		$scope.orderedTexts = ['Exclusive', 'Multiple', 'Yes/No', 'Slider', 'Drag & Drop', 'Image', 'Branching'];

		//Free Text feature check
		switch(CONSTANTS.OPEN_TEXT_ACCESS) {

			case 'internal':

				if($scope.user.data.is_internal_account.value == "1") {

					$scope.orderedTexts.push('Free Text')
				}
				break;
			case 'all':

				$scope.orderedTexts.push('Free Text');
				break;
			case 'none':
				break;
		}

		//Control for format change - enable/disable radio buttons
		$scope.questionFormatChangeInProgress = false;

		/*
		MM: Added this to perform validation before submit
		BW: Added 2nd arg - 'send' - set to true to send pulse immediately after queueing
		 */
		$scope.queueQuestion = function($pulse, send){

			var validAnswers = $filter('validAnswer')($scope.question.data.answers);

			$scope.validAnswerCount = validAnswers.length;

			//I am automatically correcting answer dupes, both text and sequence here before final validation
			$pulse = ValidationService.duplicateAnswerCorrection($pulse);

			$pulse = ValidationService.answerSequenceCorrection($pulse);

			//Link type check
			linkTypeCheck();

			if($scope.question.data.text.value =='') {

				AlertService.warning(
					translations['alert.question_text_absent.title'],
					translations['alert.question_text_absent.text']
				);

			} else if($scope.question.formatText != "Slider" && $scope.question.formatText != "Free Text" && $scope.validAnswerCount < 2){

				AlertService.warning(
					translations['alert.question_min_answers.title'],
					translations['alert.question_min_answers.text']
				);

			} else if($scope.question.formatText == "Slider" &&
				(($scope.question.data.slider_min.value == '' || typeof $scope.question.data.slider_min.value == "undefined") ||
				($scope.question.data.slider_max.value == '' || typeof $scope.question.data.slider_min.value == "undefined") ||
				($scope.question.data.slider_step.value == '' || typeof $scope.question.data.slider_min.value == "undefined") )) {

				AlertService.warning(
					translations['alert.question_slider_absent.title'],
					translations['alert.question_slider_absent.text']
				);

			} else if($scope.question.formatText == "Slider" &&
					($scope.question.data.slider_min.value.toString().indexOf('-') != -1 ||
					$scope.question.data.slider_max.value.toString().indexOf('-') != -1 ||
					$scope.question.data.slider_step.value.toString().indexOf('-') != -1 )) {

					AlertService.warning(
						translations['alert.question_slider_negative.title'],
						translations['alert.question_slider_negative.text']
					);

			} else if($scope.question.formatText == "Slider" &&
				(parseInt($scope.question.data.slider_min.value) >= parseInt($scope.question.data.slider_max.value))){

				AlertService.warning(
					translations['alert.question_slider_min_error.title'],
					translations['alert.question_slider_min_error.text']
				);

			} else if($scope.question.formatText == "Slider" &&
				(parseInt($scope.question.data.slider_max.value) <= parseInt($scope.question.data.slider_min.value))){

				AlertService.warning(
					translations['alert.question_slider_max_error.title'],
					translations['alert.question_slider_max_error.text']
				);

			} else if($scope.question.formatText == "Slider" && (parseInt($scope.question.data.slider_step.value) < 1 ||
				(parseInt($scope.question.data.slider_step.value) > parseInt($scope.question.data.slider_max.value)))){

				AlertService.warning(
					translations['alert.question_slider_step_error.title'],
					translations['alert.question_slider_step_error.text']
				);

			} else if($scope.question.formatText != "Slider" && $scope.question.formatText != "Free Text" && hasAnswerDuplicates()) {

				AlertService.warning(
					translations['alert.question_has_answer_duplicates.title'],
					translations['alert.question_has_answer_duplicates.text']
				);

			} else if ($scope.question.formatText == "Multiple" && checkMultipleMinMax()) {

                AlertService.warning(
                    translations['alert.question_has_bad_minmax.title'],
                    translations['alert.question_has_bad_minmax.text']
                );
            //Cannot allow live edit of pulses with responses of greater than 200. Too much data to update
            } else if ($scope.pulse.data.status.value == "Active" && $scope.pulse.data.responses.value > 200) {

                AlertService.warning(
                    translations["alert.cannot_live_edit.title"],
                    translations["alert.cannot_live_edit.text"]
                )
			} else {

				if(send) {

					if($scope.performValidation()) {

						$scope.submitCallback(null, $scope.question);
						$scope.popupPanelSizeDialog(false);
					}
				} else {

					$scope.submitCallback(null, $scope.question);
				}
			}
		};

		if(typeof $scope.question.data.is_shuffle_answers != "undefined") {

			$scope.question.data.is_shuffle_answers.value = ($scope.question.data.is_shuffle_answers.value == "" ? "0" : $scope.question.data.is_shuffle_answers.value);
		}

		//Toggle shuffle answer order for question
		$scope.shuffleAnswers = function() {

			$scope.question.data.is_shuffle_answers.value = ($scope.question.data.is_shuffle_answers.value == "0" ? "1" : "0");
		};

		$scope.mode = attributes.mode;

		$scope.questionFormVisible = true;

		$scope.linkAdd = function() {

			question.data.text.value = question.data.text.value + $scope.link;
		};

		$scope.linkRemove = function() {

			if($scope.link != "") {

				if(question.data.text.value.indexOf($scope.link)) {

					question.data.text.value = question.data.text.value.replace($scope.link, "");
				}
			}
		};

		/*
		 MM:
		 You dont need to create answers from proto JSONs yourself they are all already nested in the question waiting to be edited.
		 Just limit the number shown to start with - empty answers are stripped and recycled at publish so dont need to clean them up
		 */

		$scope.determineMinimumAnswers = function() {

			if(typeof $scope.question.data.answers != "undefined") {

				var usedAnswers = $filter('usedAnswers')($scope.question.data.answers);

				var displayAnswersCount = 2;//default

				if(typeof usedAnswers != "undefined"){

					displayAnswersCount = usedAnswers.length > 2 ? usedAnswers.length: 2;
				}

				$scope.question.min_answers = displayAnswersCount;
			}
		};

		$scope.determineMinimumAnswers();
		/*
		 MM:
		 Moved this function from the PulseController as it relates to the question form not the pulse.
		 Massively simplified it -
		 */
		$scope.addAnswerCallback = function($event){

			//Remove focus from call new answer input
			$timeout(function() {
				angular.element('.call-new-answer').blur();
			});

			$scope.question.min_answers++;

			$scope.question.displayAnswers = $scope.question.data.answers.slice(0, $scope.question.min_answers);

			//Get added answer's DOM id (pulse id + index of answer array)
			var answerIndex = $scope.question.data.id.value + ($scope.question.min_answers - 1).toString();

			//Apply focus
			$timeout(function() {

				angular.element('#' + answerIndex).focus();
			});
		};

		//Remove the image & text from an answer
		$scope.removeImageAnswer = function($event, answer) {

			$event.preventDefault();

			for(var i = 0; i < $scope.question.data.answers.length; i++) {

				if($scope.question.data.answers[i] == answer) {

					$scope.question.data.answers[i].data.image.value = "";
                    $scope.question.data.answers[i].data.text.value = "";
				}
			}
		};

		//Remove text answer
		$scope.removeTextAnswer = function($event, answer) {

            for(var i = 0; i < $scope.question.data.answers.length; i++) {

                if($scope.question.data.answers[i] == answer) {

                    $scope.question.data.answers[i].data.text.value = "";
                }
            }

            var usedAnswers = $filter('usedAnswers')($scope.question.data.answers);
            $scope.question.min_answers = usedAnswers.length > 2 ? usedAnswers.length : 2;
            $scope.question.displayAnswers = $scope.question.data.answers.slice(0, $scope.question.min_answers);

            $scope.pulse = ValidationService.answerSequenceCorrection($scope.pulse);
        };

		/*
		 MM:
		 We only want to show add another option if there is more answers in the question than in displayAnswers
		 */

		$scope.isMaxReached = function(){

			if ($scope.question.displayAnswers != undefined) {

				if($scope.question.formatText == 'Yes/No') {

					return $scope.question.displayAnswers.length >= 2;
				} else {

					return $scope.question.displayAnswers.length == $scope.question.data.answers.length;
				}
			} else {
				return false;
			}
		};

		$scope.toggleQuestionComments = function() {

			$scope.question.data.is_allow_comment.value = ($scope.question.data.is_allow_comment.value == '1' ? '0' : '1');
		};

		/*
		 MM:
		 Do your setup for the form here
		 */

		//Set default as Yes/No only if no option was selected
		if (findSelectedOption($scope.question.data.format.options) == undefined) {

			for(var index = 0; index < $scope.question.data.format.options.length; index++){

				if($scope.question.data.format.options[index].option.text=='Yes/No'){

					$scope.question.data.format.options[index].option.selected = true;
					$scope.question.data.answers[0].data.text.value = 'Yes';
					$scope.question.data.answers[1].data.text.value = 'No';
				} else {
					$scope.question.data.format.options[index].option.selected = false;
				}
			}
		}

		$scope.question.format = findSelectedOption($scope.question.data.format.options).option_token;
		$scope.question.formatText = findSelectedOption($scope.question.data.format.options).option.text;

		if($scope.question.formatText == 'Free Text') {

			//Set initial display type for free text question: results|sentiment|wordCloud
			$scope.question.displayType = CONSTANTS.QUESTION_FREE_TEXT_INITIAL_DISPLAY_TYPE;
		}

		/*
		 MM:
		 Mess around with answers if the question is not a slider (has answers)
		 */

		if ($scope.question.data.answers !== undefined) {

			/*
			 MM:
			 Create an array of displayAnswers as a subset of the nested answers sent by the server
			 */
			$scope.question.displayAnswers = $scope.question.data.answers.slice(0, $scope.question.min_answers);

			/*
			 MM: add the defaults if nothing is set
			 */
			if ($scope.question.formatText == 'Yes/No') {

				if($scope.question.data.answers[0].data.text.value.length==0){
					$scope.question.data.answers[0].data.text.value = translations["question.boolean_true"];
				}

				if($scope.question.data.answers[1].data.text.value.length==0){
					$scope.question.data.answers[1].data.text.value = translations["question.boolean_false"];
				}

			}
		}

		//If a dragged element (Only for Drag & Drop question type) is dropped we need to update the answers in scope
		$scope.$on('bag-one.drop-model', function (e, el, target, source) {

			$timeout(function() {

				$scope.question.answerTexts = [];

				for (var i = 0; i < target[0].children.length; i++) {

					$scope.question.answerTexts.push(target[0].children[i].children[0].getAttribute('data-text'))
				}
			});
		});

		//Check if related link is a video or inline type
		var linkTypeCheck = function() {

			if(typeof $scope.question.data.link_url.value != "undefined") {

				if($scope.question.data.link_url.value != "") {

					var matches = $scope.question.data.link_url.value.match(/(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/);

					//Reset all options
					for (var i = 0; i < $scope.question.data.link_type.options.length; i++) {

						$scope.question.data.link_type.options[i].option.selected = false;
					}

					//Set option
					for (var i = 0; i < $scope.question.data.link_type.options.length; i++) {

						if (matches && $scope.question.data.link_type.options[i].option.text == "video") {

							$scope.question.data.link_type.options[i].option.selected = true;
						} else if (matches == null && $scope.question.data.link_type.options[i].option.text == "page") {

							$scope.question.data.link_type.options[i].option.selected = true;
						}
					}
				}
			}
		};

		//Make sure multiple min and max values are within bounds (1 - 8)
		var checkMultipleMinMax = function() {

			if(typeof $scope.question.data.max_answers != "undefined" && typeof $scope.question.data.min_answers != "undefined") {

				var validAnswers = $filter('validAnswer')($scope.question.data.answers);

				//Set max to the total number of valid answers if it is greater (This can occur if answers are added and then removed)
				if(parseInt($scope.question.data.max_answers.value) > validAnswers.length) {

					$scope.question.data.max_answers.value = validAnswers.length;
				}

				return(parseInt($scope.question.data.max_answers.value) > 12 || parseInt($scope.question.data.max_answers.value) < 1 ||
					parseInt($scope.question.data.min_answers.value) > 12 || parseInt($scope.question.data.min_answers.value) < 1 ||
				typeof $scope.question.data.max_answers.value === "undefined" || typeof $scope.question.data.min_answers.value === "undefined")
			} else {

				return false;
			}
		};

		/*
		 Put the question data through the FormService and get all the rules and fields
		 */
		var orderQuestionTypes = function(types) {

			var newTypes = [];

			for(var i = 0; i < $scope.orderedTexts.length; i++) {

				var text = $scope.orderedTexts[i];

				var j = 0;

				while(typeof newTypes[i] === 'undefined') {

					if (types[j].option.text == text) {

						newTypes.push(types[j]);
					}

					j++;
				}
			}

			return newTypes;
		};

		var hasAnswerDuplicates = function() {

			var answerTexts = $scope.question.data.answers.map(function(el) {return el.data.text.value});

			answerTexts = answerTexts.filter(String); // Get rid of empty strings

			return hasDuplicates(answerTexts)
		};
		
		$scope.question.format = findSelectedOption($scope.question.data.format.options).option_token;
		$scope.question.formatText = findSelectedOption($scope.question.data.format.options).option.text;
		$scope.question.data.format.options = orderQuestionTypes($scope.question.data.format.options);

		$scope.textInputsVisible = function() {
			return ($scope.question.formatText != 'Image' && $scope.question.formatText != 'Slider');
		};

		$scope.cancel = function() {

			$scope.$parent.setTopAnimation(true);
			DataService.remove('pulse/question', $scope.question.data_token)
					.then(function (result) {
						$scope.removeQuestionFromPulse($scope.question);
					})

		};

		$scope.transition = function(type, direction) {

			var left = '';
			var right = '';
			var opacity = '';

			switch(type) {
				case 'out':
					opacity = '0';
					switch(direction) {
						case 'left':
							left = '-200px';
							right = '200px';
							break;
						case 'right':
							left = '200px';
							right = '-200px';
							break;
					}
					break;
				case 'in':
					left = '0px';
					right = '0px';
					opacity = '1';
					break;
			}

			var element = $('#' + $scope.question.data.id.value);

			element.animate({
				'margin-left': left,
				'margin-right': right,
				opacity: opacity
			}, {
				duration: 500,
				complete: function() {

					element.css('margin-left', right);
					element.css('margin-right', left);
				}
			});
		};

		$scope.saveQuestion = function(savePulse, oldFormatText, newFormatText) {

			//var direction = ($scope.orderedTexts.indexOf(newFormatText) > $scope.orderedTexts.indexOf(oldFormatText)?'left':'right');

			//$scope.transition('out', direction);

			DataService.submit('pulse/question', $scope.question).then(function(result){

                //Update question data
                $scope.question = result.question;

				//Re-enable question format change radio group
				$timeout(function() {

					$scope.questionFormatChangeInProgress = false;
				});

				//$scope.transition('in', null);

				$scope.question.format = findSelectedOption($scope.question.data.format.options).option_token;
				$scope.question.formatText = findSelectedOption($scope.question.data.format.options).option.text;
				$scope.question.data.format.options = orderQuestionTypes($scope.question.data.format.options);

				if (typeof $scope.question.data.answers !== "undefined") {

					var usedAnswers = $filter('usedAnswers')($scope.question.data.answers);
					$scope.question.min_answers = usedAnswers.length > 2 ? usedAnswers.length : 2;
					$scope.question.displayAnswers = $scope.question.data.answers.slice(0, $scope.question.min_answers);

					//Fix for slider & yes/no answer value removal
					if ($scope.question.data.id.value == $rootScope.questionId &&
						($scope.question.formatText != 'Yes/No' && $scope.question.formatText != 'Branching' && $scope.question.formatText != 'Slider') &&
						(typeof $rootScope.answerTexts != "undefined" && $rootScope.answerTexts != null && $rootScope.answerTexts.length != 0)) {

						for(var j = 0; j < $scope.question.data.answers.length; j++) {

							$scope.question.data.answers[j].data.text.value = $rootScope.answerTexts[j];
						}

						$rootScope.answerTexts = [];
						$rootScope.questionId = null;
					}

					//If the new format is yes/no we remove all other answers and add 'yes' and 'no'
					if($scope.question.formatText == 'Yes/No') {

						for(var k = 0; k < 8; k++) {

							if(typeof $scope.question.data.answers[k] != "undefined") {

								$scope.question.data.answers[k].data.text.value = '';
							}
						}

						$scope.question.data.answers[0].data.text.value = 'Yes';
						$scope.question.data.answers[1].data.text.value = 'No';
					}

					//If the new format is image we need to reset the answer text values to an empty string after the update
					if($scope.question.formatText == 'Image') {

						for(var l = 0; l < 6; l++) {

							if(typeof $scope.question.data.answers[l] != "undefined") {

								$scope.question.data.answers[l].data.text.value = '';
							}
						}
					}
				}

				//Replace question in pulse
				for(var i = 0; i < $scope.pulse.data.questions.length; i++) {

					if ($scope.pulse.data.questions[i].data.id.value == $scope.question.data.id.value) {

						$scope.pulse.data.questions[i] = $scope.question;
						$scope.pulse.data.questions[i].format = $scope.question.format;
						$scope.pulse.data.questions[i].formatText = $scope.question.formatText;
						$scope.pulse.data.questions[i].min_answers = $scope.question.min_answers;
						$scope.pulse.data.questions[i].displayAnswers = $scope.question.displayAnswers;
						$scope.pulse.data.questions[i].data.format.options = $scope.question.data.format.options;
					}
				}

				//Save pulse if necessary (Branching on/off)
				if(savePulse) {

					$scope.savePulse($scope.pulse, false);
				} else {

					$rootScope.fadedOverlay = false;
					$rootScope.loadingStateText = "";
				}
			})
		};

        $scope.questionFormatChangeListener = function(oldVal) {

            var newVal = $scope.question.format;

			if (newVal != oldVal && typeof oldVal !== "undefined" && typeof newVal !== "undefined") {

				$rootScope.fadedOverlay = true;
				$rootScope.loadingStateText = "Changing Format";
				$scope.questionFormatChangeInProgress = true;

				// oldFormat and newFormat don't refer to options object, so we have to update options in place.
				var oldFormat = findOptionIndexByToken($scope.question.data.format.options, oldVal);
				var newFormat = findOptionIndexByToken($scope.question.data.format.options, newVal);

				if(typeof oldFormat !== "undefined") {

					var oldText = $scope.question.data.format.options[oldFormat].option.text;
					var newText = $scope.question.data.format.options[newFormat].option.text;

					//Process the format change
					var process = function(savePulse) {

						//Make a deep copy of all answers and clear text values if we are moving to either the slider or yes/no question types
						if (newText == 'Slider' || newText == 'Yes/No') {

							if(typeof $scope.question.data.answers !== "undefined") {

								for(var j = 0; j < $scope.question.data.answers.length; j++) {

									$rootScope.answerTexts[j] = angular.copy($scope.question.data.answers[j].data.text.value);
									$scope.question.data.answers[j].data.text.value = "";
								}

                                $rootScope.questionId = $scope.question.data.id.value;
							}
						}

						$scope.question.data.format.options[oldFormat].option.selected = false;
						$scope.question.data.format.options[newFormat].option.selected = true;

						$scope.question.format = findSelectedOption($scope.question.data.format.options).option_token;
						$scope.question.formatText = findSelectedOption($scope.question.data.format.options).option.text;

						$scope.saveQuestion(savePulse, oldText, newText);
					};

					//If we are moving to image format, we need to update the answers so that we can upload
					if(newText == 'Image' && oldText != 'Slider' && oldText != 'Free Text') {

						for(var k = 0; k < 6; k++) {

							if(typeof $scope.question.data.answers[k] != "undefined") {

								if($scope.question.data.answers[k].data.id.value == "") {

									$scope.question.data.answers[k].data.text.value = 'Image ' + k;
								}
							}
						}
					}

					//If we are moving to another format from Branching, we need to warn the user and cleanup
					if(oldText == 'Branching') {

						if(!$rootScope.errorOnBranchChange) {

							$scope.pulse.data.is_branched.value = "0";

							$scope.branchingDisabledCleanup();

							process(true);
						} else {

							$rootScope.errorOnBranchChange = false;

							//Re-enable question format change radio group
							$timeout(function() {

								$scope.questionFormatChangeInProgress = false;
							});
						}

					//Else if we are moving to Branching, we need to run some checks
					} else if(newText == 'Branching') {

						//Throw a warning if there are fewer than 2 answers and undo format change before processing
						if(filledAnswers($scope.question.data.answers).length < 2) {

							var translations;
							$translate(['alert.cannot_branch_pulse.title', 'alert.cannot_branch_pulse.text']).then(function(result) {
								translations = result;

								AlertService.warning(
									translations['alert.cannot_branch_pulse.title'],
									translations['alert.cannot_branch_pulse.text']
								);
							});

							newFormat = oldFormat;
							newText = oldText;

							$scope.question.data.format.options[oldFormat].option.selected = false;
							$scope.question.data.format.options[newFormat].option.selected = true;

							$scope.question.format = findSelectedOption($scope.question.data.format.options).option_token;
							$scope.question.formatText = findSelectedOption($scope.question.data.format.options).option.text;

							$rootScope.errorOnBranchChange = true;

							//Re-enable question format change radio group
							$timeout(function() {

								$scope.questionFormatChangeInProgress = false;
								$rootScope.fadedOverlay = false;
								$rootScope.loadingStateText = "";
							});

						//Clear the answer text for extra answers if there are more than 3 answers, and then process
						} else if(filledAnswers($scope.question.data.answers).length > 3) {

							for(var i = 0; i < $scope.question.data.answers.length; i++) {

								if(i > 2) {

									$scope.question.data.answers[i].data.text.value = "";
								}
							}

							$scope.branchingEnabledPrep();
							process(true);
						//There are the correct number of answers so we process
						} else {

							$scope.branchingEnabledPrep();
							process(true);
						}
					//Else it is neither of the above cases so we simply process the format change
					} else {

						process(false);
					}
				}
			}
		};

		$scope.closeQuestionEditWarning = function(question) {

			$scope.newQuestionFormVisible = false;
			$scope.newQuestionHeaderVisible = false;
			$scope.newQuestionId = null;
			$scope.disableNewQuestionAnimation = false;

			var index = $scope.questionBeingEdited.indexOf(question.data.id.value);

			if (index != -1) {
				$scope.questionBeingEdited.splice(index, 1);
			}
		};

		//Checks if question can be branched
		$scope.canBeBranched = function(question) {

			//Only allow branching from question 1, if not already branched and if question has 2 answers
			return (question.data.sequence.value == "1");
		};

		$scope.linkType = "Type";

		$scope.setLinkType = function ($event) {
			var elem = angular.element($event.currentTarget);

			var linkType = findOptionByToken($scope.question.data.link_type.options, elem.data('option-token'));

			angular.forEach($scope.question.data.link_type.options, function ($it) {
				$it.option.selected = false;
			});

			linkType.option.selected = true;

			$scope.linkType = uppercaseFirst(linkType.option.text);

		};

		//Prevent decimal points in slider value
		$scope.$watch('question.data.slider_min.value', function(newValue,oldValue) {

			if(typeof $scope.question.data.slider_min != "undefined" && typeof newValue != "undefined") {

				if (newValue.toString().indexOf('.') != -1) {
					$scope.question.data.slider_min.value = oldValue;
				}
			}
		});

		//Prevent decimal points in slider value
		$scope.$watch('question.data.slider_max.value', function(newValue,oldValue) {

			if(typeof $scope.question.data.slider_max != "undefined" && typeof newValue != "undefined") {

				if (newValue.toString().indexOf('.') != -1) {
					$scope.question.data.slider_max.value = oldValue;
				}
			}
		});

		//Prevent decimal points in slider value
		$scope.$watch('question.data.slider_step.value', function(newValue,oldValue) {

			if(typeof $scope.question.data.slider_step != "undefined" && typeof newValue != "undefined") {

				if (newValue.toString().indexOf('.') != -1) {
					$scope.question.data.slider_step.value = oldValue;
				}
			}
		});

		$scope.$on('event:pulse-translated',function(){

			var translation = findItemById($scope.translation.data.questions, $scope.question.data.id.value);

			$scope.question.translation = translation;

			$scope.$broadcast('event:question-translated');
		})
	};

	return {
		link: linkFunction,
		scope: true,
		templateUrl: 'app/views/elements/forms/question.html'
	}
}]);
