/**
 * Created by barry on 02/05/2018.
 *
 */
onePulse.controller('ErrorController', ['$scope', '$routeParams',
	function ($scope, $routeParams) {

        $scope.type = $routeParams.type;
	
		//Initialise
		var init = function() {

		    switch($scope.type) {

                case 'invite-failed':

                    break;

                case 'invite-already-accepted':

                    break;

                default:

                    break;
            }
		};

		init();
}]);
