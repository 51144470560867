onePulse.controller('PulsesController', ['$http', '$scope', '$location', '$rootScope', 'DataService', '$route', 'ValidationService', 'RoleService',
	'$window', '$timeout', '$mdDialog', 'AlertService', '$translate', 'SocialShareService', 'ExportService', 'SearchService', 'CONSTANTS',
	function ($http, $scope, $location, $rootScope, DataService, $route, ValidationService, RoleService,
			  $window, $timeout, $mdDialog, AlertService, $translate, SocialShareService, ExportService, SearchService, CONSTANTS) {

	$scope.user = DataService.grab("user");

	$scope.selected = typeof $window.localStorage["pulses_active_tab"] !== "undefined" ? $window.localStorage["pulses_active_tab"] : "All";
	$window.localStorage["pulses_active_tab"] = "All";

	$scope.search = {"search": $rootScope.currentSearch, "pulse": "", "question": "", "status": "", "type": "full_text"};

	$scope.ctrl = {
		"orderBy": "pulse.started_at",
		"orderDirection": "desc"
	};

	$scope.sort = {};
    $scope.sort.field = $scope.ctrl.orderBy;
	$scope.sort.order = $scope.ctrl.orderDirection;

	$scope.noSearchResults = false;

	//Get device type - mobile/desktop
	$scope.deviceType = determineDeviceType(getViewportDimensions());

	//Control for infinite scroll - to prevent multiple calls
	$scope.loadingMore = false;

	$scope.showPulses = true;

	angular.extend($scope.search, $location.search());

	var setScope = function () {

		switch ($scope.selected) {

			case "Purchased":

				$scope.pulses = $scope.purchasedPulses;
				break;
			case "All":
				default:

				$scope.pulses = $scope.allPulses;
				break;
		}

		$scope.originalset_pulses = angular.copy($scope.pulses);

		$scope.orderPulses("started_at");

		$timeout(function() {

			initPulsesCardSlideUp();
		}, 2000);
	};

	searchPresent = function() {
		for(var key in $scope.search) {
			if($scope.search[key].length > 0) {
				return true;
			}
		}

		return false;
	};

	$scope.orderBy = function() {

		$scope.showPulses = false;
		$scope.loadingMore = true;

        $scope.sort = {};
        $scope.sort.field = $scope.ctrl.orderBy;
        $scope.sort.order = $scope.ctrl.orderDirection;

        SearchService.performSearch("es", "pulses", $scope.search, null, $scope.sort, false, true).then(function (result) {

            $scope.pulses = result.pulses;
            $scope.showPulses = true;
            $scope.loadingMore = false;
        })
	};

	$scope.orderPulses = function(property) {

		//$scope.pulses = sortByProperty($scope.pulses, property, "desc");
	};

	$scope.setSelected = function(selected) {

		$scope.selected = selected;
	};

	$scope.getPageNumber = function(num) {

		return new Array(num);
	};

	//Handle filtering of pulses based on state
	$scope.loadPulses = function(status){

        $scope.showPulses = false;
        $scope.loadingMore = true;

        $scope.search.search = "";
        $rootScope.currentSearch = "";
		$scope.search.status = status;

		if(status=="All") {

			$scope.pulses = $scope.originalset_pulses;
            $scope.showPulses = true;
            $scope.loadingMore = false;
			$route.reload();
		} else if (status=="Purchased") {

			$scope.pulses = $scope.purchasedPulses;
            $scope.showPulses = true;
            $scope.loadingMore = false;
		} else {

			$scope.searchPulses();
		}
	};

	$scope.setPulseView = function(type) {

		$rootScope.pulseView = type;
		$window.localStorage["pulse_view"] = type;

	};

	//Listener to set updated pulses in scope after go-live/closed notif
	$scope.deregisterPulseStatusChangeHandler = $rootScope.$on("event:pulse_status_change", function(event, result){

		$scope.pagination = angular.fromJson(result).pagination;

		$timeout(function() {

			setScope(angular.fromJson(result).pulses)
		});
	});

	$scope.$on("$destroy", function() {

		//De-register all root scope event handlers when pulse controller scope is destroyed
		if(typeof $scope.deregisterPulseStatusChangeHandler != "undefined") {$scope.deregisterPulseStatusChangeHandler()};
		if(typeof $scope.deregisterUpdatePulseListHandler != "undefined") {$scope.deregisterUpdatePulseListHandler()};
	});

	//Update scope after pagination/search set change
	$scope.$on("event:pulses-page-change", function (event, result) {

		if($scope.selected == "All"){

			$scope.pulses = result.pulses;
		}else {
			$scope.pulses = filterPulses(result.pulses, $scope.selected);
		}
		$scope.pagination = result.pagination;
	});

	//Request pulses that match search string
	$scope.searchPulses = function () {

        SearchService.performSearch("es", "pulses", $scope.search, null, $scope.sort, false, true).then(function (result) {

            $rootScope.$broadcast("event:pulses-page-change", angular.fromJson(result));
            $scope.showPulses = true;
            $scope.loadingMore = false;
        });
	};

	//Run a new search
	$scope.runSearch = function(event) {

		$rootScope.currentSearch = $scope.search.search;
		$scope.searchPulses();
	};

	//Image update event - after location change from pulse editor
	$scope.deregisterUpdatePulseListHandler = $rootScope.$on("event:update-pulse-list", function(event, pulse) {

		if(typeof $scope.pulses != "undefined") {

			for(var i = 0; i < $scope.pulses.length; i++) {

				if($scope.pulses[i].data.id.value == pulse.data.id.value) {

					$scope.pulses[i].data.image.value = pulse.data.image.value
				}
			}
		}
	});

	/*
	 Create button call back function
	 */
	$scope.createCallback = function ($event) {

		if(RoleService.allowed(CONSTANTS.RIGHTS.CREATE_EDIT_PULSE, true)) {

            $rootScope.renderComplete = false;
            $rootScope.loadingStateText = "Creating Pulse";

            DataService.create("pulse").then(function (result) {

                $scope.pulses.push(result.pulse);
                $rootScope.createdPulse = result.pulse;
                $location.path("/pulse/" + result.pulse.data.id.value);

                $rootScope.renderComplete = true;
                $rootScope.loadingStateText = "";
            });
        }

		$event.preventDefault();
	};

	//Infinite Scroll
	$scope.loadMore = function() {

		if(typeof $scope.pagination != "undefined") {

			//If haven't already pulled down the last page of pulses and not already calling, then do so
			if($scope.pagination.current_page != $scope.pagination.last_page && !$scope.loadingMore) {

				$scope.loadingMore = true;

                SearchService.performSearch("es", "pulses", $scope.search, ($scope.pagination.current_page + 1), $scope.sort, false, true).then(function (result) {

					var parsedResult = angular.fromJson(result);

					for(var i = 0; i < parsedResult.pulses.length; i++) {

						$scope.pulses.push(parsedResult.pulses[i]);
					}

					if($scope.selected != "All" && $scope.selected != "Archived" && $scope.selected != "Purchased") {

						$scope.pulses = filterPulses($scope.pulses, $scope.selected);
					}

					$scope.pagination = parsedResult.pagination;

					$scope.loadingMore = false;
				});
			}
		}
	};

	$scope.querySearch = function(query){

		var suggestions = [];

		if( query == null || query == "") {
			$(".pulse-cell").css({"display":"inline-block"});

			//Show all
			for (var index = 0; index < $scope.originalset_pulses.length; index++) {

				angular.element("#" + $scope.originalset_pulses[index].data.id.value).css({"display": "inline-block"});
			}

			return suggestions;
		}

		$scope.noSearchResults = true;

		if(typeof $scope.originalset_pulses !== "undefined") {

			for (var index = 0; index < $scope.originalset_pulses.length; index++) {

				var answers = [];
				var questions = [];
				var titleExists = false;
				var answerExists = false;
				var questionExists = false;

				//Iterate over questions and answers and insert into arrays
				for (var index2 in $scope.originalset_pulses[index].data.questions) {

					if ($scope.originalset_pulses[index].data.questions[index2].data.text.value != "") {

						questions.push($scope.originalset_pulses[index].data.questions[index2].data.text.value.toLowerCase());
					}

					for (var index3 in $scope.originalset_pulses[index].data.questions[index2].data.answers) {

						if ($scope.originalset_pulses[index].data.questions[index2].data.answers[index3].data.text.value != "") {

							answers.push($scope.originalset_pulses[index].data.questions[index2].data.answers[index3].data.text.value.toLowerCase());
						}
					}
				}

				//Search questions and answers arrays for query value
				answerExists = searchStringInArray(query, answers);
				questionExists = searchStringInArray(query, questions);

				//Check title for match with query
				if ($scope.originalset_pulses[index].data.title.value.toLowerCase().indexOf(query.toLowerCase()) != -1) {

					titleExists = true;
				}

				//If query exists in the pulse title/questions/answers we show the card, otherwise it is hidden
				if (titleExists || answerExists || questionExists) {
					angular.element("#" + $scope.originalset_pulses[index].data.id.value).css({"display": "inline-block"});
					$scope.noSearchResults = false;
				} else {
					angular.element("#" + $scope.originalset_pulses[index].data.id.value).css({"display": "none"});
				}

			}
		}

		return suggestions;
	};

	// for autocompelte
	$scope.simulateQuery = true;

	$scope.selectedItemChange = function(selectedItem) {
		if(selectedItem == null){
			return;
		}

		//$scope.querySearch(selectedItem.data.title.value);
	};

	$scope.initColorTheif = function(imageDivId, imgSrc){

		var img = new Image();
		img.crossOrigin = "anonymous";
		img.onload = function () {
			var colorThief = new ColorThief();
			var color = colorThief.getColor(img);
			document.getElementById("link-" + imageDivId).style.backgroundColor = "rgba(" + color + ", 0.40)";
		};
		img.src = imgSrc;
	};

	$scope.share = function($event, pulse, provider) {

		$event.stopPropagation();

		SocialShareService.share("pulse", pulse.data, provider);
	};

	$scope.calculateRatingWidth = calculateRatingWidth;

	$scope.showArchivedPulseModal = function() {

		$mdDialog.show({
			controller: "PulsesController",
			templateUrl: "app/views/elements/includes/archive-pulse-download.html",
			clickOutsideToClose: true
		});
	};

	$scope.closeDialog = function() {

		$mdDialog.hide();
	};

	$scope.focusInput = function(check, id) {

	    if(check) {
			$timeout(function() {

				$('#' + id).focus();
			}, 600);

            
        }
    };

	$scope.getArchivedPulses = function() {

		DataService.getArchivedPulses().then(function(result) {

			$scope.archives = result.archives;
		});
	};

		$scope.getArchivedPulse = function(format, pulse) {

			DataService.getArchivedPulse(format, pulse.data_token).then(function(result) {

				var filename = "";
				var file = null;

				switch(format) {
					case "csv":

						filename = pulse.data.title.value + ".csv";

						file = new Blob([result], {type: "text/csv"});

						break;

					case "excel":

						filename = pulse.data.title.value + ".xlsx";

						file = new Blob([result], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

						break;
				}

				if(file != null) {

					ExportService.saveFile(file, filename);
				}
			});
		};

	$scope.getArchivedPulses();

	//Remove a pulse from scope by data_token
	$scope.removePulse = function(data_token) {

		for(var i = 0; i < $scope.pulses.length; i++) {

			if($scope.pulses[i].data_token === data_token) {

                $scope.pulses.splice(i, 1);
				$route.reload();
			}
		}
	};

	var init = function() {

		$scope.search.search = "";
		$scope.search.status = "Draft Active Scheduled Closed";

		SearchService.performSearch("es", "pulses", $scope.search, null, $scope.sort, false, true).then(function (result) {

			$scope.allPulses = angular.fromJson(result).pulses;
			$scope.pagination = angular.fromJson(result).pagination;

			DataService.fetch("pulses/catalogue").then(function (result2) {

				$scope.purchasedPulses = angular.fromJson(result2).purchased_pulses;

				setScope();
			});
		});
	};

	init();
}]);
