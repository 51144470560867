onePulse.controller('FriendRequestController', ['$scope', 'DataService', '$window', '$routeParams', 'CONSTANTS',
    function ($scope, DataService, $window, $routeParams, CONSTANTS) {

        $scope.code = $routeParams.code;

        //Get Mobile OS. 'unknown' for desktop
        $scope.mobileOS = getMobileOperatingSystem();

        //Link to iOS/Android store
        $scope.storeLink = function() {

            switch($scope.mobileOS) {

                case 'Android':
                    $window.open(CONSTANTS.ANDROID_APP, '_blank');
                    break;
                case 'iOS':
                    $window.open(CONSTANTS.IOS_APP, '_blank');
                    break;
                default:
                    break;
            }
        };

        var init = function() {

            DataService.invite($scope.code).then(function (result) {

                $scope.user = result.mobile_user;
            });
        };

        init();

}]);