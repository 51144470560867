
onePulse.directive('workspace.workspace', ['WorkspaceService',
    function(WorkspaceService) {

    linkFunction = function($scope, element, attributes){

        $scope.currentWorkspace = null;
        $scope.otherWorkspaces = [];

        var init = function() {

            $scope.currentWorkspace = null;
            $scope.otherWorkspaces = [];

            for(var i = 0; i < $scope.user.data.workspaces.length; i++) {

                if($scope.user.data.workspaces[i].code.value === $scope.user.data.client_code.value) {

                    $scope.currentWorkspace = $scope.user.data.workspaces[i];
                } else {

                    $scope.otherWorkspaces.push($scope.user.data.workspaces[i]);
                }
            }
        };

        init();

        $scope.createWorkspace = function($event) {

            WorkspaceService.createWorkspace($event, $scope.user, $scope.currentWorkspace, $scope.otherWorkspaces).then(function() {

                init();
            });
        };

        $scope.switchWorkspace = function(code) {

            WorkspaceService.switchWorkspace(code);
        };
    };

    return {
        scope: false,
        templateUrl: '/app/views/elements/forms/workspace/workspace.html',
        link:linkFunction
    }
}]);
