onePulse.controller('PanelSizeDialogCtrl', ['$scope', '$mdDialog', 'DataService', '$rootScope', '$mdpDatePicker', 'ValidationService', 'LZString', 'RoleService',
    '$mdpTimePicker', 'FeatureService', 'CONSTANTS', '$window', '$route', '$translate', 'AlertService', '$location', '$timeout',
    function($scope, $mdDialog, DataService, $rootScope, $mdpDatePicker, ValidationService, LZString, RoleService,
    $mdpTimePicker, FeatureService, CONSTANTS, $window, $route, $translate, AlertService, $location, $timeout) {

    $ruleMaxParticipants = /max:(\d+)/.exec($scope.pulse.data.participants.rules)[0];

    //TEMP: Removed as part of business request
    $scope.maxAllowableParticipants = (typeof $scope.pulse.maxParticipants != "undefined" ? $scope.pulse.maxParticipants : null);

    $scope.user = DataService.grab('user');

    //Default setting for publish button (2 states: 'Publish'/'Topup')
    $scope.submitButtonState = 'Publish';

    //Default setting for warning message (5 states: 'None'/'InsufficientCredit'/'NoCredit/ZeroParticipants/ChannelNotInit')
    $scope.warningState = 'None';

    //Default setting for publish type (3 states: 'None'/'AutoBill'/'Mixed')
    $scope.publishState = 'None';

    $scope.checkoutStep = 1;

    //Default setting for checkout type (3 states: 'Direct'/'Topup'/'Sub')
    $scope.checkoutType = 'Direct';

    //Toggles for displaying scheduler and Publish Url
    $scope.scheduler = false;

    //Control for live update
    $scope.isLiveUpdate = false;

    $scope.expiredChanged = false;
    $scope.startChanged = false;

    $scope.submitSpinner = false;
    $rootScope.showStepLoadingSpinner = false;

    $scope.currentDate =
    $scope.currentTimeStartJS =
    $scope.currentDateStartJS = new Date();

    //Default top-up
    $scope.selectedTopup = 'autoBill';
    $rootScope.descriptionTopup = $scope.selectedTopup;

    //Set dates - If pulse is not in a draft state, we are updating so need to pull current start and end dates
    $scope.currentDateStart = ($scope.pulse.data.status.value === "Draft" ? 'TODAY' : new Date($scope.pulse.data.started_at.value));
    $scope.currentDateEnd = ($scope.pulse.data.status.value === "Draft" ? new Date() : new Date($scope.pulse.data.expired_at.value));

    //Add 7 days to default end date if pulse in draft state
    if($scope.pulse.data.status.value === "Draft") {

        $scope.currentDateEnd.setDate($scope.currentDateEnd.getDate() + 7);
    }

    //Set times - If pulse is not in a draft state, we are updating so need to pull current start and end times
    $scope.currentTimeStart = ($scope.pulse.data.status.value === "Draft" ? 'NOW' : new Date($scope.pulse.data.started_at.value));
    $scope.currentTimeEnd = ($scope.pulse.data.status.value === "Draft" ? new Date() : new Date($scope.pulse.data.expired_at.value));

    $rootScope.pulseDateEnd = $scope.currentDateEnd;
    $rootScope.pulseDateStart = new Date();

    $scope.currentlySelectedTimezones = [];

    //Check the currently set pulse start time and set warning accordingly
    $scope.checkPublishTime = function(startTime) {

        if($scope.user.data.client_home_region != null) {

            var hourStart = startTime.getHours();
            var selectedRegions = [];

            $scope.currentlySelectedTimezones = [];
            $scope.quietTimeRegions = [];

            for(var i = 0; i < $scope.pulse.data.regions.options.length; i++) {

                if ($scope.pulse.data.regions.options[i].option.selected) {

                    selectedRegions.push($scope.pulse.data.regions.options[i].option.code);

                    if(typeof CONSTANTS.PULSE_QUIET_TIMES[$scope.pulse.data.regions.options[i].option.code] !== "undefined") {

                        for(var j = 0; j < CONSTANTS.PULSE_QUIET_TIMES[$scope.pulse.data.regions.options[i].option.code].UTC_OFFSETS.length; j++) {

                            $scope.currentlySelectedTimezones.push('UTC' + CONSTANTS.PULSE_QUIET_TIMES[$scope.pulse.data.regions.options[i].option.code].UTC_OFFSETS[j]);
                        }
                    }
                }
            }

            if(selectedRegions.length <= 1) {

                if(selectedRegions.indexOf($scope.user.data.client_home_region.code) !== -1) {

                    $scope.publishTimeWarningType = "home_region";
                } else {

                    $scope.publishTimeWarningType = "other_region";
                }
            } else {

                $scope.publishTimeWarningType = "multi_region";
            }

            $scope.quietTimeRegions = outsideOfPulseQuietTimes(selectedRegions, CONSTANTS.PULSE_QUIET_TIMES, hourStart);

            //If set start time is between our pre-defined quiet hours we need to warn the user
            if($scope.quietTimeRegions.length > 0) {

                $timeout(function() {

                    $scope.warningState = 'publishTime';
                })
            }
        }
    };

    $scope.showDatePickerStart = function(ev) {

        $scope.startChanged = true;

        var tempCurrentDate = $scope.currentDateStart == 'TODAY' ? new Date() : $scope.currentDateStart;
        var tempCurrentTime = $scope.currentTimeStart == 'NOW' ? new Date() : $scope.currentTimeStart;

        $rootScope.pulseDateStart = new Date(formatDate(new Date(tempCurrentDate), new Date(tempCurrentTime)));
        $rootScope.pulseDateEnd = new Date(formatDate(new Date($scope.currentDateEnd), new Date($scope.currentTimeEnd)));
        $rootScope.pickerType = CONSTANTS.DATE_PICKER_START;

        //var tempDate = new Date($scope.currentDateEnd);
        //tempDate.setDate(tempDate.getDate() + 1);

        $mdpDatePicker($scope.currentDateStart, {
            minDate: $scope.currentDate,
            maxDate: $scope.currentDateEnd,
            targetEvent: ev
        }).then(function(selectedDate) {
            $scope.currentDateStart = selectedDate;
            $scope.currentDateStartJS = new Date(selectedDate);

            $scope.showTimePickerStart(ev);
        });
    };

    $scope.showDatePickerEnd = function(ev) {

        $scope.expiredChanged = true;

        var tempCurrentDate = $scope.currentDateStart == 'TODAY' ? new Date() : $scope.currentDateStart;
        var tempCurrentTime = $scope.currentTimeStart == 'NOW' ? new Date() : $scope.currentTimeStart;

        $rootScope.pulseDateStart = new Date(formatDate(new Date(tempCurrentDate), new Date(tempCurrentTime)));
        $rootScope.pulseDateEnd = new Date(formatDate(new Date($scope.currentDateEnd), new Date($scope.currentTimeEnd)));
        $rootScope.pickerType = CONSTANTS.DATE_PICKER_END;

        var tempDate = new Date(tempCurrentDate);
        tempDate.setDate(tempDate.getDate() - 1);

        $mdpDatePicker($scope.currentDateEnd, {
            minDate: tempDate,
            targetEvent: ev
        }).then(function(selectedDate) {
            $scope.currentDateEnd = selectedDate;

            $scope.showTimePickerEnd(ev);
        });
    };

    $scope.filterDate = function(date) {
        return moment(date).date() % 2 == 0;
    };

    $scope.showTimePickerStart = function(ev) {

        var tempCurrentDate = $scope.currentDateStart == 'TODAY' ? new Date() : $scope.currentDateStart;
        var tempCurrentTime = $scope.currentTimeStart == 'NOW' ? new Date() : $scope.currentTimeStart;

        $rootScope.pulseDateStart = new Date(formatDate(new Date(tempCurrentDate), new Date(tempCurrentTime)));
        $rootScope.pulseDateEnd = new Date(formatDate(new Date($scope.currentDateEnd), new Date($scope.currentTimeEnd)));
        $rootScope.pickerType = CONSTANTS.DATE_PICKER_START;

        $mdpTimePicker($scope.currentTimeStart, {
            targetEvent: ev
        }).then(function(selectedDate) {
            $scope.currentTimeStart = selectedDate;
            $scope.currentTimeStartJS = new Date(selectedDate);
            $scope.warningState = 'None';

            $scope.checkPublishTime($scope.currentTimeStartJS);
        });
    };

    $scope.showTimePickerEnd = function(ev) {

        var tempCurrentDate = $scope.currentDateStart == 'TODAY' ? new Date() : $scope.currentDateStart;
        var tempCurrentTime = $scope.currentTimeStart == 'NOW' ? new Date() : $scope.currentTimeStart;

        $rootScope.pulseDateStart = new Date(formatDate(new Date(tempCurrentDate), new Date(tempCurrentTime)));
        $rootScope.pulseDateEnd = new Date(formatDate(new Date($scope.currentDateEnd), new Date($scope.currentTimeEnd)));
        $rootScope.pickerType = CONSTANTS.DATE_PICKER_END;

        $mdpTimePicker($scope.currentTimeEnd, {
            targetEvent: ev
        }).then(function(selectedDate) {
            $scope.currentTimeEnd = selectedDate;
        });
    };

    $scope.getDatetime = function() {
        return new Date();
    };

    //Toggle publish to share
    $scope.togglePublishShare = function() {

        DataService.fetchItem("pulse/no_push", $scope.pulse.data_token).then(function(result) {

            $scope.pulse.data.is_no_push = result.pulse.data.is_no_push;
            $scope.pulse.data.pulse_type_id = result.pulse.data.pulse_type_id;
        });
    };

    // Integer and fraction parts of max value are styled differently, that's why we need to split the value into two
    // parts and format them properly.
    $updateValueParts = function() {

        if(($scope.pulse.data.status.value === "Draft" && (parseInt($scope.pulse.data.participants.value) > parseInt($scope.user.data.balance.value))) ||
            ($scope.pulse.data.status.value !== "Draft")) {

            $scope.integerAmount =$scope.chargeablePulseValue.toString().split('.')[0];
            $scope.fractionAmount =$scope.chargeablePulseValue.toString().split('.')[1];
        } else if($scope.pulse.data.status.value === "Draft") {

            $scope.integerAmount =$scope.$maxPulseValue.toString().split('.')[0];
            $scope.fractionAmount =$scope.$maxPulseValue.toString().split('.')[1];
        }

        if($scope.integerAmount == 0 || $scope.integerAmount == undefined) {
            $scope.integerAmount = '00';
        } else if($scope.integerAmount.length == 1) {
            $scope.integerAmount = '0' + $scope.integerAmount;
        }

        if($scope.fractionAmount == 0 || $scope.fractionAmount == undefined) {
            $scope.fractionAmount = '00';
        } else if($scope.fractionAmount.length == 1) {
            $scope.fractionAmount = $scope.fractionAmount + '0';
        }
    };

    //$scope.$maxPulseValue = $scope.pulse.data.participants.value * $scope.user.data.current_subscription.data.response_value.value;

    $scope.$maxPulseValue = Math.round($scope.pulse.data.participants.value * $scope.user.data.current_subscription.data.response_value.value * 100) / 100;

    if(($scope.pulse.data.status.value === "Draft" && (parseInt($scope.pulse.data.participants.value) > parseInt($scope.user.data.balance.value))) ||
        ($scope.pulse.data.status.value !== "Draft")) {

        var difference = ($scope.pulse.data.status.value === "Draft" ? parseInt($scope.pulse.data.participants.value) - parseInt($scope.user.data.balance.value) : (parseInt($scope.pulse.data.participants.value) - parseInt($scope.originalParticipantCount)) - (parseInt($scope.user.data.balance.value)));
        $scope.chargeablePulseValue = Math.round(difference * $scope.user.data.current_subscription.data.response_value.value * 100) / 100;
    }

    $updateValueParts();

    $scope.showTotalDue = function() {

        //Display total due only if user credit is less than the required participants and the account is not set to free
        return (parseInt($scope.pulse.data.participants.value) >  parseInt($scope.user.data.balance.value) && $scope.user.data.is_free_account.value != "1");
    };

    //Check that user has enough credit to publish to the selected number of participants
    $scope.checkCredit = function(prePublish, payload, deviceData) {

        //Grab user again - necessary if handling event
        $scope.user = DataService.grab('user');

        //Pulse is not in draft state so this is a live update
        $scope.isLiveUpdate = ($scope.pulse.data.status.value !== "Draft");

        //If pulse is not draft, it is an active/scheduled update so we only take the difference between the original participant count and the new participant count
        $scope.participantCount = (!$scope.isLiveUpdate ? parseInt($scope.pulse.data.participants.value) : parseInt($scope.pulse.data.participants.value) - parseInt($scope.originalParticipantCount));

        //Only allow publishing to over 0 participants or if on a live update, only to >= participants than currently published to
        if($scope.participantCount > 0 || ($scope.pulse.data.status.value !== "Draft" && $scope.participantCount >= 0)) {

            //TEMP: workaround in place for Algeria - Publish directly if hostname matches Algerian instance
            if(($scope.$maxPulseValue == 0 && $scope.user.data.is_autobilled.value == "0")
                || $scope.user.data.is_free_account.value == "1"
                || $location.host() == 'dz.onepulse.com'
                || $location.host() == 'dz-alpha.onepulse.com') {

                $scope.publishState = 'Credit';
                $scope.submitButtonState = 'Publish';
                $scope.warningState = 'None';
                //If the user has credit greater than 0 we can continue to process the publish, otherwise we offer the Topup immediately
            } else if(parseInt($scope.user.data.balance.value) > 0) {

                //If the user does not have enough credit we need to check auto-bill status
                if (parseInt($scope.user.data.balance.value) < $scope.participantCount) {

                    //If the user is not auto-billed we need to present the top-up button and insufficient credit warning
                    if ($scope.user.data.is_autobilled.value == "0") {

                        $scope.publishState = 'None';
                        $scope.submitButtonState = 'Topup';
                        $scope.warningState = 'InsufficientCredit';
                        //Else if the user is auto-billed but they do have some credit, we can process a mixed form of publish payment
                    } else {

                        $scope.publishState = 'Mixed';
                        $scope.submitButtonState = 'Publish';
                        $scope.warningState = 'None';
                    }
                } else {

                    $scope.publishState = 'Credit';
                    $scope.submitButtonState = 'Publish';
                    $scope.warningState = 'None';
                }
                //Else if the user is auto-billed we can also proceed with the publish process
            } else if($scope.user.data.is_autobilled.value == "1") {

                $scope.publishState = 'AutoBill';
                $scope.submitButtonState = 'Topup';
                $scope.warningState = 'None';
            } else {

                $scope.publishState = 'None';
                $scope.submitButtonState = 'Topup';
                $scope.warningState = 'NoCredit';
            }

            if(prePublish) {

                $scope.prePublish(payload, deviceData);
            }
        } else {

            $scope.publishState = 'None';
            $scope.submitButtonState = 'None';
            $scope.warningState = 'ZeroParticipants';
        }
    };

    $scope.checkChannel = function() {

        $scope.group = findSelectedOption($scope.pulse.data.group.options);

        if(typeof $scope.group.option.query_object !== 'undefined') {

            if(typeof $scope.group.option.init_progress_percentage !== 'undefined' &&
                parseInt($scope.group.option.init_progress_percentage) < CONSTANTS.CHANNEL_INIT_PUBLISH_PERCENTAGE) {

                $scope.publishState = 'None';
                $scope.submitButtonState = 'None';
                $scope.warningState = 'ChannelNotInit';
            }
        }
    };

    $scope.init = function() {

        DataService.fetchItem('user').then(function (result) {

            $scope.user = result.user;
            $scope.checkCredit(false, null, null);
            $scope.checkPublishTime($scope.currentTimeStartJS);
            $scope.checkChannel();
        });
    };

    $scope.init();

    $scope.incrementSize = function() {

        $scope.pulse.data.participants.value++;

        //TEMP: Removed as part of business request
        if($scope.pulse.data.participants.value == parseInt($scope.maxAllowableParticipants + 1)) {

            var translations;
            $translate(['alert.participant_value_too_high_warning.title', 'alert.participant_value_too_high_warning.text']).then(function(result){
                translations = result;

                AlertService.warning(
                    translations['alert.participant_value_too_high_warning.title'],
                    translations['alert.participant_value_too_high_warning.text'] + '(' + $scope.maxAllowableParticipants +  ')'
                )
            });
        }
    };

    $scope.decrementSize = function() {

        $scope.pulse.data.participants.value--;
    };

    $scope.$watch('pulse.data.participants.value', function(newVal, oldVal) {

        if(oldVal != newVal) {

            if (newVal > $ruleMaxParticipants) {

                $scope.newVal = $ruleMaxParticipants;
            } else if (newVal < 1) {
                newVal = 1;
            }

            if (typeof newVal === 'undefined') {

                $scope.$maxPulseValue = 0;

                if(($scope.pulse.data.status.value === "Draft" && (parseInt(newVal) > parseInt($scope.user.data.balance.value))) ||
                    ($scope.pulse.data.status.value !== "Draft" && ((parseInt(newVal) - parseInt($scope.originalParticipantCount)) > parseInt($scope.user.data.balance.value)))) {

                    $scope.chargeablePulseValue = 0
                }
            } else {

               $scope.$maxPulseValue = Math.round(newVal * $scope.user.data.current_subscription.data.response_value.value * 100) / 100;

                if(($scope.pulse.data.status.value === "Draft" && (parseInt(newVal) > parseInt($scope.user.data.balance.value))) ||
                    ($scope.pulse.data.status.value !== "Draft" && ((parseInt(newVal) - parseInt($scope.originalParticipantCount)) > parseInt($scope.user.data.balance.value)))) {

                    var difference = ($scope.pulse.data.status.value === "Draft" ? parseInt(newVal) - parseInt($scope.user.data.balance.value) : (parseInt(newVal) - parseInt($scope.originalParticipantCount)) - (parseInt($scope.user.data.balance.value)));
                    $scope.chargeablePulseValue = Math.round(difference * $scope.user.data.current_subscription.data.response_value.value * 100) / 100;
                } else {

                    $scope.chargeablePulseValue = 0;
                }
            }

            $updateValueParts();

            $scope.checkCredit(false, null, null);
            $scope.checkChannel();

            if($scope.pulse != null) {

                $scope.pulse.data.participants.value = newVal;
            }
        }
    });

    //Reduce user's response balance
    $scope.reduceUserResponseBalance = function(debitResponses) {

        $scope.user.data.balance.value = parseInt($scope.user.data.balance.value) - debitResponses;
        $window.localStorage['user'] = LZString.compressToUTF16(JSON.stringify($scope.user));
    };

    $scope.publish = function(debitResponses) {

        //Set scheduled date & time before publish
        if ($scope.currentDateStart === 'TODAY' && $scope.currentTimeStart !== 'NOW') {

            $scope.pulse.data.started_at.value = formatDate(new Date(), new Date($scope.currentTimeStart));
        } else if ($scope.currentDateStart !== 'TODAY' && $scope.currentTimeStart === 'NOW') {

            $scope.pulse.data.started_at.value = formatDate(new Date($scope.currentDateStart), new Date());
        } else if ($scope.currentDateStart === 'TODAY' && $scope.currentTimeStart === 'NOW') {

            $scope.pulse.data.started_at.value = formatDate(new Date(), new Date());
        } else {

            $scope.pulse.data.started_at.value = formatDate(new Date($scope.currentDateStart), new Date($scope.currentTimeStart));
        }

        $scope.pulse.data.expired_at.value = formatDate(new Date($scope.currentDateEnd), new Date($scope.currentTimeEnd));

        //Set correct order of D&D question answers if it is necessary
        // for(var i = 0; i < $scope.pulse.data.questions.length; i++) {
        //
        //     if(typeof $scope.pulse.data.questions[i].answerTexts !== 'undefined') {
        //
        //         for(var j = 0; j < $scope.pulse.data.questions[i].data.answers.length; j++) {
        //
        //             $scope.pulse.data.questions[i].data.answers[j].data.text.value = $scope.pulse.data.questions[i].answerTexts[j];
        //             $scope.pulse.data.questions[i].data.answers[j].data.sequence.value = (j + 1).toString();
        //         }
        //     }
        // }

        $scope.pulse = ValidationService.duplicateAnswerCorrection($scope.pulse);

        $scope.pulse = ValidationService.answerSequenceCorrection($scope.pulse);

        //We need this for the reload after the live edit
        if($scope.isLiveUpdate) {

            $rootScope.liveUpdate = true;
            $rootScope.routeChanged = true;
            $rootScope.routeChangedPath = '/pulse/' + $scope.pulse.data.id.value;

            var data = {
                "started_at": $scope.pulse.data.started_at.value,
                "expired_at": $scope.pulse.data.expired_at.value,
                "participants": $scope.participantCount
            };

            var dataToken = $scope.pulse.data_token;

            DataService.livePublish('pulse', data, dataToken).then(function (result) {

                $scope.reduceUserResponseBalance(debitResponses);

                DataService.fetchItem('user').then(function (result) {

                    $scope.user = result.user;
                    $window.location = '/pulse/' + $scope.pulse.data.id.value;
                });
            });
        } else {

            DataService.submit('pulse', $scope.pulse).then(function (result) {

                $rootScope.$broadcast('event:pulse-update', result.pulse);

                angular.element('.got-error').removeClass('got-error');
                angular.element('.is-error').remove();

                DataService.publish('pulse', result.pulse.data_token).then(function (result) {

                    $scope.reduceUserResponseBalance(debitResponses);

                    DataService.fetchItem('user').then(function (result) {

                        $scope.user = result.user;
                        $window.location = '/pulse/' + $scope.pulse.data.id.value;
                    });
                });
            });
        }
    };

    $scope.prePublish = function(payload, deviceData) {

        //Handle participant limit feature
        if(!FeatureService.allowed(CONSTANTS.FEATURES.PARTICIPANTS, false) ||
            (FeatureService.allowed(CONSTANTS.FEATURES.PARTICIPANTS, false) &&
            $scope.pulse.data.participants.value <= FeatureService.checkMeta(CONSTANTS.FEATURES.PARTICIPANTS))) {

            var process = function() {

                $mdDialog.hide();
                $rootScope.renderComplete = false;

                $rootScope.loadingStateText = ($scope.pulse.data.status.value === "Draft" ? "Publishing Pulse" : "Updating Pulse");

                if ($scope.isTranslated) {
                    DataService.submit('pulse/translation', $scope.translation).then(function (result) {

                        $scope.translation = angular.fromJson(result.translation);
                        $scope.$broadcast('event:pulse-translated');
                    })
                }

                switch($scope.publishState) {

                    //Publish entirely on credit: Simply publish the pulse and debit the responses
                    case 'Credit':

                        $scope.publish(typeof $scope.participantCount !== "undefined" ? $scope.participantCount : parseInt($scope.pulse.data.participants.value));
                        break;

                    //Publish with a mix of credit and auto-bill: Bill for the difference between the user's credit and the required participants
                    case 'Mixed':

                        var data = {
                            "nonce": payload !== null ? payload.nonce : null,
                            "device_data": deviceData,
                            "responses": ((typeof $scope.participantCount !== "undefined" ? $scope.participantCount : parseInt($scope.pulse.data.participants.value)) - parseInt($scope.user.data.balance.value))
                        };

                        DataService.profileClient("topups", data, "/purchase/onthego").then(function(result) {

                            $scope.publish(parseInt($scope.user.data.balance.value));
                        });
                        break;

                    //Publish entirely on auto-bill: Bill for the entire number of required participants
                    case 'AutoBill':

                        var data = {
                            "nonce": payload !== null ? payload.nonce : null,
                            "device_data": deviceData,
                            "responses": (typeof $scope.participantCount !== "undefined" ? $scope.participantCount : parseInt($scope.pulse.data.participants.value))
                        };

                        DataService.profileClient("topups", data, "/purchase/onthego").then(function(result) {

                            $scope.publish(0);
                        });
                        break;
                }
            };

            //Controller for Pulse Upsell modal
            var pulseUpsellModalController = function($scope, $mdDialog, FeatureService, totalCost) {

                $scope.totalCost = totalCost;

                //Send Pulse
                $scope.process = function() {

                    process();
                };

                $scope.displayPlans = function() {

                    $mdDialog.hide();

                    FeatureService.showUpgradeModal();
                };

                $scope.closeDialog = function () {

                    $mdDialog.hide();

                    $timeout(function() {
                        angular.element('#schedulePulse').triggerHandler('click');
                    }, 0);
                }
            };

            //Controller for Participant Warning modal
            var participantWarningModalController = function($scope, $mdDialog, CONSTANTS, values, user, totalCost) {

                $scope.participants = values.participants;
                $scope.max = values.max;

                //Send Pulse
                $scope.process = function() {

                    if($scope.participants >= parseInt(CONSTANTS.PAYG_UPSELL_PARTICIPANT_COUNT)
                        && user.data.current_subscription.data.description.value == 'PAYG') {

                        pulseUpsellModalController.$inject = ['$scope', '$mdDialog', 'FeatureService', 'totalCost'];

                        $mdDialog.show({
                            templateUrl: 'app/views/elements/includes/pulse-up-sell.html',
                            controller: pulseUpsellModalController,
                            parent: angular.element(document.body),
                            clickOutsideToClose: false,
                            locals: {
                                totalCost: totalCost
                            }
                        });
                    } else {

                        process();
                    }
                };

                $scope.closeDialog = function () {

                    $mdDialog.hide();

                    $timeout(function() {
                        angular.element('#schedulePulse').triggerHandler('click');
                    }, 0);
                }
            };

            //If the set participant value is higher than the max number of potential participants, we display a warning modal
            if($scope.pulse.data.participants.value > parseInt($scope.maxAllowableParticipants)) {

                var values = {
                    "participants": $scope.pulse.data.participants.value,
                    "max": $scope.maxAllowableParticipants
                };

                participantWarningModalController.$inject = ['$scope', '$mdDialog', 'CONSTANTS', 'values', 'user', 'totalCost'];

                $mdDialog.show({
                    templateUrl: 'app/views/elements/includes/pulse-value-too-high.html',
                    controller: participantWarningModalController,
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    locals: {
                        values: values,
                        user: $scope.user,
                        totalCost: $scope.$maxPulseValue
                    }
                });
            //If the set participant value is higher than the upsell limit for PAYG accounts, we present a sub upgrade modal
            } else if($scope.pulse.data.participants.value >= parseInt(CONSTANTS.PAYG_UPSELL_PARTICIPANT_COUNT)
                && $scope.user.data.current_subscription.data.description.value == 'PAYG') {

                pulseUpsellModalController.$inject = ['$scope', '$mdDialog', 'FeatureService', 'totalCost'];

                $mdDialog.show({
                    templateUrl: 'app/views/elements/includes/pulse-up-sell.html',
                    controller: pulseUpsellModalController,
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    locals: {
                        totalCost: $scope.$maxPulseValue
                    }
                });
            } else {

                process();
            }
        } else {

            var translations;
            $translate(['alert.participant_count_too_high.title']).then(function(result) {
                translations = result;

                AlertService.alert(
                    translations['alert.participant_count_too_high.title'],
                    "Your current subscription caps your Pulses at a participant count of " + FeatureService.checkMeta(CONSTANTS.FEATURES.PARTICIPANTS) + ". Please get in touch with your account manager, or at ukclients@onepulse.com (UK) or usclients@onepulse.com (US) if you need to send out a larger Pulse!"
                    );
            });
        }
    };

    //Error callback for Braintree
    $scope.errorCallback = function() {

        var translations;
        $translate(['alert.card_details_error.title', 'alert.card_details_error.text']).then(function(result) {
            translations = result;

            AlertService.error(
                translations['alert.card_details_error.title'],
                translations['alert.card_details_error.text']
            );
        });
    };

    //Success callback for Braintree - Onthego payment
    $scope.successCallbackOnthego = function(payload, deviceData) {

        if(!$scope.submitSpinner) {

            $scope.submitSpinner = true;

            // Submit payload.nonce and other data
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "responses": ((typeof $scope.participantCount !== "undefined" ? $scope.participantCount : parseInt($scope.pulse.data.participants.value)) - parseInt($scope.user.data.balance.value))
            };

            DataService.profileClient("topups", data, "/purchase/onthego", function() {

                $scope.submitSpinner = false;

                //Hide loading spinner and show confirm button text
                $('.button-text').css('display', 'block');
                $('.button-spinner').css('display', 'none');

                //Enable submit button
                $('#submit').prop("disabled",false);
            }).then(function (result) {

                $scope.publish(parseInt($scope.user.data.balance.value));
            });
        }
    };

    //Success callback for Braintree - Topup payment
    $scope.successCallbackTopup = function(payload, deviceData) {

        if(!$scope.submitSpinner) {

            $scope.submitSpinner = true;

            var data_token = $('#selectedDataToken').val();
            var description = $('#selectedDescription').val();
            var responsesCredited = $('#selectedResponsesCredited').val();

            // Submit payload.nonce and other data
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "data_token": data_token
            };

            DataService.profileClient("topups", data, "/purchase", function() {

                $scope.submitSpinner = false;

                //Hide loading spinner and show confirm button text
                $('.button-text').css('display', 'block');
                $('.button-spinner').css('display', 'none');

                //Enable submit button
                $('#submit').prop("disabled",false);
            }).then(function (result) {

                //Have to enable autobilling if purchase is made
                $scope.user.data.is_autobilled.value = "1";

                DataService.submit('user', $scope.user).then(function (result) {

                    $scope.submitSpinner = false;
                    $scope.user = result.user;

                    //Store topup object for use in next modal
                    $rootScope.descriptionTopup = description;

                    $rootScope.modalOpen = false;
                    $mdDialog.hide();
                    $timeout(function () {
                        angular.element('#schedulePulse').triggerHandler('click');
                    }, 0)
                });
            });
        }
    };

    //Success callback for Braintree - Subscription payment
    $scope.successCallbackSubs = function(payload, deviceData) {

        if(!$scope.submitSpinner) {

            $scope.submitSpinner = true;

            var data_token = $('#selectedDataToken').val();
            var description = $('#selectedDescription').val();

            // Submit payload.nonce and other data
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "data_token": data_token
            };

            DataService.profileClient("subscription", data, "/purchase", function() {

                $scope.submitSpinner = false;

                //Hide loading spinner and show confirm button text
                $('.button-text').css('display', 'block');
                $('.button-spinner').css('display', 'none');

                //Enable submit button
                $('#submit').prop("disabled",false);
            }).then(function (result) {

                data = {
                    "braintree_subscription_key": result.braintree_subscription_key
                };

                DataService.profileClient("subscription", data, "/status", function() {

                    $scope.submitSpinner = false;

                    //Hide loading spinner and show confirm button text
                    $('.button-text').css('display', 'block');
                    $('.button-spinner').css('display', 'none');

                    //Enable submit button
                    $('#submit').prop("disabled",false);
                }).then(function (result2) {

                    //Set the new access token and reinitialize the feature set
                    TokenService.setAccessToken(result2.access_token);

                    DataService.fetchMetaData("products/features").then(function (result3) {

                        FeatureService.initFeatures(result3);

                        //Have to enable autobilling if purchase is made
                        $scope.user.data.is_autobilled.value = "1";

                        DataService.submit('user', $scope.user).then(function (result4) {

                            $scope.submitSpinner = false;
                            $scope.user = result4.user;

                            //Store subscription object for use in next modal
                            $rootScope.descriptionSubscription = description;

                            $rootScope.modalOpen = false;
                            $mdDialog.hide();
                            $timeout(function () {
                                angular.element('#schedulePulse').triggerHandler('click');
                            }, 0)
                        });
                    });
                });
            });
        }
    };

    $scope.successCallbackPaymentCreate = function(payload, deviceData) {

        if(!$scope.submitSpinner) {

            $scope.submitSpinner = true;
            $scope.isDefault = true;

            //Register client's banking details - set as preferred if selected
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "is_default": true
            };

            // DataService.paymentMethod('create', data).then(function (result) {
            //
            //     $rootScope.isDefault = true;
            //
            //     //Set user is_autobilled to true and update user
            //     $scope.user.data.is_autobilled.value = "1";
            //
            //     DataService.submit('user', $scope.user).then(function (result) {
            //
            //         $scope.submitSpinner = false;
            //         $scope.user = result.user;
            //
            //         $scope.checkCredit(true, payload, deviceData);
            //     });
            // });

            $scope.submitSpinner = false;

            $scope.checkCredit(true, payload, deviceData);
        }
    };

    $scope.goToStep = function(step) {

        $scope.checkoutStep = step;
    };

    $scope.step = function(purchase, description) {

        switch($scope.checkoutStep) {

            //Participant selection
            case 1:

                $rootScope.showStepLoadingSpinner = true;
                $scope.checkoutType = 'Direct';
                $rootScope.descriptionTopup = null;
                $rootScope.descriptionSubscription = null;

                if(purchase || $scope.user.data.current_subscription.data.description.value === 'Enterprise') {

                    $scope.checkoutStep = 4;

                    DataService.fetchBrainTreeClientToken().then(function(result) {

                        var brainTreeClientToken = result.token;

                        initiateBrainTree(brainTreeClientToken, '.direct-form', $scope.errorCallback, $scope.successCallbackPaymentCreate);
                        $rootScope.showStepLoadingSpinner = false;
                    });
                } else {

                    $scope.checkoutStep = 2;
                }

                break;

            //Credit upsell
            case 2:

                $rootScope.showStepLoadingSpinner = true;

                if(purchase) {

                    $rootScope.descriptionTopup = description;
                    $scope.checkoutType = 'Topup';
                    $scope.checkoutStep = 4;

                    DataService.fetchBrainTreeClientToken().then(function(result) {

                        var brainTreeClientToken = result.token;

                        initiateBrainTree(brainTreeClientToken, '.topup-form', $scope.errorCallback, $scope.successCallbackTopup);
                        $rootScope.showStepLoadingSpinner = false;
                    });
                } else {

                    $scope.checkoutStep = 3;
                }

                break;

            //Sub upsell
            case 3:

                $rootScope.showStepLoadingSpinner = true;
                $scope.checkoutStep = 4;

                if(purchase) {

                    $rootScope.descriptionSubscription = description;
                    $scope.checkoutType = 'Sub';

                    if(RoleService.allowed(CONSTANTS.RIGHTS.PURCHASE_PULSE_EDIT_SUB_PAYMENT, true)) {

                        DataService.fetchBrainTreeClientToken().then(function (result) {

                            var brainTreeClientToken = result.token;

                            initiateBrainTree(brainTreeClientToken, '.subs-form', $scope.errorCallback, $scope.successCallbackSubs);
                            $rootScope.showStepLoadingSpinner = false;
                        });
                    }
                } else {

                    $rootScope.descriptionTopup = null;
                    $rootScope.descriptionSubscription = null;
                    $scope.checkoutType = 'Direct';

                    DataService.fetchBrainTreeClientToken().then(function(result) {

                        var brainTreeClientToken = result.token;

                        initiateBrainTree(brainTreeClientToken, '.direct-form', $scope.errorCallback, $scope.successCallbackPaymentCreate);
                        $rootScope.showStepLoadingSpinner = false;
                    });
                }

                break;
        }
    };

    $scope.toFloat = function(string) {

        return parseFloat(string);
    };

    $scope.closeDialog = function(){

        $mdDialog.hide();
    };
}]);
