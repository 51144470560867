/**
 * Created by Barry on 13/06/2017.
 *
 * Directive for displaying a question sentiment results card
 */
onePulse.directive('questionSentimentResults', ['DataService', '$mdDialog', 'FeatureService', 'ExportService', 'CONSTANTS', '$rootScope', '$timeout',
    function(DataService, $mdDialog, FeatureService, ExportService, CONSTANTS, $rootScope, $timeout) {

    linkFunction = function($scope,element,attributes) {

        //Determine question format
        if (typeof $scope.question.data.format.options != 'undefined') {

            for (var i = 0; i < $scope.question.data.format.options.length; i++) {

                if ($scope.question.data.format.options[i].option.selected == true) {

                    $scope.question.formatText = $scope.question.data.format.options[i].option.text;
                }
            }
        } else {

            $scope.question.formatText = $scope.question.data.format.value;
        }

        //Only define link function if question format is correct
        if ($scope.question.formatText == 'Free Text') {

            $scope.ctrl = {
                "orderDirection": "desc"
            };

            $scope.resultsDataLoaded = false;
            $scope.wordCloudDataLoaded = false;
            $scope.sentimentDataLoaded = false;

            $scope.originalResults = null;

            $scope.showAllComments = true;

            $scope.determineSentimentColour = function (score) {

                var colourRGB = null;

                if (parseFloat(score) > -1.00 && parseFloat(score) < -0.85) {

                    colourRGB = "rgb(255,29,50)";
                } else if (parseFloat(score) >= -0.85 && parseFloat(score) < -0.70) {

                    colourRGB = "rgb(255,69,50)";
                } else if (parseFloat(score) >= -0.70 && parseFloat(score) < -0.55) {

                    colourRGB = "rgb(255,108,51)";
                } else if (parseFloat(score) >= -0.55 && parseFloat(score) < -0.30) {

                    colourRGB = "rgb(255,147,51)";
                } else if (parseFloat(score) >= -0.30 && parseFloat(score) < 0.15) {

                    colourRGB = "rgb(255,187,51)";
                } else if (parseFloat(score) >= 0.15 && parseFloat(score) < 0.30) {

                    colourRGB = "rgb(255,228,77)";
                } else if (parseFloat(score) >= 0.30 && parseFloat(score) < 0.55) {

                    colourRGB = "rgb(179,210,72)";
                } else if (parseFloat(score) >= 0.55 && parseFloat(score) < 0.70) {

                    colourRGB = "rgb(140,201,70)";
                } else {

                    colourRGB = "rgb(51,201,72)";
                }

                return colourRGB;
            };

            var getQuestionData = function () {

                $scope.resultsDataLoaded = false;
                $scope.wordCloudDataLoaded = false;
                $scope.sentimentDataLoaded = false;

                $scope.$parent.showOTChartSpinner = true;

                DataService.getQuestionData($scope.question.data_token).then(function (result) {

                    $scope.originalResults = angular.copy(result);

                    buildDemographicsPostPublish(result);

                    $scope.dataTable = angular.copy(result.data_table);

                    $scope.question.dataTable = $scope.dataTable;
                    $scope.question.rawDataTable = result.data_table;

                    $scope.result = result;

                    if ($scope.result.question.total_responses > 1) {

                        $scope.showFilterControls = true;
                    }

                    //Total number of unique participants
                    $scope.uniqueIdArray = [];

                    for (var i = 1; i < $scope.result.data_table.length; i++) {

                        $scope.result.data_table[i][12] = $scope.result.data_table[i][12].toString();

                        if ($scope.uniqueIdArray.indexOf($scope.result.data_table[i][0]) == -1) {

                            $scope.uniqueIdArray.push($scope.result.data_table[i][0]);
                        }
                    }

                    $scope.question.total_responses_multiple = $scope.uniqueIdArray.length;

                    $scope.resultsDataLoaded = true;
                    $scope.$parent.showOTChartSpinner = false;

                    $scope.initResponseHandler(result);
                });
            };

            getQuestionData();

            var buildDemographicsPostPublish = function(result) {

                $timeout(function() {

                    if(typeof $scope.demographicsPostPublish != "undefined") {

                        if($scope.demographicsPostPublish.length == 0) {

                            for(var i = 0; i < result.filters.length; i++) {

                                $scope.demographicsPostPublish[i] = {};
                                $scope.demographicsPostPublish[i].text = result.filters[i].title;
                                $scope.demographicsPostPublish[i].values = {};
                                $scope.demographicsPostPublish[i].values.options = [];

                                var valueArray = [];

                                if(result.filters[i].options != null) {

                                    for (var j = 0; j < result.filters[i].options.length; j++) {

                                        if (result.filters[i].options[j].indexOf(", ") != -1 &&
                                            result.filters[i].title != 'Drinking alcohol' &&
                                            result.filters[i].title != 'Employer sector') {

                                            valueArray = result.filters[i].options[j].split(", ");
                                            valueArray = valueArray.reduce(function (a, b) {
                                                if (a.indexOf(b) < 0) a.push(b);
                                                return a;
                                            }, []);
                                        } else {

                                            valueArray.push(result.filters[i].options[j]);
                                        }
                                    }

                                    // var valueArrayUnique = valueArray.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);

                                    for (var k = 0; k < valueArray.length; k++) {

                                        $scope.demographicsPostPublish[i].values.options[k] = {};

                                        $scope.demographicsPostPublish[i].values.options[k].option = {};
                                        $scope.demographicsPostPublish[i].values.options[k].option.text = valueArray[k];
                                        $scope.demographicsPostPublish[i].values.options[k].option.selected = true;
                                    }
                                }
                            }

                            $rootScope.$broadcast("event:post-dems-loaded", $scope.demographicsPostPublish);
                        }
                    }
                });
            };

            $scope.initResponseHandler = function (result) {

                $scope.$on('event:user_responded', function ($event, $payload) {

                    $scope.resultsDataLoaded = true;

                    if ($scope.question.data.id.value in $payload.data) {

                        if (typeof $payload.data[$scope.question.data.id.value][0] != "undefined") {

                            if ($scope.uniqueIdArray.indexOf($payload.data[$scope.question.data.id.value][0][0] == -1)) {

                                $scope.uniqueIdArray.push($payload.data[$scope.question.data.id.value][0][0]);
                                $scope.question.total_responses_multiple++;
                            }

                            //Add comment to scope
                            if ($payload.data[$scope.question.data.id.value][0][9] != "") {

                                var comment = {
                                    "user": $payload.profile,
                                    "text": {
                                        "value": $payload.data[$scope.question.data.id.value][0][9]
                                    }
                                };

                                $scope.question.comments.data.push(comment);
                            }
                        }

                        $scope.showFilterControls = true;

                        if (typeof $scope.responseControl != "undefined") {

                            $scope.responseControl.show = true;
                        }

                        $scope.headCount++;

                        if (typeof $scope.slickConfigPulse != "undefined") {

                            if ($scope.question.data.sequence.value == 1) {

                                var firstDem = $payload.data[$scope.question.data.id.value][0][15];
                                var secondDem = $payload.data[$scope.question.data.id.value][0][16];

                                var avatarImage = getUserAvatar($payload.profile.avatar, firstDem, secondDem);

                                var displayData = "<span>" + firstDem +
                                    "<br /> " + secondDem + "</span>";

                                $scope.slickConfigPulse.method.slickAdd(
                                    "<div class='user-response-item'>" +
                                    "<div class='user-avatar'>" +
                                    "<img src='" + avatarImage + "'/>" +
                                    "</div>" +
                                    "<div class='info'>" +
                                    displayData +
                                    "</div>" +
                                    "</div>");

                                $scope.slickConfigPulse.method.slickNext();
                            }
                        }
                    }

                    angular.forEach($payload.data[$scope.question.data.id.value], function (row) {

                        //API can not be depended on to always return a string. Need to make sure type is properly set
                        for (var k = 0; k < row.length; k++) {

                            if (row[k] != null) {

                                row[k] = row[k].toString();
                            }
                        }

                        $scope.dataTable.push(row);

                        //Push ably response row into results for filtered chart and region filter
                        result.data_table.push(row);
                        $scope.originalResults.data_table.push(row);

                        $scope.$apply();
                    });

                    //Add dataTable to scoped question for later use
                    $scope.question.dataTable = $scope.dataTable;
                    $scope.question.rawDataTable = result.data_table;
                });
            };

            function AnswersController($scope, $mdDialog, question, dataTable, determineSentimentColour) {

                $scope.ctrl = {
                    "searchTerm": ""
                };

                $scope.answers = dataTable;
                $scope.question = question;
                $scope.determineSC = determineSentimentColour;

                $scope.validateSearch = function(commentText) {

                    return (commentText.indexOf($scope.ctrl.searchTerm) !== -1);
                };

                $scope.closeDialog = function () {

                    $mdDialog.hide();
                }
            }

            $scope.showAnswerModal = function (ev) {

                AnswersController.$inject = ['$scope', '$mdDialog', 'question', 'dataTable', 'determineSentimentColour'];

                $mdDialog.show({
                    controller: AnswersController,
                    templateUrl: 'app/views/elements/includes/answers.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    targetEvent: ev,
                    locals: {
                        question: $scope.question,
                        dataTable: $scope.dataTable,
                        determineSentimentColour: $scope.determineSentimentColour
                    }
                });
            };

            function ReportCommenController($scope, $mdDialog, dataTable, determineSentimentColour) {

                $scope.answers = dataTable;
                $scope.determineSC = determineSentimentColour;

                $scope.closeDialog = function () {

                    $mdDialog.hide();
                }
            }

            $scope.reportCommentModal = function (ev) {

                ReportCommenController.$inject = ['$scope', '$mdDialog', 'dataTable', 'determineSentimentColour'];

                $mdDialog.show({
                    controller: ReportCommenController,
                    templateUrl: 'app/views/elements/includes/report-comment.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    targetEvent: ev,
                    locals: {
                        dataTable: $scope.dataTable,
                        determineSentimentColour: $scope.determineSentimentColour
                    }
                });
            };

            //Extract Raw Data CSV
            $scope.extractData = function ($event) {

                if (FeatureService.allowed(CONSTANTS.FEATURES.SPREADSHEET_DATA_EXTRACTS, true)) {

                    if(typeof analytics !== "undefined" && typeof analytics.track !== "undefined") {

                        var user = DataService.grab('user');

                        if (typeof user.data.brand_name != "undefined") {

                            analytics.track('Extract Raw Data', {
                                name: user.data.brand_name.value
                            });
                        }
                    }

                    var questionsArray = [];
                    questionsArray.push($scope.question);

                    var pulseHeader = ExportService.buildPulseHeader('csv', $scope.pulse);

                    var header = ExportService.buildHeader('csv', questionsArray, $scope.question.rawDataTable[0], false, pulseHeader);

                    var dataObjArray = {};
                    dataObjArray = ExportService.addQuestion('csv', $scope.question, dataObjArray, $scope.allRegions);

                    var formattedDataTable = ExportService.buildBody('csv', header, dataObjArray, questionsArray, false);

                    ExportService.saveFile(ExportService.encodeUri('csv', formattedDataTable), $scope.result.question.text + '_raw.csv');
                }
            };

            $scope.$on('event:region-filter', function (event, regions) {

                var selectedRegionIds = [];

                for (var j = 0; j < regions.options.length; j++) {

                    if (regions.options[j].option.filterBy) {

                        selectedRegionIds.push(parseInt(regions.options[j].option.id));
                    }
                }

                if ($scope.originalResults.data_table.length > 1) {

                    var newDataTable = [];

                    newDataTable.push($scope.originalResults.data_table[0]);

                    for (var i = 1; i < $scope.originalResults.data_table.length; i++) {

                        if (selectedRegionIds.indexOf(parseInt($scope.originalResults.data_table[i][12])) != -1) {

                            newDataTable.push($scope.originalResults.data_table[i]);
                        }
                    }

                    $scope.question.dataTable = newDataTable;
                    $scope.question.rawDataTable = newDataTable;

                    $scope.dataTable = newDataTable;

                    if (typeof $scope.currentFilterCategory !== "undefined" && $scope.currentFilterCategory != null) {

                        $scope.$broadcast('event:filter-switch', $scope.currentFilterCategory);
                    }
                }

            });

            $scope.$on('event:filter-off', function (event) {

                $scope.switch.filters = {};

                $scope.chartFilters = [];

                $scope.question.dataTable = $scope.originalResults.data_table;
                $scope.question.rawDataTable = $scope.originalResults.data_table;

                $scope.dataTable = $scope.originalResults.data_table;
            });

            $scope.$on('event:filter-switch', function (event, filterCategory) {

                //Get the colIndex by searching the header array
                var colIndex = $scope.originalResults.data_table[0].indexOf(filterCategory.text);

                if (colIndex > -1) {

                    var values = [];
                    var colors = [];

                    for (var k = 0; k < filterCategory.values.options.length; k++) {

                        if (filterCategory.values.options[k].option.showFilter && filterCategory.values.options[k].option.selected) {

                            values.push(filterCategory.values.options[k].option.text);
                            colors.push(filterCategory.values.options[k].option.color);
                        }
                    }
                }

                //If filters have been set
                if (values.length > 0) {

                    var filteredDataArray = [];

                    filteredDataArray.push($scope.originalResults.data_table[0]);

                    for (var l = 1; l < $scope.originalResults.data_table.length; l++) {

                        for (var m = 0; m < values.length; m++) {

                            var valueArray = [];

                            if ($scope.originalResults.data_table[l][colIndex].indexOf(", ") != -1) {

                                valueArray = $scope.originalResults.data_table[l][colIndex].split(", ");
                            } else {

                                valueArray.push($scope.originalResults.data_table[l][colIndex]);
                            }

                            for (var n = 0; n < valueArray.length; n++) {

                                if (valueArray[n].trim() == values[m]) {

                                    filteredDataArray.push($scope.originalResults.data_table[l]);
                                }
                            }
                        }
                    }
                }

                $scope.question.dataTable = filteredDataArray;
                $scope.question.rawDataTable = filteredDataArray;
                $scope.dataTable = filteredDataArray;
            });
        }
    };

    return {
        restrict: 'E',
        priority: 0,
        replace: false,
        scope: false,
        link:linkFunction,
        templateUrl:'/app/views/elements/questionSentimentResults.html'
    }
}]);