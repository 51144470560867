onePulse.factory('ESQueryService', ['DataService', '$q',
    function(DataService, $q){

        //build query
        var buildQuery = function(searchObj) {

            var query = "";

            if(searchObj.search !== "" && searchObj.type !== "") {

                var tempSearchString = angular.copy(searchObj.search);
                var tempSearchStringArray = [];
                var searchString = "";
                var type = (searchObj.type === "tags" ? searchObj.type : "pulse." + searchObj.type);

                if(tempSearchString.indexOf(" ") !== -1) {

                    tempSearchStringArray = tempSearchString.split(" ");
                }

                if(tempSearchStringArray.length > 1) {

                    for(var i = 0; i < tempSearchStringArray.length; i++) {

                        if(i === 0) {

                            searchString = type + ":(*" + tempSearchStringArray[i] + "*)";
                        } else {

                            searchString = searchString.concat(type + ":(*" + tempSearchStringArray[i] + "*)");
                        }

                        if(i < tempSearchStringArray.length - 1) {

                            searchString = searchString.concat(" AND ");
                        }
                    }
                } else {

                    searchString = type + ":(*" + searchObj.search + "*)";
                }

                if(searchObj.type === "full_text") {

                    //Not pretty but a last minute addition of tags to the 'All' option of the search context
                    //TODO: refactor searchObj.type as array of types
                    query = query.concat("(" + searchObj.type + ":" + searchObj.search + "* OR tags:\"" + searchObj.search + "\")");
                } else {

                    query = query.concat(searchString);
                }
            }

            if(searchObj.search !== "" && searchObj.status !== "") {

                query = query.concat(" AND ");
            }

            if(searchObj.status !== "") {

                query = query.concat("status:(" + searchObj.status + ")");
            }

            query = query.concat(" AND !_exists_:pulse.deleted_at");

            return query;
        };

        //Submit query to Query endpoint - types currently supported: 'pulses'
        var submitQuery = function(type, query, page, sort, isStore, isAuth, isSimplified) {

            var deferred = $q.defer();

            page = (typeof page === "undefined") ? null : page;
            isStore = (typeof isStore === "undefined") ? false : isStore;
            isSimplified = (typeof isSimplified === "undefined") ? false : isSimplified;

            DataService.submitESQuery(type, query, page, sort, isStore, isAuth, isSimplified).then(function(result) {

                deferred.resolve(result);
            });

            return deferred.promise;
        };

        return {
            buildQuery: buildQuery,
            submitQuery: submitQuery
        }
    }]);