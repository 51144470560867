/**
 * Created by Barry on 21/09/2017.
 *
 * Directive for displaying the White Label client Login form
 */
onePulse.directive('whitelabelLogin', ['$rootScope', function($rootScope) {

        linkFunction = function($scope, element, attributes) {

        };

        return {
            restrict: 'E',
            priority: 0,
            replace: false,
            scope: false,
            link:linkFunction,
            templateUrl:'/app/views/elements/logins/whitelabelLogin.html'
        }
    }]);
