/**
 * Created by Barry on 2017/03/27.
 */

onePulse.directive('ngRect', [function () {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: {
            rectXAxis: '=',
            rectYAxis: '=',
            rectHeight: '=',
            rectWidth: '=',
            rectFill: '='
        },
        templateUrl: 'app/views/elements/svg/rect.html',
        templateNamespace: 'svg'
    };
}]);
