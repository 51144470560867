/**
 * Created by mike on 02/02/16.
 */
onePulse.directive('pulseTranslationForm', ['FormService', function(FormService) {

    var $service = FormService;

    linkFunction = function($scope,element,attributes) {

        /*
         Put the question data through the FormService and get all the rules and fields
         */

    }

    return {
        link:linkFunction,
        templateUrl:'/app/views/elements/forms/pulse-translation.html'
    }
}]);
