/**
 * Created by Barry on 29/05/18.
 */
onePulse.factory('WorkspaceService', ['$rootScope', '$translate', '$window', '$q', 'DataService', 'TokenService', 'LoginService',
    'AlertService', 'Config', '$mdDialog', '$location',
    function($rootScope, $translate, $window, $q, DataService, TokenService, LoginService,
             AlertService, Config, $mdDialog, $location){

        var workspaceExists = function(brandName, currentWorkspace, otherWorkspaces) {

            if(currentWorkspace.brand_name.value == brandName) {

                return true;
            }

            for(var i = 0; i < otherWorkspaces.length; i++) {

                if (otherWorkspaces[i].brand_name.value == brandName) {

                    return true;
                }
            }

            return false;
        };

    return {

        createWorkspace: function($event, user, currentWorkspace, otherWorkspaces) {

            var deferred = $q.defer();

            function createWorkspaceModalController(
                $scope, $mdDialog, $translate, $location, $window, Config, AlertService, DataService, user, currentWorkspace, otherWorkspaces
            ) {

                $scope.submitSpinner = false;

                $scope.newWorkspace = {
                    "data": {
                        "brand_name": ""
                    },
                    "data_token": ""
                };

                $scope.user = user;

                $scope.submit = function() {

                    if($scope.newWorkspace.data.brand_name == "") {

                        var translations;
                        $translate(['alert.required_field_missing.title', 'alert.required_field_missing.text']).then(function(result) {
                            translations = result;

                            AlertService.warning(
                                translations['alert.required_field_missing.title'],
                                translations['alert.required_field_missing.text']
                            )
                        });
                    } else if(workspaceExists($scope.newWorkspace.data.brand_name, currentWorkspace, otherWorkspaces)) {

                        $translate(['alert.workspace_exists.title', 'alert.workspace_exists.text']).then(function (result) {
                            translations = result;

                            AlertService.warning(
                                translations['alert.workspace_exists.title'],
                                translations['alert.workspace_exists.text']
                            )
                        });
                    } else {

                        $scope.submitSpinner = true;

                        Config.countryCheck(true, false).then(function(response) {

                            var countryCode = typeof response !== "undefined" ? (typeof response.country !== "undefined" ? response.country : null) : null;

                            //Pass in referral code and country code
                            var data = {
                                "country_code": countryCode
                            };

                            DataService.workspace("create", data).then(function (result) {

                                result.data.brand_name.value = $scope.newWorkspace.data.brand_name;

                                DataService.submit('client', result).then(function (result2) {

                                    $scope.user = result2.user;

                                    DataService.fetchItem('user').then(function (result3) {

                                        $scope.user = result3.user;

                                        $scope.submitSpinner = false;

                                        var translations;
                                        $translate(["alert.workspace_created.title", "alert.workspace_created.text"]).then(function (result) {
                                            translations = result;

                                            AlertService.success(
                                                translations["alert.workspace_created.title"],
                                                translations["alert.workspace_created.text"]
                                            );
                                        });

                                        deferred.resolve(JSON.stringify(response.data));

                                        $mdDialog.hide();

                                        $window.location = $location.path();
                                    });
                                });
                            });
                        });
                    }
                };

                $scope.closeDialog = function () {

                    $mdDialog.hide();
                }
            }

            createWorkspaceModalController.$inject = [
                "$scope", "$mdDialog", "$translate", "$location", "$window", "Config", "AlertService", "DataService", "user", "currentWorkspace", "otherWorkspaces"
            ];

            $mdDialog.show({
                templateUrl: "app/views/elements/includes/create-workspace.html",
                controller: createWorkspaceModalController,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                targetEvent: $event,
                locals: {
                    Config: Config,
                    AlertService: AlertService,
                    DataService: DataService,
                    user: user,
                    currentWorkspace: currentWorkspace,
                    otherWorkspaces: otherWorkspaces
                }
            });

            return deferred.promise;
        },

        switchWorkspace: function(code) {

            var translations;
            $translate(["alert.switch_workspace.title", "alert.switch_workspace.text"]).then(function (result) {
                translations = result;

                AlertService.confirm(
                    translations["alert.switch_workspace.title"],
                    translations["alert.switch_workspace.text"],
                    function () {

                        var data = {
                            "code": code
                        };

                        $rootScope.renderComplete = false;
                        $rootScope.loadingStateText = "Switching Workspace";

                        DataService.workspace("select", data).then(function(response) {

                            $rootScope.renderComplete = true;
                            $rootScope.loadingStateText = "";

                            TokenService.setAccessToken = response.access_token;

                            LoginService.processLogin(response);

                            $window.location = '/dashboard';
                        })
                    },
                    "Confirm"
                )
            });
        }
    }
}]);
