onePulse.controller('InboxController', ['$scope', '$window', 'Config', 'DataService', '$timeout', '$routeParams', '$rootScope', '$sce',
    function ($scope, $window, Config, DataService, $timeout, $routeParams, $rootScope, $sce) {

    $scope.selectedMessage = null;
    $scope.searchString = "";

    $scope.actionButtonsDisplayed = false;

    //Default list/select/order state - drop-downs
    $scope.ctrl = {
        "listState"         : "All",
        "selectBy"          : "None",
        "orderBy"           : "Date",
        "orderByDirection"  : "desc"
    };

    //If coming from header message
    $scope.messageId = $routeParams.id;

    //Format message date
    $scope.messageDateTime = function(dateTime) {

        return prettyDate(new Date(dateTime));
    };

    //Set selected message to display in main panel
    $scope.setSelectedMessage = function(message) {

        $timeout(function() {

            if(!message.data.is_read.value) {

                message.data.is_read.value = true;

                DataService.submit('user/inbox', message).then(function(result) {

                    $scope.selectedMessage = result.inbox;

                    if(typeof $scope.selectedMessage.data.body.value == "string") {

                        $scope.selectedMessage.data.body.value = $sce.trustAsHtml($scope.selectedMessage.data.body.value);
                    }
                })
            }

            var hasUnread = false;

            for(var i = 0; i < $scope.messages.length; i++) {

                if(!$scope.messages[i].data.is_read.value) {

                    hasUnread = true;
                }
            }

            $rootScope.hasUnreadMessages = hasUnread;

            $scope.selectedMessage = message;

            if(typeof message.data.body.value == "string") {

                $scope.selectedMessage.data.body.value = $sce.trustAsHtml(message.data.body.value);
            }
        })
    };

    //Check if any messages are selected and display/hide action buttons accordingly
    $scope.checkSelected = function() {

        $scope.actionButtonsDisplayed = false;

        for(var i = 0; i < $scope.messages.length; i++) {

            if($scope.messages[i].data.selected.value) {

                $scope.actionButtonsDisplayed = true;
            }
        }
    };

    //Filter messages by string
    $scope.filterMessages = function($event) {

        if($scope.searchString != "") {

            var filteredMessages = [];

            for(var i = 0; i < $scope.originalMessages.length; i++) {

                if($scope.originalMessages[i].data.body.value.indexOf($scope.searchString) != -1 ||
                    $scope.originalMessages[i].data.title.value.indexOf($scope.searchString) != -1) {

                    filteredMessages.push($scope.originalMessages[i])
                }
            }

            $timeout(function() {

                $scope.messages = filteredMessages;

            })
        } else {

            $scope.messages = angular.copy($scope.originalMessages);
        }
    };

    //Filter/select messages
    $scope.filterState = function(type, ctrl) {

        if($scope.ctrl[ctrl] != "All" && $scope.ctrl[ctrl] != "None") {

            var filteredMessages = [];

            var _loop = function(i, property, comparison) {

                if(type == 'select') {

                    $scope.messages[i].data.selected.value = false;
                }

                if($scope.originalMessages[i].data[property].value == comparison) {

                    switch(type) {

                        case 'filter':

                            filteredMessages.push($scope.originalMessages[i]);
                            break;
                        case 'select':

                            $scope.messages[i].data.selected.value = true;
                            break;
                    }
                }

                if(i == $scope.originalMessages.length - 1) {

                    $timeout(function() {

                        if(type != 'select') {

                            $scope.messages = filteredMessages;
                        }
                    })
                } else {

                    _loop(i + 1, property, comparison);
                }
            };

            switch($scope.ctrl[ctrl]) {

                case 'Read':

                    _loop(0, "is_read", true);
                    break;
                case 'Unread':

                    _loop(0, "is_read", false);
                    break;
                case 'UserStarred':

                    _loop(0, "is_pinned_by_user", true);
                    break;
                case 'AdminStarred':

                    _loop(0, "is_pinned_by_admin", true);
                    break;
                default:

                    filteredMessages = angular.copy($scope.originalMessages);
                    break;
            }
        } else {

            $scope.messages = angular.copy($scope.originalMessages);

            if(type == 'select') {

                for(var i = 0; i < $scope.messages.length; i++) {

                    $scope.messages[i].data.selected.value = ($scope.ctrl[ctrl] == 'All');
                }
            }
        }
    };

    //Perform action on selected messages
    $scope.listAction = function(action) {

        $rootScope.fadedOverlay = true;
        $rootScope.loadingStateText = "Performing Action";

        var _loop = function _loop(i, property, setTo) {

            if ($scope.messages[i].data.selected.value) {

                //Check that the selected message is not pinned by admin if this is a delete update - We do not allow deletion in this case
                if(!(action == 'Delete' && $scope.messages[i].data.is_pinned_by_admin.value)) {

                    $scope.messages[i].data[property].value = setTo;
                }

                DataService.submit('user/inbox', $scope.messages[i]).then(function (result) {

                    if(i == $scope.messages.length - 1) {

                        init(true);
                    } else {

                        _loop(i + 1, property, setTo);
                    }
                });
            } else {

                if(i == $scope.messages.length - 1) {

                    init(true);
                } else {

                    _loop(i + 1, property, setTo);
                }
            }
        };

        switch(action) {

            case "MarkRead":

                _loop(0, 'is_read', true);
                break;

            case "Delete":

                _loop(0, 'is_deleted', true);
                break;

            default:
                break;
        }

    };

    //Order inbox by property
    $scope.orderBy = function() {

        switch($scope.ctrl.orderBy) {

            case 'Date':

                $scope.messages = sortByProperty($scope.messages, 'created_at', $scope.ctrl.orderByDirection);
                break;
            case 'Starred':

                $scope.messages = sortByProperty($scope.messages, 'is_pinned_by_user', $scope.ctrl.orderByDirection);
                $scope.messages = sortByProperty($scope.messages, 'is_pinned_by_admin', $scope.ctrl.orderByDirection);
                break;
            case 'Type':

                $scope.messages = sortByProperty($scope.messages, 'type', $scope.ctrl.orderByDirection);
                break;
            case 'Status':

                $scope.messages = sortByProperty($scope.messages, 'is_read', $scope.ctrl.orderByDirection);
                break;
            case 'None':
                default:

                    $scope.messages = angular.copy($scope.originalMessages);
                break;
        }
    };

    //Toggle user pin
    $scope.userPinToggle = function(message) {

        message.data.is_pinned_by_user.value = !message.data.is_pinned_by_user.value;

        DataService.submit('user/inbox', message).then(function() {})
    };

    //Refresh Inbox
    $scope.refreshInbox = function() {

        $rootScope.fadedOverlay = true;
        $rootScope.loadingStateText = "Refreshing Inbox";

        init(false);
    };

    var init = function(initHeader) {

        $scope.selectedMessage = null;

        if($window.localStorage.access_token && $window.localStorage['access_token'] !== Config.get('OP_APPLICATION_TOKEN')) {

            DataService.fetchItem('user/inbox').then(function (result) {

                $rootScope.fadedOverlay = false;
                $rootScope.loadingStateText = "";

                $scope.messages = result.inboxes;

                var hasUnread = false;

                for (var i = 0; i < $scope.messages.length; i++) {

                    //Set new selected property on message to handle actions
                    $scope.messages[i].data.selected = {"value": false};

                    //If messageId is set (linked from header inbox) we open the selected message else we display the first message
                    if (typeof $scope.messageId != "undefined" && $scope.messageId != null) {

                        if ($scope.messages[i].data.id.value == $scope.messageId) {

                            $scope.setSelectedMessage($scope.messages[i]);
                        }
                    } else {

                        $scope.setSelectedMessage($scope.messages[0]);
                    }

                    if (!$scope.messages[i].data.is_read.value) {

                        hasUnread = true;
                    }
                }

                $rootScope.hasUnreadMessages = hasUnread;

                $scope.originalMessages = angular.copy($scope.messages);

                $scope.orderBy();

                if (initHeader) {

                    $rootScope.$broadcast('event:inbox-update');
                }
            })
        }
    };

    init(false);
}]);
