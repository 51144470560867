/**
 * Created by Barry on 2018/04/20.
 *
 * Directive to handle behaviour of workspace menu
 */

onePulse.directive('workspaceMenu', ['WorkspaceService',
    function(WorkspaceService) {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: false,
        templateUrl:'/app/views/elements/navigation/workspaceMenu.html',

        link: function postLink($scope, element, iAttrs) {

            $scope.currentWorkspace = null;
            $scope.otherWorkspaces = [];

            var init = function() {

                for(var i = 0; i < $scope.user.data.workspaces.length; i++) {

                    if($scope.user.data.workspaces[i].code.value === $scope.user.data.client_code.value) {

                        $scope.currentWorkspace = $scope.user.data.workspaces[i];
                    } else {

                        $scope.otherWorkspaces.push($scope.user.data.workspaces[i]);
                    }
                }
            };

            init();

            $scope.createWorkspace = function($event) {

                WorkspaceService.createWorkspace($event, $scope.user, $scope.currentWorkspace, $scope.otherWorkspaces).then(function() {

                    init();
                });
            };

            $scope.switchWorkspace = function(code) {

                WorkspaceService.switchWorkspace(code);
            };
        }
    };
}]);