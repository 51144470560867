/**
 * Created by mike on 23/01/16.
 */
angular.module('loaderMod', [])

    .config(['$httpProvider', function($httpProvider) {
        $httpProvider.interceptors.push('loadingStatusInterceptor');
    }])

    .directive('loader', function () {

        return {
            link: function ($scope, $element, $attrs) {


                $scope.isLoading = false;

                $scope.$on('event:api-request-'+$attrs.endpoint, function () {
                    //console.log('heard - event:api-request-'+$attrs.endpoint);
                    // got the request end notification, hide the element
                    $scope.isLoading = true;
                });

                $scope.$on('event:api-response-'+$attrs.endpoint, function () {
                    //console.log('heard - event:api-response-'+$attrs.endpoint);
                    // got the request end notification, hide the element
                    $scope.isLoading = false;
                });
            },
            templateUrl: 'app/views/elements/loader.html'
        };
    })

    .factory('loadingStatusInterceptor', ['$q', '$rootScope', '$timeout', function($q, $rootScope, $timeout) {

        var started = function(endpoint) {
            $timeout(function() {
                $rootScope.$broadcast('event:api-request-' + endpoint);
            },20);

        };
        var ended = function(endpoint) {

            $rootScope.$broadcast('event:api-response-'+endpoint);

        };
        return {
            request: function(config) {

                var endpoint = parseEndPoint(config.url);

                started(endpoint);

                return config || $q.when(config);
            },
            response: function(response) {

                var endpoint = parseEndPoint(response.config.url);

                ended(endpoint);

                return response || $q.when(response);
            },
            responseError: function(rejection) {

                var endpoint = parseEndPoint(rejection.config.url);

                ended(endpoint);

                return $q.reject(rejection);
            }
        };
    }]);
