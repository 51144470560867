/**
 * Created by fausto on 24/10/18.
 */

onePulse.directive('workspace.customization', ['$rootScope', '$window', '$timeout', '$translate', 'DataService', 'CONSTANTS', 'AlertService',
    function($rootScope, $window, $timeout, $translate, DataService, CONSTANTS, AlertService) {

    linkFunction = function($scope, element, attributes){

        var translations;

        $translate(["alert.wl_login_pixel_size.title", "alert.wl_login_pixel_size.text",
            "alert.wl_bar_pixel_size.title", "alert.wl_bar_pixel_size.text",
            "alert.wl_login_pixel_size_empty.title", "alert.wl_login_pixel_size_empty.text",
            "alert.wl_bar_pixel_size_empty.title", "alert.wl_bar_pixel_size_empty.text"])
            .then(function (result) {
                translations = result;
            });

        $scope.wlForm = {"sub_domain": ""};
        $scope.wlActive = false;
        $scope.submitSpinner = false;
        $scope.domainReady = false;
        $scope.isInit = false;

        $scope.save = function() {

            if($scope.white_label.logo_image !== '' && parseInt($scope.white_label.logo_size_px) > CONSTANTS.MAX_WIDTH_WL_LOGIN_LOGO_PX) {

                AlertService.warning(
                    translations["alert.wl_login_pixel_size.title"],
                    translations["alert.wl_login_pixel_size.text"]
                );

            } else if($scope.white_label.logo_image !== '' && (parseInt($scope.white_label.logo_size_px) === 0 ||
                $scope.white_label.logo_size_px === '' ||
                $scope.white_label.logo_size_px === null)) {

                AlertService.warning(
                    translations["alert.wl_login_pixel_size_empty.title"],
                    translations["alert.wl_login_pixel_size_empty.text"]
                );
            } else {

                $scope.submitSpinner = true;
                $scope.user.data.white_label = $scope.white_label;

                DataService.submit('client/white_label', $scope.user, function() {

                    $scope.submitSpinner = false;
                }).then(function (result) {

                    $scope.user = result.user;
                    $scope.submitSpinner = false;

                    $timeout(function() {

                        init();
                    })
                });
            }
        };

        $scope.enable = function() {

            $scope.submitSpinner = true;

            var data = {
                "sub_domain": $scope.wlForm.sub_domain
            };

            DataService.create('client/white_label', data, function() {

                $scope.submitSpinner = false;
            }).then(function (result) {

                $scope.user.data.white_label = result;

                $timeout(function() {

                    init();
                })
            });
        };

        //Update whitelabel when image is changed
        var imageSavedListener = $rootScope.$on('event:whitelabel-image-saved', function() {

            $scope.save();

            // Remove listener
            imageSavedListener();
        });

        var init = function() {

            DataService.fetchItem('user').then(function(result) {

                $scope.white_label = result.user.data.white_label;
                $scope.domainReady = false;

                if ($scope.white_label !== null) {

                    var checkDomainReady = function () {

                        var wlCreatedAt = new Date($scope.white_label.created_at.value);
                        var minsAhead = new Date(wlCreatedAt.getTime() + (5 * 60000));

                        if (minsAhead <= new Date()) {

                            $scope.domainReady = true;
                        }
                    };

                    checkDomainReady();

                    if (!$scope.domainReady) {

                        $timeout(function () {

                            checkDomainReady();
                        }, 300000)
                    }
                }

                $scope.isInit = true;
            });
        };

        init();
    };

    return {
        templateUrl: '/app/views/elements/forms/workspace/customization.html',
        link:linkFunction
    }
}]);
