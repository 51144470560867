/**
 * Created by Barry on 2018/10/29.
 *
 */

onePulse.directive('upsellCreditsDash', ['DataService', '$rootScope',
    function (DataService, $rootScope) {

        return {
            restrict: 'E',
            priority: 0,
            replace: true,
            scope: false,
            templateUrl:'/app/views/elements/dashboard/upsellCredits.html',

            link: function postLink(scope, element, iAttrs) {

                scope.topups = [];

                var initTopups = function() {

                    DataService.fetchMetaData("topups").then(function(result) {

                        var tempTopups = result.topups;
                        var cleanedTopups = [];

                        for (var i = 0; i < tempTopups.length; i++) {

                            if (typeof tempTopups[i].data.is_active.value !== "undefined") {

                                if (tempTopups[i].data.is_active.value == '1' && tempTopups[i].data.display_order.value != '') {

                                    tempTopups[i].data.display_price.value = parseFloat(tempTopups[i].data.display_price.value).toFixed(0);
                                    tempTopups[i].data.cost.value = parseFloat(tempTopups[i].data.cost.value).toFixed(0);

                                    cleanedTopups.push(tempTopups[i])
                                }
                            }
                        }

                        cleanedTopups.sort(function (a, b) {

                            return parseInt(a.data.display_order.value) - parseInt(b.data.display_order.value);
                        });

                        scope.topups = cleanedTopups;

                        $rootScope.showStepLoadingSpinner = false;
                    });
                };

                initTopups();
            }
        };
    }]);