/*
 * Created by Barry
 *
 * Controller for Client OnBoarding (Pulse Lite)
 *
 * Handles new client enrolment and pulse lite creation, updating and publishing. Also displays real-time pulse lite results
 */
onePulse.controller('ClientOnBoardingController', ['$rootScope', '$scope', 'DataService', 'LogoutService', 'LoginService', '$window', 'AblyService', '$translate',
    '$mdDialog', '$location', '$interval', '$timeout', 'AlertService', 'SearchService', 'CONSTANTS', 'vcRecaptchaService',
    function ($rootScope, $scope, DataService, LogoutService, LoginService, $window, AblyService, $translate,
              $mdDialog, $location, $interval, $timeout, AlertService, SearchService, CONSTANTS, vcRecaptchaService) {

        //Delay to cycle section titles
        var titleCycleDelay = 8000;

        //Delay between text for real-time pulsing stages
        var pulsingStageTextDelay = 3000;

        $scope.user = {};
        $scope.lite = {};
        $scope.validAnswerCount = 0;
        $scope.pulsing = false;
        $scope.currentPulsingStage = 0;

        $scope.password = null;
        $scope.password_confirmation = null;
        $scope.ablyChannel = null;

        //Current step
        $scope.currentStep = 1;

        //Current titles
        $scope.currentQuestionTitle = 1;
        $scope.currentAnswerTitle = 1;
        $scope.currentEmailTitle = 1;

        //Display form errors
        $scope.errorQuestionTitle = false;
        $scope.errorAnswer1 = false;
        $scope.errorAnswer2 = false;
        $scope.errorAnswer3 = false;
        $scope.errorEmail = false;
        $scope.errorContactName = false;
        $scope.errorPassword = false;
        $scope.errorPasswordConfirm = false;
        $scope.errorPasswordMatch = false;
        $scope.errorBrandName = false;

        //Button state (Prevent double push)
        $scope.askNowButtonClicked = false;
        $scope.signupNowButtonClicked = false;
        
        $scope.onBoardingNotificationIcon = 'mdi mdi-format-list-checks';
        $scope.onBoardingNotificationTitle = 'Exclusive Answer';
        $scope.onBoardingNotificationLabel = 'Restricted to 2 for live demo';
        $scope.onBoardingNotificationText = 'Ask people to choose from one of 12 answers.';

        $scope.GOOGLE_RECAPTCHA_SITE_KEY = CONSTANTS.GOOGLE_RECAPTCHA_SITE_KEY;

             // Slick On Boarding
             $scope.slickOnBoarding = {
                enabled: true,
                autoplay: true,
                draggable: false,
                arrows: false,
                dots:true,
                fade:true,
                adaptiveHeight: false,
                autoplaySpeed: 15000,
                slidesToShow: 1,
                slidesToScroll: 1,
                pauseOnFocus: false,
                pauseOnHover: false,
                method: {},
                event: {
                    beforeChange: function (event, slick, currentSlide, nextSlide) {
                    },
                    afterChange: function (event, slick, currentSlide, nextSlide) {
                    }
                }
            };

        $timeout(function() {
            $window.dispatchEvent(new Event("resize"));
        }, 200);
            
        var translations;

        $translate(['alert.captcha_error.title', 'alert.captcha_error.text']).then(function(result){
            translations = result;
        });

        //Give first step input focus after a second
        $timeout(function() {

            angular.element('.focus_step_' + $scope.currentStep).focus();
        }, 1000);

        $scope.setResponse = function (response) {

            if(!$scope.askNowButtonClicked && !$scope.validateStep(3)) {

                $scope.rcResponse = response;
                $scope.publishPulse(3);
            }
        };

        $scope.setWidgetId = function (widgetId) {
            $scope.widgetId = widgetId;
        };

        $scope.cbExpiration = function() {
            vcRecaptchaService.reload($scope.widgetId);
            $scope.rcResponse = null;
        };

        //Begin the Pulse Lite publication process
        $scope.publishPulse = function(step) {

            if($scope.rcResponse === "") {

                AlertService.warning(
                    translations['alert.captcha_error.title'],
                    translations['alert.captcha_error.text']
                );
            } else {

                $scope.askNowButtonClicked = true;
                $rootScope.renderComplete = false;
                $mdDialog.hide();

                //Validate client's email address - Only process further if it does not exist
                DataService.validateClient({
                    "email": $scope.user.data.email.value,
                    "brand_name": $scope.user.data.contact_name.value,
                    'g-recaptcha-response': $scope.rcResponse
                }, function() {

                    $scope.askNowButtonClicked = false;
                    $scope.cbExpiration();
                }).then(function () {

                    //Call the enrol API endpoint for an access token with pulse lite creation permissions
                    DataService.enrol().then(function (response) {

                        response.user.data.email.value = $scope.user.data.email.value;
                        response.user.data.contact_name.value = $scope.user.data.contact_name.value;
                        response.user.data.display_name.value = $scope.user.data.contact_name.value;
                        response.user.data.brand_name.value = $scope.user.data.brand_name.value;
                        $scope.user = response.user;

                        //Create a new Pulse Lite
                        DataService.create('lite').then(function (response) {

                            response.lite.data.questions[0].data.text.value = $scope.lite.data.questions[0].data.text.value;

                            //Update Pulse Lite
                            DataService.submit('lite', response.lite).then(function (response) {

                                response.lite.data.questions[0].data.answers[0].data.text.value = $scope.lite.data.questions[0].data.answers[0].data.text.value;
                                response.lite.data.questions[0].data.answers[1].data.text.value = $scope.lite.data.questions[0].data.answers[1].data.text.value;

                                //Update Pulse Lite
                                DataService.submit('lite', response.lite).then(function (response) {

                                    $scope.lite = response.lite;
                                    $scope.validAnswerCount = response.lite.data.questions[0].data.answers.length;

                                    //Update user
                                    DataService.submit('user', $scope.user, function () {

                                        $scope.askNowButtonClicked = false;
                                        $scope.cbExpiration();
                                    }).then(function (response) {

                                        $scope.user = response.user;

                                        //Publish Pulse Lite
                                        DataService.publish('lite', $scope.lite.data_token).then(function (response) {

                                            $scope.lite = response.lite;

                                            //Update Client User password
                                            DataService.updatePassword($scope.password, $scope.password).then(function () {

                                                //Publish Client User
                                                DataService.publishClient().then(function () {

                                                    $timeout(function() {

                                                        //Close the modal
                                                        $mdDialog.hide();

                                                        //Reset state for final Sign Up Now button
                                                        $scope.signupNowButtonClicked = false;

                                                        //Set state to logged in and redirect to the dashboard
                                                        $scope.isLoggedIn = true;

                                                        $window.location = '/dashboard/' + $scope.lite.data.id.value;
                                                    }, 5000)
                                                });
                                            });
                                        })
                                    });
                                })
                            });
                        })
                    });
                });
            }
        };

        //Go to particular step of Pulse Lite process
        $scope.goToStep = function(step) {

            var selector = angular.element('.board-step-' + step);

            //If we are moving forward in the process
            if ($scope.currentStep < step) {

                //Only apply validation rules if moving forward
                if(!$scope.validateStep(step - 1)) {

                    //Fade in/out transitions based on our direction in the process
                    selector.removeClass('fade-outup-indown');
                    selector.addClass('fade-outdown-inup');

                    $scope.currentStep = step;

                    //Apply focus to input based on step
                    angular.element('.focus_step_' + $scope.currentStep).focus();

                }
            } else {

                selector.removeClass('fade-outdown-inup');
                selector.addClass('fade-outup-indown');

                $scope.currentStep = step;

                //Apply focus to input based on step
                angular.element('.focus_step_' + $scope.currentStep).focus();
            }
        };

        //Close modal
        $scope.exit = function() {

            $window.localStorage.clear();
            $mdDialog.hide();
        };

        //Redirect to full registration
        $scope.redirectRegistration = function() {

            $mdDialog.hide();
            $location.path('/registration');
        };

        //Clear all form field errors
        $scope.clearErrors = function() {

            $scope.errorQuestionTitle =
            $scope.errorAnswer1 =
            $scope.errorAnswer2 =
            $scope.errorAnswer3 =
            $scope.errorEmail =
            $scope.errorContactName =
            $scope.errorPassword =
            $scope.errorPasswordConfirm =
            $scope.errorPasswordMatch =
            $scope.errorBrandName = false;
        };

        //Validate each steps fields
        $scope.validateStep = function(step) {

            var stepError = false;

            switch(step) {

                case 1:
                    stepError = ($scope.errorQuestionTitle = angular.element('#step_1_question_title').val() == '') ||
                        ($scope.errorAnswer1 = angular.element('#step_2_answer_1').val() == '') ||
                        ($scope.errorAnswer2 = angular.element('#step_2_answer_2').val() == '') ||
                        ($scope.errorAnswer3 = (angular.element('#step_2_answer_2').val() == angular.element('#step_2_answer_1').val()) && angular.element('#step_2_answer_1').val() != '');
                    break;
                case 3:
                    stepError = ($scope.errorEmail = angular.element('#step_3_email').val() == '') ||
                        ($scope.errorContactName = angular.element('#step_3_contact_name').val() == '') ||
                        ($scope.errorPassword = angular.element('#step_5_password').val() == '') ||
                    // ($scope.errorPasswordConfirm = angular.element('#step_5_password_confirm').val() == '') ||
                    // ($scope.errorPasswordMatch = (angular.element('#step_5_password').val() != angular.element('#step_5_password_confirm').val()) && angular.element('#step_5_password').val() != '');
                        ($scope.errorBrandName = angular.element('#step_6_brand_name').val() == '');
                    break;
                // case 5:
                //     stepError = ($scope.errorPassword = angular.element('#step_5_password').val() == '') ||
                //     ($scope.errorPasswordConfirm = angular.element('#step_5_password_confirm').val() == '') ||
                //     ($scope.errorPasswordMatch = (angular.element('#step_5_password').val() != angular.element('#step_5_password_confirm').val()) && angular.element('#step_5_password').val() != '');
                //     break;
                // case 6:
                //     stepError = $scope.errorBrandName = angular.element('#step_6_brand_name').val() == '';
                //     break;
            }

            return stepError;
        };

        //View Pulse Lite - Real-time results
        var viewPulse = function() {

            var translations;
            $translate(['alert.pulse_completed.title', 'alert.pulse_completed.text',
                'alert.pulse_completed.confirm_button_text']).then(function(result) {
                translations = result;
            });

            $scope.questionBeingEdited = null;

            //If Pulse Lite is not in a closed state
            if ($scope.lite.data.status.value !== 'Closed') {

                $scope.pulsing = true;

                $interval(function() {

                    if($scope.currentPulsingStage < 2) {
                        $scope.currentPulsingStage++;
                    }
                }, pulsingStageTextDelay);

                //Reset Ask Now button state
                $scope.askNowButtonClicked = false;

                $scope.ablyChannel = AblyService.client().channels.get('pulse:data:' + $scope.lite.data.id.value);

                $scope.ablyChannel.presence.enter();

                //Subscribe to the pulse_completed channel
                $scope.ablyChannel.subscribe('pulse_completed', function($ablyMessage) {

                    $scope.currentPulsingStage = 3;

                    AlertService.info(
                        translations['alert.pulse_completed.title'],
                        translations['alert.pulse_completed.text']
                    );

                    $scope.lite.data.status.value = 'Closed';
                    $rootScope.$broadcast('event:pulse-update', $scope.lite);
                });

                $scope.lastAblyMessage = undefined;

                $scope.previousAblyMessage = undefined;

                //Subscribe to the user_responded channel
                $scope.ablyChannel.subscribe('user_responded',function($ablyMessage){

                    $scope.previousAblyMessage = $scope.lastAblyMessage;

                    $scope.lastAblyMessage = $ablyMessage;

                    $scope.$broadcast('event:user_responded',angular.fromJson($ablyMessage.data));
                });

            }

            $scope.showQuestion = function(){
                return true;
            };

            $scope.isItActive = function ($id) {
                return $id == $scope.isActive;
            };

            $scope.isQuestionVisible = function(question) {
                return (question.data.id.value == $scope.newQuestionId) && $scope.newQuestionFormVisible;
            };
        };

        //Leave pulse channel on location change.
        $scope.$on('$locationChangeStart', function (event, next, current) {

            if (typeof $scope.ablyChannel != "undefined" && $scope.ablyChannel != null) {

                $scope.ablyChannel.presence.leave();
                $scope.ablyChannel.detach();
                AblyService.disconnect();
            }
        });

        $scope.$on('$destroy', function() {

            if (typeof $scope.ablyChannel != "undefined" && $scope.ablyChannel != null) {

                $scope.ablyChannel.presence.leave();
                $scope.ablyChannel.detach();
                AblyService.disconnect();
            }

            if(!$scope.isLoggedIn) {

                $window.localStorage.clear();
            }
        });

        $timeout(function() {

            $scope.ready = true;
        }, 200);

        //Cycle through various section titles
        $interval(function() {

            switch($scope.currentStep) {

                case 1:
                    if($scope.currentQuestionTitle == 2) {
                        $scope.currentQuestionTitle = 1;
                    } else {
                        $scope.currentQuestionTitle++;
                    }
                    break;
                case 2:
                    if($scope.currentAnswerTitle == 3) {
                        $scope.currentAnswerTitle = 1;
                    } else {
                        $scope.currentAnswerTitle++;
                    }
                    break;
                case 3:
                    if($scope.currentEmailTitle == 2) {
                        $scope.currentEmailTitle = 1;
                    } else {
                        $scope.currentEmailTitle++;
                    }
                    break;
            }
        }, titleCycleDelay);
    }]);
