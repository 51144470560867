onePulse.controller('LoginController', ['$scope', '$window', '$location', 'LoginService', 'AuthService', '$translate', 'TokenService',
    '$routeParams', 'AlertService', 'CONSTANTS', '$mdDialog', 'Config', '$rootScope', 'DataService',
    function ($scope, $window, $location, LoginService, AuthService, $translate, TokenService,
              $routeParams, AlertService, CONSTANTS, $mdDialog, Config, $rootScope, DataService) {

        $scope.displayFooter = false;
        $rootScope.submitSpinner = false;

        $scope.selectedWorkspace = null;
        $scope.logStraightIn = false;

        $scope.token = $routeParams.token;
        $scope.code = $routeParams.code;

        $scope.display_name = "";
        $scope.password = "";
        $scope.confirmPassword = "";
        $scope.newPassword = "";

        $scope.passwordStrength = "";

        //Determine name and version of user's browser
        $scope.browser = determineBrowser();

        TokenService.resetAccessToken();

        var translations;

        $translate(['alert.enter_username.title', 'alert.enter_username.text',
            'alert.enter_password.title', 'alert.enter_password.text']).then(function(result){
            translations = result;
        });

        //Set env toggle to current env
        $scope.dataRadio = {
            domain : Config.get('ENV')
        };

        //Redirect to env domain if toggle value is changed
        $scope.setEnvDomain = function() {

            if($scope.dataRadio.domain == 'UK' || $scope.dataRadio.domain == 'US') {

                window.location.replace("https://" + $scope.dataRadio.domain.toLowerCase() + ".onepulse.com" + window.location.pathname);
            }
        };

        //If route is /forgot/mobile and there is no URL token, redirect to landing page
        if($window.location == "/forgot/mobile" && ($scope.token == null || $scope.token == "" || typeof $scope.token == "undefined")) {

            $window.location = '/';
        }

        $scope.doLogin = function (direct) {

            $rootScope.submitSpinner = true;

            var $data = {
                "username": $scope.username,
                "password": $scope.password,
                "code": $scope.code
            };

            if(typeof $scope.username === "undefined" || $scope.username === "" || $scope.username == null) {

                $rootScope.submitSpinner = false;

                AlertService.warning(
                    translations['alert.enter_username.title'],
                    translations['alert.enter_username.text']
                )
            } else if (typeof $scope.password === "undefined" || $scope.password === "" || $scope.password == null) {

                $rootScope.submitSpinner = false;

                AlertService.warning(
                    translations['alert.enter_password.title'],
                    translations['alert.enter_password.text']
                )
            } else {

                var completeLogin = function() {

                    $rootScope.login = {
                        "username": $data.username,
                        "password": $data.password,
                        "code": $data.code
                    };

                    //If WS code is already set, we can login directly without the need for the WS select overlay
                    if(typeof $data.code !== "undefined" && $data.code != null && $data.code != "") {

                        direct = true;
                    }

                    LoginService.login($data, direct, function() {

                        $rootScope.submitSpinner = false;

                    }).then(function () {

                        $scope.isLoggedIn = true;

                        $window.location = '/dashboard';
                    });
                };

                //If the browser is compatible we login else we display warning confirmation modal before login
                if(LoginService.browserCompatibility($scope.browser)) {

                    completeLogin();
                } else {

                    //Controller for browser warning modal
                    function browserWarningModalController($scope, $mdDialog) {

                        $scope.acceptDialog = function () {

                            $mdDialog.hide();
                            completeLogin();
                        };
                    }

                    browserWarningModalController.$inject = ['$scope', '$mdDialog'];

                    //Call the browser warning modal
                    $mdDialog.show({
                        templateUrl: 'app/views/elements/includes/browser-detection.html',
                        controller: browserWarningModalController,
                        parent: angular.element(document.body),
                        clickOutsideToClose: false
                    });
                }
            }
        };

        //Request a password reset token - submit username
        $scope.doResetPasswordRequest = function(type) {

            var data = {
                "username": $scope.username
            };

            LoginService.resetPasswordRequest(data, type).then(function () {

                var translations;
                $translate(['alert.password_change_request.title', 'alert.password_change_request.text']).then(function(result) {
                    translations = result;

                    AlertService.alert(
                        translations['alert.password_change_request.title'],
                        translations['alert.password_change_request.text'],
                        function(){

                            $window.location = '/login';
                        },
                        "Ok"
                    );
                });
            });
        };

        //Submit password reset token and new password
        $scope.doResetPassword = function() {

            var translations;

            if(scorePassword($scope.newPassword) < 50) {

                $scope.submitSpinner = false;

                $translate(['alert.password_weak_error.title', 'alert.password_weak_error.text']).then(function(result) {
                    translations = result;

                    AlertService.warning(
                        translations['alert.password_weak_error.title'],
                        translations['alert.password_weak_error.text']
                    );
                });
            } else if($scope.newPassword == "" || $scope.confirmPassword == "") {

                $scope.submitSpinner = false;

                $translate(['alert.required_field_missing.title', 'alert.required_field_missing.text']).then(function(result) {
                    translations = result;

                    AlertService.warning(
                        translations['alert.required_field_missing.title'],
                        translations['alert.required_field_missing.text']
                    );
                });
            } else if($scope.confirmPassword != $scope.newPassword) {

                $scope.submitSpinner = false;

                $translate(['alert.password_match_error.title', 'alert.password_match_error.text']).then(function(result) {
                    translations = result;

                    AlertService.warning(
                        translations['alert.password_match_error.title'],
                        translations['alert.password_match_error.text']
                    );
                });
            } else {

                var data = {
                    "password": $scope.newPassword
                };

                LoginService.resetPassword(data, $scope.token).then(function (api_result) {

                    $translate(['alert.password_change.title', 'alert.password_change.text']).then(function(result) {
                        translations = result;

                        AlertService.alert(
                            translations['alert.password_change.title'],
                            translations['alert.password_change.text'],
                            function() {

                                //Redirect user based on type
                                switch(api_result.type) {

                                    case 'client':
                                        $window.location = '/login';
                                        break;

                                    case 'mobile':

                                        var mobileOS = getMobileOperatingSystem();

                                        switch(mobileOS) {

                                            case 'Android':
                                                $window.location = CONSTANTS.ANDROID_APP;
                                                break;
                                            case 'iOS':
                                                $window.location = CONSTANTS.IOS_APP;
                                                break;
                                            default:
                                                $window.location = '/';
                                                break;
                                        }
                                        break;
                                }
                            },
                            "Ok"
                        );
                    });
                });
            }
        };

        $scope.analyzePassword = function() {

            $scope.passwordStrength = checkPassStrength($scope.newPassword);
        };

        //Submit password create token and new password
        $scope.doCreatePassword = function() {

            var translations;

            if($scope.password !== $scope.confirmPassword) {

                //Passwords do not match - show alert
                $translate(['alert.password_match_error.title', 'alert.password_match_error.text']).then(function(result) {
                    translations = result;

                    AlertService.warning(
                        translations['alert.password_match_error.title'],
                        translations['alert.password_match_error.text']
                    );
                });
            } else if($scope.display_name == "" && $scope.display_name != null) {

                //Passwords do not match - show alert
                $translate(['alert.missing_display_name.title', 'alert.missing_display_name.text']).then(function(result) {
                    translations = result;

                    AlertService.warning(
                        translations['alert.missing_display_name.title'],
                        translations['alert.missing_display_name.text']
                    );
                });
            } else {

                var data = {
                    "display_name": $scope.display_name,
                    "password": $scope.password,
                    "password_confirmation": $scope.confirmPassword
                };

                $rootScope.submitSpinner = true;

                LoginService.createPassword(data, $scope.token).then(function (response) {

                    $translate(['alert.user_create.title', 'alert.user_create.text']).then(function (result) {
                        translations = result;

                        AlertService.success(
                            translations['alert.user_create.title'],
                            translations['alert.user_create.text']
                        );
                    });

                    if($scope.logStraightIn) {

                        $scope.username = $scope.display_name;
                        $scope.code = response.workspace.code.value;

                        $scope.doLogin(true);
                    } else {

                        $window.location = '/login';
                    }
                });
            }
        };

        $scope.acceptInvitation = function() {

            $rootScope.submitSpinner = true;

            LoginService.acceptInvitation().then(function(response) {

                $translate(['alert.invitation_accepted.title', 'alert.invitation_accepted.text']).then(function (result) {
                    translations = result;

                    AlertService.success(
                        translations['alert.invitation_accepted.title'],
                        translations['alert.invitation_accepted.text']
                    );
                });

                $window.location = '/login';
            });
        };

        $scope.$on("$locationChangeStart", function(event, next, current) {

            $rootScope.displayWorkspaceLoginOverlay.open = false;
        });

        $scope.$watch(function () {
            return AuthService.isLoggedIn()
        }, function (newVal, oldVal) {

            if (oldVal !== newVal) {
                $scope.isLoggedIn = newVal;
            }
        })

    }]);