/**
 * Created by Barry on 2016/10/07
 *
 * Directive to add text limit label content based on field rules
 */

onePulse.directive('limitLabel', ['$timeout', function ($timeout) {

    return {
        restrict: 'A',
        priority: 0,
        replace: false,
        scope: true,
        link: function postLink(scope, element, iAttrs) {

            scope.displayLimit = false;
            scope.limit = 0;

            $timeout(function() {

                //Parse rules
                var rules = iAttrs.data.split('|');

                for (var i = 0; i < rules.length; i++) {

                    //Max Length
                    if (rules[i].indexOf('max') !== -1) {

                        //split attr
                        var attr = rules[i].split(':');

                        scope.limit = parseInt(attr[1]);

                        scope.displayLimit = true;
                    }
                }

            }, 5000); //Delay is needed as some elements get their value from an API call
        }
    };
}]);
