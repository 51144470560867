/**
 * Created by mike on 16/12/15.
 */
onePulse.controller('GroupController', ['$http', '$scope', '$routeParams', '$rootScope', 'SocialShareService',
	'$window', 'DataService', 'FormService', '$route', '$mdDialog', '$translate', 'AlertService',
	function ($http, $scope, $routeParams, $rootScope, SocialShareService,
			  $window, DataService, FormService, $route, $mdDialog, $translate, AlertService) {

	$scope.user = DataService.grab('user');

	$scope.panelShow = $rootScope.panelShow;

	$scope.edit = true;
	$scope.submitSpinner = false;

    $rootScope.imageSpinner = true;

	$scope.colors = ['#000000', '#37d7c3', '#673ab7', '#839BB4', '#80d8ff', '#42a5f5', '#01579b', '#ff4081', '#e91d63',
		'#880d4f', '#fed502', '#ffab00', '#ff5721', '#4caf50', '#338e23', '#01695c'];

	$scope.fileString = '';

	var setInitialSelectedGroupCategory = function() {

		$scope.selected = {"GroupCategory": {"id": null, "text": "Category"}};

		if(typeof $scope.group.data.categories != "undefined") {

			for(var i = 0; i < $scope.group.data.categories.options.length; i++) {

				if($scope.group.data.categories.options[i].option.selected) {

					$scope.selected.GroupCategory = $scope.group.data.categories.options[i].option;
				}
			}
		}
	};


	var setSelectedGroupCategory = function(group) {

		if((group.data.is_visible.value == "1" || group.data.is_visible.value == 1) && $scope.selected.GroupCategory.id != null) {

			if($scope.selected.GroupCategory.id != null) {

				if(typeof group.data.categories != "undefined") {

					for(var i = 0; i < group.data.categories.options.length; i++) {

						group.data.categories.options[i].option.selected = (group.data.categories.options[i].option.id == $scope.selected.GroupCategory.id);
					}
				}
			}
			
		}

		return group;
	};

	/*
	Callback function for the form submit
	 */
	$scope.submitCallback = function ($event, publish) {

		//If changes have been made, process the save, else simply close the modal
		if(!_.isEqual($scope.originalGroupState, $scope.group.data)) {

            var translations;

			if(typeof $scope.selected.GroupCategory.id === "undefined") {

				$scope.selected.GroupCategory = JSON.parse($scope.selected.GroupCategory);
			}

			$scope.group = setSelectedGroupCategory($scope.group);

			if(($scope.group.data.is_visible.value === "1" || $scope.group.data.is_visible.value === 1) && $scope.selected.GroupCategory.id == null) {

				$translate(['alert.visible_panel_category.title', 'alert.visible_panel_category.text']).then(function(result) {
					translations = result;

					AlertService.warning(
						translations['alert.visible_panel_category.title'],
						translations['alert.visible_panel_category.text']
					);
				});
				
			} else {
				$scope.submitSpinner = publish;
				
				

				var groups = DataService.grab('all');
				var groupNames = [];

				for (var index2 in groups) {

					groupNames.push(groups[index2].data.name.value);
				}

				if(publish) {

					if($scope.group.data.name.value !== "" &&
						$scope.group.data.description.value !== "" &&
                        $scope.group.data.code.value !== "") {

						if (publish && $scope.group.data.is_active.value != '1') {

							if(groupNames.indexOf($scope.group.data.name.value) == -1) {

								DataService.submit('group', $scope.group, function() {

									$scope.submitSpinner = false;

								}).then(function (result) {

									$rootScope.showGroupToken = null;

									DataService.publish('group', result.group.data_token).then(function (result) {

										$scope.submitSpinner = false;
										$scope.edit = false;
										$rootScope.creatingGroup = false;
										$mdDialog.hide();

										var translations;
										$translate(['alert.private_panel_created.title', 'alert.private_panel_created.text']).then(function(result) {
											translations = result;

											AlertService.success(
												translations['alert.private_panel_created.title'],
												translations['alert.private_panel_created.text']
											)
										});

										$rootScope.$broadcast('event:modal-closed');
									});
								});
							} else {

								$scope.submitSpinner = false;

								$translate(['alert.panel_already_exists.title', 'alert.panel_already_exists.text']).then(function(result) {
									translations = result;

									AlertService.warning(
										translations['alert.panel_already_exists.title'],
										translations['alert.panel_already_exists.text']
									);
								});
							}
						} else {

							DataService.submit('group', $scope.group).then(function (result) {

								$scope.submitSpinner = false;
								$scope.edit = false;
								$rootScope.creatingGroup = false;
								$mdDialog.hide();

								var translations;
								$translate(['alert.private_panel_created.title', 'alert.private_panel_created.text']).then(function(result) {
									translations = result;

									AlertService.success(
										translations['alert.private_panel_created.title'],
										translations['alert.private_panel_created.text']
									)
								});

								$rootScope.$broadcast('event:modal-closed');

								$rootScope.showGroupToken = null;
								$scope.oldGroupName = null;
							});
						}
					} else {

						$scope.submitSpinner = false;

						if($scope.group.data.name.value === "") {

							$translate(['alert.panel_require_title.title', 'alert.panel_require_title.text']).then(function(result) {
								translations = result;

								AlertService.warning(
									translations['alert.panel_require_title.title'],
									translations['alert.panel_require_title.text']
								);
							});
						}

						if($scope.group.data.description.value === "") {

							$translate(['alert.panel_require_description.title', 'alert.panel_require_description.text']).then(function(result) {
								translations = result;

								AlertService.warning(
									translations['alert.panel_require_description.title'],
									translations['alert.panel_require_description.text']
								);
							});
						}

                        if($scope.group.data.code.value === "") {

                            $translate(['alert.panel_require_code.title', 'alert.panel_require_code.text']).then(function(result) {
                                translations = result;

                                AlertService.warning(
                                    translations['alert.panel_require_code.title'],
                                    translations['alert.panel_require_code.text']
                                );
                            });
                        }
					}
				} else {

					DataService.submit('group', $scope.group).then(function (result) {

						var translations;
						$translate(['alert.private_panel_created.title', 'alert.private_panel_created.text']).then(function(result) {
							translations = result;

							AlertService.success(
								translations['alert.private_panel_created.title'],
								translations['alert.private_panel_created.text']
							)
						});

						$rootScope.$broadcast('event:modal-closed');
					});
				}
			}
		} else {

			$mdDialog.hide();
		}

		$event.preventDefault();
	};

	$scope.deleteGroup = function() {

		DataService.delete('group', $scope.group.data_token);
	};

	$scope.backCallback = function ($event) {

		$window.history.back();
		$event.preventDefault()
	};

	$scope.notifyClipboard = function() {

		var translations;
		$translate(['alert.invitation_code_copied.title', 'alert.invitation_code_copied.text']).then(function(result) {
			translations = result;

			AlertService.success(
				translations['alert.invitation_code_copied.title'],
				translations['alert.invitation_code_copied.text']
			)
		});
	};

	$scope.notifyClipboardShareLink = function() {

		var translations;
		$translate(['alert.share_link_copied.title', 'alert.share_link_copied.text']).then(function(result) {
			translations = result;

			AlertService.success(
				translations['alert.share_link_copied.title'],
				translations['alert.share_link_copied.text']
			)
		});
	};

	$scope.share = function($event, group, provider) {

		SocialShareService.share("community", group.data, provider);
	};

	$scope.$watch('edit', function(newVal, oldVal){
		if (!$scope.allowedToEdit) {
			newVal = false;
		}
	});

	//Close the Panel Editor Modal
	$scope.closePanelEditor = function() {

		//If this is a newly created group and the modal is closed, there has been no save action so we simply delete the group
		if($rootScope.creatingGroup) {

			$scope.deleteGroup();
			$rootScope.creatingGroup = false;
		}

		$scope.submitSpinner = false;
		$scope.edit = false;
		$rootScope.showGroupToken = null;
		$mdDialog.hide();
	};

	var init = function() {

        if (typeof $rootScope.showGroupToken != "undefined" && $rootScope.showGroupToken != null) {

            $scope.groupToken = $rootScope.showGroupToken;

            DataService.fetchItem('group', $scope.groupToken).then(function(result) {

                $scope.group = result.group;

                if($rootScope.creatingGroup) {

                    $scope.group.data.is_edited.value = "1";
                    $scope.group.data.is_public.value = "0";
                    $scope.group.data.is_visible.value = "0";
                    $scope.group.data.name.value = "";
                }

                $scope.originalGroupState = angular.copy($scope.group.data);
                $scope.oldGroupName = angular.copy($scope.group.data.name.value);

                $scope.form = FormService.parseData($scope.group.data);

                // edit only available when click create button not for the ones selected in the left list
                if($scope.group.data.name.value == ""){
                    $scope.edit = true;
                }

                if($scope.group.data.color.value == ""){
                    $scope.group.data.color.value = '#673AB7';
                }

                //Set Selected Group Category
                setInitialSelectedGroupCategory();
            });
        }
	};

	init();

}]);