/**
 * Created by Barry on 2018/05/25.
 *
 * Pulse toolbar
 */

onePulse.directive('pulseToolbar', ['PulseService',
    function (PulseService) {

    return {
        restrict: 'E',
        priority: 0,
        replace: true,
        scope: false,
        templateUrl:'/app/views/elements/pulse/pulseToolbar.html',

        link: function postLink(scope, element, iAttrs) {

            //scope.validRepulse = new Date(scope.pulse.data.started_at.value) > new Date(new Date().setDate(new Date().getDate() - 90));

            scope.duplicatePulse = function($event) {

                PulseService.duplicatePulse($event, scope.pulse, false);
            };

            scope.rePulse = function($event) {

                PulseService.rePulse($event, scope.pulse);
            };

            scope.deletePulse = function ($event) {

                PulseService.deletePulse($event, scope.pulse, scope.returnToPulseList, null);
            };

            scope.previewPulseModal = function($event) {

                PulseService.previewPulseModal($event, scope.pulse, scope.preview.displayName, scope.validate);
            };

            scope.extractPulseData = function($event) {

                PulseService.extractPulseData($event, scope.pulse, scope.allRegions);
            };

            scope.extractPulseDemographicSummary = function($event) {

                PulseService.extractPulseDemographicSummary($event, scope.pulse, scope.allRegions, scope.demographicsPostPublish);
            };
        }
    };
}]);
