/**
 * Created by mike on 13/12/15.
 */

onePulse.directive('user.profile', [function () {

	linkFunction = function ($scope, element, attributes) {

	};

	return {
		templateUrl: '/app/views/elements/forms/user/profile.html',
		link: linkFunction
	}
}]);
