'use strict';

var onePulse = angular.module('onePulse', [
	'ngResource',
	'ngRoute',
	'naif.base64',
	'ngMaterial',
	'numfmt-error-module',
	'ngAnimate',
	'ngDialog',
	'md.data.table',
	'imageCropper',
	'monospaced.mousewheel',
	'pascalprecht.translate',
	'googlechart',
	'mdPickers',
	'infinite-scroll',
	'vcRecaptcha',
	'slickCarousel',
	'ngScrollable',
	'ui.odometer',
	'ngAudio',
	'angular-jwt',
	'ngclipboard',
	'ngMeta',
	'720kb.socialshare',
	'ngFileSaver',
	'lz-string',
	'ui-rangeSlider',
	'angular-d3-word-cloud',
	'angular.filter',
	angularDragula(angular)

]);
