/**
 * Created by barry
 *
 */
onePulse.controller('ResellerManagementController', ['$scope', '$rootScope', 'DataService', 'FeatureService', 'CONSTANTS',
    '$mdDialog', '$window', 'AlertService', '$translate', 'Config', 'WorkspaceService', 'LoginService',
	function ($scope, $rootScope, DataService, FeatureService, CONSTANTS,
              $mdDialog, $window, AlertService, $translate, Config, WorkspaceService, LoginService) {

		//Grab current user and features features
		// $scope.features = FeatureService.getFeatures();
		$scope.user = DataService.grab('user');

		$scope.resellerClients = null;

		var translations;

		$translate(['alert.required_field_missing.title', 'alert.required_field_missing.text',
			'alert.user_created.title', 'alert.user_created.text',
			'alert.user_edited.title', 'alert.user_edited.text',
			'alert.delete_user_complete.title', 'alert.delete_user_complete.text',
			'alert.delete_user_confirm.title', 'alert.delete_user_confirm.text']).then(function(result){
			translations = result;
		});

		function AddResellerClientController($scope, $mdDialog, DataService, init) {

			$scope.submitSpinner = false;

            $scope.resellerClient = {"data": null};

			$scope.data = {
			    "brand_name": "",
				"display_name": "",
				"email": "",
                "password": "",
                "country_code": ""
			};

			$scope.modalAddResellerClient = function() {

				$scope.submitSpinner = true;

                Config.countryCheck(true, false).then(function(response) {

					var countryCode = typeof response !== "undefined" ? (typeof response.country !== "undefined" ? response.country : null) : null;

                    //Pass in referral code and country code
                    $scope.data.country_code = countryCode;

                    if ($scope.data.email === "" || $scope.data.display_name === "" || $scope.data.password === "") {

                        $scope.submitSpinner = false;

                        AlertService.warning(
                            translations['alert.required_field_missing.title'],
                            translations['alert.required_field_missing.text']
                        );
                    } else {

                        DataService.client('reseller_client', 'create', $scope.data, null, function () {

                            $scope.submitSpinner = false;
                        }).then(function () {

                            $scope.submitSpinner = false;

                            $mdDialog.hide();

                            AlertService.success(
                                translations['alert.user_created.title'],
                                translations['alert.user_created.text']
                            );

                            init();
                        });
                    }
                });
			};

			$scope.closeDialog = function () {

				$mdDialog.hide();
			};
		}

		//Add new user
		$scope.addResellerClient = function($event) {

			AddResellerClientController.$inject = ['$scope', '$mdDialog', 'DataService', 'init'];

			$mdDialog.show({
				controller: AddResellerClientController,
				templateUrl: 'app/views/elements/includes/reseller-management-add-reseller.html',
				parent: angular.element(document.body),
				clickOutsideToClose: true,
				targetEvent: $event,
				locals: {
				    DataService: DataService,
                    init: init
				}
			});
		};

        function ViewUserController($scope, $mdDialog, DataService, WorkspaceService, resellerClient) {

            $scope.submitSpinner = false;
            $scope.purchaseAdmin = null;
            $scope.workspaceCode = null;

            //Determine Purchase Admin for this reseller client
            for(var i = 0; i <= resellerClient.data.client_users.length; i++) {

                if(typeof resellerClient.data.client_users[i] !== "undefined") {

                    for(var j = 0; j <= resellerClient.data.client_users[i].workspaces.length; j++) {

                        if (typeof resellerClient.data.client_users[i].workspaces[j] !== "undefined") {

                            if (resellerClient.data.client_users[i].workspaces[j].code.value === resellerClient.data.client_code.value) {

                                if (resellerClient.data.client_users[i].workspaces[j].role.value === 'client:purchase_manager') {

                                    $scope.workspaceCode = resellerClient.data.client_users[i].workspaces[j].code.value;
                                    $scope.purchaseAdmin = resellerClient.data.client_users[i];
                                }
                            }
                        }
                    }
                }
            }

			$scope.assumeLogin = function () {

            	var data = {
            		'cu_id': $scope.purchaseAdmin.obf_id.value,
					'ws_id': $scope.workspaceCode
				};

				LoginService.assumeLogin(data, function () {

					$scope.submitSpinner = false;
				}).then(function () {

					$scope.submitSpinner = false;

					$mdDialog.hide();

					$scope.isLoggedIn = true;

					$window.location = '/dashboard';
				});
			};

            $scope.closeDialog = function () {

                $mdDialog.hide();
            };
        }

        //Add new user
        $scope.viewUser = function($event, resellerClient) {

            ViewUserController.$inject = ['$scope', '$mdDialog', 'DataService', 'WorkspaceService', 'resellerClient'];

            $mdDialog.show({
                controller: ViewUserController,
                templateUrl: 'app/views/elements/includes/reseller-view-user.html',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                targetEvent: $event,
                locals: {
                    DataService: DataService,
                    WorkspaceService: WorkspaceService,
                    resellerClient: resellerClient
                }
            });
        };

		//Disable user (soft delete)
		$scope.deleteResellerClient = function(resellerClient) {

			AlertService.confirm(
				translations['alert.delete_user_confirm.title'],
				translations['alert.delete_user_confirm.text'],
				function() {

					$rootScope.renderComplete = false;
					$rootScope.loadingStateText = "Deleting Reseller Client";

					DataService.client('reseller_client', 'delete', null, resellerClient.data_token).then(function() {

						AlertService.success(
							translations['alert.delete_user_complete.title'],
							translations['alert.delete_user_complete.text']
						);

						$rootScope.renderComplete = true;
						$rootScope.loadingStateText = "";

						$scope.resellerClients = unsetElementByDataToken($scope.resellerClients, resellerClient.data_token);
					});
				}
			);
		};

		//Populate client user's list of manageable users
		var populateResellerClientList = function() {

			$scope.resellerClients = [];

			DataService.fetch('client').then(function(result) {

				$scope.resellerClients = JSON.parse(result).client.reseller_clients;
			});


		};

		//Initialise user management
		var init = function() {

			populateResellerClientList();
		};

		init();

}]);
