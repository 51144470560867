/**
 * Created by Barry on 25/05/2018
 *
 * Pulse function service
 */
onePulse.factory('PulseService', ['$rootScope', 'DataService', 'AlertService', 'ErrorService', 'FeatureService', 'ExportService', 'ValidationService',
    'RoleService', '$mdDialog', '$translate', '$window', '$location', '$route', 'CONSTANTS',
    function($rootScope, DataService, AlertService, ErrorService, FeatureService, ExportService, ValidationService,
             RoleService, $mdDialog, $translate, $window, $location, $route, CONSTANTS) {

        return {

            duplicatePulse: function($event, pulse, fromList) {

                if(fromList) {

                    $event.stopPropagation();

                    $window.location = "/pulse/" + pulse.data.id.value + "/duplicate";
                } else {

                    pulse = ValidationService.duplicateAnswerCorrection(pulse);
                    pulse = ValidationService.answerSequenceCorrection(pulse);

                    $rootScope.renderComplete = false;
                    $rootScope.loadingStateText = "Duplicating Pulse";

                    var duplicate = function() {

                        DataService.duplicate(pulse.data_token, "pulse").then(function (result2) {

                            DataService.submit("pulse", result2.pulse).then(function (result3) {

                                var _loop = function (count) {

                                    if (typeof pulse.data.questions[count].data.branch !== "undefined") {

                                        result3.pulse.data.questions[count].data.branch = {"value": pulse.data.questions[count].data.branch.value};
                                    }

                                    DataService.submit("pulse/question", result3.pulse.data.questions[count]).then(function (result4) {

                                        if (count == result3.pulse.data.questions.length - 1) {

                                            $window.location = "/pulse/" + result3.pulse.data.id.value + "/edit";
                                        } else {

                                            _loop(count + 1);
                                        }
                                    });
                                };

                                if (result3.pulse.data.questions.length > 0) {

                                    _loop(0);
                                }
                            });
                        });
                    };

                    //If pulse is in draft state it may not have been saved before duplication. We need to force this save
                    if(pulse.data.status.value == "Draft") {

                        DataService.submit("pulse", pulse).then(function (result) {

                            pulse = result.pulse;

                            duplicate();
                        });
                    } else {

                        duplicate();
                    }
                }
            },

            rePulse: function($event, pulse) {

                $event.stopPropagation();

                $rootScope.renderComplete = false;
                $rootScope.loadingStateText = "re-Pulsing";

                DataService.repulse(pulse.data_token, "pulse").then(function (result) {

                    $window.location = "/pulse/" + result.pulse.data.id.value + "/edit";
                });
            },

            //Controller for Delete Pulse modal
            deletePulseModalController: function($scope, $rootScope, $mdDialog, $translate, pulse, AlertService, DataService, returnToPulseList, removePulse) {

                $scope.pulse = pulse;
                $scope.submitSpinner = false;

                $scope.deletePulse = function() {

                    $mdDialog.hide();
                    $rootScope.renderComplete = false;
                    $rootScope.loadingStateText = "Deleting Pulse";
                    $rootScope.deletingPulse = true;

                    DataService.delete("pulse", $scope.pulse.data_token).then(function() {

                        if(removePulse !== null) {

                            removePulse($scope.pulse.data_token);
                        }

                        var translations;
                        $translate(["alert.delete_pulse_complete.title", "alert.delete_pulse_complete.text"]).then(function(result) {
                            translations = result;

                            AlertService.success(
                                translations["alert.delete_pulse_complete.title"],
                                translations["alert.delete_pulse_complete.text"]
                            );
                        });

                        $rootScope.renderComplete = true;
                        $rootScope.loadingStateText = "";

                        if(returnToPulseList !== null) {

                            returnToPulseList();
                        }
                    });
                };

                $scope.closeDialog = function () {

                    $mdDialog.hide();
                }
            },

            //Soft delete Pulse
            deletePulse: function ($event, pulse, returnToPulseList, removePulse) {

                $event.stopPropagation();

                this.deletePulseModalController.$inject = [
                    "$scope", "$rootScope", "$mdDialog", "$translate", "pulse", "AlertService", "DataService", "returnToPulseList", "removePulse"
                ];

                $mdDialog.show({
                    templateUrl: "app/views/elements/includes/delete-pulse.html",
                    controller: this.deletePulseModalController,
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    targetEvent: $event,
                    locals: {
                        pulse: pulse,
                        AlertService: AlertService,
                        DataService: DataService,
                        returnToPulseList: returnToPulseList,
                        removePulse: removePulse
                    }
                });
            },

            //Controller for Pulse Lite Closed modal
            PulseLiteClosedModalController: function($scope, $rootScope, $mdDialog, $route, $location, DataService) {

                $scope.createPulse = function(event) {

                    DataService.create("pulse").then(function (result) {
                        $rootScope.createdPulse = result.pulse;
                        $location.path("/pulse/" + result.pulse.data.id.value);

                        $rootScope.renderComplete = true;
                        $rootScope.loadingStateText = "";
                    });

                    event.preventDefault();
                }

                $scope.closeDialog = function () {

                    $mdDialog.hide();
                    $route.reload();
                }
            },

            //Show Preview Pulse Modal
            pulseLiteClosedModal: function(event) {

                this.PulseLiteClosedModalController.$inject = ["$scope", "$rootScope", "$mdDialog" ,"$route", "$location", "DataService"];

                $mdDialog.show({
                    controller: this.PulseLiteClosedModalController,
                    templateUrl: "app/views/elements/includes/pulse-lite-closed.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    targetEvent: event,
                    locals: {
                        DataService: DataService
                    }
                });
            },

            //Controller for Pulse preview modal
            PreviewController: function($scope, $rootScope, $mdDialog, ErrorService, pulse, displayName, validate) {

                $scope.pulse = pulse;
                $scope.preview = {displayName: displayName};
                $scope.validate = validate;
                $scope.submitSpinner = false;

                ErrorService.prep("preview");
                $scope.errors = $rootScope.errors;

                //Send Pulse Preview
                $scope.previewPulse = function() {

                    if($scope.preview.displayName != "" && $scope.validate(false, false)) {

                        $scope.submitSpinner = true;

                        $scope.deregisterPulseUpdateForPreviewHandler = $rootScope.$on("event:pulse-update", function() {

                            $scope.submitSpinner = false;

                            //Remove listener
                            $scope.deregisterPulseUpdateForPreviewHandler();

                            DataService.pulsePreview($scope.preview.displayName, $scope.pulse.data_token).then(function() {

                                $mdDialog.hide();

                                var translations;
                                $translate(["preview.success.title", "preview.success.text"]).then(function(result) {
                                    translations = result;

                                    AlertService.success(
                                        translations["preview.success.title"],
                                        translations["preview.success.text"]
                                    );
                                });
                            })
                        });
                    }
                };

                $scope.closeDialog = function () {

                    //Remove listener
                    if(typeof $scope.removeListener !== "undefined") {

                        $scope.removeListener();
                    }

                    $mdDialog.hide();
                }
            },

            //Show Preview Pulse Modal
            previewPulseModal: function(event, pulse, displayName, validate) {

                this.PreviewController.$inject = ["$scope", "$rootScope", "$mdDialog", "ErrorService", "pulse", "displayName", "validate"];

                $mdDialog.show({
                    controller: this.PreviewController,
                    templateUrl: "app/views/elements/includes/pulse-preview.html",
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    targetEvent: event,
                    locals: {
                        pulse: pulse,
                        displayName: displayName,
                        validate: validate
                    }
                });
            },

      

            //Create data extract for entire pulse
            extractPulseData: function(event, pulse, allRegions) {

                //Check that feature is allowed
                if(FeatureService.allowed(CONSTANTS.FEATURES.SPREADSHEET_DATA_EXTRACTS, true)) {

                    if(typeof analytics !== "undefined" && typeof analytics.track !== "undefined") {

                        var user = DataService.grab("user");

                        if(typeof user.data.brand_name !== "undefined") {

                            analytics.track("Extract Raw Data", {
                                name: user.data.brand_name.value
                            });
                        }
                    }

                    var dataHeaders = null;

                    for (var j = 0; j < pulse.data.questions.length; j++) {

                        if(typeof pulse.data.questions[j].rawDataTable !== "undefined") {

                            dataHeaders = pulse.data.questions[j].rawDataTable[0];
                        // } else {
                        //
                        //     AlertService.alert(
                        //         'Dataset not completely loaded',
                        //         'Extract dataset is still loading. Please try again in a few moments.'
                        //     );
                        //
                        //     return;
                        }

                        //Determine question format
                        if (typeof pulse.data.questions[j].data.format.options !== "undefined") {

                            for (var k = 0; k < pulse.data.questions[j].data.format.options.length; k++) {

                                if (pulse.data.questions[j].data.format.options[k].option.selected == true) {

                                    pulse.data.questions[j].formatText = pulse.data.questions[j].data.format.options[k].option.text;
                                }
                            }
                        } else {

                            pulse.data.questions[j].formatText = pulse.data.questions[j].data.format.value;
                        }
                    }

                    var pulseHeader = ExportService.buildPulseHeader("csv", pulse);
                    var header = ExportService.buildHeader("csv", pulse.data.questions, dataHeaders, true, pulseHeader);
                    var dataObjArray = {};

                    //Have to split logic for branched/non-branched pulses - A branched pulse requires more processing
                    if(pulse.data.is_branched.value == "0") {

                        for (var i = 0; i < pulse.data.questions.length; i++) {

                            if(typeof pulse.data.questions[i].dataTable !== "undefined" && typeof pulse.data.questions[i].rawDataTable !== "undefined") {

                                dataObjArray = ExportService.addQuestion("csv", pulse.data.questions[i], dataObjArray, allRegions);
                            }
                        }

                        var formattedDataTable = ExportService.buildBody("csv", header, dataObjArray, pulse.data.questions, pulse.data.is_branched.value);

                        ExportService.saveFile(ExportService.encodeUri("csv", formattedDataTable), pulse.data.title.value + "_raw.csv");

                    } else {

                        //Recursive function so that download is only created on final data response
                        var _loop = function _loop(i) {

                            //For a branched pulse we require all question data so need to pull this down, as normally only the active branch is requested
                            DataService.getQuestionData(pulse.data.questions[i].data_token).then(function (result) {

                                pulse.data.questions[i].rawDataTable = result.data_table;
                                dataObjArray = ExportService.addQuestion("csv", pulse.data.questions[i], dataObjArray, allRegions);

                                if(i == pulse.data.questions.length - 1) {

                                    var formattedDataTable = ExportService.buildBody("csv", header, dataObjArray, pulse.data.questions, pulse.data.is_branched.value);

                                    ExportService.saveFile(ExportService.encodeUri("csv", formattedDataTable), pulse.data.title.value + "_raw.csv");
                                } else {

                                    _loop(i + 1);
                                }
                            });
                        };

                        if (pulse.data.questions.length > 0) {
                            _loop(0);
                        }
                    }
                }
            },

            extractPulseDemographicSummary: function($event, pulse, allRegions, demographicsPostPublish) {

                //Check that feature is allowed
                if(FeatureService.allowed(CONSTANTS.FEATURES.SPREADSHEET_DATA_EXTRACTS, true)) {

                    if(typeof analytics !== "undefined" && typeof analytics.track !== "undefined") {

                        var user = DataService.grab("user");

                        if(typeof user.data.brand_name !== "undefined") {

                            analytics.track("Extract Summary Data", {
                                name: user.data.brand_name.value
                            });
                        }
                    }

                    for (var j = 0; j < pulse.data.questions.length; j++) {

                        // if(typeof pulse.data.questions[j].rawDataTable === "undefined") {
                        //
                        //     AlertService.alert(
                        //         'Dataset not completely loaded',
                        //         'Extract dataset is still loading. Please try again in a few moments.'
                        //     );
                        //
                        //     return;
                        // }

                        //Determine question format
                        if (typeof pulse.data.questions[j].data.format.options !== "undefined") {

                            for (var k = 0; k < pulse.data.questions[j].data.format.options.length; k++) {

                                if (pulse.data.questions[j].data.format.options[k].option.selected == true) {

                                    pulse.data.questions[j].formatText = pulse.data.questions[j].data.format.options[k].option.text;
                                }
                            }
                        } else {

                            pulse.data.questions[j].formatText = pulse.data.questions[j].data.format.value;
                        }
                    }

                    var exportData = "";

                    //Have to split logic for branched/non-branched pulses - A branched pulse requires more processing
                    if(pulse.data.is_branched.value == "0") {

                        for (var i = 0; i < pulse.data.questions.length; i++) {

                            if(typeof pulse.data.questions[i].dataTable !== "undefined" && typeof pulse.data.questions[i].rawDataTable !== "undefined") {

                                var questionsArray = [];
                                questionsArray.push(pulse.data.questions[i]);

                                var pulseHeader = ExportService.buildPulseHeader("csv", pulse);

                                var title = ExportService.buildDemographicSummaryTitle("csv", pulse.data.questions[i], pulseHeader);

                                var header = ExportService.buildDemographicSummaryHeader("csv", questionsArray, title);

                                var dataObjArray = {};
                                dataObjArray = ExportService.addQuestion("csv", pulse.data.questions[i], dataObjArray, allRegions);

                                var formattedDataTable = ExportService.buildDemographicSummaryBody("csv", header, dataObjArray, questionsArray, pulse, demographicsPostPublish);

                                exportData = exportData + formattedDataTable;
                                exportData = ExportService.newLine(exportData);
                            }
                        }

                        ExportService.saveFile(ExportService.encodeUri("csv", exportData), pulse.data.title.value + "_summary.csv");

                    } else {

                        //Recursive function so that download is only created on final data response
                        var _loop = function _loop(i) {

                            //For a branched pulse we require all question data so need to pull this down, as normally only the active branch is requested
                            DataService.getQuestionData(pulse.data.questions[i].data_token).then(function (result) {

                                pulse.data.questions[i].rawDataTable = result.data_table;

                                var questionsArray = [];
                                questionsArray.push(pulse.data.questions[i]);

                                var pulseHeader = ExportService.buildPulseHeader("csv", pulse);

                                var title = ExportService.buildDemographicSummaryTitle("csv", pulse.data.questions[i], pulseHeader);

                                var header = ExportService.buildDemographicSummaryHeader("csv", questionsArray, title);

                                var dataObjArray = {};

                                dataObjArray = ExportService.addQuestion("csv", pulse.data.questions[i], dataObjArray, allRegions);

                                var formattedDataTable = ExportService.buildDemographicSummaryBody("csv", header, dataObjArray, questionsArray, pulse, demographicsPostPublish);

                                exportData = exportData + formattedDataTable;
                                exportData = ExportService.newLine(exportData);

                                if(i == pulse.data.questions.length - 1) {

                                    ExportService.saveFile(ExportService.encodeUri("csv", exportData), pulse.data.title.value + "_summary.csv");
                                } else {

                                    _loop(i + 1);
                                }
                            });
                        };

                        if (pulse.data.questions.length > 0) {
                            _loop(0);
                        }
                    }
                }
            },

            editPulse: function(pulseId) {

                $location.path("/pulse/" + pulseId + "/edit");
            },

            createPulse: function($event) {

                if(RoleService.allowed(CONSTANTS.RIGHTS.CREATE_EDIT_PULSE, true)) {

                    $rootScope.renderComplete = false;
                    $rootScope.loadingStateText = "Creating Pulse";

                    DataService.create("pulse").then(function (result) {
                        $rootScope.createdPulse = result.pulse;
                        $location.path("/pulse/" + result.pulse.data.id.value);

                        $rootScope.renderComplete = true;
                        $rootScope.loadingStateText = "";
                    });
                }

                $event.preventDefault();
            }
        }
    }]);
