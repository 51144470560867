/**
 * Created by mike on 08/12/15.
 */
onePulse.controller('DashboardController', ['$scope', '$location', '$rootScope', 'DataService', 'AuthService',
    '$mdDialog', 'FeatureService', '$window', '$timeout', '$routeParams', 'CONSTANTS', 'SearchService', 'RoleService','$http',
    function ($scope, $location, $rootScope, DataService, AuthService,
              $mdDialog, FeatureService, $window, $timeout, $routeParams, CONSTANTS, SearchService, RoleService,$http) {

    $scope.userType = AuthService.userType();
    $scope.isLoggedIn = AuthService.isLoggedIn();
    $scope.starterQuestion = '';
    $scope.startingPulseInProgress = false;
    $scope.testInteger = 256;
    $scope.loadingSpinner = true;
    $scope.saving = {"amount": 0, "topup": 0};

    $scope.pulseLiteId = typeof $routeParams.id != "undefined" ? $routeParams.id : null ;

    if($scope.pulseLiteId !== null) {

        $rootScope.renderComplete = false;
    }


    $scope.search = {"search": "", "pulse": "", "question": "", "status": "Draft Active Scheduled Closed", "type": "full_text"};

    $scope.ctrl = {
        "orderBy": "pulse.created_at",
        "orderDirection": "desc"
    };

    $scope.sort = {};
    $scope.sort.field = $scope.ctrl.orderBy;
    $scope.sort.order = $scope.ctrl.orderDirection;

    $scope.search = {search: '', pulse: '', question: '', status: ''};

    $scope.search.status = 'Active Closed';

    $scope.news_items = null;

    $scope.newsItemsLoaded = false;

    $scope.showUpsellBanner = {
        "Topup": false,
        "Sub": false
    };

    $scope.slickConfigNewsDashboard = {
        enabled: true,
        autoplay: true,
        draggable: true,
        dots: true,
        fade: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        method: {},
        event: {
            beforeChange: function (event, slick, currentSlide, nextSlide) {
            },
            afterChange: function (event, slick, currentSlide, nextSlide) {
            }
        }
    };

    /*
    MM:

    This creates a pulse, adds a question, updates the question text with the value from the input
    and redirects to the pulse with edit state forced
     */
    $scope.starterPulse = function($event){

        if ($event.which === 13) {

            if (!$scope.startingPulseInProgress) {
                $scope.startingPulseInProgress = true;
                $scope.$parent.transitionClass = 'dashboard_transition';
                $scope.createPulse();
                $event.preventDefault();
            }
        }
    };

    $scope.dismissUpsellBanner = function(event, type) {

        event.preventDefault();

        $scope.showUpsellBanner[type] = false;

        var data = {
            "sub_upsell_last_dismissed": (type === "Sub" ? new Date() : ($scope.currentWorkspace.sub_upsell_last_dismissed.value === "" ? null : $scope.currentWorkspace.sub_upsell_last_dismissed.value)),
            "topup_upsell_last_dismissed": (type === "Topup" ? new Date() : ($scope.currentWorkspace.topup_upsell_last_dismissed.value === "" ? null : $scope.currentWorkspace.topup_upsell_last_dismissed.value))
        };

        DataService.workspace('update', data).then(function() {})
    };

    $scope.editPulse = function(pulseId) {

        $location.path('/pulse/' + pulseId + '/edit');
    };

    $scope.redirectPanels = function() {

        $location.path('/channels');
    };

    $scope.calculateRatingWidth = calculateRatingWidth;

    $scope.deregisterPulsesLocalstorageUpdateHandler = $rootScope.$on("event:pulses-localstorage-update", function (event) {

        if($scope.pulseLiteId !== null) {

            $rootScope.renderComplete = false;

            $window.location = '/pulse/' + $scope.pulseLiteId + '/edit';
        }
    });

    $scope.deregisterRenderCompleteHandler = $rootScope.$on("event:render_complete", function (event) {

        if($scope.pulseLiteId != null) {

            $rootScope.renderComplete = false;
        }

        $timeout(function () {

            $window.dispatchEvent(new Event("resize"));
        })
    });

    $scope.popupUpsellDialog = function (step) {

        var template = 'dashboard-checkout';

        $mdDialog.show({
            controller: "DashboardUpsellController",
            templateUrl: "app/views/elements/includes/" + template + ".html",
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            scope: $scope,
            locals: {step: step},
            preserveScope: true
        });
    };

    var initNewsItems = function() {

        DataService.fetchMetaData("news_items").then(function(result) {

            $timeout(function() {

                $scope.newsItemsLoaded = false;

                var tempNewsItems = result.news_items;
                var cleanedNewsItems = [];
                var longestSlideWaitDurationInSeconds = 5;

                for (var i = 0; i < tempNewsItems.length; i++) {

                    if (tempNewsItems[i].display_order.value != '') {

                        cleanedNewsItems.push(tempNewsItems[i]);

                        if (tempNewsItems[i].wait_time.value != '') {

                            if (parseInt(tempNewsItems[i].wait_time.value) > longestSlideWaitDurationInSeconds) {

                                longestSlideWaitDurationInSeconds = parseInt(tempNewsItems[i].wait_time.value);
                            }
                        }
                    }
                }

                cleanedNewsItems.sort(function (a, b) {

                    return parseInt(a.display_order.value) - parseInt(b.display_order.value);
                });

                $scope.news_items = cleanedNewsItems;

                $scope.slickConfigNewsDashboard.autoplaySpeed = longestSlideWaitDurationInSeconds * 1000;

                $scope.newsItemsLoaded = true;
                $scope.loadingSpinner = false;
            })
        });
    };

    initNewsItems();

    var init = function() {

        //Display/hide web training overlays
        if(CONSTANTS.DISPLAY_TRAINING_OVERLAYS && $scope.pulseLiteId == null) {

            if(typeof $window.localStorage['showDashboardTrainingOverlay'] == "undefined") {

                $rootScope.showDashboardTrainingOverlay = (typeof $scope.user.data != "undefined" ? ($location.absUrl().search('/dashboard') != -1 && $scope.user.data.is_web_training_displayed.value) : false);
                $window.localStorage['showDashboardTrainingOverlay'] = (typeof $scope.user.data != "undefined" ? ($location.absUrl().search('/dashboard') != -1 && $scope.user.data.is_web_training_displayed.value) : false);
            } else {

                $rootScope.showDashboardTrainingOverlay = ($window.localStorage['showDashboardTrainingOverlay'] === "true") && ($location.absUrl().search('/dashboard') != -1 && $scope.user.data.is_web_training_displayed.value);
            }
        }

        DataService.fetchItem('user').then(function(result) {

            $scope.user = result.user;
            $scope.currentWorkspace = null;

            for(var i = 0; i < $scope.user.data.workspaces.length; i++) {

                if($scope.user.data.workspaces[i].code.value === $scope.user.data.client_code.value) {

                    $scope.currentWorkspace = $scope.user.data.workspaces[i];
                }
            }

            $scope.determineUpsellVisibility();
            $scope.determineCarouselVisibility();

            DataService.fetchMetaData("subscriptions").then(function(result) {

                result.subscriptions.sort(function (a, b) {

                    return a.data.display_order.value - b.data.display_order.value;
                });

                $scope.subscriptions = result.subscriptions;

                DataService.fetchMetaData("topups").then(function(result) {

                    var tempTopups = result.topups;
                    var cleanedTopups = [];

                    for (var i = 0; i < tempTopups.length; i++) {

                        if (typeof tempTopups[i].data.is_active.value !== "undefined") {

                            if (tempTopups[i].data.is_active.value == '1' &&
                                tempTopups[i].data.display_order.value != '' &&
                                tempTopups[i].data.is_hidden.value == '0') {

                                tempTopups[i].data.display_price.value = parseFloat(tempTopups[i].data.display_price.value).toFixed(0);
                                tempTopups[i].data.cost.value = parseFloat(tempTopups[i].data.cost.value).toFixed(0);

                                cleanedTopups.push(tempTopups[i])
                            }
                        }
                    }

                    cleanedTopups.sort(function (a, b) {

                        return parseInt(a.data.display_order.value) - parseInt(b.data.display_order.value);
                    });

                    $scope.topups = cleanedTopups;

                    DataService.fetch('client/metrics').then(function(result){

                        result = angular.fromJson(result);

                        $scope.metrics = result.metrics;

                        $scope.determineSavings();
                    });
                });
            });
        });

        //Initialise role
        RoleService.initRole();

        SearchService.performSearch("es", "pulses", $scope.search, null, $scope.sort, false, true).then(function (result) {

            $scope.pulses = angular.fromJson(result).pulses.slice(0,6)
        });

        DataService.fetchMetaData("products/features").then(function(result) {

            FeatureService.initFeatures(result);

        });

        /*
        PWA: Register service worker if browser supports
         */
        if ('serviceWorker' in navigator) {

            $http.get('rev-manifest.json').
            then(function onSuccess(response) {

                const worker = response.data['service-worker.js'];
                navigator.serviceWorker.register(worker)
                    .then(function (registration) {
                        var serviceWorker;
                        if (registration.installing) {
                            serviceWorker = registration.installing;
                        } else if (registration.waiting) {
                            serviceWorker = registration.waiting;
                        } else if (registration.active) {
                            serviceWorker = registration.active;
                        }

                        if (serviceWorker) {

                            serviceWorker.addEventListener('statechange', function (e) {
                                //console.log("ServiceWorker phase:", e.target.state);
                            });
                        }
                    }).catch(function (err) {
                    //console.log('ServiceWorker registration failed: ', err);
                });
            }).catch(function onError(response) {
                //console.log(response);
            });

            //if the device is iOS do custom prompt
            $timeout(function () {
                var isApple = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
                if(isApple && $scope.user.data.is_show_pwa_prompt.value=="1"){
                    var ua = window.navigator.userAgent;
                    $scope.iossafari = !!ua.match(/WebKit/i) && !ua.match(/CriOS/i) && !ua.match(/Chrome/i); //is webkit and is not chrome iOS or chrome
                    $scope.showPwaPrompt = true;
                    $timeout(function () {
                        //in 10 seconds close the prompt
                      if($scope.showPwaPrompt) {
                        $scope.dropIsShowPwaPrompt ();
                      }
                    },10000);
                }
            },30000)//30 seconds to emulate the Android paradigm

        } else {
            console.log("this browser does NOT support service worker");
        }
    };

    $scope.iossafari = false;
    $scope.showPwaPrompt = false;
    $scope.dropIsShowPwaPrompt = function(){
        $scope.showPwaPrompt = false;
        $scope.user.data.is_show_pwa_prompt.value = "0";
        DataService.submit('user', $scope.user).then(function (result) {
            $scope.user = result.user;
        });
    };

    init();

    $scope.determineSavings = function() {

        if($scope.user.data.current_subscription.data.description.value == 'Enterprise') {

            $scope.saving.amount = 0;
        } else {

            for(var i = 0; i < $scope.subscriptions.length; i++) {

                if($scope.subscriptions[i].data.description.value != 'Enterprise' &&
                    $scope.subscriptions[i].data.description.value != 'PAYG') {

                    var difference = (((parseFloat($scope.metrics.responses_credit_thirty_days) * parseFloat($scope.user.data.current_subscription.data.response_value.value))
                            + parseFloat($scope.user.data.current_subscription.data.display_price.value))  -
                        ((parseFloat($scope.metrics.responses_credit_thirty_days) * parseFloat($scope.subscriptions[i].data.response_value.value))
                            + parseFloat($scope.subscriptions[i].data.display_price.value))
                        ).toFixed(2);

                    $scope.saving.amount = parseFloat($scope.saving.amount > parseFloat(difference) ? $scope.saving.amount : parseFloat(difference)).toFixed(2);
                }
            }
        }

        for(var j = 0; j < $scope.topups.length; j++) {

            var topupDifference = (((parseFloat($scope.metrics.responses_credit_thirty_days) * parseFloat($scope.user.data.current_subscription.data.response_value.value)) -
                    (parseFloat($scope.metrics.responses_credit_thirty_days) * parseFloat($scope.topups[j].data.display_price.value / $scope.topups[j].data.respones_credit.value)))
            ).toFixed(2);

            $scope.saving.topup = parseFloat($scope.saving.topup > parseFloat(topupDifference) ? $scope.saving.topup : parseFloat(topupDifference)).toFixed(2);
        }
    };

    $scope.determineCarouselVisibility = function() {

        $scope.showNewsCarousel = ($scope.user.data.current_subscription.data.is_carousel_hidden.value == "0" || $scope.user.data.current_subscription.data.is_carousel_hidden.value == 0);
    };

    $scope.determineUpsellVisibility = function() {

        if($scope.currentWorkspace !== null && $scope.user.data.current_subscription.data.is_custom.value == "0") {

            $scope.showUpsellBanner.Sub = $scope.currentWorkspace.sub_upsell_last_dismissed.value === "";
            $scope.showUpsellBanner.Topup = $scope.currentWorkspace.topup_upsell_last_dismissed.value === "";

            if($scope.currentWorkspace.sub_upsell_last_dismissed.value !== "") {

                $scope.showUpsellBanner.Sub = parseInt(diffInDays(new Date(), new Date($scope.currentWorkspace.sub_upsell_last_dismissed.value))) > parseInt($scope.currentWorkspace.sub_upsell_hide_period.value);
            }

            if($scope.currentWorkspace.topup_upsell_last_dismissed.value !== "") {

                $scope.showUpsellBanner.Topup = parseInt(diffInDays(new Date(), new Date($scope.currentWorkspace.topup_upsell_last_dismissed.value))) > parseInt($scope.currentWorkspace.topup_upsell_hide_period.value);
            }
        }
    }

    $scope.$on("$destroy", function() {

        //De-register all scope event handlers when controller scope is destroyed
        if (typeof $scope.deregisterPulsesLocalstorageUpdateHandler != "undefined") {$scope.deregisterPulsesLocalstorageUpdateHandler()}
        if (typeof $scope.deregisterRenderCompleteHandler !== "undefined") {$scope.deregisterRenderCompleteHandler()}
    });
}]);
