onePulse.controller('DashboardUpsellController', ['$scope', '$mdDialog', 'DataService', '$rootScope', '$timeout', '$translate',
    'TokenService', 'FeatureService', 'RoleService', 'CONSTANTS', 'step',
    function($scope, $mdDialog, DataService, $rootScope, $timeout, $translate,
             TokenService, FeatureService, RoleService, CONSTANTS, step) {

    $scope.checkoutStep = step;
    //Default setting for checkout type (3 states: 'Direct'/'Topup'/'Sub')
    $scope.checkoutType = 'Direct';

    $scope.submitSpinner = false;
    $rootScope.showStepLoadingSpinner = false;

    //Default top-up
    $scope.selectedTopup = 'autoBill';
    $rootScope.descriptionTopup = $scope.selectedTopup;

    $scope.init = function() {

        DataService.fetchItem('user').then(function (result) {

            $scope.user = result.user;
        });
    };

    $scope.init();

    //Error callback for Braintree
    $scope.errorCallback = function() {

        var translations;
        $translate(['alert.card_details_error.title', 'alert.card_details_error.text']).then(function(result) {
            translations = result;

            AlertService.error(
                translations['alert.card_details_error.title'],
                translations['alert.card_details_error.text']
            );
        });
    };

    //Success callback for Braintree - Topup payment
    $scope.successCallbackTopup = function(payload, deviceData) {

        if(!$scope.submitSpinner) {

            $scope.submitSpinner = true;

            var data_token = $('#selectedDataToken').val();
            var description = $('#selectedDescription').val();
            var responsesCredited = $('#selectedResponsesCredited').val();

            // Submit payload.nonce and other data
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "data_token": data_token
            };

            DataService.profileClient("topups", data, "/purchase", function() {

                $scope.submitSpinner = false;

                //Hide loading spinner and show confirm button text
                $('.button-text').css('display', 'block');
                $('.button-spinner').css('display', 'none');

                //Enable submit button
                $('#submit').prop("disabled",false);
            }).then(function (result) {

                //Have to enable autobilling if purchase is made
                $scope.user.data.is_autobilled.value = "1";

                DataService.submit('user', $scope.user).then(function (result) {

                    $scope.submitSpinner = false;
                    $scope.user = result.user;

                    //Store topup object for use in next modal
                    $rootScope.descriptionTopup = description;

                    DataService.fetch('client/metrics').then(function(result) {

                        //MM: shouldnt need to do this..
                        result = angular.fromJson(result);

                        $scope.metrics = result.metrics;

                        $rootScope.modalOpen = false;
                        $mdDialog.hide();
                    });
                });
            });
        }
    };

    //Success callback for Braintree - Subscription payment
    $scope.successCallbackSubs = function(payload, deviceData) {

        if(!$scope.submitSpinner) {

            $scope.submitSpinner = true;

            var data_token = $('#selectedDataToken').val();
            var description = $('#selectedDescription').val();

            // Submit payload.nonce and other data
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "data_token": data_token
            };

            DataService.profileClient("subscription", data, "/purchase", function() {

                $scope.submitSpinner = false;

                //Hide loading spinner and show confirm button text
                $('.button-text').css('display', 'block');
                $('.button-spinner').css('display', 'none');

                //Enable submit button
                $('#submit').prop("disabled",false);
            }).then(function (result) {

                data = {
                    "braintree_subscription_key": result.braintree_subscription_key
                };

                DataService.profileClient("subscription", data, "/status", function() {

                    $scope.submitSpinner = false;

                    //Hide loading spinner and show confirm button text
                    $('.button-text').css('display', 'block');
                    $('.button-spinner').css('display', 'none');

                    //Enable submit button
                    $('#submit').prop("disabled",false);
                }).then(function (result2) {

                    //Set the new access token and reinitialize the feature set
                    TokenService.setAccessToken(result2.access_token);

                    DataService.fetchMetaData("products/features").then(function (result3) {

                        FeatureService.initFeatures(result3);

                        //Have to enable autobilling if purchase is made
                        $scope.user.data.is_autobilled.value = "1";

                        DataService.submit('user', $scope.user).then(function (result4) {

                            $scope.submitSpinner = false;
                            $scope.user = result4.user;

                            //Store subscription object for use in next modal
                            $rootScope.descriptionSubscription = description;

                            $rootScope.modalOpen = false;
                            $mdDialog.hide();
                        });
                    });
                });
            });
        }
    };

    $scope.successCallbackPaymentCreate = function(payload, deviceData) {

        if(!$scope.submitSpinner) {

            $scope.submitSpinner = true;
            $scope.isDefault = true;

            //Register client's banking details - set as preferred if selected
            var data = {
                "nonce": payload.nonce,
                "device_data": deviceData,
                "is_default": true
            };

            DataService.paymentMethod('create', data).then(function (result) {

                $rootScope.isDefault = true;

                //Set user is_autobilled to true and update user
                $scope.user.data.is_autobilled.value = "1";

                DataService.submit('user', $scope.user).then(function (result) {

                    $scope.submitSpinner = false;
                    $scope.user = result.user;
                    $scope.prePublish();
                });
            });
        }
    };

    $scope.goToStep = function(step) {

        $scope.checkoutStep = step;
    };

    $scope.step = function(purchase, description) {

        switch($scope.checkoutStep) {

            //Credit upsell
            case 2:

                $rootScope.showStepLoadingSpinner = true;

                if(purchase) {

                    $rootScope.descriptionTopup = description;
                    $scope.checkoutType = 'Topup';
                    $scope.checkoutStep = 4;

                    DataService.fetchBrainTreeClientToken().then(function(result) {

                        var brainTreeClientToken = result.token;

                        initiateBrainTree(brainTreeClientToken, '.topup-form', $scope.errorCallback, $scope.successCallbackTopup);
                        $rootScope.showStepLoadingSpinner = false;
                    });
                } else {

                    $scope.checkoutStep = 3;
                }

                break;

            //Sub upsell
            case 3:

                $rootScope.showStepLoadingSpinner = true;
                $scope.checkoutStep = 4;

                if(purchase) {

                    $rootScope.descriptionSubscription = description;
                    $scope.checkoutType = 'Sub';

                    if(RoleService.allowed(CONSTANTS.RIGHTS.PURCHASE_PULSE_EDIT_SUB_PAYMENT, true)) {

                        DataService.fetchBrainTreeClientToken().then(function (result) {

                            var brainTreeClientToken = result.token;

                            initiateBrainTree(brainTreeClientToken, '.subs-form', $scope.errorCallback, $scope.successCallbackSubs);
                            $rootScope.showStepLoadingSpinner = false;
                        });
                    }
                } else {

                    $rootScope.descriptionTopup = null;
                    $rootScope.descriptionSubscription = null;
                    $scope.checkoutType = 'Direct';

                    DataService.fetchBrainTreeClientToken().then(function(result) {

                        var brainTreeClientToken = result.token;

                        initiateBrainTree(brainTreeClientToken, '.direct-form', $scope.errorCallback, $scope.successCallbackPaymentCreate);
                        $rootScope.showStepLoadingSpinner = false;
                    });
                }

                break;
        }
    };

    $scope.toFloat = function(string) {

        return parseFloat(string);
    };

    $scope.closeDialog = function(){

        $mdDialog.hide();
    };
}]);
