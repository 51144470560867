/**
 * Created by haneez on 21/01/16.
 */
 onePulse.controller('QuestionController', ['$scope', '$rootScope', '$mdDialog', '$location', '$filter',
     '$compile', 'DataService', 'FeatureService', '$window', 'CONSTANTS',
     function($scope, $rootScope, $mdDialog, $location, $filter,
              $compile, DataService, FeatureService, $window, CONSTANTS) {

         //Toggle for advanced options
         $scope.advancedOptions = false;

         var isPreviewPulse = function() {

             return ($location.path().split('/')[1] === 'store');
         };

         $scope.repulse = function($event, answerToken) {

             if(FeatureService.allowed(CONSTANTS.FEATURES.CHAINED_PULSING, true)) {

                 $rootScope.renderComplete = false;
                 $rootScope.loadingStateText = "re-Pulsing";

                 DataService.repulse(answerToken, 'answer').then(function(result) {

                     $rootScope.renderComplete = true;
                     $rootScope.loadingStateText = "";

                     $window.location = '/pulse/' + result.pulse.data.id.value + '/edit';
                 });
             }
         };

         $scope.showRelatedPulses = function($event, question) {
             
             $location.path('/pulses').search({pulse: $scope.pulse.data.id.value, question: question.data.id.value});
         };

         //Only show branching and editing options for a full Pulse - not for Pulse Lites
         $scope.isFullPulse = function() {

             //If we are not at root, not in the store or viewing a shared pulse then we can display repulse control
             return ($location.url() !== '/' && $location.path().split('/')[1] !== 'store' && $location.path().split('/')[1] !== 'share');
         };

        $scope.$on('event:pulse-saved',function(){

             $scope.validAnswers = $filter('validAnswer')($scope.question.data.answers);

             $scope.validAnswerCount = $scope.validAnswers.length;
         });
 }]);
