/**
 * Created by mike on 23/01/16.
 */
onePulse.directive('questionFilters', ['$sce', 'FormService',
    function($sce, FormService) {

    var $service = FormService;

    linkFunction = function($scope, element, attributes){

        /*
         Put the question data through the FormService and get all the rules and fields
         */
        $scope.form = $service.parseData($scope.question.data);

        $scope.answers = $scope.question.data.answers;

    }

    return {
        link:linkFunction,
        templateUrl:'/app/views/elements/question-filters.html'
    }
}]);
