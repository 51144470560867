/**
 * Created by mike on 08/12/15.
 *
 * BW: Notes - Both mdDialog & ngDialog have to be used as only a single mdDialog modal may be open at one time and
 * as we are using a modal for topups and subs, as well as the feature lock (which will appear above the already open
 * modal), we need both libs
 *
 */
onePulse.controller('UserController', ['$scope', '$location', 'DataService', 'FormService', '$routeParams', '$mdDialog', 'ngDialog', '$q',
	'$translate', '$window', '$rootScope', 'LoginService', 'AlertService', '$timeout', 'FeatureService', 'CONSTANTS', 'DocumentService', '$route',
	'FileSaver', 'Blob', 'TokenService', 'ForexService', 'Config', 'ChartService', 'RoleService','AuthService',
	function ($scope, $location, DataService, FormService, $routeParams, $mdDialog, ngDialog, $q,
			  $translate, $window, $rootScope, LoginService, AlertService, $timeout, FeatureService, CONSTANTS, DocumentService, $route,
			  FileSaver, Blob, TokenService, ForexService, Config, ChartService, RoleService, AuthService) {

	$scope.action = $routeParams.settings;

	//Grab VAT
	$scope.vat = CONSTANTS.VAT;

	//Control for submit button spinner and loader
	$scope.submitSpinner = false;
	$scope.loadingSpinner = true;

	//For displaying in upgrade modal
	$scope.currentLockedFeature = $rootScope.currentLockedFeature;
	$scope.currentLockedProduct = null;

	//Boolean for setting preferred payment method
	$scope.isDefault = $rootScope.isDefault;

	//Toggle control for subscription modal tabs
	$scope.tabView = 'subs';

	$scope.toggling = false;

	//Grab current user's features
	$scope.features = FeatureService.getFeatures();

	//Control vars for form edit states
	// $scope.editUserInfo = false;
	// $scope.editPassword = false;
	// $scope.editCompanyInfo = false;

	$scope.currency = null;
	$scope.toggleState = "USD";
        
    //FF: Spinner for Credit Chart
    $scope.loadingSpinnerChart = true;

    //Is reseller client: Requires purchase manager to purchase credits
	$scope.isResellerClient = false;

	//Set the user's current subscription
	$scope.setSubscription = function() {

		$scope.currentSubscription = {};

		if(typeof $scope.user.data.current_subscription !== "undefined") {

			$scope.currentSubscription.plan = $scope.user.data.current_subscription.data.description.value;
			$scope.currentSubscription.status = "Active";

			//This root var is also used for upgrading, so only update here (controller init) if it is null
			if($rootScope.descriptionSubscription == null) {

				$rootScope.descriptionSubscription = $scope.user.data.current_subscription.data.description.value;
			}
		}
	};

	$scope.allowUserManagement = function() {

        //Check user management display rights
        return RoleService.allowed(CONSTANTS.RIGHTS.EDIT_USER_WORKSPACE, false);
    };

	$scope.allowCustomization = function() {

		if(FeatureService.allowed(CONSTANTS.FEATURES.WHITE_LABEL, false)) {

			//Check user management display rights
			return RoleService.allowed(CONSTANTS.RIGHTS.CUSTOMIZATION, false);
		}

		return false;
    };

	$scope.allowReferrals = function() {

		//Check reseller management display rights
		return typeof $scope.user.data !== "undefined" ? $scope.user.data.is_referrals_disabled.value === false : false;
	};

    $scope.allowResellerManagement = function() {

        //Check reseller management display rights
        return typeof $scope.user.data !== "undefined" ? $scope.user.data.is_master_reseller.value === "1" : false;
    };

    $scope.allowPaymentMethod = function() {

        //Check payment method update rights
        return RoleService.allowed(CONSTANTS.RIGHTS.PURCHASE_PULSE_EDIT_SUB_PAYMENT, false);
    };

	$scope.determinePercentageResponsesUsed = function() {

		$scope.percentageResponses = (parseInt($scope.user.data.balance.value) / parseInt($scope.user.data.total_purchased_balance.value)) * 100;
	};

	$scope.loadingSpinner = true;

	$scope.canUpgrade = function(subscription) {

		if(typeof subscription != "undefined") {

			return ($scope.user.data.current_subscription.data.display_price.value != "" ? (parseFloat($scope.user.data.current_subscription.data.display_price.value) < parseFloat(subscription.data.display_price.value) && parseFloat(subscription.data.display_price.value) > 0.00) : true);
		}
	};

	$scope.sendSubContactMail = function() {

        $window.open("mailto:info@onepulse.com","_self");
    };

	//Initialise subscription features
	var initSubscriptionFeatures = function() {

		DataService.fetchMetaData("products/features").then(function(result) {

			var featuresTemp = [];
			var featuresKeys = [];

			for (var key in result) {

				if (typeof result[key].is_active != "undefined") {

					if (typeof result[key].key != "undefined") {

						result[key].key.value = removeCharsAfterChar(':', result[key].key.value);

						if ($scope.currentLockedFeature == result[key].key.value) {

							$scope.currentLockedProduct = result[key];
							$scope.currentLockedProduct.description.value = removeCharsAfterChar(':', $scope.currentLockedProduct.description.value);
						}

						if (result[key].is_active.value == '1' && featuresKeys.indexOf(result[key].key.value) == -1) {

							if (typeof result[key].display_order != "undefined" && typeof result[key].description != "undefined") {

								result[key].display_order.value = parseInt(result[key].display_order.value);
								result[key].description.value = removeCharsAfterChar(':', result[key].description.value);
							}

							featuresKeys.push(result[key].key.value);
							featuresTemp.push(result[key])
						}
					}
				}
			}

			var featuresTempOrdered = featuresTemp.slice(0);

			featuresTempOrdered.sort(function (a, b) {

				return a.display_order.value - b.display_order.value;
			});

			$scope.features = featuresTempOrdered;

			var tempSubscriptions = angular.copy($scope.subscriptions);

			for (var i = 0; i < tempSubscriptions.length; i++) {

				if (tempSubscriptions[i].data.cost.value != "") {

					tempSubscriptions[i].data.cost.value = thousandSeparator([tempSubscriptions[i].data.cost.value], true);
				}

				featuresTemp = [];
				featuresKeys = [];

				for (var key in tempSubscriptions[i].data.features) {

					if (typeof tempSubscriptions[i].data.features[key].is_active != "undefined") {

						if (tempSubscriptions[i].data.features[key].is_active.value == '1' && featuresKeys.indexOf(tempSubscriptions[i].data.features[key].key.value) == -1) {

							tempSubscriptions[i].data.features[key].featureDetail = {"value": null};

							//Check description for meta data
							if (tempSubscriptions[i].data.features[key].description.value.indexOf(':') != -1) {

								tempSubscriptions[i].data.features[key].featureDetail.value = tempSubscriptions[i].data.features[key].description.value.split(':')[1].trim();
								tempSubscriptions[i].data.features[key].description.value = tempSubscriptions[i].data.features[key].featureDetail.value + ' ' + tempSubscriptions[i].data.features[key].description.value;
							}

							//Check key for meta data - key takes precedence over description
							if (tempSubscriptions[i].data.features[key].key.value.indexOf(':') != -1) {

								tempSubscriptions[i].data.features[key].featureDetail.value = tempSubscriptions[i].data.features[key].key.value.split(':')[1];
							}

							tempSubscriptions[i].data.features[key].blank = {"value": false};

							tempSubscriptions[i].data.features[key].key.value = removeCharsAfterChar(':', tempSubscriptions[i].data.features[key].key.value);
							tempSubscriptions[i].data.features[key].display_order.value = parseInt(tempSubscriptions[i].data.features[key].display_order.value);
							tempSubscriptions[i].data.features[key].description.value = removeCharsAfterChar(':', tempSubscriptions[i].data.features[key].description.value);

							featuresKeys.push(tempSubscriptions[i].data.features[key].key.value);
							featuresTemp.push(tempSubscriptions[i].data.features[key])
						}
					}
				}

				featuresTempOrdered = featuresTemp.slice(0);

				featuresTempOrdered.sort(function (a, b) {

					return a.display_order.value - b.display_order.value;
				});

				var count = featuresTempOrdered.length;

				for (var k = 0; k < ($scope.features.length - count); k++) {

					var item = {"blank": {"value": true}, "description": {"value": "Blank"}};

					featuresTempOrdered.push(item);
				}

				tempSubscriptions[i].data.features = featuresTempOrdered
			}

			$scope.subscriptions = tempSubscriptions;
		});
	};

	var setOriginalSubValues = function() {

		$scope.original = [];

		for (var i = 0; i < $scope.subscriptions.length; i++) {

			$scope.original[i] = {};

			$scope.original[i].display_price = angular.copy($scope.subscriptions[i].data.display_price.value);
			$scope.original[i].equivalent_value = angular.copy($scope.subscriptions[i].data.equivalent_value.value);
			$scope.original[i].response_value = angular.copy($scope.subscriptions[i].data.response_value.value);
			$scope.original[i].cost = angular.copy($scope.subscriptions[i].data.cost.value);
		}
	};

	DataService.fetchItem('user').then(function(result) {

		$scope.user = result.user;

		//NB: Needs to be a property of another object - md-select will only bind to a model with dot notation: https://github.com/angular/material/issues/1714
		$scope.select = {selectedIndustry : $scope.setSelectedIndustry()};

		//If the user is the purchase manager for this workspace, allow credit purchases on invoice
		if(RoleService.getRole() === CONSTANTS.ROLES.CLIENT_PURCHASE_MANAGER.label) {

			$scope.payment = 'invoice';
		} else {

			$scope.payment = ($scope.user.data.last_4.value != '' && $scope.user.data.last_4.value != null)?'preferred':'add';
		}

		$scope.setSubscription();

		$scope.determinePercentageResponsesUsed();

		$scope.determineMasterReseller();

        $scope.topups = DataService.grab('topups');

        if(!$scope.topups) {

            DataService.fetchMetaData("topups").then(function(result) {

                $scope.topups = result.topups;
                $scope.processCost($scope.topups);
                $scope.setSelectedTopup();
                $scope.loadingSpinner = false;
            });
        } else {

            $scope.processCost($scope.topups);
            $scope.setSelectedTopup();
            $scope.loadingSpinner = false;
        }

		DataService.fetchMetaData("subscriptions").then(function(result) {
            
			result.subscriptions.sort(function(a,b) {

				return a.data.display_order.value - b.data.display_order.value;
			});

			$scope.subscriptions = result.subscriptions;

			$scope.processCost($scope.subscriptions);
			$scope.setSelectedSubscription();
			setOriginalSubValues();
			initSubscriptionFeatures();

			// ForexService.getLocalCurrency().then(function(result) {
			//
			// 	$scope.currency = result;
			//
			// 	ForexService.getExchangeRate("USD", result.code);
			//
			// });

            $scope.currency = ForexService.setCurrency('GB');
            ForexService.getExchangeRate("USD", $scope.currency.code);
            $scope.loadingSpinner = false;
		});

        
		//Circliful init needs to be here for every load/reload of the controller
		if($scope.action == 'credits') {
            
			if(!$rootScope.modalOpen) {

				var percentages = [];
				var percentage = null;
				var totalCredited = 0;

                if($scope.user.data.current_subscription.data.responses_credit.value > 0) {

                    totalCredited += parseInt($scope.user.data.current_subscription.data.responses_credit.value);
                }

                for(var j = 0; j < $scope.user.data.current_topups.topups.length; j++) {

                    if($scope.user.data.current_topups.topups[j].data.respones_balance.value != '0' && $scope.user.data.current_topups.topups[j].data.is_active.value == '1') {

                        totalCredited += parseInt($scope.user.data.current_topups.topups[j].data.respones_credited.value);
                    }
                }

                if(parseInt($scope.user.data.balance.value) < totalCredited) {

                    percentage = (totalCredited - parseInt($scope.user.data.balance.value)) * (100 / totalCredited);

                    percentages.push({
                        'percent': percentage,
                        'color': 'grey',
                        'title': 'Used credit: ' + (totalCredited - parseInt($scope.user.data.balance.value)).toString() + '/' + totalCredited
                    });
                }

				if($scope.user.data.current_subscription.data.responses_credit.value > 0) {

					percentage = $scope.user.data.current_purchased_subscription.data.responses_balance.value * (100 / totalCredited);

					percentages.push({
						'percent': percentage,
						'color': $scope.user.data.current_subscription.data.color.value,
						'title': $scope.user.data.current_purchased_subscription.data.responses_balance.value + '/' + $scope.user.data.current_subscription.data.responses_credit.value
					});
				}

				for(var i = 0; i < $scope.user.data.current_topups.topups.length; i++) {

					if($scope.user.data.current_topups.topups[i].data.respones_balance.value != '0' && $scope.user.data.current_topups.topups[i].data.is_active.value == '1') {

						percentage = parseInt($scope.user.data.current_topups.topups[i].data.respones_balance.value) * (100 / totalCredited);

						percentages.push({
							'percent'	: percentage,
							'color'		: $scope.user.data.current_topups.topups[i].data.color.value,
							'title'		: $scope.user.data.current_topups.topups[i].data.respones_balance.value + '/' + $scope.user.data.current_topups.topups[i].data.respones_credited.value
						});
					}
				}

				//Limit circliful display to only show sub and first 3 packs otherwise it gets way too crowded
				percentages = percentages.slice(0, 3);
			}
            
            
			$scope.donutChart = ChartService.buildDonutChart(percentages, "Pack", "Credits");
            
            $timeout(function() {
                $window.dispatchEvent(new Event("resize"));
                $scope.loadingSpinnerChart = false;
            }, 2000);
            
		}

//		$scope.loadingSpinner = false;
	});

	$scope.$watch('isDefault', function (newVal, oldVal) {

		$rootScope.isDefault = newVal;
	});

	$scope.$on("$locationChangeStart", function (event, next, current) {

		$rootScope.currency = Config.get('I18N_CURRENCY');
	});

	$scope.determineMasterReseller = function() {

		if($scope.user.data.master_reseller_id.value !== null && $scope.user.data.master_reseller_id.value !== "" && $scope.user.data.master_reseller_id.value != '0') {

			$scope.isResellerClient = true;
		}

		$scope.purchaseManager = null;

		for(var i = 0; i <= $scope.user.data.client_users.length; i++) {

			if(typeof $scope.user.data.client_users[i] !== 'undefined') {

				for (var j = 0; j <= $scope.user.data.client_users[i].workspaces.length; j++) {

					if (typeof $scope.user.data.client_users[i].workspaces[j] !== 'undefined') {

						if ($scope.user.data.client_users[i].workspaces[j].code.value === $scope.user.data.client_code.value
							&& $scope.user.data.client_users[i].workspaces[j].role.value === CONSTANTS.ROLES.CLIENT_PURCHASE_MANAGER.label) {

							$scope.purchaseManager = $scope.user.data.client_users[i].email.value;
						}
					}
				}
			}
		}
	};

	$scope.purchaseManagerPopup = function() {

		$translate(['alert.request_credits.title', 'alert.request_credits.text']).then(function(result) {

			var translations = result;

			AlertService.alert(
				translations['alert.request_credits.title'],
				translations['alert.request_credits.text']
			)
		});
	};

	$scope.lockedFeatureCheck = function(description, locked) {

		return((locked == 'Logins' && description.indexOf(locked) != -1) ||
			(locked == 'Pulse Size' && description.indexOf(locked) != -1) ||
			(locked == 'Pulse Store Discount' && description.indexOf(locked) != -1) ||
			(locked == description));
	};

	$scope.toggleCurrency = function(currencyToCode) {

		$rootScope.currency = (currencyToCode == "USD" ? "$" : $scope.currency.symbol);

		$scope.toggleState = currencyToCode;

		for(var i = 0; i < $scope.subscriptions.length; i++) {

			$scope.subscriptions[i].data.display_price.value = (currencyToCode == "USD" ? parseFloat($scope.original[i].display_price) : (parseFloat($scope.subscriptions[i].data.display_price.value) * $scope.currency.baseMultiplier).toFixed(2));
			$scope.subscriptions[i].data.equivalent_value.value = (currencyToCode == "USD" ? parseFloat($scope.original[i].equivalent_value) : (parseFloat($scope.subscriptions[i].data.equivalent_value.value) * $scope.currency.baseMultiplier).toFixed(2));
			$scope.subscriptions[i].data.response_value.value = (currencyToCode == "USD" ? parseFloat($scope.original[i].response_value) : (parseFloat($scope.subscriptions[i].data.response_value.value) * $scope.currency.baseMultiplier).toFixed(2));
			$scope.subscriptions[i].data.cost.value = (currencyToCode == "USD" ? parseFloat($scope.original[i].cost) : (parseFloat($scope.subscriptions[i].data.cost.value) * $scope.currency.baseMultiplier).toFixed(2));
		}
	};

	$scope.togglePayment = function(payment) {

		$scope.payment = payment;
	};

	//Toggle all training overlays
	$scope.toggleTrainingDisplayed = function() {

		//Toggling all overlay vars
		$window.localStorage['showPanelsTrainingOverlay'] = $scope.user.data.is_web_training_displayed.value;
		$window.localStorage['showDashboardTrainingOverlay'] = $scope.user.data.is_web_training_displayed.value;

		DataService.submit('user', $scope.user).then(function (result) {

			$scope.user = result.user;
		});
	};

	//Add 30 days to created_at date to return the expiry date of a subscription's response credits
	$scope.calculateExpirySub = function(purchasedSub) {

        if (purchasedSub.data.rollover_day.value === "") {

            if (typeof purchasedSub.data.created_at.value !== "undefined") {

                var dateString = purchasedSub.data.created_at.value.split(" ")[0];

                var dateObj = new Date(dateString);

                dateObj.setDate(dateObj.getDate() + 30);

                return simpleDate(dateObj);
            }
        } else {

            var currentDate = new Date();
            var currentDay = currentDate.getDate();
            var expiredDay = purchasedSub.data.rollover_day.value;
            var expiredMonth = currentDate.getMonth() + 1;
            var expiredYear = currentDate.getFullYear();

            if(expiredDay < currentDay) {

                if(currentDate.getMonth() != 11) {

                    expiredMonth = currentDate.getMonth() + 2;
                } else {

                    expiredMonth = 1;
                    expiredYear += 1;
                }
            }
            
            var expiredDate = expiredYear.toString() + '-' + expiredMonth.toString() + '-' + expiredDay.toString();

            var dateObj = new Date(expiredDate);

            return simpleDate(dateObj);
        }
	};

	//Add 1 year to created_at date to return the expiry date of a topup's response credits
	$scope.calculateExpiryTopup = function(createdAt) {

		if (typeof createdAt != "undefined") {

			var dateString = createdAt.split(" ")[0];

			var dateObj = new Date(dateString);

			dateObj.setDate(dateObj.getDate() + 365);

			return simpleDate(dateObj);
		}

	};
        
	$scope.calculateTax = function(amount) {

		return $rootScope.chargeVat ? parseFloat(amount * (CONSTANTS.VAT / 100)).toFixed(2) : 0.00;
	};

	$scope.setSelectedTopup = function() {

		if (typeof $rootScope.descriptionTopup !== "undefined" && $rootScope.descriptionTopup != null) {

			if($rootScope.descriptionTopup == "autoBill") {

				$scope.selectedTopup = $rootScope.descriptionTopup;
			} else {

				for (var i = 0; i < $scope.topups.length; i++) {

					if ($scope.topups[i].data.description.value == $rootScope.descriptionTopup) {

						$scope.selectedTopup = $scope.topups[i];
					}
				}
			}
			$rootScope.descriptionTopup = null;
		} else {

			$scope.selectedTopup = $scope.topups[0];
		}
	};

	$scope.setSelectedSubscription = function() {

		if (typeof $rootScope.descriptionSubscription !== "undefined" && $rootScope.descriptionSubscription != null) {

			for (var i = 0; i < $scope.subscriptions.length; i++) {

				if ($scope.subscriptions[i].data.description.value == $rootScope.descriptionSubscription) {

					$scope.selectedSubscription = $scope.subscriptions[i];
				}
			}

			$rootScope.descriptionSubscription = null;
		}
	};

	$scope.processCost = function(obj) {

		for(var i = 0; i < obj.length; i++) {

			if(obj[i].data.cost.value != "") {

				obj[i].data.cost.value = thousandSeparator([obj[i].data.cost.value], true);
			}
		}
	};

	$scope.isActive = function (viewLocation) {

		return (viewLocation === $location.path());
	};

	//Modal for subscription type selection
	$scope.popupChangePlanSelectionDialog = function() {

		$rootScope.modalOpen = true;

		$mdDialog.show({
			controller: 'UserController',
			templateUrl: 'app/views/elements/includes/plan-subscription.html',
			// fullscreen: true
		});
	};

	//Modal for subscription payment
	$scope.popupChangePlanPaymentDialog = function() {

		$scope.closeDialog();

        if(RoleService.allowed(CONSTANTS.RIGHTS.PURCHASE_PULSE_EDIT_SUB_PAYMENT, true)) {

            $rootScope.modalOpen = true;

            $mdDialog.show({
                controller: 'UserController',
                templateUrl: 'app/views/elements/includes/plan-subscription-payment.html',
                clickOutsideToClose: false
            });

            DataService.fetchBrainTreeClientToken().then(function (result) {

                var brainTreeClientToken = result.token;

                initiateBrainTree(brainTreeClientToken, '.subs-form', $scope.errorCallback, $scope.successCallbackSubs);
            });
        }
	};

	//Set the subscription for payment
	$scope.selectSubscription = function(subscriptionDescription) {

		ngDialog.close();

		$rootScope.descriptionSubscription = subscriptionDescription;

		$scope.popupChangePlanPaymentDialog();
	};

	//Modal for Topup payment
	$scope.popupTopupDialog = function(ev) {

		$rootScope.modalOpen = true;

		if($scope.user.data.is_autobilled.value == "0") {

			$mdDialog.show({
				controller: 'BillingController',
				templateUrl: 'app/views/elements/includes/billing-options.html',
				clickOutsideToClose: true
			});
		} else {

			$mdDialog.show({
				controller: 'UserController',
				templateUrl: 'app/views/elements/includes/plan-topup.html',
				clickOutsideToClose: false
			});

			DataService.fetchBrainTreeClientToken().then(function(result) {

				var brainTreeClientToken = result.token;

				initiateBrainTree(brainTreeClientToken, '.topup-form', $scope.errorCallback, $scope.successCallbackTopup);
			});
		}
	};

	$scope.updatePaymentMethod = function(action) {

		$rootScope.modalOpen = true;

		var template = (action == 'add' ? 'app/views/elements/includes/add-payment-method.html' : 'app/views/elements/includes/update-payment-method.html' );

		$mdDialog.show({
			controller: 'UserController',
			templateUrl: template,
			clickOutsideToClose: false
		});

		DataService.fetchBrainTreeClientToken().then(function(result) {

			var brainTreeClientToken = result.token;

			initiateBrainTree(brainTreeClientToken, '.topup-form', $scope.errorCallback, $scope.successCallbackPaymentCreate);
		});
	};

	$scope.deletePaymentMethod = function() {

		$translate(['alert.delete_payment_method.title', 'alert.delete_payment_method.text']).then(function(result) {
			var translations = result;

			AlertService.confirm(
				translations['alert.delete_payment_method.title'],
				translations['alert.delete_payment_method.text'],
				function () {

					$scope.submitSpinner = true;

					$rootScope.fadedOverlay = true;
					$rootScope.loadingStateText = "";

					var data = {};

					DataService.paymentMethod('delete', data).then(function(result) {

						//Have to disable autobilling if payment method is removed
						$scope.user.data.is_autobilled.value = "0";

						DataService.submit('user', $scope.user).then(function (result) {

							$scope.submitSpinner = false;
							$scope.user = result.user;
							$scope.closeDialogAndReload();
						});
					});
				}
			);
		});
	};

	//Create a pulse (After top up/subscription upgrade)
	$scope.createPulse = function(){

		$mdDialog.hide();

        if(RoleService.allowed(CONSTANTS.RIGHTS.CREATE_EDIT_PULSE, AuthService.isLoggedIn())) {

            DataService.create('pulse').then(function (result) {

                $location.path('/pulse/' + result.pulse.data.id.value + '/edit');
            });
        }
	};

	//Close mdDialog
	$scope.closeDialog = function(){

		$rootScope.currentLockedFeature = null;
		$rootScope.modalOpen = false;
		$mdDialog.hide();
	};

	//Close ngDialog
	$scope.closeNgDialog = function(){

		$rootScope.currentLockedFeature = null;
		ngDialog.close();
	};

	//Close mdDialog and reload route - need to use $window so that page refreshes
	$scope.closeDialogAndReload = function(){

		$rootScope.currentLockedFeature = null;
		$rootScope.modalOpen = false;
		$mdDialog.hide();
		$rootScope.fadedOverlay = false;
		$rootScope.loadingStateText = "";
		$window.location = 'workspace/plans';
	};

	//Error callback for Braintree
	$scope.errorCallback = function() {

		var translations;
		$translate(['alert.card_details_error.title', 'alert.card_details_error.text']).then(function(result) {
			translations = result;

			AlertService.error(
				translations['alert.card_details_error.title'],
				translations['alert.card_details_error.text']
			);
		});
	};

	//Success callback for Braintree - Topup payment
	$scope.successCallbackTopup = function(payload, deviceData) {

		if(!$scope.submitSpinner) {

			$scope.submitSpinner = true;

			var data_token = $('#selectedDataToken').val();
			var description = $('#selectedDescription').val();
			var responsesCredited = $('#selectedResponsesCredited').val();

			// Submit payload.nonce and other data
			var data = {
				"nonce": payload.nonce,
				"device_data": deviceData,
				"data_token": data_token
			};

			DataService.profileClient("topups", data, "/purchase", function() {

				$scope.submitSpinner = false;

				//Hide loading spinner and show confirm button text
				$('.button-text').css('display', 'block');
				$('.button-spinner').css('display', 'none');

				//Enable submit button
				$('#submit').prop("disabled",false);
			}).then(function (result) {

				//Have to enable autobilling if purchase is made
				$scope.user.data.is_autobilled.value = "1";

				DataService.submit('user', $scope.user).then(function (result) {

					$scope.submitSpinner = false;
					$scope.user = result.user;

					//Store topup object for use in next modal
					$rootScope.descriptionTopup = description;

					$rootScope.modalOpen = true;

					var translations;
					$translate(['alert.successful_topup.title', 'alert.successful_topup.text']).then(function (result) {
						translations = result;

						AlertService.success(
							translations['alert.successful_topup.title'],
							responsesCredited + ' ' + translations['alert.successful_topup.text']
						);
					});

					$mdDialog.show({
						controller: 'UserController',
						templateUrl: 'app/views/elements/includes/plan-topup-complete.html',
						clickOutsideToClose: false
					});
				});
			});
		}
	};

	//Success callback for Braintree - Subscription payment
	$scope.successCallbackSubs = function(payload, deviceData) {

		if(!$scope.submitSpinner) {

			$scope.submitSpinner = true;

			var data_token = $('#selectedDataToken').val();
			var description = $('#selectedDescription').val();

			// Submit payload.nonce and other data
			var data = {
				"nonce": payload.nonce,
				"device_data": deviceData,
				"data_token": data_token
			};

			DataService.profileClient("subscription", data, "/purchase", function() {

				$scope.submitSpinner = false;

				//Hide loading spinner and show confirm button text
				$('.button-text').css('display', 'block');
				$('.button-spinner').css('display', 'none');

				//Enable submit button
				$('#submit').prop("disabled",false);
			}).then(function (result) {

				data = {
					"braintree_subscription_key": result.braintree_subscription_key
				};

				DataService.profileClient("subscription", data, "/status", function() {

					$scope.submitSpinner = false;

					//Hide loading spinner and show confirm button text
					$('.button-text').css('display', 'block');
					$('.button-spinner').css('display', 'none');

					//Enable submit button
					$('#submit').prop("disabled",false);
				}).then(function (result2) {

					//Set the new access token and reinitialize the feature set
					TokenService.setAccessToken(result2.access_token);

					DataService.fetchMetaData("products/features").then(function (result3) {

						FeatureService.initFeatures(result3);

						//Have to enable autobilling if purchase is made
						$scope.user.data.is_autobilled.value = "1";

						DataService.submit('user', $scope.user).then(function (result4) {

							$scope.submitSpinner = false;
							$scope.user = result4.user;

							//Store subscription object for use in next modal
							$rootScope.descriptionSubscription = description;

							$rootScope.modalOpen = true;

							$mdDialog.show({
								controller: 'UserController',
								templateUrl: 'app/views/elements/includes/plan-subscription-complete.html',
								clickOutsideToClose: false
							});
						});
					});
				});
			});
		}
	};

	$scope.successCallbackPaymentCreate = function(payload, deviceData) {

		if(!$scope.submitSpinner) {

			$scope.submitSpinner = true;
			$scope.isDefault = true;

			//Register client's banking details - set as preferred if selected
			var data = {
				"nonce": payload.nonce,
				"device_data": deviceData,
				"is_default": true
			};

			DataService.paymentMethod('create', data).then(function (result) {

				$rootScope.isDefault = true;

				//Set user is_autobilled to true and update user
				$scope.user.data.is_autobilled.value = "1";

				DataService.submit('user', $scope.user).then(function (result) {

					$scope.submitSpinner = false;
					$scope.user = result.user;
					$scope.closeDialogAndReload();
				});
			});
		}
	};

	$scope.payForSubWithPreferred = function(event) {

		if(!$scope.submitSpinner) {

			$scope.submitSpinner = true;

			var data_token = $('#selectedDataToken').val();
			var description = $('#selectedDescription').val();

			// Submit payload.nonce and other data
			var data = {
				"data_token": data_token
			};

			DataService.profileClient("subscription", data, "/purchase", function() {

				$scope.submitSpinner = false;
			}).then(function (result) {

				data = {
					"braintree_subscription_key": result.braintree_subscription_key
				};

				DataService.profileClient("subscription", data, "/status", function() {

					$scope.submitSpinner = false;
				}).then(function (result2) {

					//Set the new access token and reinitialize the feature set
					TokenService.setAccessToken(result2.access_token);

					DataService.fetchMetaData("products/features").then(function (result3) {

						FeatureService.initFeatures(result3);

						//Have to enable autobilling if purchase is made
						$scope.user.data.is_autobilled.value = "1";

						DataService.submit('user', $scope.user).then(function (result4) {

							$scope.submitSpinner = false;
							$scope.user = result4.user;

							//Store subscription object for use in next modal
							$rootScope.descriptionSubscription = description;

							$rootScope.modalOpen = true;

							$mdDialog.show({
								controller: 'UserController',
								templateUrl: 'app/views/elements/includes/plan-subscription-complete.html',
								clickOutsideToClose: false
							});
						});
					});
				});
			});
		}
	};

	//Pay for topup package with preferred payment method
	$scope.payWithPreferred = function(event) {

		if(!$scope.submitSpinner) {

			$scope.submitSpinner = true;

			if($scope.selectedTopup == 'autoBill') {

				//Set user is_autobilled to true and update user
				$scope.user.data.is_autobilled.value = "1";

				DataService.submit('user', $scope.user).then(function (result) {

					$scope.submitSpinner = false;
					$scope.user = result.user;

					//If we are not coming from /workspace/plans we need to call pre-publish
					if($scope.action != 'plans') {

						$rootScope.modalOpen = false;
						$mdDialog.hide();
						$timeout(function() {
							angular.element('#schedulePulse').triggerHandler('click');
						}, 0);
					} else {

						$scope.closeDialogAndReload();
					}
				});
			} else {

				var data_token = $('#selectedDataToken').val();
				var description = $('#selectedDescription').val();
				var responsesCredited = $('#selectedResponsesCredited').val();

				// Submit data_token of chosen topup
				var data = {
					"data_token": data_token
				};

				DataService.profileClient("topups", data, "/purchase", function() {

					$scope.submitSpinner = false;
				}).then(function (result) {

					//Have to enable autobilling if purchase is made
					$scope.user.data.is_autobilled.value = "1";

					DataService.submit('user', $scope.user).then(function (result) {

						$scope.submitSpinner = false;
						$scope.user = result.user;

						//If we are not coming from /workspace/plans we need to call pre-publish
						if ($scope.action != 'plans') {

							$rootScope.modalOpen = false;
							$mdDialog.hide();
							$timeout(function () {
								angular.element('#schedulePulse').triggerHandler('click');
							}, 0);
						} else {

							//Store topup object for use in next modal
							$rootScope.descriptionTopup = description;

							$rootScope.modalOpen = true;

							var translations;
							$translate(['alert.successful_topup.title', 'alert.successful_topup.text']).then(function (result) {
								translations = result;

								AlertService.success(
									translations['alert.successful_topup.title'],
									responsesCredited + ' ' + translations['alert.successful_topup.text']
								);
							});

							$mdDialog.show({
								controller: 'UserController',
								templateUrl: 'app/views/elements/includes/plan-topup-complete.html',
								clickOutsideToClose: false
							});
						}
					});
				});
			}
		}
	};

	//Pay for topup package on invoice (Purchase manager role)
	$scope.payOnInvoice = function(event) {

		if(!$scope.submitSpinner) {

			$scope.submitSpinner = true;

			if($scope.selectedTopup == 'autoBill') {

				//Set user is_autobilled to true and update user
				$scope.user.data.is_autobilled.value = "1";

				DataService.submit('user', $scope.user).then(function (result) {

					$scope.submitSpinner = false;
					$scope.user = result.user;

					//If we are not coming from /workspace/plans we need to call pre-publish
					if($scope.action != 'plans') {

						$rootScope.modalOpen = false;
						$mdDialog.hide();
						$timeout(function() {
							angular.element('#schedulePulse').triggerHandler('click');
						}, 0);
					} else {

						$scope.closeDialogAndReload();
					}
				});
			} else {

				var data_token = $('#selectedDataToken').val();
				var description = $('#selectedDescription').val();
				var responsesCredited = $('#selectedResponsesCredited').val();

				// Submit data_token of chosen topup
				var data = {
					"data_token": data_token
				};

				DataService.profileClient("topups", data, "/purchase", function() {

					$scope.submitSpinner = false;
				}).then(function (result) {

					//Have to enable autobilling if purchase is made
					$scope.user.data.is_autobilled.value = "1";

					DataService.submit('user', $scope.user).then(function (result) {

						$scope.submitSpinner = false;
						$scope.user = result.user;

						//If we are not coming from /workspace/plans we need to call pre-publish
						if ($scope.action != 'plans') {

							$rootScope.modalOpen = false;
							$mdDialog.hide();
							$timeout(function () {
								angular.element('#schedulePulse').triggerHandler('click');
							}, 0);
						} else {

							//Store topup object for use in next modal
							$rootScope.descriptionTopup = description;

							$rootScope.modalOpen = true;

							var translations;
							$translate(['alert.successful_topup.title', 'alert.successful_topup.text']).then(function (result) {
								translations = result;

								AlertService.success(
									translations['alert.successful_topup.title'],
									responsesCredited + ' ' + translations['alert.successful_topup.text']
								);
							});

							$mdDialog.show({
								controller: 'UserController',
								templateUrl: 'app/views/elements/includes/plan-topup-complete.html',
								clickOutsideToClose: false
							});
						}
					});
				});
			}
		}
	};

	$scope.toggleAutobill = function() {

		if(!$scope.toggling) {

			$scope.toggling = true;

			DataService.submit('user', $scope.user).then(function (result) {

				$scope.user = result.user;
				$scope.toggling = false;
			});
		}

	};

	//Reset password
	$scope.doResetPasswordRequest = function() {

		var translations;

		if($scope.user.newPassword == '' || typeof $scope.user.newPassword == "undefined") {

			$translate(['alert.password_change_no_password.title', 'alert.password_change_no_password.text']).then(function(result) {
				translations = result;

				AlertService.warning(
					translations['alert.password_change_no_password.title'],
					translations['alert.password_change_no_password.text']
				);
			});
		} else if($scope.user.newPasswordConfirm == '' || typeof $scope.user.newPasswordConfirm == "undefined") {

			$translate(['alert.password_change_no_confirm_password.title', 'alert.password_change_no_confirm_password.text']).then(function(result) {
				translations = result;

				AlertService.warning(
					translations['alert.password_change_no_confirm_password.title'],
					translations['alert.password_change_no_confirm_password.text']
				);
			});
		} else if($scope.user.newPassword == $scope.user.newPasswordConfirm) {

			$translate(['alert.password_change_request_confirm.title', 'alert.password_change_request_confirm.text']).then(function(result) {
				translations = result;

				AlertService.confirm(
					translations['alert.password_change_request_confirm.title'],
					translations['alert.password_change_request_confirm.text'],
					function () {

						var data = {
							"password": $scope.user.newPassword
						};

						LoginService.resetPassword(data, $window.localStorage.access_token).then(function (api_result) {

							var translations;
							$translate(['alert.password_change.title', 'alert.password_change.text']).then(function (result) {
								translations = result;

								AlertService.success(
									translations['alert.password_change.title'],
									translations['alert.password_change.text']
								);
							});
						});
					}
				);
			});
		} else {

			//Passwords do not match - show alert

			$translate(['alert.password_match_error.title', 'alert.password_match_error.text']).then(function(result) {
				translations = result;

				AlertService.warning(
					translations['alert.password_match_error.title'],
					translations['alert.password_match_error.text']
				);
			});
		}
	};

	//Update User
	$scope.updateUser = function(showModal) {

		var deferred = $q.defer();

		// $scope.editUserInfo = false;
		// $scope.editPassword = false;
		// $scope.editCompanyInfo = false;

		//Update the selected industry
		if($scope.select.selectedIndustry != null) {

			for(var i = 0; i < $scope.user.data.industry.options.length; i++) {

				$scope.user.data.industry.options[i].option.selected = ($scope.user.data.industry.options[i].option.text == $scope.select.selectedIndustry);
			}
		}

		DataService.submit('user', $scope.user).then(function (result) {

			deferred.resolve(result);

			if(showModal) {

				var translations;
				$translate(['alert.user_updated.title', 'alert.user_updated.text']).then(function(result) {
					translations = result;

					AlertService.success(
						translations['alert.user_updated.title'],
						translations['alert.user_updated.text'],
						function() {

							$window.location = 'user/' + $scope.action;
						}
					);
				});
			}
		});

		return deferred.promise;
	};

	//Update the selected industry
	$scope.updateSelectedIndustry = function() {

		if($scope.select.selectedIndustry != null) {

			for(var i = 0; i < $scope.user.data.industry.options.length; i++) {

				$scope.user.data.industry.options[i].option.selected = ($scope.user.data.industry.options[i].option.text == $scope.select.selectedIndustry);
			}
		}
	};

	//Set the selected industry on render
	$scope.setSelectedIndustry = function() {

		if(typeof $scope.user.data.industry != "undefined") {

			for(var i = 0; i < $scope.user.data.industry.options.length; i++) {

				if($scope.user.data.industry.options[i].option.selected) {
					return $scope.user.data.industry.options[i].option.text
				}
			}

			//If no industry has been saved we return an empty string
			return "";
		}
	};

	//Parse total string to float in order to determine total amount including VAT
	$scope.total = function(string) {

		return $rootScope.chargeVat ? parseFloat(parseFloat(string) + (parseFloat(string) * ($scope.vat / 100))).toFixed(2) : parseFloat(string).toFixed(2);
	};

	//Check if a subscription is an upgrade relative to current subscription (Return true if user's current sub no longer exists)
	$scope.checkUpgrade = function(subscription) {

		if(typeof $scope.selectedSubscription != "undefined") {

			return $scope.selectedSubscription.data.cost.value < subscription.data.cost.value
		} else {

			return true;
		}

	};

	//Check if a subscription is a downgrade relative to current subscription (Return false if user's current sub no longer exists)
	$scope.checkDowngrade = function(subscription) {

		if(typeof $scope.selectedSubscription != "undefined") {

			return (subscription.data.cost.value < $scope.selectedSubscription.data.cost.value || subscription.data.cost.value == '')
		} else {

			return false;
		}
	};

	//Update user when image is changed
	var imageSavedListener = $rootScope.$on('event:image-saved', function() {

		$scope.updateUser(false).then(function(result) {

			$timeout(function() {

				$window.location.reload();
			})
		});

		// Remove listener
		imageSavedListener();
	});

	//If topup was triggered from the footer - popup the modal
	if($rootScope.topupComingFromFooter) {

		$rootScope.topupComingFromFooter = false;
		$rootScope.modalOpen = true;

		$mdDialog.show({
			controller: 'BillingController',
			templateUrl: 'app/views/elements/includes/billing-options.html',
			clickOutsideToClose: true
		});
	}
}]);
